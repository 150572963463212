import classnames from 'classnames';
import { Map as ImmutableMap } from 'immutable';
import moment from 'moment';

import { Tooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';
import { first, get, size, toJsIfImmutable } from 'utils/immutable';

import './validator.scss';

const renderRuleSetLabel = (ruleSet) => {
  const label = get(ruleSet, 'label');
  if (get(ruleSet, 'requested') === false) {
    return `${label} (${i18n.t(
      'frontproductstream.plugins.validator.inactive_ruleset.suffix',
      { defaultValue: 'Not activated' }
    )})`;
  }
  return <b>{label}</b>;
};

const renderDeadline = (deadline) => {
  const day = moment.utc(deadline).local().format('L');
  return (
    <>
      <i className="mdi mdi-calendar-clock" />
      &nbsp;
      {i18n.t('frontproductstream.plugins.validator.deadline.label', {
        defaultValue: 'Deadline:',
      })}
      &nbsp;
      <span className="deadline">{day}</span>
    </>
  );
};

const renderClosestApplicabilityDeadline = (applicabilities) => {
  const withDeadlines = Object.entries(toJsIfImmutable(applicabilities))
    .filter(([, { deadline }]) => !!deadline)
    .sort(
      (a, b) =>
        moment.utc(a[1].deadline).unix() - moment.utc(b[1].deadline).unix()
    );

  if (withDeadlines.length) {
    const [retailerId, { deadline }] = withDeadlines[0];
    return <li key={retailerId}>{renderDeadline(deadline)}</li>;
  }
  return null;
};

export const renderRuleSetLabels = (
  rule,
  ruleSets,
  bypassed = false,
  scopeToRecipientId = 0
) => {
  const ruleSetLabelClasses = classnames(
    'Raguel__label',
    'Raguel__label--margin',
    {
      'Raguel__label--error': !bypassed,
    }
  );

  if (size(ruleSets) === 1) {
    let applicabilities = get(
      first(ruleSets),
      'applicabilityByOrganizations',
      {}
    );
    if (scopeToRecipientId) {
      applicabilities = ImmutableMap(
        applicabilities.filter(
          (applicability, retailerId) =>
            retailerId === scopeToRecipientId.toString()
        )
      );
    }

    return (
      <span className={ruleSetLabelClasses}>
        {renderRuleSetLabel(first(ruleSets))}
        <ul>{renderClosestApplicabilityDeadline(applicabilities)}</ul>
      </span>
    );
  }

  const tooltipId = `raguel-error-message-ruleset-tip-${get(rule, 'id')}`;
  const ruleSetItems = ruleSets.map((rs) => (
    <ul key={get(rs, 'id')}>
      <li key={`${get(rs, 'id')}-label`}>{renderRuleSetLabel(rs)}</li>
      {renderClosestApplicabilityDeadline(
        get(rs, 'applicabilityByOrganizations')
      )}
    </ul>
  ));
  return (
    <span className={ruleSetLabelClasses} data-tip data-for={tooltipId}>
      <b>
        {i18n.t('frontproductstream.plugins.validator.ruleset_count.text', {
          defaultValue: '{{ count }} rulesets:',
          count: size(ruleSets),
        })}
      </b>
      <Tooltip id={tooltipId}>{ruleSetItems}</Tooltip>
    </span>
  );
};
