import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';

import { updateEntity } from 'actions/entity';
import Field from 'components/ui/form/field';

import './image-checkbox.scss';
import SelectableImage from './selectable-image';

class ImageCheckbox extends Field {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getLines() {
    return this.props.field.lines || [];
  }

  getKey = () => {
    const { field } = this.props;
    // Legacy fallback
    return field.inputKind?.mainKey ?? 'isConceptualizedBy';
  };

  onClick(element) {
    const { value, entityKind, entityId, field, dispatch } = this.props;
    const newValue = cloneDeep(value) || [];
    const elementIndex = this.selectedElementIndex(element, newValue);

    const key = this.getKey();

    if (elementIndex === -1) {
      // Element is not selected, add it.
      newValue.push({
        [key]: element,
      });
    } else {
      // We need to pop it.
      newValue.splice(elementIndex, 1);
    }
    dispatch(updateEntity(field.model, newValue, entityId, entityKind));
  }

  selectedElementIndex(element, selectedElements) {
    const key = this.getKey();
    return selectedElements.findIndex(
      (selectedElement) => selectedElement[key].id === element.id
    );
  }

  renderElementList(selectedElements) {
    const isReadOnly = this.isReadOnly();
    return this.getLines().map((element) => {
      const selected = !!(
        this.selectedElementIndex(element, selectedElements) + 1
      );
      if (!selected && isReadOnly) {
        return null;
      }
      return (
        <div className="ImageCheckbox__item" key={element.id}>
          <SelectableImage
            element={element}
            kind={this.props.field.inputKind.pictureKind}
            onClick={this.onClick}
            selected={selected}
            readOnly={isReadOnly}
          />
        </div>
      );
    });
  }

  render() {
    const { value, field } = this.props;
    if (!field) {
      return null;
    }

    return (
      <div
        className={classNames(this.getClasses({ 'ImageCheckbox row': true }))}
      >
        {this.renderLabel('col-xs-4')}
        <div id={this.getId()} className="ImageCheckbox__content col-xs-8">
          <div className="row">
            <div className="col-xs-12">
              {this.renderElementList(value || [])}
            </div>
          </div>
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export { ImageCheckbox };
export default connect()(ImageCheckbox);
