import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BrandAutocomplete from 'components/ui/autocomplete/brand';
import Field from 'components/ui/form/field';
import { hasCompanyProfile } from 'modules/company-profile/shared/utils';
import {
  selectIsAdmin,
  selectIsMaker,
  selectUser,
} from 'reducers/user/selectors';
import { get, toJsIfImmutable } from 'utils/immutable';

const mapStateToProps = createStructuredSelector({
  isUserManufacturer: selectIsMaker,
  isUserAdmin: selectIsAdmin,
  hasCompanyProfileFeature: (state) => hasCompanyProfile(selectUser(state)),
});

export class FormBrandAutocomplete extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    isUserManufacturer: PropTypes.bool.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    hasCompanyProfileFeature: PropTypes.bool.isRequired,
  });

  shouldComponentUpdate(nextProps, nextState) {
    // We override this method because we are checking the id within the value.
    return (
      nextState !== this.state ||
      get(nextProps, 'value.id') !== get(this.props, 'value.id') ||
      nextProps.field !== this.props.field ||
      this.getPatch(nextProps) !== this.getPatch()
    );
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue(data) {
    return data.value || null;
  }

  render() {
    const { value, isUserManufacturer, isUserAdmin, hasCompanyProfileFeature } =
      this.props;

    if (!this.isVisible()) {
      return null;
    }

    return (
      <div
        className={classNames(
          this.getClasses({ FormBrandAutocomplete: true, row: true })
        )}
      >
        <div className="col-xs-12">
          <BrandAutocomplete
            value={value ? [toJsIfImmutable(value)] : []}
            onChange={this.handleChange}
            isUserAdmin={isUserAdmin}
            isUserManufacturer={isUserManufacturer}
            hasCompanyProfile={hasCompanyProfileFeature}
            isReadOnly={this.isReadOnly()}
            inputId={this.getId()}
          />
          {this.renderPlugins({ offset: true })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormBrandAutocomplete);
