import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

export const OrganizationAdmin = asyncComponent(async () =>
  registerModule(
    await import(/* webpackChunkName: "organization-admin" */ './async')
  )
);

export { OrganizationBrandList } from './components/brand';
export { hasLegalIdentifiersReminderModal } from './components/utils';
