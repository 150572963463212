import { notificationError, notificationSuccess } from 'actions/notification';
import { productSelection } from 'modules/catalog/product/actions';
import { acceptBulk as acceptBulkApi } from 'resources/serviceProductApi';
import i18n from 'utils/i18n';

export const acceptBulk = (productKeyIds) => async (dispatch) => {
  const { error } = await acceptBulkApi(
    productKeyIds,
    'bulk-accept-from-catalog'
  );
  if (error) {
    dispatch(
      notificationError(i18n.t('An error occured, please try again later.'))
    );
    return;
  }

  dispatch(
    notificationSuccess(
      i18n.t('Your products are being validated, this can take a few minutes.')
    )
  );

  dispatch(productSelection({ all: true, selected: false }));
};
