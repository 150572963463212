import { Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { keyBy } from 'utils/fn';

import * as constants from './constants';

const initialState = Map();

export default createReducer(initialState, {
  [constants.RECEIVE_REFERENTIAL]: (state, { payload: { slug, entities } }) => {
    const entitiesById = keyBy(entities, (entity) => entity.get('id'));
    return state.set(slug, fromJS({ entities, entitiesById }));
  },
});
