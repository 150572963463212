import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';

import './label.scss';

class InputLabel extends PureComponent {
  static demoProps = [
    { id: 'label1', label: 'Label 1' },
    { id: 'label2', label: 'Label 2', help: 'Some useful help' },
    { id: 'label3', label: 'Label 3', alignLeft: true },
    {
      id: 'label4',
      label: 'Label 4',
      help: 'Some useful help',
      alignLeft: true,
    },
  ];

  static propTypes = {
    id: PropTypes.string.isRequired,
    htmlFor: PropTypes.string,
    label: PropTypes.string,
    help: PropTypes.node,
    alignLeft: PropTypes.bool,
    mandatory: PropTypes.bool,
    multiLevel: PropTypes.bool,
  };

  static defaultProps = {
    htmlFor: null,
    help: null,
    alignLeft: false,
    mandatory: false,
  };

  render() {
    const { id, htmlFor, label, help, alignLeft, mandatory, multiLevel } =
      this.props;
    const classes = {
      InputLabel: true,
      'InputLabel--left': alignLeft,
      'InputLabel--multiLevel': multiLevel,
    };
    return (
      <div className={classNames(classes)}>
        {mandatory && <span className="InputLabel__mandatory">*&nbsp;</span>}
        <label htmlFor={htmlFor}>{label}</label>
        {help && (
          <div className="InputLabel__help">
            <HelpTooltip id={`help-${id}`} message={help} />
          </div>
        )}
      </div>
    );
  }
}

export default InputLabel;
