import classNames from 'classnames';
import { remove as removeDiacritics } from 'diacritics';
import { List } from 'immutable';
import { isBoolean } from 'lodash/fp';
import React, { useCallback, useEffect, useState } from 'react';

import { useDebounce } from '@alkem/react-hooks';
import ChoiceTree from '@alkem/react-ui-choicetree';
import { ClickOutside } from '@alkem/react-ui-click-outside';
import { Text } from '@alkem/react-ui-inputs';
import { Spinner } from '@alkem/react-ui-spinner';

import i18n from 'utils/i18n';
import { get, set, size } from 'utils/immutable';

import './select-multiple.scss';

type SelectMultipleProps = {
  id: string;
  pluralLabel: string;
  options: List<any>;
  secondaryOptions?: List<any> | null;
  headers?: {
    primary?: string;
    secondary?: string;
    secondaryHidden?: string;
    secondaryVisible?: string;
  };
  multiple?: boolean;
  isLoading?: boolean;
  loadingMessage?: string | null;
  renderItem?: (label: any, ruleSet: any) => any;
  onSelect?: (option: any, e: any, checked: boolean) => any;
  removeFiltered?: boolean;
  selectors?: {
    selectId?: (e: any) => string;
    selectLabel?: (e: any) => string;
  };
  placeholder?: string;
  isOpen?: boolean;
  setOpen?: (open: boolean) => void;
};

const _SelectMultipleInternal = React.memo(
  ({
    id,
    selectors = {
      selectId: (e) => e.get('id'),
      selectLabel: (e) => e.get('label'),
    },
    secondaryOptions = null,
    headers = {},
    removeFiltered = true,
    multiple = true,
    placeholder,
    isLoading = false,
    loadingMessage,
    options = List(),
    pluralLabel,
    renderItem,
    onSelect,
    isOpen,
  }: SelectMultipleProps) => {
    const [showSecondaryOptions, setShowSecondaryOptions] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState<List<any> | null>(
      null
    );
    const [filteredSecondaryOptions, setFilteredSecondaryOptions] =
      useState<List<any> | null>(null);

    useEffect(() => {
      if (!isOpen) {
        setFilterValue('');
        setShowSecondaryOptions(false);
      }
    }, [isOpen]);

    const matchFilter = useCallback(
      (option) => {
        const filter = removeDiacritics(filterValue.trim().toLowerCase());
        const optionLabel = selectors?.selectLabel?.(option);
        const label =
          optionLabel && removeDiacritics(optionLabel.trim().toLowerCase());
        if (label.indexOf(filter) >= 0) {
          return true;
        }
        const children = get(option, 'children');
        if (size(children)) {
          return children.some((child) => matchFilter(child));
        }
        return false;
      },
      [selectors, filterValue]
    );

    const filterOptions = useCallback(
      (_options) => {
        if (!filterValue) {
          return _options;
        }
        if (removeFiltered) {
          return _options.filter((option) => matchFilter(option));
        }
        return _options.map((option) =>
          set(option, 'hide', !matchFilter(option))
        );
      },
      [filterValue, matchFilter, removeFiltered]
    );

    const onUpdateFilterValue = useCallback((event) => {
      setFilterValue(event.target.value);
    }, []);

    const onSearch = useCallback(() => {
      if (filterValue) {
        setFilteredOptions(filterOptions(options));
        setFilteredSecondaryOptions(
          secondaryOptions ? filterOptions(secondaryOptions) : null
        );
      } else {
        setFilteredOptions(null);
        setFilteredSecondaryOptions(null);
      }
    }, [filterValue, options, secondaryOptions, filterOptions]);

    useDebounce(filterValue, 500, onSearch);

    const onToggleSecondaryOptions = useCallback(
      () => setShowSecondaryOptions(!showSecondaryOptions),
      [showSecondaryOptions]
    );

    const getPlaceholder = () => {
      const allOptions = options.concat(secondaryOptions || List());
      const selected = allOptions.filter((r) => r.get('checked')).size;
      switch (selected) {
        case 0:
          return placeholder;
        case 1:
          return allOptions
            .filter((r) => r.get('checked'))
            .first()
            .get('label');
        default:
          return `${selected} ${pluralLabel}`;
      }
    };

    const renderSearch = () => {
      const className = classNames({
        mdi: true,
        'mdi-chevron-up': isOpen,
        'mdi-chevron-down': !isOpen,
      });
      return (
        <div
          data-testid="select-multiple-text"
          className="ViewAsSelectMultiple__search"
        >
          <Text
            id={`${id}-search`}
            value={filterValue}
            placeholder={getPlaceholder()}
            onChange={(e) => onUpdateFilterValue(e)}
            type="text"
          />
          <i className={className} />
        </div>
      );
    };

    const renderOptions = (selectedPaths) => {
      const displayedOptions = filteredOptions || options;
      if (displayedOptions.size === 0) {
        return null;
      }
      if (isLoading) {
        return (
          <div className="alk-flex alk-flex-center ViewAsSelectMultiple__loading">
            <Spinner small />
            {loadingMessage ||
              i18n.t(
                'frontproductstream.view_as.recipients_select.loading_message',
                { defaultValue: 'Loading your recipients...' }
              )}
          </div>
        );
      }
      return (
        <>
          {headers.primary && (
            <div className="ViewAsSelectMultiple__header">
              {headers.primary}
            </div>
          )}
          <ChoiceTree
            id={`${id}-choicetree`}
            options={displayedOptions}
            onSelect={onSelect}
            selectedPath={selectedPaths}
            selectors={selectors}
            multiple={multiple}
            renderItem={renderItem}
            isStatic
            scrollable
          />
        </>
      );
    };

    const renderSecondaryOptions = (selectedPaths, filtered) => {
      const displayedSecondaryOptions =
        filteredSecondaryOptions || secondaryOptions;
      if (!displayedSecondaryOptions || displayedSecondaryOptions.size === 0) {
        return null;
      }
      if (isLoading) {
        return null;
      }
      const show = showSecondaryOptions || filtered;
      return (
        <>
          {!filtered && (
            <div
              className="ViewAsSelectMultiple__header ViewAsSelectMultiple__header--clickable"
              onClick={onToggleSecondaryOptions}
            >
              {showSecondaryOptions
                ? headers.secondaryVisible
                : headers.secondaryHidden}
            </div>
          )}
          {filtered && (
            <div className="ViewAsSelectMultiple__header">
              {headers.secondary}
            </div>
          )}
          {show && (
            <ChoiceTree
              id={`${id}-secondary`}
              options={displayedSecondaryOptions}
              onSelect={onSelect}
              selectedPath={selectedPaths}
              selectors={selectors}
              multiple={multiple}
              renderItem={renderItem}
              isStatic
              scrollable
            />
          )}
        </>
      );
    };

    const allOptions = options.concat(secondaryOptions || List());
    const displayedOptions = filteredOptions || options;
    const displayedSecondaryOptions =
      filteredSecondaryOptions || secondaryOptions;
    const selectedPaths = allOptions
      .map((o) => (o.get('checked') ? [o.get('id')] : null))
      .filter((o) => !!o)
      .toArray();
    const noOptionSelected = selectedPaths.length === 0;
    const oneOptionMode = allOptions.size === 1;
    const className = classNames({
      ViewAsSelectMultiple: true,
      'ViewAsSelectMultiple--one-option-mode': oneOptionMode,
      'ViewAsSelectMultiple--no-option-selected': noOptionSelected,
    });
    const showOptions = !!(
      (isOpen || oneOptionMode) &&
      (displayedOptions.size ||
        (displayedSecondaryOptions && displayedSecondaryOptions.size))
    );

    return (
      <div id={id} className={className}>
        {!oneOptionMode && renderSearch()}
        {showOptions && (
          <div className="ViewAsSelectMultiple__options">
            {renderOptions(selectedPaths)}
            {renderSecondaryOptions(
              selectedPaths,
              filteredSecondaryOptions && filteredSecondaryOptions.size > 0
            )}
          </div>
        )}
      </div>
    );
  }
);

export const SelectMultiple = ({
  setOpen,
  isOpen,
  ...props
}: SelectMultipleProps) => {
  const [isInternalOpen, setInternalOpen] = useState(false);
  useEffect(() => {
    if (isBoolean(isOpen) && isOpen !== isInternalOpen) {
      setInternalOpen(isOpen);
    }
  }, [isOpen, isInternalOpen]);
  const onOpen = useCallback(() => {
    setInternalOpen(true);
    setOpen?.(true);
  }, [setOpen]);
  const onClose = useCallback(() => {
    setInternalOpen(false);
    setOpen?.(false);
  }, [setOpen]);
  return (
    <ClickOutside onClick={onOpen} onClickOutside={onClose}>
      <_SelectMultipleInternal
        {...props}
        isOpen={isBoolean(isOpen) ? isOpen : isInternalOpen}
      />
    </ClickOutside>
  );
};

export default SelectMultiple;
