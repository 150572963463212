import { call, put } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import { fetchSourcing } from 'resources/searchApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import { receivePublicProduct } from '../actions/public';

export function* fetchPublicProductSaga({
  payload: { productVersionId, reference },
} = {}) {
  try {
    const response = yield call(fetchSourcing, {
      queries: {
        gtinsIn: reference,
      },
      sourceInclude: ['owner.uuid'],
    });
    yield put(
      receivePublicProduct({
        productVersionId,
        data: response.list.first().toJS(),
      })
    );
  } catch (e) {
    const error = i18n.t('Error when trying to receive product preview.');
    yield put(notificationError(error));
    yield call(logError, error);
  }
}
