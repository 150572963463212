import { List, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { selectProductKeyId } from 'reducers/productVersion';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { fill } from 'utils/routing';

import {
  selectExportFormat,
  selectExportProducts,
  selectSelectedMap,
} from '../../../../../../selectors';

const mapStateToProps = createStructuredSelector({
  selectedFormat: selectExportFormat,
  exportProducts: selectExportProducts,
  selectedProducts: selectSelectedMap,
  productKeyId: selectProductKeyId,
  user: (state) => state.user,
});

export class CatalogExportModalV2Step2 extends PureComponent {
  static propTypes = {
    selectedFormat: PropTypes.object,
    selectedProducts: ImmutablePropTypes.map.isRequired,
    productKeyId: PropTypes.number.isRequired,
    user: PropTypes.object,
    exportProducts: ImmutablePropTypes.shape({
      sharedValidProducts: ImmutablePropTypes.list.isRequired,
      sharedInvalidProducts: ImmutablePropTypes.list.isRequired,
      blockedProducts: ImmutablePropTypes.list.isRequired,
      unsharedProducts: ImmutablePropTypes.list.isRequired,
      get: PropTypes.func,
    }),
  };

  renderProductGroup = (title, subtitle, mdiTag, products, renderLink) => {
    const { user, productKeyId } = this.props;
    return (
      <div className="CatalogExportModalV2__Group">
        <div className="CatalogExportModalV2__ProductGroupHeader">
          <div className="CatalogExportModalV2__ProductGroupTitle">
            <i className={`mdi ${mdiTag}`} />
            <div>{title}</div>
          </div>
          <div className="CatalogExportModalV2__ProductGroupSubtitle">
            {subtitle}
          </div>
        </div>
        <div className="CatalogExportModalV2__Products">
          {products.map((product) => {
            const gtin = product.getIn([
              'specializes',
              'isIdentifiedBy',
              0,
              'reference',
            ]);
            const productName = (
              product
                .get('namePublicLong', List())
                .find(
                  (decl) =>
                    decl.getIn(['expressedIn', 'normalizedCode']) ===
                    user.get('language')
                ) ||
              product.get('namePublicLong', List()).first() ||
              fromJS({ data: '' })
            ).get('data');
            return (
              <div
                key={`product-${gtin}`}
                className="CatalogExportModalV2__Product"
              >
                <div>
                  <b>{gtin}</b> - {productName}
                </div>
                {renderLink && (
                  <Link to={fill(routes.productDashboard, productKeyId)}>
                    {i18n.t('Configure')}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render = () => {
    const { selectedFormat, selectedProducts, exportProducts } = this.props;
    if (selectedProducts.length === 0) {
      return (
        <div className="CatalogExportModalV2__disclaimer">
          {i18n.t(
            'Export summary is not available when running a full catalog export.'
          )}
        </div>
      );
    }

    return (
      <>
        <div className="CatalogExportModalV2__ExportName">
          {i18n.t('Export in {{format}} format', {
            format: selectedFormat.label,
          })}
        </div>
        {exportProducts.get('sharedValidProducts').count() > 0
          ? this.renderProductGroup(
              i18n.t('Valid data'),
              '',
              'ProductValidationStatusOk',
              exportProducts.get('sharedValidProducts'),
              false
            )
          : null}
        {exportProducts.get('sharedInvalidProducts').count() > 0
          ? this.renderProductGroup(
              i18n.t('Incomplete data'),
              i18n.t('Fields might be missing'),
              'ProductValidationStatusKo',
              exportProducts.get('sharedInvalidProducts'),
              false
            )
          : null}
        {exportProducts.get('blockedProducts').count() > 0
          ? this.renderProductGroup(
              i18n.t('Blocked products'),
              i18n.t('Will not be exported'),
              'ProductValidationStatusUnknown',
              exportProducts.get('blockedProducts'),
              true
            )
          : null}
        {exportProducts.get('unsharedProducts').count() > 0
          ? this.renderProductGroup(
              i18n.t('Recipient not activated'),
              i18n.t('Will not be exported'),
              'ProductValidationStatusUnknown',
              exportProducts.get('unsharedProducts'),
              true
            )
          : null}
      </>
    );
  };
}

export default connect(
  mapStateToProps,
  separateActions
)(CatalogExportModalV2Step2);
