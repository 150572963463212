import { flow, identity, set } from 'lodash/fp';

import { ActionPayload } from 'types/redux';

import { initDataOps } from '../actions';
import { DataOpsState } from '../types';

import { dataOpsResetReducer } from './reset';

export const dataOpsInitReducer = (
  {
    productKeyId,
    entityIdToProductKeyId,
    productKeyIdToEntityId,
  }: ActionPayload<typeof initDataOps>,
  state: DataOpsState
) =>
  flow(
    state.productKeyId && state.productKeyId !== productKeyId
      ? dataOpsResetReducer
      : identity,
    set(['productKeyId'], productKeyId),
    set(['entityIdToProductKeyId'], entityIdToProductKeyId),
    set(['productKeyIdToEntityId'], productKeyIdToEntityId)
  );
