import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import { compareStatusesDates } from './utils';

export const selectModuleState = (state) => state.module.unsynchedWarning;

export const getStatuses = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('exportSynchronizationStatuses')
);

export const getStatusesByRecipient = createSelector(
  selectModuleState,
  (moduleState) =>
    moduleState
      .get('exportSynchronizationStatuses')
      .sort(compareStatusesDates)
      .reduce((_map, status) => {
        let map = _map;
        const recipientId = status.getIn(['targetOrganization', 'id']);
        if (!map.get(recipientId)) {
          map = map.set(recipientId, List());
        }

        // we get only the newest response by targetOrganization
        if (
          map
            .get(recipientId)
            .filter((s) => s.get('format') === status.get('format')).size < 1
        ) {
          map = map.set(recipientId, map.get(recipientId).push(status));
        }

        return map;
      }, Map())
);
