import { isNumber, isString } from 'lodash';
import { flow } from 'lodash/fp';
import memoize from 'memoize-one';
import { createSelector } from 'reselect';

import { CONSUMER_UNIT, DISPLAY_UNIT } from 'constants/fields';
import {
  getOrganizationFields,
  getOrganizationMainCurrency,
} from 'core/api/organization';
import {
  getIsDisplayUnit,
  getIsMadeOf,
  getProductId,
} from 'core/api/productversion';
import { getTargetOrganizationId } from 'core/api/sharing-unit';
import {
  getMyFields,
  getMyMainCurrency,
  getOrganizationId,
  isRetailer,
} from 'core/api/user';
import { RELEASE_PRICE_WATERFALL_TAXES_DATES_MANAGEMENT } from 'modules/feature-flag';
import {
  getRecipientsMap,
  selectRecipientByIdHasSetting,
} from 'modules/recipients/reducers';
import { SHARING_UNIT_STATUS_DRAFT } from 'modules/sharing-units/constants';
import { getSharingUnits } from 'modules/sharing-units/selectors';
import { selectEditedProductVersion } from 'reducers/productVersion';
import { selectUser } from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import { VATRateCodes } from './constants';

export const selectModuleState = (state) => state.priceWaterfalls;

export const selectBracketTypeOptions = createSelector(
  selectModuleState,
  (state) => {
    if (!state.bracketTypes) {
      return null;
    }
    const bracketTypeOptions = state.bracketTypes
      .map((b) => {
        return {
          value: b.get('id'),
          label: b.get('label'),
        };
      })
      .toJS();
    bracketTypeOptions.push({ label: i18n.t('N.C.'), value: null });
    return bracketTypeOptions;
  }
);

export const selectTaxLabels = createSelector(
  selectModuleState,
  (state) => state.taxLabels
);

function isValidTaxValue(value) {
  return isNumber(value) || (isString(value) && value !== '');
}

export function getTaxesFromDates(
  tax,
  suPriceEffectiveDates,
  hasPWTaxesDatesManagementRelease
) {
  if (!hasPWTaxesDatesManagementRelease) {
    return {
      taxValue: get(tax, 'dutyFeeTaxList.0.dutyFeeTaxAmountNumber.0.data'),
      taxRate: get(tax, 'dutyFeeTaxList.0.dutyFeeTaxRateNumber'),
    };
  }
  if (suPriceEffectiveDates) {
    const suPriceEffectiveStartDate = get(
      suPriceEffectiveDates.first(),
      'effectiveStartDateTime'
    );
    const suPriceEffectiveEndDate =
      get(suPriceEffectiveDates.first(), 'effectiveEndDateTime') ||
      Number.MAX_SAFE_INTEGER;
    const taxEffectiveStartDate = get(tax, 'dutyFeeTaxEffectiveStartDate') || 0;
    const taxEffectiveEndDate =
      get(tax, 'dutyFeeTaxEffectiveEndDate') || Number.MAX_SAFE_INTEGER;
    if (
      suPriceEffectiveEndDate <= taxEffectiveEndDate &&
      suPriceEffectiveStartDate >= taxEffectiveStartDate
    ) {
      return {
        taxValue: get(tax, 'dutyFeeTaxList.0.dutyFeeTaxAmountNumber.0.data'),
        taxRate: get(tax, 'dutyFeeTaxList.0.dutyFeeTaxRateNumber'),
      };
    }
  }
  return {
    taxValue: null,
    taxRate: null,
  };
}

export function getTaxInformationForProduct(
  pv,
  fieldNames,
  suPriceEffectiveDates,
  hasPWTaxesDatesManagementRelease
) {
  if (!fieldNames || !fieldNames.size) {
    return null;
  }
  const data = {};
  let VATRate = null;
  if (!fieldNames.includes('dutyFeeTaxInformationList')) {
    return { data, VATRate };
  }
  const taxes = get(pv, 'dutyFeeTaxInformationList');
  if (taxes && taxes.length) {
    for (const t of taxes.filter((e) => !!get(e, 'dutyFeeTaxTypeCode.code'))) {
      const { code } = t.dutyFeeTaxTypeCode;
      const { taxValue, taxRate } = getTaxesFromDates(
        t,
        suPriceEffectiveDates,
        hasPWTaxesDatesManagementRelease
      );
      if (isValidTaxValue(taxValue)) {
        data[code] = taxValue;
        if (get(t, 'dutyFeeTaxTypeCode.data.old_name')) {
          // TODO PALPA-899: remove once migrated.
          data[t.dutyFeeTaxTypeCode.data.old_name] = taxValue;
        }
      } else if (isValidTaxValue(taxRate) && VATRateCodes.includes(code)) {
        // VATRate.
        VATRate = {
          code,
          data: { rate: taxRate },
        };
      }
    }
  }
  return { data, VATRate };
}

export function getTaxInformationForProducts(
  fieldNamesFunc,
  product,
  targetOrganizationId,
  suPriceEffectiveDates,
  hasPWTaxesDatesManagementRelease
) {
  const fieldNames = fieldNamesFunc(targetOrganizationId);
  // Get appropriate data based on the product.
  // If display unit, then get it for the display and all direct children.
  const products = {};
  const isDisplayUnit = getIsDisplayUnit(product);
  // Current product.
  products[getProductId(product)] = getTaxInformationForProduct(
    product,
    isDisplayUnit ? fieldNames[DISPLAY_UNIT] : fieldNames[CONSUMER_UNIT],
    suPriceEffectiveDates,
    hasPWTaxesDatesManagementRelease
  );
  // All children.
  if (isDisplayUnit) {
    (getIsMadeOf(product) || []).forEach((imo) => {
      products[get(imo, ['targetProduct', 'id'])] = getTaxInformationForProduct(
        get(imo, ['targetProduct', 'version']),
        fieldNames[CONSUMER_UNIT],
        null,
        null
      );
    });
  }
  return products;
}

export const selectFieldNames = createSelector(
  selectUser,
  getRecipientsMap,
  (user, recipients) =>
    memoize((targetOrganizationId) => {
      // Load fields names from view as.
      let fieldNamesConsumer = null;
      let fieldNamesDisplay = null;
      // Currently we have no target organization ID when we are the retailer.
      if (
        getOrganizationId(user) === targetOrganizationId ||
        !targetOrganizationId
      ) {
        fieldNamesConsumer = get(getMyFields(user), CONSUMER_UNIT);
        fieldNamesDisplay = get(getMyFields(user), DISPLAY_UNIT);
      } else {
        const recipient = get(recipients, targetOrganizationId);
        fieldNamesConsumer = get(
          getOrganizationFields(recipient),
          CONSUMER_UNIT
        );
        fieldNamesDisplay = get(getOrganizationFields(recipient), DISPLAY_UNIT);
      }
      return {
        [CONSUMER_UNIT]: fieldNamesConsumer,
        [DISPLAY_UNIT]: fieldNamesDisplay,
      };
    })
);

export const selectTaxInformation = createSelector(
  selectFieldNames,
  selectEditedProductVersion,
  getSharingUnits,
  selectRecipientByIdHasSetting,
  (fieldNamesFunc, productVersion, sharingUnits, recipientByIdHasSettingFunc) =>
    memoize(({ targetOrganizationId, entityId }) => {
      const sharingUnit = get(sharingUnits, entityId);
      const suPriceEffectiveDates = get(
        sharingUnit,
        'data.priceEffectiveDateList'
      );
      const hasPWTaxesDatesManagementRelease = recipientByIdHasSettingFunc(
        targetOrganizationId,
        RELEASE_PRICE_WATERFALL_TAXES_DATES_MANAGEMENT
      );
      return getTaxInformationForProducts(
        fieldNamesFunc,
        productVersion,
        targetOrganizationId,
        suPriceEffectiveDates,
        hasPWTaxesDatesManagementRelease
      );
    })
);

export const selectCurrencyForSharingUnit = createSelector(
  selectUser,
  getRecipientsMap,
  getSharingUnits,
  (user, recipients, sharingUnits) =>
    memoize((suId) => {
      if (isRetailer(user)) {
        return getMyMainCurrency(user);
      }
      const sharingUnit = get(sharingUnits, suId);
      const targetOrganizationId = getTargetOrganizationId(sharingUnit);
      const recipient = get(recipients, targetOrganizationId);
      return getOrganizationMainCurrency(recipient) || null;
    })
);

export const selectCurrencyForRecipient = createSelector(
  selectUser,
  getRecipientsMap,
  (user, recipients) =>
    memoize((targetOrganizationId) => {
      if (isRetailer(user)) {
        return getMyMainCurrency(user);
      }
      const recipient = get(recipients, targetOrganizationId);
      return getOrganizationMainCurrency(recipient) || null;
    })
);

export const selectSharingUnitIsDraft = createSelector(
  getSharingUnits,
  (sharingUnits) => (suId) => {
    const sharingUnit = get(sharingUnits, suId);
    return get(sharingUnit, 'status') === SHARING_UNIT_STATUS_DRAFT;
  }
);

export const selectCreatingPriceWaterfall = createSelector(
  selectModuleState,
  (priceWaterfalls) =>
    memoize((productId) => priceWaterfalls.creatingForProduct[productId])
);

export const selectLevelTypes = flow(
  selectModuleState,
  (priceWaterfalls) => priceWaterfalls.levelTypes
);

export const selectLevelItemTypes = flow(
  selectModuleState,
  (priceWaterfalls) => priceWaterfalls.levelItemTypes
);
