import { call, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  persistFilteredFiltersState,
  persistFiltersCollapsedState,
  persistFiltersPaginationState,
  persistOnlyDuplicatedProducts,
  persistWithAllTypes,
  persistWithArchivedProducts,
  persistWithRefusedAssignations,
} from 'core/modules/list/sagas/filters-config';
import { persistItemsPerPage } from 'core/modules/list/sagas/pagination';
import { logError } from 'utils/logging';
import { withCatch } from 'utils/saga';

import {
  BULK_ACTIVATE_RECIPIENTS,
  COLLAPSE_FILTERS,
  DOWNLOAD_COMPLIANCE_REPORT,
  EXPORT_PRODUCTS,
  FETCH_COLUMN_REFERENTIALS,
  FETCH_EXPORT_FILE_FORMATS,
  FETCH_LIST,
  FETCH_MATURITY_RULESETS,
  FETCH_PUBLIC_PRODUCT,
  INIT_CATALOG,
  SEARCH,
  SELECT_COLUMN,
  SORT_LIST,
  TRACK_CATALOG_ACTION,
  TRACK_SEARCH,
  UPDATE_FILTERED_FILTERS,
  UPDATE_FILTERS_PAGINATION,
  UPDATE_ITEMS_PER_PAGE,
  UPDATE_ONLY_DUPLICATED_PRODUCTS,
  UPDATE_SEARCH_FROM_QUERY,
  UPDATE_SELECTED_FILTERS,
  UPDATE_WITH_ALL_TYPES,
  UPDATE_WITH_ARCHIVED_PRODUCTS,
  UPDATE_WITH_REFUSED_ASSIGNATIONS,
  UPSERT_LIST_COLUMNS,
} from '../actions/types';
import { FILTERS_CONFIG_KEY, ITEMS_PER_PAGE_KEY } from '../constants/storage';
import { selectCatalogContext } from '../selectors';

import { bulkActivateRecipientsSaga } from './bulk-activate-recipients';
import columnReferentialSaga from './column-referentials';
import { complianceReportSaga } from './download-compliance-report';
import { fetchExportFileFormatsSaga } from './export';
import { updateQueryParams } from './history';
import { initCatalogSaga } from './init';
import fetchListSaga, { exportProductsSaga } from './list';
import { fetchMaturityRulesets } from './maturity-rulesets';
import { fetchPublicProductSaga } from './public';
import {
  trackAction,
  trackSearch,
  trackSelectedFilters,
  trackSortList,
} from './tracking';
import { updateListColumnsSaga, upsertListColumnsSaga } from './user-settings';

const persistWithContext = withCatch(function* persistWithContextSaga(
  saga,
  storageKey,
  action
) {
  const context = yield select(selectCatalogContext);
  yield call(saga, `${storageKey}/${context.type}`, action);
});

export default function* catalogMainSaga() {
  try {
    yield takeLatest(INIT_CATALOG, withCatch(initCatalogSaga));
    yield takeLatest(
      [
        FETCH_LIST,
        UPDATE_SELECTED_FILTERS,
        SEARCH,
        UPDATE_ITEMS_PER_PAGE,
        UPDATE_WITH_ARCHIVED_PRODUCTS,
        UPDATE_ONLY_DUPLICATED_PRODUCTS,
        UPDATE_WITH_ALL_TYPES,
        UPDATE_WITH_REFUSED_ASSIGNATIONS,
      ],
      fetchListSaga
    );
    yield takeLatest(FETCH_COLUMN_REFERENTIALS, columnReferentialSaga);
    yield takeLatest(TRACK_SEARCH, trackSearch);
    yield takeLatest(UPDATE_SELECTED_FILTERS, trackSelectedFilters);
    yield takeLatest(EXPORT_PRODUCTS, exportProductsSaga);
    yield takeLatest(DOWNLOAD_COMPLIANCE_REPORT, complianceReportSaga);
    yield takeLatest(SORT_LIST, trackSortList);
    yield takeLatest(UPSERT_LIST_COLUMNS, upsertListColumnsSaga);
    yield takeLatest(FETCH_MATURITY_RULESETS, withCatch(fetchMaturityRulesets));

    yield takeEvery(SELECT_COLUMN, updateListColumnsSaga);
    yield takeEvery(
      [UPDATE_SELECTED_FILTERS, SEARCH, UPDATE_SEARCH_FROM_QUERY],
      updateQueryParams
    );
    yield takeEvery(TRACK_CATALOG_ACTION, trackAction);

    // sagas from core/modules/list/sagas
    yield takeLatest(
      COLLAPSE_FILTERS,
      persistWithContext,
      persistFiltersCollapsedState,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_FILTERED_FILTERS,
      persistWithContext,
      persistFilteredFiltersState,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_FILTERS_PAGINATION,
      persistWithContext,
      persistFiltersPaginationState,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_ITEMS_PER_PAGE,
      persistWithContext,
      persistItemsPerPage,
      ITEMS_PER_PAGE_KEY
    );
    yield takeLatest(
      UPDATE_WITH_ARCHIVED_PRODUCTS,
      persistWithContext,
      persistWithArchivedProducts,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_ONLY_DUPLICATED_PRODUCTS,
      persistWithContext,
      persistOnlyDuplicatedProducts,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_WITH_ALL_TYPES,
      persistWithContext,
      persistWithAllTypes,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_WITH_REFUSED_ASSIGNATIONS,
      persistWithContext,
      persistWithRefusedAssignations,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(FETCH_PUBLIC_PRODUCT, fetchPublicProductSaga);
    yield takeLatest(
      FETCH_EXPORT_FILE_FORMATS,
      withCatch(fetchExportFileFormatsSaga)
    );

    yield takeLatest(
      BULK_ACTIVATE_RECIPIENTS,
      withCatch(bulkActivateRecipientsSaga)
    );
  } catch (e) {
    logError(e);
  }
}
