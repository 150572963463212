import serviceProductApi from 'resources/serviceProductApi';
import { cancelPromise } from 'utils';
import { createAction } from 'utils/redux';
import { track } from 'utils/tracking';

import {
  selectIsDisableProductsInProgress,
  selectProductVersionsToDisable,
} from '../selectors';

import {
  REMOVE_FROM_ACTIVE_RANGE_DONE,
  REMOVE_FROM_ACTIVE_RANGE_IN_PROGRESS,
  REMOVE_FROM_ACTIVE_RANGE_RESET,
  REMOVE_FROM_ACTIVE_RANGE_SHOW,
} from './types';

let disableProductsPromise;

export const showRemoveFromActiveRange = createAction(
  REMOVE_FROM_ACTIVE_RANGE_SHOW
);
export const removeFromActiveRangeInProgress = createAction(
  REMOVE_FROM_ACTIVE_RANGE_IN_PROGRESS
);
export const removeFromActiveRangeDone = createAction(
  REMOVE_FROM_ACTIVE_RANGE_DONE
);

export const resetRemoveFromActiveRange = () => {
  cancelPromise(disableProductsPromise);
  return { type: REMOVE_FROM_ACTIVE_RANGE_RESET };
};

function requestRemoveFromActiveRange({ productKeyIds, dispatch, getState }) {
  disableProductsPromise =
    serviceProductApi.disableFromActiveRange(productKeyIds);
  return disableProductsPromise
    .then(() => {
      track({
        category: 'product',
        action: 'product_keys_removed',
        label: productKeyIds,
      });
      return 'success';
    })
    .catch(() => 'danger')
    .then((status) => {
      if (!selectIsDisableProductsInProgress(getState())) {
        return Promise.reject();
      }
      dispatch(
        removeFromActiveRangeInProgress({
          count: productKeyIds.length,
          productKeyIds,
          status,
        })
      );
      dispatch(removeFromActiveRangeDone());
      return Promise.resolve();
    });
}

export const removeProductsFromActiveRange = () => (dispatch, getState) => {
  const productVersions = selectProductVersionsToDisable(getState());
  const productKeyIds = productVersions
    .valueSeq()
    .map((pv) => pv.getIn(['product_key', 'id']))
    .toJS();

  cancelPromise(disableProductsPromise);
  dispatch(removeFromActiveRangeInProgress());
  return requestRemoveFromActiveRange({
    productKeyIds,
    dispatch,
    getState,
  });
};
