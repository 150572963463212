import { uniqBy } from 'lodash/fp';
import { put, select } from 'redux-saga/effects';

import { RELEASE_B2K_COLLAB_EDITS_SV_SF } from 'modules/feature-flag';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import { selectEditedDataMap } from 'modules/logistical-hierarchies/selectors';
import { selectEditedProductVersion } from 'reducers/productVersion';
import { selectIsManufacturer } from 'reducers/user/selectors';
import {
  ProductData,
  ProductLogisticalHierarchyData,
  ProductVersionData,
} from 'types';

import { dataOpsPatchesReceived, initDataOps } from '../actions';
import {
  selectHasDataOps,
  selectHasDataOpsForManufacturers,
} from '../selectors';
import {
  DataOpsDiffStatus,
  DataOpsEntityType,
  DataOpsPatch,
  DataOpsState,
} from '../types';

import { hierarchyEntityId } from './utils';

type HasDataOps = ReturnType<typeof selectHasDataOps>;
type PvData = ProductVersionData['data'];
type LuData = ProductLogisticalHierarchyData['data'];

export function* processProductDataSaga({
  payload: product,
}: {
  payload: ProductData;
}) {
  const hasDataOps: HasDataOps = yield select(selectHasDataOps);
  const isManufacturer = yield select(selectIsManufacturer);
  const hasDataOpsForManuf = yield select(selectHasDataOpsForManufacturers);

  const hasReleaseDataOpsOnSpecificValue = yield select(
    selectHasFeature(RELEASE_B2K_COLLAB_EDITS_SV_SF)
  );

  if (
    !hasDataOps ||
    (isManufacturer &&
      (!hasDataOpsForManuf ||
        !(
          product.data.productVersion.patches?.length ||
          product.data.logisticalHierarchies_beta?.some(
            (lu) => lu.patches?.length
          )
        )))
  ) {
    return;
  }

  const pv: PvData = yield select(selectEditedProductVersion);
  const hierarchies: { [internalId: string]: LuData } = yield select(
    selectEditedDataMap
  );
  const productKeyId = product.key.id;
  const pvId = pv.id;

  const entityIdToProductKeyId: DataOpsState['entityIdToProductKeyId'] = {
    [pvId]: productKeyId,
  };
  const productKeyIdToEntityId: DataOpsState['productKeyIdToEntityId'] = {
    [productKeyId]: pvId,
  };

  for (const hierarchy of Object.values(hierarchies)) {
    const luProductKeyId = hierarchy.product_key_id;
    if (luProductKeyId !== productKeyId) {
      const luEntityId = hierarchyEntityId(hierarchy);
      entityIdToProductKeyId[luEntityId] = luProductKeyId;
      productKeyIdToEntityId[luProductKeyId] = luEntityId;
    }
  }

  yield put(
    initDataOps({
      productKeyId,
      entityIdToProductKeyId,
      productKeyIdToEntityId,
    })
  );

  const addEntityInfos = (patches: DataOpsPatch[]) =>
    patches.map((patch) => {
      if (hasReleaseDataOpsOnSpecificValue) {
        const orga_id = patch.user?.belongsTo?.[0]?.id;
        if (
          orga_id &&
          product.data.productVersion.specificData?.filter(
            (specData) =>
              specData.targetOrganization.id === orga_id &&
              patch.fieldName in specData.data
          ).length
        ) {
          return {
            ...patch,
            appliesOnEntityId: orga_id,
            entityType: DataOpsEntityType.PRODUCTVERSION_OVERRIDE,
            isSpecific: true,
          };
        }
      }

      return {
        ...patch,
        appliesOnEntityId: pvId,
        entityType: DataOpsEntityType.PRODUCTVERSION,
      };
    });

  const mapProductKeys = (patches: DataOpsPatch[]) =>
    patches
      .map((patch) => ({
        ...patch,
        productKeyId: patch.sourceProductKeyId as number,
        retailerProductKeyId: patch.productKeyId,
      }))
      .filter((patch) =>
        [
          DataOpsDiffStatus.PATCH_PENDING_STATUS,
          DataOpsDiffStatus.PATCH_DISPUTE_STATUS,
        ].includes(patch.diffStatus)
      );

  const patchIds = new Set();
  let ucPatchList: DataOpsPatch[] = product.data.productVersion.patches || [];
  ucPatchList = addEntityInfos(ucPatchList);

  if (isManufacturer) {
    ucPatchList = mapProductKeys(ucPatchList);
  }

  for (const patch of ucPatchList) {
    patchIds.add(patch.id);
  }

  let ulPatchList: DataOpsPatch[] = (
    product.data.logisticalHierarchies_beta || []
  )
    .reduce((acc, lu) => {
      if (lu.patches) {
        return acc.concat(
          lu.patches.map((patch) => ({
            ...patch,
            entityType: DataOpsEntityType.LOGISTICAL_HIERARCHY_UNIT,
          }))
        );
      }
      return acc;
    }, [] as DataOpsPatch[])
    .filter((patch) => !patchIds.has(patch.id));

  if (isManufacturer) {
    ulPatchList = mapProductKeys(ulPatchList);
  }

  const patchList = uniqBy(
    (patch) => patch.id,
    [...ucPatchList, ...ulPatchList]
  );

  if (patchList.length) {
    yield put(dataOpsPatchesReceived({ patches: patchList }));
  }
}
