import { call, put, takeLatest } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import { FETCH_ADMIN_CUSTOMER, receiveAdminCustomer } from '../actions/admin';
import { fetchCustomer as fetchCustomerApi } from '../api/billing';

export function* fetchCustomerForAdminSaga() {
  try {
    const { customer, subscriptions, error } = yield call(fetchCustomerApi, {
      withSubscriptions: true,
    });
    if (error) {
      throw error;
    }
    yield put(receiveAdminCustomer({ customer, subscriptions }));
  } catch (error) {
    logError(error);
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.billing_admin.fetching_customer_information.error',
          {
            defaultValue:
              'An error occurred while fetching your customer information',
          }
        )
      )
    );
  }
}

export default function* billingMainSaga() {
  try {
    yield takeLatest(FETCH_ADMIN_CUSTOMER, fetchCustomerForAdminSaga);
  } catch (error) {
    logError(error);
  }
}
