import { get } from 'lodash/fp';

import { parseDateFromValue } from 'utils/datetime';
import i18n from 'utils/i18n';

const errorMessage = i18n.t(
  'frontproductstream.field.inline.unsupported_error',
  { defaultValue: 'Cannot display value inline' }
);

export const getInlineValue = function (field, data, currentLanguage) {
  const value = get(field.model, data);
  if (!value) {
    return null;
  }
  switch (field.inputKind.kind) {
    case 'labels': {
      return value.map(get(['isConceptualizedBy', 'label'])).join(', ');
    }
    case 'text':
      return value;
    case 'gln':
      return value;
    case 'timestamp':
      return value;
    case 'float':
      return value;
    case 'int':
      return value;
    case 'textarea':
      return value;
    case 'tags': {
      return value
        .filter((e) => e.text && e.text.length && e.text[0].expressedIn)
        .filter((e) => e.text[0].expressedIn.id === currentLanguage.id)
        .map((e) => e.text[0].data);
    }
    case 'list': {
      if (field.declinableBy) {
        if (currentLanguage && field.declinableBy.kind === 'languages') {
          return value
            .filter((e) => e.expressedIn.id === currentLanguage.id)
            .map((e) => e.data);
        } else {
          return value.map((e) => e.data).join(', ');
        }
      } else if (field.children && field.children.length === 1) {
        return value
          .map((v) => getInlineValue(field.children[0], v, currentLanguage))
          .filter((e) => !!e)
          .join(', ');
      }
      return errorMessage;
    }
    case 'checkbox':
      return value;
    case 'radio': {
      if (field.inputKind.referential) {
        return field.inputKind.values[value.id].label;
      }
      return value
        ? i18n.t('frontproductstream.field.radio.yes_label', {
            defaultValue: 'Yes',
          })
        : i18n.t('frontproductstream.field.radio.no_label', {
            defaultValue: 'No',
          });
    }
    case 'autocomplete':
      return value.label;
    case 'date': {
      const v = parseDateFromValue(value, get(['inputKind', 'output'], field));
      return v ? v.format('L') : errorMessage;
    }
    default:
      return errorMessage;
  }
};
