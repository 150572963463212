import { cloneElement, useState } from 'react';

export default function ComponentSequentialWorkflow({ children }) {
  const [currentChildIndex, setCurrentChildIndex] = useState(
    children.findIndex((child) => child)
  );

  const currentChild = children[currentChildIndex] || null;

  return (
    currentChild &&
    cloneElement(currentChild, {
      onClose: () => setCurrentChildIndex(currentChildIndex + 1),
    })
  );
}
