import lodash, { curry, flip } from 'lodash/fp';

import { keyBy as defaultKeyBy } from 'utils/fn';
import {
  getFp,
  isImmutable,
  setFp,
  size as sizeFp,
  toArray,
} from 'utils/immutable';

export const get = getFp;

export const set = setFp;

export const keyBy = curry(flip(defaultKeyBy));

export const update = curry((key, updater, o) =>
  isImmutable(o) ? o.update(key, updater) : lodash.update(key, updater, o)
);

export const map = curry((mapper, o) =>
  isImmutable(o) ? o.map(mapper) : lodash.map(mapper, o)
);

export const reduce = curry((reducer, acc, arr) =>
  isImmutable(arr) ? arr.reduce(reducer, acc) : lodash.reduce(arr, reducer, acc)
);

export const filter = curry((filterer, o) =>
  isImmutable(o) ? o.filter(filterer) : lodash.filter(filterer, o)
);

export const has = curry((path, o) =>
  isImmutable(o) ? o.hasIn(toArray(path)) : lodash.hasIn(path, o)
);

// FIXME curry signature with arity is missing in d.ts o_O
export const updateIfHas = (curry as any)(
  (key, updater, subject) =>
    // prettier-ignore
    has(key, subject)
      ? update(key, updater, subject)
      : subject,
  3
);

export const traverseOn = curry((key, fn, item) =>
  updateIfHas(key, map(traverseOn(key, fn)), fn(item))
);

export const uncurry = (f) => (x, y) => f(x)(y);

export const copy = curry((from, to, obj) =>
  lodash.set(to, lodash.get(from, obj), obj)
);

export const or = curry((a, b) => a || b);

export const unset = curry((path, o) =>
  isImmutable(o) ? o.deleteIn(toArray(path)) : lodash.unset(path, o)
);

export const size = sizeFp;

export const keys = (o) => (isImmutable(o) ? o.keySeq() : Object.keys(o));

export { compose, flow, pipe } from 'lodash/fp';
