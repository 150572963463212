import { useSelector } from 'react-redux';

import ProductPackshot from 'core/components/product/packshot';
import {
  FEATURE_ALL_USERLABEL_MANAGEMENT,
  hasFeature,
} from 'modules/feature-flag';
import { ProductUserLabels } from 'modules/user-label';
import { selectAssets } from 'reducers/media';
import { selectUser } from 'reducers/user/selectors';

import { BasicInformation } from '../../basic-information';
import ProductHeaderActions from '../product-header-actions';

import './product-header-elements.scss';

const ProductHeaderElements = () => {
  const assets: any = useSelector(selectAssets);
  const user = useSelector(selectUser);

  return (
    <div className="ProductHeader__header">
      <div className="ProductHeader__picture">
        <ProductPackshot assets={assets} size="128" />
      </div>
      <div className="ProductHeader__basic">
        <BasicInformation />
        {hasFeature(user, FEATURE_ALL_USERLABEL_MANAGEMENT) && (
          <ProductUserLabels />
        )}
      </div>
      <div className="ProductHeader__actions">
        <ProductHeaderActions />
      </div>
    </div>
  );
};

export default ProductHeaderElements;
