import { createSelector } from 'reselect';

import { hasFeature, hasFeatureValue } from '.';

// NOTE don't use selectUser here (creates circular dependency)
export const userSelector = (state) => ('user' in state ? state.user : state);

export const createFeatureSelector = (...flags) =>
  createSelector(userSelector, (user) => hasFeature(user, ...flags));

export const createFeatureHasValueSelector = (flag, value) =>
  createSelector(userSelector, (user) => hasFeatureValue(user, flag, value));
