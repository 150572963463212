import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { selectUser } from 'reducers/user/selectors';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';

import { cancelDataOpsPatch } from '../../actions';
import {
  isDataOpsPatcher,
  isDataOpsReceiver,
  selectHasDataOps,
} from '../../selectors';
import { DataOpsDiffStatus, DataOpsNewPatch, DataOpsPatch } from '../../types';

import { DataOpsDiffModal } from './diff-modal';
import './index.scss';

type Patch = DataOpsPatch | DataOpsNewPatch;

interface Props {
  patches?: Patch[];
  canPatch?: boolean;
  label: string;
}

export const DataOpsInfoPlugin = ({ patches, canPatch, label }: Props) => {
  const dispatch = useDispatch();
  const hasDataOps = useSelector(selectHasDataOps);
  const user: UserImmutable = useSelector(selectUser);
  const [patchForDiff, setPatchForDiff] = useState<null | Patch>(null);
  const isUserManufacturer = isDataOpsReceiver(user);
  const hasPatches = !!patches?.length;
  const hasFeature = hasDataOps && isUserManufacturer ? hasPatches : hasDataOps;

  if (!hasFeature || !hasPatches) {
    return null;
  }

  const getStatusLabel = (
    patch: Patch,
    status: DataOpsDiffStatus | null
  ): string => {
    if (isDataOpsPatcher(user)) {
      switch (status) {
        case DataOpsDiffStatus.PATCH_RESOLVED_STATUS:
          return i18n.t(
            'frontproductstream.product_collaborative_edit.field_info_retailer_view_correction_accepted_by_manufacturer.text',
            { defaultValue: 'Correction accepted by manufacturer' }
          );
        case DataOpsDiffStatus.PATCH_IGNORED_STATUS:
          return i18n.t(
            'frontproductstream.product_collaborative_edit.field_info_retailer_view_update_by_manufacturer_was_refused.text',
            { defaultValue: 'Update by manufacturer was refused' }
          );
        case DataOpsDiffStatus.PATCH_DISPUTE_STATUS:
          return i18n.t(
            'frontproductstream.product_collaborative_edit.field_info_retailer_view_update_was_made_by_manufacturer.text',
            { defaultValue: 'The manufacturer has made an update' }
          );
        case DataOpsDiffStatus.PATCH_CANCELLED_STATUS:
          return i18n.t(
            'frontproductstream.product_collaborative_edit.field_info_retailer_view_patch_should_be_cancelled.text',
            { defaultValue: 'This patch should be cancelled' }
          );
        default:
          if ((patch as DataOpsPatch).refusedAt) {
            return i18n.t(
              'frontproductstream.product_collaborative_edit.field_info_retailer_view_correction_refused_by_manufacturer.text',
              { defaultValue: 'Correction refused by manufacturer' }
            );
          }
          return i18n.t(
            'frontproductstream.product_collaborative_edit.field_info_retailer_view_pending_patch.text',
            { defaultValue: 'Pending patch' }
          );
      }
    } else if ('user' in patch) {
      const patchInfoText = i18n.t(
        'frontproductstream.product_collaborative_edit.field_info_manufacturer_view_data_patch_by_info.text',
        {
          defaultValue: 'Patched by {{user}}',
          user: patch.user.belongsTo?.[0]?.nameLegal || patch.user.username,
        }
      );
      switch (status) {
        case DataOpsDiffStatus.PATCH_PENDING_STATUS:
          return patchInfoText;
        case DataOpsDiffStatus.PATCH_IGNORED_STATUS:
        case DataOpsDiffStatus.PATCH_DISPUTE_STATUS:
          if ((patch as DataOpsPatch).ignoredAt) {
            return patchInfoText;
          }
      }
    }
    return '';
  };

  return (
    <>
      {(patches as Patch[]).map((patch, index) => {
        const patchId = 'id' in patch ? patch.id : index;
        const diffStatus =
          'diffStatus' in patch
            ? patch.diffStatus
            : DataOpsDiffStatus.PATCH_PENDING_STATUS;
        return (
          <div
            key={patchId}
            className="DataOpsInfoPlugin alk-flex alk-flex-center"
            data-testid="data-ops-info-plugin"
            data-patch-id={patchId}
          >
            <i className="mdi mdi-bandage DataOpsInfoPlugin__icon" />
            <span
              className="DataOpsInfoPlugin__label"
              data-diff-status={diffStatus}
              data-testid="data-ops-info-plugin-diff-status"
            >
              {getStatusLabel(patch, diffStatus)}
            </span>
            <Button
              className="DataOpsInfoPlugin__diffBtn"
              onClick={() => {
                setPatchForDiff(patch);
              }}
              link
              testid="data-ops-info-plugin-see-diff"
            >
              {i18n.t(
                'frontproductstream.product_collaborative_edit.field_info_see_correction.button',
                { defaultValue: 'See correction' }
              )}
            </Button>
          </div>
        );
      })}
      {patchForDiff && (
        <DataOpsDiffModal
          onClose={() => {
            setPatchForDiff(null);
          }}
          patch={patchForDiff}
          user={user}
          canPatch={canPatch}
          onCancelPatch={(patch) => {
            dispatch(cancelDataOpsPatch({ patch, label }));
          }}
        />
      )}
    </>
  );
};
