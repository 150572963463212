import { createSelector } from 'reselect';

import { getModuleState } from 'utils/modules/reducer';

import { REDUCER_KEY } from '../constants';

export const selectState = getModuleState(REDUCER_KEY);

export const selectIsAuthorized = createSelector(selectState, (state) =>
  state.get('authorized')
);
