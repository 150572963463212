import { createAction, createActionWithPayload } from 'utils/redux';

import { UserLabel } from '../types';

import {
  CANCEL_DETACH_USER_LABEL,
  CANCEL_FETCH_ALL_USER_LABELS,
  CANCEL_SAVE_USER_LABELS,
  DELETE_USER_LABEL,
  DETACH_USER_LABEL,
  DETACH_USER_LABEL_DONE,
  FETCH_ALL_USER_LABELS,
  FETCH_LINKED_USERS_LABELS,
  FETCH_LINKED_USERS_LABELS_DONE,
  RECEIVE_ALL_USER_LABELS,
  RESET_USER_LABEL_MODAL,
  SAVE_USER_LABELS,
  SAVE_USER_LABELS_DONE,
  SAVE_USER_LABELS_ERROR,
  SELECT_USER_LABEL,
  SET_USER_LABELS_CONTEXT,
  SHOW_USER_LABEL_MODAL,
  TRACK_USER_LABELS,
  UPDATE_USER_LABEL,
  USER_LABELS_CREATED,
} from './types';

export const showUserLabelModal = createAction(SHOW_USER_LABEL_MODAL);
export const resetUserLabelModal = createAction(RESET_USER_LABEL_MODAL);
export const selectUserLabel = createAction(SELECT_USER_LABEL);
export const userLabelsCreated = createAction(USER_LABELS_CREATED);
export const saveUserLabels = createAction(SAVE_USER_LABELS);
export const saveUserLabelsDone = createAction(SAVE_USER_LABELS_DONE);
export const saveUserLabelsError = createAction(SAVE_USER_LABELS_ERROR);
export const cancelSaveUserLabels = createAction(CANCEL_SAVE_USER_LABELS);
export const detachUserLabels = createAction(DETACH_USER_LABEL);
export const detachUserLabelsDone = createAction(DETACH_USER_LABEL_DONE);
export const cancelDetachUserLabels = createAction(CANCEL_DETACH_USER_LABEL);
export const fetchAllUserLabels = createAction(FETCH_ALL_USER_LABELS);
export const receiveAllUserLabels = createAction(RECEIVE_ALL_USER_LABELS);
export const cancelFetchAllUserLabels = createAction(
  CANCEL_FETCH_ALL_USER_LABELS
);
export const trackUserLabels = createAction(TRACK_USER_LABELS);
export const setUserLabelsContext = createAction(SET_USER_LABELS_CONTEXT);
export const updateUserLabel =
  createActionWithPayload<UserLabel>(UPDATE_USER_LABEL);
export const deleteUserLabel =
  createActionWithPayload<UserLabel>(DELETE_USER_LABEL);
export const fetchLinkedUserLabels = createActionWithPayload<number>(
  FETCH_LINKED_USERS_LABELS
);
export const fetchLinkedUserLabelsDone = createActionWithPayload<any>(
  FETCH_LINKED_USERS_LABELS_DONE
);
