import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Select } from '@alkem/react-ui-select';

import Modal from 'components/ui/modal';
import { selectLocalesByTargetMarket } from 'reducers/user/selectors';
import i18n from 'utils/i18n';

import { assign, reset, selectGroup, unselectgroup } from '../actions';
import {
  selectIsAssigning,
  selectIsDone,
  selectIsOpen,
  selectProductVersions,
  selectRemainingGroupOptions,
  selectSelectedGroupOptions,
} from '../selectors';

import BulkPrepareForChannelsProductInfo from './product-info';

const mapStateToProps = createStructuredSelector({
  isOpen: selectIsOpen,
  isAssigning: selectIsAssigning,
  isDone: selectIsDone,
  productVersions: selectProductVersions,
  locales: selectLocalesByTargetMarket,

  groupOptions: selectRemainingGroupOptions,
  selectedGroupOptions: selectSelectedGroupOptions,
});

const mapDispatchToProps = {
  assign,
  reset,
  selectGroup,
  unselectgroup,
};

export class BulkPrepareForChannelsModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isAssigning: PropTypes.bool.isRequired,
    isDone: PropTypes.bool.isRequired,
    productVersions: ImmutablePropTypes.map.isRequired,
    locales: ImmutablePropTypes.map.isRequired,
    groupOptions: PropTypes.array,
    selectedGroupOptions: PropTypes.array,

    assign: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    selectGroup: PropTypes.func.isRequired,
    unselectgroup: PropTypes.func.isRequired,
  };

  onAssign = () => {
    this.props.assign();
  };

  onClose = () => {
    this.props.reset();
  };

  onAddGroup = ({ id }) => {
    this.props.selectGroup(id);
  };

  onRemoveGroup = (idx) => {
    const { selectedGroupOptions } = this.props;
    const groupToRemove = selectedGroupOptions[idx];
    if (groupToRemove && groupToRemove.id) {
      this.props.unselectgroup(groupToRemove.id);
    }
  };

  renderBody() {
    const {
      isAssigning,
      isDone,
      productVersions,
      locales,
      groupOptions,
      selectedGroupOptions,
    } = this.props;

    return (
      <div>
        <div>{i18n.t('Assign the following products:')}</div>
        <ul className="BulkPrepareForChannelsModal__products">
          {productVersions.keySeq().map((productVersionId) => (
            <BulkPrepareForChannelsProductInfo
              key={productVersionId}
              productVersion={productVersions.get(productVersionId)}
              locales={locales}
            />
          ))}
        </ul>
        <div>{i18n.t('to channels:')}</div>
        <Select
          id="select-groups"
          multiple
          autoSize
          onValueAdd={this.onAddGroup}
          onValueDelete={this.onRemoveGroup}
          options={groupOptions}
          values={selectedGroupOptions}
          disabled={isAssigning || isDone}
          placeholder={i18n.t('Select...')}
        />
      </div>
    );
  }

  render() {
    const { isAssigning, isDone, isOpen } = this.props;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        title={i18n.t('Assign products to channels')}
        confirmButtonText={isDone ? i18n.t('Assigned') : i18n.t('Assign')}
        confirmDisabled={isDone}
        isProcessing={isAssigning}
        onConfirm={this.onAssign}
        closeButtonText={i18n.t('Close')}
        onClose={this.onClose}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkPrepareForChannelsModal);
