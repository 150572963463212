import { WorkflowSharingStatuses } from 'constants/workflowSharingStatus';
import { isMaker } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_REPORTING_SHARING_STATUS_FILTER,
  FEATURE_SHARING_STATUS_FILTER,
} from 'modules/feature-flag/constants';
import { i18n } from 'utils/i18n';

export const workflowSharingStatusFilter = {
  key: 'filter_recipient_sharing_status_in',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_workflow_sharing_status.label',
    { defaultValue: 'Sharing status' }
  ),
  query: 'NOT_USED',
  isAvailable: ({ user } = {}) =>
    isMaker(user) &&
    hasFeature(user, FEATURE_SHARING_STATUS_FILTER) &&
    hasFeature(user, FEATURE_REPORTING_SHARING_STATUS_FILTER),
  workflowSharingStatuses: [
    WorkflowSharingStatuses.DRAFT,
    WorkflowSharingStatuses.EXPECTED,
    WorkflowSharingStatuses.RECIPIENT_ADDED,
    WorkflowSharingStatuses.BLOCKED,
    WorkflowSharingStatuses.SHARED,
  ],
};
