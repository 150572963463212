import classNames from 'classnames';
import { isFinite } from 'lodash';
import { connect } from 'react-redux';

import { Select } from '@alkem/react-ui-select';

import Field from 'components/ui/form/field';

import './select.scss';

const mapStateToProps = () => ({});

class FormSelect extends Field {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeLocal = this.handleChangeLocal.bind(this);
    this.handleDeleteLocal = this.handleDeleteLocal.bind(this);
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  handleChangeLocal(item) {
    return this.handleChange({ item, ...item });
  }

  handleDeleteLocal() {
    return this.handleChange(
      null, // value
      true, // isDirty
      false // ignoreFields
    );
  }

  getValue(item) {
    const { onlyValue } = this.props;
    if (onlyValue) {
      return item ? item.value : undefined;
    }
    return item;
  }

  render() {
    const { field, onlyValue, value } = this.props;
    if (!field) {
      return null;
    }

    if (field.declinableBy && field.declinableBy.kind === 'languages') {
      return null;
    }

    const placeholder = field.placeholder || field.label;
    const options = field.inputKind.values || [];

    let localValue = value;
    if (isFinite(Number(value)) && onlyValue) {
      localValue = options.find((option) => option.value === value);
    }
    if (localValue && !Array.isArray(localValue)) {
      localValue = [localValue];
    }
    localValue = localValue || [];

    const extraOptions = field.options;
    const renderedLabel = this.renderLabel('col-xs-4');
    const autocompleteClasses = `InputField__input FormSelect__input ${
      renderedLabel ? 'col-xs-8' : 'col-xs-12'
    }`;

    return (
      <div>
        <div
          className={classNames(this.getClasses({ 'FormSelect row': true }))}
        >
          {renderedLabel}
          <span className={classNames(autocompleteClasses)}>
            <Select
              id={this.getId()}
              onValueAdd={this.handleChangeLocal}
              onValueDelete={this.handleDeleteLocal}
              values={localValue}
              placeholder={placeholder}
              options={options}
              extraOptions={extraOptions}
              disabled={this.isReadOnly()}
              inputable={!this.getOptionValue('noInput') === true}
            />
            {this.renderPlugins()}
          </span>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormSelect);
