import PropTypes from 'prop-types';
import { useCallback } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useDispatch, useSelector } from 'react-redux';

import ProductReference from 'components/ui/product-reference';
import { TAG_EXPORTABLE } from 'constants/tags';
import { hasSingleExportableGtin } from 'core/api/organization-settings';
import { search } from 'modules/catalog/product/actions';
import { selectOrganizationSettings } from 'reducers/user/selectors';

export default function CatalogGtinColumn({ data, productVersion }) {
  const organizationSettings = useSelector(selectOrganizationSettings);

  const singleExportableGTIN = hasSingleExportableGtin(organizationSettings);

  const iconDisplayable = productVersion.get('hasDuplicated');
  const dispatch = useDispatch();
  const onClickIcon = useCallback(() => {
    dispatch(search(data));
  }, [dispatch, data]);

  const productVersionId = productVersion.get('id');

  const isExportable = productVersion
    .get('isTaggedBy', [])
    .some((tag) => tag === TAG_EXPORTABLE);
  return (
    <ProductReference
      reference={data}
      versionId={productVersionId}
      copy
      iconDisplayable={iconDisplayable}
      isExportable={isExportable}
      hasSingleExportableGTIN={singleExportableGTIN}
      onClick={onClickIcon}
      hasSingleExportableGTI
    />
  );
}

CatalogGtinColumn.className = 'CatalogGtinColumn';

CatalogGtinColumn.propTypes = {
  data: PropTypes.string,
  productVersion: ImmutablePropTypes.map,
};
