import { notificationError, notificationSuccess } from 'actions/notification';
import segmentHierarchyApi from 'resources/segmentHierarchyApi';
import i18n from 'utils/i18n';

export const classifyProducts =
  (
    productIds,
    productKeyIds,
    selectedSegment,
    successCallback,
    errorCallback
  ) =>
  (dispatch) =>
    segmentHierarchyApi
      .attachProducts({
        productSegmentId: selectedSegment.id,
        productIds,
        productKeyIds,
      })
      .then(
        () => {
          dispatch(
            notificationSuccess(
              i18n.t(
                '{{length}} products have been classified in {{segment}}.',
                {
                  length: productKeyIds.length,
                  segment: selectedSegment.name,
                }
              )
            )
          );
          if (successCallback) {
            successCallback(productIds, productKeyIds, selectedSegment.id);
          }
        },
        () => {
          dispatch(
            notificationError(
              i18n.t(
                'Error, products have not been classified in {{segment}}.',
                {
                  segment: selectedSegment.name,
                }
              ),
              { context: 'modal' }
            )
          );
          if (errorCallback) {
            errorCallback();
          }
        }
      );

export const unclassifyProducts =
  (productIds, productKeyIds, successCallback, errorCallback) => (dispatch) =>
    segmentHierarchyApi.detachProducts({ productIds, productKeyIds }).then(
      () => {
        dispatch(
          notificationSuccess(
            i18n.t('{{length}} products have been unclassified.', {
              length: productKeyIds.length,
            })
          )
        );
        if (successCallback) {
          successCallback(productIds, null);
        }
      },
      () => {
        dispatch(
          notificationError(
            i18n.t('Error, products have not been unclassified properly.'),
            { context: 'modal' }
          )
        );
        if (errorCallback) {
          errorCallback();
        }
      }
    );
