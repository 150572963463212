import processString from 'react-process-string';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';

import Modal from 'components/ui/modal';
import { selectOrganizationId } from 'reducers/user/selectors';
import i18n from 'utils/i18n';

import { closeModal, sendMessageStart } from '../actions';
import { selectInProgress, selectMessagesResult } from '../selectors';

import './index.scss';

export const ChatSentModal = () => {
  const dispatch = useDispatch();
  const inProgress = useSelector(selectInProgress);
  const retailerId = useSelector(selectOrganizationId);
  const messagesResults = useSelector(selectMessagesResult);
  const failedMessages = messagesResults.filter(
    (messageResult) => messageResult.hasError
  );

  const onClose = () => {
    dispatch(closeModal());
  };

  const onRetry = () => {
    const messagesToSend = failedMessages.map((failedMessage) => {
      return {
        retailerId: retailerId,
        recipientId: failedMessage.entityId,
        recipientName: failedMessage.recipientName,
        message: failedMessage.message,
      };
    });
    dispatch(sendMessageStart(messagesToSend));
  };

  const renderGlobalError = () => {
    return (
      <div>
        <ul className="InformationRequests__errorEntities">
          {failedMessages.length < messagesResults.length
            ? i18n.t(
                'If the error is persisting, please contact the support. Messages in success have been properly sent to their recipients.'
              )
            : i18n.t('If the error is persisting, please contact the support.')}
        </ul>
      </div>
    );
  };

  const renderFail = (recipientName: string, errorMessage: string) => {
    return (
      <>
        <i className="mdi mdi-cancel alk-txt-danger alk-margin-right--small"></i>
        {processString([
          {
            regex: /__OrganizationName__/,
            fn: () => (
              <span
                key={`name-${recipientName}`}
                className="InformationRequests__supplierManuf"
              >
                {`${recipientName}`}
              </span>
            ),
          },
          {
            regex: /__ErrorMessage__/,
            fn: () => (
              <span
                key={`error-message-${recipientName}`}
                className="InformationRequests__supplierMessage"
              >
                {errorMessage}
              </span>
            ),
          },
        ])(
          i18n.t(
            'Failed to send message to Alkemics organization {{orga}} with error: {{error}}.',
            {
              orga: '__OrganizationName__',
              error: '__ErrorMessage__',
            }
          )
        )}
      </>
    );
  };

  const renderSuccess = (recipientName: string) => {
    return (
      <>
        <i className="mdi mdi-check-circle alk-txt-success alk-margin-right--small"></i>
        {processString([
          {
            regex: /__OrganizationName__/,
            fn: () => (
              <span
                key={`name-${recipientName}`}
                className="InformationRequests__supplierManuf"
              >
                {`${recipientName}`}
              </span>
            ),
          },
        ])(
          i18n.t(
            'Message successfully sent to Alkemics organization {{orga}}.',
            {
              orga: '__OrganizationName__',
            }
          )
        )}
      </>
    );
  };

  return (
    <Modal
      modalStyle="dynamic"
      title={i18n.t('Sending your message')}
      className="InformationRequestModal"
      confirmButtonText={i18n.t('Close')}
      onConfirm={onClose}
      secondaryAction={
        failedMessages.length > 0 ? [i18n.t('Retry'), onRetry] : undefined
      }
    >
      {inProgress ? (
        <div className="alk-flex alk-flex-v-center">
          <Spinner />
          <div>{i18n.t('Loading')}</div>
        </div>
      ) : (
        <div>
          {messagesResults.map((messageResult) => (
            <div key={messageResult.entityId}>
              {messageResult.hasError
                ? renderFail(
                    messageResult.recipientName,
                    messageResult.resultMessage
                  )
                : renderSuccess(messageResult.recipientName)}
            </div>
          ))}
          {failedMessages.length > 0 ? renderGlobalError() : null}
        </div>
      )}
    </Modal>
  );
};
