import { Map } from 'immutable';
import { createSelector } from 'reselect';

const selectModuleState = (state) => state.module.retailerSharingUnits;

export const selectSharingUnitsInit = createSelector(
  selectModuleState,
  (sharingUnitsByStatus) =>
    sharingUnitsByStatus.getIn(['sharingunitsByStatus', 'INIT'])
);

export const selectSharingUnitsAgreed = createSelector(
  selectModuleState,
  (sharingUnitsByStatus) =>
    sharingUnitsByStatus.getIn(['sharingunitsByStatus', 'AGREED'])
);

export const selectSharingUnitsRefused = createSelector(
  selectModuleState,
  (sharingUnitsByStatus) =>
    sharingUnitsByStatus.getIn(['sharingunitsByStatus', 'REFUSED'])
);

export const selectSharingunitsMap = createSelector(
  selectModuleState,
  (sharingUnits) => (sharingUnits ? sharingUnits.get('sharingunitsMap') : Map())
);

export const selectSharingunitsList = createSelector(
  selectSharingunitsMap,
  (sharingUnits) => sharingUnits.toList()
);

export const selectSharingUnitsLoading = createSelector(
  selectModuleState,
  (sharingUnits) => sharingUnits.get('loading')
);

export const selectSharingUnitHierarchyIds = createSelector(
  selectSharingunitsMap,
  (sharingUnits) =>
    sharingUnits
      .valueSeq()
      .map((sharingUnit) =>
        sharingUnit.getIn(['data', 'hierarchyProduct', 'id'])
      )
      .filter((e) => e)
      .toJS()
);
