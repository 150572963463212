import { List, Map } from 'immutable';
import { compose, curry } from 'lodash/fp';

import { NETWORK_PRODUCTION } from 'constants/networks';
import { SETTING_ENABLE_VALUE } from 'constants/organization-settings';
import { SOURCE_ALKEMICS } from 'constants/organization-source';
import { AlkemicsOrganizationsIds } from 'constants/organizations';
import {
  PRODUCT_UPDATE,
  PRODUCT_VALIDATE,
  RFP_USE,
} from 'constants/permissions';
import {
  MANUFACTURER_TYPE,
  PRIVATE_LABEL,
  RETAILER_TYPE,
  THIRD_PARTY_TYPE,
} from 'constants/types';
import {
  TYPE_ALK_SERVICE,
  TYPE_ALK_SUPER_ADMIN,
  TYPE_PLATFORM_ADMIN,
  TYPE_SUPPORT,
  USER_ACCEPTED_STATUS,
  USER_PENDING_STATUS,
  USER_REJECTED_STATUS,
  USER_ROLE_ADMIN,
  USER_ROLE_LOCAL_ADMIN,
  USER_ROLE_USER,
  USER_STATUSES,
} from 'constants/user';
import {
  getOrganizationFields,
  getOrganizationMainCurrency,
} from 'core/api/organization';
import { hasFeatureValue } from 'modules/feature-flag';
import { FEATURE_DISALLOW_PRODUCT_CREATION } from 'modules/feature-flag/constants';
import { hasHierarchyFeature } from 'modules/feature-flag/permissions';
import { i18n } from 'utils/i18n';
import { get } from 'utils/immutable';

// user

export const getUserId = (user) => get(user, 'id');

export const hasId = (user) => user && getUserId(user) > 0;

export const isDisabled = (user) => get(user, 'status') === 0;

export const getAcceptedStatus = (user) =>
  get(user, ['belongsTo', 0, 'relation_status']);

export const getUserStatus = (user) => {
  switch (getAcceptedStatus(user)) {
    case USER_REJECTED_STATUS:
      return {
        code: 'rejected',
        label: i18n.t('frontproductstream.core.user.rejected_status', {
          defaultValue: 'Rejected',
        }),
      };
    case USER_PENDING_STATUS:
      return {
        code: 'pending',
        label: i18n.t('frontproductstream.core.user.pending_status', {
          defaultValue: 'Pending acceptation',
        }),
      };
    case USER_ACCEPTED_STATUS:
    default:
      return USER_STATUSES.find(({ id }) => user.status === id);
  }
};

export const isUserAccepted = (user) =>
  getAcceptedStatus(user) === USER_ACCEPTED_STATUS;

export const getUserSettings = (user) => get(user, ['settings']);

export const getUserSetting = curry((setting, user) =>
  get(getUserSettings(user), [setting])
);

export const getIsScopedFor = (user) => user.get('isScopedFor', List());

// organization

export const getOrganization = (user) => get(user, ['belongsTo', 0]);

export const hasOrganization = (user) =>
  !!(getOrganization(user) && getOrganization(user).size > 0);

export const getOrganizationId = (user): number =>
  getOrganization(user) && get(getOrganization(user), 'id');

export const getOrganizationUuid = (user) =>
  getOrganization(user) && getOrganization(user).get('uuid');

export const getOrganizationNetworkId = (user) =>
  getOrganization(user) && getOrganization(user).getIn(['network', 'id']);

export const isUserOnProductionNetwork = (user) =>
  getOrganizationNetworkId(user) === NETWORK_PRODUCTION;

export const getOrganizationPermissions = (user) =>
  getOrganization(user) && get(getOrganization(user), 'permissions');

export const getOrganizationPreferences = (user) =>
  getOrganization(user) && getOrganization(user).get('preferences');

export const getWorksOnTargetMarkets = (user) =>
  (getOrganization(user) &&
    getOrganization(user).get('worksOnTargetMarkets')) ||
  List();

export const getOrganizationLegalIdentifiers = (user) =>
  (getOrganization(user) && getOrganization(user).get('legalIdentifiers')) ||
  List();

export const filterOrganizationTargetMarkets = (
  organizationId,
  isScopedFor,
  worksOnTargetMarkets
) => {
  const userScope =
    isScopedFor
      .filter((s) => s.get('organization_id') === organizationId)
      .first() || Map();
  const userTMIds = userScope
    .get('targetMarkets', List())
    .map((tm) => tm.get('id'));
  return worksOnTargetMarkets.filter((tm) => userTMIds.includes(tm.get('id')));
};

export const getOrganizationTargetMarkets = (user) => {
  const organizationId = getOrganizationId(user);
  const isScopedFor = getIsScopedFor(user);
  const worksOnTargetMarkets = getWorksOnTargetMarkets(user);
  return filterOrganizationTargetMarkets(
    organizationId,
    isScopedFor,
    worksOnTargetMarkets
  );
};

export const getOrganizationTargetMarketIds = (user) =>
  getOrganizationTargetMarkets(user)
    .map((targetmarket) => targetmarket.get('id'))
    .toSet();

export const getAllOrganizationTargetMarketIds = (user) =>
  getWorksOnTargetMarkets(user)
    .map((targetmarket) => targetmarket.get('id'))
    .toSet();

export const getLegalOrganizationName = (user) =>
  getOrganization(user) && getOrganization(user).get('nameLegal');

export const getOrganizationType = (user) =>
  getOrganization(user) && get(getOrganization(user), 'type');

export const getSupportedLocales = (user) =>
  (getOrganization(user) && getOrganization(user).get('supportsLocales')) ||
  List();

export const getLocalesByTargetMarket = (user) =>
  (getOrganization(user) &&
    getOrganization(user).get('localesByTargetMarket')) ||
  List();

export const getOrganizationSettings = (user) =>
  get(getOrganization(user), 'settings') || Map();

export const getOrganizationSettingByKey = (user, key) =>
  getOrganizationSettings(user).get(key);

export const getOrganizationSource = compose(
  (org = Map()) => org.get('source', SOURCE_ALKEMICS),
  getOrganization
);

// user type

export function isRetailer(user) {
  return getOrganizationType(user) === RETAILER_TYPE;
}

export function isManufacturer(user) {
  return getOrganizationType(user) === MANUFACTURER_TYPE;
}

export function isPrivateLabel(user) {
  return getOrganizationType(user) === PRIVATE_LABEL;
}

export function isThirdParty(user) {
  return getOrganizationType(user) === THIRD_PARTY_TYPE;
}

export function isNotThirdParty(user) {
  return !isThirdParty(user);
}

export const getUserOrganizationType = (user) => {
  if (isRetailer(user)) {
    return 'Retailer';
  }
  if (isThirdParty(user)) {
    return 'Third-Party';
  }
  return 'Maker';
};

export function isMaker(user) {
  return getUserOrganizationType(user) === 'Maker';
}

export function isUserAlkemics(user) {
  const orgId = getOrganizationId(user);
  return AlkemicsOrganizationsIds.includes(orgId);
}

// permissions

export const getRole = (user) =>
  get(getOrganizationPermissions(user), ['0']) || USER_ROLE_USER;

export const setRole = curry((role, user) =>
  user.setIn(['belongsTo', 0, 'permissions', 0], role)
);

export const getPermissions = (user) => get(user, 'permissions') || [];

export const setPermissions = curry((permissions, user) => {
  return user.setIn(['permissions'], permissions);
});

export const isAdmin = (user) => getRole(user) === USER_ROLE_ADMIN;
export const hasAdminPermission = (user) =>
  getPermissions(user).includes(USER_ROLE_ADMIN);

export const managesOrganization = (user) =>
  [USER_ROLE_ADMIN, USER_ROLE_LOCAL_ADMIN].includes(getRole(user));

export const getUserType = (user) => user.get('type');

export const isSuperAdmin = (user) =>
  [TYPE_ALK_SERVICE, TYPE_ALK_SUPER_ADMIN].includes(getUserType(user));

export const isPlatformAdmin = (user) =>
  getUserType(user) === TYPE_PLATFORM_ADMIN;

export const isSupport = (user) => getUserType(user) === TYPE_SUPPORT;

export const isAtLeastPlatformAdmin = (user) =>
  isPlatformAdmin(user) || isSuperAdmin(user);

export const isAtLeastSupport = (user) =>
  isSupport(user) || isAtLeastPlatformAdmin(user);

export const getUserTypeLoggedAs = (user) =>
  user.getIn(['loggedAs', 'adminType']);

export const isSuperAdminLoggedAs = (user) =>
  getUserTypeLoggedAs(user) === TYPE_ALK_SUPER_ADMIN;

export const isPlatformAdminLoggedAs = (user) =>
  getUserTypeLoggedAs(user) === TYPE_PLATFORM_ADMIN;

export const isSupportLoggedAs = (user) =>
  getUserTypeLoggedAs(user) === TYPE_SUPPORT;

export const isAtLeastPlatformAdminLoggedAs = (user) =>
  isPlatformAdminLoggedAs(user) || isSuperAdminLoggedAs(user);

export const isAtLeastSupportLoggedAs = (user) =>
  isSupportLoggedAs(user) || isAtLeastPlatformAdminLoggedAs(user);

export const isLoggedAs = (user) => !!user.getIn(['loggedAs', 'adminId']);

export const loggedAsOrganizationPermissions = (user) =>
  user.getIn(['loggedAs', 'organizationPermissions'], []);

export const hasProductPermission = (permission) => (user) =>
  (user.getIn(['permissions', 'productversion']) || List()).some((segment) =>
    (get(segment, 'permissions') || List()).includes(permission)
  ) || !hasHierarchyFeature(user);

export const hasAnyProductUpdatePermission =
  hasProductPermission(PRODUCT_UPDATE);

export const hasAnyProductValidatePermission =
  hasProductPermission(PRODUCT_VALIDATE);

export const userHasRfp = (user) => {
  return user.getIn(['organizationPermissions'], []).includes(RFP_USE);
};

// misc

export const displayTargetMarket = (user) => user.get('id') === 1382;

export const getMyFields = (user) =>
  getOrganizationFields(getOrganization(user));

export const getMyMainCurrency = (user) =>
  getOrganizationMainCurrency(getOrganization(user));

export const isSsoUser = (user) => !!user.get('sso');

export const hasProductCreation = (user) =>
  !hasFeatureValue(
    user,
    FEATURE_DISALLOW_PRODUCT_CREATION,
    SETTING_ENABLE_VALUE
  );

export const canCreateProduct = (user) =>
  hasProductCreation(user) && hasAnyProductUpdatePermission(user);

export const canValidateProduct = (user) =>
  hasAnyProductValidatePermission(user);
