import { curry, filter, flow, identity, mapValues, values } from 'lodash/fp';

import { Diff } from 'types/diff';

type DiffOrNull = Diff | null;

const groupDiffs = curry(
  (
    diffList: Diff[],
    diffType: 'old' | 'new',
    diffByModel: { [key: string]: [DiffOrNull, DiffOrNull] }
  ) => {
    const dict = diffByModel;
    for (const diff of diffList) {
      const diffKey =
        `${diff.model}-` +
        (Array.isArray(diff.label) ? diff.label.join() : diff.label);
      if (!dict[diffKey]) {
        dict[diffKey] = [null, null];
      }
      dict[diffKey][diffType === 'old' ? 0 : 1] = diff;
    }
    return dict;
  }
);

export const mergeDiffs = flow<
  [Diff[], Diff[]],
  { [key: string]: [DiffOrNull, DiffOrNull] },
  { [key: string]: DiffOrNull },
  DiffOrNull[],
  Diff[]
>(
  // group by uniq model key
  (diffListOld, diffListNew) =>
    flow(groupDiffs(diffListOld, 'old'), groupDiffs(diffListNew, 'new'))({}),
  // merge diff list assigned to model key
  mapValues(([diffOld, diffNew]) => {
    let diffMerge: DiffOrNull = null;
    if (diffOld && diffNew) {
      diffMerge = { ...diffOld, current: diffNew.old };
    } else if (!diffOld && diffNew) {
      diffMerge = { ...diffNew, current: diffNew.old, old: diffNew.new };
    } else if (diffOld && !diffNew) {
      diffMerge = { ...diffOld, current: diffOld.new };
    }
    return diffMerge;
  }),
  // transform dict to array of values
  values,
  // filter invalid diffs (it should not happen)
  filter(identity)
);
