import { call, put, putResolve, select, takeEvery } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError, notificationSuccess } from 'actions/notification';
import { fetchRetailerProductVersionByKeyId } from 'actions/productversion';
import { FEATURE_PERMISSIONS_V3_PRODUCT } from 'modules/feature-flag/constants';
import { CHAT_PERMISSION, PRODUCT_PERMISSION } from 'modules/permissions/const';
import { selectPermissions } from 'modules/permissions/selectors';
import {
  hasInstancePermissions,
  hasPermissionV3,
} from 'modules/permissions/utils';
import { selectProductId, selectProductKeyId } from 'reducers/productVersion';
import { selectUser } from 'reducers/user/selectors';
import {
  acceptTransactions,
  refuseTransactions,
} from 'resources/serviceProductApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { track } from 'utils/tracking';

import { sendMessage } from './actions';
import {
  ACCEPT_CONSUMER_UNIT,
  ACCEPT_LISTING_INIT,
  ACCEPT_LISTING_UPDATES,
  REFUSE_CONSUMER_UNIT,
  REFUSE_LISTING_UPDATES,
  RETAILER_REQUESTS_TOGGLE_PROGRESS,
} from './constants';
import { selectTransactionIds } from './selectors';

export const toggleApplyProgress = (inProgress) => ({
  type: RETAILER_REQUESTS_TOGGLE_PROGRESS,
  inProgress,
});

export default function* productShareDiffMainSaga() {
  yield takeEvery(ACCEPT_CONSUMER_UNIT, acceptConsumerUnitSaga);
  yield takeEvery(REFUSE_CONSUMER_UNIT, refuseConsumerUnitSaga);
  yield takeEvery(ACCEPT_LISTING_UPDATES, acceptListingUpdatesSaga);
  yield takeEvery(REFUSE_LISTING_UPDATES, refuseListingUpdatesSaga);
  yield takeEvery(ACCEPT_LISTING_INIT, acceptListingInitSaga);
}

/**
 * Accept or refuse requests
 * For now it can be either or both:
 *  - a sharing unit (accept the sharing unit + referenced hierarchy).
 *  - consumer unit updates.
 *  - for accept only, a sharing unit + referenced hierarchy + consumer unit requests.
 * */
export function* applyRequests({
  accept,
  consumerUnit,
  sharingUnitId,
  message,
}) {
  const productKeyId = yield select(selectProductKeyId);
  try {
    yield put(startLoading());
    yield put(toggleApplyProgress(true));
    const user = yield select(selectUser);
    const permissions = yield select(selectPermissions);
    const productId = yield select(selectProductId);
    const transactionIds = yield select(
      selectTransactionIds(consumerUnit, sharingUnitId)
    );
    if (accept) {
      yield call(acceptTransactions, productKeyId, transactionIds);
    } else {
      yield call(refuseTransactions, productKeyId, transactionIds);

      if (
        !hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT) ||
        hasInstancePermissions(
          permissions,
          PRODUCT_PERMISSION,
          productKeyId,
          CHAT_PERMISSION
        )
      ) {
        yield put(sendMessage(message));
      }
    }

    yield call(track, {
      category: 'product',
      action: accept ? 'requests_accept' : 'requests_refuse',
      label: `product#${productId}`,
    });
    yield put(
      notificationSuccess(
        accept
          ? i18n.t('Requests have been accepted')
          : i18n.t('Requests have been refused')
      )
    );
  } catch (err) {
    logError(err);
    if (err.status === 409) {
      yield put(
        notificationError(
          i18n.t(
            'The manufacturer made some changes in the meantime, please review.'
          )
        )
      );
    } else {
      yield put(
        notificationError(
          accept
            ? i18n.t('Could not accept requests')
            : i18n.t('Could not refuse requests')
        )
      );
    }
  } finally {
    yield put(stopLoading());
    yield putResolve(fetchRetailerProductVersionByKeyId(productKeyId));
    yield put(toggleApplyProgress(false));
  }
}

export function* acceptConsumerUnitSaga() {
  yield call(applyRequests, {
    accept: true,
    consumerUnit: true,
    sharingUnitId: null,
    message: null,
  });
}

export function* refuseConsumerUnitSaga({ payload: { message } }) {
  yield call(applyRequests, {
    accept: false,
    consumerUnit: true,
    sharingUnitId: null,
    message,
  });
}

export function* acceptListingUpdatesSaga({ payload: { sharingUnitId } }) {
  yield call(applyRequests, {
    accept: true,
    consumerUnit: false,
    sharingUnitId,
    message: null,
  });
}

export function* refuseListingUpdatesSaga({
  payload: { sharingUnitId, message },
}) {
  yield call(applyRequests, {
    accept: false,
    consumerUnit: false,
    sharingUnitId,
    message,
  });
}

export function* acceptListingInitSaga({ payload: { sharingUnitId } }) {
  yield call(applyRequests, {
    accept: true,
    consumerUnit: true,
    sharingUnitId,
    message: null,
  });
}
