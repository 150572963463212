import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';

import Tipster from 'components/ui/tipster';
import i18n from 'utils/i18n';

import CollapsiblePageSection from '../../../../../../components/ui/collapsible/collapsible-page-section';
import {
  productAdminGetAuditDatas,
  productAdminStartAudit,
} from '../../actions';
import { selectAudits, selectIsStartingAudit } from '../../selectors';
import type { Audit as AuditType } from '../../types';

import { Audit } from './audit';
import './audit-section.scss';

export const AuditSection = () => {
  const params = useParams<{ productKeyId: string }>();
  const productKeyId = params.productKeyId as string;

  const dispatch = useDispatch();

  const isStartingAudit: boolean = useSelector(selectIsStartingAudit);
  const audits: AuditType[] = useSelector(selectAudits);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const startAudit = () => {
    dispatch(productAdminStartAudit(+productKeyId));
  };

  const getAuditDatas = () => {
    dispatch(productAdminGetAuditDatas(+productKeyId));
  };

  const toggleCollapsed = () => {
    const newIsCollapsed = !isCollapsed;
    setIsCollapsed(newIsCollapsed);
    if (!newIsCollapsed) {
      getAuditDatas();
    }
  };

  return (
    <CollapsiblePageSection
      className="ProductAdminAudit"
      title={i18n.t('frontproductstream.product_admin.audit.title', {
        defaultValue: 'Validation Audit',
      })}
      collapsed={isCollapsed}
      toggle={toggleCollapsed}
    >
      <Tipster
        info={i18n.t('frontproductstream.product_admin.get_audit_data.info', {
          defaultValue:
            "Audit Product allows you to launch a 30 minutes audit on this product. Contexts of the actions you'll " +
            'do will be retrieved and accessible here. \nWarning: audits can be harmful to the platform. ' +
            'Do not hesitate to contact Kalitey team to get further advices.',
        })}
        type="information"
      />
      <div className="alk-flex alk-flex-margin-horizontal alk-flex-column">
        <Button
          id="product-admin-audit-button"
          testid="audit-button"
          className="Audit_Button alk-flex-align-self-end"
          primary
          content={i18n.t(
            'frontproductstream.product_admin.create_audit.button',
            { defaultValue: 'Audit product' }
          )}
          disabled={isStartingAudit}
          displaySpinner={isStartingAudit}
          onClick={startAudit}
        />
      </div>
      {audits.map((audit) => (
        <Audit
          key={'key-audit-context-' + audit.id}
          audit={audit}
          productKeyId={productKeyId}
        />
      ))}
    </CollapsiblePageSection>
  );
};
