import { List } from 'immutable';
import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { get } from 'utils/fp';

import { ROOMS_PER_PAGE } from '../constants';
import { MODULE_NAME } from '../shared';
import { onSelectRoom } from '../utils';

const createLocalSelector = (path) =>
  createSelector(get([MODULE_REDUCER_KEY, MODULE_NAME, 'room']), get(path));

const getLocalRooms = createLocalSelector('localRooms');
const getRemoteRooms = createLocalSelector('remoteRooms');
const getSelectedRoomId = createLocalSelector('selectedRoomId');
const getMessagesByRoomId = createLocalSelector('messagesByRoomId');
const getTotalRemoteRooms = createLocalSelector('totalRemoteRooms');
const getCurrentRoomPage = createLocalSelector('currentRoomPage');
const getPendingRequests = createLocalSelector('pendingRequests');
const getFetchedAhead = createLocalSelector('fetchedAhead');

const selectHasNoMoreMessages = createLocalSelector('noMoreMessages');

const getIsFetchingRooms = createSelector(
  getPendingRequests,
  (roomRequests) => roomRequests > 0
);

const getMergedRoomsAsList = createSelector(
  getLocalRooms,
  getRemoteRooms,
  getSelectedRoomId,
  getCurrentRoomPage,
  (localRooms, remoteRooms, selectedRoomId, page) =>
    onSelectRoom(selectedRoomId, localRooms.merge(remoteRooms))
      .toList()
      .skip((page - 1) * ROOMS_PER_PAGE)
      .take(ROOMS_PER_PAGE)
);

const getMessagesForSelectedRoom = createSelector(
  getSelectedRoomId,
  getMessagesByRoomId,
  (selectedRoomId, messagesByRoomId) =>
    messagesByRoomId.get(selectedRoomId) || List()
);

const getTotalRoomCount = createSelector(
  getLocalRooms,
  getTotalRemoteRooms,
  (localRooms, totalRemoteRooms) => localRooms.size + totalRemoteRooms
);

const selectByRoomId = (selectedRoomId) => (room) =>
  room.get('id') === selectedRoomId;

const getSelectedRoom = createSelector(
  getSelectedRoomId,
  getLocalRooms,
  getRemoteRooms,
  (roomId, localRooms, remoteRooms) =>
    // IDEA is it not better to store all rooms in one place?
    localRooms.find(selectByRoomId(roomId)) ||
    remoteRooms.find(selectByRoomId(roomId))
);

const getAllRoomsById = createSelector(
  getLocalRooms,
  getRemoteRooms,
  (localRooms, remoteRooms) => localRooms.merge(remoteRooms)
);

export {
  getLocalRooms,
  getRemoteRooms,
  getSelectedRoomId,
  getMergedRoomsAsList,
  getMessagesForSelectedRoom,
  getTotalRoomCount,
  getCurrentRoomPage,
  getSelectedRoom,
  getAllRoomsById,
  getIsFetchingRooms,
  getPendingRequests,
  getTotalRemoteRooms,
  getFetchedAhead,
  selectHasNoMoreMessages,
};
