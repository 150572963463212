import { fromJS } from 'immutable';
import { createLogger } from 'redux-logger';

import { isDevelopment } from 'utils';
import { reload } from 'utils/location';

import blacklist from './blacklist';

if (isDevelopment) {
  global.logger = {
    // default values
    collapsed: true,
    // loaded from local store
    ...JSON.parse(localStorage.getItem('logger')),
    // show help
    help: `logger.enable(isActive) => enable logger for logging (needs reload)
logger.isActive => log actions to console, default: false
logger.diff => add state diff (slow!), default: false
logger.collapsed => collapse logged actions, default: true
logger.persist() => save settings to local storage
logger.reset() => remove persisted settings, disable logger
logger.setFilter(string) => filter actions by type
`,
    // methods to persist settings
    enable: (isActive) => {
      const { help, enable, persist, reset, ...data } = global.logger;
      localStorage.setItem(
        'logger',
        JSON.stringify({ ...data, isActive, enabled: true })
      );
      reload();
    },
    persist: () => {
      const { persist, ...data } = global.logger;
      localStorage.setItem('logger', JSON.stringify(data));
    },
    reset: () => {
      localStorage.removeItem('logger');
    },
    setFilter: (filter) => {
      global.logger.filter = filter;
      localStorage.setItem('logger', JSON.stringify(global.logger));
    },
  };
}

export const { logger } = global;

const options = {
  // see https://github.com/evgenyrodionov/redux-logger#options for all options
  predicate: (_, action) => {
    if (logger.isActive) {
      if (logger.filter) {
        return action.type.toLowerCase().includes(logger.filter.toLowerCase());
      }
      return !blacklist.includes(action.type);
    }
    return false;
  },
  stateTransformer: (state) => fromJS(state).toJS(),
  actionTransformer: (action) => fromJS(action).toJS(),
  collapsed: logger.collapsed,
  diff: logger.diff,
};

export default createLogger(options);
