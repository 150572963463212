import { createReducer } from 'redux-create-reducer';

import { SET_ASSIGNATION_INFO, SET_LOADING, SET_PROCESSING } from './constants';

const initialState = {
  loading: false,
  processing: false,
  assignation: {
    reference: null,
    targetMarketId: null,
    informationRequestsOnly: false,
    fromCatalog: false,
  },
};

export default createReducer(initialState, {
  [SET_PROCESSING]: (state, { payload: { processing } }) => ({
    ...state,
    processing,
  }),
  [SET_LOADING]: (state, { payload: { loading } }) => ({
    ...state,
    loading,
  }),
  [SET_ASSIGNATION_INFO]: (
    state,
    {
      payload: {
        reference,
        targetMarketId,
        informationRequestsOnly,
        fromCatalog,
      },
    }
  ) => ({
    ...state,
    assignation: {
      reference,
      targetMarketId,
      informationRequestsOnly,
      fromCatalog,
    },
  }),
});
