import { Notification } from 'components/ui/notification';
import i18n from 'utils/i18n';

import { CollapsibleHierarchyProps } from './types';

interface Props extends CollapsibleHierarchyProps {
  error: Error;
}

const renderValue = (label: string, value: any) =>
  value ? (
    <li>
      {label}: {value}
    </li>
  ) : null;

export function CollapsibleHierarchyError({
  dataMap,
  rootInternalId,
  error,
}: Props) {
  const unit = dataMap?.[rootInternalId] || {};
  const { id, gtin, patch_id } = unit;
  const hasInfo = [id, gtin, patch_id].some((info) => info);
  const idLabel = i18n.t(
    'frontproductstream.collapsible_hierarchies.id.label',
    { defaultValue: 'Logistical hierarchy ID' }
  );
  const patchIdLabel = i18n.t(
    'frontproductstream.collapsible_hierarchies.patch_id.label',
    { defaultValue: 'Logistical hierarchy patch ID' }
  );
  const gtinLabel = i18n.t(
    'frontproductstream.collapsible_hierarchies.root_gtin.label',
    { defaultValue: 'Logistical hierarchy root GTIN' }
  );
  return (
    <Notification
      context="block"
      type="error"
      error={error}
      additionalInfo={[
        [idLabel, `${id}`],
        [patchIdLabel, `${patch_id}`],
        [gtinLabel, `${gtin}`],
      ]}
    >
      {i18n.t('frontproductstream.collapsible_hierarchies.hierarchy.error', {
        defaultValue:
          'Something went wrong with this logistical hierarchy. Please contact our support team.',
      })}
      {hasInfo && (
        <ul>
          {renderValue(idLabel, id)}
          {renderValue(patchIdLabel, patch_id)}
          {renderValue(gtinLabel, gtin)}
        </ul>
      )}
    </Notification>
  );
}
