import Immutable from 'immutable';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  PRODUCTS_IS_REQUESTED,
  basicFilterStatusAdvancedList,
  basicFilterStatusList,
} from 'constants/requested-products-status';
import { hasAllowPush } from 'core/api/organization-settings';
import { getOrganizationSettings } from 'core/api/user';
import {
  requestedProductsFilter,
  requestedProductsStatusFilter,
} from 'core/modules/list/constants/filters';
import { selectAggregations } from 'modules/catalog/product/selectors';
import { selectUser } from 'reducers/user/selectors';
import {
  FilterOnChange,
  FilterOnCollapse,
  FilterOptions,
  ListAggregations,
} from 'types';

import CollapsibleFilter from '../collapsible';
import { SelectedFilterMap } from '../generic/types';
import { useQueryFilter } from '../generic/utils';
import FilterItem from '../simple/item';

import './index.scss';

const filterStatusList: FilterOptions = Immutable.fromJS(basicFilterStatusList);

const filterStatusAdvancedList: FilterOptions = Immutable.fromJS(
  basicFilterStatusAdvancedList
);

interface PropsStatusAdvanced {
  filterQueryValues: string[];
  hasRecipient?: boolean;
  onChange: FilterOnChange;
  selectedFilterMap: SelectedFilterMap;
  hasDocCount: boolean;
}
export const RequestedProductsStatusAdvancedFilter = memo(
  ({
    filterQueryValues,
    onChange,
    selectedFilterMap,
    hasDocCount,
  }: PropsStatusAdvanced) => {
    const { filterLabel, key } = requestedProductsStatusFilter;
    const selectId = (filter) => filter.get('id');
    const selectLabel = (filter) => filter.get('label');
    const aggStatus: Immutable.Map<
      string,
      Immutable.Map<string, number>
    > = useSelector(selectAggregations);
    const aggregation = aggStatus.get(key);

    useQueryFilter({
      options: filterStatusAdvancedList,
      filter: {
        key,
        filterStatusAdvancedList,
        selectFilterValue: (filter) => selectId(filter),
        selectFilterLabel: (filter) => `${filterLabel}: ${selectLabel(filter)}`,
        selectFilterData: (filter) => filter,
      },
      filterQueryValues,
      selectedFilterMap,
      onChange,
    });

    const onLocalChange = (filter, selected) => {
      onChange({
        key: key,
        value: selectId(filter),
        label: `${filterLabel}: ${selectLabel(filter)}`,
        add: selected,
        data: filter,
      });
    };

    return (
      <div>
        {filterStatusAdvancedList.map((filter) => {
          return (
            <FilterItem
              key={`list-filter-${key}-${selectId(filter)}`}
              filterKey={key}
              filter={filter}
              aggregation={aggregation?.get(selectId(filter))}
              selected={!!selectedFilterMap?.get(selectId(filter))}
              hasDocCount={hasDocCount}
              onChange={onLocalChange}
              selectId={selectId}
              selectLabel={selectLabel}
            />
          );
        })}
      </div>
    );
  }
);

interface PropsStatus {
  aggregations: ListAggregations;
  collapsed: boolean;
  filterQueryValuesRequested: string[];
  filterQueryValuesRequestedStatuses: string[];
  hasRecipient?: boolean;
  onChange: FilterOnChange;
  onCollapse: FilterOnCollapse;
  selectedFilterMapRequested: SelectedFilterMap;
  selectedFilterMapRequestedStatuses: SelectedFilterMap;
}

export const RequestedProductsStatusFilter = memo(
  ({
    aggregations,
    collapsed,
    filterQueryValuesRequested,
    filterQueryValuesRequestedStatuses,
    onChange,
    onCollapse,
    selectedFilterMapRequested,
    selectedFilterMapRequestedStatuses,
  }: PropsStatus) => {
    const { filterLabel } = requestedProductsStatusFilter;
    const { key } = requestedProductsFilter;
    const selectId = (filter) => filter.get('id');
    const selectIdToString = (filter) => filter.get('id').toString();
    const selectName = (filter) => filter.get('name');

    const onLocalChange = (filter, selected) => {
      onChange({
        key: key,
        value: selectId(filter),
        label: `${filterLabel}: ${selectName(filter)}`,
        add: selected,
        data: filter,
      });
    };

    useQueryFilter({
      options: filterStatusList,
      filter: {
        key,
        filterStatusList,
        selectFilterValue: (filter) => selectId(filter),
        selectFilterLabel: (filter) => `${filterLabel}: ${selectName(filter)}`,
        selectFilterData: (filter) => filter,
      },
      filterQueryValues: filterQueryValuesRequested,
      selectedFilterMap: selectedFilterMapRequested,
      onChange,
    });

    const onLocalCollapse = useCallback(
      (isCollapsed: boolean) => {
        onCollapse(key, isCollapsed);
      },
      [onCollapse, key]
    );

    const user = useSelector(selectUser);

    const hasFirstLevel = useMemo(
      () => hasAllowPush(getOrganizationSettings(user)),
      [user]
    );

    return (
      <CollapsibleFilter
        id="list-filter-requested-products-status"
        label={filterLabel}
        collapsed={collapsed}
        onCollapse={onLocalCollapse}
      >
        {hasFirstLevel ? (
          <div>
            {filterStatusList.map((filter) => {
              return (
                <FilterItem
                  key={`list-filter-${key}-${selectId(filter)}`}
                  filterKey={key}
                  filter={filter}
                  aggregation={aggregations?.get(selectIdToString(filter))}
                  selected={!!selectedFilterMapRequested?.get(selectId(filter))}
                  hasDocCount={
                    !!aggregations?.getIn([
                      selectIdToString(filter),
                      'doc_count',
                    ])
                  }
                  onChange={onLocalChange}
                  selectId={selectIdToString}
                  selectLabel={selectName}
                />
              );
            })}
            <div className="ListFilterNestedRequestedProductStatusFilter">
              <RequestedProductsStatusAdvancedFilter
                key={requestedProductsFilter.key}
                selectedFilterMap={selectedFilterMapRequestedStatuses}
                onChange={onChange}
                filterQueryValues={filterQueryValuesRequestedStatuses}
                hasDocCount={
                  !!aggregations?.getIn([
                    PRODUCTS_IS_REQUESTED.id.toString(),
                    'doc_count',
                  ])
                }
              />
            </div>
          </div>
        ) : (
          <RequestedProductsStatusAdvancedFilter
            key={requestedProductsFilter.key}
            selectedFilterMap={selectedFilterMapRequestedStatuses}
            onChange={onChange}
            filterQueryValues={filterQueryValuesRequestedStatuses}
            hasDocCount={
              !!aggregations?.getIn([
                PRODUCTS_IS_REQUESTED.id.toString(),
                'doc_count',
              ])
            }
          />
        )}
      </CollapsibleFilter>
    );
  }
);
