import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Textarea } from '@alkem/react-ui-inputs';

import i18n from 'utils/i18n';

import { updateMessage } from '../../actions';
import { selectMessage } from '../../selectors';

export const mapStateToProps = (state) => ({
  message: selectMessage(state),
});
export const mapDispatchToProps = {
  updateMsg: (e) => updateMessage(e.target.value),
};

export const Message = ({ message, updateMsg }) => (
  <div className="row">
    <label
      htmlFor="request-modal-message"
      className="InputField__label col-xs-3"
    >
      {i18n.t('Message')}
    </label>
    <div className="InputField__input InputTextarea InputField col-xs-9">
      <Textarea
        id="request-modal-message"
        placeholder={i18n.t('Add a message (optional)')}
        className="RequestModal__message"
        rows={6}
        value={message}
        onChange={updateMsg}
      />
    </div>
  </div>
);

Message.propTypes = {
  message: PropTypes.string.isRequired,
  updateMsg: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
