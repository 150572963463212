import PropTypes from 'prop-types';
import { Component } from 'react';

import { Text as ComponentText } from '@alkem/react-ui-inputs';

import i18n from 'utils/i18n';

class Text extends Component {
  static demoProps = [
    { id: 'text1', placeholder: 'Enter a value' },
    {
      id: 'text2',
      label: 'Field 2',
      placeholder: 'Enter a value',
      helpMessage: 'Help !',
    },
    {
      id: 'text3',
      label: 'Field 3',
      placeholder: 'Enter a value',
      value: 'Text value',
      disabled: true,
    },
    { id: 'text4', placeholder: 'Enter a value', disabled: true },
    { id: 'text5', placeholder: 'Enter a value', type: 'number', value: '3.4' },
  ];

  static demoHandlers = {
    onChange: {
      prop: 'value',
      handler: (event) => event.target.value,
    },
  };

  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    helpMessage: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    onFocus: PropTypes.func,
    maxLength: PropTypes.number,
  };

  static defaultProps = {
    disabled: false,
    helpMessage: null,
    invalid: false,
    value: '',
    label: '',
    placeholder: '',
    type: 'text',
    maxLength: null,
  };

  render() {
    const emptyValueString = `(${i18n.t(
      'frontproductstream.input.text_and_number.placeholder',
      { defaultValue: 'empty' }
    )})`;
    const numberErrorString = i18n.t(
      'frontproductstream.input.text_and_number.number_error',
      { defaultValue: 'The value is not a number' }
    );
    return (
      <ComponentText
        emptyValueString={emptyValueString}
        numberErrorString={numberErrorString}
        valuesToIgnore={[
          i18n.t('frontproductstream.input.text_and_number.encrypted', {
            defaultValue: 'encrypted',
          }),
        ]}
        {...this.props}
      />
    );
  }
}

export default Text;
