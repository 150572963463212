import { List } from 'immutable';
import moment from 'moment/moment';

import { ProgressBar } from '@alkem/react-ui-progress';

import { MaturitySummary } from 'modules/catalog/product/components/list/table/cell/maturity/index';
import { computePercentageAndQuality } from 'modules/catalog/product/components/list/table/cell/percentage';
import i18n from 'utils/i18n';

import './index.scss';

interface Props {
  maturity: MaturitySummary;
  colorRange?: List<number>;
  includeLateLabel: boolean;
}

export const LateMessage = ({ maturity }) => {
  if (!maturity.deadline) {
    return null;
  }

  const deadline = moment.utc(maturity.deadline).local();
  const endOfToday = moment().endOf('day');

  if (!deadline.isSameOrBefore(endOfToday)) {
    return null;
  }

  const message = i18n.t(
    'frontproductstream.maturity_panel.late_scenario.text',
    {
      defaultValue: 'To be updated before {{date}}',
      date: deadline.format('L'),
    }
  );

  return (
    <div className="MaturityCompletion__lateLabel">
      <i className="mdi mdi-calendar-alert" />
      {message}
    </div>
  );
};

export const MaturityCompletion = ({
  maturity,
  colorRange,
  includeLateLabel,
}: Props) => {
  const qualityToColor = {
    low: 'danger',
    medium: 'warning',
    good: 'success',
  };

  return (
    <div className="MaturityCompletion">
      <div className="MaturityCompletion__maturityLabel">{maturity.label}</div>
      {includeLateLabel && <LateMessage maturity={maturity} />}
      <ProgressBar
        color={
          qualityToColor[
            computePercentageAndQuality(
              maturity.successful,
              maturity.total,
              colorRange
            ).quality
          ]
        }
        value={maturity.successful}
        max={maturity.total}
        labelWithColor={true}
      />
    </div>
  );
};
