import classnames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Ellitips } from '@alkem/react-ui-ellitips';

import Form from 'components/ui/form';
import Modal from 'components/ui/modal';
import { ENTITY_TYPE_SHARINGUNIT } from 'constants/entities';
import * as RetailerSettings from 'core/api/organization-settings';
import { getTargetOrganizationId } from 'core/api/sharing-unit';
import { withNavigate } from 'hocs';
import { ExportSynchronizationStatus } from 'modules/export-synchronization-status';
import { TemplateTypes } from 'modules/sharing-unit-tariffs';
import { selectCurrentLanguage } from 'reducers/productVersion';
import {
  selectIsLoggedAs,
  selectLoggedAsHasDeleteSharingUnitPermission,
} from 'reducers/user/selectors';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { get, isImmutable, toJsIfImmutable } from 'utils/immutable';
import { fill } from 'utils/routing';

import { fetchDisplayGroups } from '../../actions';
import { SHARING_UNIT_STATUS_DRAFT } from '../../constants';
import {
  getDisplayGroups,
  getTariffDisplayGroups,
} from '../../selectors/display-groups';
import {
  traverseAndSetReadOnlyForTariff,
  traverseAndSetReadOnlyForTemplated,
} from '../../utils/core';
import { getSharingUnitTitle } from '../../utils/view';

import HierarchyGrid from './form/components/hierarchy-grid';
import SharingUnitStatus from './status';
import './styles.scss';

// --------------------------------------------------------------------

const mapStateToProps = (state, { sharingUnit }) => ({
  displayGroups:
    sharingUnit.get('template') &&
    sharingUnit.getIn(['template', 'type']) === TemplateTypes.TARIFF.id
      ? getTariffDisplayGroups(state)
      : getDisplayGroups(state),
  currentLanguage: selectCurrentLanguage(state),
  canForceDelete:
    selectIsLoggedAs(state) &&
    selectLoggedAsHasDeleteSharingUnitPermission(state),
});

const mapDispatchToProps = {
  fetchDisplayGroups,
};

export class SharingUnit extends PureComponent {
  static propTypes = {
    // own props
    sharingUnit: ImmutablePropTypes.map.isRequired,
    retailer: ImmutablePropTypes.map.isRequired,
    isTarget: PropTypes.bool,
    readOnly: PropTypes.bool,
    deleteSharingUnit: PropTypes.func.isRequired,

    // from store
    displayGroups: ImmutablePropTypes.map.isRequired,
    currentLanguage: PropTypes.object,
    canForceDelete: PropTypes.bool,

    // actions
    fetchDisplayGroups: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    readOnly: false,
  };

  state = {
    deleteModalOpened: false,
  };

  componentDidMount() {
    const { sharingUnit, readOnly } = this.props;
    const targetOrganizationId = getTargetOrganizationId(sharingUnit);
    const isTariff =
      !!sharingUnit.get('template') &&
      sharingUnit.getIn(['template', 'type']) === TemplateTypes.TARIFF.id;
    this.props.fetchDisplayGroups({
      targetOrganizationId,
      isTariff,
      readOnly,
    });
  }

  componentDidUpdate(prevProps) {
    const { sharingUnit, readOnly } = this.props;
    if (readOnly !== prevProps.readOnly) {
      const targetOrganizationId = getTargetOrganizationId(sharingUnit);
      const isTariff =
        !!sharingUnit.get('template') &&
        sharingUnit.getIn(['template', 'type']) === TemplateTypes.TARIFF.id;
      this.props.fetchDisplayGroups({
        targetOrganizationId,
        isTariff,
        readOnly,
      });
    }
  }

  getDisplayGroups(sharingUnit) {
    let displayGroups = this.props.displayGroups.get(
      getTargetOrganizationId(sharingUnit)
    );
    if (sharingUnit.getIn(['template', 'id'])) {
      if (sharingUnit.getIn(['template', 'type']) === TemplateTypes.TARIFF.id) {
        displayGroups = traverseAndSetReadOnlyForTariff(
          displayGroups,
          this.props.retailer
        );
      } else {
        displayGroups = traverseAndSetReadOnlyForTemplated(
          displayGroups,
          this.props.retailer
        );
      }
    }
    return displayGroups;
  }

  getRetailerSettings() {
    const { retailer } = this.props;
    if (retailer.has('settings') && retailer.get('settings').size > 0) {
      return retailer.get('settings');
    }
    return retailer;
  }

  viewTemplate = (templateId) => () =>
    this.props.navigate(fill(routes.SharingUnitTariffsDetails, templateId));

  deleteSharingUnit = (force) => () => {
    const { sharingUnit } = this.props;
    this.props.deleteSharingUnit({ id: sharingUnit.get('id'), force });
    this.closeDeleteModal();
  };

  forceDeleteSharingUnit = () => {
    this.setState({ deleteModalOpened: true });
  };

  closeDeleteModal = () => this.setState({ deleteModalOpened: false });

  renderDeleteButton() {
    const { sharingUnit, readOnly, canForceDelete } = this.props;
    if (readOnly && !canForceDelete) {
      return null;
    }
    if (
      sharingUnit.get('id') === 0 ||
      (sharingUnit.get('status') !== SHARING_UNIT_STATUS_DRAFT &&
        !canForceDelete)
    ) {
      return null;
    }
    const requiresForce =
      sharingUnit.get('status') !== SHARING_UNIT_STATUS_DRAFT;
    return (
      <button
        type="button"
        className={classnames('SharingUnit__delete', {
          'SharingUnit__delete--force': requiresForce,
        })}
        onClick={
          requiresForce
            ? this.forceDeleteSharingUnit
            : this.deleteSharingUnit(false)
        }
      >
        <i className="mdi mdi-delete" />
        {requiresForce && <span> (admin)</span>}
      </button>
    );
  }

  renderHierarchyImmature(sharingUnitId, sharingUnitData) {
    const { readOnly } = this.props;
    if (RetailerSettings.hasImmatureListing(this.getRetailerSettings())) {
      return (
        <HierarchyGrid
          id={`su-${sharingUnitId}-hierarchy`}
          value={sharingUnitData.get('hierarchy')}
          readOnly={readOnly}
          className="hierarchy"
          sharingUnitId={sharingUnitId}
        />
      );
    }
    return null;
  }

  renderName() {
    const { sharingUnit, isTarget } = this.props;
    const sharingUnitId = sharingUnit.get('id');

    const title = (
      <h3 className="SharingUnit__title">
        <Ellitips
          id={`sharing-unit-name-${sharingUnitId}`}
          label={getSharingUnitTitle(sharingUnit)}
        />
      </h3>
    );

    const template = sharingUnit.get('template');
    if (!isTarget && template && template.get('id')) {
      return (
        <Button
          content={title}
          onClick={this.viewTemplate(template.get('id'))}
          link
        />
      );
    }

    return title;
  }

  render() {
    const { sharingUnit, retailer, currentLanguage } = this.props;
    const hasFeatureSynchronizationStatus =
      retailer.get('export_synchronization_status') === 'enable';
    if (!isImmutable(sharingUnit)) {
      return null;
    }

    const sharingUnitId = sharingUnit.get('id');
    const sharingUnitData = sharingUnit.get('data');
    const sharingUnitDataJS = toJsIfImmutable(sharingUnitData);

    const sharingUnitDisplayGroups = this.getDisplayGroups(sharingUnit);

    if (!sharingUnitDisplayGroups) {
      return null;
    }

    let status = <SharingUnitStatus sharingUnit={sharingUnit} />;
    if (
      hasFeatureSynchronizationStatus &&
      SHARING_UNIT_STATUS_DRAFT !== sharingUnit.get('status')
    ) {
      status = <ExportSynchronizationStatus sharingUnit={sharingUnit} />;
    }
    const id = `SharingUnit-${getSharingUnitTitle(sharingUnit).replace(
      /\s/g,
      ''
    )}`;
    const recipientId = get(sharingUnit, ['targetOrganization', 'id'], false);

    // Render the hierarchy first if mature listing.
    return (
      <div className="SharingUnit" id={id}>
        <div className="SharingUnit__header">
          <div className="SharingUnit__title_and_status">
            {this.renderName()}
            {status}
          </div>
          {this.renderDeleteButton()}
        </div>
        {sharingUnitData && (
          <div>
            {sharingUnitDisplayGroups.map((displayGroup) => (
              <Form
                key={get(displayGroup, 'id')}
                entity={sharingUnitDataJS}
                entityKind={ENTITY_TYPE_SHARINGUNIT}
                entityId={sharingUnit.get('id')}
                formGroup={toJsIfImmutable(displayGroup)}
                showTitle={false}
                currentLanguage={currentLanguage}
                recipientId={recipientId}
              />
            ))}
            {this.renderHierarchyImmature(sharingUnitId, sharingUnitData)}
          </div>
        )}
        {this.state.deleteModalOpened && (
          <Modal
            danger
            modalStyle="dynamic"
            title={i18n.t(
              'frontproductstream.product_page.sharing_unit_modal_listing_deletion.title',
              { defaultValue: 'Confirm listing deletion' }
            )}
            confirmButtonText={
              /** @type {string} */ (
                i18n.t(
                  'frontproductstream.product_page.sharing_unit_modal_listing_deletion.button',
                  { defaultValue: 'Confirm deletion' }
                )
              )
            }
            onConfirm={this.deleteSharingUnit(true)}
            onClose={this.closeDeleteModal}
          >
            {i18n.t(
              'frontproductstream.product_page.sharing_unit_modal_listing_deletion.warning',
              {
                defaultValue:
                  'This listing is already shared with the recipient, deleting it may have unexpected consequences.',
              }
            )}
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigate(SharingUnit));
