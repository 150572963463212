import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { ProgressBar } from '@alkem/react-ui-progress';

import Modal from 'components/ui/modal';
import Tipster from 'components/ui/tipster';
import { hasSingleExportableGtin } from 'core/api/organization-settings';
import { getId, getProductGTIN } from 'core/api/productversion';
import {
  exportableEligibleTargetProductStatuses,
  getTargetProductStatus,
  isExportable,
} from 'core/api/retailerproductversion';
import {
  selectLocalesByTargetMarket,
  selectUserOrganizationSettings,
} from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import {
  resetBulkValidate,
  showBulkValidate,
  validateProducts,
} from '../actions';
import {
  selectBulkValidateVisible,
  selectProductVersionsToValidate,
  selectToValidateProductsDone,
  selectToValidateProductsInProgress,
  selectValidatedProductsErrors,
  selectValidatedProductsInProgressCount,
  selectValidatedProductsInProgressStatus,
  selectValidatedProductsSuccess,
} from '../selectors';

import './index.scss';
import ProductInfo from './product-info';

// GROUP__* are name the groups of product versions when partitionning the selection of product versions
const GROUPNAME__ELIGIBLE = 'eligible';
const GROUPNAME__AMBIGUOUS = 'ambiguous';
const GROUPNAME__ALREADY_EXPORTABLE = 'already exportable';
const GROUPNAME__NOT_READY = 'not ready';

const GROUP_DISPLAY_ORDER = [
  GROUPNAME__ELIGIBLE,
  GROUPNAME__ALREADY_EXPORTABLE,
  GROUPNAME__NOT_READY,
  GROUPNAME__AMBIGUOUS,
];

const findDuplicateGTINs = (productVersions) =>
  productVersions
    .map(getProductGTIN)
    .reduce(
      (gtinsCount, gtin) => gtinsCount.update(gtin, (count = 0) => count + 1),
      Map()
    )
    .filter((count) => count > 1);

const mapStateToProps = (state) => ({
  orgSettings: selectUserOrganizationSettings(state),
  isVisible: selectBulkValidateVisible(state),
  productVersions: selectProductVersionsToValidate(state),
  inProgress: selectToValidateProductsInProgress(state),
  inProgressCount: selectValidatedProductsInProgressCount(state),
  inProgressStatus: selectValidatedProductsInProgressStatus(state),
  isDone: selectToValidateProductsDone(state),
  locales: selectLocalesByTargetMarket(state),
  errors: selectValidatedProductsErrors(state),
  success: selectValidatedProductsSuccess(state),
});

const mapDispatchToProps = {
  resetBulkValidate,
  validateProducts,
  showBulkValidate,
};

// The bulk-action to mark products as exportable.
export class BulkValidate extends PureComponent {
  static propTypes = {
    orgSettings: ImmutablePropTypes.map.isRequired,
    isVisible: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    isDone: PropTypes.bool.isRequired,
    productVersions: ImmutablePropTypes.map.isRequired,
    inProgressCount: PropTypes.number.isRequired,
    inProgressStatus: PropTypes.string.isRequired,
    locales: ImmutablePropTypes.map.isRequired,
    onClose: PropTypes.func,
    errors: ImmutablePropTypes.map.isRequired,
    success: ImmutablePropTypes.map.isRequired,
    actions: PropTypes.shape({
      resetBulkValidate: PropTypes.func.isRequired,
      validateProducts: PropTypes.func.isRequired,
      showBulkValidate: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    onClose() {},
  };

  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.retry = this.retry.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.resetBulkValidate();
  }

  close() {
    const { actions, inProgress, isDone, onClose } = this.props;
    actions.resetBulkValidate();
    onClose(inProgress || isDone);
  }

  retry() {
    const { actions, errors, productVersions } = this.props;
    actions.resetBulkValidate();
    actions.showBulkValidate(
      errors
        .keySeq()
        .reduce(
          (map, productVersionId) =>
            map.set(productVersionId, productVersions.get(productVersionId)),
          Map()
        )
    );
    actions.validateProducts();
  }

  render() {
    const {
      orgSettings,
      isVisible,
      actions,
      productVersions,
      inProgress,
      inProgressCount,
      inProgressStatus,
      isDone,
      locales,
      errors,
      success,
    } = this.props;

    if (!isVisible) {
      return null;
    }

    // Partitioning the given products into these categories
    // - ProductVersions already decorated by the 'set exportable' tag
    // - ProductVersions which are not in the eligible sharing state
    // - ProductVersions whose GTIN is the same as another product in the list.
    //    * This happens in the context of a retailer with feature 'single exportable gtin' where there is
    //      ambiguity on which one should be set exportable.
    //    * Ideally this should be done only once per change of the product list, so not in render.
    //      TBD when/if we refactor this as a functional component.
    const pvs = List(productVersions.values());
    const GTINDuplicates = findDuplicateGTINs(pvs);

    const pvGroups = pvs.groupBy((pv) => {
      // The "ambiguous" group has priority in this partitioning.
      // It must be tested first. because it's about the relationship between
      // products and could interfere with the following filters
      if (
        hasSingleExportableGtin(orgSettings) &&
        GTINDuplicates.has(getProductGTIN(pv))
      )
        return GROUPNAME__AMBIGUOUS;
      if (isExportable(pv)) return GROUPNAME__ALREADY_EXPORTABLE;
      if (
        !exportableEligibleTargetProductStatuses.includes(
          getTargetProductStatus(pv)
        )
      )
        return GROUPNAME__NOT_READY;
      return GROUPNAME__ELIGIBLE;
    });

    const labelForGroup = {
      [GROUPNAME__ELIGIBLE]: i18n.t(
        'frontproductstream.catalog_action_mark_products_as_exportable_dialog.eligible_products.text',
        {
          defaultValue: 'Exportable: these products will be set as exportable.',
        }
      ),
      [GROUPNAME__ALREADY_EXPORTABLE]: i18n.t(
        'frontproductstream.catalog_action_mark_products_as_exportable_dialog.already_exported_products.text',
        {
          defaultValue:
            'Already exportable: no action needed. The following products will not be modified.',
        }
      ),
      [GROUPNAME__NOT_READY]: i18n.t(
        'frontproductstream.catalog_action_mark_products_as_exportable_dialog.not_yet_accepted_by_supplier_products.text',
        {
          defaultValue:
            'Not yet exportable: the sharing process with the supplier must be completed. The following products will not be modified.',
        }
      ),
      [GROUPNAME__AMBIGUOUS]: i18n.t(
        'frontproductstream.catalog_action_mark_products_as_exportable_dialog.same_product_belonging_to_multiple_suppliers.text',
        {
          defaultValue:
            'Several suppliers for a same product: Please select only one same product (GTIN) to make exportable. The following products will not be modified.',
        }
      ),
    };

    const productInfoElement = (pv) => {
      const id = getId(pv);
      return (
        <ProductInfo
          key={id}
          productVersion={pv}
          locales={locales}
          success={success.has(id)}
          error={errors.has(id)}
        />
      );
    };

    const productsListing = (group) => (
      <ul className="BulkValidate__products">
        {pvGroups.get(group, List()).map(productInfoElement)}
      </ul>
    );

    const hasErrors = errors.size > 0;
    let finalWording;
    let buttonLabel;
    let retryLabel;
    let errorLabel;
    let handleModalConfirmation = this.close;

    const eligibleProducts = pvGroups.get(GROUPNAME__ELIGIBLE, List());
    const eligibleCount = eligibleProducts.size;

    if (isDone) {
      if (hasErrors) {
        handleModalConfirmation = this.retry;

        retryLabel = i18n.t(
          'frontproductstream.catalog_action_mark_products_as_exportable_dialog.on_error_retry_validate_products.button',
          {
            defaultValue: 'Retry to set {{count}} products as exportable',
            count: errors.size,
          }
        );

        errorLabel = i18n.t(
          'frontproductstream.catalog_action_mark_products_as_exportable_dialog.on_error_retry_validate_products.header',
          {
            defaultValue:
              'We have encountered an error while trying to set those {{count}} products as exportable. Please retry:',
            count: errors.size,
          }
        );
      }
    } else {
      handleModalConfirmation = () =>
        actions.validateProducts(eligibleProducts);

      switch (eligibleCount) {
        case 0:
          finalWording = i18n.t(
            'frontproductstream.catalog_action_mark_products_as_exportable_dialog.no_eligible_products.text',
            {
              defaultValue:
                'No selected product can be set as exportable. Please complete the actions suggested or select other products.',
            }
          );
          buttonLabel = i18n.t(
            'frontproductstream.catalog_action_mark_products_as_exportable_dialog.close.button',
            { defaultValue: 'Close modal' }
          );
          handleModalConfirmation = this.close;
          break;
        default:
          buttonLabel = i18n.t(
            'frontproductstream.catalog_action_mark_products_as_exportable_dialog.mark_products_as_exportable.button',
            {
              defaultValue: 'Set {{count}} products as exportable',
              count: eligibleCount,
            }
          );
      }
    }

    let outcomeElement;
    if (isDone) {
      if (hasErrors) {
        outcomeElement = (
          <>
            <div className="BulkValidate__errorsLabel">{errorLabel}</div>
            <ul className="BulkValidate__products BulkValidate__errors">
              {errors.keySeq().map((productVersionId) => (
                <ProductInfo
                  key={productVersionId}
                  productVersion={productVersions.get(productVersionId)}
                  locales={locales}
                  error
                />
              ))}
            </ul>
          </>
        );
      } else {
        outcomeElement = (
          <div className="BulkValidate__success">
            <Tipster
              info={i18n.t(
                'frontproductstream.catalog_action_mark_products_as_exportable_dialog.mark_products_as_exportable_success.text',
                {
                  defaultValue:
                    'Setting as exportable is successful. Updates can take few seconds to show.',
                }
              )}
              type="success"
            />
          </div>
        );
      }
    }

    const pvGroupsElements = GROUP_DISPLAY_ORDER.filter((group) =>
      pvGroups.has(group)
    ).map((group) => (
      <div key={group}>
        <div>{labelForGroup[group]}</div>
        {productsListing(group)}
      </div>
    ));

    return (
      <Modal
        modalStyle="dynamic"
        className="BulkValidate"
        title={i18n.t(
          'frontproductstream.catalog_action_mark_products_as_exportable_dialog.header.title',
          { defaultValue: 'Set as exportable' }
        )}
        confirmButtonText={
          (!isDone && buttonLabel) ||
          (hasErrors && retryLabel) ||
          i18n.t(
            'frontproductstream.catalog_action_mark_products_as_exportable_dialog.close.button',
            { defaultValue: 'Close modal' }
          )
        }
        isProcessing={inProgress}
        onConfirm={handleModalConfirmation}
        onClose={this.close}
      >
        {pvGroupsElements}
        {finalWording}
        {!!eligibleCount && (
          <ProgressBar
            value={inProgressCount}
            max={eligibleCount}
            color={inProgressStatus}
            height="medium"
          />
        )}
        {outcomeElement}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(BulkValidate);
