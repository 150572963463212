import { useSelector } from 'react-redux';

import { selectIsRetailer } from 'reducers/user/selectors';

export default function withRetailer<T = any>(WrappedComponent: any) {
  return function WithRetailerHOC(props: T) {
    const isRetailer = useSelector(selectIsRetailer);
    return isRetailer ? <WrappedComponent {...props} /> : null;
  };
}
