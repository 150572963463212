import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';

import Modal from 'components/ui/modal';
import { isManufacturer } from 'core/api/user';
import { selectUser } from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import {
  cancelExportProducts,
  closeExportModal,
  exportProducts,
  fetchExportFileFormats,
  updateExportFormat,
} from '../../../../../actions';
import {
  selectExportFileFormats,
  selectExportFormat,
  selectExportInProgress,
  selectIsExportModalOpen,
  selectPagination,
  selectProductMap,
  selectProductsToExport,
  selectSelectedMap,
} from '../../../../../selectors';

import './index.scss';
import CatalogExportModalV2Step1 from './step1';
import CatalogExportModalV2Step2 from './step2';

const mapStateToProps = createStructuredSelector({
  productMap: selectProductMap,
  selectedProducts: selectSelectedMap,
  productsToExport: selectProductsToExport,
  totalProducts: (state) => selectPagination(state).get('total'),
  selectedFormat: selectExportFormat,
  isOpen: selectIsExportModalOpen,
  isExportInProgress: selectExportInProgress,
  isManufacturerUser: (state) => isManufacturer(selectUser(state)),
  fileFormats: selectExportFileFormats,
  user: (state) => state.user,
  recipients: (state) => state.recipients,
});

const mapDispatchToProps = {
  closeExportModal,
  updateExportFormat,
  exportProducts,
  cancelExportProducts,
  fetchExportFileFormats,
};

export class CatalogExportModalV2 extends PureComponent {
  static propTypes = {
    selectedProducts: ImmutablePropTypes.map.isRequired,
    productsToExport: PropTypes.array,
    totalProducts: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
    isExportInProgress: PropTypes.bool,
    actions: PropTypes.shape({
      exportProducts: PropTypes.func.isRequired,
      closeExportModal: PropTypes.func.isRequired,
      updateExportFormat: PropTypes.func.isRequired,
      cancelExportProducts: PropTypes.func.isRequired,
      fetchExportFileFormats: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    isExportInProgress: false,
    totalProducts: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      exportStep: 1,
    };
  }

  componentDidMount() {
    this.props.actions.fetchExportFileFormats();
  }

  onExport = () => {
    this.props.actions.exportProducts();
  };

  onClose = () => {
    this.props.actions.cancelExportProducts();
    this.props.actions.closeExportModal();
    this.setState({ exportStep: 1 });
  };

  onSelectFormat = (option) => {
    this.props.actions.updateExportFormat(option);
  };

  getSelectedProducts = memoize((selectedProducts) =>
    selectedProducts.filter((s) => s)
  );

  getTitle = () => {
    const { selectedProducts, totalProducts } = this.props;
    const exportedProducts =
      this.getSelectedProducts(selectedProducts).size || totalProducts;
    if (exportedProducts === 1) {
      return i18n.t('Export {{exportedProducts}} product', {
        exportedProducts,
      });
    } else {
      return i18n.t('Export {{exportedProducts}} products', {
        exportedProducts,
      });
    }
  };

  createOnSelectExport = (exportFormat) => () => {
    this.props.actions.updateExportFormat(exportFormat.toJS());
    this.setState({ exportStep: 2 });
  };

  setExportStep1 = () => this.setState({ exportStep: 1 });

  setExportStep2 = () => this.setState({ exportStep: 2 });

  renderFooterStepAction() {
    const { isExportInProgress } = this.props;
    const { exportStep } = this.state;
    return exportStep === 2 && !isExportInProgress ? (
      <Button
        link
        content={i18n.t('< Modify')}
        onClick={this.setExportStep1}
        className="catalogExportModalV2__footerLeft"
      />
    ) : (
      <div />
    );
  }

  renderFooterStatus() {
    const { exportStep } = this.state;
    return (
      <div className="catalogExportModalV2__footerStep">
        {exportStep === 1
          ? i18n.t('1/2: selection of the format')
          : i18n.t('2/2: summary')}
      </div>
    );
  }

  render = () => {
    const { isOpen, isExportInProgress, productsToExport } = this.props;
    const { exportStep } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        className="CatalogExportModal CatalogExportModalV2"
        title={this.getTitle()}
        confirmButtonText={i18n.t('Export >')}
        confirmDisabled={
          exportStep < 2 ||
          (Array.isArray(productsToExport) && productsToExport.length === 0)
        }
        isProcessing={isExportInProgress}
        onConfirm={this.onExport}
        onClose={!isExportInProgress && this.onClose}
        additionalFooterContent={this.renderFooterStepAction()}
        centerFooterContent={this.renderFooterStatus()}
      >
        {exportStep === 1 ? (
          <CatalogExportModalV2Step1 onPickExport={this.setExportStep2} />
        ) : (
          <CatalogExportModalV2Step2 />
        )}
      </Modal>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(CatalogExportModalV2);
