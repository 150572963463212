import { hasInformationRequest } from 'core/api/organization-settings';
import { getOrganizationSettings, isAdmin, isRetailer } from 'core/api/user';
import { PaginationData } from 'core/modules/pagination/types';
import {
  importsGeneric,
  importsGenericInformationRequestReport,
  importsGenericSuppliersReport,
} from 'routes';
import { UserImmutable } from 'types/user';
import { getPathname, getQuery, replace } from 'utils/history';
import { i18n } from 'utils/i18n';

import {
  EMPTY_STATUS,
  ERROR_STATUS_TYPES,
  INFORMATION_REQUEST_IMPORT,
  INFORMATION_REQUEST_REPORT,
  SUPPLIER_DIRECTORY_IMPORT,
  SUPPLIER_DIRECTORY_REPORT,
  allImportsAvailable,
} from './constants';

// Feature available
export const hasImportsGenericFeature: (user: UserImmutable) => boolean = (
  user
) =>
  isRetailer(user) &&
  isAdmin(user) &&
  allImportsAvailable.some((opt) =>
    opt.isAvailable.every((cond) => cond(getOrganizationSettings(user)))
  );

// Render generic cells
export const getStatusGenericImports = (
  status: string,
  totalEntity: number
): string => {
  if (ERROR_STATUS_TYPES.includes(status)) {
    return i18n.t('Fail');
  } else if (status === 'IN_PROGRESS') {
    return i18n.t('In progress');
  } else if (status === EMPTY_STATUS && totalEntity === 0) {
    return i18n.t('Empty');
  }
  return i18n.t('Done');
};

// Equivalence between report shown and choice pre-selected
export const getReportTabFromImportChoice = (typeImport: string): string => {
  if (typeImport === INFORMATION_REQUEST_IMPORT) {
    return importsGenericInformationRequestReport;
  }
  return importsGenericSuppliersReport;
};

export const getReportTypeFromReportTab = (
  pathname: string,
  user: UserImmutable
): string => {
  if (pathname === importsGenericInformationRequestReport) {
    return INFORMATION_REQUEST_REPORT;
  }
  if (
    hasInformationRequest(getOrganizationSettings(user)) &&
    pathname === importsGeneric
  ) {
    // Default one if user has IR enabled
    return INFORMATION_REQUEST_REPORT;
  }
  // Default one
  return SUPPLIER_DIRECTORY_REPORT;
};

export const getImportChoiceFromReportTab = (
  pathname: string,
  user: UserImmutable
): string => {
  if (pathname === importsGenericInformationRequestReport) {
    return INFORMATION_REQUEST_IMPORT;
  }
  if (
    hasInformationRequest(getOrganizationSettings(user)) &&
    pathname === importsGeneric
  ) {
    // Default one if user has IR enabled
    return INFORMATION_REQUEST_IMPORT;
  }
  // Default one
  return SUPPLIER_DIRECTORY_IMPORT;
};

export const getPathFromReportSelected = (
  pathnameSelected: string,
  user: UserImmutable
): string => {
  if (pathnameSelected === importsGenericInformationRequestReport) {
    return importsGenericInformationRequestReport;
  }
  if (
    hasInformationRequest(getOrganizationSettings(user)) &&
    pathnameSelected === importsGeneric
  ) {
    // Default one if user has IR enabled
    return importsGenericInformationRequestReport;
  }
  // Default one
  return importsGenericSuppliersReport;
};

// Replace QP in the url when the user changes pagination
export const updateQueryParams = (paginationUpdates: {
  limit?: number;
  page?: number;
}) => {
  const pathname = getPathname();
  const queries = getQuery();
  replace(pathname, {
    page: paginationUpdates.page || queries.page,
    limit: paginationUpdates.limit || queries.limit,
  });
};

// Checked if the query params are different from the current pagination when refreshing
export const hasQPPaginationChanges = (
  pagination: PaginationData,
  filterQueryValues: {
    page?: string;
    limit?: string;
  }
): { changed: boolean; newPage: number; newLimit: number } => {
  let newLimit = pagination.limit;
  let newPage = pagination.page;
  let changed = false;

  if (pagination.total === undefined) {
    return { changed, newPage, newLimit };
  }

  const numPage = Number(filterQueryValues.page);
  const numLimit = Number(filterQueryValues.limit);
  if (
    Number.isInteger(numPage) &&
    numPage > 0 &&
    numPage !== pagination.page &&
    numPage < pagination.pages
  ) {
    newPage = numPage;
    changed = true;
  }
  if (
    Number.isInteger(numLimit) &&
    numLimit !== pagination.limit &&
    [20, 50, 100, 250].includes(numLimit)
  ) {
    newLimit = numLimit;
    changed = true;
  }

  return { changed, newPage, newLimit };
};
