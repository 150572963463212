import { List, Map, Set } from 'immutable';
import { identity, isEqual, omit, overArgs, sortBy, uniqWith } from 'lodash/fp';
import { createSelector } from 'reselect';

import {
  isKO,
  isSuggestion,
  isSuggestionDisplayedAsHelp,
  isSuggestionDisplayedAsWarning,
} from 'components/ui/form/plugins/validator/utils';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { selectHasMultipleLocales } from 'modules/feature-flag/selectors';
import {
  ruleEntities,
  selectStaticWarning,
  selectValidationResultsForViewAsSettings,
} from 'modules/validation';
import { selectDisplayGroups } from 'modules/view-as/selectors/fields';
import {
  selectCurrentLanguage,
  selectProductVersionId,
} from 'reducers/productVersion';
import { isSameEntity } from 'utils/entity';
import { get } from 'utils/immutable';

// /!\ defines how errors are sorted for stepper
const ERROR_SORT_CONFIG = [
  // then by (display group) rank
  'rank',
  // then by (display groups) path
  'path',
  // then by order of field withing the display groups
  'order',
  // last by list position (when multiple children of same kind)
  'listPosition',
];

export const selectLanguageCode = createSelector(
  selectHasMultipleLocales,
  selectCurrentLanguage,
  (withMultiLocale, currentLanguage) =>
    withMultiLocale ? get(currentLanguage, 'code') : null
);

export const selectValidationData = createSelector(
  selectValidationResultsForViewAsSettings,
  selectStaticWarning,
  selectProductVersionId,
  (validationResultsForViewAsSettings, staticWarning, productVersionId) => {
    if (
      !validationResultsForViewAsSettings ||
      !validationResultsForViewAsSettings.size ||
      !isSameEntity(
        ENTITY_TYPE_PRODUCTVERSION,
        productVersionId,
        validationResultsForViewAsSettings.get('entity')
      )
    ) {
      return Map();
    }

    return validationResultsForViewAsSettings.set(
      'staticWarning',
      staticWarning
    );
  }
);

export const selectRules = createSelector(
  selectValidationData,
  (validationData) =>
    validationData
      .get('rules', List())
      .concat(validationData.getIn(['normalizedComments', 'entities'], List()))
);

export const selectFailedRules = createSelector(
  selectRules,
  selectLanguageCode,
  (rules, languageCode) =>
    rules.filter(
      (rule) =>
        isKO(rule, languageCode) &&
        !isSuggestionDisplayedAsHelp(rule) &&
        (!rule.get('isSuggestion') || isSuggestionDisplayedAsWarning(rule))
    )
);

const isVisible = (node) => !(node.options && node.options.visible === false);

const traceNode = (nodes = [], model, ppath) =>
  nodes
    .map((node, order) => {
      if (node.kind === 'DisplayGroup') {
        const result = traceNode(node.items, model, ppath);
        if (result) {
          return {
            ...result,
            path: [String(node.id).padStart(3, '0'), result.path]
              .filter((x) => x)
              .join('.'),
            rank: [String(node.rank).padStart(4, '0'), result.rank]
              .filter((x) => x)
              .join('.'),
          };
        }
      }
      if (node.model === model && isVisible(node)) {
        if (model === ppath) {
          return { order };
        }
        if ((node.children || []).length) {
          const childPath = ppath.split('.').slice(2);
          const result = traceNode(
            node.children,
            childPath[0],
            childPath.join('.')
          );
          if (result) {
            return {
              order,
              listPosition: result.order,
            };
          }
        }
        return { order, rank: node.rank };
      }
      return { missed: true };
    })
    .find((x) => !x.missed);

const createError = (
  type,
  rule,
  { path, order, listPosition, rank } = {},
  fieldName = ''
) => ({
  type,
  path,
  order,
  listPosition,
  rank,
  fieldName,
  message: rule.get('errorMessage'),
  isSuggestion: isSuggestion(rule),
});

const getPaths = (rule) =>
  rule
    .get('paths', Map())
    .filter((path) => path.size)
    .toList()
    .flatten();

const findRecipientId = (rule) =>
  rule
    .getIn(['status_by_recipients', 'specific'], Map())
    .findKey((r) => r.get('status') === 1);

const findErrors = (failedRules, fn) =>
  failedRules.reduce((acc, _rule) => {
    const rule = Map(_rule);
    const paths = getPaths(rule);
    const recipientId = findRecipientId(rule);
    let fields = rule.get('root_fields', List.of(rule.get('root_field')));
    if (fields.size === 0) {
      fields = paths
        .map((x) => x.split('.')[0])
        .toSet()
        .take(1);
    }
    if (
      [
        ruleEntities.PRODUCT_PICTURE,
        ruleEntities.PRODUCT_DOCUMENT,
        ruleEntities.PRODUCT_VIDEO,
        ruleEntities.PRODUCT_PICTURES,
        ruleEntities.PRODUCT_DOCUMENTS,
        ruleEntities.PRODUCT_VIDEOS,
      ].includes(rule.get('entityType'))
    ) {
      fields = List(['assets']).concat(fields);
    }
    const nextErrors = fields
      .map((rootField) =>
        (paths.size ? paths : List.of(rootField)).map(
          fn(rule, recipientId, rootField)
        )
      )
      .flatten() // flatten multiple possible root fields per rule
      .filter((failedRule) => Object.keys(failedRule).length > 0)
      .toArray();
    return acc.concat(nextErrors);
  }, []);

const selectAllErrors = createSelector(
  selectFailedRules,
  selectDisplayGroups,
  (failedRules, displayGroups) =>
    findErrors(failedRules, (rule, recipientId, fieldName) => (path) => {
      if (
        [
          ruleEntities.CONSUMER_UNIT,
          ruleEntities.DISPLAY_UNIT,
          ruleEntities.PRODUCT_PICTURE,
          ruleEntities.PRODUCT_DOCUMENT,
          ruleEntities.PRODUCT_VIDEO,
          ruleEntities.PRODUCT_PICTURES,
          ruleEntities.PRODUCT_DOCUMENTS,
          ruleEntities.PRODUCT_VIDEOS,
          ruleEntities.TEXTILE_VARIANT,
        ].includes(rule.get('entityType'))
      ) {
        if (!recipientId) {
          const result = traceNode(displayGroups, fieldName, path);
          if (fieldName === 'synonyms') {
            // NOTE synonyms are in failed rules, but no error is shown on the page
            // TODO check if that is due to a configuration issue
            // TODO check if the same occurs on prod
            return {};
          }
          if (result) {
            if (fieldName === 'assets') {
              return {
                ...createError(
                  'media',
                  rule.set('errorMessage', ''),
                  result,
                  fieldName
                ),
                ruleId: rule.get('id'),
                paths: rule
                  .get('paths', Map())
                  .reduce(
                    (acc, value) =>
                      acc.union(
                        (value || List()).map((p) =>
                          p.replace(/assets\.[a-zA-Z]+(\.[0-9]+\.)?/, '')
                        )
                      ),
                    Set()
                  )
                  .filter(identity)
                  .sort()
                  .join(),
              };
            } else {
              return createError('consumer unit', rule, result, fieldName);
            }
          } else {
            return {};
          }
        }
      }
      return {};
    })
);

export const selectSortedErrors = createSelector(
  selectAllErrors,
  (allErrors) => {
    const uniqErrors = uniqWith(
      overArgs(isEqual, [omit(['ruleId']), omit(['ruleId'])]),
      allErrors
    );
    const sortedErrors = sortBy(ERROR_SORT_CONFIG, uniqErrors);
    return sortedErrors;
  }
);

export const selectErrorTypesByDisplayGroup = createSelector(
  selectSortedErrors,
  (errors) => {
    const dict = {};
    errors.forEach((error) => {
      if (error.type === 'consumer unit' || error.type === 'media') {
        const dgId = error.path.split('.')[0];
        if (!dict[dgId]) {
          dict[dgId] = { errors: 0, suggestions: 0 };
        }
        if (error.isSuggestion) {
          dict[dgId].suggestions += 1;
        } else {
          dict[dgId].errors += 1;
        }
      }
    });
    return dict;
  }
);

export const selectNumberOfErrors = createSelector(
  selectFailedRules,
  selectValidationData,
  (failedRules, results) => failedRules.size + results.get('staticWarning', 0)
);
