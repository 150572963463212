import Immutable from 'immutable';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Radio } from '@alkem/react-ui-inputs';
import TurboSelect from '@alkem/react-ui-turbo-select';

import { notificationError, notificationSuccess } from 'actions/notification';
import Modal from 'components/ui/modal';
import {
  getProductProductKeyId,
  getProductVersionTargetProductStatus,
  getSourceProductKeyId,
} from 'core/api/productversion';
import { isRetailer } from 'core/api/user';
import { selectMaturityRulesets } from 'modules/catalog/product/selectors';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_PERMISSIONS_V3_PRODUCT,
  RELEASE_DATA_MATURITY_DEADLINE,
  RELEASE_DATA_MATURITY_UNSHARED_PRODUCTS,
} from 'modules/feature-flag/constants';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  MANAGE_MATURITY_PERMISSION,
  PRODUCT_PERMISSION,
} from 'modules/permissions/const';
import { validationApi } from 'resources/validationApi';
import { GlobalState, UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { separateActions } from 'utils/redux';
import { track } from 'utils/tracking';

import { fetchList } from '../../../../../../actions';

import { DeadlineSelector } from './deadlineSelector';
import './index.scss';

interface ConnectedProps {
  maturityRulesets?: Immutable.List<Immutable.Map<string, any>>;
}

interface OwnProps {
  selectedMap: Immutable.Map<string, any>;
  productMap: Immutable.Map<string, any>;
  user: UserImmutable;
  disabled: boolean;
}

interface Actions {
  notificationSuccess: typeof notificationSuccess;
  notificationError: typeof notificationError;
  fetchList: typeof fetchList;
}

type Props = ConnectedProps & OwnProps & { actions: Actions };

interface State {
  showModal: boolean;
  saving: boolean;
  selectedMaturity: any;
  activate: boolean;
  deadline: any;
  defaultDeadline: any;
  minimumDateMoment: any;
}

const mapStateToProps = createStructuredSelector<GlobalState, ConnectedProps>({
  maturityRulesets: selectMaturityRulesets,
});

const mapDispatchToProps: Actions = {
  notificationSuccess,
  notificationError,
  fetchList,
};

export class MaturityRulesetsAction extends Component<Props, State> {
  static shouldBeDisplayed = ({ user }: { user: UserImmutable }) =>
    isRetailer(user) &&
    (!hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT) ||
      hasPermissionModule(
        user,
        PRODUCT_PERMISSION,
        MANAGE_MATURITY_PERMISSION
      ));

  initialState: State = {
    showModal: false,
    saving: false,
    selectedMaturity: null,
    activate: true,
    deadline: null,
    defaultDeadline: null,
    minimumDateMoment: moment().add(1, 'day').endOf('day'),
  };

  state: State = this.initialState;

  radioOptions = [
    {
      label: i18n.t(
        'frontproductstream.catalog_bulk_actions_maturity.modal_option_activate.text',
        {
          defaultValue: 'Activate',
        }
      ),
      value: true,
    },
    {
      label: i18n.t(
        'frontproductstream.catalog_bulk_actions_maturity.modal_option_deactivate.text',
        {
          defaultValue: 'Deactivate',
        }
      ),
      value: false,
    },
  ];

  selectFilteredProductKeyIds(pvId, productMap, user) {
    if (hasFeature(user, RELEASE_DATA_MATURITY_UNSHARED_PRODUCTS)) {
      return getProductProductKeyId(productMap.get(pvId as string));
    } else {
      return getSourceProductKeyId(productMap.get(pvId as string))
        ? getProductProductKeyId(productMap.get(pvId as string))
        : null;
    }
  }

  getSelectedProductKeyIDs() {
    const { selectedMap, productMap, user } = this.props;
    const selectedProductKeyIDs = [
      ...(selectedMap
        .map((_, pvId) =>
          this.selectFilteredProductKeyIds(pvId, productMap, user)
        )
        .values() as any),
    ];

    const ignoredProductsCount = selectedProductKeyIDs.filter((x) => !x).length;

    return {
      totalCount: selectedProductKeyIDs.length,
      selectedProductKeyIDs: selectedProductKeyIDs.filter((x) => x),
      ignoredProductsCount,
    };
  }

  getProductStatus(productVersion) {
    return getProductVersionTargetProductStatus(productVersion);
  }

  openModal = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState(this.initialState);
  };

  onSelectMaturity = (ruleset) => {
    this.setState({
      selectedMaturity: ruleset,
    });

    if (ruleset?.get('with_deadline', false)) {
      const defaultDeadline = moment(
        ruleset.get('default_deadline', '')
      ).isAfter(this.state.minimumDateMoment)
        ? moment(ruleset.get('default_deadline', ''))
        : this.state.minimumDateMoment;

      this.setState({
        deadline: defaultDeadline,
        defaultDeadline,
      });
    } else {
      this.setState({ deadline: null, defaultDeadline: null });
    }
  };

  handleDeadlineChange = (deadline) => {
    this.setState({ deadline });
  };

  updateActivate = (event) => {
    const activate = event.target.value === 'true';
    const nextState = { activate } as State;
    if (!activate) {
      nextState.deadline = null;
    }
    this.setState(nextState);
  };

  updateRulesets = async () => {
    const { selectedMaturity, activate, deadline } = this.state;
    const { actions, productMap, selectedMap } = this.props;
    const { selectedProductKeyIDs } = this.getSelectedProductKeyIDs();

    if (!selectedMaturity) {
      return;
    }

    this.setState({ saving: true });

    try {
      let deadlineParam = null;

      this.trackUpdateScenarioStatus(
        selectedMap,
        productMap,
        selectedMaturity.get('id'),
        activate,
        deadline
      );

      if (activate && deadline) {
        deadlineParam = deadline.toISOString();
      }

      await validationApi.updateRuleSetsRequireStatus(
        {
          [selectedMaturity.get('id')]: activate,
        },
        selectedProductKeyIDs,
        deadlineParam
      );
      actions.notificationSuccess(
        i18n.t(
          'frontproductstream.catalog_bulk_actions_maturity.notification.success',
          {
            defaultValue: 'Maturity rules have been applied properly',
          }
        )
      );

      this.closeModal();
      this.props.actions.fetchList();
    } catch (error: any) {
      logError(error);
      this.setState({ saving: false });
      actions.notificationError(
        (error.status === 403 ? error.data?.message : null) ||
          i18n.t(
            'frontproductstream.catalog_bulk_actions_maturity.notification.error',
            {
              defaultValue: 'An error occured while applying maturity rules',
            }
          ),
        { context: 'modal', error }
      );
    }
  };

  private trackUpdateScenarioStatus(
    selectedMap: Immutable.Map<string, any>,
    productMap: Immutable.Map<string, any>,
    rulesetId: number,
    activateScenario: boolean,
    deadline: any
  ) {
    type TrackingDataType = {
      via: string;
      has_deadline?: string;
      ruleset_id: number;
      category: string;
      action: string;
      [status: string]: any;
    };

    const trackingData = selectedMap.reduce<TrackingDataType>(
      (acc = {} as TrackingDataType, _, pvId) => {
        const pv = productMap.get(pvId as string);
        const status = this.getProductStatus(pv);
        const statusKey = `nb_products_concerned_sharing_${status}`;
        acc[statusKey] = statusKey in acc ? acc[statusKey] + 1 : 1;
        return acc as TrackingDataType;
      },
      {
        via: 'catalog',
        ruleset_id: rulesetId,
        category: 'product',
        action: activateScenario ? 'scenario_activated' : 'scenario_disabled',
      } as TrackingDataType
    );

    if (activateScenario) {
      trackingData.has_deadline = deadline ? 'yes' : 'no';
    }

    track(trackingData);
  }

  render() {
    const { user, maturityRulesets } = this.props;
    const {
      showModal,
      selectedMaturity,
      saving,
      activate,
      minimumDateMoment,
      defaultDeadline,
    } = this.state;
    const { totalCount, selectedProductKeyIDs, ignoredProductsCount } =
      this.getSelectedProductKeyIDs();

    const allowDeadlineSelection =
      activate &&
      selectedMaturity?.get('with_deadline', false) &&
      hasFeature(user, RELEASE_DATA_MATURITY_DEADLINE);

    return (
      <>
        <div className="ActionOption" onClick={this.openModal}>
          {i18n.t(
            'frontproductstream.catalog_bulk_actions.dropdown_maturity.option',
            {
              defaultValue: 'Activate / deactivate maturity rules',
            }
          )}
        </div>
        {showModal && (
          <Modal
            className="MaturityRuleSetModal"
            modalStyle="dynamic"
            title={i18n.t(
              'frontproductstream.catalog_bulk_actions_maturity.modal.title',
              {
                defaultValue: 'Activate / deactivate maturity rules',
              }
            )}
            confirmButtonText={
              activate
                ? i18n.t(
                    'frontproductstream.catalog_bulk_actions_maturity.modal_activate.button',
                    {
                      defaultValue: 'Activate',
                    }
                  )
                : i18n.t(
                    'frontproductstream.catalog_bulk_actions_maturity.modal_deactivate.button',
                    {
                      defaultValue: 'Deactivate',
                    }
                  )
            }
            confirmDisabled={saving || !selectedMaturity}
            isProcessing={saving}
            onConfirm={this.updateRulesets}
            onClose={this.closeModal}
          >
            {selectedProductKeyIDs.length ? (
              <div>
                <p>
                  {selectedProductKeyIDs.length >= 1 &&
                    i18n.t(
                      'frontproductstream.catalog_bulk_actions_maturity.modal.description',
                      {
                        defaultValue:
                          'Select a rule to change for the {{count}} selected products:',
                        count: totalCount,
                      }
                    )}
                  {ignoredProductsCount > 0 && (
                    <span>
                      <br />
                      {i18n.t(
                        'frontproductstream.catalog.catalog_bulk_actions_maturity.modal.ignored_products',
                        {
                          defaultValue:
                            '{{count}} selected products will not be impacted by this action because of their status.',
                          count: ignoredProductsCount,
                        }
                      )}
                    </span>
                  )}
                </p>
                <Radio
                  id="radio-maturity-action"
                  value={activate}
                  onChange={this.updateActivate}
                  options={this.radioOptions}
                />
                <TurboSelect
                  id="bulk-action-maturity-selector"
                  placeholder={i18n.t('Select a maturity rule')}
                  noOptionsMessage={() =>
                    i18n.t(
                      'frontproductstream.catalog_bulk_actions_maturity.modal_dropdown.no_maturities',
                      { defaultValue: 'No available scenario' }
                    )
                  }
                  isSearchable
                  value={selectedMaturity}
                  options={maturityRulesets as any}
                  onChange={this.onSelectMaturity}
                  getOptionLabel={(rs) => rs.get('label')}
                  menuPlacement="bottom"
                />
                {!!allowDeadlineSelection && (
                  <DeadlineSelector
                    onChange={this.handleDeadlineChange}
                    defaultDeadline={defaultDeadline}
                    minimumDateMoment={minimumDateMoment}
                  />
                )}
              </div>
            ) : (
              <div>
                <p>
                  {i18n.t(
                    'frontproductstream.catalog.bulk_maturity_rulesets.no_impacted_product',
                    {
                      defaultValue:
                        'None of the selected products can be impacted because of their status.',
                    }
                  )}
                </p>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default connect<ConnectedProps, Actions, OwnProps, Props, GlobalState>(
  mapStateToProps as any,
  mapDispatchToProps,
  separateActions
)(MaturityRulesetsAction);
