export const ENTITY_TYPE_PRODUCTVERSION = 'ProductVersion';
export const ENTITY_TYPE_PRODUCTVERSION_BULKEDIT = 'ProductVersionBulkEdit';
export const ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY = 'ProductVersionHierarchy';
export const ENTITY_TYPE_SHARINGUNIT = 'SharingUnit';
export const ENTITY_TYPE_ORGANIZATION = 'Organization';
export const ENTITY_TYPE_SHARINGUNIT_TARIFF = 'SharingUnitTemplate';
export const ENTITY_TYPE_SHARINGUNIT_FOR_TEMPLATE = 'SharingUnitForTemplate';
export const ENTITY_TYPE_PRODUCTVERSION_OVERRIDE = 'ProductVersionOverride';
export const ENTITY_TYPE_PRODUCT_MEDIA = 'ProductMedia';
export const ENTITY_TYPE_PRODUCT_PICTURE = 'ProductPicture';
export const ENTITY_TYPE_RFP_EVENT = 'RFPEvent';
export const ENTITY_TYPE_RECIPIENT = 'Recipient';
export const ENTITY_TYPE_RFP_BU = 'RFPBU';
export const ENTITY_TYPE_RFP_ANSWER = 'RfpAnswer';
export const MEDIA_ENTITIES_TO_VALIDATE = [
  ENTITY_TYPE_PRODUCT_MEDIA,
  ENTITY_TYPE_PRODUCT_PICTURE,
];
