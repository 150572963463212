import Immutable from 'immutable';
import PropTypes from 'prop-types';
// frameworks
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

// ui components
import Dropdown from 'components/ui/dropdown';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_TARGET_CATALOG_IMPORT,
  FEATURE_TARGET_CATALOG_IMPORT_PRODUCT,
} from 'modules/feature-flag/constants';
// utils
import i18n from 'utils/i18n';

import { ButtonDropDown } from './ButtonDropDown';
import { openTargetCatalogImportModal } from './actions';
import { FILE_FORMAT_PRODUCT, FILE_FORMAT_RANGE } from './constants';
import { ImportActiveRangeModal } from './import-modal/ImportActiveRangeModal';
import { ImportProductDataModal } from './import-modal/ImportProductDataModal';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  openTargetCatalogImportModal,
};

export class TargetCatalogImportModalButton extends PureComponent {
  static propTypes = {
    user: PropTypes.instanceOf(Immutable.Map).isRequired,
    openTargetCatalogImportModal: PropTypes.func.isRequired,
  };

  allOptions = [
    {
      name: 'action-targetCatalogImportModal',
      title: 'XLSX Active Range',
      featureFlag: FEATURE_TARGET_CATALOG_IMPORT,
      node: ButtonDropDown,
      component: ImportActiveRangeModal,
      fileFormat: FILE_FORMAT_RANGE,
    },
    {
      name: 'action-TargetCatalogImportProductModal',
      title: 'XLSX Product Data',
      featureFlag: FEATURE_TARGET_CATALOG_IMPORT_PRODUCT,
      node: ButtonDropDown,
      component: ImportProductDataModal,
      fileFormat: FILE_FORMAT_PRODUCT,
    },
  ];

  selectKey = (opt) => opt.key;

  formatOptions() {
    const { user } = this.props;
    return this.allOptions.reduce((acc, opt) => {
      if (hasFeature(user, opt.featureFlag)) {
        acc.push({
          key: opt.name,
          label: <opt.node title={opt.title} fileFormat={opt.fileFormat} />,
          fileFormat: opt.fileFormat,
          component: opt.component,
        });
      }
      return acc;
    }, []);
  }

  render() {
    const options = this.formatOptions();

    if (options === null || options.length < 1) {
      return null;
    } else if (options.length === 1) {
      const ImportModal = options[0].component;
      return (
        <>
          <Button
            secondary
            className="TargetCatalogImportModalButton"
            content={i18n.t('Import')}
            onClick={() =>
              this.props.openTargetCatalogImportModal(options[0].fileFormat)
            }
            testid="ButtonImport"
          />
          <ImportModal />
        </>
      );
    }
    return (
      <Dropdown
        label={i18n.t('Import')}
        options={options}
        selectKey={this.selectKey}
        closeDropdownOnClickElement
        rightDropdown
        withoutWrappers
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetCatalogImportModalButton);
