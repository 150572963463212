import i18n from 'utils/i18n';

export interface UnitType {
  id: number;
  label: string;
}

export const CONSUMER_UNIT: UnitType = {
  id: 0,
  label: i18n.t(
    'frontproductstream.constants.product_unit_type.consumer_unit',
    {
      defaultValue: 'Consumer unit',
    }
  ),
};

export const DISPLAY_UNIT: UnitType = {
  id: 1,
  label: i18n.t('frontproductstream.constants.product_unit_type.display_unit', {
    defaultValue: 'Display unit',
  }),
};

export const LOGISTICAL_UNIT: UnitType = {
  id: 2,
  label: i18n.t(
    'frontproductstream.constants.product_unit_type.logistical_unit',
    {
      defaultValue: 'Logistical unit',
    }
  ),
};

export const AMBIGOUS_UNIT: UnitType = {
  id: 3,
  label: '',
};

export const HETEROGENEOUS_UNIT: UnitType = {
  id: 4,
  label: i18n.t(
    'frontproductstream.constants.product_unit_type.heterogeneous_unit',
    {
      defaultValue: 'Heterogeneous unit',
    }
  ),
};
