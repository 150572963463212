import contributionApi from 'resources/contributionApi';
import productApi from 'resources/productApi';
import productVersionApi from 'resources/productVersionApi';
import { validationApiNotImmutable } from 'resources/validationApi';

import { CONTRIBUTION_TYPE_PHYSICAL_CHECKER } from './constants';

export async function fetchStateMachineApi() {
  const response = await contributionApi.getStateMachine(
    CONTRIBUTION_TYPE_PHYSICAL_CHECKER
  );
  return response.data.data;
}

export async function createContributionApi(contribution) {
  const response = await contributionApi.createContribution(contribution);
  return response.data.data;
}

export async function updateStatusApi(contributionId, status) {
  const response = await contributionApi.updateStatus(contributionId, status);
  return response.data.data;
}

export async function submitFieldsApi(contributionId, fields) {
  const response = await contributionApi.submitFields(contributionId, fields);
  return response.data.data;
}

export async function refuseReviewApi(contributionId, fields) {
  const response = await contributionApi.refuseReview(contributionId, fields);
  return response.data.data;
}

export async function acceptReviewApi(contributionId, fields) {
  const response = await contributionApi.acceptReview(contributionId, fields);
  return response.data.data;
}

export async function checkValidationApi(body) {
  const response = await validationApiNotImmutable.applyRulesRequired(body);
  return response.data;
}

export async function validateDataApi(body) {
  const response = await productApi.ProductValidateVersion(body);
  return response.data;
}

export async function fetchContributionDiffApi(source, edited) {
  const response = await productVersionApi.getProductVersionDiff(
    source,
    edited
  );
  return response.data;
}
