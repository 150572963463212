import PropTypes from 'prop-types';
import { useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useNavigate } from 'react-router-dom';

import {
  getProductGTINWithFallbackOnId,
  getTargetMarketId,
} from 'core/api/productversion';
import {
  hasAnyProductUpdatePermission,
  isManufacturer,
  isPrivateLabel,
} from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import UpsellModal from 'modules/feature-flag/components/product-duplication/modal';
import { FEATURE_MANUFACTURER_DUPLICATION } from 'modules/feature-flag/constants';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { withQuery } from 'utils/query';
import { fill } from 'utils/routing';

const isConsumerOrDisplayUnit = (product) =>
  product.get('isConsumerUnit') || product.get('isDisplayUnit');

const getSelectedId = (selectedMap) => selectedMap.keySeq().first();

function DuplicateAction({ disabled, user, selectedMap, productMap }) {
  const navigate = useNavigate();
  const [isUpsellModalOpen, setUpsellModalOpen] = useState(false);
  const label = i18n.t('Duplicate product');
  const hasDuplicate = hasFeature(user, FEATURE_MANUFACTURER_DUPLICATION);

  const getProduct = () => {
    const productVersion = selectedMap
      .map((_, pvId) => productMap.get(pvId))
      .toList()
      .first();
    return {
      reference: getProductGTINWithFallbackOnId(productVersion),
      targetMarket: getTargetMarketId(productVersion),
    };
  };

  const onClick = () => {
    const product = getProduct();
    navigate(
      fill(
        withQuery(routes.productCreate, {
          copy: product.reference,
          tm: product.targetMarket,
        })
      )
    );
  };

  return hasDuplicate || disabled ? (
    <div className="ActionOption" onClick={disabled ? undefined : onClick}>
      <div data-for="bulk-duplicate-disabled" data-tip id="Action_Duplicate">
        {label}
      </div>
    </div>
  ) : (
    <>
      <div className="ActionOption" onClick={() => setUpsellModalOpen(true)}>
        <span>
          <span>{label}</span>
          <i>{` - ${i18n.t('Upgrade!')}`}</i>
        </span>
      </div>
      {isUpsellModalOpen && (
        <UpsellModal onClose={() => setUpsellModalOpen(false)} />
      )}
    </>
  );
}

DuplicateAction.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  selectedMap: ImmutablePropTypes.map.isRequired,
  productMap: ImmutablePropTypes.map.isRequired,
  disabled: PropTypes.any,
};

DuplicateAction.isEnabled = ({ productMap, selectedMap }) =>
  selectedMap &&
  selectedMap.size === 1 &&
  isConsumerOrDisplayUnit(productMap.get(getSelectedId(selectedMap)));

DuplicateAction.shouldBeDisplayed = ({ user }) =>
  (isManufacturer(user) || isPrivateLabel(user)) &&
  hasAnyProductUpdatePermission(user);

export default DuplicateAction;
