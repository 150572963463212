import {
  ARCHIVED_PRODUCTS,
  PRODUCTS,
  PRODUCTS_WITH_MENU,
} from 'modules/catalog/product/constants/context';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_ALL_CATALOG_FILTER_ISDISPLAYABLEFORTARGETS,
  FEATURE_SHARING_STATUS_FILTER,
} from 'modules/feature-flag/constants';
import i18n from 'utils/i18n';

export const isDisplayableForTargetsFilter = {
  key: 'alkemics.is_displayable_for_targets',
  query: 'is_displayable_for_targets',
  isAvailable:
    /**
     * @param {object} params
     * @param {import('types').UserImmutable=} params.user
     * @param {any=} params.context
     * @returns
     */
    ({ user, context } = {}) => {
      if (
        hasFeature(user, FEATURE_ALL_CATALOG_FILTER_ISDISPLAYABLEFORTARGETS)
      ) {
        if (
          [PRODUCTS, PRODUCTS_WITH_MENU, ARCHIVED_PRODUCTS].includes(context)
        ) {
          return !hasFeature(user, FEATURE_SHARING_STATUS_FILTER);
        }
        return true;
      }
      return false;
    },
  customField: 'recipients_is_display_for',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_displayable_for_targets.label',
    { defaultValue: 'Is visible by' }
  ),
  filterSearchPlaceHolder: i18n.t(
    'frontproductstream.core.list_filter_displayable_for_targets.search_placeholder',
    { defaultValue: 'Search for recipients' }
  ),
  filterNameKey: 'name',
  filterNameKeyFallback: 'id',
  haveKey: i18n.t(
    'frontproductstream.core.list_filter_displayable_for_targets.visible_option',
    { defaultValue: 'Visible by' }
  ),
  doesntHaveKey: i18n.t(
    'frontproductstream.core.list_filter_displayable_for_targets.not_visible_option',
    { defaultValue: 'Not visible by' }
  ),
};
