import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Text as ComponentText } from '@alkem/react-ui-inputs';

import InputValidityHint from 'components/ui/input/input-validity-hint';
import i18n from 'utils/i18n';
import { isValidGln } from 'utils/validation';

import './gln.scss';

class Gln extends PureComponent {
  static propTypes = {
    ...ComponentText.propTypes,
    enableValidator: PropTypes.bool,
  };

  static defaultProps = {
    ...ComponentText.defaultProps,
    enableValidator: false,
  };

  hasValidGln = () => {
    const { value } = this.props;
    if (isUndefined(value) || value === '' || value === null) {
      return null;
    }
    return isValidGln(value);
  };

  renderValidityHints() {
    const isValid = this.hasValidGln();
    const message = isValid
      ? i18n.t('frontproductstream.input.gln.valid_text', {
          defaultValue: 'This GLN is valid',
        })
      : i18n.t('frontproductstream.input.gln.invalid_text', {
          defaultValue: 'This GLN is invalid',
        });
    return (
      <div className="InputGln__Hint">
        <InputValidityHint valid={isValid} message={message} />
      </div>
    );
  }

  renderSideElements() {
    return (
      <div className="InputGln__SideElements">
        {this.props.enableValidator &&
          !this.props.disabled &&
          this.renderValidityHints()}
      </div>
    );
  }

  render() {
    return (
      <div className="InputGln">
        <ComponentText type="text" {...this.props} />
        {this.renderSideElements()}
      </div>
    );
  }
}

export default Gln;
