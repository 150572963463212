import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

export const SupplierDirectory = asyncComponent(async () =>
  registerModule(
    await import(/* webpackChunkName: "supplier-directory" */ './async')
  )
);

export type {
  Supplier,
  Field,
  OrganizationMatch,
  SupplierContact,
} from './types';
