import PropTypes from 'prop-types';
import { memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { WrapperRequestsActionsModal } from 'modules/catalog-requests-actions';
import {
  ImportActiveRangeModal,
  ImportProductDataModal,
} from 'modules/target-catalog-import';
import TargetRequestModal from 'modules/target-request-modal';
import { logError } from 'utils/logging';
import qs from 'utils/query';

import { resetCatalog } from '../actions';
import {
  ARCHIVED_PRODUCTS,
  PRODUCTS,
  PRODUCTS_TO_REVIEW,
  PRODUCTS_WITH_MENU,
} from '../constants/context';
import { filterQueryMap } from '../constants/filters';

import CatalogFilters from './filters';
import { CatalogList } from './list';
import CatalogExportModal from './list/actions/export-modal';

function CatalogView({ context }) {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { filtersQueryMap, searchQuery } = useMemo(() => {
    let filters = {};
    let localSearchQuery;
    try {
      const query = qs.parse(search);
      // filters
      filters = Object.keys(query).reduce((acc, queryKey) => {
        const filter = filterQueryMap[queryKey];
        if (filter) {
          const queryValues = query[queryKey];
          const valueStr = Array.isArray(queryValues)
            ? queryValues.join()
            : queryValues || '';
          let values;
          if (filter.parseQueryValues) {
            values = filter.parseQueryValues(valueStr);
          } else {
            values = valueStr.split(',').filter((f) => f);
          }
          if (values.length) {
            Object.assign(acc, {
              [filter.key]: (acc[filter.key] || []).concat(values),
            });
          }
          if (typeof values === 'object' || typeof values === 'number') {
            Object.assign(acc, { [filter.key]: values });
          }
        }
        return acc;
      }, {});

      // search
      if (query.search) {
        // TODO: for now it does not handle advanced search
        localSearchQuery = query.search;
      }
    } catch (e) {
      logError(e);
    }
    return { filtersQueryMap: filters, searchQuery: localSearchQuery };
  }, [search]);

  useEffect(
    () => () => {
      dispatch(resetCatalog());
    },
    [dispatch]
  );

  return (
    <>
      <CatalogFilters filtersQueryMap={filtersQueryMap} context={context} />
      <CatalogList
        filtersQueryMap={filtersQueryMap}
        searchQuery={searchQuery}
        context={context}
      />
      {[
        PRODUCTS,
        PRODUCTS_WITH_MENU,
        PRODUCTS_TO_REVIEW,
        ARCHIVED_PRODUCTS,
      ].includes(context) && (
        <>
          <TargetRequestModal />
          <ImportActiveRangeModal />
          <ImportProductDataModal />
          <CatalogExportModal />
          <WrapperRequestsActionsModal />
        </>
      )}
    </>
  );
}

CatalogView.propTypes = {
  context: PropTypes.shape({
    type: PropTypes.string,
    menu: PropTypes.bool,
    search: PropTypes.bool,
  }).isRequired,
};

export const Catalog = memo(CatalogView);
export default Catalog;
