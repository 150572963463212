import classNames from 'classnames';
import { List, Map } from 'immutable';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';

import { notificationSuccess } from 'actions/notification';
import { ListItemsPerPage } from 'core/modules/list/components/actions/items-per-page';
import ListActions, {
  ListLeftActions,
  ListRightActions,
} from 'core/modules/list/components/actions/layout';
import { ListPaginationButtons } from 'core/modules/list/components/actions/pagination/buttons';
import ListBody from 'core/modules/list/components/body';
import TableBody from 'core/modules/list/components/body/table';
import { Sorting } from 'core/modules/list/components/table/header/cell';
import { ITEMS_PER_PAGE_OPTIONS } from 'core/modules/list/constants';
import { PaginationData } from 'core/modules/pagination/types';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_REPORTING_STICKY_FIRST_ROW_AND_COLUMN } from 'modules/feature-flag/constants';
import { selectIsRetailer, selectUser } from 'reducers/user/selectors';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { track } from 'utils/tracking';
import { createUpsellEvent } from 'utils/tracking/events';

import {
  exportReport,
  fetchReport,
  nextPage,
  previousPage,
  redirectToCatalog,
  selectReport,
  sortReport,
  updateItemsPerPage,
} from '../../actions';
import {
  selectCharts,
  selectColumnList,
  selectColumnMap,
  selectExportInProgress,
  selectIsBackendPagination,
  selectIsDemo,
  selectPagination,
  selectReportAggregation,
  selectReportList,
  selectReportMap,
  selectSelectedMap,
  selectSorting,
} from '../../selectors';

import { ReportingExportButton } from './actions/export-button';
import ReportingCharts from './charts';
import DemoInfo from './demo/info';
import UpsellModal from './demo/upsell';
import './index.scss';
import { ReportingPaginationInfo } from './pagination/info';
import { ReportingTable } from './table';

type Props = {
  reportConfig: Map<string, any>;
  loading?: boolean;
};

export const ReportingList = ({ reportConfig, loading = false }: Props) => {
  const dispatch = useDispatch();

  const reportMap: Map<string, any> = useSelector(selectReportMap) || Map();
  const reportList: List<any> = useSelector(selectReportList) || List();
  const columnMap: Map<string, any> = useSelector(selectColumnMap) || Map();
  const columnList: List<any> = useSelector(selectColumnList) || List();
  const selectedMap: Map<string, any> = useSelector(selectSelectedMap) || Map();
  const pagination: PaginationData = useSelector(selectPagination);
  const sorting: Sorting = useSelector(selectSorting);
  const exportInProgress: boolean =
    useSelector(selectExportInProgress) || false;
  const reportAggregation = useSelector(selectReportAggregation);
  const isDemo: boolean = useSelector(selectIsDemo) || false;
  const charts: List<any> = useSelector(selectCharts) || List();
  const isRetailer: boolean = useSelector(selectIsRetailer);
  const user: UserImmutable = useSelector(selectUser);
  const isBackendPagination: boolean = useSelector(selectIsBackendPagination);

  const [showUpsell, setShowUpsell] = useState(true);
  const prevReportConfig = useRef(reportConfig);

  useEffect(() => {
    if (reportConfig.get('id') !== prevReportConfig.current.get('id')) {
      setShowUpsell(true);
      prevReportConfig.current = reportConfig;
    }
  }, [reportConfig]);

  const onSelectReport = (id, selected) => {
    selectReport({ id, selected });
  };

  const onPreviousPage = () => {
    dispatch(previousPage());
    if (isBackendPagination) {
      dispatch(fetchReport());
    }
  };

  const onNextPage = () => {
    dispatch(nextPage());
    if (isBackendPagination) {
      dispatch(fetchReport());
    }
  };

  const onExportReport = () => dispatch(exportReport());

  const onChangeItemsPerPage = (nb) => {
    dispatch(updateItemsPerPage(nb));
    if (isBackendPagination) {
      dispatch(fetchReport());
    }
  };

  const onSort = (referential, asc) => {
    if (isBackendPagination) {
      return;
    }
    dispatch(sortReport({ referential, asc }));
  };

  const onClickCell = (data) => {
    dispatch(redirectToCatalog(data));
  };

  const onUpsell = () => {
    track(createUpsellEvent('reporting', reportConfig.get('id')));
    setShowUpsell(false);
    dispatch(
      notificationSuccess(
        i18n.t(
          'frontproductstream.reporting_page.upsell_call_to_action_success.notification',
          {
            defaultValue:
              'Thank you for your interest, we are going to recontact you soon.',
          }
        )
      )
    );
  };

  const isSticky = hasFeature(
    user,
    FEATURE_REPORTING_STICKY_FIRST_ROW_AND_COLUMN
  );

  return (
    <ListBody
      className={classNames('ReportingList', isDemo && 'ReportingList--demo')}
    >
      {!isDemo && (
        <>
          <ListActions>
            <ListLeftActions>
              <ReportingExportButton
                selected={selectedMap.filter((v) => v).size}
                total={get(pagination, 'total')}
                inProgress={exportInProgress}
                onExport={onExportReport}
                fullExport={isBackendPagination}
              />
            </ListLeftActions>
            <ListRightActions>
              <ListItemsPerPage
                itemsPerPage={get(pagination, 'limit')}
                options={ITEMS_PER_PAGE_OPTIONS}
                onChange={onChangeItemsPerPage}
              />
              <ListPaginationButtons
                id="reporting-pagination-buttons"
                pagination={pagination}
                onPrevious={onPreviousPage}
                onNext={onNextPage}
              />
            </ListRightActions>
          </ListActions>
          {!isBackendPagination && (
            <ReportingPaginationInfo
              selectedMap={selectedMap}
              pagination={pagination}
            />
          )}
        </>
      )}
      {isDemo && (
        <>
          <DemoInfo />
          {showUpsell && <UpsellModal onUpsell={onUpsell} />}
        </>
      )}
      <TableBody scrollable isSticky={isSticky}>
        {!charts.isEmpty() && (
          <ReportingCharts charts={charts} isSticky={isSticky} />
        )}
        {loading && (
          <div className="Reporting__spinner">
            <Spinner big />
          </div>
        )}
        <ReportingTable
          columnMap={columnMap}
          columnList={columnList}
          reportMap={reportMap}
          reportList={reportList}
          sorting={isBackendPagination ? (Map() as Sorting) : sorting}
          selectedMap={selectedMap}
          reportAggregation={reportAggregation}
          onSort={!isBackendPagination ? onSort : null}
          onSelectReport={onSelectReport}
          onClickCell={onClickCell}
          isRetailer={isRetailer}
          isSticky={isSticky}
        />
      </TableBody>
    </ListBody>
  );
};

export default ReportingList;
