import { List } from 'immutable';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';

import { MaturitySummary } from 'modules/catalog/product/components/list/table/cell/maturity/index';
import i18n, { getUserLanguage } from 'utils/i18n';
import { sortAsc } from 'utils/sort';
import { capitalize } from 'utils/string';

import { MaturityCompletion } from '../maturity-completion';

import './index.scss';

interface RecipientGroup {
  recipientId?: number;
  recipientName?: string;
  scenarios: MaturitySummary[];
}
interface DateGroup {
  date: Moment;
  recipients: RecipientGroup[];
  scenariosWithoutRecipient: MaturitySummary[];
}

export const UpcomingDeadlines = ({
  upcomingScenarios,
  colorRange,
}: {
  upcomingScenarios: MaturitySummary[];
  colorRange?: List<number>;
}) => {
  const scenariosByDateThenRecipient = useMemo(() => {
    return upcomingScenarios.reduce((dateGroups: DateGroup[], scenario) => {
      const date = moment(scenario.deadline).startOf('day');

      let dateGroup = dateGroups.find((candidate) =>
        candidate.date.isSame(date)
      );
      if (!dateGroup) {
        dateGroup = {
          date: date,
          recipients: [],
          scenariosWithoutRecipient: [],
        };
        dateGroups.push(dateGroup);
      }

      if (!scenario.recipient_id) {
        dateGroup.scenariosWithoutRecipient.push(scenario);
      } else {
        let recipientGroup = dateGroup.recipients.find(
          (candidate) => candidate.recipientId === scenario.recipient_id
        );
        if (!recipientGroup) {
          recipientGroup = {
            recipientId: scenario.recipient_id,
            recipientName: scenario.recipient_name,
            scenarios: [],
          };
          dateGroup.recipients.push(recipientGroup);
        }
        recipientGroup.scenarios.push(scenario);
      }
      return dateGroups;
    }, []);
  }, [upcomingScenarios]);

  const RecipientSection = ({
    recipientGroup,
  }: {
    recipientGroup: RecipientGroup;
  }) => {
    return (
      <div>
        <div
          className="MaturityPanelDeadlines__recipientHeader"
          data-testid="recipient-header"
        >
          {i18n.t('frontproductstream.constants.label.colon', {
            defaultValue: '{{label}}:',
            label: recipientGroup.recipientName,
          })}
        </div>
        {recipientGroup.scenarios
          .sort((a, b) => sortAsc(a.label, b.label))
          .map((scenario) => {
            return (
              <MaturityCompletion
                key={scenario.id}
                maturity={scenario}
                colorRange={colorRange}
                includeLateLabel={false}
              />
            );
          })}
      </div>
    );
  };
  const DateSection = ({ dateGroup }) => {
    function formatDate(date: moment.Moment) {
      const dateFormatOptions: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return capitalize(
        date.toDate().toLocaleString(getUserLanguage(), dateFormatOptions)
      );
    }

    const hasDatePassed = dateGroup.date.isBefore(moment.now());

    const sortedRecipientGroups = dateGroup.recipients.sort((a, b) =>
      sortAsc(a.recipientName, b.recipientName)
    );
    return (
      <>
        <div
          className="MaturityPanelDeadlines__dateHeader"
          data-testid="date-header"
        >
          <span>{formatDate(dateGroup.date)}</span>
          {hasDatePassed && (
            <span className="MaturityPanelDeadlines__overdueFlag">
              {' '}
              {i18n.t(
                'frontproductstream.maturity_panel.deadlines.overdueText',
                {
                  defaultValue: 'Overdue',
                }
              )}
            </span>
          )}
        </div>
        {/* case of retailers */}
        {dateGroup.scenariosWithoutRecipient
          .sort((a, b) => sortAsc(a.label, b.label))
          .map((scenario) => {
            return (
              <MaturityCompletion
                key={scenario.id}
                maturity={scenario}
                colorRange={colorRange}
                includeLateLabel={false}
              />
            );
          })}
        {/* case of manufacturers */}
        {sortedRecipientGroups.map((recipientGroup) => {
          return (
            <RecipientSection
              key={recipientGroup.recipientId}
              recipientGroup={recipientGroup}
            />
          );
        })}
      </>
    );
  };

  if (upcomingScenarios?.length) {
    const sortedDateGroups = scenariosByDateThenRecipient.sort(
      (a, b) => a.date.unix() - b.date.unix()
    );
    return (
      <>
        {sortedDateGroups.map((dateGroup) => (
          <DateSection
            key={dateGroup.date.toISOString()}
            dateGroup={dateGroup}
          ></DateSection>
        ))}
      </>
    );
  } else {
    return (
      <div className="MaturityPanelDeadlines__emptyUpcoming">
        <div className="MaturityPanelDeadlines__emptyUpcomingTitle">
          {i18n.t('frontproductstream.maturity_panel.noupcoming.title', {
            defaultValue: 'Nothing to come',
          })}
        </div>
        <div className="MaturityPanelDeadlines__emptyUpcomingText">
          {i18n.t('frontproductstream.maturity_panel.noupcoming.text', {
            defaultValue: 'You currently have no upcoming update',
          })}
        </div>
      </div>
    );
  }
};
