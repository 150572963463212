import { useCallback, useState } from 'react';

import { Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';

import DocumentModal from '../../actions/document/document-modal';
import { PictureModal } from '../../actions/picture/picture-modal';
import { AssetTypeV2, Field } from '../types';
import { areAssetReadOnly } from '../utils';

import { RecipeModalSwitch } from './RecipeModalSwitch';

type ViewOrEditButtonProps = {
  asset: AssetTypeV2;
  field: Field;
  updateEntities: () => void;
};

export const ViewOrEditButton = ({
  asset,
  field,
  updateEntities,
}: ViewOrEditButtonProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleSuccess = useCallback(() => {
    updateEntities();

    handleCloseModal();
  }, [handleCloseModal, updateEntities]);

  if (asset._type === 'ProductPicture') {
    const isReadOnly = areAssetReadOnly({ field, assetType: 'picture' });

    const buttonLabel = isReadOnly
      ? i18n.t('frontproductstream.asset_list.view_button.label', {
          defaultValue: 'View',
        })
      : i18n.t('frontproductstream.asset_list.edit_button.label', {
          defaultValue: 'Edit',
        });

    const title = isReadOnly
      ? i18n.t('frontproductstream.asset_list.picture_modal.view_title', {
          defaultValue: 'View picture',
        })
      : i18n.t('frontproductstream.asset_list.picture_modal.edit_title', {
          defaultValue: 'Edit picture',
        });

    const fieldAction = field.actions.find((f) => f.render === 'addPicture');

    return (
      <>
        <Button
          className="AssetV2__preview__button"
          primary
          content={buttonLabel}
          onClick={handleOpenModal}
        />
        {isModalOpen && (
          <PictureModal
            title={title}
            fieldActionItems={fieldAction?.items || []}
            count={-1}
            media={asset}
            productKeyId={asset.product_key_id}
            readOnly={isReadOnly}
            onClose={handleCloseModal}
            onSuccess={handleSuccess}
          />
        )}
      </>
    );
  }

  if (asset._type === 'ProductDocument') {
    const isReadOnly = areAssetReadOnly({ field, assetType: 'document' });

    const buttonLabel = isReadOnly
      ? i18n.t('frontproducstream.asset_list.view_button.label', {
          defaultValue: 'View',
        })
      : i18n.t('frontproducstream.asset_list.edit_button.label', {
          defaultValue: 'Edit',
        });

    const title = isReadOnly
      ? i18n.t('frontproducstream.asset_list.document_modal.view_title', {
          defaultValue: 'View document',
        })
      : i18n.t('frontproducstream.asset_list.document_modal.edit_title', {
          defaultValue: 'Edit document',
        });

    const action = field.actions.find((a) => a.render === 'addDocument') || {
      items: [],
    };

    return (
      <>
        <Button
          className="AssetV2__preview__button"
          primary
          content={buttonLabel}
          onClick={handleOpenModal}
        />
        {isModalOpen && (
          <DocumentModal
            title={title}
            action={action}
            index={asset.index}
            media={asset}
            productKeyId={asset.product_key_id}
            onSuccess={handleSuccess}
            onClose={handleCloseModal}
            readOnly={isReadOnly}
          />
        )}
      </>
    );
  }

  if (asset._type === 'ProductEnrichedContent') {
    const isReadOnly = areAssetReadOnly({ field, assetType: 'recipe' });

    const buttonLabel = isReadOnly
      ? i18n.t('frontproductstream.asset_list.view_button.label', {
          defaultValue: 'View',
        })
      : i18n.t('frontproductstream.asset_list.edit_button.label', {
          defaultValue: 'Edit',
        });

    return (
      <>
        <Button
          className="AssetV2__preview__button"
          primary
          content={buttonLabel}
          onClick={handleOpenModal}
        />
        {isModalOpen && (
          <RecipeModalSwitch
            asset={asset}
            isReadOnly={isReadOnly}
            onClose={handleCloseModal}
          />
        )}
      </>
    );
  }

  if (asset._type === 'ProductVideo') {
    const buttonLabel = i18n.t(
      'frontproductstream.asset_list.video_modal.view_title',
      {
        defaultValue: 'Video',
      }
    );

    const handleVideoButton = () => {
      window.open(asset.uniformResourceIdentifier, '_blank');
    };

    return (
      <Button
        className="AssetV2__preview__button"
        primary
        content={buttonLabel}
        onClick={handleVideoButton}
      />
    );
  }

  return null;
};
