import IndexerApi from 'resources/indexerApi';
import { createAdminProductApi } from 'resources/productApi';
import { createAdminValidationApi } from 'resources/validationApi';
import qs from 'utils/query';

export const reindexProduct = (productKeyId: number) =>
  IndexerApi.post('/indexer/product/v1/request', {
    product_key_ids: [productKeyId],
  });

export const resyncProduct = (productKeyId: number) =>
  createAdminProductApi().post('/product/v2/products/sync/support', {
    product_key_id: productKeyId,
  });

export const startAudit = async (productKeyId: number) => {
  const response = await createAdminValidationApi().post(
    '/validation/v4/audits',
    {
      product_key_id: productKeyId,
    }
  );
  return response.data;
};

export const getAudit = async (productKeyId: number | string) => {
  const response = await createAdminValidationApi().get(
    `/validation/v4/audits${qs.stringify(
      { product_key_id: productKeyId },
      true
    )}`
  );
  return response;
};

export const getAuditContext = async (auditId: string) => {
  return await createAdminValidationApi().get(
    '/validation/v4/audits/' + auditId + '/contexts'
  );
};

export const getAuditContextDatas = async (payload: {
  auditId: number | string;
  contextId: string;
}) => {
  return await createAdminValidationApi().get(
    '/validation/v4/audits/' +
      payload.auditId +
      '/contexts/' +
      payload.contextId +
      '/product'
  );
};

export const deleteAudit = async (auditId: number | string) => {
  return await createAdminValidationApi().delete(
    '/validation/v4/audits/' + auditId
  );
};
