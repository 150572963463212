import { List } from 'immutable';
import { get, isArray } from 'lodash/fp';

export const isAsset = (item) => item.kind === 'AssetList';

const isRejectedValue = (value) => [undefined, null, ''].includes(value);

const isEmptyExpressedIn = (item) =>
  get('expressedIn', item) && isRejectedValue(get('data', item));

const isInvalidValue = (value) =>
  isRejectedValue(value) ||
  get('length', value) === 0 ||
  isEmptyExpressedIn(get(0, value));

const hasNoData = (item) =>
  isEmptyExpressedIn(item) ||
  Object.keys(item)
    .filter((key) => key !== 'type')
    .map((key) => item[key])
    .every(isInvalidValue);

const hasOnlyEmptyValues = (value) => value.every(hasNoData);

const isEmpty = (value) =>
  isRejectedValue(value) ||
  (isArray(value) && (value.length === 0 || hasOnlyEmptyValues(value)));

export const isEmptyField = (productVersion) => (node) =>
  node.kind === 'Field' &&
  !node.filterApplicableCondition &&
  isEmpty(get(`source.${node.model}`, productVersion));

export const isHiddenConditionalField = (productVersion) => (node) =>
  node.kind === 'Field' &&
  node.filterApplicableCondition &&
  !node.applicableConditionValues.includes(
    get(
      `edited.${node.filterApplicableCondition.conditionSource}`,
      productVersion
    )
  );

export const isUnlistedDisputedField =
  (disputedFields = List()) =>
  (node) => {
    if (node.kind !== 'Field') {
      return true;
    }
    if (disputedFields.size === 0) {
      return true;
    }
    return disputedFields.includes(node.model);
  };
