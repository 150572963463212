import { flow, set } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import { FETCH_ADMIN_CUSTOMER, RECEIVE_ADMIN_CUSTOMER } from '../actions/admin';
import { BillingAdminState } from '../types';

const initialState = {
  customer: undefined,
  subscriptions: undefined,
  loaded: false,
};

export default createReducer<BillingAdminState>(initialState, {
  [FETCH_ADMIN_CUSTOMER]: set('loaded', false),
  [RECEIVE_ADMIN_CUSTOMER]: (state, { payload = {} }: any) => {
    const { customer, subscriptions } = payload;
    return flow(
      set(['customer'], customer),
      set(['subscriptions'], subscriptions),
      set(['loaded'], true)
    )(state);
  },
});
