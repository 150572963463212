import classNames from 'classnames';
import { Map, Set } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';

import { GROUP_STATUS_ACTIVE, GROUP_STATUS_PREPARING } from '../../constants';
import { isGroupActive } from '../../utils';

import './group.scss';
import GroupHeader from './header';
import RecipientRow from './recipient-row';

// Exported only for testing purposes
export const computeCanUnprepareProductForGroup = (group, recipientsToAdd) =>
  // It should be preparing
  group.get('status') === GROUP_STATUS_PREPARING &&
  // No member should be synced
  !group.get('members').some((m) => m.get('isSynced')) &&
  // No recipient should be in planned to be added
  (!recipientsToAdd ||
    recipientsToAdd.intersect(group.get('members').map((m) => m.get('id')))
      .size === 0);

export const expandedButtonLabel = () => {
  return i18n.t(
    'frontproductstream.publication_summary.hide_recipients.button',
    { defaultValue: 'Hide' }
  );
};

class GroupPublicationSummary extends PureComponent {
  static propTypes = {
    productId: PropTypes.number.isRequired,
    isPublished: PropTypes.bool.isRequired,
    isExclusive: PropTypes.bool.isRequired,
    group: ImmutablePropTypes.map.isRequired,
    validationByRecipientId: ImmutablePropTypes.map,
    // Recipient activation
    canUpdateSharingUnit: PropTypes.bool.isRequired,
    addingRecipients: ImmutablePropTypes.set,
    onAddRecipient: PropTypes.func.isRequired,
    canRemoveRecipient: PropTypes.func,
    onRemoveRecipient: PropTypes.func,
    recipientsToAdd: ImmutablePropTypes.set,
    // Preparation
    canPrepareProductForGroup: PropTypes.bool,
    isPreparing: PropTypes.bool,
    onPrepare: PropTypes.func,
    onDeactivateGroups: PropTypes.func,
    onUnshare: PropTypes.func,
    isBigManufacturer: PropTypes.bool,
    subscriptions: PropTypes.array,
  };

  static defaultProps = {
    validationByRecipientId: Map(),
    addingRecipients: Set(),
    onRemoveRecipient: null,
    recipientsToAdd: Set(),

    canPrepareProductForGroup: false,
    isPreparing: false,
    onPrepare() {},
  };

  state = { expanded: false };

  onAddRecipient = (recipient) => {
    const { onAddRecipient, group } = this.props;
    onAddRecipient({ recipient, group });
  };

  onRemoveRecipient = (recipient) => {
    const { onRemoveRecipient, group } = this.props;
    onRemoveRecipient({ recipient, group });
  };

  onClickExpandButton = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  getExpandLabel(expanded) {
    if (expanded) {
      return expandedButtonLabel();
    }
    return i18n.t(
      'frontproductstream.publication_summary.show_recipients.button',
      { defaultValue: 'Show all recipients' }
    );
  }

  computeCanDeactivateGroup = (group) =>
    // product should be exclusive
    this.props.isExclusive &&
    // group should be active
    group.get('status') === GROUP_STATUS_ACTIVE &&
    // no member should be toggled
    !group.get('members').some((member) => member.get('toggled'));

  renderMembers() {
    const {
      addingRecipients,
      productId,
      isPublished,
      group,
      validationByRecipientId,
      canUpdateSharingUnit,
      onAddRecipient,
      canRemoveRecipient,
      onRemoveRecipient,
      recipientsToAdd,
      onUnshare,
      isBigManufacturer,
      subscriptions,
    } = this.props;
    const { expanded } = this.state;

    const importantRecipients = group.get('importantRecipients');
    const alwaysDisplayableRecipients = group.get(
      'alwaysDisplayableRecipients'
    );
    const additionalRecipients = group.get('additionalRecipients');
    const relationStatus = group.get('relationStatus');
    let isAddingRecipients = false;
    if (!isGroupActive(group)) {
      isAddingRecipients = addingRecipients.some((recipientId) =>
        importantRecipients.some(
          (recipient) => recipientId === recipient.get('id')
        )
      );
    }
    return (
      <div className="GroupPublicationSummaryMembers">
        {importantRecipients
          .map((recipient) => {
            const isInRecipientsToAdd = recipientsToAdd.includes(
              recipient.get('id')
            );
            const isAddingCurrentRecipient = addingRecipients.includes(
              recipient.get('id')
            );
            return (
              <RecipientRow
                key={recipient.get('id')}
                productId={productId}
                canUpdateSharingUnit={canUpdateSharingUnit}
                recipient={recipient}
                hasSpinner={isAddingCurrentRecipient}
                isAvailable
                isPublished={isPublished}
                isAdded={isInRecipientsToAdd}
                isBusy={isAddingRecipients || isAddingCurrentRecipient}
                onAdd={onAddRecipient ? this.onAddRecipient : null}
                onRemove={
                  canRemoveRecipient &&
                  onRemoveRecipient &&
                  canRemoveRecipient(recipient)
                    ? this.onRemoveRecipient
                    : null
                }
                onUnshare={onUnshare}
                validation={
                  validationByRecipientId.get(recipient.get('id')) || Map()
                }
                groupRelationStatus={relationStatus}
                isBigManufacturer={isBigManufacturer}
                subscriptions={subscriptions}
              />
            );
          })
          .toArray()}
        {alwaysDisplayableRecipients.size > 0 &&
          alwaysDisplayableRecipients
            .map((recipient) => (
              <RecipientRow
                key={recipient.get('id')}
                productId={productId}
                isPublished={isPublished}
                canUpdateSharingUnit={canUpdateSharingUnit}
                recipient={recipient}
                isAvailable={false}
                groupRelationStatus={relationStatus}
              />
            ))
            .toArray()}
        {additionalRecipients.size > 0 && (
          <Button
            link
            className="GroupPublicationSummary__AdditionalRecipientsButton"
            content={
              <div className="GroupPublicationSummary__AdditionalRecipientsButtonContent">
                <i
                  className={classNames('mdi', {
                    'mdi-chevron-up': expanded,
                    'mdi-chevron-down': !expanded,
                  })}
                />
                <span>
                  {this.getExpandLabel(expanded)} ({additionalRecipients.size})
                </span>
                <i
                  className={classNames('mdi', {
                    'mdi-chevron-up': expanded,
                    'mdi-chevron-down': !expanded,
                  })}
                />
              </div>
            }
            onClick={this.onClickExpandButton}
          />
        )}
        {expanded &&
          additionalRecipients
            .map((recipient) => (
              <RecipientRow
                key={recipient.get('id')}
                productId={productId}
                isPublished={isPublished}
                canUpdateSharingUnit={canUpdateSharingUnit}
                recipient={recipient}
                isAvailable={false}
                groupRelationStatus={relationStatus}
              />
            ))
            .toArray()}
      </div>
    );
  }

  render() {
    const {
      group,
      isExclusive,
      canPrepareProductForGroup,
      onPrepare,
      isPreparing,
      recipientsToAdd,
      onDeactivateGroups,
    } = this.props;

    const canUnprepareProductForGroup = computeCanUnprepareProductForGroup(
      group,
      recipientsToAdd
    );

    const canDeactivateGroup = this.computeCanDeactivateGroup(group);

    return (
      <div className="GroupPublicationSummary">
        <GroupHeader
          group={group}
          isExclusive={isExclusive}
          canPrepareProductForGroup={canPrepareProductForGroup}
          canUnprepareProductForGroup={canUnprepareProductForGroup}
          canDeactivateGroup={canDeactivateGroup}
          onDeactivateGroups={onDeactivateGroups}
          isPreparing={isPreparing}
          onPrepare={onPrepare}
        />
        {this.renderMembers()}
      </div>
    );
  }
}

export default GroupPublicationSummary;
