import { curry, flow, get, identity, isString, over, set } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { getDisplayName } from 'core/api/productversion';
import { isActivelyShared } from 'core/api/retailerproductversion';
import { isManufacturer, isRetailer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_DATA_OPS,
  FEATURE_DATA_OPS_RETAILER_HIERARCHIES,
  RELEASE_DATA_OPS_MANUFACTURERS,
} from 'modules/feature-flag/constants';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import {
  formatLogisticalUnitEntityId,
  parseLogisticalUnitEntityId,
} from 'modules/logistical-hierarchies/helpers';
import {
  selectCurrentLanguage,
  selectEditedProductVersion,
  selectSourceProductVersion,
} from 'reducers/productVersion';
import { selectIsRetailer, selectUser } from 'reducers/user/selectors';
import { UserImmutable } from 'types';
import { ProductVersionData } from 'types/product';
import { GlobalState } from 'types/redux';

import { DATA_OPS_REDUCER } from './constants';
import {
  DataOpsNewPatch,
  DataOpsPatch,
  DataOpsPatchState,
  DataOpsPatchesState,
  DataOpsState,
} from './types';

export const dataOpsPatchKey = (
  entityId: number | string,
  fieldName: string
) => {
  let id = entityId;
  if (isString(entityId) && /_\d+$/.test(entityId)) {
    [, id] = parseLogisticalUnitEntityId(entityId);
  }
  return [id, fieldName].join('/');
};

export const dataOpsLuEntityId = (internalRootId: string, gtin: string) =>
  formatLogisticalUnitEntityId(internalRootId, gtin, null);

export const parseDataOpsKey = (patch: DataOpsPatch) => {
  const [entityId, fieldName] = patch.key.split('/');
  return { entityId, fieldName };
};

export const isDataOpsPatcher = (user: UserImmutable) => isRetailer(user);

export const isDataOpsReceiver = (user: UserImmutable) => isManufacturer(user);

export const selectIsDataOpsPatcher = flow(selectUser, isDataOpsPatcher);

export const selectIsDataOpsReceiver = flow(selectUser, isDataOpsReceiver);

export const selectHasDataOps = flow(
  over<boolean>([
    selectHasFeature(FEATURE_DATA_OPS),
    selectIsDataOpsPatcher,
    selectIsDataOpsReceiver,
  ]),
  ([hasDataOpsFeature, isPatcher, isReceiver]) =>
    (isPatcher && hasDataOpsFeature) || isReceiver
);

export const selectHasDataOpsForManufacturers = flow(selectUser, (user) =>
  hasFeature(user, RELEASE_DATA_OPS_MANUFACTURERS)
);

export const selectHasDataOpsRetailerHierarchy = flow(selectUser, (user) =>
  hasFeature(user, FEATURE_DATA_OPS_RETAILER_HIERARCHIES)
);

const selectDataOpsState: (state: GlobalState) => DataOpsState = get([
  MODULE_REDUCER_KEY,
  DATA_OPS_REDUCER,
]);

export const selectDataOpsPatches = flow<
  [GlobalState],
  DataOpsState,
  DataOpsPatchesState
>(selectDataOpsState, get(['patches']));

export const selectDataOpsPatch = curry<
  [number | string, string],
  GlobalState | DataOpsPatchesState,
  DataOpsPatchState | undefined
>(([entityId, fieldName], state) =>
  get(
    [dataOpsPatchKey(entityId, fieldName)],
    selectDataOpsPatches(state) || state
  )
);

export const selectDataOpsDirtyPatchList = flow<
  [GlobalState],
  DataOpsPatchesState,
  DataOpsPatch[] | DataOpsNewPatch[]
>(selectDataOpsPatches, (patches) =>
  Object.values(patches)
    .filter((patchState) => patchState.dirty)
    .map((patchState) => patchState.data[0])
);

export const selectDataOpsEntityIdToProductKeyId = flow<
  [GlobalState],
  DataOpsState,
  DataOpsState['entityIdToProductKeyId']
>(selectDataOpsState, get(['entityIdToProductKeyId']));

export const selectDataOpsProductKeyIdToEntityId = flow<
  [GlobalState],
  DataOpsState,
  DataOpsState['productKeyIdToEntityId']
>(selectDataOpsState, get(['productKeyIdToEntityId']));

export const selectDataOpsMessages = flow<
  [GlobalState],
  DataOpsState,
  DataOpsState['pendingMessages']
>(selectDataOpsState, get(['pendingMessages']));

export const selectDataOpsDisplayName = flow<
  [GlobalState],
  [GlobalState, ProductVersionData['data'], any, boolean, boolean],
  string
>(
  over([
    identity,
    selectEditedProductVersion,
    selectCurrentLanguage,
    selectHasDataOps,
    selectIsRetailer,
  ]) as any,
  ([
    state,
    currentProductVersion,
    currentLanguage,
    hasDataOps,
    isUserRetailer,
  ]) => {
    let productVersion = currentProductVersion;
    if (hasDataOps && isUserRetailer) {
      const fieldNames = ['namePublicLong', 'nameLegal'];
      for (const fieldName of fieldNames) {
        const patch = selectDataOpsPatch([productVersion.id, fieldName], state);
        if (patch?.data?.[0]?.dataNew !== undefined) {
          productVersion = set(
            [fieldName],
            patch.data[0].dataNew,
            productVersion
          );
        }
      }
    }
    return getDisplayName(productVersion, currentLanguage);
  }
);

export const selectDataOpsAmendingStatus = flow<
  [GlobalState],
  DataOpsState,
  DataOpsState['amendingStatus']
>(selectDataOpsState, get(['amendingStatus']));

export const selectIsActivelyShared = flow(
  over([selectSourceProductVersion, selectIsDataOpsPatcher]),
  ([pv, isPatcher]) => (isPatcher ? isActivelyShared(pv) : true)
);
