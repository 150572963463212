import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';
import { scrollSmoothly, topCenter } from 'utils/scroll';

import './errors-stepper.scss';

class ErrorsStepper extends PureComponent {
  static propTypes = {
    errors: PropTypes.array.isRequired,
  };

  static errorClassName = 'ErrorsStepper__error';

  static highlightElements(errors) {
    errors.forEach((e) => {
      e.element.classList.add(ErrorsStepper.errorClassName);
    });
  }

  static unHighlightElements() {
    Array.from(
      document.querySelectorAll(`.${ErrorsStepper.errorClassName}`)
    ).forEach((element) => {
      element.classList.remove(ErrorsStepper.errorClassName);
    });
  }

  static enrichErrorsWithElements(errors) {
    // Remove the `id` selectors if applicable (autocompletes).
    const cleanedErrors = errors
      .map((e) => ({
        ...e,
        path: e.path
          .split('.')
          .filter((p) => p !== 'id')
          .join('-'),
      }))
      // Put the errors with displayable messages first.
      .sort((a, b) => {
        if (
          (a.displayable && b.displayable) ||
          (!a.displayable && !b.displayable)
        ) {
          return 0;
        }
        return a.displayable ? -1 : 1;
      });

    return (
      cleanedErrors
        // Deduplicate those with no displayable messages.
        .filter(
          (e, i) =>
            e.displayable ||
            cleanedErrors.findIndex((err) => err.path === e.path) === i
        )
        .map((e) => ({
          ...e,
          element: document.querySelector(`[id*="${e.path}"]:not([id*=help-])`),
        }))
        .filter((e) => !!e.element)
        .sort(
          (a, b) =>
            a.element.getBoundingClientRect().top -
            b.element.getBoundingClientRect().top
        )
        .map((e, index) => ({ ...e, index }))
    );
  }

  state = {
    currentError: null,
  };

  componentDidMount() {
    this.onStep(1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      ErrorsStepper.unHighlightElements();
      // Reset the stepper.
      this.onStep(1, null);
    }
  }

  componentWillUnmount() {
    ErrorsStepper.unHighlightElements();
  }

  onPrevious = () => {
    const { currentError } = this.state;
    this.onStep(-1, currentError);
  };

  onNext = () => {
    const { currentError } = this.state;
    this.onStep(1, currentError);
  };

  onStep(inc, currentError) {
    const { errors } = this.props;
    const enrichedErrors = ErrorsStepper.enrichErrorsWithElements(errors);
    if (enrichedErrors.length === 0) {
      return;
    }
    ErrorsStepper.highlightElements(enrichedErrors);
    let currentIndex = -1;
    if (currentError) {
      currentIndex = currentError.index;
    }
    const nextIndex =
      (enrichedErrors.length + currentIndex + inc) % enrichedErrors.length;

    scrollSmoothly({ top: topCenter(enrichedErrors[nextIndex].element) });
    this.setState({ currentError: enrichedErrors[nextIndex] });
  }

  renderUnknownError() {
    return (
      <div className="ErrorsStepper">
        <div className="ErrorsStepper__details">
          {i18n.t(
            'frontproductstream.core.validation_error_stepper.unknown_error',
            {
              defaultValue:
                'Validation of the data has failed, please contact our Support Team.',
            }
          )}
        </div>
      </div>
    );
  }

  renderLocatedErrors() {
    const { errors } = this.props;
    const { currentError } = this.state;
    return (
      <div className="ErrorsStepper">
        <div className="ErrorsStepper__details">
          <div className="ErrorsStepper__message">
            <strong>
              {i18n.t(
                'frontproductstream.core.validation_error_stepper.error_count',
                {
                  defaultValue: '{{errors}} errors were found',
                  errors: errors.length,
                  count: errors.length,
                }
              )}
            </strong>
            ,&nbsp;
            {i18n.t(
              'frontproductstream.core.validation_error_stepper.fix_before_saving',
              {
                defaultValue:
                  'please correct before saving (go to details by using the navigation arrows on the right)',
              }
            )}
          </div>
          {currentError && currentError.displayable && currentError.message && (
            <div className="ErrorsStepper__message">{currentError.message}</div>
          )}
        </div>
        <div>
          <Button
            className="ErrorsStepper__button ErrorsStepper__button--left"
            content={<span className="mdi mdi-chevron-left" />}
            onClick={this.onPrevious}
            white
          />
          <Button
            className="ErrorsStepper__button ErrorsStepper__button--right"
            content={<span className="mdi mdi-chevron-right" />}
            onClick={this.onNext}
            white
          />
        </div>
      </div>
    );
  }

  render() {
    const { currentError } = this.state;
    if (!currentError) {
      return this.renderUnknownError();
    }
    return this.renderLocatedErrors();
  }
}

export default ErrorsStepper;

export const renderStepper = (errors) => <ErrorsStepper errors={errors} />;
