import { Map } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS,
  RECEIVE_PROFILE,
  RECEIVE_USER_ORGANIZATION_PERMISSIONS,
  UPDATE_USERSETTING,
  UPDATE_USER_FIELD,
} from 'constants/events/user';
import { LOGOUT } from 'modules/auth/shared/actions/types';

const initialState = Map();

export default createReducer(initialState, {
  [RECEIVE_PROFILE]: (state, action) => state.merge(action.user),

  [UPDATE_USERSETTING]: (state, { key, value }) =>
    state.setIn(['settings', key], value),

  [UPDATE_USER_FIELD]: (state, { payload: { path, value } }) =>
    state.setIn(path, value),

  [RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS]: (
    state,
    { payload: { productSegments } }
  ) => state.setIn(['permissions', 'productversion'], productSegments),

  [RECEIVE_USER_ORGANIZATION_PERMISSIONS]: (
    state,
    { payload: { permissions } }
  ) => state.setIn(['organizationPermissions'], permissions),

  [LOGOUT]: () => initialState,
});
