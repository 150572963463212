import classNames from 'classnames';
import Immutable from 'immutable';
import moment from 'moment';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import {
  RELEASE_DATA_MATURITY_DEADLINE_V2,
  RELEASE_DATA_MATURITY_DEADLINE_V3,
  hasFeature,
} from 'modules/feature-flag';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { sortAsc } from 'utils/sort';

import './index.scss';

type MaturityDetail = Immutable.Map<string, boolean | number | string>;
type MaturityDetails = Immutable.Map<string, MaturityDetail>;

export interface MaturitiesSummary {
  upcoming_applicability_deadline: string;
  require_action: boolean;
  details: MaturityDetails;
}

interface MaturitiesSummaryImmutable extends Immutable.Map<string, any> {
  toJS(): MaturitiesSummary;
  get<K extends keyof MaturitiesSummary>(key: K): MaturitiesSummary[K];
}

interface Props {
  data?: MaturitiesSummaryImmutable;
  user: UserImmutable;
}

const Information = ({ children }) => {
  return (
    <span
      className="MaturityDeadlineCell__information"
      data-testid="maturity-cell-information"
    >
      {children}
    </span>
  );
};

const MaturityDeadlineCellDisplay = ({ children, user }) => {
  return (
    <div
      className={classNames('MaturityDeadlineCell', {
        ['MaturityDeadlineCell--not-released']: !hasFeature(
          user,
          RELEASE_DATA_MATURITY_DEADLINE_V2
        ),
      })}
    >
      {children}
    </div>
  );
};

const MaturityDeadlineCellTooltip = ({ children, maturitiesDetails }) => {
  const id = useMemo(() => uuid(), []);
  let tooltipMessage;

  const now = moment();
  const nowISOString = now.toISOString();
  const sortedMaturities = maturitiesDetails
    .toArray()
    .filter((maturity) => {
      return (
        maturity.get('requested') &&
        maturity.get('applicability_deadline') &&
        (!maturity.get('status') ||
          moment.utc(maturity.get('applicability_deadline')).toISOString() >=
            nowISOString)
      );
    })
    .map((maturity) => {
      const isStatusOk = maturity.get('status');
      const isDeadlineInTheFuture =
        moment.utc(maturity.get('applicability_deadline')).toISOString() >=
        nowISOString;
      const maturityStatusClassName = isStatusOk
        ? 'MaturityDeadlineCell__details--ok'
        : isDeadlineInTheFuture
        ? 'MaturityDeadlineCell__details--nok-future'
        : 'MaturityDeadlineCell__details--nok-past';
      return maturity.mergeDeep({
        maturityStatusClassName,
      });
    })
    .sort((a, b) =>
      sortAsc(
        `${moment.utc(a.get('applicability_deadline')).toISOString()}`,
        `${moment.utc(b.get('applicability_deadline')).toISOString()}`
      )
    );

  if (sortedMaturities.length) {
    tooltipMessage = (
      <>
        {sortedMaturities.map((maturity, index) => (
          <div
            key={`maturity-${index}-${id}`}
            className={classNames(
              'alk-flex',
              'alk-flex-center',
              'MaturityDeadlineCell__details'
            )}
            data-testid="maturity-cell-details"
          >
            <span
              className={classNames(maturity.get('maturityStatusClassName'))}
            >
              {maturity.get('label')} ({maturity.get('successful')}/
              {maturity.get('total')}){' '}
            </span>
            <span className="alk-flex-end">
              {moment
                .utc(maturity.get('applicability_deadline'))
                .local()
                .format('L')}
            </span>
          </div>
        ))}
      </>
    );
  } else {
    tooltipMessage = (
      <div>{i18n.t('No maturity to come for this product.')}</div>
    );
  }

  return (
    <LazyTooltip
      id={`tooltip-${id}`}
      tooltipLabel={tooltipMessage}
      block
      hoverable={true}
    >
      {children}
    </LazyTooltip>
  );
};

const MaturityDeadlineCell = ({ data, user }: Props) => {
  let body;

  const allDetails = data?.get('details');
  const upcomingDeadline = data?.get('upcoming_applicability_deadline');

  if (!upcomingDeadline) {
    body = (
      <Information>
        <i className="mdi mdi-calendar-blank" /> {i18n.t('nothing coming')}
      </Information>
    );
  } else if (!data?.get('require_action')) {
    body = (
      <Information>
        <i className="mdi mdi-calendar-check" /> {i18n.t('nothing to do')}
      </Information>
    );
  } else {
    // Some previously indexed product don't have TZ present: utc was assumed
    const upcomingDeadlineTZaware = moment.utc(upcomingDeadline).local();

    const icon = upcomingDeadlineTZaware.isBefore(moment())
      ? 'calendar-alert'
      : 'calendar-clock';

    body = (
      <span>
        <i className={`mdi mdi-${icon}`} />{' '}
        {upcomingDeadlineTZaware.format('L')}
      </span>
    );
  }

  if (hasFeature(user, RELEASE_DATA_MATURITY_DEADLINE_V3)) {
    return (
      <MaturityDeadlineCellDisplay user={user}>
        <MaturityDeadlineCellTooltip maturitiesDetails={allDetails}>
          {body}
        </MaturityDeadlineCellTooltip>
      </MaturityDeadlineCellDisplay>
    );
  } else {
    return (
      <MaturityDeadlineCellDisplay user={user}>
        {body}
      </MaturityDeadlineCellDisplay>
    );
  }
};

export default MaturityDeadlineCell;
