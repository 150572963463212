import PropTypes from 'prop-types';
import { Component } from 'react';

import { withNavigate } from 'hocs';
import { PRODUCT_DASHBOARD } from 'modules/redirect';
import * as routes from 'routes';
import { withQuery } from 'utils/query';
import { fill } from 'utils/routing';
import { track } from 'utils/tracking';

class ProductAction extends Component {
  static propTypes = {
    productKeyId: PropTypes.number,
    reference: PropTypes.string,
    targetMarketId: PropTypes.number,
    recipient: PropTypes.object,
    children: PropTypes.node.isRequired,
    action: PropTypes.string,
    fromCatalog: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fromCatalog: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onAcceptAssignation() {
    const { reference, recipient, fromCatalog } = this.props;
    track({
      category: 'assignation',
      action: 'assignation_accept',
      label: `reference#${reference}`,
    });
    if (fromCatalog) {
      const sourceOrg = recipient.getIn([
        'alkemics',
        'assignation',
        'source_organizations',
        '0',
      ]);
      track({
        category: 'assignation',
        action: 'assignation_accept_from_catalog',
        label: `reference#${reference}`,
        source_organization_id: sourceOrg.get('id'),
        source_organization_name: sourceOrg.get('name'),
      });
    }
    this.goToProductDashboard();
  }

  callActionByType = () => {
    const { action } = this.props;
    switch (action) {
      case 'attribution':
        this.onAcceptAssignation();
        break;
      case 'share':
        this.goToProductDashboard();
        break;
      default:
    }
  };

  goToProductDashboard = () => {
    const { productKeyId, reference, targetMarketId } = this.props;
    if (productKeyId) {
      this.props.navigate(fill(routes.productChat, productKeyId));
    } else {
      this.props.navigate(
        withQuery(routes.redirect, {
          action: PRODUCT_DASHBOARD,
          gtin: reference,
          targetMarket: targetMarketId,
        })
      );
    }
  };

  render() {
    const { children } = this.props;
    return (
      <span onClick={this.callActionByType} data-testid="ProductAction">
        {children}
      </span>
    );
  }
}

export default withNavigate(ProductAction);
