import { List } from 'immutable';

import { getActiveShares } from 'core/api/productversion';
import { selectSupportedLocalesForRecipientsIds } from 'modules/recipients/reducers';
import { selectEditedProductVersion } from 'reducers/productVersion';
import { keyBy } from 'utils/fn';
import { get, size } from 'utils/immutable';

import { applyRulesForSpecificRecipients } from './common';
import { RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS } from './events';

export const applyRulesForActiveSharedRecipients =
  (entityKind: string) => async (dispatch, getState) => {
    const state = getState();
    const productVersion = selectEditedProductVersion(state);
    const activeShares = getActiveShares(productVersion);

    if (!size(activeShares)) {
      return List();
    }

    const activeRecipientsById = keyBy(
      activeShares.map((share) => get(share, 'targetOrganization')),
      'id'
    );
    const recipientIds = Object.keys(activeRecipientsById).map(Number);
    const languages =
      selectSupportedLocalesForRecipientsIds(state)(recipientIds);

    const results = await dispatch(
      applyRulesForSpecificRecipients({
        entityKind,
        recipientIds,
        languages,
      })
    );
    if (results) {
      dispatch({
        type: RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS,
        data: results,
        versionId: get(results, 'entity.id'),
      });
    }
    return results;
  };
