import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import Dropdown from 'components/ui/dropdown';
import {
  selectHasRecipeFeature,
  selectHasVideoFeature,
} from 'modules/feature-flag/selectors';
import i18n from 'utils/i18n';

import { AssetTypeV2, FieldAction } from '../types';

import { AddMediaModal } from './AddMediaModal';

type Option = {
  label: string;
  onClick: () => void;
};

type AddMediaDropdownProps = {
  fieldActions: FieldAction[];
  productKeyId: number;
  items: AssetTypeV2[];
  updateEntities: () => void;
};

export const AddMediaDropdown = ({
  fieldActions,
  productKeyId,
  items,
  updateEntities,
}: AddMediaDropdownProps) => {
  const hasRecipeFeature = useSelector(selectHasRecipeFeature);
  const hasVideoFeature = useSelector(selectHasVideoFeature);

  const [currentOpenModal, setCurrentOpenModal] = useState<
    FieldAction['render'] | null
  >(null);

  const actionsByRender = fieldActions.reduce(
    (acc, f) => ({
      ...acc,
      [f.render]: f,
    }),
    {}
  );

  const getUpgradeLabel = ({
    hasFeature,
    label,
  }: {
    hasFeature: boolean;
    label: string;
  }) => {
    const upgradeTranslation = i18n.t(
      'frontproducstream.asset_list.feature_upgrade.suffix',
      { defaultValue: 'Upgrade!' }
    );

    return !hasFeature ? `${label} - ${upgradeTranslation}` : label;
  };

  const getItemLabel = (fieldAction: FieldAction) => {
    const { content } = fieldAction;

    switch (fieldAction.render) {
      case 'addRecipe':
        return getUpgradeLabel({
          hasFeature: hasRecipeFeature,
          label: content,
        });
      case 'addVideo':
        return getUpgradeLabel({ hasFeature: hasVideoFeature, label: content });
      default:
        return content;
    }
  };

  const options = fieldActions.reduce<Option[]>((acc, field) => {
    const { render } = field;

    const alreadyHasMaxRecipe =
      render === 'addRecipe' &&
      items &&
      items.some((item) => item._type === 'ProductEnrichedContent');

    if (alreadyHasMaxRecipe) {
      return acc;
    }

    return [
      ...acc,
      {
        label: getItemLabel(field),
        onClick: () => setCurrentOpenModal(render),
      },
    ];
  }, []);

  const handleCloseModal = useCallback(() => {
    setCurrentOpenModal(null);
  }, []);

  const handleSuccess = useCallback(() => {
    updateEntities();

    setCurrentOpenModal(null);
  }, [updateEntities]);

  if (!options.length) {
    return null;
  }

  return (
    <>
      <div>
        <Dropdown
          label={
            <span>
              {i18n.t(
                'frontproductstream.asset_list.see_and_edit_all_media.add_media_dropdown.label',
                {
                  defaultValue: 'Add a media',
                }
              )}
            </span>
          }
          options={options}
        />
      </div>
      {currentOpenModal && (
        <AddMediaModal
          fieldAction={actionsByRender[currentOpenModal]}
          productKeyId={productKeyId}
          onCloseModal={handleCloseModal}
          onSuccess={handleSuccess}
          assets={items}
        />
      )}
    </>
  );
};
