import { createAction, createActionWithPayload } from 'utils/redux';

import {
  DATA_OPS_ACCEPT_PATCHES,
  DATA_OPS_ACCEPT_PATCHES_DONE,
  DATA_OPS_BULK_PATCH_FIELD,
  DATA_OPS_BULK_STORE_PATCH,
  DATA_OPS_CANCEL_PATCH,
  DATA_OPS_CANCEL_PATCH_DONE,
  DATA_OPS_EDIT_FIELD,
  DATA_OPS_FILTER_PATCHES_RECIPIENTS,
  DATA_OPS_INIT,
  DATA_OPS_PATCHES_RECEIVED,
  DATA_OPS_PATCH_FIELD,
  DATA_OPS_REFUSE_PATCHES,
  DATA_OPS_REFUSE_PATCHES_DONE,
  DATA_OPS_REMOVE_PATCHES,
  DATA_OPS_SAVE_PATCHES,
  DATA_OPS_SAVE_PATCHES_DONE,
  DATA_OPS_STORE_PATCH,
} from './constants';
import { DataOpsNewPatch, DataOpsPatch, DataOpsState } from './types';

export const initDataOps =
  createActionWithPayload<
    Pick<
      DataOpsState,
      'productKeyId' | 'entityIdToProductKeyId' | 'productKeyIdToEntityId'
    >
  >(DATA_OPS_INIT);

export const editDataOpsField = createActionWithPayload<{
  entityId: number | string;
  entityKind: string;
  isReadOnly: boolean;
  model: string;
}>(DATA_OPS_EDIT_FIELD);

export const cancelDataOpsPatch = createActionWithPayload<{
  patch: DataOpsPatch | DataOpsNewPatch;
  label: string;
}>(DATA_OPS_CANCEL_PATCH);

export const cancelDataOpsPatchDone = createActionWithPayload<{
  patch: DataOpsPatch | DataOpsNewPatch;
  error?: any;
}>(DATA_OPS_CANCEL_PATCH_DONE);

export const dataOpsPatchesReceived = createActionWithPayload<{
  patches: DataOpsPatch[];
}>(DATA_OPS_PATCHES_RECEIVED);

export const patchDataOpsField =
  createActionWithPayload<
    [
      label: string,
      model: string,
      newValue: any,
      entityId: number | string,
      entityKind: string,
      isDirty?: boolean,
      ignoreField?: boolean,
      assetId?: number
    ]
  >(DATA_OPS_PATCH_FIELD);

export const bulkPatchDataOpsField = createActionWithPayload<
  // [field.model, newValue, entityId, entityKind, isDirty, ignoreField]
  (
    | [string, any, number | string, string]
    | [string, any, number | string, string, boolean]
    | [string, any, number | string, string, boolean, boolean]
  )[]
>(DATA_OPS_BULK_PATCH_FIELD);

export const storeDataOpsPatch = createActionWithPayload<{
  entityId: number | string;
  fieldName: string;
  patch: DataOpsPatch | DataOpsNewPatch;
  label: string;
  entityKind: string;
}>(DATA_OPS_STORE_PATCH);

export const bulkStoreDataOpsPatch = createActionWithPayload<
  {
    entityId: number | string;
    fieldName: string;
    patch: DataOpsPatch | DataOpsNewPatch;
    entityKind: string;
  }[]
>(DATA_OPS_BULK_STORE_PATCH);

export const saveDataOpsPatches = createAction(DATA_OPS_SAVE_PATCHES);

export const saveDataOpsPatchesDone = createActionWithPayload<{ error?: any }>(
  DATA_OPS_SAVE_PATCHES_DONE
);

export const acceptDataOpsPatches = createActionWithPayload<{
  patches: DataOpsPatch[];
  all?: boolean;
}>(DATA_OPS_ACCEPT_PATCHES);

export const acceptDataOpsPatchesDone = createActionWithPayload<{
  patches: DataOpsPatch[];
  isPatcher: boolean;
  all?: boolean;
}>(DATA_OPS_ACCEPT_PATCHES_DONE);

export const refuseDataOpsPatches = createActionWithPayload<{
  patches: DataOpsPatch[];
  all?: boolean;
}>(DATA_OPS_REFUSE_PATCHES);

export const refuseDataOpsPatchesDone = createActionWithPayload<{
  patches: DataOpsPatch[];
  isPatcher: boolean;
  all?: boolean;
}>(DATA_OPS_REFUSE_PATCHES_DONE);

export const removeDataOpsPatches = createActionWithPayload<DataOpsPatch[]>(
  DATA_OPS_REMOVE_PATCHES
);

export const filterDataOpsPatchesByProductKeyId = createActionWithPayload<{
  productKeyId: number | string;
}>(DATA_OPS_FILTER_PATCHES_RECIPIENTS);
