import { ROOM_TYPE_ORGANIZATION } from 'constants/room';
import { createRoom } from 'modules/chat/api';
import { get } from 'utils/immutable';

import { MessageToSend } from '../types';

const sendMessage = (messageToSend: MessageToSend) => async () => {
  let result;
  try {
    result = await createRoom(
      messageToSend.recipientId,
      undefined,
      ROOM_TYPE_ORGANIZATION,
      messageToSend.message
    );
  } catch (e: any) {
    const message = get(
      e,
      ['data', 'message'],
      'error while sending message to  ' +
        messageToSend.recipientId +
        ' from retailer ' +
        messageToSend.retailerId
    );
    throw Error(message);
  }
  return result;
};

export default sendMessage;
