import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Textarea } from '@alkem/react-ui-inputs';

import Modal from 'components/ui/modal';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_CORRECTION_MESSAGE,
  FEATURE_PERMISSIONS_V3_PRODUCT,
} from 'modules/feature-flag/constants';
import { CHAT_PERMISSION, PRODUCT_PERMISSION } from 'modules/permissions/const';
import {
  hasPermissionModule,
  hasPermissionV3,
} from 'modules/permissions/utils';
import { requestChanges } from 'modules/product-page/modules/normalized-comments/actions';
import { selectIsSending } from 'modules/product-page/modules/normalized-comments/selectors';
import { ValidationWorkflowType } from 'modules/validation';
import { selectUser } from 'reducers/user/selectors';
import i18n from 'utils/i18n';

import './index.scss';

function formatMessage(entities, user) {
  const formattedNormalizedComments = entities
    .filter((e) => e.type === ValidationWorkflowType.NORMALIZED_COMMENT)
    .map((e) => `  - ${e.formattedMessage}`)
    .join('\n');
  const formattedDataOpsPatches = entities
    .filter((e) => e.type === ValidationWorkflowType.PATCH)
    .map((e) => `  - ${e.formattedMessage}`)
    .join('\n');

  if (hasFeature(user, FEATURE_CORRECTION_MESSAGE)) {
    return (
      (formattedNormalizedComments.length
        ? i18n.t(
            'Hello,\nSome fields seem to be incorrect:\n{{formattedNormalizedComments}}\nCould you please correct them ? Thank you in advance.',
            { formattedNormalizedComments }
          )
        : '') +
      (formattedDataOpsPatches.length
        ? i18n.t('Some fields were patched:\n{{formattedDataOpsPatches}}\n', {
            formattedDataOpsPatches,
          })
        : '')
    );
  }

  return (
    (formattedNormalizedComments.length
      ? i18n.t(
          'Hello,\nSome fields seem to be incorrect:\n{{formattedNormalizedComments}}\nThank you for taking adequate measures as soon as possible.',
          { formattedNormalizedComments }
        )
      : '') +
    (formattedDataOpsPatches.length
      ? i18n.t('Some fields were patched:\n{{formattedDataOpsPatches}}\n', {
          formattedDataOpsPatches,
        })
      : '')
  );
}

const mapStateToProps = createStructuredSelector({
  isSending: selectIsSending,
  user: selectUser,
});

class MessageModalView extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    entities: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
    isSending: PropTypes.bool,
    user: PropTypes.object.isRequired,
  };

  state = {
    message: formatMessage(this.props.entities, this.props.user),
  };

  componentDidUpdate(prevProps) {
    if (prevProps.entities !== this.props.entities) {
      // eslint-disable-next-line
      this.setState({
        message: formatMessage(this.props.entities, this.props.user),
      });
    }
  }

  onChange = (event) => {
    this.setState({ message: event.target.value });
  };

  requestModifications = (canSendMessage) => {
    const { dispatch, isSending } = this.props;
    if (!isSending) {
      const { message } = this.state;
      dispatch(requestChanges({ message, canSendMessage }));
    }
  };

  render() {
    const { closeModal, entities, isSending, user } = this.props;
    const { message } = this.state;

    const canSendMessage = hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)
      ? hasPermissionModule(user, PRODUCT_PERMISSION, CHAT_PERMISSION)
      : true;

    const label =
      entities.length === 1
        ? i18n.t('Request 1 change')
        : i18n.t('Request {{count}} changes', { count: entities.length });

    return (
      <Modal
        title={i18n.t('Send the change request')}
        className="MessageModal"
        confirmButtonText={label}
        isProcessing={isSending}
        onConfirm={() => this.requestModifications(canSendMessage)}
        onClose={closeModal}
      >
        <div>{i18n.t('Requested changes:')}</div>
        <ul>
          {entities.map((e, index) => (
            <li key={`modif-request-${index}`}>
              <span className="MessageModal__summaryLine">{e.label}</span>
              {e.value ? ` - ${e.value}` : ''}
            </li>
          ))}
        </ul>
        {canSendMessage && (
          <>
            <div className="MessageModal__textareaHeader">
              <h3>{i18n.t('Message for the manufacturer - preview')} </h3>
              <div>
                {i18n.t('Please add any additionnal data you feel necessary')}
              </div>
            </div>
            <Textarea
              id="inputmessage"
              placeholder=""
              value={message}
              onChange={this.onChange}
              maxLength={2000}
              rows={entities.length + 5}
              focusOnMount
            />
          </>
        )}
      </Modal>
    );
  }
}

export const MessageModal = connect(mapStateToProps)(MessageModalView);
