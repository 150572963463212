import { PureComponent } from 'react';

import ProductReference from 'components/ui/product-reference';
import {
  getLogisticalTypePackagingLabel,
  getTypePackagingCode,
} from 'constants/typePackaging';
import {
  getIsConsumerUnit,
  getTypePackaging,
  getTypePackagingId,
} from 'core/api/productversion';
import i18n from 'utils/i18n';

import { getReference } from '../../../../core/api/product';
import { getMainHierarchyUnit } from '../../helpers';
import {
  CurrentVersionInfo,
  Data,
  DataMap,
  HierarchyMap,
} from '../../structures';
import LogisticalHierarchyIcon from '../icon';
import '../unit/unit.scss';

import './one-line.scss';

interface Props {
  rootInternalId: string;
  dataMap: DataMap;
  hierarchyMap: HierarchyMap;
  currentVersionInfo: CurrentVersionInfo;
}

export default class OneLineHierarchy extends PureComponent<Props> {
  private getVersionData(data: Data) {
    const { currentVersionInfo } = this.props;
    if (
      getReference(data) &&
      getReference(data) === getReference(currentVersionInfo)
    ) {
      return currentVersionInfo;
    }
    return data.version;
  }

  private renderLabel() {
    const { rootInternalId, dataMap } = this.props;
    const rootData = dataMap[rootInternalId];
    if (!rootData.gtin && !rootData.productIdentifier) {
      return null;
    }

    const rootVersion = this.getVersionData(rootData);
    const type = getLogisticalTypePackagingLabel(
      getTypePackagingCode(getTypePackaging(rootVersion)),
      getIsConsumerUnit(rootVersion),
      true
    );
    return (
      <span className="OneLineHierarchy__reference">
        {rootData.gtin
          ? i18n.t(
              'frontproductstream.one_line_logistical_hierarchy.type_gtin.text',
              {
                type: type,
                defaultValue: '{{type}} GTIN:',
              }
            )
          : i18n.t(
              'frontproductstream.one_line_logistical_hierarchy.type_identifier.text',
              {
                type: type,
                defaultValue: '{{type}} IDENTIFIER:',
              }
            )}
        <ProductReference
          reference={rootData.gtin || rootData.productIdentifier}
          inline
        />
      </span>
    );
  }

  private renderIcon(
    internalId: string,
    data: Data,
    quantity?: number | string | null
  ) {
    const version = this.getVersionData(data);
    return (
      <LogisticalHierarchyIcon
        key={internalId}
        packagingTypeId={getTypePackagingId(version)}
        quantity={quantity}
      />
    );
  }

  private renderLevels(
    internalId: string,
    mainHierarchyUnit: { id: string } | null,
    quantity?: number | string | null
  ) {
    const { dataMap, hierarchyMap } = this.props;
    const data = dataMap[internalId];
    const children = hierarchyMap[internalId];

    const icon = this.renderIcon(internalId, data, quantity);

    if (
      (mainHierarchyUnit && internalId === mainHierarchyUnit.id) ||
      !children ||
      children.length !== 1 // More than one children means that those are variants.
    ) {
      if (children && children.length > 0) {
        return [
          icon,
          <div
            key={`${internalId}-sep`}
            className="OneLineHierarchy__separator"
          />,
          <i
            key={`${internalId}-more`}
            className="mdi mdi-dots-horizontal OneLineHierarchy__more"
          />,
        ];
      }
      return [icon];
    }

    // There can be one and only one children at this point.
    const nextItem = children[0];
    const nextLevels = this.renderLevels(
      nextItem.id,
      mainHierarchyUnit,
      nextItem.quantity
    );
    return [
      icon,
      <div key={`${internalId}-sep`} className="OneLineHierarchy__separator" />,
      ...nextLevels,
    ];
  }

  public render() {
    const { rootInternalId, dataMap, hierarchyMap, currentVersionInfo } =
      this.props;
    const mainHierarchyUnit = getMainHierarchyUnit(
      rootInternalId,
      dataMap,
      hierarchyMap,
      currentVersionInfo
    );
    return (
      <div className="OneLineHierarchy">
        {this.renderLabel()}
        {this.renderLevels(rootInternalId, mainHierarchyUnit)}
      </div>
    );
  }
}
