import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';

import PnqAutocomplete from 'components/ui/autocomplete/pnq';
import ButtonBar from 'components/ui/button-bar';
import Field from 'components/ui/form/field';
import FormGroup from 'components/ui/form/form-group';
import { MEASUREMENT_PRECISION_EXACT } from 'constants/referentials';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import PNQTable from './pnq-table';
import './psq-editor.scss';

const options = { searchIcon: true };

export class PSQEditorWithoutRedux extends Field {
  constructor(props) {
    super(props);

    this.state.selectedItem =
      this.props.value && this.props.value.length ? 0 : undefined;

    this.onButtonBarRemove = this.onButtonBarRemove.bind(this);
    this.onButtonBarSelect = this.onButtonBarSelect.bind(this);
    this.onButtonBarAdd = this.onButtonBarAdd.bind(this);
    this.onAutocompleteSelect = this.onAutocompleteSelect.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    this.ensureDataIsPresent();
    this.updateSelectedItem(prevProps, prevState);
  }

  updateSelectedItem(prevProps, prevState) {
    const { value } = prevProps;
    const newValue = this.props.value;
    // Handle selected item.
    let { selectedItem } = prevState;
    if (
      (value && newValue && value.length !== newValue.length) ||
      (newValue && newValue.length && !value)
    ) {
      // We just added or deleted an element.
      selectedItem =
        newValue && newValue.length ? newValue.length - 1 : undefined;
    }
    if (selectedItem !== prevState.selectedItem) {
      this.setState({ selectedItem });
    }
  }

  onButtonBarSelect(data) {
    this.setState({ selectedItem: data.index });
  }

  onChangeField(model, newValue, isDirty = true, ignoreField = true) {
    this.dispatchChangeWithModel(model, newValue, isDirty, ignoreField);
  }

  onButtonBarRemove(data, index) {
    const { field, value } = this.props;
    const newValue = cloneDeep(value);
    newValue.splice(index, 1);

    this.onChangeField(field.model, newValue);
  }

  onButtonBarAdd() {
    const { field, value } = this.props;
    const newPSQ = this.getDefaultPsqValue(this.props.field.children);
    const newValue = value ? [...value, newPSQ] : [newPSQ];

    this.onChangeField(field.model, newValue);
  }

  onAutocompleteSelect(autocompleteData) {
    const { selectedItem } = this.state;
    if (selectedItem === null) {
      return;
    }

    const { value, field } = this.props;
    const newValue =
      (value && value[selectedItem] && value[selectedItem].contains) || [];
    newValue.push(this.getDefaultPnq(autocompleteData.item));

    this.onChangeField(`${field.model}.${selectedItem}.contains`, newValue);
  }

  getDefaultPsqValue(fields) {
    const defaultPsq = this.getDefaultValue(fields);

    fields.forEach((field) => {
      if (!field.inputKind.partial) {
        return;
      }
      field.lines.forEach((nutrientCode) => {
        const defaultPnq = this.getDefaultPnq(nutrientCode);
        defaultPsq[field.model].push(defaultPnq);
      });
    });

    return defaultPsq;
  }

  getDefaultPnq(nutrientCode) {
    const { field } = this.props;
    const pnqField = field.children.find((f) => f.model === 'contains');
    const defaultValue = this.getDefaultValue(pnqField.children);

    // Take the first measurement precision given by the DisplayGroup.
    const defaultQuantityMeasurementPrecision = pnqField.children
      .find((column) => column.model === 'quantityMeasurementPrecision')
      .inputKind.values.find(
        (value) => value.code === MEASUREMENT_PRECISION_EXACT.code
      );
    // Take the unit given in the expressedIn field of the Nutrient, or the
    // first one given by the Display Group if none given.
    const defaultUnit = get(nutrientCode, ['expressedIn', 0, 'data', 0]) || {
      id: 101,
      code: 'mg',
      label: i18n.t('frontproductstream.psq_editor.default_pnq_item_mg.label', {
        defaultValue: 'milligram',
      }),
      description: i18n.t(
        'frontproductstream.psq_editor.default_pnq_item_mg.help',
        { defaultValue: 'Milligram (weight unit)' }
      ),
    };

    defaultValue.nutrientCode = nutrientCode;
    defaultValue.quantityMeasurementPrecision =
      defaultQuantityMeasurementPrecision;
    defaultValue.quantity.push({
      data: null,
      expressedIn: defaultUnit,
    });

    return defaultValue;
  }

  getDefaultValue(fields) {
    const defaultValue = {};

    fields.forEach((f) => {
      // Do not create entry in defaultValue for text-related display
      if (
        f.inputKind.kind === 'buttonBar' ||
        f.model.indexOf('nutrientCode') !== -1
      ) {
        return;
      }

      let value = null;
      if (f.inputKind.kind === 'table' || f.inputKind.kind === 'list') {
        value = [];
      }
      defaultValue[f.model] = value;
    });

    return defaultValue;
  }

  renderButtonBar() {
    const { value } = this.props;

    const addButtonConf = {
      onAddButton: this.onButtonBarAdd,
      label: i18n.t('frontproductstream.psq_editor.add_button.label', {
        defaultValue: 'Add a standard quantity',
      }),
    };
    const buttonBarItems = value
      ? value.map((item) => ({
          label: (item.name && item.name.length && item.name[0].data) || (
            <em>{`(${
              i18n.t('frontproductstream.psq_editor.empty.title', {
                defaultValue: 'empty',
              }) // e.g. “250 mL”
            })`}</em>
          ),
        }))
      : [];
    let label = i18n.t('frontproductstream.psq_editor.list.prefix', {
      defaultValue: 'For:',
    });
    if (!buttonBarItems.length) {
      label = ''; // If there is no PSQ do not display the 'For:' label
    }
    const classes = {
      PSQEditor__buttonBar: true,
      'PSQEditor__buttonBar--empty': buttonBarItems.length === 0,
    };

    const classesErrorOrder = {
      'col-xs-12': true,
      PSQEditor__header__buttonBar: true, // this class is used to make the display flex
      'PSQEditor__header__buttonBar--empty': buttonBarItems.length === 0, // this class is used to changed the order of the elements based on whether there is some data or not
    };

    return (
      <div
        className={classNames(
          this.getClasses({ 'PSQEditor__header row': true })
        )}
      >
        <div className={classNames(classesErrorOrder)}>
          {this.renderPlugins()}
          <div className={classNames(classes)}>
            <ButtonBar
              addButtonConf={addButtonConf}
              buttons={buttonBarItems}
              label={label}
              onRemoveButton={this.onButtonBarRemove}
              onSelect={this.onButtonBarSelect}
              removable
              selectedIndex={this.state.selectedItem}
              readOnly={this.isReadOnly()}
            />
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    const { selectedItem } = this.state;
    const { entity, entityKind, entityId, field, extraParams } = this.props;
    const { onChange, noDispatch, entityIndex, flags, patch, disableDataOps } =
      extraParams || {};
    const baseModel = `${field.model}.${selectedItem}`;

    const fields = field.children.filter((f) => f.model !== 'contains');

    const enrichedFields = fields.map((f) => {
      const localField = { ...f };
      localField.model = `${baseModel}.${localField.model}`;
      return localField;
    });

    const dg = {
      kind: 'DisplayGroup',
      items: enrichedFields,
    };

    return (
      <div className="PSQEditor__form" key="form">
        <FormGroup
          entity={entity}
          entityKind={entityKind}
          entityId={entityId}
          formGroup={dg}
          onChangeField={onChange}
          noDispatch={noDispatch}
          entityIndex={entityIndex}
          flags={flags}
          patch={patch}
          disableDataOps={disableDataOps}
        />
      </div>
    );
  }

  renderPNQs() {
    const { selectedItem } = this.state;
    const { entity, entityId, entityKind, value, extraParams } = this.props;

    if (
      !value ||
      !value[selectedItem] ||
      !value[selectedItem].contains ||
      !value[selectedItem].contains.length
    ) {
      return null;
    }

    const tableItems = [...value[selectedItem].contains];
    const field = this.props.field.children.find((f) => f.model === 'contains');
    const enrichedField = {
      ...field,
      model: `${this.props.field.model}.${selectedItem}.contains`,
    };

    return (
      <div className="PSQEditor__table" key="table">
        <PNQTable
          field={enrichedField}
          entity={entity}
          entityId={entityId}
          entityKind={entityKind}
          value={tableItems}
          onChange={this.onChangeField}
          fieldExtraParams={extraParams}
        />
      </div>
    );
  }

  renderAutoComplete() {
    if (this.isReadOnly()) {
      return null;
    }
    const { selectedItem } = this.state;
    const { value } = this.props;

    const presentPNQs =
      value && value[selectedItem] && value[selectedItem].contains;
    const excludeList = presentPNQs
      ? presentPNQs.map((pnq) => ({ key: pnq.nutrientCode?.id }))
      : [];

    return (
      <div className="PSQEditor__autocomplete" key="autocomplete">
        <PnqAutocomplete
          id="pnq-autocomplete"
          excludeList={excludeList}
          onSelect={this.onAutocompleteSelect}
          options={options}
          placeholder={i18n.t(
            'frontproductstream.psq_editor.pnq_autocomplete.placeholder',
            {
              defaultValue:
                'Enter a nutritional component (Salt, Vitamin A, Sodium...)',
            }
          )}
        />
      </div>
    );
  }

  render() {
    const { selectedItem } = this.state;

    const shouldFullyRender = selectedItem !== undefined;

    return (
      <div
        id={this.getId()}
        className={classNames(this.getClasses({ PSQEditor: true }))}
      >
        {this.renderButtonBar()}
        {shouldFullyRender
          ? [this.renderForm(), this.renderPNQs(), this.renderAutoComplete()]
          : null}
      </div>
    );
  }
}

export const PSQEditor = connect()(PSQEditorWithoutRedux);
