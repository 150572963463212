import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { downloadPictures } from 'actions/media';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_PICTURE_DERIVATIVES_DOWNLOAD,
  FEATURE_RETAILER_DOWNLOAD_PACKSHOT,
} from 'modules/feature-flag/constants';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { trackAction } from '../../../../../../../actions';
import '../../index.scss';
import { download, getDownloadablePackshots } from '../utils';

const mapDispatchToProps = {
  downloadPictures,
  trackAction,
};

export class DownloadPackshotsAction extends PureComponent {
  static propTypes = {
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    user: ImmutablePropTypes.map.isRequired,
    actions: PropTypes.shape({
      downloadPictures: PropTypes.func.isRequired,
      trackAction: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    [
      FEATURE_RETAILER_DOWNLOAD_PACKSHOT,
      FEATURE_PICTURE_DERIVATIVES_DOWNLOAD,
    ].some((feature) => hasFeature(user, feature));

  static isEnabled = ({ productMap, selectedMap, user }) =>
    !!getDownloadablePackshots(selectedMap, productMap, user).size;

  state = { openModal: false };

  onDownloadPackshots = () => {
    const { disabled, selectedMap, productMap, user, actions } = this.props;
    if (disabled) {
      return;
    }
    download(true, selectedMap, productMap, user, actions);
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    const { user, selectedMap, productMap } = this.props;
    const productsWithPackshots = getDownloadablePackshots(
      selectedMap,
      productMap,
      user
    );
    if (hasFeature(user, FEATURE_PICTURE_DERIVATIVES_DOWNLOAD)) {
      return null;
    }

    return (
      <>
        <div
          className="ActionOption BulkAction_DownloadPackshot"
          onClick={this.onDownloadPackshots}
        >
          {i18n.t('Download shared packshot {{count}} sheet(s)', {
            count: productsWithPackshots.size,
          })}
        </div>
      </>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions
)(DownloadPackshotsAction);
