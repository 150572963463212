import { isFunction, once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { getProductSegments } from 'actions/segment';
import DropdownTree from 'components/ui/dropdown-tree';
import Modal from 'components/ui/modal';
import { getProductId, getProductProductKeyId } from 'core/api/productversion';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { track } from 'utils/tracking';

import { classifyProducts, unclassifyProducts } from './actions';
import './index.scss';

const mapStateToProps = (state) => ({
  segmentList: state.segments.list,
  segmentTree: state.segments.tree,
});

const mapDispatchToProps = {
  getProductSegments,
  classifyProducts,
  unclassifyProducts,
};

export class ProductSegmentsModal extends PureComponent {
  static propTypes = {
    checkedProducts: ImmutablePropTypes.list,
    onClose: PropTypes.func,
    organizationId: PropTypes.number.isRequired,
    segmentList: PropTypes.array,
    segmentTree: PropTypes.array,
    actions: PropTypes.shape({
      getProductSegments: PropTypes.func.isRequired,
      classifyProducts: PropTypes.func.isRequired,
      unclassifyProducts: PropTypes.func.isRequired,
    }),
  };

  init = once(() => {
    const { actions, segmentTree, organizationId } = this.props;
    if (segmentTree.length === 0) {
      actions.getProductSegments({ organization_id: organizationId });
    }
  });

  constructor(props) {
    super(props);
    this.state = { processing: false };
  }

  componentDidMount() {
    this.init();
  }

  onSuccess = (productIds, productKeyIds, segmentId) => {
    this.setState({ processing: false });
    this.onClose({ refresh: true });
    track({
      category: 'product',
      action: 'product_moved',
      label: `products#${productIds.join()}->segment#${segmentId}`,
    });
  };

  onError = () => {
    this.setState({ processing: false });
  };

  onClick = () => {
    const { checkedProducts, actions, segmentList } = this.props;
    const productIds = checkedProducts.map((pv) => getProductId(pv)).toJS();
    const productKeyIds = checkedProducts
      .map((pv) => getProductProductKeyId(pv))
      .toJS();

    this.setState({ processing: true });
    if (this.segmentId) {
      actions.classifyProducts(
        productIds,
        productKeyIds,
        segmentList.find((segment) => segment.id === this.segmentId),
        this.onSuccess,
        this.onError
      );
    } else {
      actions.unclassifyProducts(
        productIds,
        productKeyIds,
        this.onSuccess,
        this.onError
      );
    }
  };

  onSegmentChange = (segmentId) => {
    this.segmentId = segmentId;
  };

  onClose = ({ refresh } = {}) => {
    const { onClose } = this.props;
    if (isFunction(onClose)) {
      onClose(refresh);
    }
  };

  formatBody() {
    const { segmentTree } = this.props;
    return (
      <div className="ProductSegmentsModal__body">
        <div>
          {i18n.t('Choose the position of your products in the hierarchy:')}
        </div>
        <br />
        <DropdownTree
          onSelectionChange={this.onSegmentChange}
          entities={segmentTree}
          labelKey="name"
        />
      </div>
    );
  }

  render() {
    const { processing } = this.state;
    return (
      <Modal
        className="ProductSegmentsModal"
        title={i18n.t('Link products to a hierarchy')}
        confirmButtonText={processing ? i18n.t('Moving') : i18n.t('Move')}
        isProcessing={processing}
        onConfirm={this.onClick}
        onClose={this.onClose}
      >
        {this.formatBody()}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ProductSegmentsModal);
