export const isListedIn = (fields) => (node) =>
  Boolean(
    node.kind !== 'Field' ||
      node.isPrivate ||
      node.specific ||
      fields.includes(node.model)
  );

export const isNotListedIn = (fields) => (node) =>
  node.kind !== 'Field' || !fields.includes(node.model);
