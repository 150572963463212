import { noop } from 'lodash';
import { get, pick } from 'lodash/fp';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';
import { Radio } from '@alkem/react-ui-inputs/src/radio/index';

import SearchAutocomplete from 'components/ui/autocomplete/search';
import { InputReference } from 'components/ui/input/reference';
import { LifeCycleIdsWithoutArchived } from 'constants/filters/lifeCycle';
import {
  selectCurrentLanguage,
  selectCurrentProductVersionGTIN,
  selectKindId,
  selectTargetMarketId,
  selectTextileVariantList,
  selectTypePackagingId,
} from 'reducers/productVersion';
import { selectIsAdmin } from 'reducers/user/selectors';
import { parseBoolean } from 'utils';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux/index';

import { addVariant, createVariant } from '../../actions';
import { selectIsCreatingVariant } from '../../selectors';
import TextileVariantImportModal from '../import-modal';

import './index.scss';

const mapStateToProps = createStructuredSelector({
  isCreating: selectIsCreatingVariant,
  modelKindId: selectKindId,
  modelPackagingTypeId: selectTypePackagingId,
  modelTargetMarketId: selectTargetMarketId,
  modelGtin: selectCurrentProductVersionGTIN,
  modelVariantList: selectTextileVariantList,
  currentLanguage: selectCurrentLanguage,
  canCreate: selectIsAdmin,
});

const mapDispatchToProps = {
  addVariant,
  createVariant,
};

interface State {
  isImportModalOpen: boolean;
  addVariantFromFront: boolean;
  newVariantGtin: string;
}

interface Props {
  isCreating: boolean;
  variantFieldNames: string[];
  modelKindId: number;
  modelPackagingTypeId: number;
  modelTargetMarketId: number;
  modelGtin: string;
  modelVariantList: any[];
  currentLanguage: any;
  canCreate: boolean;
  actions: {
    addVariant: (variant: {
      id: number;
      gtin: string;
      product_key_id: number;
      label: string;
      version: any;
    }) => void;
    createVariant: (gtin: string) => void;
  };
}

export class AddVariant extends PureComponent<Props, State> {
  public state: State = {
    isImportModalOpen: false,
    addVariantFromFront: true,
    newVariantGtin: '',
  };

  private openImportModal = () => {
    this.setState({ isImportModalOpen: true });
  };

  private closeImportModal = () => {
    this.setState({ isImportModalOpen: false });
  };

  private changeAddingType = (event) => {
    const value = parseBoolean(event.currentTarget.value) as boolean;
    this.setState({ addVariantFromFront: value });
  };

  private onUpdateGtin = (event) => {
    this.setState({ newVariantGtin: event.target.value });
  };

  private onCreate = () => {
    this.props.actions.createVariant(this.state.newVariantGtin);
    this.setState({ newVariantGtin: '' });
  };

  private renderExcelImport = () => {
    const { isCreating } = this.props;
    return (
      <div className="Textile_variants__addFromImport">
        <div className="Textile_variants__addFromImport__info">
          {i18n.t(
            'You can add all variants and fill all the required field by using an Excel template'
          )}
        </div>
        <Button
          secondary
          content={i18n.t('Add variants via Excel')}
          onClick={this.openImportModal}
          disabled={isCreating}
        />
      </div>
    );
  };

  private onSelect = (product) => {
    const { variantFieldNames } = this.props;
    const { value } = product;
    this.props.actions.addVariant({
      id: value.id,
      gtin: value.gtin,
      product_key_id: value.product_key_id,
      label: value.label,
      // Directly display the values that are filled on the product.
      version: pick([...variantFieldNames, 'namePublicLong'], value),
    });
  };

  private makeSearchQuery = () => {
    const {
      modelKindId,
      modelPackagingTypeId,
      modelTargetMarketId,
      variantFieldNames,
      modelGtin,
      modelVariantList,
    } = this.props;
    const excludedGtins = [
      modelGtin,
      ...(modelVariantList || [])
        .map(get(['textileVariant', 'gtin']))
        .filter((g) => !!g),
    ];
    return {
      advanced_search: {
        must: [
          // Must be the same kind.
          { query: modelKindId, fields: ['kind.id'] },
          // Must be the same packaging type.
          { query: modelPackagingTypeId, fields: ['typePackaging.id'] },
          // Must not be a model.
          {
            must_not: [
              { exists: true, fields: ['alkemics.textileVariants.gtins'] },
            ],
          },
        ],
      },
      filter_source_include: [
        ...(variantFieldNames || []),
        'gtin',
        'namePublicLong',
      ],
      target_market: modelTargetMarketId,
      filter_lifecycle_in: LifeCycleIdsWithoutArchived,
      filter_gtins_out: excludedGtins || null,
      // Exclude display units and logistical units.
      filter_unit_type_in: ['consumerUnit'],
    };
  };
  private renderFromFront = () => {
    const { currentLanguage, isCreating } = this.props;
    const { newVariantGtin } = this.state;
    return (
      <div className="Textile_variants__addFromFront">
        <SearchAutocomplete
          id="add-variant-autocomplete"
          className="InputField__input"
          value={[]}
          onSelect={this.onSelect}
          onUnselect={noop}
          path="/search/v2/productversion"
          placeholder={i18n.t('Select an existing variant')}
          body={this.makeSearchQuery()}
          currentLanguage={currentLanguage}
          // locales={locales}
          disableOnClickOutside
          delegateSelection
          searchOnClick
          disabled={isCreating}
        />
        <div className="Textile_variants__addFromFront__or">{i18n.t('or')}</div>
        <div className="Textile_variants__addFromFront__fromGtin">
          <div className="Textile_variants__addFromFront__fromGtin_label">
            {i18n.t('GTIN')}
          </div>
          <InputReference
            classname="Textile_variants__addFromFront__fromGtin__gtin"
            value={newVariantGtin}
            enableValidator
            placeholder={i18n.t("Create a variant with it's GTIN")}
            onChange={this.onUpdateGtin}
            disabled={isCreating}
          />
          <Button
            secondary
            content={i18n.t('Create')}
            onClick={this.onCreate}
            disabled={isCreating || !newVariantGtin}
          />
        </div>
      </div>
    );
  };

  render() {
    const { variantFieldNames } = this.props;
    const { isImportModalOpen, addVariantFromFront } = this.state;
    return (
      <>
        <div className="Textile_variants__add">
          <div className="alk-txt-primary Textile_variants__add__infos">
            <i className="mdi mdi-plus-circle" />
            {i18n.t('New variants')}
          </div>
          <div className="Textile_variants__add__radio">
            <Radio
              id="unsubscription-event-radio"
              value={addVariantFromFront}
              onChange={this.changeAddingType}
              options={[
                { label: i18n.t('Add from the interface'), value: true },
                { label: i18n.t('Add from Excel import'), value: false },
              ]}
            />
          </div>
          {addVariantFromFront
            ? this.renderFromFront()
            : this.renderExcelImport()}
        </div>
        {isImportModalOpen && (
          <TextileVariantImportModal
            onCloseModal={this.closeImportModal}
            fieldNames={variantFieldNames}
          />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(AddVariant);
