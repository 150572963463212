import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import InputLabel from 'components/ui/input/label';
import { acceptSuggestion } from 'modules/product-page/modules/product-suggestions/actions';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { track } from 'utils/tracking';

import ConceptLabel from '../concept-label';
import '../labels.scss';

import { selectLabelMLSuggestions } from './selectors';
import './suggestions.scss';

const mapStateToProps = createStructuredSelector({
  labelSuggestions: selectLabelMLSuggestions,
});

const mapDispatchToProps = {
  acceptSuggestion,
};

export class LabelSuggestions extends PureComponent {
  static propTypes = {
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    labelSuggestions: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.any,
    isReadOnly: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      acceptSuggestion: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.onClickSuggestion = this.onClickSuggestion.bind(this);
  }

  onClickSuggestion(selected) {
    const { actions, labelSuggestions } = this.props;
    actions.acceptSuggestion(
      labelSuggestions.get(selected.isConceptualizedBy.id)
    );
    this.trackAcceptation(selected.isConceptualizedBy.label);
  }

  trackAcceptation(labelName) {
    const { entityId } = this.props;
    track({
      category: `product`,
      action: `product_suggestion_accepted`,
      label: `product_version#${entityId}`,
      fieldmetadata: `isLabeledBy`,
      value: labelName,
    });
  }

  render() {
    const { field, value, isReadOnly, labelSuggestions } = this.props;

    if (isReadOnly || !labelSuggestions || !labelSuggestions.size) {
      return null;
    }

    const selectedLabels = value
      ? value.map((label) => label.isConceptualizedBy.id)
      : [];
    const suggestionText = i18n.t(
      'frontproductstream.labels.suggestions.text',
      { defaultValue: 'Recommended labels' }
    );
    const help = i18n.t('frontproductstream.labels.suggestions.help', {
      defaultValue: 'Suggestions based on machine learning',
    });

    const suggestedLabels = labelSuggestions
      .valueSeq()
      .map((labelSuggestion) => (
        <div
          className="Labels__item Labels__suggestion col-xs-12 col-sm-6 col-md-4"
          key={labelSuggestion.getIn([
            'data',
            'suggestedValue',
            'isConceptualizedBy',
            'id',
          ])}
        >
          <div className="LabelList__item__content">
            <ConceptLabel
              label={labelSuggestion.getIn(['data', 'suggestedValue']).toJS()}
              onClick={this.onClickSuggestion}
              disabled={
                selectedLabels.indexOf(
                  labelSuggestion.getIn([
                    'data',
                    'suggestedValue',
                    'isConceptualizedBy',
                    'id',
                  ])
                ) !== -1
              }
              selectable
            />
          </div>
        </div>
      ));
    return (
      <div key="MLSuggestion">
        <InputLabel
          id={`${field.model}-${'MLSuggestion'}`}
          label={suggestionText}
          help={help}
          alignLeft
        />
        <div className="LabelList Labels__suggestions row">
          {suggestedLabels}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(LabelSuggestions);
