import { get } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import qs, { withQuery } from './query';

export const ELEVIO_ARTICLE = 'data-elevio-article';

export function DocumentationHandler() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const search = qs.parse(location.search);
    let article = search[ELEVIO_ARTICLE];
    article = Array.isArray(article) ? article[0] : article;
    const open = get(global, ['_elev', 'openArticle']);
    if (open && article) {
      open(article);
      const searchWithoutElevioArticle = Object.keys(search)
        .filter((key) => key !== ELEVIO_ARTICLE)
        .reduce((acc, key) => Object.assign(acc, { [key]: search[key] }), {});
      navigate(withQuery(location.pathname, searchWithoutElevioArticle), {
        replace: true,
      });
    }
  }, [location, navigate]);
  return null;
}
