import { isNotThirdParty } from 'core/api/user';

import { isAvailableForProductStatus } from '../../utils/filters/product-status';

import { postShareProductStatus } from './share-status';

export const packshotFilter = {
  key: 'alkemics.hasPackshot',
  query: 'haspackshot',
  isAvailable: ({ user, selectedFilterTargetProductStatus } = {}) =>
    isNotThirdParty(user) &&
    isAvailableForProductStatus(
      selectedFilterTargetProductStatus,
      postShareProductStatus
    ),
};
