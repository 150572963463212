import { call, put, select } from 'redux-saga/effects';

import { filters } from 'core/modules/list/constants';
import { selectUser } from 'reducers/user/selectors';
import { fetchProductVersions } from 'resources/searchApi';
import { logError } from 'utils/logging';

import { receiveProductsAggregations } from '../../actions';

function buildAggregations({ user }) {
  return filters
    .filter((filter) => filter.isAvailable({ user }))
    .map((filter) =>
      Object.assign({ key_field: filter.key }, filter.aggregation)
    );
}

export function* fetchProductsAggregationsSaga() {
  try {
    const user = yield select(selectUser);
    const aggregationsQuery = buildAggregations({ user });
    const queries = {
      aggregations: aggregationsQuery,
    };

    const { aggregations, error: pvsError } = yield call(fetchProductVersions, {
      offset: 0,
      limit: 0,
      queries,
      withSourceInclude: false,
    });
    if (pvsError) {
      throw pvsError;
    }
    yield put(receiveProductsAggregations(aggregations));
  } catch (error) {
    logError(error);
  }
}
