import { get } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Search from 'components/ui/input/search';
import { getOrganizationListColumns } from 'core/api/organization-settings';
import {
  getOrganizationSettings,
  getUserSettings,
  isRetailer,
} from 'core/api/user';
import { getUserListColumns } from 'core/api/user-settings';
import ListBody from 'core/modules/list/components/body';
import TableBody from 'core/modules/list/components/body/table';
import { PermissionsEmptyState, usePermission } from 'modules/permissions';
import { PRODUCT_PERMISSION, SHOW_PERMISSION } from 'modules/permissions/const';
import { selectUser } from 'reducers/user/selectors';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';

import {
  search as doSearch,
  fetchColumnReferentials,
  fetchList,
  fetchMaturityRulesets,
  productSelection,
  selectColumn,
  sortList,
  trackSearch,
  updateItemsPerPage,
  updateSearchFromQuery,
} from '../../actions';
import { ASSIGNATION, PRODUCTS } from '../../constants/context';
import {
  selectColumnMap,
  selectIsFetchingList,
  selectIsFetchingReferentials,
  selectPagination,
  selectProductList,
  selectProductMap,
  selectReferentials,
  selectSearch,
  selectSelectedMap,
  selectSorting,
} from '../../selectors';

import CatalogActions from './actions';
import './index.scss';
import PaginationInfo from './pagination-info';
import CatalogTable from './table';

type Props = {
  context?: typeof PRODUCTS;
  filtersQueryMap?: object;
  searchQuery?: string;
};

const computeListColumns = (user: UserImmutable, context: typeof PRODUCTS) => ({
  userPreferredColumns: getUserListColumns(
    getUserSettings(user),
    context.userColumns
  ),
  organizationAllowedColumns: getOrganizationListColumns(
    getOrganizationSettings(user),
    context.orgColumns
  ),
});

const hasFiltersSelected = (filtersQueryMap?: object) =>
  filtersQueryMap
    ? Object.values(filtersQueryMap).some((value) => value.length)
    : false;

const CatalogListSearch = ({ search, onSearch }) => (
  <div className="CatalogList__search">
    <Search
      query={search}
      updateSearchQuery={onSearch}
      placeholder={i18n.t('Search by GTIN (complete) or name')}
      withPasteHandler={false}
      isTransparent
    />
  </div>
);

export const CatalogList = memo(
  ({ context = PRODUCTS, searchQuery, filtersQueryMap }: Props) => {
    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const productList = useSelector(selectProductList);
    const productMap = useSelector(selectProductMap);
    const selectedMap = useSelector(selectSelectedMap);
    const columnMap = useSelector(selectColumnMap);
    const referentials = useSelector(selectReferentials);
    const sorting = useSelector(selectSorting);
    const isFetchingList = useSelector(selectIsFetchingList);
    const isFetchingReferentials = useSelector(selectIsFetchingReferentials);
    const pagination = useSelector(selectPagination);
    const user = useSelector(selectUser);
    const search = useSelector(selectSearch);

    const { hasLegacyPermissions, hasModule } = usePermission({
      module: PRODUCT_PERMISSION,
    });
    const canShowProducts = hasLegacyPermissions
      ? true
      : hasModule([SHOW_PERMISSION]);

    const { userPreferredColumns, organizationAllowedColumns } = useMemo(
      () => computeListColumns(user, context),
      [user, context]
    );

    useEffect(() => {
      if (isMounted.current || !canShowProducts) {
        return;
      }
      isMounted.current = true;
      dispatch(
        fetchColumnReferentials({
          organizationAllowedColumns,
          userPreferredColumns,
          filtersQueryMap,
          searchQuery,
          initial: true,
        })
      );
    }, [
      dispatch,
      userPreferredColumns,
      organizationAllowedColumns,
      filtersQueryMap,
      searchQuery,
      canShowProducts,
    ]);

    useEffect(() => {
      if (searchQuery) {
        dispatch(updateSearchFromQuery(searchQuery));
      }
    }, [dispatch, searchQuery]);

    useEffect(() => {
      // Only activable by retailers: manuf has no use of the requestable rulesets
      if (isRetailer(user)) {
        dispatch(fetchMaturityRulesets());
      }
    }, [dispatch, user]);

    const onSearch = useCallback(
      (query) => {
        dispatch(doSearch(query));
        dispatch(trackSearch());
      },
      [dispatch]
    );

    const onPreviousPage = useCallback(() => {
      dispatch(fetchList({ previous: true }));
    }, [dispatch]);

    const onNextPage = useCallback(() => {
      dispatch(fetchList({ next: true }));
    }, [dispatch]);

    const onSelectProduct = useCallback(
      (id, selected) => {
        dispatch(productSelection({ id, selected }));
      },
      [dispatch]
    );

    const onSelectAllProduct = useCallback(
      (selected) => {
        dispatch(productSelection({ all: true, selected }));
      },
      [dispatch]
    );

    const onChangeColumn = useCallback(
      (referential, selected) => {
        dispatch(
          selectColumn({
            referential,
            selected,
            userPreferredColumns,
            organizationAllowedColumns,
          })
        );
      },
      [dispatch, organizationAllowedColumns, userPreferredColumns]
    );

    const onSort = useCallback(
      (referential, asc) => {
        dispatch(sortList({ referential, asc }));
        dispatch(fetchList());
      },
      [dispatch]
    );

    const onChangeItemsPerPage = useCallback(
      (itemsPerPage) => {
        dispatch(updateItemsPerPage(itemsPerPage));
      },
      [dispatch]
    );

    const isLoading = isFetchingList || isFetchingReferentials;
    return (
      <ListBody className="CatalogList">
        {context.search && (
          <CatalogListSearch search={search} onSearch={onSearch} />
        )}
        <CatalogActions
          user={user}
          productMap={productMap}
          selectedMap={selectedMap}
          columnMap={columnMap}
          pagination={pagination}
          referentials={referentials}
          context={context}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          onSelectAllProduct={onSelectAllProduct}
          onChangeColumn={onChangeColumn}
          onChangeItemsPerPage={onChangeItemsPerPage}
          filtersQueryMap={filtersQueryMap}
        />
        <PaginationInfo
          pagination={pagination}
          selectedMap={selectedMap}
          withoutSelection={context === ASSIGNATION}
        />
        <TableBody
          scrollable
          margin={productList.isEmpty() ? 0 : get(context, 'size.action.width')}
        >
          {canShowProducts ? (
            <CatalogTable
              user={user}
              productList={productList}
              hasSelectedFilters={hasFiltersSelected(filtersQueryMap)}
              hasSearchQuery={!!search && search.length > 0}
              productMap={productMap}
              selectedMap={selectedMap}
              columnMap={columnMap}
              referentials={referentials}
              sorting={sorting}
              loading={isLoading}
              context={context}
              onSelectProduct={onSelectProduct}
              onSort={onSort}
            />
          ) : (
            <PermissionsEmptyState>
              <strong>
                {i18n.t("You don't have permission to see products.")}
              </strong>
              <span>{i18n.t('Please contact your administrator.')}</span>
            </PermissionsEmptyState>
          )}
        </TableBody>
      </ListBody>
    );
  }
);

export default CatalogList;
