import { apply, call, delay, put, race, take } from 'redux-saga/effects';

import etlApi from 'resources/etlApi';
import { logError } from 'utils/logging';

import {
  pollGenericImportReport,
  pollImportReport,
  receiveImportReport,
} from '../../actions';
import { CANCEL_POLL_IMPORT_REPORT } from '../../actions/types';
import { getTrackerImport } from '../../api/api';

function* getImportReport(reportId) {
  let stopPolling = false;
  try {
    const response = yield apply(etlApi, etlApi.ProductImportReportGet, [
      reportId,
    ]);
    const { steps } = response.data.data;
    stopPolling = response.data.data.status !== 'IN_PROGRESS';
    let status = 'PENDING';
    if (stopPolling) {
      if (steps.some((s) => s.status === 'ERROR')) {
        if (steps.some((s) => s.status === 'ERROR' && s.name === 'crawl')) {
          status = 'CRAWL_ERROR';
        } else {
          status = 'ERROR';
        }
      } else {
        status = 'SUCCESS';
      }
    }
    yield put(
      receiveImportReport({
        report: {
          entities: response.data.data.productImports,
          steps: response.data.data.steps,
        },
        status,
      })
    );
  } catch (e) {
    logError(e);
  } finally {
    if (!stopPolling) {
      yield delay(2000);
      yield put(pollImportReport({ reportId }));
    }
  }
}

function* getGenericImportReport(reportId) {
  let stopPolling = false;
  try {
    const response = yield call(getTrackerImport, reportId);

    stopPolling = response.data.data.status !== 'IN_PROGRESS';
    const status =
      response.data.data.status === 'IN_PROGRESS'
        ? 'PENDING'
        : response.data.data.status;
    yield put(
      receiveImportReport({
        report: {
          entities: response.data.data.entities,
          steps: response.data.data.steps,
        },
        status,
      })
    );
  } catch (e) {
    logError(e);
  } finally {
    if (!stopPolling) {
      yield delay(2000);
      yield put(pollGenericImportReport({ reportId }));
    }
  }
}

export function* manageImportReport(action) {
  yield race({
    response: call(getImportReport, action.payload.reportId),
    cancel: take(CANCEL_POLL_IMPORT_REPORT),
  });
}

export function* manageGenericImportReport(action) {
  yield race({
    response: call(getGenericImportReport, action.payload.reportId),
    cancel: take(CANCEL_POLL_IMPORT_REPORT),
  });
}
