import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Field from 'components/ui/form/field';
import TagsInput from 'components/ui/input/tags';

export class FormTags extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    currentLanguage: PropTypes.object,
  });

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  onChange = (values) => {
    const { value, currentLanguage, field } = this.props;
    if (!currentLanguage) {
      this.handleChange({ currentTarget: { value: values } });
    } else {
      const childFieldModel = field?.children?.[0]
        ? field.children[0].model
        : 'text';
      const otherLanguageValues = value
        ? value.filter(
            (e) =>
              e[childFieldModel]?.[0]?.expressedIn?.id !== currentLanguage.id
          )
        : [];
      const newValue = values
        .map((e) => ({
          [childFieldModel]: [
            {
              expressedIn: currentLanguage,
              data: e,
            },
          ],
        }))
        .concat(otherLanguageValues);
      this.handleChange({ currentTarget: { value: newValue } });
    }
  };

  getValuesToDisplay() {
    const { value, currentLanguage, field } = this.props;

    if (!currentLanguage) {
      return value ? value : [];
    }

    const childFieldModel = field?.children?.[0]
      ? field.children[0].model
      : 'text';
    if (!currentLanguage || !value || !value.length) {
      return [];
    }
    return value
      .filter(
        (e) => e[childFieldModel]?.[0]?.expressedIn?.id === currentLanguage.id
      )
      .map((e) => e[childFieldModel][0].data);
  }

  matchSynonym = (value) => {
    const re = /\S/; // This looks for at least one non whitespace character
    return re.test(value);
  };

  render() {
    const { field } = this.props;
    if (!field) {
      return null;
    }

    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };

    return (
      <div className={classNames(this.getClasses({ 'FormTags row': true }))}>
        {renderedLabel}
        <div className={classNames(classes)}>
          <TagsInput
            id={this.getId()}
            matchTag={this.matchSynonym}
            onChange={this.onChange}
            tags={this.getValuesToDisplay()}
            matchOnWhitespace={false}
            disabled={this.isReadOnly()}
          />
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect()(FormTags);
