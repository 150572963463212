import type { ComponentProps, ComponentType } from 'react';
import { useParams } from 'react-router-dom';

export function withParams<C extends ComponentType<any>>(
  Component: C,
  statics: string[] = []
) {
  function WithParams(props: any) {
    const params = useParams();
    return <Component {...props} params={params} />;
  }
  for (const key of ['defaultProps', ...statics]) {
    if (key in Component) {
      WithParams[key] = Component[key];
    }
  }
  if (Component.propTypes) {
    const { params, ...propTypes } = Component.propTypes;
    WithParams.propTypes = propTypes;
  }
  return WithParams as ComponentType<Omit<ComponentProps<C>, 'params'>> & {
    [key in keyof C]: C[key];
  };
}
