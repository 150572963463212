import { CancelablePromise } from 'cancelable-promise';
import { List, Map } from 'immutable';
import {
  flow,
  getOr,
  intersection,
  isArray,
  isEmpty,
  isNull,
  isObject,
  isUndefined,
  keys,
} from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PermissionModule, UserModules } from '@alkem/sdk-dashboard';

import { getRootModel } from 'core/api/fields';
import { managesOrganization } from 'core/api/user';
import { hasFeature as hasFeatureFlag } from 'modules/feature-flag';
import { allPermissionsV3Flags } from 'modules/feature-flag/constants';
import { selectUser } from 'reducers/user/selectors';
import { get } from 'utils/immutable';

import { UserImmutable } from '../../types';

import { selectPermissions } from './selectors';
import { PermissionEntitiesType, ResponseWithPermissionsType } from './types';

export async function responseWithPermissions<T>(
  apiCall: CancelablePromise<ResponseWithPermissionsType<T>>
) {
  const response = await apiCall;
  return {
    result: {
      permissions: get(response, 'data.permissions') as string[],
      data: get(response, 'data.data'),
    } as ResponseWithPermissionsType<T>,
  };
}

export const hasPermissionV3 = (
  user: UserImmutable,
  ...flags: string[]
): boolean => {
  if (flags.length) {
    return hasFeatureFlag(user, ...flags);
  }
  return allPermissionsV3Flags.some((flag) => hasFeatureFlag(user, flag));
};

export const isAdminPermissionsV3 = (user: UserImmutable): boolean =>
  managesOrganization(user) && hasPermissionV3(user);

export const hasPermissionModule = (
  user: UserImmutable,
  module: PermissionModule,
  permissions: string | string[] = []
): boolean => {
  const userModules: UserModules = get(user, 'modules', Map()).toJS();
  if (isArray(userModules)) {
    return userModules.includes(module);
  }
  if (isObject(userModules)) {
    permissions = (isArray(permissions) ? permissions : [permissions]).map(
      (permission) => `${module}.${permission}`
    );
    if (permissions.length) {
      const modulePermissions = get(userModules, [module, 'permissions'], []);
      return (
        intersection(modulePermissions, permissions).length ===
        permissions.length
      );
    }
    return Boolean(get(userModules, [module]));
  }
  return false;
};

export const hasInstancePermissions = (
  entitiesPermissions: PermissionEntitiesType,
  entityType: PermissionModule,
  entityId: number | string,
  permissions: string | string[] = [],
  { modulePrefix = true }: { modulePrefix?: boolean } = {}
): boolean => {
  let permList = isArray(permissions) ? permissions : [permissions];
  if (modulePrefix) {
    permList = permList.map((permission) => `${entityType}.${permission}`);
  }
  return (
    flow(
      getOr([], [entityType, entityId]),
      (entityPermissions) =>
        isArray(entityPermissions)
          ? entityPermissions
          : keys(entityPermissions),
      intersection(permList)
    )(entitiesPermissions as any).length === permList.length
  );
};

export const getPermissionKey = (kind: string, permission: string) => {
  return `${kind}.${permission}`;
};

export const usePermission = ({
  module,
  entityId,
}: {
  module: PermissionModule;
  entityId?: number | string;
}) => {
  const user = useSelector(selectUser);
  const entitiesPermissions = useSelector(selectPermissions);
  const modules = useMemo(() => user.get('modules'), [user]);

  const hasFeature = useCallback(
    (...features: string[]) => hasPermissionV3(user, ...features),
    [user]
  );

  const hasLegacyPermissions = useMemo(
    () => !hasFeature(`permission-v3-${module}`),
    [hasFeature, module]
  );

  const hasEntityPermissions = useCallback(
    (permissions?: string | string[]) =>
      Boolean(module) && hasPermissionModule(user, module, permissions),
    [module, user]
  );

  const hasPermission = useCallback(
    ({
      permissions,
      entityId: _entityId = entityId,
      modulePrefix = true,
    }: {
      permissions: string | string[];
      entityId?: string | number;
      modulePrefix?: boolean;
    }) =>
      hasLegacyPermissions || // backward compatibility
      (_entityId
        ? hasInstancePermissions(
            entitiesPermissions,
            module,
            _entityId,
            permissions,
            { modulePrefix }
          )
        : hasEntityPermissions(permissions)),
    [
      hasEntityPermissions,
      hasLegacyPermissions,
      module,
      entityId,
      entitiesPermissions,
    ]
  );

  const getPermission = useCallback(
    (userRight: string) =>
      hasLegacyPermissions
        ? undefined
        : get(
            entitiesPermissions,
            [module, entityId, getPermissionKey(module, userRight)],
            null
          ),
    [hasLegacyPermissions, entitiesPermissions, entityId, module]
  );

  return {
    hasLegacyPermissions,
    user,
    permissions: entitiesPermissions,
    modules,
    hasPermission,
    hasModule: hasEntityPermissions,
    hasFeature,
    getPermission,
  };
};

export const hasPermissionsByEntity = ({
  entity,
  entityType,
  permissions,
  fallbackValue = false,
}: {
  entity: any;
  permissions: string | string[];
  entityType?: string;
  fallbackValue?: boolean;
}): boolean => {
  const entityPermissions = get(entity, ['permissions']);
  if (!entityPermissions) {
    return fallbackValue;
  }
  if (typeof permissions === 'string') {
    permissions = [permissions];
  }
  if (entityType) {
    permissions = permissions.map((perm) => `${entityType}.${perm}`);
  }
  if (Array.isArray(entityPermissions) || List.isList(entityPermissions)) {
    return permissions.every((perm) => entityPermissions.includes(perm));
  }
  return permissions.every((perm) => get(entityPermissions, [perm]));
};

export const hasFieldPermission = (
  field: { model: string; [key: string]: any },
  entityPermissions?:
    | {
        [key in keyof any]?: string[] | number[];
      }
    | null
) => {
  // retro compatibility mode for permissions v2
  if (isUndefined(entityPermissions)) {
    return true;
  }
  // getPermission will return null if no permission v3 found
  if (isNull(entityPermissions)) {
    return false;
  }
  const rootModel = getRootModel(field);
  return rootModel in entityPermissions
    ? !isEmpty(entityPermissions[rootModel])
    : true;
};
