import { useDispatch as useDispatchRedux } from 'react-redux';
import type { Action } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

export function useDispatch<
  State,
  ExtraThunkArg,
  BasicAction extends Action
>(): ThunkDispatch<State, ExtraThunkArg, BasicAction> {
  return useDispatchRedux();
}
