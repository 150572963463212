import classNames from 'classnames';
import { isUndefined } from 'lodash';
import { connect } from 'react-redux';

import { Select } from '@alkem/react-ui-select';

import Field from 'components/ui/form/field';
import { cleanFieldLabel } from 'components/ui/form/field/utils/clean';
import {
  enrichChildFields,
  filterChildFields,
} from 'components/ui/form/field/utils/filter';
import { renderField } from 'components/ui/form/field/utils/render';
import { cleanChildFields } from 'components/ui/form/field/utils/seed';
import FormGroup from 'components/ui/form/form-group';
import i18n from 'utils/i18n';

import './full-list.scss';

class FormFullList extends Field {
  static getDerivedStateFromProps(props, state) {
    const { filter } = props;
    if (filter) {
      const shouldBeInList = (props.value || [])
        .filter(filter)
        .map((v) => v[props.field.inputKind.mainKey].id);
      const choices = [
        ...state.choices,
        ...shouldBeInList.filter((id) => !state.choices.includes(id)),
      ];
      return { choices };
    }
    return {};
  }

  constructor(props) {
    super(props);
    if (props.filter) {
      this.state = this.state || {};
      this.state.choices = (props.value || [])
        .filter(props.filter)
        .map((v) => v[props.field.inputKind.mainKey].id);
    }
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (cleanChildFields(nextProps.field, nextProps.value)) {
      return false;
    }
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  getSingleChild(children) {
    const { field } = this.props;
    const localChildren = children || field.children;
    const filteredChildren = localChildren.filter(
      (f) =>
        !(f.options && !isUndefined(f.options.visible) && !f.options.visible)
    );
    return filteredChildren.length === 1 ? filteredChildren[0] : null;
  }

  getChoices() {
    const { field, filter, value } = this.props;
    const { mainKey } = field.inputKind;
    if (!filter) {
      return (value || []).map((choice, index) => ({
        choice,
        index,
      }));
    }
    const mappedElements =
      (value || []).reduce((o, choice, index) => {
        if (this.shouldBeInList(choice)) {
          const newObject = o;
          newObject[choice[mainKey].id] = { choice, index };
          return newObject;
        }
        return o;
      }, {}) || {};
    return this.state.choices.map((id) => mappedElements[id] || {});
  }

  shouldBeInList = (choice) => {
    const { field } = this.props;
    const { mainKey } = field.inputKind;
    return choice[mainKey] && this.state.choices.includes(choice[mainKey].id);
  };

  shouldBeInDropdown = (choice) => !this.shouldBeInList(choice);

  onSelect = (option) => {
    const { choices } = this.state;
    this.setState({ choices: [...choices, option.value] });
  };

  onUnselect = () => {};

  formatList() {
    const { field, value } = this.props;
    const { mainKey } = field.inputKind;
    const options = (value || []).filter(this.shouldBeInDropdown);
    return options
      .filter((o) => !!o[mainKey])
      .map((o) => ({
        label: o[mainKey].label,
        value: o[mainKey].id,
      }));
  }

  renderFilteredAddInput() {
    const { field, filter } = this.props;
    const { mainKey } = field.inputKind;
    const htmlId = `add-input-${mainKey}`;
    if (!filter || this.isReadOnly()) {
      return null;
    }
    return (
      <div className="FormFullList__addChoice">
        <i className="mdi mdi-plus-circle" />
        <div className="FormFullList__addChoiceLabel" htmlFor={htmlId}>
          {i18n.t('frontproductstream.full_list.add_button.label', {
            defaultValue: 'Add element',
          })}
        </div>
        <div className="FormFullList__addChoiceSelect">
          <Select
            id={htmlId}
            onValueAdd={this.onSelect}
            onValueDelete={this.onUnselect}
            values={[]}
            placeholder={i18n.t(
              'frontproductstream.full_list.add_select.placeholder',
              { defaultValue: 'Select an element to add' }
            )}
            delegateSearch={this.search}
            multiple={false}
            options={this.formatList()}
            valueEqualsTreeItem={this.valueEqualsTreeItem}
            inputable
          />
        </div>
      </div>
    );
  }

  renderItem(value, index, mainKeyValue) {
    const {
      field,
      entity,
      entityKind,
      entityId,
      hasProductUpdatePermission,
      extraParams,
    } = this.props;
    const {
      onChange,
      noDispatch,
      entityIndex,
      flags,
      patch,
      isDisabled,
      disableDataOps,
      entityPermissions,
      hasNormalizedCommentPermission,
    } = extraParams || {};
    const enrichedFields = enrichChildFields(field, index);
    const singleChild = this.getSingleChild(enrichedFields);
    if (singleChild) {
      const cleanChildField = cleanFieldLabel(singleChild);
      return (
        <div
          key={mainKeyValue.id}
          className="FormFullList__listItem--singleChild"
        >
          <div className="InputLabel col-xs-4">{mainKeyValue.label}</div>
          <div className="col-xs-8">
            {renderField(
              entity,
              entityKind,
              entityId,
              mainKeyValue.id,
              cleanChildField,
              false, // validate DO NOT REMOVE amigo :taco: otherwise all the elements are gonna be validated too
              hasProductUpdatePermission,
              null,
              extraParams
            )}
          </div>
        </div>
      );
    }

    const dg = {
      kind: 'DisplayGroup',
      items: filterChildFields(entity, field, enrichedFields),
    };

    return (
      <div key={mainKeyValue.id}>
        {this.shouldDisplayItem('mainKeyLabel') ? (
          <div className="row">
            <div className="col-xs-4">
              <h4 className="InputLabel FormFullList__title">
                {mainKeyValue.label}
              </h4>
            </div>
          </div>
        ) : null}
        <div className="FormFullList__listItem--multipleChildren">
          <FormGroup
            hasNormalizedCommentPermission={hasNormalizedCommentPermission}
            entity={entity}
            entityKind={entityKind}
            entityId={entityId}
            entityPermissions={entityPermissions}
            formGroup={dg}
            borders
            condensed
            onChangeField={onChange}
            noDispatch={noDispatch}
            entityIndex={entityIndex}
            flags={flags}
            patch={patch}
            disableDataOps={disableDataOps}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    );
  }

  render() {
    const { field } = this.props;
    if (!field) {
      return null;
    }
    const { mainKey } = field.inputKind;
    const { lines } = field;
    if (!mainKey || !lines || lines.length === 0) {
      return null;
    }
    const allowedMainKeyValueIds = lines.map((line) => line.id);

    const isSingleChild = !!this.getSingleChild();
    const listClassName = {
      'FormFullList__list--singleChild': isSingleChild,
    };
    // Only display allowed values.
    const displayItem = (choice) =>
      choice &&
      choice[mainKey] &&
      allowedMainKeyValueIds.includes(choice[mainKey].id);

    // Special render for single item with single child.
    if (isSingleChild && lines.length === 1) {
      return (
        <div
          className={classNames(
            this.getClasses({ row: true, FormFullList: true })
          )}
        >
          {this.renderLabel('col-xs-4')}
          <div
            id={this.getId()}
            className="col-xs-8 FormFullList__list--singleItemSingleChild"
          >
            {this.getChoices().map(({ choice, index }) =>
              displayItem(choice)
                ? this.renderItem(choice, index, choice[mainKey])
                : null
            )}
          </div>
          {this.renderFilteredAddInput()}
          {this.renderPlugins({ offset: true })}
        </div>
      );
    }

    return (
      <div className={classNames(this.getClasses({ FormFullList: true }))}>
        {this.renderLabel('', true)}
        <div id={this.getId()} className={classNames(listClassName)}>
          {this.getChoices().map(({ choice, index }) =>
            displayItem(choice)
              ? this.renderItem(choice, index, choice[mainKey])
              : null
          )}
        </div>
        {this.renderFilteredAddInput()}
        {this.renderPlugins({ offset: true })}
      </div>
    );
  }
}

export { FormFullList };
export default connect()(FormFullList);
