import { List, Map } from 'immutable';
import { call, put, select } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import { applyRulesForViewAsRecipients } from 'modules/validation';
import { VIEW_AS_RECEIVE_RULESETS } from 'modules/view-as/events';
import { selectRuleSets } from 'modules/view-as/selectors';
import {
  selectProductKeyId,
  selectTargetProductStatus,
} from 'reducers/productVersion';
import validationApi from 'resources/validationApi';
import { request } from 'utils/api';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import { selectUser } from '../../../reducers/user/selectors';
import ValidationApi from '../../../resources/validationApi';
import {
  RELEASE_DATA_MATURITY_DEADLINE_V4,
  hasFeature,
} from '../../feature-flag';
import {
  promptMaturityRuleSetsWithDeadlines,
  requireMaturityRuleSetsWithDeadlinesDone,
  requireMaturityRulesDone,
} from '../actions';
import { PromptedMaturityRuleSet, RuleSetRequirement } from '../types';

export function* requireMaturityRulesSaga({
  payload: ruleSetValues,
}: {
  payload: {
    [key: number]: boolean;
  };
}) {
  try {
    const user = yield select(selectUser);
    const productKeyId = yield select(selectProductKeyId);

    const isActivation = !!Object.values(ruleSetValues).find((value) => value);

    if (isActivation && hasFeature(user, RELEASE_DATA_MATURITY_DEADLINE_V4)) {
      const activableMaturityRuleSets = yield* addDeadlineInfo(ruleSetValues);

      if (
        activableMaturityRuleSets.filter(
          (ruleSet) => ruleSet?.withDeadline ?? false
        ).length
      ) {
        yield put(
          promptMaturityRuleSetsWithDeadlines(List(activableMaturityRuleSets))
        );
        return;
      }
    }

    const ruleSetUpdates = Object.entries(ruleSetValues).map(
      ([ruleSetId, requested]) =>
        new RuleSetRequirement(parseInt(ruleSetId), requested, undefined)
    );
    yield trackRuleSetActivationUpdates(ruleSetUpdates);

    yield call(() =>
      validationApi.updateRuleSetsRequireStatus(
        ruleSetValues,
        [productKeyId],
        null
      )
    );

    yield notifyRuleSetsUpdated(requireMaturityRulesDone, ruleSetUpdates);
  } catch (error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.product_page.notification.maturity_rule_sets_activation_update_error',
          {
            defaultValue:
              'An error occured while updating maturity rules for this product',
          }
        )
      )
    );
    yield put(requireMaturityRulesDone());
    throw error;
  }
}

export function* requireMaturityRuleSetsWithDeadlinesSaga({
  payload: ruleSetsRequirements,
}: {
  payload: Array<RuleSetRequirement>;
}) {
  try {
    yield trackRuleSetActivationUpdates(ruleSetsRequirements);

    const productKeyId = yield select(selectProductKeyId);
    for (const ruleSetRequirement of ruleSetsRequirements) {
      yield call(() =>
        validationApi.updateRuleSetsRequireStatus(
          { [ruleSetRequirement.ruleSetId]: true },
          [productKeyId],
          ruleSetRequirement.deadline?.toISOString()
        )
      );
    }

    yield notifyRuleSetsUpdated(
      requireMaturityRuleSetsWithDeadlinesDone,
      ruleSetsRequirements
    );
  } catch (error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.product_page.notification.maturity_rule_sets_activation_update_error',
          {
            defaultValue:
              'An error occured while updating maturity rules for this product',
          }
        )
      )
    );
    yield put(requireMaturityRuleSetsWithDeadlinesDone());
    throw error;
  }
}

function* addDeadlineInfo(ruleSetValues: { [p: number]: boolean }) {
  const maturityRuleSetDeadlines = (yield call(
    request,
    ValidationApi,
    'getRequestableRulesets'
  )).result;

  const activableMaturityRuleSets = agregateRuleSetsWithDeadlineInfo(
    ruleSetValues,
    maturityRuleSetDeadlines
  );
  return activableMaturityRuleSets;
}

function* updateViewRuleSets(
  ruleSetRequirements: Array<RuleSetRequirement>,
  ruleSets: List<Map<string, any>>
) {
  const updatedRuleSets = ruleSets.map((ruleSet) => {
    const requirement = ruleSetRequirements.find(
      (candidate) => candidate.ruleSetId === ruleSet?.get('id')
    );
    if (requirement) {
      return ruleSet?.setIn(['requested'], requirement.activated);
    }
    return ruleSet;
  });

  yield put({
    type: VIEW_AS_RECEIVE_RULESETS,
    ruleSets: updatedRuleSets,
  });
}

function* notifyRuleSetsUpdated(
  actionDone: any,
  ruleSetRequirements: Array<RuleSetRequirement>
) {
  yield put(
    notificationSuccess(
      ruleSetRequirements[0].activated
        ? i18n.t(
            'frontproductstream.product_page.notification.maturity_rule_sets_activated',
            {
              defaultValue: 'Maturity rules have been enabled for this product',
            }
          )
        : i18n.t(
            'frontproductstream.product_page.notification.maturity_rule_sets_deactivated',
            {
              defaultValue:
                'Maturity rules have been disabled for this product',
            }
          )
    )
  );
  const ruleSets: List<Map<string, any>> = yield select(selectRuleSets);
  yield* updateViewRuleSets(ruleSetRequirements, ruleSets);
  yield put(actionDone());
  const applyRules: any = applyRulesForViewAsRecipients;
  yield put(applyRules());
}

function* trackRuleSetActivationUpdates(
  ruleSetUpdates: Array<RuleSetRequirement>
) {
  const status = yield select(selectTargetProductStatus);
  for (const ruleSetUpdate of ruleSetUpdates) {
    const scenario_datas = {
      category: 'product',
      action: ruleSetUpdate.activated
        ? 'scenario_activated'
        : 'scenario_disabled',
      via: 'product_page',
      ruleset_id: '' + ruleSetUpdate.ruleSetId,
    };
    if (ruleSetUpdate.activated) {
      scenario_datas['has_deadline'] = ruleSetUpdate.deadline ? 'yes' : 'no';
    }

    scenario_datas[`nb_products_concerned_sharing_${status}`] = 1;

    track(scenario_datas);
  }
}

function agregateRuleSetsWithDeadlineInfo(
  ruleSetValues: {
    [key: number]: boolean;
  },
  maturityRuleSetDeadlines: List<Map<string, any>>
) {
  const ruleSetDetails = maturityRuleSetDeadlines.toArray() || [];
  return ruleSetDetails
    .filter((ruleSet) => ruleSet?.get('id') in ruleSetValues)
    .map((ruleSet) => {
      return {
        id: ruleSet?.get('id'),
        name: ruleSet?.get('label'),
        defaultDeadline: ruleSet?.get('default_deadline'),
        withDeadline: ruleSet?.get('with_deadline'),
      } as PromptedMaturityRuleSet;
    });
}
