import { List } from 'immutable';
import { flow } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { get, reduce } from 'utils/fp';

import { REDUCER_KEY } from './constants';

export const selectModuleState = (state) =>
  state[MODULE_REDUCER_KEY][REDUCER_KEY];

export const selectGroups = flow(selectModuleState, get('groups'));

export const selectValidationByRecipientId = flow(
  selectModuleState,
  get('validationByRecipientId')
);

export const selectActivatingGroups = flow(
  selectModuleState,
  get('activatingGroups')
);

export const selectDeactivatingGroups = flow(
  selectModuleState,
  get('deactivatingGroups')
);

export const selectAddingRecipients = flow(
  selectModuleState,
  get('addingRecipients')
);

export const selectPreparingGroups = flow(
  selectModuleState,
  get('preparingGroups')
);

export const selectIsLoading = flow(selectModuleState, get('isLoading'));

export const selectFailedFetching = flow(
  selectModuleState,
  get('failedFetching')
);

export const selectIsUnshareModalOpened = flow(
  selectModuleState,
  get(['unshare', 'isModalOpened'])
);

export const selectRecipientToUnshare = flow(
  selectModuleState,
  get(['unshare', 'recipient'])
);

export const selectIsUnsharingRecipient = flow(
  selectModuleState,
  get(['unshare', 'isUnsharing'])
);

export const selectToggledRecipients = flow(
  selectModuleState,
  get('groups'),
  (e) => e || List(),
  reduce((acc, group) => {
    const idgroups = group
      .get('members')
      .map((member) => member.get('toggled') && member.get('id'))
      .filter((e) => e);

    return acc.concat(idgroups);
  }, List())
);
