import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import Image from 'components/ui/image';
import { getDefaultDisplayName } from 'core/api/productversion';
import ProductPackshot from 'core/components/product/packshot';
import { withNavigate } from 'hocs';
import { fetchPublicProduct } from 'modules/catalog/product/actions/public';
import { selectModuleState } from 'modules/catalog/product/selectors';
import { selectLocalesByTargetMarket } from 'reducers/user/selectors';
import * as routes from 'routes';
import { sanitize } from 'utils/DOMPurify';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { fill } from 'utils/routing';

import './index.scss';

const mapStateToProps = (state, ownProps) => {
  const product = selectModuleState(state).get('currentPublicProduct');
  return {
    product: product && product.data,
    isLoading:
      !product || product.productVersionId !== ownProps.productVersionId,
    locales: selectLocalesByTargetMarket(state),
  };
};

const mapDispatchToProps = {
  fetchPublicProduct,
};

export class SourcingPreview extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    product: PropTypes.object,
    productVersionId: PropTypes.number.isRequired,
    fetchPublicProduct: PropTypes.func.isRequired,
    locales: PropTypes.object,
    organizationUuid: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchPublicProduct({
      productVersionId: this.props.productVersionId,
      reference: this.props.reference,
    });
  }

  goToOrganization = () => {
    const { organizationUuid } = this.props;
    this.props.navigate(fill(routes.companyProfile, organizationUuid));
  };

  renderLabels() {
    const { product } = this.props;
    const isLabeledBy = get(product, 'isLabeledBy') || [];
    if (!isLabeledBy.length) {
      return null;
    }
    return (
      <div className="PreviewSourcing__labels">
        {isLabeledBy.map(this.renderLabel)}
      </div>
    );
  }

  renderLabel = (label) => (
    <div className="PreviewSourcing__label" key={label.id}>
      <Image
        src={label.isConceptualizedBy.pictureUrl}
        maxWidth="64px"
        maxHeight="64px"
        alt={label.isConceptualizedBy.label}
      />
    </div>
  );

  renderPackshot() {
    const { product } = this.props;
    const pictures = get(product, ['assets', 'pictures']) || [];
    if (!pictures.length) {
      return null;
    }

    const picture = pictures.find((p) => p.isPackshot) || pictures[0];
    const { pictureUrl } = picture.exportables.reduce((image, exportable) => {
      if (
        exportable.width >= 512 &&
        (!image.size || exportable.width < image.size)
      ) {
        return {
          pictureUrl: exportable.uniformResourceIdentifier,
          size: exportable.width,
        };
      }
      return image;
    }, {});

    return <ProductPackshot src={pictureUrl} size="100%" />;
  }

  renderAllergens = () => {
    const { product } = this.props;
    const allergensData = get(product, ['allergens', 0, 'data']);
    if (!allergensData) {
      return null;
    }
    return (
      <p>
        <strong>{i18n.t('Allergens')} : </strong>
        {allergensData}
      </p>
    );
  };

  renderIngredients() {
    const { product } = this.props;
    const compositionData = get(product, ['composition', 0, 'data']);
    if (!compositionData) {
      return null;
    }
    return (
      <div className="PreviewSourcing__ingredients">
        <h2 className="PreviewSourcing__smallTitle">{i18n.t('Ingredients')}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: sanitize(compositionData),
          }}
        />
        {this.renderAllergens()}
      </div>
    );
  }

  renderBrand() {
    const { product } = this.props;
    const brandLabel = get(product, ['brand', 'label']);
    if (!brandLabel || brandLabel === 'NoBrand') {
      return null;
    }
    return (
      <>
        <h2 className="PreviewSourcing__smallTitle">{i18n.t('Brand')}</h2>
        <p className="PreviewSourcing__highlight">{brandLabel}</p>
      </>
    );
  }

  render() {
    const { locales, isLoading, organizationName, product } = this.props;
    if (isLoading) {
      return (
        <div className="PreviewSourcing__loading">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="PreviewSourcing">
        <div className="PreviewSourcing__left">
          <div className="PreviewSourcing__images">{this.renderPackshot()}</div>
          <div className="PreviewSourcing__description">
            <h2 className="PreviewSourcing__smallTitle">
              {i18n.t('Description')}
            </h2>
            {get(product, ['description', 0, 'data']) || (
              <em>{i18n.t('Not completed')}</em>
            )}
          </div>
        </div>
        <div className="PreviewSourcing__right">
          <div className="PreviewSourcing__name">
            <span className="PreviewSourcing__highlight">
              {getDefaultDisplayName(product, locales)}
            </span>
            <div className="PreviewSourcing__categories">
              <span className="PreviewSourcing__category">
                {get(product, ['kind', 'label'])}
              </span>
            </div>
          </div>
          <div className="PreviewSourcing__organization">
            {this.renderBrand()}
            <h2 className="PreviewSourcing__smallTitle">
              {i18n.t('Organization')}
            </h2>
            <p className="PreviewSourcing__highlight">
              {organizationName || i18n.t('Not public')}
            </p>
            <p>
              <Button
                content={i18n.t('See company profile')}
                onClick={this.goToOrganization}
                secondary
              />
            </p>
          </div>
          {this.renderIngredients()}
          {this.renderLabels()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigate(SourcingPreview));
