import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import createReducer from 'reducers';
import rootSaga from 'sagas';
import { isDevelopment } from 'utils';
import { sagaMiddleware, setSagaMiddlewareAsReady } from 'utils/modules/saga';
import composeEnhancers from 'utils/redux/devtools';
import reduxLogger, { logger } from 'utils/redux/logger';

const middlewares = [
  sagaMiddleware,
  typeof (thunk as any).default === 'function' ? (thunk as any).default : thunk,
];

if (isDevelopment() && logger.enabled) {
  middlewares.push(reduxLogger);
}

export default function configureStore(initialState) {
  const rootReducer = createReducer();
  const _store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);
  setSagaMiddlewareAsReady();
  return _store;
}

// WARNING: "var" declaration is required for DEV mode
// eslint-disable-next-line no-var
export var store = configureStore((window as any).__INITIAL_STATE__);
