import { Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  CANNOT_CHECK_MODAL_OPEN,
  CANNOT_CHECK_MODAL_RESET,
  CANNOT_CHECK_MODAL_SUBMIT,
  DIFF_MODAL_OPEN,
  DIFF_MODAL_RESET,
  DISPUTE_MODAL_OPEN,
  DISPUTE_MODAL_RESET,
  REMOVE_FIELD,
  SELECT_FIELD,
  SEND_REVIEW,
  SET_CONTRIBUTIONS,
  SET_CONTRIBUTION_DIFF,
  SET_REVIEW_FIELD,
  SET_STATE_MACHINE,
  SUBMIT_PHYSICAL_CHECK,
} from '../constants';

export const initialState = Map({
  // State machine for transitions of contribution statuses
  // Give you the possible status for a current status
  // Look like a map, key = status, value = list of possible status
  // { 1 : [2, 3],
  //   2 : [4]}
  stateMachine: Map(),
  // fields selected by the third party during a physical check
  physicalCheckFields: Map(),
  // fields selected by the manufacturer during a review
  reviewFields: Map(),
  // contribution for current product version
  contributionsByContributionType: Map(),
  cannotCheckModal: Map({
    isOpen: false,
    isSaving: false,
  }),
  disputeModal: Map({
    isOpen: false,
    isSaving: false,
  }),
  diffModal: Map({
    isOpen: false,
    isSaving: false,
  }),
});

export default createReducer(initialState, {
  [SET_STATE_MACHINE]: (state, { payload }) =>
    state.set('stateMachine', fromJS(payload.stateMachine)),
  [SELECT_FIELD]: (state, { payload }) =>
    state.setIn(['physicalCheckFields', payload.model], payload.value),
  [REMOVE_FIELD]: (state, { payload }) =>
    state.removeIn(['physicalCheckFields', payload.model]),
  [SET_REVIEW_FIELD]: (state, { payload }) =>
    state.setIn(['reviewFields', payload.transactionId], payload.isAccepted),
  [SET_CONTRIBUTIONS]: (state, { payload }) =>
    state
      .set('physicalCheckFields', Map())
      .set('reviewFields', Map())
      .set(
        'contributionsByContributionType',
        fromJS(payload.contributions).reduce(
          (acc, contribution) =>
            acc.set(String(contribution.get('type')), contribution),
          Map()
        )
      ),

  [CANNOT_CHECK_MODAL_OPEN]: (state) =>
    state.setIn(['cannotCheckModal', 'isOpen'], true),
  [CANNOT_CHECK_MODAL_RESET]: (state) =>
    state
      .setIn(['cannotCheckModal', 'isOpen'], false)
      .setIn(['cannotCheckModal', 'isSaving'], false),
  [CANNOT_CHECK_MODAL_SUBMIT]: (state) =>
    state.setIn(['cannotCheckModal', 'isSaving'], true),

  [DISPUTE_MODAL_OPEN]: (state) =>
    state.setIn(['disputeModal', 'isOpen'], true),
  [DISPUTE_MODAL_RESET]: (state) =>
    state
      .setIn(['disputeModal', 'isOpen'], false)
      .setIn(['disputeModal', 'isSaving'], false),
  [SEND_REVIEW]: (state) => state.setIn(['cannotCheckModal', 'isSaving'], true),
  [DIFF_MODAL_OPEN]: (state) => state.setIn(['diffModal', 'isOpen'], true),
  [DIFF_MODAL_RESET]: (state) =>
    state
      .setIn(['diffModal', 'isOpen'], false)
      .setIn(['diffModal', 'isSaving'], false),
  [SUBMIT_PHYSICAL_CHECK]: (state) =>
    state.setIn(['diffModal', 'isSaving'], true),
  [SET_CONTRIBUTION_DIFF]: (state, { payload }) =>
    state.set('contributionDiff', fromJS(payload.contributionDiff)),
});
