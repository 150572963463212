import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  selectIsManufacturer,
  selectIsProductDnaSource,
} from 'reducers/user/selectors';
import * as routes from 'routes';

import './app-store-link.scss';

export const AppStoreLink = () => {
  const isManufacturer = useSelector(selectIsManufacturer);
  const isProductDnaSource = useSelector(selectIsProductDnaSource);
  if (!isManufacturer || isProductDnaSource) {
    return null;
  }
  return (
    <li>
      <Link className="Navbar__appStoreLink" to={routes.appStore}>
        <i className="mdi mdi-apps" /> <span>App store</span>
      </Link>
    </li>
  );
};
