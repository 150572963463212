import { call, put } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import applicationApi from 'resources/applicationApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { track } from 'utils/tracking';

import {
  ACTIVATE_APPLICATION_ERROR,
  ACTIVATE_APPLICATION_SUCCESS,
} from '../constants';

export const token = {
  error: i18n.t(
    'frontproductstream.app_store.activate_application.notification_error',
    {
      defaultValue: 'Error when trying to activate the app.',
    }
  ),
  success: i18n.t(
    'frontproductstream.app_store.activate_application.notification_success',
    {
      defaultValue: 'Application activated successfully.',
    }
  ),
};

export default function* (action) {
  try {
    yield call(
      (applicationId) => applicationApi.ApplicationActivate(applicationId),
      action.payload.id
    );
    yield put({
      type: ACTIVATE_APPLICATION_SUCCESS,
      payload: { id: action.payload.id },
    });
    yield call(track, {
      category: 'app',
      action: `app_activated`,
      label: `app#${action.payload.name}`,
      feature_name: `app#${action.payload.name}`,
    });
    yield put(notificationSuccess(token.success));
  } catch (error) {
    yield put(notificationError(token.error));
    yield put({ type: ACTIVATE_APPLICATION_ERROR, error: error.message });
    yield call(logError, error.message);
  }
}
