import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomAutocomplete from 'components/ui/autocomplete/custom';
import Field from 'components/ui/form/field';

import './multi-autocomplete.scss';

export class FormMultiAutocomplete extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    // Parameter `params` to pass extra agrument when calling getList through CustomAutocomplete
    params: PropTypes.object,
  });

  static defaultProps = Object.assign({}, Field.defaultProps, {
    params: {},
  });

  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue(data) {
    const { value, field } = this.props;
    const localValue = value || [];

    // Delete event.
    if (typeof data === 'number') {
      localValue.splice(data, 1);
      return localValue;
    }

    if (!data.value) {
      return value;
    }
    const { mainKey } = field.inputKind;

    // Don't put the same value twice.
    const existingValue = localValue.find(
      (v) => v[mainKey].id === data.value.id
    );
    if (existingValue) {
      return value;
    }

    const newValue = {};
    newValue[mainKey] = data.value;
    return [...localValue, newValue];
  }

  render() {
    const { value, field, params } = this.props;
    if (!this.isVisible()) {
      return null;
    }
    const { mainKey } = field.inputKind;
    if (!mainKey) {
      return null;
    }

    const mainKeyField = field.children.find((f) => f.model === mainKey);
    if (!mainKeyField) {
      return null;
    }

    const localValue = (value || []).map((v) => v[mainKey]);

    return (
      <div
        className={classNames(
          this.getClasses({ FormMultiAutocomplete: true, row: true })
        )}
      >
        {this.renderLabel('col-xs-4')}
        <div className="InputField__input col-xs-8">
          <CustomAutocomplete
            id={this.getId()}
            value={localValue}
            onSelect={this.handleChange}
            onUnselect={this.handleChange}
            path={mainKeyField.inputKind.url}
            placeholder={mainKeyField.placeholder}
            disabled={this.isReadOnly()}
            params={params}
            multiple
            searchOnClick
            disableOnClickOutside
            delegateSelection
          />
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect()(FormMultiAutocomplete);
