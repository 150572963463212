import { createSelector } from 'reselect';

const selectProductHierarchy = (state) => state.productHierarchy;

export const selectProductHierarchyFilters = createSelector(
  selectProductHierarchy,
  (productHierarchy) => productHierarchy.filters
);

export const selectProductHierarchyFlatList = createSelector(
  selectProductHierarchy,
  (productHierarchy) => productHierarchy.segmentFlatList
);

export const selectProductHierarchyMap = createSelector(
  selectProductHierarchy,
  (productHierarchy) => productHierarchy.segmentMap
);
