import { Map } from 'immutable';

import { notificationError } from 'actions/notification';
import { hasAnyProductUpdatePermission, isMaker } from 'core/api/user';
import { selectUser } from 'reducers/user/selectors';
import { getProductKeys as getProductKeysApi } from 'resources/serviceProductApi';
import * as routes from 'routes';
import { push } from 'utils/history';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';

export const getProductKeys = async (targetMarket, gtin, user, dispatch) => {
  /** @type {{ productKeys?: { id: number }[]; error?: any }} */
  const { productKeys, error } = await getProductKeysApi({
    productIdOrReference: gtin,
    targetMarketId: targetMarket,
  });
  if (error) {
    if (error.status === 403 || error.status === 404) {
      processNotFound({ gtin, user, dispatch });
    } else if (error.status === 400) {
      push(routes.catalog);
      dispatch(
        notificationError(
          i18n.t('frontproductstream.redirect.error.invalid_gtin', {
            defaultValue: 'Invalid GTIN: {{gtin}}',
            gtin,
          })
        )
      );
    } else {
      push(routes.catalog);
    }
  } else if (!productKeys || productKeys.length === 0) {
    processNotFound({ gtin, user, dispatch });
  }
  return productKeys;
};

function processNotFound({ gtin, user, dispatch }) {
  if (isMaker(user) && hasAnyProductUpdatePermission(user)) {
    push(routes.productCreate, { gtin });
  } else {
    push(routes.catalog);
    dispatch(
      notificationError(
        i18n.t('frontproductstream.redirect.error.no_product_rights', {
          defaultValue:
            "You don't have the rights to see product {{gtin}}. Contact your adminisrator.",
          gtin,
        }),
        { sticky: true }
      )
    );
  }
}
export const redirectToProductPages = async (
  productRoute,
  query,
  dispatch,
  getState
) => {
  const user = selectUser(getState());
  const gtin = Map(query).get('gtin');
  const targetMarket = Map(query).get('targetMarket');
  const productKeys = await getProductKeys(targetMarket, gtin, user, dispatch);
  if (productKeys) {
    push(fill(productRoute, productKeys[0].id));
  }
};

export const productChat = (query) => (dispatch, getState) => {
  redirectToProductPages(routes.productChat, query, dispatch, getState);
};

export const productDashboard = (query) => (dispatch, getState) => {
  redirectToProductPages(routes.productDashboard, query, dispatch, getState);
};

export const productHistory = (query) => (dispatch, getState) => {
  redirectToProductPages(routes.productHistory, query, dispatch, getState);
};

export const productPage = (query) => (dispatch, getState) => {
  redirectToProductPages(routes.productPage, query, dispatch, getState);
};
