import classNames from 'classnames';
import { get } from 'lodash';
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import { PureComponent } from 'react';
import { list } from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { listAllAssets, updateNewPackshot } from 'actions/media';
import { SETTING_ENABLE_VALUE } from 'constants/organization-settings';
import * as sharedStatusConstants from 'constants/sharedStatus';
import { getLastShareStatus } from 'core/api/retailerproductversion';
import { loadReferentials } from 'modules/assets/actions';
import { selectReferentials } from 'modules/assets/selectors';
import { hasFeatureValue } from 'modules/feature-flag';
import { FEATURE_PRODUCT_DOCUMENTS } from 'modules/feature-flag/constants';
import { selectHasReleaseProductpageFlywheelMedia } from 'modules/feature-flag/selectors';
import { getAll as getAllRecipients } from 'modules/recipients/reducers';
import { applyRulesForViewAsRecipients } from 'modules/validation';
import {
  selectAssetExpiredCount,
  selectDocuments,
  selectEnrichedContents,
  selectPictures,
  selectVideos,
} from 'reducers/media';
import {
  selectProductKeyId,
  selectProductKeyOrganizationId,
  selectProductKeyProductId,
  selectProductKeyTargetMarketId,
} from 'reducers/productVersion';
import { selectUser } from 'reducers/user/selectors';
import { StringOrNumber } from 'types';
import i18n from 'utils/i18n';
import { sortDesc } from 'utils/sort';

import MediaErrors from '../media-errors';

import { AddMediaDropdown } from './AssetV2/AddMediaDropdown';
import Actions from './actions';
import Asset from './asset';
import './asset-list.scss';
import { SeeEditAllAsset } from './asset/modals';
import AssetListButtonsBar from './buttons-bar';
import AssetContext from './context';

const mapStateToProps = (state) => ({
  hasProductDocument: hasFeatureValue(
    selectUser(state),
    FEATURE_PRODUCT_DOCUMENTS,
    SETTING_ENABLE_VALUE
  ),
  enrichedcontents: selectEnrichedContents(state),
  docs: selectDocuments(state),
  pictures: selectPictures(state),
  videos: selectVideos(state),
  hasReleaseProductpageFlywheelMedia:
    selectHasReleaseProductpageFlywheelMedia(state),
  nbOfPictureExpired: selectAssetExpiredCount(state),
  recipients: getAllRecipients(state),
  referentials: selectReferentials(state),
  productKeyId: selectProductKeyId(state),
  contentOwnerId: selectProductKeyOrganizationId(state),
  targetMarketId: selectProductKeyTargetMarketId(state),
  productId: selectProductKeyProductId(state),
});

const mapDispatchToProps = {
  applyRulesForViewAsRecipients,
  listAllAssets,
  loadReferentials,
  updateNewPackshot,
};

export class AssetList extends PureComponent {
  static propTypes = {
    docs: array.isRequired,
    enrichedcontents: array.isRequired,
    pictures: array.isRequired,
    videos: array.isRequired,
    nbOfPictureExpired: number.isRequired,
    entity: object.isRequired,
    entityId: oneOfType([string, number]).isRequired,
    entityKind: string.isRequired,
    field: object.isRequired,
    hasProductUpdatePermission: bool,
    recipients: list,
    referentials: object,
    hasProductDocument: bool.isRequired,
    productKeyId: StringOrNumber.isRequired,
    // actions
    applyRulesForViewAsRecipients: func.isRequired,
    listAllAssets: func.isRequired,
    loadReferentials: func.isRequired,
    updateNewPackshot: func.isRequired,
    hasReleaseProductpageFlywheelMedia: bool,
  };

  state = {
    withExpired: false,
    newPackshotId: null,
    selectedPictureIds: [],
    isSeeAndEditAllMediaModalOpen: false,
  };

  constructor(props) {
    super(props);
    // Do not show asset list before referentials are loaded
    if (!props.referentials) {
      props.loadReferentials();
    }
  }

  onPictureSelectionChange = (pictureId, selected) => {
    this.setState((state) => {
      let selectedPictureIds;
      if (selected) {
        selectedPictureIds = state.selectedPictureIds.concat([pictureId]);
      } else {
        selectedPictureIds = state.selectedPictureIds.filter(
          (id) => pictureId !== id
        );
      }
      return { selectedPictureIds };
    });
  };

  settingNewPackshot = ({ newPackshotId = null }) => {
    this.setState((state) => {
      if (
        state.selectedPictureIds.some(
          (pictureId) => pictureId === newPackshotId
        )
      ) {
        return {
          newPackshotId,
          selectedPictureIds: state.selectedPictureIds.filter(
            (pictureId) => pictureId !== newPackshotId
          ),
        };
      }
      return { newPackshotId };
    });
  };

  referenceNode = (node) => {
    this.node = node;
  };

  listAllAssets = (withLastRequests, withExpired) => {
    const { productKeyId } = this.props;

    return this.props.listAllAssets({
      with_last_requests: withLastRequests,
      with_expired: withExpired,
      product_key_id: productKeyId,
    });
  };

  statusIsPending = (entity) => {
    const sharingStatus = getLastShareStatus(entity.source);
    return !!(
      sharingStatus && sharingStatus.id === sharedStatusConstants.pending.id
    );
  };

  updateEntities = async (mediaType, assetId = null) => {
    const { entity } = this.props;
    // For performances, we do not want to call picturelist if update on packshot only
    // We simply update store if call succeded
    if (assetId !== null && mediaType === 'ProductPicture') {
      this.props.updateNewPackshot(assetId);
    } else {
      await this.listAllAssets(
        this.statusIsPending(entity),
        this.state.withExpired
      );
      this.setState((state) => {
        const selectedPictureIds = state.selectedPictureIds.filter(
          (pictureId) => this.props.pictures.some((id) => id === pictureId)
        );
        if (state.selectedPictureIds.length !== selectedPictureIds.length) {
          return { selectedPictureIds };
        }
        return state;
      });
    }
    this.props.applyRulesForViewAsRecipients();
  };

  areAssetReadOnly = (assetType) => {
    const { field } = this.props;
    return !!(
      field &&
      field.options &&
      (field.options.readOnly || get(field.options, `${assetType}.readOnly`))
    );
  };

  arePicturesReadOnly = () => this.areAssetReadOnly('picture');
  areVideosReadOnly = () => this.areAssetReadOnly('video');
  areDocumentsReadOnly = () => this.areAssetReadOnly('document');
  areRecipesReadOnly = () => this.areAssetReadOnly('recipe');

  filterExpiredMedia = () => {
    const { entity } = this.props;
    this.listAllAssets(this.statusIsPending(entity), !this.state.withExpired);
    this.setState((state) => ({
      withExpired: !state.withExpired,
      selectedPictureIds: [],
    }));
  };

  sortMedia() {
    // We want to have the following order for media:
    // packshot - videos - recipes - pictures - documents - archived pictures
    const { docs, enrichedcontents, hasProductDocument, pictures, videos } =
      this.props;

    const mapIndex = (_assets) =>
      _assets.map((asset, index) => ({ ...asset, index }));

    let assets = mapIndex(pictures)
      .concat(mapIndex(videos))
      .concat(mapIndex(enrichedcontents));

    if (hasProductDocument) {
      assets = assets.concat(mapIndex(docs));
    }

    const toComparable = (asset) => {
      const packshot = asset.isPackshot ? 1 : 0;
      let archived = 1;
      if (
        asset.fileEffectiveStartDateTime &&
        asset.fileEffectiveStartDateTime > Date.now()
      ) {
        archived = 0;
      }
      if (
        asset.fileEffectiveEndDateTime &&
        archived !== 0 &&
        asset.fileEffectiveEndDateTime < Date.now()
      ) {
        archived = 0;
      }
      const date = asset.createdAt ? new Date(asset.createdAt) : new Date();
      return `${packshot}-${archived}-${date.toISOString()}`;
    };

    return assets.sort((a, b) => sortDesc(toComparable(a), toComparable(b)));
  }

  renderButtonsBar() {
    const { entity, recipients, productKeyId } = this.props;
    const items = [].concat(
      this.props.pictures,
      this.props.videos,
      this.props.enrichedcontents
    );

    return (
      <AssetListButtonsBar
        productKeyId={productKeyId}
        pictureReadOnly={this.arePicturesReadOnly()}
        statusIsPending={this.statusIsPending(entity)}
        selectedPictureIds={this.state.selectedPictureIds}
        updateEntities={this.updateEntities}
        disableDownloadPictures={items.length === 0}
        recipients={recipients}
      />
    );
  }

  renderList() {
    const {
      field,
      referentials,
      productKeyId,
      entityId,
      entityKind,
      hasProductUpdatePermission,
    } = this.props;

    if (!referentials) {
      return null;
    }
    const items = this.sortMedia();

    const renderedItems = items.map((item) =>
      this.props.hasReleaseProductpageFlywheelMedia ? (
        <Asset
          entityId={entityId}
          entityKind={entityKind}
          asset={item}
          productKeyId={productKeyId}
          updateEntities={this.updateEntities}
          actions={field.actions}
          options={field.options}
          settingNewPackshot={this.settingNewPackshot}
          newPackshotId={this.state.newPackshotId}
          selectedPictureIds={this.state.selectedPictureIds}
          onPictureSelectionChange={this.onPictureSelectionChange}
          field={field}
          hasUpdatePermission={hasProductUpdatePermission}
          index={item.index}
          key={`${item.type}-${item.id}`}
        />
      ) : (
        <div className="col-xs-4" key={`${item.type}-${item.id}`}>
          <Asset
            entityId={entityId}
            entityKind={entityKind}
            asset={item}
            productKeyId={productKeyId}
            updateEntities={this.updateEntities}
            actions={field.actions}
            options={field.options}
            settingNewPackshot={this.settingNewPackshot}
            newPackshotId={this.state.newPackshotId}
            selectedPictureIds={this.state.selectedPictureIds}
            onPictureSelectionChange={this.onPictureSelectionChange}
            field={field}
            hasUpdatePermission={hasProductUpdatePermission}
            index={item.index}
          />
        </div>
      )
    );

    return (
      <AssetContext.Provider value={{ formGroup: field, entityId }}>
        {this.props.hasReleaseProductpageFlywheelMedia && (
          <AddMediaDropdown
            items={items}
            updateEntities={this.updateEntities}
            fieldActions={field.actions}
            productKeyId={productKeyId}
          />
        )}
        <div
          className={classNames({
            'AssetList__list-rfp':
              this.props.hasReleaseProductpageFlywheelMedia,
            'row AssetList__list AssetList__row':
              !this.props.hasReleaseProductpageFlywheelMedia,
          })}
        >
          {!this.props.hasReleaseProductpageFlywheelMedia && (
            <Actions
              productKeyId={productKeyId}
              actions={field.actions}
              updateEntities={this.updateEntities}
              items={items}
            />
          )}
          {renderedItems}
        </div>
      </AssetContext.Provider>
    );
  }

  renderErrors() {
    const { entityId, entityKind, field, hasProductUpdatePermission } =
      this.props;

    return (
      <MediaErrors
        entityId={entityId}
        entityKind={entityKind}
        hasUpdatePermission={hasProductUpdatePermission}
        picturesReadOnly={this.arePicturesReadOnly()}
        videosReadOnly={this.areVideosReadOnly()}
        documentReadOnly={this.areDocumentsReadOnly()}
        recipeReadOnly={this.areRecipesReadOnly()}
        value={field}
        withContactBlock
        hasReleaseProductpageFlywheelMedia={
          this.props.hasReleaseProductpageFlywheelMedia
        }
      />
    );
  }

  render() {
    const { entity, field, nbOfPictureExpired } = this.props;
    const { withExpired } = this.state;
    if (!entity || !field) {
      return null;
    }

    return (
      <div
        ref={this.referenceNode}
        className="form-group card card-block AssetList__container"
      >
        {this.renderButtonsBar()}
        {!this.props.hasReleaseProductpageFlywheelMedia && this.renderErrors()}
        {this.renderList()}
        {this.props.hasReleaseProductpageFlywheelMedia && this.renderErrors()}

        {!this.props.hasReleaseProductpageFlywheelMedia &&
          nbOfPictureExpired > 0 && (
            <Button
              content={
                withExpired
                  ? i18n.t(
                      'frontproductstream.asset_list.hide_archived_button.label',
                      { defaultValue: 'Hide archived media' }
                    )
                  : i18n.t(
                      'frontproductstream.asset_list.show_archived_button.label',
                      { defaultValue: 'Display archived media' }
                    )
              }
              onClick={this.filterExpiredMedia}
              className="AssetList__archivedButton btn-link"
            />
          )}

        {this.props.hasReleaseProductpageFlywheelMedia && (
          <>
            <Button
              link
              onClick={() =>
                this.setState({ isSeeAndEditAllMediaModalOpen: true })
              }
            >
              <span>
                {i18n.t(
                  'frontproductstream.asset_list.see_all_media_button.label',
                  {
                    defaultValue: 'See and edit all media',
                  }
                )}
              </span>
              <i className="mdi mdi-24px mdi-arrow-right" />
            </Button>
            {this.state.isSeeAndEditAllMediaModalOpen && (
              <SeeEditAllAsset
                productKeyId={this.props.productKeyId}
                onClose={() =>
                  this.setState({ isSeeAndEditAllMediaModalOpen: false })
                }
                updateEntities={this.updateEntities}
                isShowingExpiredMedia={withExpired}
                items={this.sortMedia()}
                field={field}
                entityId={this.props.entityId}
                recipients={this.props.recipients}
                onToggleArchived={this.filterExpiredMedia}
                expiredPictureCount={nbOfPictureExpired}
                statusIsPending={this.statusIsPending(this.props.entity)}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetList);
