import { notificationError, notificationSuccess } from 'actions/notification';
import { getContentOwnerId, getProductId } from 'core/api/productversion';
import {
  selectEditedProductVersion,
  selectProductKeyId,
} from 'reducers/productVersion';
import validationApi from 'resources/validationApi';
import i18n from 'utils/i18n';

import { applyRulesForViewAsRecipients } from './view-as';

export const toggleRuleBypassForProduct =
  (ruleId: number, unbypass?: boolean) => (dispatch, getState) => {
    const state = getState();
    const productVersion = selectEditedProductVersion(state);
    const productKeyId = selectProductKeyId(state);
    const productId = getProductId(productVersion);
    const organizationId = getContentOwnerId(productVersion);
    let promise;
    if (unbypass) {
      promise = validationApi.unbypassRule(
        ruleId,
        productId,
        organizationId,
        productKeyId
      );
    } else {
      promise = validationApi.bypassRule(
        ruleId,
        productId,
        organizationId,
        productKeyId
      );
    }
    promise.then(
      () => {
        dispatch(applyRulesForViewAsRecipients());
        const successMessageEnabled = i18n.t(
          'frontproductstream.activate_validation_rule.success.notification',
          { defaultValue: 'Rule has been re-enabled with success.' }
        );
        const successMessageDisabled = i18n.t(
          'frontproductstream.deactivate_validation_rule.success.notification',
          { defaultValue: 'Rule has been disabled with success.' }
        );
        dispatch(
          notificationSuccess(
            unbypass ? successMessageEnabled : successMessageDisabled
          )
        );
      },
      () => {
        const errorMessageEnabled = i18n.t(
          'frontproductstream.activate_validation_rule.error.notification',
          { defaultValue: 'The rule is already enabled.' }
        );
        const errorMessageDisabled = i18n.t(
          'frontproductstream.deactivate_validation_rule.error.notification',
          { defaultValue: 'The rule is already disabled.' }
        );
        dispatch(
          notificationError(
            unbypass ? errorMessageEnabled : errorMessageDisabled
          )
        );
      }
    );
    return promise;
  };
