import { memo, useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouterProvider, createHashRouter } from 'react-router-dom';

import RedirectLegacyRoutes from 'legacy/redirects';
import { selectIsAuthorized } from 'modules/auth/shared';
import { NAVIGATE_EVENT } from 'utils/history';

import { App } from './App';
import PrivateRoutes from './Private';
import PublicRoutes from './Public';

export const AppRouter = memo(() => {
  const isAuthorized = useSelector(selectIsAuthorized);

  const router = useMemo(() => {
    const toRouteData = ({
      path,
      component: RouteComponent,
      exact,
    }: {
      path: string;
      component: React.ComponentType<any>;
      exact?: boolean;
    }) => ({
      path: exact ? path : `${path}/*`,
      element: <RouteComponent />,
    });
    const publicRoutes = PublicRoutes.map(toRouteData);
    const privateRoutes = isAuthorized
      ? PrivateRoutes.map(toRouteData)
      : [{ path: '/*', element: null }];
    const redirectedRoutes = RedirectLegacyRoutes.map(
      ({ from, to, exact }) => ({
        path: exact ? from : `${from}/*`,
        element: <Navigate to={to} replace />,
      })
    );
    return createHashRouter([
      {
        path: '/',
        element: <App />,
        children: [...publicRoutes, ...privateRoutes, ...redirectedRoutes],
      },
    ]);
  }, [isAuthorized]);

  useLayoutEffect(() => {
    const callback = (
      event: MessageEvent<{ type: string; to: string; replace?: boolean }>
    ) => {
      if (
        event.origin === window.location.origin &&
        event.data?.type === NAVIGATE_EVENT
      ) {
        router.navigate(event.data.to, { replace: event.data.replace });
      }
    };
    window.addEventListener('message', callback);
    return () => {
      window.removeEventListener('message', callback);
    };
  }, [router]);

  return <RouterProvider router={router} />;
});
