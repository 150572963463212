import { bool, func, number, shape } from 'prop-types';
import { Component } from 'react';
import { mapContains } from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { separateActions } from 'utils/redux';

import {
  fetchProductsAggregations,
  fetchReport,
  resetReporting,
  saveReportConfig,
} from '../actions';
import { selectIsReportLoading } from '../selectors';

import ReportingFilters from './filters';
import ReportingList from './list';

const mapStateToProps = (state) => ({
  loading: selectIsReportLoading(state),
});

const mapDispatchToProps = {
  resetReporting,
  saveReportConfig,
  fetchReport,
  fetchProductsAggregations,
};

export class Reporting extends Component {
  static propTypes = {
    reportConfig: mapContains({
      id: number.isRequired,
    }).isRequired,
    loading: bool.isRequired,
    actions: shape({
      resetReporting: func.isRequired,
      saveReportConfig: func.isRequired,
      fetchReport: func.isRequired,
      fetchProductsAggregations: func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.saveConfig();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { reportConfig, actions } = this.props;
    if (reportConfig.get('id') !== prevProps.reportConfig.get('id')) {
      actions.resetReporting();
      this.saveConfig();
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.props.actions.resetReporting();
  }

  saveConfig() {
    const { actions, reportConfig } = this.props;
    actions.saveReportConfig(reportConfig);
  }

  fetchData() {
    this.props.actions.fetchReport();
    this.props.actions.fetchProductsAggregations();
  }

  render() {
    const { reportConfig, loading } = this.props;
    return (
      <>
        <ReportingFilters />
        <ReportingList reportConfig={reportConfig} loading={loading} />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(Reporting);
