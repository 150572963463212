import FileSaver from 'file-saver';
import { isFinite, isFunction } from 'lodash';
import { compose } from 'lodash/fp';

import { get } from 'utils/immutable';

import { getEnv } from './env';
import { getHost } from './location';

/**
 * Flattens an array of arrays into one array.
 * Example: flatten([[a,b,c], [d,e]]) == [a,b,c,d,e]
 * Note: Does not do deep flattening.
 * Source: http://stackoverflow.com/q/29158723
 */
export const flatten = (a) => (Array.isArray(a) ? [].concat(...a) : a);

export const sortBy = (sortFn) => (arr) => arr.sort(sortFn);

export const removeAt = (list, index) =>
  list.slice(0, index).concat(list.slice(index + 1));

export const replaceAt = (list, index, item) => [
  ...list.slice(0, index),
  item,
  ...list.slice(index + 1),
];

export function compareNames(obj1, obj2) {
  return (get(obj1, 'label') || get(obj1, 'name')) <
    (get(obj2, 'label') || get(obj2, 'name'))
    ? -1
    : 1;
}

export function toUTC(date) {
  return new Date(Date.UTC(date.year(), date.month(), date.date()));
}

export function fromUTC(date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

export function parseBoolean(s) {
  if (s === 'true') {
    return true;
  } else if (s === 'false') {
    return false;
  }
  return null;
}

export function parseNumber(value) {
  const number = Number(value);
  if (value !== '' && value !== null && isFinite(number)) {
    return number;
  } else {
    return null;
  }
}

export function parseIfNumber(value) {
  const parsed = parseNumber(value);
  if (parsed !== null) {
    return parsed;
  } else {
    return value;
  }
}

export const compareByLabelOrName = (a, b) => {
  const cpA = Object.keys(a).includes('label') ? a.label : a.name;
  const cpB = Object.keys(b).includes('label') ? b.label : b.name;
  if (cpA < cpB) {
    return -1;
  }
  if (cpA > cpB) {
    return 1;
  }
  return 0;
};

export const ENTER_KEY = 13;

// TODO remove
export const toJS = (value) =>
  value && typeof value.toJS === 'function' ? value.toJS() : value;

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const isTest = () => process.env.NODE_ENV === 'test';

export const isProduction = (env?: string) =>
  ['prod', 'prd'].includes(env || getEnv());

export const isRealProduction = () =>
  /^(stream|productdna|app)\.(alkemics|supplierxm\.salsify)\.com$/.test(
    getHost()
  );

export const isPreProduction = (env?: string) => (env || getEnv()) === 'ppr';

export const isRealPreproduction = () =>
  /^ppr-(stream|productdna|app)\.(alkemics|supplierxm\.salsify)\.com$/.test(
    getHost()
  );

export const isPromiseCanceled = (promise) => promise?.isCanceled?.() === true;

export const cancelPromise = (promise: any, callback?: () => void) => {
  if (promise && !isPromiseCanceled(promise) && isFunction(promise.cancel)) {
    promise.cancel();
    if (isFunction(callback)) {
      callback();
    }
  }
};

export const calculateOffset = (page: number, limit: number) =>
  Math.max((page - 1) * limit, 0);
export const calculatePage = (offset: number, limit: number) =>
  Math.ceil((offset + limit) / limit);
export const calculatePages = (total: number, limit: number) =>
  Math.ceil(total / limit);

export const toGTIN = (ref) => {
  const mask = '00000000000000';
  const reference = String(ref);
  return mask.substring(0, mask.length - reference.length) + reference;
};

export const toProductIdentifier = (ref) => {
  return ref?.trim().toUpperCase().substring(0, 16);
};

export const values = (obj) => Object.values(obj || {});

export const flattenMap = compose(flatten, values);

export const saveAs = (global as any).saveAs || FileSaver.saveAs || FileSaver;

// ---------
// Debugging
// =========
//
// log() logs it's first parameter and returns it, so you can wrap it around any
// value where it is processed. Useful if you have functions like:
// ```
// const mapStateToProps = (state) => ({
//   a: state.a,
//   b: log(state.c),
// })
// ```
// So you don't need to convert it into a common function in order to log the
// value.
if (isDevelopment()) {
  (window as any).log = function (descriptionOrvalue, valueWhenDescription) {
    /* eslint-disable no-console */
    if (arguments.length === 2) {
      console.log(
        `%c${descriptionOrvalue}`,
        'color: blue',
        valueWhenDescription
      );
      return valueWhenDescription;
    } else {
      console.log(descriptionOrvalue);
      return descriptionOrvalue;
    }
  };
  // when you want to remove the log output, but keep the log(...) in your code
  (window as any).xlog = (x, y) => y || x;

  (window as any).log2 = (a) => (b) => (window as any).log(a, b);
}

export const dedupe = <T>(arr: T[]) => [...new Set<T>(arr)];

export function isIE11() {
  return window.navigator.userAgent.indexOf('Trident/7.0') > -1;
}
