import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Tipster from 'components/ui/tipster';
import { getTargetOrganizationId } from 'core/api/sharing-unit';
import { withUserType } from 'hocs';
import { selectHasSynchronizationStatusFeature } from 'modules/feature-flag/selectors';
import { getRecipientsMap } from 'modules/recipients/reducers';
import { selectValidationResultsForActiveShares } from 'modules/validation';
import {
  selectAlreadySharedShares,
  selectCurrentProductVersionGTIN,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { listExportSynchronizationStatus } from './actions';
import {
  SSC_SUCCESS_WARNING,
  SYNCHRONIZATION_ERROR,
  SYNCHRONIZATION_STATUSES_CODE,
} from './constants';
import { getStatusesByRecipient } from './selectors';
import { getPriorityErrorFromGDSNStatus } from './utils';

const mapStateToProps = createStructuredSelector({
  shares: selectAlreadySharedShares,
  gtin: selectCurrentProductVersionGTIN,
  hasFeatureSynchronizationStatus: selectHasSynchronizationStatusFeature,
  recipients: getRecipientsMap,
  synchronizationStatusesByRecipient: getStatusesByRecipient,
  validationDataForActiveShares: selectValidationResultsForActiveShares,
});

const mapDispatchToProps = {
  listExportSynchronizationStatus,
};
export class UnsynchedWarning extends PureComponent {
  static propTypes = {
    gtin: PropTypes.string,
    hasFeatureSynchronizationStatus: PropTypes.bool,
    isRetailer: PropTypes.bool.isRequired,
    recipients: ImmutablePropTypes.map,
    shares: ImmutablePropTypes.list,
    synchronizationStatusesByRecipient: ImmutablePropTypes.map,
    validationDataForActiveShares: ImmutablePropTypes.map,
    actions: PropTypes.shape({
      listExportSynchronizationStatus: PropTypes.func.isRequired,
    }),
  };

  componentDidMount() {
    if (this.props.hasFeatureSynchronizationStatus) {
      this.props.actions.listExportSynchronizationStatus(this.props.gtin);
    }
  }

  getUnsynchedRecipients() {
    const { shares, validationDataForActiveShares } = this.props;
    const sharedWithOrgIds = shares.map(getTargetOrganizationId);
    if (
      validationDataForActiveShares &&
      validationDataForActiveShares.get('failedBlocking') &&
      sharedWithOrgIds &&
      sharedWithOrgIds.size
    ) {
      return (
        validationDataForActiveShares
          .get('recipients')
          .filter((recipient) => sharedWithOrgIds.includes(recipient.get('id')))
          .filter((recipient) => recipient.get('failedBlocking')) || List()
      );
    }
    return List();
  }

  getExportSyncError() {
    const { synchronizationStatusesByRecipient } = this.props;
    const exportErrors = synchronizationStatusesByRecipient.map((s) =>
      getPriorityErrorFromGDSNStatus(s)
    );
    const formatedStatuses = exportErrors.reduce((_map, status) => {
      let map = _map;
      if (
        status &&
        (status.get('status') === SYNCHRONIZATION_ERROR.const ||
          (SSC_SUCCESS_WARNING.includes(status.getIn(['context', 'code'])) &&
            status.get('metadata').size > 0))
      ) {
        const contextCode =
          status.getIn(['context', 'code']) || SYNCHRONIZATION_ERROR.const;
        if (!map.get(contextCode)) {
          map = map.set(contextCode, status);
          map = map.setIn([contextCode, 'recipients'], List());
        }
        if (
          !map
            .getIn([contextCode, 'recipients'], [])
            .includes(status.get('targetOrganization'))
        ) {
          map = map.setIn(
            [contextCode, 'recipients'],
            map
              .getIn([contextCode, 'recipients'])
              .push(status.get('targetOrganization'))
          );
        }
      }
      return map;
    }, Map());
    return formatedStatuses.toList();
  }

  renderExportSyncError = (error) => {
    if (!error.get('recipients')) {
      return null;
    }
    let message = i18n.t(
      'frontproductstream.product_page.unsync_warning.error_message_for_retailers',
      {
        defaultValue:
          'Synchronization error for the following retailers, Alkemics is working on it and will come back to you :',
      }
    );

    if (
      error.getIn(['context', 'code']) &&
      SYNCHRONIZATION_STATUSES_CODE[error.getIn(['context', 'code'])]
    ) {
      message =
        SYNCHRONIZATION_STATUSES_CODE[error.getIn(['context', 'code'])].help;
    }

    return (
      <div
        key={`tipster-sync-error-${error.getIn(['context', 'code'])}`}
        id={`tipster-sync-error-${error.getIn(['context', 'code'])}`}
        className="tipster-reason"
      >
        {message}
        {error.get('recipients').map((recipient) => (
          <div
            key={`tipster-invalid-fields-${recipient.get('id')}`}
            id={`tipster-invalid-fields-${recipient.get('id')}`}
            className="tipster-recipient"
          >
            – <strong>{recipient.get('nameLegal')}</strong>
          </div>
        ))}
      </div>
    );
  };

  renderRecipent = (recipient) => {
    const { recipients } = this.props;
    const failedBlocking = recipient.get('failedBlocking');
    const nameLegal = recipients.getIn([recipient.get('id'), 'nameLegal']);
    const reportMessage = i18n.t(
      'frontproductstream.product_page.unsync_warning.error_message_for_invalid_fields',
      {
        count: failedBlocking,
        defaultValue: '{{count}} invalid fields',
      }
    );
    return (
      <div
        key={`tipster-invalid-fields-${recipient.get('id')}`}
        id={`tipster-invalid-fields-${recipient.get('id')}`}
        className="tipster-recipient"
      >
        – <strong>{nameLegal}</strong> – {reportMessage}
      </div>
    );
  };

  render() {
    if (this.props.isRetailer) {
      return null;
    }

    const exportSyncError = this.props.hasFeatureSynchronizationStatus
      ? this.getExportSyncError()
      : List();
    const unsyncedRecipients = this.getUnsynchedRecipients();
    if (unsyncedRecipients.size === 0 && exportSyncError.size === 0) {
      return null;
    }
    const tipsterNotSync =
      unsyncedRecipients.size === 0 ? null : (
        <div>
          <div key="tipster-title">
            <strong>
              {' '}
              {i18n.t(
                'frontproductstream.product_page.unsync_warning.error_message',
                {
                  defaultValue:
                    'Your product is not synchronised any more with:',
                }
              )}{' '}
            </strong>
          </div>
          {unsyncedRecipients.map(this.renderRecipent)}
        </div>
      );

    return (
      <Tipster type="warning">
        {tipsterNotSync}
        {tipsterNotSync && exportSyncError.size ? <br /> : null}
        {exportSyncError.map(this.renderExportSyncError)}
      </Tipster>
    );
  }
}

const enhance = compose(
  withUserType,
  connect(mapStateToProps, mapDispatchToProps, separateActions)
);

export default enhance(UnsynchedWarning);
