import { List, Map, Set } from 'immutable';
import { get, negate as not } from 'lodash/fp';
import moment from 'moment';

import {
  CONSUMER_UNIT,
  PRODUCT_DOCUMENT,
  PRODUCT_PICTURE,
  PRODUCT_VIDEO,
} from 'constants/fields';
import * as sharedStatus from 'constants/sharedStatus';

import { isRetailer } from '../../core/api/user';
import { UserImmutable } from '../../types';

import {
  isAsset,
  isEmptyField,
  isHiddenConditionalField,
  isUnlistedDisputedField,
} from './filters/physical-checker';
import { isListedIn, isNotListedIn } from './filters/view-as';

const isValidDisplayGroup = (node) =>
  !['DisplayGroup', 'Textile'].includes(node.kind) ||
  get('items.length', node) > 0;

const applyFilters =
  (filters: any[] = [], opt = {}) =>
  (node) =>
    filters.every((filter) => filter(node, opt));

export const isPrimaryRecipient = (
  recipient,
  activeRangeRecipientIds,
  listingRecipientIds,
  shares
) => {
  const recipientId = recipient.get('id');
  const isPrimary =
    activeRangeRecipientIds.includes(recipientId) ||
    listingRecipientIds.includes(recipientId);

  if (!isPrimary && shares?.size > 0) {
    if (
      shares.some(
        (s) =>
          s.getIn(['targetOrganization', 'id']) === recipientId &&
          [
            sharedStatus.draft.id,
            sharedStatus.pending.id,
            sharedStatus.accepted.id,
          ].includes(s.getIn(['status', 'id']))
      )
    ) {
      return recipient.getIn(['settings', 'allow_push']) === true;
    }
  }

  return isPrimary;
};

export const viewAsFilter = ({ fields = [] }: { fields?: any[] } = {}) =>
  applyFilters([
    // list view as filters here
    isListedIn(fields),
  ]);

export const viewAsByTypeFilter =
  (fieldsByType = Map(), defaultType = CONSUMER_UNIT) =>
  (node, { parent = {} }: { parent?: any } = {}) => {
    let fieldType;
    switch (parent.controller) {
      case 'picture':
        fieldType = PRODUCT_PICTURE;
        break;
      case 'document':
        fieldType = PRODUCT_DOCUMENT;
        break;
      case 'video':
        fieldType = PRODUCT_VIDEO;
        break;
      default:
        fieldType = defaultType;
    }
    return isListedIn(fieldsByType.get(fieldType) || Set())(node);
  };

export const excludeFilter = ({ fields = [] }: { fields?: any[] } = {}) =>
  applyFilters([isNotListedIn(fields)]);

export const physicalCheckerFilter = ({
  productVersion,
  disputedFields,
}: any = {}) =>
  applyFilters([
    // list filters for physical checkers here
    not(isAsset),
    not(isEmptyField(productVersion)),
    not(isHiddenConditionalField(productVersion)),
    isUnlistedDisputedField(disputedFields),
  ]);

const filterByVisibility = (filters) => (node) => {
  if ('items' in node) {
    const items = node.items
      .filter(applyFilters(filters, { parent: node }))
      .map(filterByVisibility(filters))
      .filter(isValidDisplayGroup);
    return { ...node, items };
  } else {
    return node;
  }
};

export const filterDisplayGroups = (
  filters: any[] = [],
  displayGroups: any[] = []
) =>
  displayGroups
    // filter each display group
    .map(filterByVisibility(filters))
    // remove display groups without items
    .filter(isValidDisplayGroup);

export const getUpcomingDeadline = (
  user: UserImmutable,
  ruleSet: Map<string, any>,
  recipients: List<any> = List()
) => {
  if (isRetailer(user)) {
    const organizationId = user.getIn(['belongsTo', 0, 'id']);
    return ruleSet.getIn([
      'applicability_deadline_by_organization',
      `${organizationId}`,
    ]);
  } else {
    return ruleSet
      .get('applicability_deadline_by_organization')
      .filter(
        (_, organizationId) =>
          !recipients.size ||
          recipients.find(
            (recipient) => +organizationId === +recipient.get('id')
          )
      )
      .valueSeq()
      .max((deadline1, deadline2) => moment(deadline2).diff(moment(deadline1)));
  }
};
