import classnames from 'classnames';
import { ReactElement, memo } from 'react';

import './style.scss';

interface Props {
  src?: string;
  customIcon?: ReactElement;
  title: string;
  active?: boolean;
  onClick?(): void;
  variant?: 'primary' | 'secondary';
  tabIndex?: number;
  disabled?: boolean;
  tooltip?: ReactElement;
  isRowDisplay?: boolean;
}

export default memo(function IconWithTextButton(
  props: Props & Partial<HTMLSpanElement>
) {
  const { variant = 'primary', tabIndex = 0 } = props;
  return (
    <span
      className={classnames(
        'IconWithTextButton',
        `IconWithTextButton--variant-${variant}`,
        {
          'IconWithTextButton--active': props.active,
          'IconWithTextButton--disabled': props.disabled,
        }
      )}
      onClick={props.onClick}
      role="button"
      aria-pressed={props.active}
      tabIndex={tabIndex}
      id={props.id}
    >
      <span
        className={classnames('IconWithTextButton__Icon--container', {
          'IconWithTextButton__Icon__container-align-icons': props.isRowDisplay,
        })}
        data-for={props['data-for']}
        data-tip={props['data-tip']}
      >
        {props.customIcon || (
          <span className="IconWithTextButton__Icon">
            <img src={props.src} alt={`${props.title} icon`} />
          </span>
        )}
      </span>
      <span
        className={`IconWithTextButton__Text${
          props.tooltip ? '--withTooltip' : null
        }`}
      >
        <span className="IconWithTextButton__Title">{props.title}</span>
        <span className="IconWithTextButton__Tooltip InputLabel__help">
          {props.tooltip}
        </span>
      </span>
    </span>
  );
});
