import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ListFilters from 'core/modules/list/components/filters';
import { FILTERS_PANEL } from 'core/modules/list/constants';
import { separateActions } from 'utils/redux';

import { collapseFilters } from '../../actions';
import {
  selectAreFiltersCollapsed,
  selectSelectedFilterList,
} from '../../selectors';

import ExpandedFilters from './expanded';

const mapStateToProps = (state) => ({
  collapsed: selectAreFiltersCollapsed(state),
  selectedFilters: selectSelectedFilterList(state).size,
});

const mapDispatchToProps = {
  collapseFilters,
};

export class ReportingFilters extends PureComponent {
  static propTypes = {
    collapsed: PropTypes.bool,
    selectedFilters: PropTypes.number,
    actions: PropTypes.shape({
      collapseFilters: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    collapsed: true,
  };

  onCollapse = () => {
    const { collapsed, actions } = this.props;
    actions.collapseFilters({
      key: FILTERS_PANEL,
      collapsed: !collapsed,
    });
    // TODO tracking
  };

  render() {
    const { collapsed, selectedFilters } = this.props;
    return (
      <ListFilters
        collapsed={collapsed}
        selectedFilters={selectedFilters}
        onCollapse={this.onCollapse}
      >
        <ExpandedFilters />
      </ListFilters>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ReportingFilters);
