import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects';

import { bulkSeedData, setReadyForValidation } from 'actions/productversion';
import { seedData } from 'components/ui/form/field/utils/seed';
import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import {
  FEATURE_FND_MULTILEVEL_FIELDS,
  FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT,
  RELEASE_CASE_AS_BASE_UNIT,
  RELEASE_PACK_AS_BASE_UNIT,
} from 'modules/feature-flag/constants';
import {
  selectHasJsonStoreInputFeature,
  selectHasTextileRelease,
} from 'modules/feature-flag/selectors';
import {
  selectCanUpdateProduct,
  selectContentOwnerId,
  selectCurrentLanguage,
  selectEditedProductVersion,
  selectIsBaseUnit,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectIsHeterogeneousLogisticalUnit,
  selectIsMadeOf,
  selectIsOriginalProductHeterogeneousUnit,
  selectKindId,
  selectTargetMarketId,
  selectTypePackagingId,
} from 'reducers/productVersion';
import {
  selectFlags,
  selectIsRetailer,
  selectIsThirdParty,
  selectOrganizationId,
} from 'reducers/user/selectors';
import displayGroupApi from 'resources/displayGroupApi';
import { size } from 'utils/immutable';
import qs from 'utils/query';
import { withCatch } from 'utils/saga';

import {
  FETCH_DISPLAY_GROUPS,
  FORCE_EDITABLE_DISPLAY_GROUPS,
  RECEIVE_DISPLAY_GROUPS,
  RESET_EDITABLE_DISPLAY_GROUPS,
  RESET_SELECTED_HETEROGENEOUS_UNIT,
} from '../constants';
import {
  selectAreDisplayGroupsEditable,
  selectShouldFetchHeterogeneousUnitFields,
} from '../selectors';

export const fetchDisplayGroups = async (queries) =>
  displayGroupApi.get(`/core/v3/displaygroups${qs.stringify(queries, true)}`);

export function* fetchDisplayGroupsSaga() {
  let started = false;
  try {
    const isThirdParty = yield select(selectIsThirdParty);
    const organizationId = yield select(selectOrganizationId);
    const contentOwnerId = yield select(selectContentOwnerId);
    const canUpdateProduct = yield select(selectCanUpdateProduct);
    const isConsumerUnit = yield select(selectIsConsumerUnit);
    const isBaseUnit = yield select(selectIsBaseUnit);
    const isMadeOf = yield select(selectIsMadeOf);
    const isDisplayUnit = yield select(selectIsDisplayUnit);
    const isRetailer = yield select(selectIsRetailer);
    const kindId = yield select(selectKindId);
    const targetMarketId = yield select(selectTargetMarketId);
    const typePackagingId = yield select(selectTypePackagingId);
    const hasTextileRelease = yield select(selectHasTextileRelease);
    const areDisplayGroupsEditable = yield select(
      selectAreDisplayGroupsEditable
    );
    const isHeterogeneousLogisticalUnit = yield select(
      selectIsHeterogeneousLogisticalUnit
    );
    const isOriginalProductHeterogeneousLogisticalUnit = yield select(
      selectIsOriginalProductHeterogeneousUnit
    );
    const flags = yield select(selectFlags);
    const shouldFetchHeterogeneousUnitFields = yield select(
      selectShouldFetchHeterogeneousUnitFields
    );
    const hasJsonStoreInput = yield select(selectHasJsonStoreInputFeature);

    const queries = {
      is_consumer_unit: isConsumerUnit,
      is_display_unit: isDisplayUnit,
      kind_id: kindId,
      type_packaging_id: typePackagingId,
      targetmarket_ids: [targetMarketId],
      override_display_groups: hasTextileRelease,
      organization_id: isThirdParty ? organizationId : contentOwnerId,
      is_read_only: areDisplayGroupsEditable ? false : !canUpdateProduct,
      is_retailer: isRetailer,
      is_heterogeneous_unit:
        flags[FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT] &&
        (isHeterogeneousLogisticalUnit ||
          isOriginalProductHeterogeneousLogisticalUnit ||
          shouldFetchHeterogeneousUnitFields),
      is_base_unit:
        (flags[RELEASE_CASE_AS_BASE_UNIT] ||
          flags[RELEASE_PACK_AS_BASE_UNIT]) &&
        isBaseUnit &&
        size(isMadeOf) === 0,
      use_json_store_input: hasJsonStoreInput,
    };

    yield put({ type: START_LOADING });
    started = true;
    const response = yield call(fetchDisplayGroups, queries);
    yield put({ type: STOP_LOADING });
    started = false;

    // Change this to test the page as readOnly.
    // const displayGroups = response.data.map(getAsReadOnly);
    const displayGroups = response.data;

    const multiLevel = flags[FEATURE_FND_MULTILEVEL_FIELDS];

    const versionData = yield select(selectEditedProductVersion);
    const currentLanguage = yield select(selectCurrentLanguage);
    const updates = seedData(
      versionData,
      displayGroups,
      currentLanguage,
      [],
      multiLevel
    );
    yield put(bulkSeedData(updates));
    yield put(setReadyForValidation());
    yield put({ type: RECEIVE_DISPLAY_GROUPS, list: displayGroups });

    if (shouldFetchHeterogeneousUnitFields) {
      yield put({ type: RESET_SELECTED_HETEROGENEOUS_UNIT });
    }
  } finally {
    if ((yield cancelled()) && started) {
      yield put({ type: STOP_LOADING });
    }
  }
}

export default function* fetchSaga() {
  yield takeLatest(
    [
      FETCH_DISPLAY_GROUPS,
      FORCE_EDITABLE_DISPLAY_GROUPS,
      RESET_EDITABLE_DISPLAY_GROUPS,
    ],
    withCatch(fetchDisplayGroupsSaga)
  );
}
