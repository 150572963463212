import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { notificationError, notificationSuccess } from 'actions/notification';
import Modal from 'components/ui/modal';
import { isRetailer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import FeatureModal from 'modules/feature-flag/components/manufacturer-recipe/modal';
import { FEATURE_MANUFACTURER_RECIPE } from 'modules/feature-flag/constants';
import mediaApi from 'resources/mediaApi';
import { StringOrNumber } from 'types';
import { sanitize } from 'utils/DOMPurify';
import i18n from 'utils/i18n';
import moment from 'utils/moment';

import RecipeModal from '../actions/recipe/recipe-modal';

const mapStateToProps = (state) => ({
  user: state.user,
});

class RecipeAsset extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    asset: PropTypes.object,
    productKeyId: StringOrNumber,
    updateEntities: PropTypes.func,
    readOnly: PropTypes.bool,
    dispatch: PropTypes.func,
    deleting: PropTypes.bool,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    readOnly: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isFeatureModalOpened: false,
    };
    this.showFeatureModal = this.toggleFeatureModal.bind(this, true);
    this.hideFeatureModal = this.toggleFeatureModal.bind(this, false);
  }

  deleteEntity = () => {
    const { onDelete, asset, dispatch, updateEntities } = this.props;
    onDelete(true);
    return mediaApi.EnrichedContentDelete(asset.id).then(
      () => {
        updateEntities(asset._type);
        onDelete(false);
        dispatch(
          notificationSuccess(
            i18n.t(
              'frontproductstream.asset_list.enriched_content.delete_notification.success',
              { defaultValue: 'Enriched content deleted' }
            )
          )
        );
      },
      (error) => {
        onDelete(false);
        if (error.status === 403) {
          dispatch(
            notificationError(
              i18n.t(
                'frontproductstream.asset_list.enriched_content.delete_notification.permission_error',
                {
                  defaultValue:
                    'You do not have the permission to delete this enriched content',
                }
              )
            )
          );
        } else {
          dispatch(
            notificationError(
              i18n.t(
                'frontproductstream.asset_list.delete_notification.error',
                { defaultValue: 'An error occured:' }
              ) + error.message
            )
          );
        }
      }
    );
  };

  showModal = () => {
    this.setState({ isOpen: true });
  };

  hideModal = () => {
    this.setState({ isOpen: false });
  };

  onSuccess = () => {
    this.props.updateEntities('ProductEnrichedContent');
    this.hideModal();
  };

  toggleFeatureModal(isFeatureModalOpened = true) {
    this.setState({ isFeatureModalOpened });
  }

  renderModal() {
    const { asset, productKeyId } = this.props;
    return (
      <RecipeModal
        title={i18n.t(
          'frontproductstream.asset_list.enriched_content_modal.title',
          { defaultValue: 'Edit enriched content' }
        )}
        media={asset}
        productKeyId={productKeyId}
        onSuccess={this.onSuccess}
        onClose={this.hideModal}
      />
    );
  }

  render() {
    const { deleting, user, asset, readOnly } = this.props;
    const { isOpen } = this.state;

    const content = {
      __html: sanitize(asset.content),
    };
    const hasFeatureRecipe =
      isRetailer(user) || hasFeature(user, FEATURE_MANUFACTURER_RECIPE);
    const { isFeatureModalOpened } = this.state;
    const buttons = readOnly ? (
      <div>
        <p id={`view-recipe-${asset.id}`}>
          <Button
            primary
            content={i18n.t('frontproductstream.asset_list.view_button.label', {
              defaultValue: 'View',
            })}
            onClick={this.showModal}
          />
          {isOpen && (
            <Modal
              title={asset.title}
              modalStyle="fullHeight"
              resizePics // TODO remove that prop
              onClose={this.hideModal}
              hideFooter
            >
              <div dangerouslySetInnerHTML={content} />
            </Modal>
          )}
        </p>
      </div>
    ) : (
      <div>
        {hasFeatureRecipe ? (
          <p id={`edit-recipe-${asset.id}`}>
            <Button
              primary
              content={i18n.t(
                'frontproductstream.asset_list.edit_button.label',
                { defaultValue: 'Edit' }
              )}
              onClick={this.showModal}
              disabled={deleting}
            />
            {isOpen && this.renderModal()}
          </p>
        ) : (
          <p id={`edit-recipe-${asset.id}`} />
        )}
        <p id={`delete-recipe-${asset.id}`}>
          <Button
            secondary
            content={i18n.t(
              'frontproductstream.asset_list.delete_button.label',
              { defaultValue: 'Delete' }
            )}
            onClick={this.deleteEntity}
            disabled={deleting}
            displaySpinner={deleting}
          />
        </p>
      </div>
    );
    const recipeIconClass = classNames('Asset__recipeIcon', {
      Asset__recipeIcon__upgradeRequired: !hasFeatureRecipe,
    });

    return (
      <div>
        <div className={recipeIconClass}>
          <span className="mdi mdi-food-variant" />
          {!hasFeatureRecipe && (
            <span>
              <div className="Asset__noRecipe" onClick={this.showFeatureModal}>
                <span className="mdi mdi-star-outline" />
                <span>
                  {' '}
                  {i18n.t(
                    'frontproductstream.asset_list.feature_required.text',
                    { defaultValue: 'Upgrade now to reactivate' }
                  )}{' '}
                </span>
              </div>
              {isFeatureModalOpened && (
                <FeatureModal onClose={this.hideFeatureModal} />
              )}
            </span>
          )}
        </div>
        <div className="Asset__recipeBody">
          <h3>{asset.title}</h3>
          <p className="Asset__dateAdd">
            {i18n.t('frontproductstream.asset_list.creation_date.text', {
              defaultValue: 'Added on',
            })}{' '}
            {moment(asset.createdAt).format('L')}
          </p>
          <div className="Asset__content" dangerouslySetInnerHTML={content} />
          {buttons}
        </div>
      </div>
    );
  }
}

export { RecipeAsset };
export default connect(mapStateToProps)(RecipeAsset);
