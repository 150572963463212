import classNames from 'classnames';
import { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';

import Modal from 'components/ui/modal';
import { ImportReportModal } from 'modules/import-modal';
import i18n from 'utils/i18n';

import '../TargetCatalogImportModal.scss';
import {
  closeTargetCatalogImportModal,
  restartTargetCatalogImportModal,
  selectFileInTargetCatalogImportModal,
  uploadTargetCatalog,
} from '../actions';
import { FILE_FORMAT_PRODUCT } from '../constants';
import {
  selectModalIsOpen,
  selectModalReportID,
  selectModalSelectedFile,
  selectModalSelectedFileFormat,
  selectUploadingFile,
} from '../selectors';

export const ImportProductDataModal = () => {
  const dispatch = useDispatch();

  const file: File | null = useSelector(selectModalSelectedFile);
  const fileFormat: string = useSelector(selectModalSelectedFileFormat);
  const modalIsOpen: boolean = useSelector(selectModalIsOpen);
  const uploadingFile: boolean = useSelector(selectUploadingFile);
  const reportID: number | null = useSelector(selectModalReportID);

  const onSelectFile = useCallback(
    (files) => {
      dispatch(selectFileInTargetCatalogImportModal(files[0]));
    },
    [dispatch]
  );

  const uploadFile = useCallback(() => {
    dispatch(uploadTargetCatalog(FILE_FORMAT_PRODUCT));
  }, [dispatch]);

  const onCloseModal = useCallback(() => {
    dispatch(closeTargetCatalogImportModal());
  }, [dispatch]);

  const onClickBackToFormatSelection = useCallback(() => {
    dispatch(restartTargetCatalogImportModal());
  }, [dispatch]);

  const fileSelected = () => !!file;

  const renderProductDropZone = () => {
    return (
      <div
        className="TargetCatalogImportModal__body"
        data-testid="ProductDropZoneID"
      >
        <div className="TargetCatalogImportModal__Readme--Step">
          <div className="TargetCatalogImportModal__Readme--Bullet">1</div>
          <span>
            {i18n.t('Fill in the template with your products informations')}
          </span>
        </div>
        <Dropzone multiple onDrop={onSelectFile}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps({
                className: classNames(
                  'TargetCatalogImportModal__dropzone',
                  isDragActive && 'TargetCatalogImportModal__dropzone--active'
                ),
              })}
            >
              {uploadingFile ? (
                <Spinner big />
              ) : (
                <>
                  <input {...getInputProps()} />
                  <div className="TargetCatalogImportModal__Readme--Step">
                    <div className="TargetCatalogImportModal__Readme--Bullet">
                      2
                    </div>
                    <b>{i18n.t('Drag and drop your XLSX file here')}</b>
                  </div>
                  <div className="Dropzone__label">
                    {i18n.t('Or select a file from your computer...')}
                  </div>
                </>
              )}
            </div>
          )}
        </Dropzone>
      </div>
    );
  };

  if (reportID) {
    return (
      <ImportReportModal
        onClose={onCloseModal}
        onCloseReportView={onClickBackToFormatSelection}
        reportId={reportID}
      />
    );
  }

  if (!modalIsOpen || fileFormat !== FILE_FORMAT_PRODUCT) {
    return null;
  }
  return (
    <Modal
      modalStyle="dynamic"
      title={i18n.t('Upload product file')}
      confirmButtonText={i18n.t('Upload')}
      confirmDisabled={!fileSelected()}
      onConfirm={uploadFile}
      isProcessing={uploadingFile}
      onClose={onCloseModal}
    >
      <div className="TargetCatalogImportModal__body">
        {!fileSelected() &&
          fileFormat === FILE_FORMAT_PRODUCT &&
          renderProductDropZone()}
        {fileSelected() && fileFormat === FILE_FORMAT_PRODUCT && (
          <div className="TargetCatalogImportModal_Upload">
            {i18n.t('The following file will be imported')}: {file?.name}
          </div>
        )}
      </div>
    </Modal>
  );
};
