import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { SUPPORT_EMAIL } from 'constants/emails';
import logo from 'images/logo-salsify-dark.png';
import { selectUser } from 'reducers/user/selectors';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { reload } from 'utils/location';
import { formatMailTo } from 'utils/mailto';

import './fallback.scss';

interface Props {
  error?: Error;
  origin: string;
  withLogo?: boolean;
}

export function ErrorFallback({ error, origin, withLogo }: Props) {
  const user: UserImmutable = useSelector(selectUser);
  const [showError, setShowError] = useState(false);
  return (
    <div className="ErrorFallback">
      {withLogo && (
        <img className="ErrorFallback__logo" src={logo} alt="Alkemics" />
      )}
      <h1>
        <i className="mdi mdi-alert-circle-outline ErrorFallback__icon" />
      </h1>
      <h1 className="ErrorFallback__title">
        {i18n.t('frontproductstream.core.error_fallback.title', {
          defaultValue: 'Something went wrong...',
        })}
      </h1>
      <p className="ErrorFallback__subTitle">
        {i18n.t('frontproductstream.core.error_fallback.reload_message', {
          defaultValue: 'Please, try reloading the page.',
        })}
      </p>
      <p className="ErrorFallback__reload">
        <Button onClick={reload} primary>
          {i18n.t('frontproductstream.core.error_fallback.reload_button', {
            defaultValue: 'Reload page',
          })}
        </Button>
      </p>
      <p className="ErrorFallback__again">
        {i18n.t(
          'frontproductstream.core.error_fallback.send_error_report_message',
          {
            defaultValue:
              'If the error persists, please send us an error report',
          }
        )}
      </p>
      <p className="alk-inline-flex alk-flex-center">
        <Button
          href={formatMailTo(
            i18n.t('frontproductstream.core.error_fallback.email_subject', {
              defaultValue: 'I had this error on the Alkemics platform',
            }),
            SUPPORT_EMAIL,
            [
              ['origin', origin || 'unknown'],
              ['username', get(user, ['username']) || 'unknown'],
              ['location', document.location.href],
              ['stack', error?.stack || JSON.stringify(error)],
            ]
          )}
          secondary
        >
          {i18n.t('frontproductstream.core.error_fallback.send_error_button', {
            defaultValue: 'Send error report',
          })}
        </Button>
        <span className="alk-margin-right--large alk-margin-left--large">
          {i18n.t('frontproductstream.core.error_fallback.or_separator', {
            defaultValue: 'or',
          })}
        </span>
        <Button href="/#/" link>
          {i18n.t('frontproductstream.core.error_fallback.home_page_link', {
            defaultValue: 'Go back home',
          })}
        </Button>
      </p>
      {error?.stack && (
        <>
          <p>
            <Button
              className="ErrorFallback__error"
              onClick={() => setShowError((show) => !show)}
              link
            >
              {showError ? (
                <>
                  {i18n.t(
                    'frontproductstream.core.error_fallback.hide_error_button',
                    {
                      defaultValue: 'Hide error',
                    }
                  )}{' '}
                  <i className="mdi mdi-chevron-up" />
                </>
              ) : (
                <>
                  {i18n.t(
                    'frontproductstream.core.error_fallback.show_error_button',
                    {
                      defaultValue: 'Show error',
                    }
                  )}{' '}
                  <i className="mdi mdi-chevron-down" />
                </>
              )}
            </Button>
          </p>
          {showError && (
            <div>
              <pre>{error.stack}</pre>
            </div>
          )}
        </>
      )}
    </div>
  );
}
