import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';

import './index.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

export const ListBody = forwardRef<HTMLDivElement, Props>(
  ({ children, className }: Props, ref) => (
    <div ref={ref} className={classNames('ListBody', className)}>
      {children}
    </div>
  )
);

export * from './table';
export default ListBody;
