// NOTE we can remove this once migrated away from hash router
import qs from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const hash = (path) => `#${path}`.replace(/#\/?#/, '#');

export const fill = (path, ...params) =>
  params.reduce((route, param) => route.replace(/:\w+/, param), path);

export const laxPath = (path: string): string =>
  path.length === 0 ? '*' : `${path}/*`;

export function useQueryParams<
  T extends {
    [K in keyof T]?: string | boolean | (string | boolean)[];
  } = object
>(): T {
  const { search } = useLocation();
  return useMemo(() => qs.parse(search), [search]);
}

export const relativePath = (path: string, rootPath: string) => {
  if (path === rootPath) return '';
  const parentPath = rootPath.endsWith('/') ? rootPath : `${rootPath}/`;
  return path.startsWith(parentPath) ? path.slice(parentPath.length) : path;
};

export const relativeLaxPath = (path: string, rootPath: string) =>
  laxPath(relativePath(path, rootPath));
