import { chunk, omit } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';
import { ServiceProductApi } from '@alkem/sdk-dashboard';

import Modal from 'components/ui/modal';
import { isRetailer } from 'core/api/user';
import {
  selectLocalesByTargetMarket,
  selectUser,
} from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { track } from 'utils/tracking';

import ListProductHierarchies from './listProductHierarchies';
import RecipientSelector from './recipientsSelector';
import ShareStatus from './shareStatus';

const mapStateToProps = createStructuredSelector({
  locales: selectLocalesByTargetMarket,
  user: selectUser,
});

const getInitialState = () => {
  return {
    isModalOpen: false,
    selectedRetailer: null,
    productsHierarchies: [],
    sharingUnits: {},
    totalSharedUnits: 0,
    cancelSharing: false,
    step: 'select-recipient',
  };
};

const BATCH_SIZE = 20;

class BulkShareLU extends Component {
  state = getInitialState();

  static propTypes = {
    productMap: PropTypes.object.isRequired,
    selectedMap: PropTypes.object.isRequired,
    locales: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) => !isRetailer(user);

  setisModalOpen = (modalState) => {
    this.setState({
      isModalOpen: modalState,
    });
  };

  onSelectRetailer = (selectedRetailer) => {
    this.setState({
      selectedRetailer,
    });
  };

  onConfirm = () => {
    if (this.state.step === 'select-recipient') {
      this.fetchProductsHierarchies();
      this.setState({
        productsHierarchies: [],
        step: 'list-product-hierarchies',
      });
      return;
    }

    if (this.state.step === 'list-product-hierarchies') {
      this.bulkCreateSharingUnit();
      this.setState({
        step: 'share-product-hierarchies',
      });
    }

    if (this.state.step === 'share-product-hierarchies') {
      this.onClose();
    }
  };

  onClickPreviousStep = () => {
    if (this.state.step === 'share-product-hierarchies') {
      this.setState({
        step: 'list-product-hierarchies',
      });
      return;
    }

    if (this.state.step === 'list-product-hierarchies') {
      this.setState({
        step: 'select-recipient',
      });
    }
  };

  trackSharing = ({ isCanceled }) => {
    const { user } = this.props;
    const { sharingUnits, totalSharedUnits, selectedRetailer } = this.state;
    const organisation = user.getIn(['belongsTo', 0]);
    track({
      category: 'product',
      action: 'bulk_share_logistical_hierarchies',
      recipient_retailer: selectedRetailer
        ? `${selectedRetailer.value} - ${selectedRetailer.label}`
        : '',
      source_manufacturer: {
        user: `${user.get('id')} ${user.get('firstname')} - ${user.get(
          'lastname'
        )}`,
        organisation: `${organisation.get('id')} - ${organisation.get(
          'nameLegal'
        )}`,
      },
      isCanceled: isCanceled,
      sharedProducts: Object.values(sharingUnits)
        .slice(0, totalSharedUnits)
        .map((su) => ({
          productGtin: su.productGtin,
          hierarchyGtin: su.hierarchyGtin,
        })),
    });
  };

  getProductKeyIds = () => {
    let { productMap, selectedMap } = this.props;
    return selectedMap.reduce((accumelator, _, productKey) => {
      const productKeyId = productMap
        .get(productKey)
        .get('product_key')
        .get('id');
      return [...accumelator, productKeyId];
    }, []);
  };

  getProductTargetMarketId = (productMap, productKey) => {
    const product = productMap.find((p) => {
      return p.getIn(['product_key', 'id']) === productKey;
    });

    return product.getIn(['tags', 'targetMarket', 'id']);
  };

  fetchProductsHierarchies = async () => {
    const serviceProduct = new ServiceProductApi();
    try {
      const {
        data: { data },
      } = await serviceProduct.getSharableHierarchies(
        this.getProductKeyIds(),
        this.state.selectedRetailer.value
      );
      this.setState({
        productsHierarchies: this.enrichWithTargetMarketId(data),
        sharingUnits: this.buildSharingUnits(data),
      });
    } catch (e) {
      logError(e);
    }
  };

  enrichWithTargetMarketId = (products) => {
    const { productMap } = this.props;
    return products.map((product) => {
      return {
        ...product,
        tags: {
          targetMarket: {
            id: this.getProductTargetMarketId(
              productMap,
              product.product_key_id
            ),
          },
        },
      };
    });
  };

  buildSharingUnits = (products) => {
    const sharingUnits = {};
    let firstSharableHierarchyId = null;
    let firstSharableHierarchyGtin = null;
    for (let p of products) {
      if (p.hierarchies && p.hierarchies.length > 0) {
        [firstSharableHierarchyId = null, firstSharableHierarchyGtin = null] =
          this.getFirstSharableHierarchyId(p.hierarchies);
        if (firstSharableHierarchyId) {
          sharingUnits[p.product_key_id] = {
            productGtin: p.gtin,
            sourceProductKey: p.product_key_id,
            hierarchyProduct: firstSharableHierarchyId,
            hierarchyGtin: firstSharableHierarchyGtin,
          };
        }
      }
    }

    return sharingUnits;
  };

  getFirstSharableHierarchyId = (hierarchies) => {
    for (let h of hierarchies) {
      if (!h.is_shared) {
        return [h.product_id, h.gtin];
      }
    }

    return [];
  };

  getBulkCrateSharingPayload() {
    let r = [];
    const { sharingUnits, selectedRetailer } = this.state;

    for (let sharingUnit of Object.values(sharingUnits)) {
      if (sharingUnit.hierarchyProduct !== null) {
        let data = {
          source_product_key: {
            id: sharingUnit.sourceProductKey,
          },
          targetOrganization: {
            id: selectedRetailer.value,
          },
          data: {
            hierarchyProduct: {
              id: sharingUnit.hierarchyProduct,
            },
          },
        };
        r.push(data);
      }
    }

    return r;
  }

  bulkCreateSharingUnit = async () => {
    let serviceProduct = new ServiceProductApi();
    const { totalSharedUnits } = this.state;
    let sharingUnits = null;
    try {
      if (totalSharedUnits > 0) {
        // Continue sharing
        sharingUnits =
          this.getBulkCrateSharingPayload().slice(totalSharedUnits);
      } else {
        // Start sharing
        sharingUnits = this.getBulkCrateSharingPayload();
      }
      for (let batchSharingUnits of chunk(sharingUnits, BATCH_SIZE)) {
        if (this.state.cancelSharing) {
          // Stop sharing
          return;
        }
        await serviceProduct.bulkCreateSharingUnitWithTarget(batchSharingUnits);
        this.setState((state) => {
          return {
            totalSharedUnits: state.totalSharedUnits + batchSharingUnits.length,
          };
        });
      }
    } catch (e) {
      logError(e);
    }
  };

  onHierarchyChange = (productKeyId) => {
    return (productId) => {
      const sharingUnits = this.state.sharingUnits;
      const newsharingUnits = {
        ...sharingUnits,
        [productKeyId]: {
          ...sharingUnits[productKeyId],
          hierarchyProduct: productId,
        },
      };
      this.setState({ sharingUnits: newsharingUnits });
    };
  };

  onRemoveProduct = (productKeyId) => {
    // Remove only if there is more than one product
    const { productsHierarchies, sharingUnits } = this.state;
    if (productsHierarchies.length === 1) {
      return;
    }
    this.setState({
      productsHierarchies: productsHierarchies.filter(
        (ph) => ph.product_key_id !== productKeyId
      ),
      sharingUnits: omit(sharingUnits, [productKeyId]),
    });
  };

  onClose = () => {
    const { step, totalSharedUnits } = this.state;
    const totalSharingUnits = Object.keys(this.state.sharingUnits).length;
    const isSharingInProgress = totalSharedUnits !== totalSharingUnits;
    if (step === 'share-product-hierarchies' && isSharingInProgress) {
      this.setState({
        cancelSharing: true,
      });
      return;
    }

    this.trackSharing({ isCanceled: false });
    this.setState(getInitialState());
  };

  onConfirmCancelSharing = () => {
    this.trackSharing({ isCanceled: true });
    this.setState(getInitialState());
  };

  onCloseCancelSharing = () => {
    this.setState(
      {
        cancelSharing: false,
      },
      () => {
        // Continue sharing
        this.bulkCreateSharingUnit();
      }
    );
  };

  onClick = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setisModalOpen(true);
  };

  render() {
    return (
      <>
        <div className="ActionOption" onClick={this.onClick}>
          {i18n.t(
            'Create listings for a retailer from existing logistical hierarchies'
          )}
        </div>
        {this.state.isModalOpen && (
          <BulkShareModal
            onConfirm={this.onConfirm}
            onClose={this.onClose}
            onSelectRetailer={this.onSelectRetailer}
            step={this.state.step}
            onClickPreviousStep={this.onClickPreviousStep}
            selectedRetailer={this.state.selectedRetailer}
            productKeyIds={this.getProductKeyIds()}
            productsHierarchies={this.state.productsHierarchies}
            onHierarchyChange={this.onHierarchyChange}
            onRemoveProduct={this.onRemoveProduct}
            selectedProductsNumber={this.props.selectedMap.size}
            totalSharedUnits={this.state.totalSharedUnits}
            cancelSharing={this.state.cancelSharing}
            onConfirmCancelSharing={this.onConfirmCancelSharing}
            onCloseCancelSharing={this.onCloseCancelSharing}
            totalSharingUnits={Object.keys(this.state.sharingUnits).length}
            locales={this.props.locales}
          />
        )}
      </>
    );
  }
}

function BulkShareModal(props) {
  const { step, totalSharingUnits } = props;

  const additionalFooterContent = () => {
    if (step === 'list-product-hierarchies') {
      return (
        <Button secondary onClick={props.onClickPreviousStep}>
          {i18n.t('Previous')}
        </Button>
      );
    }
    return null;
  };

  const confirmButtonText = () => {
    if (step === 'select-recipient') {
      return i18n.t('Next');
    }
    if (step === 'share-product-hierarchies') {
      return i18n.t('Close');
    }
    return i18n.t('Submit');
  };

  const isConfirmDisabled = () => {
    const hasNoRetailerSelected = !props.selectedRetailer;
    const hasNoSharingUnits =
      step === 'list-product-hierarchies' && totalSharingUnits === 0;
    return hasNoRetailerSelected || hasNoSharingUnits;
  };

  return (
    <Modal
      title={i18n.t(
        'Bulk create listings with existing logistical hierarchies'
      )}
      modalStyle="fullHeight"
      confirmButtonText={confirmButtonText()}
      shouldCloseOnOverlayClick={false}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      hideCloseButton
      confirmDisabled={isConfirmDisabled()}
      additionalFooterContent={additionalFooterContent()}
      hideFooter={step === 'share-product-hierarchies'}
    >
      {step === 'select-recipient' && (
        <RecipientSelector
          onSelectRetailer={props.onSelectRetailer}
          selectedRetailer={props.selectedRetailer}
          selectedProductsNumber={props.selectedProductsNumber}
        />
      )}
      {step === 'list-product-hierarchies' && (
        <ListProductHierarchies
          productsHierarchies={props.productsHierarchies}
          onHierarchyChange={props.onHierarchyChange}
          onRemoveProduct={props.onRemoveProduct}
          locales={props.locales}
        ></ListProductHierarchies>
      )}
      {step === 'share-product-hierarchies' && (
        <ShareStatus
          totalSharedUnits={props.totalSharedUnits}
          totalSharingUnits={props.totalSharingUnits}
          recipient={props.selectedRetailer.label}
          cancelSharing={props.cancelSharing}
          onConfirmCancelSharing={props.onConfirmCancelSharing}
          onCloseCancelSharing={props.onCloseCancelSharing}
        />
      )}
    </Modal>
  );
}

BulkShareModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSelectRetailer: PropTypes.func.isRequired,
  onClickPreviousStep: PropTypes.func.isRequired,
  selectedRetailer: PropTypes.object.isRequired,
  productKeyIds: PropTypes.object.isRequired,
  step: PropTypes.string.isRequired,
  selectedProductsNumber: PropTypes.number.isRequired,
  productsHierarchies: PropTypes.object.isRequired,
  onHierarchyChange: PropTypes.func.isRequired,
  onRemoveProduct: PropTypes.func.isRequired,
  totalSharedUnits: PropTypes.number.isRequired,
  totalSharingUnits: PropTypes.number.isRequired,
  cancelSharing: PropTypes.bool.isRequired,
  onConfirmCancelSharing: PropTypes.func.isRequired,
  onCloseCancelSharing: PropTypes.func.isRequired,
  locales: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(BulkShareLU);
