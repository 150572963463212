import { isEAN } from 'utils/actions/list';
import formatMultipleSearch from 'utils/formatMultipleSearch';

export function buildSearch(query) {
  if (!query) {
    return {};
  }
  const cleanSearch = formatMultipleSearch(query);
  if (isEAN(cleanSearch)) {
    return { gtinsIn: cleanSearch };
  }
  return { queryName: query };
}
