import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import { i18n } from 'utils/i18n';

interface Props {
  isAmendingAll?: boolean;
  onAcceptAll: () => void;
  onRefuseAll: () => void;
  isDataOpsPatcher?: boolean;
  isDataOpsReceiver?: boolean;
  canPatch: boolean;
}

export function DataOpsDiffBulkActions({
  isAmendingAll,
  onAcceptAll,
  onRefuseAll,
  isDataOpsPatcher,
  canPatch,
}: Props) {
  const isDisabled = isAmendingAll || !canPatch;
  return (
    <div className="alk-flex alk-flex-center alk-flex-justify-right alk-padding-top-1">
      <div className="Spinner--small">{isAmendingAll && <Spinner small />}</div>
      <Button
        testid="refuse-all-patch"
        className="alk-margin-left-1"
        onClick={() => {
          if (!isDisabled) {
            onRefuseAll();
          }
        }}
        disabled={isDisabled}
        link
      >
        {isDataOpsPatcher
          ? i18n.t(
              'frontproductstream.product_collaborative_edit.diff_block_retailer_reject_all_manufacturer_values.button',
              { defaultValue: 'Refuse all new values' }
            )
          : i18n.t(
              'frontproductstream.product_collaborative_edit.diff_block_manufacturer_reject_all_retailer_patches.button',
              { defaultValue: 'Refuse all patches' }
            )}
      </Button>
      <Button
        testid="accept-all-patch"
        className="alk-margin-left-1"
        onClick={() => {
          if (!isDisabled) {
            onAcceptAll();
          }
        }}
        disabled={isDisabled}
        primary
      >
        {isDataOpsPatcher
          ? i18n.t(
              'frontproductstream.product_collaborative_edit.diff_block_retailer_accept_all_manufacturer_values.button',
              { defaultValue: 'Accept all new values' }
            )
          : i18n.t(
              'frontproductstream.product_collaborative_edit.diff_block_manufacturer_accept_all_retailer_patches.button',
              { defaultValue: 'Accept all patches' }
            )}
      </Button>
    </div>
  );
}
