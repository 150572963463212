import { List, Map } from 'immutable';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { RaguelStatic } from 'components/ui/form/plugins/validator/static';
import Tipster from 'components/ui/tipster';
import { ENTITY_TYPE_SHARINGUNIT } from 'constants/entities';
import { AnchoredSection, AnchoredSectionItem } from 'modules/anchored';
import { selectHasSpecificValues } from 'modules/feature-flag/selectors';
import { selectHasLogisticalUnitEdited } from 'modules/logistical-hierarchies/selectors';
import { saveProduct } from 'modules/product-page/actions';
import { selectHasSpecificFieldEdited } from 'modules/recipient-specific-block/selectors';
import { TemplateTypes } from 'modules/sharing-unit-tariffs';
import {
  createSharingUnit,
  deleteSharingUnit,
} from 'modules/sharing-units/actions';
import CreateSharingUnitButton from 'modules/sharing-units/components/create-sharing-unit-button';
import SharingUnit from 'modules/sharing-units/components/sharing-unit';
import SharingUnitTariffs from 'modules/sharing-units/components/tariffs';
import { selectHasEditedSharingUnit } from 'modules/sharing-units/selectors';
import { getSharingUnitTitle } from 'modules/sharing-units/utils/view';
import {
  restrictionTypes,
  selectValidationResultsByEntity,
} from 'modules/validation';
import {
  selectCanUpdateProduct,
  selectCanUpdateSharingUnit,
  selectCurrentLanguage,
  selectIsDirty,
  selectIsLogisticalUnit,
  selectPermissions,
  selectProductKeyId,
  selectShares,
} from 'reducers/productVersion';
import {
  selectIsSuperAdmin,
  selectManagesOrganization,
} from 'reducers/user/selectors';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { fill } from 'utils/routing';

import { deleteFieldOverride, overrideField } from '../../actions';
import SpecificFields from '../specific-fields';
import SpecificValues from '../specific-values';
import { ManufacturerRecipientValidation } from '../validation';

import './index.scss';

export const formatSectionTitle = (recipient) =>
  `${i18n.t('frontproductstream.recipient_specific_block.section_title.text', {
    defaultValue: 'Specific information',
  })} - ${recipient.get('label')}`;

const mapStateToProps = createStructuredSelector({
  sectionTitle: (state, ownProps) => formatSectionTitle(ownProps.recipient),
  currentLanguage: selectCurrentLanguage,
  resultsByEntity: selectValidationResultsByEntity,
  canUpdateProduct: selectCanUpdateProduct,
  permissions: selectPermissions,
  canUpdateSharingUnits: (state) =>
    selectIsSuperAdmin(state) || selectCanUpdateSharingUnit(state),
  doesManageOrganization: selectManagesOrganization,
  canSaveProduct: (state) =>
    selectCanUpdateProduct(state) || selectCanUpdateSharingUnit(state),
  productRequiresSaving: (state) =>
    selectHasLogisticalUnitEdited(state) ||
    selectIsDirty(state) ||
    selectHasEditedSharingUnit(state) ||
    selectHasSpecificFieldEdited(state),
  hasSpecificValues: selectHasSpecificValues,
  shares: selectShares,
  productKeyId: selectProductKeyId,
  isLogisticalUnit: selectIsLogisticalUnit,
});

const mapDispatchToProps = {
  onOverrideField: overrideField,
  onDeleteFieldOverride: deleteFieldOverride,
  onCreateSharingUnit: createSharingUnit,
  onDeleteSharingUnit: deleteSharingUnit,
  onSaveProduct: saveProduct,
};

export class RecipientSpecificSection extends PureComponent {
  static propTypes = {
    recipient: ImmutablePropTypes.map.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    currentLanguage: PropTypes.object,
    canUpdateProduct: PropTypes.bool.isRequired,
    doesManageOrganization: PropTypes.bool.isRequired,
    permissions: PropTypes.array,
    isPrimary: PropTypes.bool,
    isSelectedInViewAs: PropTypes.bool,
    productRequiresSaving: PropTypes.bool.isRequired,
    canSaveProduct: PropTypes.bool.isRequired,
    specificData: ImmutablePropTypes.map,
    hasSpecificValues: PropTypes.bool,
    isLogisticalUnit: PropTypes.bool,
    productKeyId: PropTypes.number,

    // Specific values and attributes.
    shares: ImmutablePropTypes.list.isRequired,
    canHaveSpecificData: PropTypes.bool,
    canHaveSpecificFields: PropTypes.bool,
    hasSpecificAttributes: PropTypes.bool,
    hasSpecificAssets: PropTypes.bool,
    specificFields: ImmutablePropTypes.list,
    overridableFields: PropTypes.array,
    overriddenFields: ImmutablePropTypes.list,
    onOverrideField: PropTypes.func.isRequired,
    onDeleteFieldOverride: PropTypes.func.isRequired,

    // Sharing units
    canUpdateSharingUnits: PropTypes.bool.isRequired,
    hasSharingUnits: PropTypes.bool,
    sharingUnits: ImmutablePropTypes.list,
    onCreateSharingUnit: PropTypes.func.isRequired,
    onDeleteSharingUnit: PropTypes.func.isRequired,
    resultsByEntity: ImmutablePropTypes.list,

    // Product page save
    onSaveProduct: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sharingUnits: List(),
  };

  onCreateSharingUnit = (recipient) => () => {
    this.props.onCreateSharingUnit({
      targetOrganizationId: recipient.get('id'),
    });
  };

  getSharingUnitsWithTemplate = memoize((sharingUnits, resultsByEntity) =>
    sharingUnits
      .map((s) =>
        (s.get('template') || Map())
          .set('sharingUnitId', s.get('id'))
          .set('targetOrganizationId', s.getIn(['targetOrganization', 'id']))
      )
      .filter((t) => t && t.get('type') === TemplateTypes.TARIFF.id)
      // Only display the same tariff once.
      .groupBy((t) => t.get('id'))
      .valueSeq()
      .toList()
      .map((ts) => {
        let first = ts.first();
        first = first.set('errorNumber', 0);
        first = first.set('hasBlockingErrors', false);
        for (const t of ts) {
          const errors = (
            resultsByEntity
              .filter(
                (r) =>
                  r.getIn(['entity', 'id']) === t.get('sharingUnitId') &&
                  r.getIn(['entity', '_type']) === ENTITY_TYPE_SHARINGUNIT
              )
              .first() || Map()
          )
            .get('rules', List())
            .filter((r) => r.get('status') === 1);

          const errorNumber = errors.toList().size;
          const hasBlockingErrors = errors
            .valueSeq()
            .some(
              (v) =>
                v.getIn([
                  'restrictionTypesByOrganizations',
                  `${t.get('targetOrganizationId')}`,
                ]) === restrictionTypes.BLOCKING
            );
          first = first.update('errorNumber', (e) => e + errorNumber);
          first = first.update(
            'hasBlockingErrors',
            (v) => v || hasBlockingErrors
          );
        }
        return first;
      })
  );

  getTariffRule = memoize((resultsByEntity, recipientId) =>
    resultsByEntity
      .map((e) => e.get('rules') || List())
      .map((e) => e.valueSeq())
      .flatten(1)
      .filter((r) =>
        r.get('paths').some((ps) => ps.some((p) => p.startsWith('__tariffs')))
      )
      .filter((r) =>
        r.hasIn(['restrictionTypesByOrganizations', `${recipientId}`])
      )
  );

  getSharingUnitRules = memoize((resultsByEntity, recipientId) =>
    resultsByEntity
      .map((e) => e.get('rules') || List())
      .map((e) => e.valueSeq())
      .flatten(1)
      .filter((r) =>
        r
          .get('paths', Map())
          .some((ps) => ps.some((p) => p === '__sharingUnits'))
      )
      .filter((r) =>
        r.hasIn(['restrictionTypesByOrganizations', `${recipientId}`])
      )
  );

  getEnrichedSpecificFields() {
    // Enrich specific fields with recipient ids
    const { specificFields, recipient } = this.props;
    if (specificFields == null) {
      return specificFields;
    }

    return specificFields.map((field) => {
      if (field.getIn(['inputKind', 'kind']) === 'supplierIdRBA') {
        return field.setIn(
          ['options', 'targetOrganizationId'],
          recipient.get('id')
        );
      }
      return field;
    });
  }

  renderSpecificAttributes(hasDeletedShare) {
    const {
      sectionTitle,
      specificData,
      recipient,
      currentLanguage,
      canHaveSpecificData,
      canUpdateProduct,
      permissions,
      hasSpecificAssets,
      overridableFields,
      overriddenFields,
      onOverrideField,
      onDeleteFieldOverride,
      hasSpecificValues,
      productKeyId,
    } = this.props;

    if (!canHaveSpecificData) {
      return null;
    }

    const specificFields = this.getEnrichedSpecificFields();

    return (
      <AnchoredSectionItem
        linked
        section={sectionTitle}
        blockName={`${i18n.t(
          'frontproductstream.recipient_specific_block.section_item.title',
          { defaultValue: 'Specific data' }
        )} - ${recipient.get('label')}`}
        position={0}
      >
        {hasDeletedShare && (
          <Tipster type="warning">
            {i18n.t(
              'frontproductstream.recipient_specific_block.section_item_tipster.text',
              {
                defaultValue:
                  'You don’t share anymore updates for this product with {{recipient}}.',
                recipient: recipient.get('label'),
              }
            )}{' '}
            <Link to={fill(routes.productDashboard, productKeyId)}>
              {i18n.t(
                'frontproductstream.recipient_specific_block.section_item_publication_page_link.text',
                { defaultValue: 'Go to publication page.' }
              )}
            </Link>
          </Tipster>
        )}
        {hasSpecificValues && (
          <SpecificValues
            recipientId={recipient.get('id')}
            currentLanguage={currentLanguage}
            data={specificData}
            permissions={permissions}
            readOnly={!canUpdateProduct}
            hasSpecificAssets={hasSpecificAssets}
            overridableFields={overridableFields}
            overriddenFields={overriddenFields}
            onOverrideField={onOverrideField}
            onDeleteFieldOverride={onDeleteFieldOverride}
          />
        )}
        <SpecificFields
          recipient={recipient}
          currentLanguage={currentLanguage}
          data={specificData}
          permissions={permissions}
          specificFields={specificFields}
        />
      </AnchoredSectionItem>
    );
  }

  renderSharingUnitErrors() {
    const { recipient, resultsByEntity } = this.props;
    const rules = this.getSharingUnitRules(
      resultsByEntity,
      recipient.get('id')
    );

    if (!recipient.get('listing') || !rules.size) {
      return null;
    }

    return (
      <div className="SharingUnits__errors FormField--raguelError">
        {rules
          .map((rule) => (
            <RaguelStatic
              key={get(rule, ['id'])}
              rule={rule}
              recipientId={recipient.get('id')}
            />
          ))
          .toArray()}
      </div>
    );
  }

  renderSharingUnits() {
    const {
      sectionTitle,
      recipient,
      hasSharingUnits,
      canUpdateSharingUnits,
      sharingUnits,
      onDeleteSharingUnit,
    } = this.props;

    if (!recipient.get('listing') && !hasSharingUnits) {
      return null;
    }

    const sharingUnitsNotTariff = sharingUnits.filter(
      (s) =>
        !s.getIn(['template']) ||
        s.getIn(['template', 'type']) !== TemplateTypes.TARIFF.id
    );
    return (
      <div
        className="SharingUnits"
        id={`sharing-units-for-${recipient.get('id')}`}
      >
        {sharingUnitsNotTariff.map((sharingUnit, idx) => (
          <AnchoredSectionItem
            linked
            key={sharingUnit.get('id')}
            section={sectionTitle}
            blockId={`${sharingUnit.get('id')}`}
            blockName={getSharingUnitTitle(sharingUnit)}
            position={idx + 10}
          >
            <SharingUnit
              readOnly={!canUpdateSharingUnits}
              sharingUnit={sharingUnit}
              retailer={recipient}
              isTarget={false}
              deleteSharingUnit={onDeleteSharingUnit}
            />
          </AnchoredSectionItem>
        ))}
        <ManufacturerRecipientValidation
          recipientId={recipient.get('id')}
          sharingUnits={sharingUnits}
        />
        {canUpdateSharingUnits && (
          <>
            <CreateSharingUnitButton
              key="CreateSharingUnitButton"
              create={this.onCreateSharingUnit}
              retailer={recipient}
            />
          </>
        )}
      </div>
    );
  }

  renderTariff() {
    const {
      recipient,
      sharingUnits,
      doesManageOrganization,
      canSaveProduct,
      productRequiresSaving,
      onSaveProduct,
      resultsByEntity,
      isLogisticalUnit,
    } = this.props;
    if (!recipient.getIn(['settings', 'tariff'])) {
      return null;
    }

    const sharingUnitsWithTemplate = this.getSharingUnitsWithTemplate(
      sharingUnits,
      resultsByEntity
    );
    const tariffRules = this.getTariffRule(
      resultsByEntity,
      recipient.get('id')
    );
    return (
      <SharingUnitTariffs
        tariffs={sharingUnitsWithTemplate}
        canUpdateTariff={doesManageOrganization}
        canSaveProduct={canSaveProduct}
        productRequiresSaving={productRequiresSaving}
        tariffRules={tariffRules}
        isLogisticalUnit={isLogisticalUnit}
        recipient={recipient}
        actions={{ saveProduct: onSaveProduct }}
      />
    );
  }

  render() {
    const {
      recipient,
      sectionTitle,
      hasSpecificAttributes,
      hasSpecificAssets,
      hasSharingUnits,
      isPrimary,
      isSelectedInViewAs,
      canHaveSpecificData,
      canHaveSpecificFields,
      shares,
      resultsByEntity,
    } = this.props;

    const sharingUnitRules = this.getSharingUnitRules(
      resultsByEntity,
      recipient.get('id')
    );

    if (
      !hasSpecificAttributes &&
      !hasSharingUnits &&
      !hasSpecificAssets &&
      !(isSelectedInViewAs && canHaveSpecificData) &&
      !(isPrimary && canHaveSpecificFields) &&
      !(isPrimary && sharingUnitRules && sharingUnitRules.size > 0)
    ) {
      return null;
    }

    const hasDeletedShare = shares.some(
      (s) =>
        s.getIn(['targetOrganization', 'id']) === recipient.get('id') &&
        s.getIn(['status', 'id']) === 0
    );

    const headerContent = {
      icon: 'lock',
      text:
        i18n.t(
          'frontproductstream.recipient_specific_block.header_title.text',
          {
            defaultValue: 'Only visible to ',
          }
        ) + recipient.get('label'),
    };

    return (
      <AnchoredSection
        collapsible
        section={sectionTitle}
        icon="store"
        order={recipient.get('id')}
        header={headerContent}
      >
        {this.renderSpecificAttributes(hasDeletedShare)}
        {this.renderSharingUnitErrors()}
        {this.renderSharingUnits()}
        {this.renderTariff()}
      </AnchoredSection>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipientSpecificSection);
