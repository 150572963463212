import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Text as ComponentText } from '@alkem/react-ui-inputs';

import InputValidityHint from 'components/ui/input/input-validity-hint';
import { ProductReferenceTypes } from 'constants/product-reference-types';
import { coreBaseApiNoCache } from 'resources/coreBaseApi';
import i18n from 'utils/i18n';
import { isValidGtin } from 'utils/validator/gtin';
import { checkErrorProductIdentifier } from 'utils/validator/product-identifier';

import './reference.scss';

class Reference extends PureComponent {
  static propTypes = {
    ...ComponentText.propTypes,
    enableGenerator: PropTypes.bool,
    enableValidator: PropTypes.bool,
    referenceType: PropTypes.object,
  };

  static defaultProps = {
    ...ComponentText.defaultProps,
    enableGenerator: false,
    enableValidator: false,
    referenceType: ProductReferenceTypes.GTIN,
  };

  getRandomGtin = async () => {
    const response = await coreBaseApiNoCache.get(
      '/core/v3/products/generate_gtin'
    );
    const value = response.data.data;
    this.props.onChange({ target: { value } });
  };

  hasValidGtin = () => {
    const { value } = this.props;
    if (!value) {
      return null;
    }
    return isValidGtin(value);
  };

  hasErrorProductIdentifier = () => {
    const { value } = this.props;
    if (!value) {
      return null;
    }
    return checkErrorProductIdentifier(value);
  };

  renderValidityHints() {
    let isValid;
    let message;
    switch (this.props.referenceType) {
      case ProductReferenceTypes.GTIN:
        isValid = this.hasValidGtin();
        message = isValid
          ? i18n.t('frontproductstream.input.gtin.valid_text', {
              defaultValue: 'This GTIN is valid',
            })
          : i18n.t('frontproductstream.input.gtin.invalid_text', {
              defaultValue: 'This GTIN is invalid',
            });
        break;
      case ProductReferenceTypes.IDENTIFIER:
        message = this.hasErrorProductIdentifier();
        isValid = message ? false : true;
    }
    return <InputValidityHint valid={isValid} message={message} />;
  }

  renderRandomGenerator() {
    return (
      <button
        type="button"
        className="alk-btn-phantom"
        onClick={this.getRandomGtin}
        data-testid="gtin-random-generator"
      >
        <i className="mdi mdi-auto-fix alk-clickable-icon" />
      </button>
    );
  }

  renderSideElements() {
    return (
      <div className="InputReference__SideElements">
        {this.props.enableValidator &&
          !this.props.disabled &&
          this.renderValidityHints()}
        {this.props.enableGenerator &&
          !this.props.disabled &&
          this.props.referenceType === ProductReferenceTypes.GTIN &&
          this.renderRandomGenerator()}
      </div>
    );
  }

  render() {
    const emptyValueString = `(${i18n.t(
      'frontproductstream.input.gtin.placeholder',
      { defaultValue: 'empty' }
    )})`;
    const {
      referenceType,
      enableValidator,
      enableGenerator,
      dispatch,
      ...props
    } = this.props;
    return (
      <div className="InputReference">
        <ComponentText emptyValueString={emptyValueString} {...props} />
        {this.renderSideElements()}
      </div>
    );
  }
}

export default Reference;
