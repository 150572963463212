import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { resetDisputeModal, sendReview } from 'modules/contribution/actions';
import {
  getDisputeModalOpen,
  getDisputeModalSaving,
} from 'modules/contribution/selectors';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { BaseCommentModal } from './base-comment-modal';

const mapStateToProps = createStructuredSelector({
  opened: getDisputeModalOpen,
  saving: getDisputeModalSaving,
});

const mapDispatchToProps = {
  submit: sendReview,
  reset: resetDisputeModal,
};

export class DisputeModal extends BaseCommentModal {
  static defaultProps = {
    opened: false,
    saving: false,
    submitText: i18n.t(
      'frontproductstream.physical_check_dispute_dialog.submit.button',
      { defaultValue: 'Submit Dispute' }
    ),
    title: i18n.t(
      'frontproductstream.physical_check_dispute_dialog.header.text',
      { defaultValue: 'Dispute physical check' }
    ),
    description: i18n.t(
      'frontproductstream.physical_check_dispute_dialog.message_box.description',
      { defaultValue: 'Reason for dispute' }
    ),
    placeholder: i18n.t(
      'frontproductstream.physical_check_dispute_dialog.message_box.placeholder',
      {
        defaultValue:
          'e.g. The suggestions are not correct - the package weight is 200 grams.',
      }
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(DisputeModal);
