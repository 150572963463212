import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { STATUS_BAR_DEFAULT } from 'components/ui/status-bar';
import {
  getIsEligibleForSourcing,
  getPictureURI,
  getProductGTINWithFallbackOnId,
  getProductVersionId,
  getProductVersionTargetProductStatus,
  getSourceProductStatus,
  getTargetMarketId,
} from 'core/api/productversion';
import {
  getLocalesByTargetMarket,
  isRetailer,
  isSuperAdmin,
} from 'core/api/user';
import ListTableCell from 'core/modules/list/components/table/cell';
import ListTableRow from 'core/modules/list/components/table/row';
import { sourceProductStatusFilter } from 'core/modules/list/constants/filters/source-product-status';
import { selectHighlightedRowIdentifier } from 'modules/catalog/product/selectors';

import {
  ARCHIVED_PRODUCTS,
  ASSIGNATION,
  PRODUCTS,
  PRODUCTS_TO_REVIEW,
  PRODUCTS_WITH_MENU,
  SOURCING,
} from '../../../../constants/context';
import { getId } from '../../../../selectors/referential';
import CatalogColumn from '../cell';
import Action from '../cell/action';
import ActionHpp from '../cell/action-hpp';
import AssignationActionColumn from '../cell/assignation-action';
import CatalogCheckboxColumn from '../cell/checkbox';
import CatalogPicture from '../cell/picture';
import ProductActionColumn from '../cell/product-action';
import SourcingActionsColumn from '../cell/sourcing-actions';
import SourcingPicture from '../cell/sourcing-picture';
import CatalogProductStatusColumn from '../cell/status-bar';

export class CatalogTableRow extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    productVersion: ImmutablePropTypes.map.isRequired,
    referentials: ImmutablePropTypes.list.isRequired,
    columnMap: ImmutablePropTypes.map.isRequired,
    selected: PropTypes.bool,
    context: PropTypes.shape({}).isRequired,
    onSelect: PropTypes.func.isRequired,
    highlightedProductVersionId: PropTypes.number,
  };

  static defaultProps = {
    context: PRODUCTS,
  };

  onSelect = (selected) => {
    this.props.onSelect(
      getProductVersionId(this.props.productVersion),
      selected
    );
  };

  getProductStatus() {
    const { user, productVersion } = this.props;
    if (isRetailer(user)) {
      return getProductVersionTargetProductStatus(productVersion);
    }
    const statusId = getSourceProductStatus(productVersion);
    const status =
      sourceProductStatusFilter.getSourceProductStatusById(statusId);
    return status.statusBarType || STATUS_BAR_DEFAULT;
  }

  getPictureSize() {
    return `${get(this.props.context, ['size', 'row', 'height'], 66) - 2}`;
  }

  render() {
    const {
      productVersion,
      selected,
      referentials,
      columnMap,
      user,
      context,
      highlightedProductVersionId,
    } = this.props;
    const productVersionId = getProductVersionId(productVersion);
    const reference = getProductGTINWithFallbackOnId(productVersion);
    const targetMarketId = getTargetMarketId(productVersion);
    const locales = getLocalesByTargetMarket(user).get(String(targetMarketId));
    const isProductContext = [
      PRODUCTS,
      PRODUCTS_WITH_MENU,
      ARCHIVED_PRODUCTS,
      PRODUCTS_TO_REVIEW,
    ].includes(context);
    const clicked = productVersionId === highlightedProductVersionId;
    return (
      <ListTableRow
        id={`catalog-row-${reference}`}
        className="CatalogTableRow"
        selected={selected || clicked}
        data-row-reference={reference}
      >
        {isProductContext && (
          <>
            <CatalogProductStatusColumn status={this.getProductStatus()} />
            {context !== PRODUCTS_TO_REVIEW && (
              <CatalogCheckboxColumn
                id={productVersionId}
                checked={selected}
                onChange={this.onSelect}
              />
            )}
            <ListTableCell className={CatalogPicture.className}>
              <Action productVersion={productVersion} user={user}>
                <CatalogPicture
                  uri={getPictureURI(productVersion)}
                  width={this.getPictureSize()}
                  height={this.getPictureSize()}
                />
              </Action>
            </ListTableCell>
          </>
        )}
        {context === SOURCING && (
          <>
            <CatalogCheckboxColumn
              id={productVersionId}
              checked={selected}
              disabled={!getIsEligibleForSourcing(productVersion)}
              onChange={this.onSelect}
            />
            <ListTableCell className={SourcingPicture.className}>
              <ActionHpp productVersion={productVersion} user={user}>
                <SourcingPicture
                  uri={getPictureURI(productVersion)}
                  width={this.getPictureSize()}
                  height={this.getPictureSize()}
                  isEligible={
                    getIsEligibleForSourcing(productVersion) ||
                    isSuperAdmin(user)
                  }
                />
              </ActionHpp>
            </ListTableCell>
          </>
        )}
        {referentials.map((referential) => {
          if (!columnMap.get(getId(referential))) {
            return null;
          }
          return (
            <CatalogColumn
              key={`catalog-table-cell-${productVersionId}-${getId(
                referential
              )}`}
              product={productVersion}
              referential={referential}
              locales={locales}
              user={user}
              context={context}
            />
          );
        })}
        <td />
        {isProductContext && (
          <ProductActionColumn productVersion={productVersion} user={user} />
        )}
        {context === ASSIGNATION && (
          <AssignationActionColumn assignation={productVersion} user={user} />
        )}
        {context === SOURCING && (
          <SourcingActionsColumn productVersion={productVersion} user={user} />
        )}
      </ListTableRow>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  highlightedProductVersionId: selectHighlightedRowIdentifier,
});

export default connect(mapStateToProps)(CatalogTableRow);
