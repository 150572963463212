import { List, Map, fromJS } from 'immutable';
import { flow, set, unset } from 'lodash/fp';
import { all, call, delay, put, race, select, take } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError, notificationSuccess } from 'actions/notification';
import {
  filters,
  recipientFilter,
  sharingStatusFilter,
  sourceProductStatusFilter,
  targetProductStatusFilter,
} from 'core/modules/list/constants';
import { physicalCheckerCatalogFilter } from 'core/modules/list/constants/filters/physical-checker-catalog';
import {
  addAllTypesFilter,
  addArchivedProductsFilter,
  addDuplicatedProductsFilter,
  addRefusedAssignationFilter,
  getFiltersCustomFieldMapping,
  hasTextileVariantsFilter,
} from 'core/modules/list/utils/filters';
import {
  buildAdvancedSearch,
  buildFiltersQuery,
} from 'core/modules/list/utils/search';
import { buildSearch } from 'modules/catalog/shared/utils/list';
import {
  FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT,
  FEATURE_PERMISSIONS_V3_PRODUCT,
} from 'modules/feature-flag/constants';
import {
  selectHasFeature,
  selectHasThirdPartyPhysicalCheckStatus,
} from 'modules/feature-flag/selectors';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import { PRODUCT_PERMISSION, SHOW_PERMISSION } from 'modules/permissions/const';
import { selectUser } from 'reducers/user/selectors';
import etlApi from 'resources/etlApi';
import {
  fetchAssignations,
  fetchProductVersions,
  fetchSourcing,
} from 'resources/searchApi';
import { calculateOffset, saveAs } from 'utils';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';
import { logError } from 'utils/logging';

import { UserImmutable } from '../../../../types';
import { exportProductsDone, receiveList, trackAction } from '../actions';
import { CANCEL_EXPORT_PRODUCTS, CANCEL_FETCH_LIST } from '../actions/types';
import { exportProducts } from '../api/etl';
import {
  ARCHIVED_PRODUCTS,
  ASSIGNATION,
  PRODUCTS,
  PRODUCTS_TO_REVIEW,
  PRODUCTS_WITH_MENU,
  SOURCING,
} from '../constants/context';
import { filterKeyMap } from '../constants/filters';
import { getFiltersByContext } from '../context';
import {
  selectCatalogContext,
  selectExportFormat,
  selectFiltersConfig,
  selectOnlyDuplicatedProducts,
  selectPagination,
  selectProductsToExport,
  selectReferentials,
  selectSearch,
  selectSelectedFilterList,
  selectSelectedFilterMap,
  selectSelectedMap,
  selectSorting,
  selectWithAllTypes,
  selectWithArchivedProducts,
  selectWithRefusedAssignations,
} from '../selectors';
import { getSort } from '../selectors/referential';

import fetchIsFirstTimeForUser from './first-time';

function extractFilenameFromFilepath(fl) {
  return fl.replace(/^.*[\\/]/, '');
}

function buildPagination(pagination) {
  return {
    offset: calculateOffset(pagination.get('page'), pagination.get('limit')),
    limit: pagination.get('limit'),
  };
}

export function buildSorting(sorting) {
  if (sorting) {
    const sort = toJsIfImmutable(getSort(sorting));
    return {
      sort: {
        advancedSortBy: sort,
        order: sorting.get('asc') ? 'asc' : 'desc',
      },
    };
  }
  return {};
}

function buildAggregations({ user, context = PRODUCTS, customFields }) {
  const filtersByContext = getFiltersByContext(context);
  return filters
    .filter(
      (filter) =>
        !filter.customQuery &&
        filtersByContext[filter.key] &&
        filter.isAvailable({ user })
    )
    .map((filter) => {
      let aggreg;
      const customField = customFields && customFields[filter.key];
      if (customField) {
        aggreg = { custom_field: customField };
      } else {
        const { missingFilter } = filter;

        aggreg = {
          key_field: filter.key,
          ...(missingFilter && { missing: missingFilter.key }),
        };
      }
      return Object.assign(aggreg, filter.aggregation);
    });
}

function buildSourceInclude({ referentials, customFields }) {
  let sourceInclude = referentials.map(
    (referential) =>
      referential.getIn(['data', 'sourceInclude']) ||
      referential.getIn(['data', 'path'])
  );
  const sourceProductStatusKey =
    customFields && customFields[sourceProductStatusFilter.key];
  if (sourceProductStatusKey) {
    sourceInclude = sourceInclude.push(sourceProductStatusKey);
  }
  return sourceInclude;
}

function buildSelectedFiltersFromQuery({
  filtersQueryMap,
  user,
}: {
  filtersQueryMap: { [filterKey: string]: any };
  user: UserImmutable;
}) {
  let selectedFilterTargetProductStatus =
    filtersQueryMap[targetProductStatusFilter.key];
  if (selectedFilterTargetProductStatus?.length) {
    selectedFilterTargetProductStatus =
      selectedFilterTargetProductStatus.reduce(
        (acc, status) => Object.assign(acc, { [status]: { id: status } }),
        {}
      );
  }
  const isFilterAvailable = (filter) => {
    const isAvailable = filter?.isAvailable
      ? filter.isAvailable({ user, selectedFilterTargetProductStatus })
      : true;
    if (isAvailable && filter?.key === sharingStatusFilter.key) {
      return recipientFilter.hasRecipient(
        fromJS({ [recipientFilter.key]: filtersQueryMap[recipientFilter.key] })
      );
    }
    return isAvailable;
  };
  return Object.entries(filtersQueryMap).reduce((acc, [key, values]) => {
    let list = acc;
    if (!isFilterAvailable(filterKeyMap[key])) {
      return list;
    }
    const type = filterKeyMap[key]?.getTypeByValue?.(values);
    // On some case, the values are not store in an array (iterable)
    // but in object instead, for example a range query like {"gte": "2021-01-01"}.
    // On that case, the following loop would cause an error so we check first if the
    // object has an iterator function
    if (typeof values[Symbol.iterator] === 'function') {
      for (const filterValue of values) {
        let value = filterValue;
        let not = false;
        if (typeof value === 'string' && value.indexOf('-') === 0) {
          value = value.substring(1);
          not = true;
        }
        list = list.push(
          fromJS({
            key,
            value,
            not,
            type,
          })
        );
      }
    } else {
      list = list.push(
        fromJS({
          key,
          value: values,
          type,
        })
      );
    }
    return list;
  }, List());
}

export function* prepareParameters({
  referentials,
  filtersQueryMap,
  searchQuery,
  filtersConfig,
}: {
  referentials: any;
  filtersQueryMap?: { [filterKey: string]: any };
  searchQuery?: string;
  filtersConfig?: any;
}) {
  const user = yield select(selectUser);
  const pagination = yield select(selectPagination);
  const sorting = yield select(selectSorting);
  const withArchivedProducts = yield select(selectWithArchivedProducts);
  const hasDuplicatedProducts = yield select(selectOnlyDuplicatedProducts);
  const withAllTypes = yield select(selectWithAllTypes);
  const withRefusedAssignations = yield select(selectWithRefusedAssignations);
  const context = yield select(selectCatalogContext);
  const selectedFiltersMap =
    toJsIfImmutable(yield select(selectSelectedFilterMap)) || {};
  const hasFiltersQueryMap = Object.keys(filtersQueryMap || {}).length > 0;
  const hasSearchQuery = !!searchQuery;
  const needFullAggregations = hasFiltersQueryMap || hasSearchQuery;
  const extraQueries: any = {};

  let search = '';
  let selectedFilters;

  if (filtersQueryMap && hasFiltersQueryMap) {
    selectedFilters = buildSelectedFiltersFromQuery({ filtersQueryMap, user });
  } else {
    selectedFilters = yield select(selectSelectedFilterList);
  }

  if (searchQuery) {
    search = searchQuery;
  } else {
    search = yield select(selectSearch);
  }

  // here we only want to map the new filters with custom query mapper
  const filtersWithCustomQuery = filters.filter(
    ({ customQuery }) => customQuery
  );
  const customQueries = filtersWithCustomQuery
    .filter(({ key }) => selectedFiltersMap[key])
    .map(({ key, customQuery }) => customQuery?.(selectedFiltersMap[key]))
    .reduce((acc, _query = {}) => ({ ...acc, ..._query }), {});

  // here we only want to keep the old filters without custom query mapper
  selectedFilters = selectedFilters.filter(
    (filter) =>
      !filtersWithCustomQuery.find(({ key: _k }) => _k === filter.get('key'))
  );

  selectedFilters = addArchivedProductsFilter({
    user,
    withArchivedProducts,
    selectedFilters,
    bypass: [ASSIGNATION, SOURCING].includes(context),
    force: context === ARCHIVED_PRODUCTS,
  });

  selectedFilters = addDuplicatedProductsFilter({
    user,
    hasDuplicatedProducts,
    selectedFilters,
    bypass: [ASSIGNATION, SOURCING].includes(context),
  });

  selectedFilters = addAllTypesFilter({
    user,
    withAllTypes,
    selectedFilters,
    bypass: [ASSIGNATION, SOURCING].includes(context),
  });

  selectedFilters = addRefusedAssignationFilter({
    user,
    withRefusedAssignations,
    selectedFilters,
    bypass: context !== ASSIGNATION,
  });

  if (hasTextileVariantsFilter(user)) {
    extraQueries.allowTextileVariants = true;
  }

  if ([PRODUCTS_TO_REVIEW].includes(context)) {
    extraQueries.isReviewPending = true;
  }

  if ([PRODUCTS, PRODUCTS_WITH_MENU, ARCHIVED_PRODUCTS].includes(context)) {
    if (physicalCheckerCatalogFilter.isAvailable({ user })) {
      selectedFilters = selectedFilters.push(
        Map({
          key: physicalCheckerCatalogFilter.key,
          exists: true,
        })
      );
    }

    const hasHeterogeneousLogisticalUnit = yield select(
      selectHasFeature(FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT)
    );

    if (selectHasThirdPartyPhysicalCheckStatus(user)) {
      extraQueries.allowNotConsumerUnits = true;
    }

    if (hasHeterogeneousLogisticalUnit) {
      extraQueries.allowHeterogeneousLogisticalUnits = true;
    }
  }

  const customFields = getFiltersCustomFieldMapping(user);
  const advancedSearch = buildAdvancedSearch(
    buildFiltersQuery(selectedFilters, { customFields, filtersConfig })
  );
  const sourceInclude = buildSourceInclude({ referentials, customFields });

  return {
    pagination,
    sorting,
    search,
    user,
    extraQueries,
    advancedSearch,
    sourceInclude,
    needFullAggregations,
    customFields,
    customQueries,
  };
}

export function* exportProductsTask() {
  try {
    const referentials = yield select(selectReferentials);
    const selectedMap = yield select(selectSelectedMap);
    const exportFormat = yield select(selectExportFormat);
    const pagination = yield select(selectPagination);
    const productsToExport = yield select(selectProductsToExport);
    // We need referentials in order to have proper sourceInclude in the query.
    if (referentials.isEmpty()) {
      return;
    }
    const { sorting, search, extraQueries, advancedSearch } = yield call(
      prepareParameters,
      { referentials }
    );
    let productCount = selectedMap.size;
    if (productCount) {
      const advancedFilterProductKey = {
        query: productsToExport,
        fields: ['product_key.id'],
        type: 'terms',
      };
      if (!advancedSearch.advancedSearch) {
        advancedSearch.advancedSearch = advancedFilterProductKey;
      } else if (!advancedSearch.advancedSearch.must) {
        advancedSearch.advancedSearch = {
          must: [advancedSearch.advancedSearch, advancedFilterProductKey],
        };
      } else {
        advancedSearch.advancedSearch.must.push(advancedFilterProductKey);
      }
    } else {
      productCount = pagination.get('total');
    }

    const queries = {
      ...buildSorting(sorting),
      ...buildSearch(search),
      ...extraQueries,
      ...advancedSearch,
      format: exportFormat.value,
      ...exportFormat.extraOptions,
    };

    queries.columns = referentials.toJS();
    yield put(
      trackAction({
        category: 'product',
        action: 'product_export_started',
        label: `gtins=${queries.gtinsIn || 'all'}`,
      })
    );
    const response = yield call(exportProducts, {
      queries,
      async: true,
    });
    const trackerID = response.data.tracker.id;
    yield call(waitExportGenerationAndDownload, trackerID);

    yield put(
      trackAction({
        category: 'product',
        action: 'product_exported',
        label: `gtins=${queries.gtinsIn || 'all'}`,
      })
    );
    const successMessage =
      productCount > 1
        ? i18n.t('{{count}} products have been successfully exported', {
            count: productCount,
          })
        : i18n.t('{{count}} product has been successfully exported', {
            count: productCount,
          });
    yield put(notificationSuccess(successMessage, { context: 'modal' }));
  } catch (err: any) {
    logError(err);
    yield put(
      notificationError(
        err.human_message
          ? err.human_message
          : i18n.t('An error occured while exporting products'),
        {
          context: 'modal',
        }
      )
    );
  } finally {
    yield put(exportProductsDone());
  }
}

export function* downloadExportedFile(efID, fileIndex, filename) {
  try {
    const response = yield call<any>(
      [etlApi, etlApi.ExportedFileDownload],
      efID,
      fileIndex
    );
    saveAs(response.data, filename);
  } catch (error: any) {
    if (error && error.data && error.data.message) {
      throw error.data.message;
    } else {
      throw i18n.t('An error occured while retrieving your file');
    }
  }
}

export function* waitExportGenerationAndDownload(trackerID) {
  while (true) {
    const response = yield call([etlApi, etlApi.ExportFile], {
      filter_ids_in: [trackerID],
    });
    const ef = response.data.data[0];

    if (ef) {
      const errorSteps = ef.steps.filter((step) => step.status === 'ERROR');

      if (errorSteps.length > 0) {
        const error = {
          human_message: errorSteps[0].human_message,
          message: errorSteps[0].message,
        };
        throw error;
      }

      const uploadStep = ef.steps.filter((step) => step.name === 'upload');

      if (uploadStep.length > 0) {
        yield all(
          uploadStep[0].data.remote_files.map((fl, i) =>
            call(
              downloadExportedFile,
              ef.id,
              i,
              extractFilenameFromFilepath(fl)
            )
          )
        );
        return;
      }
    }
    yield delay(1000);
  }
}

const replaceCustomFields = (
  aggregDict,
  customFields: { [key: string]: string }
) => {
  let newAggregDict = aggregDict;
  if (newAggregDict && customFields) {
    Object.entries(customFields).forEach(([key, customField]) => {
      if (key !== customField) {
        const aggreg = aggregDict[customField];
        newAggregDict = flow(
          set([key], aggreg),
          unset([customField])
        )(newAggregDict);
      }
    });
  }
  return newAggregDict;
};

export function* fetchListTask({
  filtersQueryMap,
  searchQuery,
}: {
  filtersQueryMap?: { [filterKey: string]: any };
  searchQuery?: string;
} = {}) {
  yield put(startLoading());
  try {
    const referentials = yield select(selectReferentials);
    const context = yield select(selectCatalogContext);
    const filtersConfig = yield select(selectFiltersConfig);
    // We need referentials in order to have proper sourceInclude in the query.
    if (referentials.isEmpty()) {
      return;
    }

    const user = yield select(selectUser);
    const canShowProducts = hasPermissionV3(
      user,
      FEATURE_PERMISSIONS_V3_PRODUCT
    )
      ? hasPermissionModule(user, PRODUCT_PERMISSION, [SHOW_PERMISSION])
      : true;
    if (!canShowProducts) {
      return;
    }

    const parameters = yield call(prepareParameters, {
      referentials,
      filtersQueryMap,
      searchQuery,
      filtersConfig,
    });
    const {
      pagination,
      sorting,
      search,
      extraQueries,
      advancedSearch,
      sourceInclude,
      needFullAggregations,
      customFields,
      customQueries,
    } = parameters;
    const aggregationsQuery = buildAggregations({
      user,
      context,
      customFields,
    });
    let fetchEntities:
      | typeof fetchProductVersions
      | typeof fetchAssignations
      | typeof fetchSourcing;
    switch (context) {
      case PRODUCTS:
      case PRODUCTS_WITH_MENU:
      case PRODUCTS_TO_REVIEW:
      case ARCHIVED_PRODUCTS:
        fetchEntities = fetchProductVersions;
        break;
      case ASSIGNATION:
        fetchEntities = fetchAssignations;
        break;
      case SOURCING:
        fetchEntities = fetchSourcing;
        break;
      default:
        throw new Error('Catalog fetchListTask: unknown context');
    }
    const listRequests = [
      call(fetchEntities, {
        ...buildPagination(pagination),
        ...buildSorting(sorting),
        queries: {
          ...buildSearch(search),
          ...extraQueries,
          ...advancedSearch,
          ...customQueries,
          aggregations: aggregationsQuery,
        },
        sourceInclude,
      }),
      call(fetchIsFirstTimeForUser),
    ];

    if (needFullAggregations) {
      listRequests.push(
        call(fetchEntities, {
          offset: 0,
          limit: 0,
          queries: {
            ...extraQueries,
            ...customQueries,
            aggregations: aggregationsQuery,
          },
          sourceInclude,
        })
      );
    }

    const [
      { list, total, aggregations, error },
      isFirstTime = false,
      { aggregations: fullAggregations = undefined } = {},
    ] = yield all(listRequests);

    if (list) {
      yield put(
        receiveList({
          list,
          total,
          aggregations: replaceCustomFields(aggregations, customFields),
          fullAggregations: replaceCustomFields(fullAggregations, customFields),
          isFirstTime,
        })
      );
    }
    if (error) {
      throw error;
    }
  } catch (err: any) {
    logError(err);
    yield put(
      notificationError(i18n.t('Products list is not available'), {
        error: err,
      })
    );
  } finally {
    yield put(stopLoading());
  }
}

export default function* fetchListSaga({ payload }: any = {}) {
  yield race({
    task: call(fetchListTask, payload),
    cancel: take(CANCEL_FETCH_LIST),
  });
}

export function* exportProductsSaga() {
  yield race({
    task: call(exportProductsTask),
    cancel: take(CANCEL_EXPORT_PRODUCTS),
  });
}
