import Immutable, { fromJS } from 'immutable';
import { cond, eq, identity, indexOf, sortBy, stubTrue } from 'lodash/fp';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  AssignationSourceOrganizationFilter,
  AssignationStatusFilter,
  BrandsFilter,
  DataQualityFilter,
  DietTypeFilter,
  DisplayUnitsFilter,
  DuplicatedProductsFilter,
  ExportableTagFilter,
  GpcFilter,
  HaveDoesntHaveFilter,
  InternalCategorizationFilter,
  LifecycleFilter,
  ManufacturersFilter,
  MaturityFilter,
  MessageFilter,
  OwnersFilter,
  PackshotFilter,
  PatchFilter,
  PhysicalCheckStatusFilter,
  ProductHierarchyFilter,
  RecipientFilter,
  RecipientsScenariosFilter,
  RejectionStatusFilter,
  RequestedProductsStatusFilter,
  RequiredFieldsCategoryFilter,
  ReviewActionStatusFilter,
  ReviewFirstShareFilter,
  SharingStatusFilter,
  SourceProductStatusFilter,
  SynchronizationStatusFilter,
  TargetMarketFilter,
  TargetProductStatusFilter,
  WorksWithSourcingFilter,
} from 'core/modules/list/components/filter';
import { ScheduledApplicationDate } from 'core/modules/list/components/filter/scheduled-application-date';
import SelectedFilters from 'core/modules/list/components/filters/selected';
import {
  assignationSourceOrganizationsFilter,
  assignationStatusFilter,
  brandsFilter,
  dataQualityFilter,
  dietTypeFilter,
  displayUnitsFilter,
  duplicatedProductsFilter,
  exportableTagFilter,
  gpcStatusFilter,
  groupsFilter,
  internalCategorizationFilter,
  isDisplayableForTargetsFilter,
  isLabeledByFilter,
  lifecycleFilter,
  manufacturerScenariosAgg,
  manufacturersFilter,
  maturityFilter,
  messageFilter,
  ownersFilter,
  packshotFilter,
  patchFilter,
  physicalCheckStatusFilter,
  productHierarchyFilter,
  recipientFilter,
  recipientsScenariosFilter,
  rejectionStatusFilter,
  requestedProductsFilter,
  requestedProductsStatusFilter,
  requiredFieldsCategoryFilter,
  reviewActionStatusFilter,
  reviewFirstShareFilter,
  scheduledApplicationDateFilter,
  sharingStatusFilter,
  sourceProductStatusFilter,
  sourcingLabelsManufacturerFilter,
  sourcingLabelsRetailerFilter,
  synchronizationStatusFilter,
  targetMarketFilter,
  targetProductStatusFilter,
  userLabelsFilter,
  worksWithSourcingFilter,
} from 'core/modules/list/constants';
import { Filter } from 'core/modules/list/constants/filters/types';
import { selectHasSharingStatusFilter } from 'modules/feature-flag/selectors';
import { selectUser } from 'reducers/user/selectors';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get, toJsIfImmutable } from 'utils/immutable';

import {
  collapseFilters,
  sortFilterByDocCount,
  updateFilteredFilters,
  updateFiltersPagination,
  updateOnlyDuplicatedProducts,
  updateSelectedFilters,
  updateSelectedFiltersFromQuery,
  updateWithAllTypes,
  updateWithArchivedProducts,
  updateWithRefusedAssignations,
} from '../../actions';
import { PRODUCTS } from '../../constants/context';
import { getFiltersByContext } from '../../context';
import {
  selectAggregations,
  selectFiltersConfig,
  selectIsFetchingReferentials,
  selectOnlyDuplicatedProducts,
  selectSelectedFilterList,
  selectSelectedFilterMap,
  selectWithAllTypes,
  selectWithArchivedProducts,
  selectWithRefusedAssignations,
} from '../../selectors';

interface FiltersProps {
  context?: { type: string };
  filtersQueryMap?: object;
}

interface SelectorsOutput {
  aggregations: Immutable.Map<string, any>;
  filtersConfig: Immutable.Map<string, any>;
  hasSharingStatusFilter: boolean;
  selectedFilterList: Immutable.List<any>;
  selectedFilterMap: Immutable.Map<string, any>;
  user: UserImmutable;
  withAllTypes?: boolean;
  withArchivedProducts?: boolean;
  onlyDuplicatedProducts?: boolean;
  withRefusedAssignations?: boolean;
  isFetchingReferentials?: boolean;
}

export const CatalogExpandedFilters: FC<FiltersProps> = ({
  filtersQueryMap = {},
  context = PRODUCTS,
}) => {
  const dispatch = useDispatch();
  const {
    aggregations,
    filtersConfig,
    hasSharingStatusFilter,
    onlyDuplicatedProducts,
    selectedFilterList,
    selectedFilterMap,
    user,
    withAllTypes,
    withArchivedProducts,
    withRefusedAssignations,
    isFetchingReferentials,
  }: SelectorsOutput = useSelector(
    createStructuredSelector<SelectorsOutput, SelectorsOutput>({
      aggregations: selectAggregations,
      filtersConfig: selectFiltersConfig,
      hasSharingStatusFilter: selectHasSharingStatusFilter,
      selectedFilterList: selectSelectedFilterList,
      selectedFilterMap: selectSelectedFilterMap,
      user: selectUser,
      onlyDuplicatedProducts: selectOnlyDuplicatedProducts,
      withAllTypes: selectWithAllTypes,
      withArchivedProducts: selectWithArchivedProducts,
      withRefusedAssignations: selectWithRefusedAssignations,
      isFetchingReferentials: selectIsFetchingReferentials,
    })
  );

  const order: Filter[] = useMemo(() => {
    if (hasSharingStatusFilter) {
      return [
        recipientFilter,
        sharingStatusFilter,
        scheduledApplicationDateFilter,
        dataQualityFilter,
        messageFilter,
      ];
    }
    return [];
  }, [hasSharingStatusFilter]);

  const updateFromQueryRef = useRef(true);

  const getFilterQueryValues = (key: string) =>
    updateFromQueryRef.current ? filtersQueryMap[key] : undefined;

  const onChangeFilter = useMemo(
    () =>
      (filter, fromQuery = false) => {
        if (fromQuery && updateFromQueryRef.current) {
          dispatch(updateSelectedFiltersFromQuery(fromJS(filter)));
        } else {
          dispatch(updateSelectedFilters(fromJS(filter)));
          updateFromQueryRef.current = false;
        }
      },
    [dispatch]
  );

  const onCollapseFilter = useMemo(
    () => (key, collapsed) => {
      dispatch(collapseFilters({ key, collapsed }));
    },
    [dispatch]
  );

  const onFilterFilters = useMemo(
    () => (key, query) => {
      dispatch(updateFilteredFilters({ key, query }));
    },
    [dispatch]
  );

  const onChangePage = useMemo(
    () => (key, page) => {
      dispatch(updateFiltersPagination({ key, page }));
    },
    [dispatch]
  );

  const onChangeWithArchived = useMemo(
    () => (withArchived) => {
      dispatch(updateWithArchivedProducts(withArchived));
    },
    [dispatch]
  );

  const onChangeOnlyDuplicated = useMemo(
    () => (duplicatedProducts) => {
      dispatch(updateOnlyDuplicatedProducts(duplicatedProducts));
    },
    [dispatch]
  );

  const onChangeWithAllTypes = useMemo(
    () => (value) => {
      dispatch(updateWithAllTypes(value));
    },
    [dispatch]
  );

  const onChangeWithRefusedAssignations = useMemo(
    () => (withRefused) => {
      dispatch(updateWithRefusedAssignations(withRefused));
    },
    [dispatch]
  );

  const getFiltersByContextMemoized = useMemo(() => getFiltersByContext, []);

  const hasFilter = useCallback(
    (filter) => {
      return (
        getFiltersByContextMemoized(context)[filter.key] &&
        filter.isAvailable({
          user,
          selectedFilterTargetProductStatus: selectedFilterMap.get(
            targetProductStatusFilter.key
          ),
          context,
        })
      );
    },
    [context, getFiltersByContextMemoized, selectedFilterMap, user]
  );

  const onSort = useCallback(
    (filterKey: string) => dispatch(sortFilterByDocCount(filterKey)),
    [dispatch]
  );

  const isCollapsed = (filterKey: string) =>
    filtersConfig.getIn([filterKey, 'collapsed']);

  const sortFilters = useCallback(
    (unsortedFilters) => {
      const last = (filter) => () =>
        indexOf(filter, unsortedFilters) + unsortedFilters.length;
      const noIndex = eq(-1);
      return sortBy(
        (filter) =>
          cond([
            [noIndex, last(filter)],
            [stubTrue, identity],
          ])(indexOf(filter, order)),
        unsortedFilters
      );
    },
    [order]
  );

  const dietFilterConfig = useMemo(
    () => toJsIfImmutable(filtersConfig.get(dietTypeFilter.key)),
    [filtersConfig]
  );

  const dietAggregations = useMemo(
    () => toJsIfImmutable(aggregations.get(dietTypeFilter.key)),
    [aggregations]
  );

  const renderFilter = {
    [recipientFilter.key]: () => (
      <RecipientFilter
        key={recipientFilter.key}
        filterQueryValues={getFilterQueryValues(recipientFilter.key)}
        onChange={onChangeFilter}
        selectedFilterMap={selectedFilterMap.get(recipientFilter.key)}
        aggregations={aggregations.get(recipientFilter.key)}
      />
    ),
    [sharingStatusFilter.key]: () => (
      <SharingStatusFilter
        key={sharingStatusFilter.key}
        aggregations={aggregations.get(sharingStatusFilter.key)}
        collapsed={isCollapsed(sharingStatusFilter.key)}
        filterQueryValues={getFilterQueryValues(sharingStatusFilter.key)}
        hasRecipient={recipientFilter.hasRecipient?.(selectedFilterMap)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        selectedFilterMap={selectedFilterMap.get(sharingStatusFilter.key)}
      />
    ),
    [targetProductStatusFilter.key]: () => (
      <TargetProductStatusFilter
        key={targetProductStatusFilter.key}
        selectedFilterMap={selectedFilterMap.get(targetProductStatusFilter.key)}
        aggregations={aggregations}
        user={user}
        collapsed={isCollapsed(targetProductStatusFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(targetProductStatusFilter.key)}
      />
    ),
    [patchFilter.key]: () => (
      <PatchFilter
        key={patchFilter.key}
        selectedFilterMap={selectedFilterMap.get(patchFilter.key)}
        aggregations={aggregations}
        collapsed={isCollapsed(patchFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(patchFilter.key)}
      />
    ),
    [sourceProductStatusFilter.key]: () => (
      <SourceProductStatusFilter
        key={sourceProductStatusFilter.key}
        selectedFilterMap={selectedFilterMap.get(sourceProductStatusFilter.key)}
        aggregations={aggregations}
        collapsed={isCollapsed(sourceProductStatusFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(sourceProductStatusFilter.key)}
      />
    ),
    [displayUnitsFilter.key]: () => (
      <DisplayUnitsFilter
        key={displayUnitsFilter.key}
        selectedFilterMap={selectedFilterMap}
        aggregations={aggregations}
        user={user}
        collapsed={isCollapsed(displayUnitsFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(displayUnitsFilter.key)}
        withAllTypes={withAllTypes}
        onChangeWithAllTypes={onChangeWithAllTypes}
      />
    ),
    [exportableTagFilter.key]: () => (
      <ExportableTagFilter
        key={exportableTagFilter.key}
        selectedFilterMap={selectedFilterMap.get(exportableTagFilter.key)}
        aggregations={aggregations}
        user={user}
        collapsed={isCollapsed(exportableTagFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(exportableTagFilter.key)}
      />
    ),
    [rejectionStatusFilter.key]: () => (
      <RejectionStatusFilter
        key={rejectionStatusFilter.key}
        selectedFilterMap={selectedFilterMap.get(rejectionStatusFilter.key)}
        aggregations={aggregations}
        collapsed={isCollapsed(rejectionStatusFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(rejectionStatusFilter.key)}
      />
    ),
    [lifecycleFilter.key]: () => (
      <LifecycleFilter
        key={lifecycleFilter.key}
        selectedFilterMap={selectedFilterMap.get(lifecycleFilter.key)}
        aggregations={aggregations}
        collapsed={isCollapsed(lifecycleFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(lifecycleFilter.key)}
        withArchived={withArchivedProducts}
        onChangeWithArchived={onChangeWithArchived}
      />
    ),
    [duplicatedProductsFilter.key]: () => (
      <DuplicatedProductsFilter
        key={duplicatedProductsFilter.key}
        selectedFilterMap={selectedFilterMap.get(duplicatedProductsFilter.key)}
        aggregations={aggregations}
        collapsed={isCollapsed(duplicatedProductsFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(duplicatedProductsFilter.key)}
        duplicatedProducts={onlyDuplicatedProducts}
        onChangeOnlyDuplicatedProducts={onChangeOnlyDuplicated}
      />
    ),
    [targetMarketFilter.key]: () => (
      <TargetMarketFilter
        key={targetMarketFilter.key}
        selectedFilterMap={selectedFilterMap.get(targetMarketFilter.key)}
        aggregations={aggregations.get(targetMarketFilter.key)}
        filterConfig={filtersConfig.get(targetMarketFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(targetMarketFilter.key)}
      />
    ),
    [requiredFieldsCategoryFilter.key]: () => (
      <RequiredFieldsCategoryFilter
        key={requiredFieldsCategoryFilter.key}
        selectedFilterMap={selectedFilterMap.get(
          requiredFieldsCategoryFilter.key
        )}
        aggregations={aggregations.get(requiredFieldsCategoryFilter.key)}
        filterConfig={filtersConfig.get(requiredFieldsCategoryFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(
          requiredFieldsCategoryFilter.key
        )}
      />
    ),
    [messageFilter.key]: () => (
      <MessageFilter
        key={messageFilter.key}
        filterKey={messageFilter.key}
        filterLabel={i18n.t('Product Comment')}
        selectedFilterMap={selectedFilterMap.get(messageFilter.key)}
        aggregations={aggregations}
        collapsed={isCollapsed(messageFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(messageFilter.key)}
      />
    ),
    [synchronizationStatusFilter.key]: () => (
      <SynchronizationStatusFilter
        key={synchronizationStatusFilter.key}
        filterKey={synchronizationStatusFilter.key}
        filterLabel={i18n.t('Synchronization status')}
        selectedFilterMap={selectedFilterMap}
        aggregations={aggregations}
        collapsed={isCollapsed(synchronizationStatusFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(
          synchronizationStatusFilter.key
        )}
      />
    ),
    [physicalCheckStatusFilter.key]: () => (
      <PhysicalCheckStatusFilter
        key={physicalCheckStatusFilter.key}
        selectedFilterMap={selectedFilterMap.get(physicalCheckStatusFilter.key)}
        aggregations={aggregations}
        user={user}
        collapsed={isCollapsed(physicalCheckStatusFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(physicalCheckStatusFilter.key)}
      />
    ),
    [dataQualityFilter.key]: () => (
      <DataQualityFilter
        key={dataQualityFilter.key}
        selectedFilterMap={selectedFilterMap.get(dataQualityFilter.key)}
        aggregations={aggregations}
        user={user}
        collapsed={isCollapsed(dataQualityFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(dataQualityFilter.key)}
      />
    ),
    [packshotFilter.key]: () => (
      <PackshotFilter
        key={packshotFilter.key}
        selectedFilterMap={selectedFilterMap.get(packshotFilter.key)}
        aggregations={aggregations}
        collapsed={isCollapsed(packshotFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(packshotFilter.key)}
      />
    ),
    [internalCategorizationFilter.key]: () => (
      <InternalCategorizationFilter
        key={internalCategorizationFilter.key}
        selectedFilterMap={selectedFilterMap.get(
          internalCategorizationFilter.key
        )}
        aggregations={aggregations.get(internalCategorizationFilter.key)}
        filterConfig={filtersConfig.get(internalCategorizationFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(
          internalCategorizationFilter.key
        )}
      />
    ),
    [productHierarchyFilter.key]: () => (
      <ProductHierarchyFilter
        key={productHierarchyFilter.key}
        selectedFilterMap={selectedFilterMap.get(productHierarchyFilter.key)}
        aggregations={aggregations.get(productHierarchyFilter.key)}
        user={user}
        filterConfig={filtersConfig.get(productHierarchyFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        filterQueryValues={getFilterQueryValues(productHierarchyFilter.key)}
      />
    ),
    [manufacturersFilter.key]: () => (
      <ManufacturersFilter
        key={manufacturersFilter.key}
        selectedFilterMap={selectedFilterMap.get(manufacturersFilter.key)}
        aggregations={aggregations.get(manufacturersFilter.key)}
        filterConfig={filtersConfig.get(manufacturersFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(manufacturersFilter.key)}
      />
    ),
    [gpcStatusFilter.key]: () => (
      <GpcFilter
        key={gpcStatusFilter.key}
        selectedFilterMap={selectedFilterMap.get(gpcStatusFilter.key)}
        aggregations={aggregations.get(gpcStatusFilter.key)}
        filterConfig={filtersConfig.get(gpcStatusFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(gpcStatusFilter.key)}
      />
    ),
    [isDisplayableForTargetsFilter.key]: () => (
      <HaveDoesntHaveFilter
        key={isDisplayableForTargetsFilter.key}
        selectedFilterMap={selectedFilterMap.get(
          isDisplayableForTargetsFilter.key
        )}
        aggregations={aggregations.get(isDisplayableForTargetsFilter.key)}
        user={user}
        filterConfig={filtersConfig.get(isDisplayableForTargetsFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(
          isDisplayableForTargetsFilter.key
        )}
        filterKey={isDisplayableForTargetsFilter.key}
        filterLabel={isDisplayableForTargetsFilter.filterLabel}
        filterSearchPlaceHolder={
          isDisplayableForTargetsFilter.filterSearchPlaceHolder
        }
        filterNameKey={isDisplayableForTargetsFilter.filterNameKey}
        filterNameKeyFallback={
          isDisplayableForTargetsFilter.filterNameKeyFallback
        }
        haveKey={isDisplayableForTargetsFilter.haveKey}
        doesntHaveKey={isDisplayableForTargetsFilter.doesntHaveKey}
      />
    ),
    [sourcingLabelsRetailerFilter.key]: () => (
      <HaveDoesntHaveFilter
        key={sourcingLabelsRetailerFilter.key}
        selectedFilterMap={selectedFilterMap.get(
          sourcingLabelsRetailerFilter.key
        )}
        aggregations={aggregations.get(sourcingLabelsRetailerFilter.key)}
        user={user}
        filterConfig={filtersConfig.get(sourcingLabelsRetailerFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(
          sourcingLabelsRetailerFilter.key
        )}
        filterKey={sourcingLabelsRetailerFilter.key}
        filterLabel={sourcingLabelsRetailerFilter.filterLabel}
        filterSearchPlaceHolder={
          sourcingLabelsRetailerFilter.filterSearchPlaceHolder
        }
        filterNameKey={sourcingLabelsRetailerFilter.filterNameKey}
      />
    ),
    [sourcingLabelsManufacturerFilter.key]: () => (
      <HaveDoesntHaveFilter
        key={sourcingLabelsManufacturerFilter.key}
        selectedFilterMap={selectedFilterMap.get(
          sourcingLabelsManufacturerFilter.key
        )}
        aggregations={aggregations.get(sourcingLabelsManufacturerFilter.key)}
        user={user}
        filterConfig={filtersConfig.get(sourcingLabelsManufacturerFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(
          sourcingLabelsManufacturerFilter.key
        )}
        filterKey={sourcingLabelsManufacturerFilter.key}
        filterLabel={sourcingLabelsManufacturerFilter.filterLabel}
        filterSearchPlaceHolder={
          sourcingLabelsManufacturerFilter.filterSearchPlaceHolder
        }
        filterNameKey={sourcingLabelsManufacturerFilter.filterNameKey}
      />
    ),
    [ownersFilter.key]: () => (
      <OwnersFilter
        key={ownersFilter.key}
        selectedFilterMap={selectedFilterMap.get(ownersFilter.key)}
        aggregations={aggregations.get(ownersFilter.key)}
        user={user}
        filterConfig={filtersConfig.get(ownersFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(ownersFilter.key)}
      />
    ),
    [brandsFilter.key]: () => (
      <BrandsFilter
        key={brandsFilter.key}
        selectedFilterMap={selectedFilterMap.get(brandsFilter.key)}
        aggregations={aggregations.get(brandsFilter.key)}
        filterConfig={filtersConfig.get(brandsFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        filterQueryValues={getFilterQueryValues(brandsFilter.key)}
        onSort={onSort}
      />
    ),
    [userLabelsFilter.key]: () => (
      <HaveDoesntHaveFilter
        key={userLabelsFilter.key}
        selectedFilterMap={selectedFilterMap.get(userLabelsFilter.key)}
        aggregations={aggregations.get(userLabelsFilter.key)}
        filterConfig={filtersConfig.get(userLabelsFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        filterQueryValues={getFilterQueryValues(userLabelsFilter.key)}
        onSort={onSort}
        filterKey={userLabelsFilter.key}
        filterLabel={userLabelsFilter.filterLabel}
        filterSearchPlaceHolder={userLabelsFilter.filterSearchPlaceHolder}
        filterNameKey={userLabelsFilter.filterNameKey}
        isExactFilter
        missingFilter={userLabelsFilter.missingFilter}
      />
    ),
    [assignationStatusFilter.key]: () => (
      <AssignationStatusFilter
        key={assignationStatusFilter.key}
        selectedFilterMap={selectedFilterMap}
        aggregations={aggregations}
        collapsed={isCollapsed(assignationStatusFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(assignationStatusFilter.key)}
        withRefused={withRefusedAssignations}
        onChangeWithRefused={onChangeWithRefusedAssignations}
      />
    ),
    [assignationSourceOrganizationsFilter.key]: () => (
      <AssignationSourceOrganizationFilter
        key={assignationSourceOrganizationsFilter.key}
        selectedFilterMap={selectedFilterMap.get(
          assignationSourceOrganizationsFilter.key
        )}
        aggregations={aggregations.get(
          assignationSourceOrganizationsFilter.key
        )}
        user={user}
        filterConfig={filtersConfig.get(
          assignationSourceOrganizationsFilter.key
        )}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(
          assignationSourceOrganizationsFilter.key
        )}
      />
    ),
    [isLabeledByFilter.key]: () => (
      <HaveDoesntHaveFilter
        key={isLabeledByFilter.key}
        selectedFilterMap={selectedFilterMap.get(isLabeledByFilter.key)}
        aggregations={aggregations.get(isLabeledByFilter.key)}
        user={user}
        filterConfig={filtersConfig.get(isLabeledByFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(isLabeledByFilter.key)}
        filterKey={isLabeledByFilter.key}
        filterLabel={isLabeledByFilter.filterLabel}
        filterSearchPlaceHolder={isLabeledByFilter.filterSearchPlaceHolder}
        filterNameKey={isLabeledByFilter.filterNameKey}
      />
    ),
    [worksWithSourcingFilter.key]: () => (
      <WorksWithSourcingFilter
        key={worksWithSourcingFilter.key}
        selectedFilterMap={selectedFilterMap.get(worksWithSourcingFilter.key)}
        aggregations={aggregations.get(worksWithSourcingFilter.key)}
        user={user}
        filterConfig={filtersConfig.get(worksWithSourcingFilter.key)}
        collapsed={isCollapsed(worksWithSourcingFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        filterQueryValues={getFilterQueryValues(worksWithSourcingFilter.key)}
      />
    ),
    [reviewActionStatusFilter.key]: () => (
      <ReviewActionStatusFilter
        key={reviewActionStatusFilter.key}
        selectedFilterMap={selectedFilterMap.get(reviewActionStatusFilter.key)}
        collapsed={isCollapsed(reviewActionStatusFilter.key)}
        user={user}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(reviewActionStatusFilter.key)}
      />
    ),
    [reviewFirstShareFilter.key]: () => (
      <ReviewFirstShareFilter
        key={reviewFirstShareFilter.key}
        selectedFilterMap={selectedFilterMap.get(reviewFirstShareFilter.key)}
        collapsed={isCollapsed(reviewFirstShareFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(reviewFirstShareFilter.key)}
      />
    ),
    [groupsFilter.key]: () => (
      <HaveDoesntHaveFilter
        key={groupsFilter.key}
        selectedFilterMap={selectedFilterMap.get(groupsFilter.key)}
        aggregations={aggregations.get(groupsFilter.key)}
        user={user}
        filterConfig={filtersConfig.get(groupsFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        onFilter={onFilterFilters}
        onChangePage={onChangePage}
        onSort={onSort}
        filterQueryValues={getFilterQueryValues(groupsFilter.key)}
        filterKey={groupsFilter.key}
        filterLabel={groupsFilter.filterLabel}
        filterSearchPlaceHolder={groupsFilter.filterSearchPlaceHolder}
        filterNameKey={groupsFilter.filterNameKey}
      />
    ),
    [maturityFilter.key]: () => (
      <MaturityFilter
        key={maturityFilter.key}
        selectedFilterMap={selectedFilterMap.get(maturityFilter.key)}
        aggregations={aggregations.get(maturityFilter.key)}
        filterConfig={filtersConfig.get(maturityFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(maturityFilter.key)}
      />
    ),
    [requestedProductsStatusFilter.key]: () => (
      <RequestedProductsStatusFilter
        key={requestedProductsStatusFilter.key}
        selectedFilterMapRequested={selectedFilterMap.get(
          requestedProductsFilter.key
        )}
        selectedFilterMapRequestedStatuses={selectedFilterMap.get(
          requestedProductsStatusFilter.key
        )}
        aggregations={aggregations.get(requestedProductsFilter.key)}
        collapsed={isCollapsed(requestedProductsFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValuesRequested={getFilterQueryValues(
          requestedProductsFilter.key
        )}
        filterQueryValuesRequestedStatuses={getFilterQueryValues(
          requestedProductsStatusFilter.key
        )}
      />
    ),
    [dietTypeFilter.key]: () => (
      <DietTypeFilter
        key={dietTypeFilter.key}
        filterConfig={dietFilterConfig}
        aggregations={dietAggregations}
        onChange={onChangeFilter}
        onFilter={onFilterFilters}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(dietTypeFilter.key)}
        selectedFilterMap={selectedFilterMap.get(dietTypeFilter.key)}
        onChangePage={onChangePage}
      />
    ),
    [scheduledApplicationDateFilter.key]: () => (
      <ScheduledApplicationDate
        key={scheduledApplicationDateFilter.key}
        id={scheduledApplicationDateFilter.key}
        onChange={onChangeFilter}
        onFilter={onFilterFilters}
        onCollapse={onCollapseFilter}
        onChangePage={onChangePage}
        filterKey={scheduledApplicationDateFilter.key}
        filterLabel={i18n.t('Scheduled application date')}
        specificFilterQueryValues={getFilterQueryValues(
          scheduledApplicationDateFilter.key
        )}
        collapsed={isCollapsed(scheduledApplicationDateFilter.key)}
        aggregations={get(aggregations, [scheduledApplicationDateFilter.key])}
        selectedFilterMap={get(selectedFilterMap, [
          scheduledApplicationDateFilter.key,
        ])}
        page={get(filtersConfig, [scheduledApplicationDateFilter.key, 'page'])}
        isFetchingReferentials={isFetchingReferentials}
      />
    ),
    [recipientsScenariosFilter.key]: () => (
      <RecipientsScenariosFilter
        key={recipientsScenariosFilter.key}
        selectedFilterMap={selectedFilterMap.get(recipientsScenariosFilter.key)}
        aggregations={aggregations.get(recipientsScenariosFilter.key)}
        collapsed={isCollapsed(recipientsScenariosFilter.key)}
        onChange={onChangeFilter}
        onCollapse={onCollapseFilter}
        filterQueryValues={getFilterQueryValues(recipientsScenariosFilter.key)}
        scenarioAggregations={aggregations.get(manufacturerScenariosAgg.key)}
      />
    ),
  };

  const filters = useMemo(() => {
    let filterList = [
      recipientFilter,
      sharingStatusFilter,
      scheduledApplicationDateFilter,
      targetProductStatusFilter,
      sourceProductStatusFilter,
      displayUnitsFilter,
      exportableTagFilter,
      rejectionStatusFilter,
      lifecycleFilter,
      duplicatedProductsFilter,
      targetMarketFilter,
      requiredFieldsCategoryFilter,
      messageFilter,
      synchronizationStatusFilter,
      physicalCheckStatusFilter,
      dataQualityFilter,
      patchFilter,
      packshotFilter,
      internalCategorizationFilter,
      productHierarchyFilter,
      manufacturersFilter,
      gpcStatusFilter,
      isDisplayableForTargetsFilter,
      sourcingLabelsRetailerFilter,
      sourcingLabelsManufacturerFilter,
      ownersFilter,
      brandsFilter,
      userLabelsFilter,
      assignationStatusFilter,
      assignationSourceOrganizationsFilter,
      isLabeledByFilter,
      worksWithSourcingFilter,
      groupsFilter,
      maturityFilter,
      requestedProductsStatusFilter,
      dietTypeFilter,
      reviewActionStatusFilter,
      reviewFirstShareFilter,
      recipientsScenariosFilter,
    ].filter((filter) => hasFilter(filter));

    if (order.length) {
      filterList = sortFilters(filterList);
    }

    return filterList;
  }, [hasFilter, order.length, sortFilters]);

  return (
    <div>
      <SelectedFilters
        selectedFilterList={selectedFilterList}
        onRemoveFilter={onChangeFilter}
        filterQueryMap={filtersQueryMap}
      />
      {filters.map((filter) => renderFilter[filter.key]())}
    </div>
  );
};
