import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import {
  hasAnyProductUpdatePermission,
  isManufacturer,
  isPrivateLabel,
} from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import UpsellModal from 'modules/feature-flag/components/product-bulk-edit/modal';
import { FEATURE_MANUFACTURER_BULKEDIT } from 'modules/feature-flag/constants';
import BulkEditModal from 'modules/product-version-bulk-edit';
import { openProductVersionBulkEdit } from 'modules/product-version-bulk-edit/actions';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

const mapDispatchToProps = {
  openProductVersionBulkEdit,
};

export class BulkEditAction extends Component {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    actions: PropTypes.shape({
      openProductVersionBulkEdit: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    (isManufacturer(user) || isPrivateLabel(user)) &&
    hasAnyProductUpdatePermission(user);

  state = {
    isUpsellModalOpen: false,
  };

  onClick = () => {
    const { actions, selectedMap, productMap, disabled } = this.props;
    if (disabled) {
      return;
    }
    if (this.hasBulkEdit()) {
      actions.openProductVersionBulkEdit(
        selectedMap.map((isSelected, pvId) => productMap.get(pvId)).toList()
      );
    } else {
      this.setState({ isUpsellModalOpen: true });
    }
  };

  onCloseUpsellModal = () => {
    this.setState({ isUpsellModalOpen: false });
  };

  getLabel() {
    const { selectedMap } = this.props;
    if (selectedMap.size === 1) {
      return i18n.t('Edit {{count}} sheet', { count: 1 });
    } else {
      return i18n.t('Edit {{count}} sheets', { count: selectedMap.size });
    }
  }

  hasBulkEdit() {
    return hasFeature(this.props.user, FEATURE_MANUFACTURER_BULKEDIT);
  }

  render() {
    const hasBulkEdit = this.hasBulkEdit();
    const label = this.getLabel();
    return (
      <div className="ActionOption ActionOption--link">
        <div className="ActionOption" onClick={this.onClick}>
          <span>
            <span>{label}</span>
            {!hasBulkEdit && <i>{` - ${i18n.t('Upgrade!')}`}</i>}
          </span>
        </div>
        {hasBulkEdit && <BulkEditModal withAutoClose />}
        {!hasBulkEdit && this.state.isUpsellModalOpen && (
          <UpsellModal onClose={this.onCloseUpsellModal} />
        )}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions
)(BulkEditAction);
