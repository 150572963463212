import { memo } from 'react';
import { useSelector } from 'react-redux';

import Form from 'components/ui/form';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import {
  TargetProductStatusRefused,
  TargetProductStatusSubmitted,
} from 'constants/targetProductStatus';
import { AnchoredSection } from 'modules/anchored';
import { selectDataOpsPatches } from 'modules/data-ops';
import { selectSpecificFieldsDisplayGroups } from 'modules/display-groups/selectors';
import { RELEASE_B2K_COLLAB_EDITS_SV_SF } from 'modules/feature-flag';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import { usePermission } from 'modules/permissions';
import {
  PRODUCT_PERMISSION,
  UPDATE_PERMISSION,
} from 'modules/permissions/const';
import {
  selectCurrentLanguage,
  selectProductKeyId,
  selectProductVersion,
  selectTargetProductStatus,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';

import './retailer-specific-fields.scss';

interface Props {
  order?: number;
  collapsed?: boolean;
}

export const RetailerSpecificFields = memo(
  ({ order, collapsed = false }: Props) => {
    const productStatus: string = useSelector(selectTargetProductStatus);
    const productVersion: any = useSelector(selectProductVersion);
    const displayGroups: any[] = useSelector(selectSpecificFieldsDisplayGroups);
    const currentLanguage: any = useSelector(selectCurrentLanguage);
    const productKeyId = useSelector(selectProductKeyId);
    const patches = useSelector(selectDataOpsPatches);
    const hasSFCollabEditFeature = useSelector(
      selectHasFeature(RELEASE_B2K_COLLAB_EDITS_SV_SF)
    );

    const { getPermission, hasPermission } = usePermission({
      module: PRODUCT_PERMISSION,
      entityId: productKeyId,
    });

    if (
      !productVersion ||
      !displayGroups ||
      displayGroups.length === 0 ||
      !productStatus ||
      productStatus === TargetProductStatusRefused.id
    ) {
      return null;
    }

    const isSubmitted = productStatus === TargetProductStatusSubmitted.id;
    const headerContent = isSubmitted
      ? { text: i18n.t('Waiting for validation') }
      : null;

    return (
      <AnchoredSection
        collapsible
        collapsed={collapsed}
        section={i18n.t('Internal fields')}
        order={order}
        header={headerContent}
        disabled={isSubmitted}
      >
        {displayGroups.map((displayGroup) => (
          <div key={displayGroup.id} className="SpecificFields__formGroup">
            <Form
              entity={productVersion}
              entityKind={ENTITY_TYPE_PRODUCTVERSION}
              entityId={productVersion.source.id}
              formGroup={displayGroup}
              currentLanguage={currentLanguage}
              patches={patches}
              disableDataOps={!hasSFCollabEditFeature}
              entityPermissions={getPermission(UPDATE_PERMISSION)}
              isDisabled={!hasPermission({ permissions: UPDATE_PERMISSION })}
            />
          </div>
        ))}
      </AnchoredSection>
    );
  }
);

export default RetailerSpecificFields;
