import {
  BillingSubscription,
  BillingSubscriptionRequiredAction,
  BillingSubscriptionStatus,
} from '@alkem/sdk-dashboard';

import { isBigManufacturer } from 'core/api/organization-settings';
import {
  getOrganizationSettings,
  isAdmin,
  isManufacturer,
} from 'core/api/user';
import {
  selectHasBillingCardFeature,
  selectHasBillingFeature,
  selectHasBillingSepaFeature,
} from 'modules/feature-flag/selectors';
import { UserImmutable } from 'types';
import { isProduction } from 'utils';
import { userWithAlkemicsSource } from 'utils/branding';
import i18n, { getUserLanguage } from 'utils/i18n';

import {
  SOURCE_TYPE_CARD,
  SOURCE_TYPE_SEPA,
  stripeGlobal,
  stripeJs,
  stripeKey,
  stripeTestKey,
} from '../constants';

export function formatCurrency(currency = '') {
  switch (currency.toLowerCase()) {
    case 'eur':
      return '€';
    case 'gpb':
      return '£';
    case 'usd':
      return '$';
    default:
      return '';
  }
}

export const cleanStripeControllers = () => {
  Array.from(global.document.getElementsByTagName('iframe'))
    .filter(
      (iframe) =>
        iframe.src.includes(stripeJs) && iframe.src.includes('/controller')
    )
    .forEach((stripeIframe) => stripeIframe.remove());
};

export const setupStripeClient = () => {
  cleanStripeControllers();
  return global[stripeGlobal](isProduction() ? stripeKey : stripeTestKey);
};

export const setupStripeElements = (stripeClient: any) =>
  stripeClient.elements({
    locale: getUserLanguage(true),
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css?family=Roboto:500,400,300,100',
      },
    ],
  });

export const hasBillingFeature: (user: UserImmutable) => boolean = (user) =>
  isAdmin(user) &&
  isManufacturer(user) &&
  !isBigManufacturer(getOrganizationSettings(user)) &&
  userWithAlkemicsSource(user) &&
  selectHasBillingFeature(user) &&
  [selectHasBillingCardFeature, selectHasBillingSepaFeature].some((selector) =>
    selector(user)
  );

export const isFormValid = ({
  formType,
  isIbanComplete,
  isCardComplete,
  taxIdValid,
  areTCsAccepted,
}: {
  formType: string;
  isIbanComplete?: boolean;
  isCardComplete?: boolean;
  taxIdValid: boolean;
  areTCsAccepted: boolean;
}) => {
  if (formType === SOURCE_TYPE_SEPA) {
    return !!isIbanComplete && areTCsAccepted && taxIdValid;
  }
  if (formType === SOURCE_TYPE_CARD) {
    return !!isCardComplete && areTCsAccepted && taxIdValid;
  }
  return false;
};

export const hasRequiredAction = (
  subscription: BillingSubscription,
  sourceType: string
) => {
  if (!subscription || !subscription.required_action) {
    return false;
  }
  const action = subscription.required_action;
  if (
    action ===
      BillingSubscriptionRequiredAction.PAYMENT_CONFIRMATION_REQUIRED ||
    action === BillingSubscriptionRequiredAction.PAYMENT_METHOD_REQUIRED ||
    (action === BillingSubscriptionRequiredAction.WAIT_PAYMENT_PROCESSING &&
      sourceType !== SOURCE_TYPE_SEPA)
  ) {
    return true;
  }
  return false;
};

export const getRequiredActionLabel = (subscription: BillingSubscription) => {
  switch (subscription && subscription.required_action) {
    case BillingSubscriptionRequiredAction.PAYMENT_CONFIRMATION_REQUIRED:
      return i18n.t(
        'frontproductstream.billing_payment.payment_to_confirm.text',
        {
          defaultValue: 'Your payment has to be confirmed',
        }
      );
    case BillingSubscriptionRequiredAction.PAYMENT_METHOD_REQUIRED:
      return i18n.t(
        'frontproductstream.billing_payment.payment_invalid_or_expired.text',
        {
          defaultValue: 'Your payment method is invalid or expired',
        }
      );
    case BillingSubscriptionRequiredAction.WAIT_PAYMENT_PROCESSING:
      return i18n.t(
        'frontproductstream.billing_payment.payment_processing.text',
        {
          defaultValue: 'Your payment is processing',
        }
      );
    default:
      return null;
  }
};

export const isSubscriptionBlocked = (subscription: BillingSubscription) =>
  [
    BillingSubscriptionStatus.PAST_DUE,
    BillingSubscriptionStatus.UNPAID,
  ].includes(subscription && subscription.status) &&
  ![
    BillingSubscriptionRequiredAction.PAYMENT_CONFIRMATION_REQUIRED,
    BillingSubscriptionRequiredAction.PAYMENT_METHOD_REQUIRED,
    BillingSubscriptionRequiredAction.WAIT_PAYMENT_PROCESSING,
  ].includes((subscription && subscription.required_action) as any);
