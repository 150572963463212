import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
  assignationSourceOrganizationsFilter,
  assignationStatusFilter,
  brandsFilter,
  dataQualityFilter,
  dietTypeFilter,
  displayUnitsFilter,
  duplicatedProductsFilter,
  exportableTagFilter,
  gpcStatusFilter,
  groupsFilter,
  internalCategorizationFilter,
  isDisplayableForTargetsFilter,
  isLabeledByFilter,
  lifecycleFilter,
  manufacturerScenariosAgg,
  manufacturersFilter,
  maturityFilter,
  messageFilter,
  messageSourcingFilter,
  ownersFilter,
  packshotFilter,
  patchFilter,
  physicalCheckStatusFilter,
  productHierarchyFilter,
  recipientFilter,
  recipientsScenariosFilter,
  rejectionStatusFilter,
  requestedProductsFilter,
  requestedProductsStatusFilter,
  requiredFieldsCategoryFilter,
  reviewActionStatusFilter,
  reviewFirstShareFilter,
  scheduledApplicationDateFilter,
  sharingStatusFilter,
  sourceProductStatusFilter,
  sourcingLabelsManufacturerFilter,
  sourcingLabelsRetailerFilter,
  synchronizationStatusFilter,
  targetMarketFilter,
  targetProductStatusFilter,
  userLabelsFilter,
  worksWithSourcingFilter,
} from 'core/modules/list/constants/filters';

import { initCatalog } from './actions';
import {
  ARCHIVED_PRODUCTS,
  ASSIGNATION,
  PRODUCTS,
  PRODUCTS_TO_REVIEW,
  PRODUCTS_WITH_MENU,
  SOURCING,
} from './constants/context';
import { selectCatalogUuid } from './selectors';

export const withContext = (context, ComponentToWrap) =>
  function ContextualCatalog() {
    const dispatch = useDispatch();
    const catalogUuid = useSelector(selectCatalogUuid);
    const uuidRef = useRef(uuid());
    const location = useLocation();
    const isReady = uuidRef.current === catalogUuid;
    useEffect(() => {
      dispatch(
        initCatalog({
          context,
          route: location.pathname,
          uuid: uuidRef.current,
          search: location.search,
        })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return isReady ? <ComponentToWrap context={context} /> : null;
  };

export const getFiltersByContext = (context) => {
  if (context === PRODUCTS_TO_REVIEW) {
    return {
      [requestedProductsFilter.key]: true,
      [requestedProductsStatusFilter.key]: true,
      [internalCategorizationFilter.key]: true,
      [reviewActionStatusFilter.key]: true,
      [reviewFirstShareFilter.key]: true,
    };
  }
  if (context === ASSIGNATION) {
    return {
      [assignationSourceOrganizationsFilter.key]: true,
      [assignationStatusFilter.key]: true,
    };
  }
  if (context === SOURCING) {
    return {
      [brandsFilter.key]: true,
      [isLabeledByFilter.key]: true,
      [ownersFilter.key]: true,
      [sourcingLabelsManufacturerFilter.key]: true,
      [sourcingLabelsRetailerFilter.key]: true,
      [worksWithSourcingFilter.key]: true,
    };
  }
  if ([PRODUCTS, PRODUCTS_WITH_MENU, ARCHIVED_PRODUCTS].includes(context)) {
    return {
      [assignationSourceOrganizationsFilter.key]: false,
      [brandsFilter.key]: true,
      [dataQualityFilter.key]: true,
      [displayUnitsFilter.key]: true,
      [duplicatedProductsFilter.key]: true,
      [exportableTagFilter.key]: true,
      [gpcStatusFilter.key]: true,
      [groupsFilter.key]: true,
      [internalCategorizationFilter.key]: true,
      [isDisplayableForTargetsFilter.key]: true,
      [lifecycleFilter.key]: context !== ARCHIVED_PRODUCTS,
      [manufacturersFilter.key]: true,
      [maturityFilter.key]: true,
      [messageFilter.key]: true,
      [messageSourcingFilter.key]: true,
      [packshotFilter.key]: true,
      [patchFilter.key]: true,
      [physicalCheckStatusFilter.key]: true,
      [productHierarchyFilter.key]: true,
      [recipientFilter.key]: true,
      [rejectionStatusFilter.key]: true,
      [requestedProductsFilter.key]: true,
      [requestedProductsStatusFilter.key]: true,
      [requiredFieldsCategoryFilter.key]: true,
      [sharingStatusFilter.key]: true,
      [sourceProductStatusFilter.key]: true,
      [synchronizationStatusFilter.key]: true,
      [targetMarketFilter.key]: true,
      [targetProductStatusFilter.key]: true,
      [userLabelsFilter.key]: true,
      [dietTypeFilter.key]: true,
      [scheduledApplicationDateFilter.key]: true,
      [manufacturerScenariosAgg.key]: true,
      [recipientsScenariosFilter.key]: true,
    };
  }
  return {};
};
