import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_PRO_RETAILER_FIELD_FLAG,
  FEATURE_PRO_VALIDATION_SUGGESTED_FIELD,
} from 'modules/feature-flag/constants';
import i18n from 'utils/i18n';

export const ProductValidationStatusUnknown = {
  name: i18n.t(
    'frontproductstream.constants.product_validation_status.not_applicable',
    { defaultValue: 'Not applicable' }
  ),
  id: 0,
  iconClass: 'ProductValidationStatusUnknown',
};

export const ProductValidationStatusKo = {
  name: i18n.t(
    'frontproductstream.constants.product_validation_status.incomplete_data',
    { defaultValue: 'Incomplete data' }
  ),
  id: 1,
  iconClass: 'ProductValidationStatusKo',
};

export const ProductValidationStatusOk = {
  name: i18n.t(
    'frontproductstream.constants.product_validation_status.satisfying_data',
    { defaultValue: 'Satisfying data' }
  ),
  id: 2,
  iconClass: 'ProductValidationStatusOk',
};

export const ProductValidationStatusSuggested = {
  name: i18n.t(
    'frontproductstream.constants.product_validation_status.update_requested',
    { defaultValue: 'Update requested' }
  ),
  id: 3,
  iconClass: 'ProductValidationStatusSuggested',
};

export const ProductValidationStatusMap = {
  [ProductValidationStatusUnknown.id]: ProductValidationStatusUnknown,
  [ProductValidationStatusKo.id]: ProductValidationStatusKo,
  [ProductValidationStatusOk.id]: ProductValidationStatusOk,
  [ProductValidationStatusSuggested.id]: ProductValidationStatusSuggested,
};

export function productValidationStatusList(user) {
  if (
    hasFeature(user, FEATURE_PRO_VALIDATION_SUGGESTED_FIELD) ||
    hasFeature(user, FEATURE_PRO_RETAILER_FIELD_FLAG)
  ) {
    return [
      ProductValidationStatusUnknown,
      ProductValidationStatusKo,
      ProductValidationStatusSuggested,
      ProductValidationStatusOk,
    ];
  }
  return [
    ProductValidationStatusUnknown,
    ProductValidationStatusKo,
    ProductValidationStatusOk,
  ];
}
