import classNames from 'classnames';
import { connect } from 'react-redux';

import { Radio } from '@alkem/react-ui-inputs';

import Field from 'components/ui/form/field';
import HierarchyIcon from 'components/ui/hierarchy/icon';
import { TypePackagingLabels } from 'constants/typePackaging';
import i18n from 'utils/i18n';

import './radio.scss';

class SuppliedUnitFormRadio extends Field {
  componentDidMount() {
    const hierarchy = this.props.entity.getIn(['edited', 'hierarchy']);
    const { value } = this.props;
    this.updateValues(hierarchy, value);
    super.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    const { entity, value } = this.props;
    if (prevProps.entity !== entity || prevProps.value !== value) {
      const hierarchy = entity.getIn(['edited', 'hierarchy']);
      this.updateValues(hierarchy, value);
    }
  }

  deliverableOption(value, typePackagingCode, selectedValue) {
    return {
      value,
      label: (
        <div className="SuppliedUnitRadio__HierarchyWrapper">
          <div>
            <HierarchyIcon
              type={typePackagingCode}
              disabled={selectedValue !== value}
            />
            {TypePackagingLabels[typePackagingCode].singular}
          </div>
        </div>
      ),
    };
  }

  updateValues(hierarchy, selectedValue) {
    const { full } = this.props;
    if (!hierarchy || !hierarchy.id) {
      return;
    }

    const mainItem = hierarchy.mainItem();
    let current = hierarchy;
    const options = [];
    while (current && (full || current.id !== mainItem.id)) {
      options.unshift(
        this.deliverableOption(
          current.specializes.id,
          current.typePackaging.code,
          selectedValue
        )
      );
      [current] = current.children;
    }
    if (current) {
      options.unshift(
        this.deliverableOption(
          current.specializes.id,
          current.typePackaging.code,
          selectedValue
        )
      );
    }
    this.setState({ options });
  }

  getValue(event) {
    let { value } = event.currentTarget;

    if (this.props.isEntity) {
      value = { id: Number(value) };
    } else if (value === 'false') {
      value = false;
    } else if (value === 'true') {
      value = true;
    } else if (value === 'null') {
      value = null;
    } else if (value === 'undefined') {
      value = undefined;
    } else if (Number(value).toString() === value) {
      value = Number(value);
    }

    return value;
  }

  render() {
    const { value, field, isEntity } = this.props;
    if (!field) {
      return null;
    }

    const { options } = this.state;

    // Default to null if not entity and not value.
    let localValue = value;
    if (!isEntity && typeof value === 'undefined') {
      localValue = null;
    }

    const labelClasses = {
      'col-xs-4': true,
      SuppliedUnitRadio__Label: !!options,
    };
    const renderedLabel = this.renderLabel(classNames(labelClasses));

    let content = (
      <Radio
        id={this.getId()}
        value={isEntity && localValue ? localValue.id : localValue}
        helpMessage={this.renderHelpMessage()}
        onChange={this.handleChange}
        options={options}
        className="FormRadio__radio"
      />
    );
    if (!options) {
      content = (
        <div id={this.getId()} className="SuppliedUnitRadio__Placeholder">
          <em>
            {i18n.t('frontproductstream.supplied_unit.empty_state.text', {
              defaultValue: 'You need to choose a logistical hierarchy.',
            })}
          </em>
        </div>
      );
    }

    const classes = {
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };
    return (
      <div className="InputField FormRadio row">
        {renderedLabel}
        <div className={classNames(classes)}>{content}</div>
      </div>
    );
  }
}

export default connect()(SuppliedUnitFormRadio);
