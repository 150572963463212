import DateRangePicker from 'react-daterange-picker';
import { connect } from 'react-redux';

import Field from 'components/ui/form/field';
import { fromUTC, toUTC } from 'utils';
import i18n from 'utils/i18n';
import moment from 'utils/moment';

import './daterange.scss';

const mapStateToProps = () => ({});

class DateRange extends Field {
  getValue(event) {
    return [toUTC(event.start), toUTC(event.end)];
  }

  render() {
    const { value, field } = this.props;
    if (!field) {
      return null;
    }

    const props = {
      numberOfCalendars: 2,
      selectionType: 'range',
      singleDateRange: true,
      onSelect: this.handleChange,
      value: moment.range(),
    };

    let range = null;
    if (value && value.length === 2 && value[0] && value[1]) {
      const start =
        value[0] instanceof Date ? fromUTC(value[0]) : moment(value[0]);
      const end =
        value[1] instanceof Date ? fromUTC(value[1]) : moment(value[1]);
      range = moment.range(start, end);
      props.value = range;
    }

    return (
      <div className="DateRange row">
        <label htmlFor={this.getId()} className="InputField__label col-xs-2">
          {field.label}
        </label>
        <div className="col-xs-10">
          <div id={this.getId()} className="DateRange__item">
            <DateRangePicker {...props} />
            <div>
              <input
                className="DateRange__input"
                type="text"
                readOnly
                placeholder={i18n.t(
                  'frontproductstream.daterange.start.placeholder',
                  { defaultValue: 'Start date' }
                )}
                value={range ? range.start.format('LL') : null}
              />
              <input
                className="DateRange__input"
                type="text"
                readOnly
                placeholder={i18n.t(
                  'frontproductstream.daterange.end.placeholder',
                  { defaultValue: 'End date' }
                )}
                value={range ? range.end.format('LL') : null}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DateRange);
