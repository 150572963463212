import { List } from 'immutable';
import { call, put, race, select, take } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { getFiltersCustomFieldMapping } from 'core/modules/list/utils/filters';
import { FEATURE_PRODUCT_REVIEW, hasFeature } from 'modules/feature-flag';
import { selectUser } from 'reducers/user/selectors';
import { fetchColumnReferentials } from 'resources/referentialApi';
import { logError } from 'utils/logging';

import { fetchList, receiveColumnReferentials } from '../actions';
import { CANCEL_FETCH_COLUMN_REFERENTIALS } from '../actions/types';
import { REVIEW_ENGINE_COLUMNS } from '../constants/review';
import { getId } from '../selectors/referential';

export function* fetchColumnReferentialsTask({
  organizationAllowedColumns,
  userPreferredColumns,
  filtersQueryMap,
  searchQuery,
}) {
  yield put(startLoading());
  try {
    let filteredReferentials: List<any> = List();
    const { referentials, error } = yield call(fetchColumnReferentials);
    if (referentials) {
      const user = yield select(selectUser);
      const hasReviewEngine = hasFeature(user, FEATURE_PRODUCT_REVIEW);

      // Filter referentials based on organizationSettings
      filteredReferentials = referentials.filter(
        (referential) =>
          organizationAllowedColumns.includes(getId(referential)) &&
          (hasReviewEngine ||
            !REVIEW_ENGINE_COLUMNS.includes(referential.get('code')))
      );

      const filtersCustomFieldMapping = getFiltersCustomFieldMapping(user);

      if (filtersCustomFieldMapping) {
        filteredReferentials = filteredReferentials.map((referential) => {
          let newReferential = referential;
          const sourceInclude = referential.getIn(['data', 'sourceInclude']);
          const path = referential.getIn(['data', 'path']);
          if (filtersCustomFieldMapping[sourceInclude]) {
            newReferential = newReferential.setIn(
              ['data', 'sourceInclude'],
              filtersCustomFieldMapping[sourceInclude]
            );
          }
          if (filtersCustomFieldMapping[path]) {
            newReferential = newReferential.setIn(
              ['data', 'path'],
              filtersCustomFieldMapping[path]
            );
          }
          if (newReferential !== referential) {
            newReferential = newReferential.deleteIn(['data', 'sort']);
          }
          return newReferential;
        }) as List<any>;
      }
      yield put(
        receiveColumnReferentials({
          filteredReferentials,
          userPreferredColumns,
        })
      );
      yield put(fetchList({ filtersQueryMap, searchQuery }));
    }
    if (error) {
      logError(error);
    }
    return filteredReferentials;
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
  return null;
}

export default function* columnReferentialSaga({ payload }) {
  yield race({
    task: call(fetchColumnReferentialsTask, payload),
    cancel: take(CANCEL_FETCH_COLUMN_REFERENTIALS),
  });
}
