import { Map } from 'immutable';
import { Component } from 'react';
import processString from 'react-process-string';
import { connect } from 'react-redux';

import { ProgressBar } from '@alkem/react-ui-progress';
import { Select } from '@alkem/react-ui-select';

import { notificationError, notificationSuccess } from 'actions/notification';
import Modal from 'components/ui/modal';
import ProductReference from 'components/ui/product-reference';
import {
  getDefaultDisplayName,
  getProductGTINWithFallbackOnId,
  getTargetMarketId,
} from 'core/api/productversion';
import {
  hasAnyProductUpdatePermission,
  isManufacturer,
  isPrivateLabel,
} from 'core/api/user';
import { bulkActivateRecipients } from 'modules/catalog/product/actions';
import { selectLocalesByTargetMarket } from 'reducers/user/selectors';
import authApi from 'resources/authApi';
import serviceProductApi from 'resources/serviceProductApi';
import { removeAt } from 'utils';
import { requestWithHeaders } from 'utils/api';
import { keyBy } from 'utils/fn';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { track } from 'utils/tracking';
import { isValidGtin } from 'utils/validator/gtin';

import './style.scss';

type ProductVersion = object;

export type SelectedMap = Map<string, boolean>;

export type ProductMap = Map<string | undefined, Map<string, any>>;

interface Props {
  productMap: ProductMap;
  selectedMap: SelectedMap;
  nbFilteredProducts: number;
  locales: object;
  disabled: boolean;
  bulkActivateRecipients: (payload: { recipientIds: number[] }) => void;
  notificationSuccess: (message: string) => void;
  notificationError: (message: string, options: { context: string }) => void;
}

export interface Recipient {
  id: string;
  label: string;
}

interface GroupOption {
  id: string;
  label: string;
  children?: Recipient[];
}

interface State {
  showModal: boolean;
  isProcessing: boolean;
  isFetchingRecipients: boolean;
  options: GroupOption[];
  selectedRecipients: Recipient[];
  processedItems: number;
}

const compareGroups = (a, b) => {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};

const mapStateToProps = (state) => ({
  locales: selectLocalesByTargetMarket(state),
});

const mapDispatchToProps = {
  bulkActivateRecipients,
  notificationSuccess,
  notificationError,
};

export class BulkActionAddRecipients extends Component<Props, State> {
  public static defaultProps = {
    disabled: false,
  };

  static shouldBeDisplayed = ({ user }) =>
    (isManufacturer(user) || isPrivateLabel(user)) &&
    hasAnyProductUpdatePermission(user);

  public state = {
    showModal: false,
    isProcessing: false,
    isFetchingRecipients: false,
    options: [],
    selectedRecipients: [] as any[],
    processedItems: 0,
  };

  private cancelled = false;

  public componentDidUpdate(_: Props, prevState: State) {
    const { showModal, options } = this.state;
    const modalOpened = showModal && !prevState.showModal;
    const noGroupData = options.length === 0;
    if (modalOpened && noGroupData) {
      this.fetchRecipients();
    }
  }

  public componentWillUnmount() {
    this.cancelled = true;
  }

  private getLabel() {
    const { selectedMap, nbFilteredProducts } = this.props;
    const count = selectedMap.isEmpty() ? nbFilteredProducts : selectedMap.size;
    return i18n.t('Activate recipients for {{count}} product', { count });
  }

  private addRecipient = (recipient: Recipient) => {
    const { selectedRecipients } = this.state;
    if (!selectedRecipients.find((r) => r.id === recipient.id)) {
      this.setState({ selectedRecipients: [...selectedRecipients, recipient] });
    }
  };

  private removeRecipient = (index: number) => {
    const { selectedRecipients } = this.state;
    this.setState({
      selectedRecipients: removeAt(selectedRecipients, index),
    });
  };

  private openModal = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({ showModal: true });
  };

  private closeModal = () => {
    this.setState({ showModal: false });
  };

  private resetModal = () => {
    this.setState({
      selectedRecipients: [],
      processedItems: 0,
      isProcessing: false,
    });
  };

  private activateRecipientsOnProductVersion = async (productVersion) => {
    const { selectedRecipients } = this.state;
    const target_organization_ids = selectedRecipients.map(
      (recipient) => recipient.id
    );

    const product_key_id = productVersion.getIn(['product_key', 'id']);

    const { error } = await requestWithHeaders(
      serviceProductApi,
      'post',
      '/product/v2/product/recipients',
      {
        product_key_id,
        target_organization_ids,
      }
    );

    if (error) {
      logError(error);
      return false;
    }

    if (this.cancelled) {
      return true;
    }

    this.setState((prevState) => ({
      processedItems: prevState.processedItems + 1,
    }));

    return true;
  };

  private activateRecipientsOnProductVersionAsync = () => {
    const { selectedRecipients } = this.state;
    const recipientIds = selectedRecipients.map((recipient) => recipient.id);

    this.props.bulkActivateRecipients({ recipientIds });

    this.closeModal();
    this.resetModal();
  };

  private activateRecipients = async () => {
    const { selectedMap, nbFilteredProducts } = this.props;
    const { selectedRecipients } = this.state;
    const productVersions = this.getSelectedProductVersions();
    const countRecipients = selectedRecipients.length;
    const countProducts = selectedMap.isEmpty()
      ? nbFilteredProducts
      : selectedMap.size;
    this.setState({ isProcessing: true });
    const results = await Promise.all(
      productVersions.map(this.activateRecipientsOnProductVersion)
    );

    if (results.some((x) => !x)) {
      this.props.notificationError(
        i18n.t('Something went wrong while activating recipients'),
        { context: 'modal' }
      );
    } else {
      setTimeout(() => {
        if (this.cancelled) {
          return;
        }
        this.closeModal();
        this.resetModal();
        this.props.notificationSuccess(
          i18n.t(
            '{{countRecipients}} recipients activated for {{countProducts}} products',
            {
              countRecipients,
              countProducts,
            }
          )
        );
      }, 1000);
    }
    if (!this.cancelled) {
      this.setState({ isProcessing: false });
    }
  };

  private getSelectedProductVersions = () => {
    const { selectedMap, productMap } = this.props;
    if (selectedMap.isEmpty()) {
      // We need it to fetch the recipients
      return productMap.map((_, pvId) => productMap.get(pvId)).toArray();
    }
    return selectedMap.map((_, pvId) => productMap.get(pvId)).toArray();
  };

  private async fetchRecipients() {
    try {
      this.setState({ isFetchingRecipients: true });
      const pvs = this.getSelectedProductVersions();
      const tm = getTargetMarketId(pvs[0]);
      const response = await authApi.RecipientsList({
        withs: { group: true },
        filters: { targetMarkets: [tm] },
      });
      const recipients = response.data.filter(({ group }) => group);

      if (this.cancelled) {
        return;
      }

      const groups = keyBy(
        recipients.map(({ group }) => ({
          id: group && group.id,
          label: group && group.name.trim(),
        })),
        'id'
      );

      recipients.forEach((recipient) => {
        if (recipient.group) {
          if (!groups[recipient.group.id].children) {
            groups[recipient.group.id].children = [];
          }
          groups[recipient.group.id].children.push({
            id: recipient.id,
            label: recipient.nameLegal,
          });
        }
      });

      this.setState({
        options: Object.values(groups).sort(compareGroups) as GroupOption[],
      });
    } catch (error) {
      logError(error);
    } finally {
      if (!this.cancelled) {
        this.setState({ isFetchingRecipients: false });
      }
    }
  }

  private renderDisplayName(pv?: ProductVersion) {
    const { locales } = this.props;
    return <b className="display-name">{getDefaultDisplayName(pv, locales)}</b>;
  }

  private renderProductReference(pv) {
    const reference = getProductGTINWithFallbackOnId(pv);
    if (isValidGtin(reference)) {
      return <ProductReference inline reference={reference} />;
    } else {
      return reference;
    }
  }

  private renderSelectedProducts() {
    const { selectedMap, nbFilteredProducts, productMap } = this.props;
    const count = selectedMap.isEmpty() ? nbFilteredProducts : selectedMap.size;
    const separator = ' • ';

    if (
      selectedMap.isEmpty() &&
      (nbFilteredProducts > 250 || nbFilteredProducts > productMap.size)
    ) {
      return (
        <div className="BulkActionAddRecipients_products">
          <p>
            {processString([
              {
                regex: /__CountProducts__/,
                fn: () => (
                  <span
                    key={`number-activate-products-${nbFilteredProducts}`}
                    className="BulkActionAddRecipients_countproducts"
                  >
                    {`${nbFilteredProducts}`}
                  </span>
                ),
              },
            ])(
              i18n.t('Products that will be shared: {{formattedCount}}', {
                count: nbFilteredProducts,
                formattedCount: '__CountProducts__',
              })
            )}
          </p>
          <p>
            {i18n.t(
              'Activating many recipients can take up to a few hours. You will receive a notification once the activation is done.'
            )}
          </p>
        </div>
      );
    }
    return (
      <div className="BulkActionAddRecipients_products">
        <p>{i18n.t('For the following {{count}} products:', { count })}</p>
        <ul>
          {this.getSelectedProductVersions()
            .filter((pv) => !!pv)
            .map((pv) => (
              <li key={pv && pv.get('id')}>
                {this.renderDisplayName(pv)}
                {separator}
                {this.renderProductReference(pv)}
              </li>
            ))}
        </ul>
      </div>
    );
  }

  private renderRecipientSelection() {
    const { options, selectedRecipients, isFetchingRecipients } = this.state;

    return (
      <div className="BulkActionAddRecipients_recipients">
        <p>{i18n.t('Activate recipients:')}</p>
        <Select
          id="activate-recipients"
          isTree
          multiple
          options={options}
          values={selectedRecipients}
          onValueAdd={this.addRecipient}
          onValueDelete={this.removeRecipient}
          displaySpinner={isFetchingRecipients}
          placeholder={i18n.t('Select...')}
        />
      </div>
    );
  }

  private renderProgressBar() {
    const { isProcessing, processedItems } = this.state;
    const { nbFilteredProducts } = this.props;
    if (!isProcessing) {
      return null;
    }
    const { selectedMap } = this.props;
    return (
      <>
        <br />
        <ProgressBar
          value={processedItems}
          max={selectedMap.isEmpty() ? nbFilteredProducts : selectedMap.size}
          color="success"
          height="medium"
        />
      </>
    );
  }

  private onConfirm = () => {
    const { selectedMap, nbFilteredProducts, productMap } = this.props;
    const { selectedRecipients } = this.state;
    if (
      selectedMap.isEmpty() &&
      (nbFilteredProducts > 250 || nbFilteredProducts > productMap.size)
    ) {
      this.activateRecipientsOnProductVersionAsync();
      track({
        category: 'bulk_activate_recipient',
        action: 'bulk_activate_recipient_started',
        type: 'asynchronous',
        recipients_number: selectedRecipients.length,
        recipients_names: selectedRecipients.map(
          (recipient) => recipient.label
        ),
        recipients_ids: selectedRecipients.map((recipient) => recipient.id),
        products_number: nbFilteredProducts,
      });
    } else {
      this.activateRecipients();
      track({
        category: 'bulk_activate_recipient',
        action: 'bulk_activate_recipient_started',
        type: 'synchronous',
        recipients_number: selectedRecipients.length,
        recipients_names: selectedRecipients.map(
          (recipient) => recipient.label
        ),
        recipients_ids: selectedRecipients.map((recipient) => recipient.id),
        products_number: selectedMap.isEmpty()
          ? nbFilteredProducts
          : selectedMap.size,
      });
    }
  };

  public render() {
    const { showModal, isProcessing, selectedRecipients } = this.state;
    return (
      <>
        <div className="ActionOption" onClick={this.openModal}>
          {this.getLabel()}
        </div>
        {showModal && (
          <Modal
            title={i18n.t('Recipient activation')}
            confirmButtonText={i18n.t('Activate')}
            confirmDisabled={selectedRecipients.length === 0}
            isProcessing={isProcessing}
            onConfirm={this.onConfirm}
            onClose={this.closeModal}
          >
            {this.renderRecipientSelection()}
            {this.renderSelectedProducts()}
            {this.renderProgressBar()}
          </Modal>
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkActionAddRecipients);
