import { Map } from 'immutable';
import { once } from 'lodash';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { isDisplayableForTargetsFilter } from 'core/modules/list/constants/filters/isdisplayablefortargets';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import i18n from 'utils/i18n';

import CollapsibleAdvancedFilter from '../advanced';

export class IsDisplayableForTargetsFilter extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    filterConfig: ImmutablePropTypes.map.isRequired,
    filterQueryValues: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onSort: PropTypes.func,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    filterConfig: Map(),
  };

  filterKey = isDisplayableForTargetsFilter.key;

  selectOptions = memoize((aggregations) => aggregations.valueSeq().toList());

  updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: this.props.filterQueryValues,
      filterList: this.selectOptions(this.props.aggregations),
      filterKey: this.filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) => `${this.filterLabel}: ${filter.get('id')}`,
      selectFilterData: (filter) => [filter.get('id')],
    }).then((filtersFromQuery) => {
      this.props.onChange(filtersFromQuery, true);
    });
  });

  filterLabel = i18n.t(
    'frontproductstream.core.list_filter_displayable_for_targets.label',
    { defaultValue: 'Is Displayable For Targets' }
  );

  filterSelectors = {
    selectId: (filter) => filter.get('id'),
    selectLabel: (filter) => filter.get('name') || filter.get('id'),
  };

  componentDidMount() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  componentDidUpdate() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  shouldUpdateSelectionFromQuery = () => {
    const { aggregations, selectedFilterMap } = this.props;
    return !aggregations.isEmpty() && selectedFilterMap.isEmpty();
  };

  render() {
    return (
      <CollapsibleAdvancedFilter
        id="list-filter-is-displayable-for-targets"
        filterKey={this.filterKey}
        filterLabel={this.filterLabel}
        filters={this.selectOptions(this.props.aggregations)}
        selectors={this.filterSelectors}
        selectedFilterMap={this.props.selectedFilterMap}
        aggregations={this.props.aggregations}
        searchPlaceholder={i18n.t(
          'frontproductstream.core.list_filter_displayable_for_targets.search_placeholder',
          { defaultValue: 'Search for targets' }
        )}
        searchQuery={this.props.filterConfig.get('query')}
        page={this.props.filterConfig.get('page')}
        collapsed={this.props.filterConfig.get('collapsed')}
        onChange={this.props.onChange}
        onCollapse={this.props.onCollapse}
        onFilter={this.props.onFilter}
        onChangePage={this.props.onChangePage}
        onSort={this.props.onSort}
        withPagination
      />
    );
  }
}
