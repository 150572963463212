import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { isRealtimeLabelSuggestion } from 'components/ui/form/plugins/validator/utils';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { selectValidationResultsByEntity } from 'modules/validation';
import { selectProductVersionId } from 'reducers/productVersion';

export const selectLabelMLSuggestions = createSelector(
  selectProductVersionId,
  selectValidationResultsByEntity,
  (entityId, ruleResults) => {
    const rulesMap = ruleResults
      .filter(
        (results) =>
          results.getIn(['entity', '_type']) === ENTITY_TYPE_PRODUCTVERSION &&
          results.getIn(['entity', 'id']) === entityId
      )
      .reduce((all, results) => all.merge(results.get('rules')));
    let suggestions = Map();
    if (rulesMap) {
      // map fieldId -> rule
      suggestions = rulesMap
        .get('rules')
        .filter((rule) => isRealtimeLabelSuggestion(rule))
        .valueSeq()
        .reduce((acc, rule) => acc.set(rule.get('fieldId'), rule), Map());
    }
    return suggestions;
  }
);
