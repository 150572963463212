import { List } from 'immutable';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AddButton, SwitchButton } from '@alkem/react-ui-button';

import { isRetailer, isThirdParty } from 'core/api/user';
import { selectHasRuleSetsSelectionFeature } from 'modules/feature-flag/selectors';
import { RuleSetType } from 'modules/validation';
import {
  selectProductKeyId,
  selectProductVersionId,
} from 'reducers/productVersion';
import { selectUser } from 'reducers/user/selectors';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';
import { track } from 'utils/tracking';

import { DispatchType, UserImmutable } from '../../types';

import {
  addRecipient,
  addRuleSet,
  init,
  removeRecipient,
  removeRuleSet,
  setFilterRuleSetsFields,
} from './actions';
import { RuleSetIcon } from './components/rule-set-icon';
import { SelectMultiple } from './components/select-multiple';
import {
  selectFilterRuleSetsFields,
  selectIsLoading,
  selectPreselectedRecipients,
  selectPrimaryRecipients,
  selectRuleSets,
  selectSecondaryRecipients,
} from './selectors';
import './view-as.scss';

const headerLabels = {
  primary: i18n.t(
    'frontproductstream.view_as.recipients_select.header_visible',
    { defaultValue: 'Product visible by recipient' }
  ),
  secondary: i18n.t('frontproductstream.view_as.recipients_select.header_add', {
    defaultValue: 'Additional recipients',
  }),
  secondaryHidden: i18n.t(
    'frontproductstream.view_as.recipients_select.header_view_additional',
    { defaultValue: 'View additional recipients' }
  ),
  secondaryVisible: i18n.t(
    'frontproductstream.view_as.recipients_select.header_hide_additional',
    { defaultValue: 'Hide additional recipients' }
  ),
};

type ViewAsProps = {
  id: string;
};

export const ViewAs = React.memo(({ id }: ViewAsProps) => {
  const dispatch: DispatchType = useDispatch();
  const filterRuleSetsFields: boolean = useSelector(selectFilterRuleSetsFields);
  const preselectedRecipients: List<any> = useSelector(
    selectPreselectedRecipients
  );
  const productKeyId: number = useSelector(selectProductKeyId);
  const productVersionId: number = useSelector(selectProductVersionId);
  const primaryRecipients: List<any> = useSelector(selectPrimaryRecipients);
  const secondaryRecipients: List<any> = useSelector(selectSecondaryRecipients);
  const ruleSets: List<any> = useSelector(selectRuleSets);
  const showRuleSets: boolean = useSelector(selectHasRuleSetsSelectionFeature);
  const user: UserImmutable = useSelector(selectUser);
  const isLoading: boolean = useSelector(selectIsLoading);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(init());
  }, [dispatch, preselectedRecipients]);

  const selectedRecipients = useMemo(
    () =>
      primaryRecipients
        .concat(secondaryRecipients)
        .filter((recipient) => recipient.get('checked')),
    [primaryRecipients, secondaryRecipients]
  );

  const formatRuleSets = useMemo(
    () =>
      ruleSets.map((ruleSet) => {
        const deadline = ruleSet.get('deadline');
        const label = ruleSet.get('label');
        if (deadline) {
          const formattedDeadline = moment(deadline).format('LL');
          return ruleSet.set('label', `${label} (${formattedDeadline})`);
        }
        return ruleSet;
      }) as List<any>,
    [ruleSets]
  );

  const onSelectRecipient = (recipient, _, checked) => {
    const trackingProps = {
      category: 'product',
      action: 'product_filtered',
      retailer_id: recipient.get('id'),
      retailer_name: recipient.get('label'),
      label: `product_version#${productVersionId}`,
    };
    track({
      category: 'product-page-tracking',
      action: 'filter recipient',
    });
    if (checked) {
      dispatch(addRecipient(recipient, user));
      track({ ...trackingProps, selected: true });
    } else {
      dispatch(removeRecipient(recipient, user));
      track({ ...trackingProps, selected: false });
    }
  };

  const onSelectRuleSet = (ruleSet, _, checked) => {
    const trackingProps = {
      category: 'product',
      action: 'product_filtered',
      ruleset_id: ruleSet.get('id'),
      ruleset_name: ruleSet.get('label'),
      scenario: ruleSet.get('type') === RuleSetType.MATURITY,
      label: `product_version#${productVersionId}`,
    };
    track({
      category: 'product-page-tracking',
      action: 'filter category',
    });
    if (checked) {
      dispatch(addRuleSet(ruleSet, productVersionId, user));
      track({ ...trackingProps, selected: true });
    } else {
      dispatch(removeRuleSet(ruleSet, productVersionId, user));
      track({ ...trackingProps, selected: false });
    }
  };

  const onCheckOptionalField = (checked) => {
    dispatch(setFilterRuleSetsFields(!checked, user));
    track({
      category: 'product-page-tracking',
      action: 'hide optional fields',
      label: checked,
    });
    window.scrollTo(0, 0);
  };

  const onRedirectToPublicationSummary = () => {
    navigate(fill(routes.productDashboard, productKeyId));
  };

  const renderRuleSet = useCallback(
    (label, ruleSet) => {
      return (
        <div className="ViewAs__ruleSetItem">
          <RuleSetIcon
            ruleSet={ruleSet}
            user={user}
            selectedRecipients={selectedRecipients}
          />
          <span>{label}</span>
        </div>
      );
    },
    [user, selectedRecipients]
  );

  const showRecipients = !isRetailer(user) && !isThirdParty(user);

  if (!showRecipients && !showRuleSets) {
    return null;
  }

  return (
    <div className="ViewAs" data-testid="view-as">
      {showRecipients && (
        <div>
          <div className="ViewAs__title">
            {i18n.t('frontproductstream.select_recipients.select.label', {
              defaultValue: 'Recipients',
            })}
            <AddButton
              label={i18n.t(
                'frontproductstream.select_recipients.button.label',
                { defaultValue: 'Add recipients' }
              )}
              onClick={onRedirectToPublicationSummary}
            />
          </div>
          <SelectMultiple
            id={`${id}--SelectMultiple`}
            onSelect={onSelectRecipient}
            options={primaryRecipients}
            secondaryOptions={secondaryRecipients}
            placeholder={i18n.t(
              'frontproductstream.view_as.recipients_select.select.placeholder',
              { defaultValue: 'Start typing to view as a recipient' }
            )}
            pluralLabel={i18n.t(
              'frontproductstream.view_as.recipients_select.plural_placeholder',
              { defaultValue: i18n.t('recipients') }
            )}
            headers={headerLabels}
            isLoading={isLoading}
            loadingMessage={i18n.t(
              'frontproductstream.view_as.recipients_select.loading_message',
              { defaultValue: 'Loading your recipients...' }
            )}
          />
        </div>
      )}

      {showRuleSets && (
        <div className="ViewAs__ruleSets">
          <div className="ViewAs__title">
            {i18n.t('frontproductstream.view_as.rulesets_select.label', {
              defaultValue: 'Filter by required field category',
            })}
          </div>
          <SelectMultiple
            id={`${id}__RuleSets--SelectMultiple`}
            onSelect={onSelectRuleSet}
            options={formatRuleSets}
            placeholder={i18n.t(
              'frontproductstream.view_as.rulesets_select.placeholder',
              { defaultValue: 'Select categories' }
            )}
            pluralLabel={i18n.t(
              'frontproductstream.view_as.rulesets_select.plural_placeholder',
              { defaultValue: 'categories' }
            )}
            renderItem={renderRuleSet}
          />
          <div className="ViewAs__ruleSets__switch">
            <SwitchButton
              content={i18n.t(
                'frontproductstream.view_as.additional_fields_switch_button.label',
                {
                  defaultValue:
                    'Show optional fields to market more efficiently your product',
                }
              )}
              onChange={onCheckOptionalField}
              checked={!filterRuleSetsFields}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default ViewAs;
