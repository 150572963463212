import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import KindAutocomplete from 'components/ui/autocomplete/kind';
import Field from 'components/ui/form/field';
import { isRealtimeKindSuggestion } from 'components/ui/form/plugins/validator/utils';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_KIND_FULL_TREE } from 'modules/feature-flag/constants';
import { selectValidationResultsByEntity } from 'modules/validation';
import { selectDefaultLocale } from 'reducers/user/selectors';
import { get } from 'utils/immutable';

const hasFeatureSelector = (state) =>
  hasFeature(state.user, FEATURE_KIND_FULL_TREE);

const mapStateToProps = createStructuredSelector({
  hasKindFullTree: hasFeatureSelector,
  locale: selectDefaultLocale,
  ruleResults: selectValidationResultsByEntity,
});

export class FormKindAutocomplete extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    hasKindFullTree: PropTypes.bool.isRequired,
    locale: PropTypes.object,
    ruleResults: PropTypes.object.isRequired,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    entityKind: PropTypes.string.isRequired,
  });

  getSuggestions() {
    const { ruleResults, entityKind, entityId } = this.props;
    const rulesMap = ruleResults
      // find all results relevant to current (entityKind, entityId)
      .filter(
        (results) =>
          results.getIn(['entity', '_type']) === entityKind &&
          results.getIn(['entity', 'id']) === entityId
      )
      // merge all results into one map of rules for this (entityKind, entityId)
      .reduce((all, results) => all.merge(results.get('rules')));
    let kindMLSuggestions = null;
    if (rulesMap && rulesMap.size > 0) {
      kindMLSuggestions = rulesMap
        .get('rules', [])
        .filter((rule) => isRealtimeKindSuggestion(rule))
        .valueSeq()
        .map((rule) => ({
          id: rule.getIn(['data', 'suggestedValue', 'code']),
          label: rule.getIn(['data', 'suggestedValue', 'label']),
        }));
    }
    return kindMLSuggestions;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // We override this method because we are checking the id within the value.
    return (
      get(nextProps, ['value', 'id']) !== get(this.props, ['value', 'id']) ||
      super.shouldComponentUpdate(nextProps, nextState)
    );
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue(data) {
    return data.value || null;
  }

  render() {
    const { value, params, hasKindFullTree } = this.props;

    if (!this.isVisible()) {
      return null;
    }

    // Extra params.
    const localParams = { ...params };
    if (hasKindFullTree) {
      localParams.all_entities = true;
    }
    const suggestions = this.getSuggestions();
    return (
      <div
        id="FormField-kind"
        className={classNames(
          this.getClasses({ FormKindAutocomplete: true, row: true })
        )}
      >
        <div className="col-xs-12">
          <KindAutocomplete
            inputId={this.getId()}
            value={value}
            onChange={this.handleChange}
            isReadOnly={this.isReadOnly()}
            params={localParams}
            suggestedKinds={suggestions ? suggestions.toJS() : suggestions}
          />
          {this.renderPlugins({ offset: true })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormKindAutocomplete);
