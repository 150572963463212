import { isFunction, isString } from 'lodash';
import PropTypes from 'prop-types';

import i18n from 'utils/i18n';

const AUTOCOMPLETE_ADD_OPTION_KEY = 'autocomplete-add-option';

export default function Addable(superClass) {
  return class AddableAutocomplete extends superClass {
    static propTypes = Object.assign({}, superClass.propTypes, {
      onAdd: PropTypes.func,
      addMissingOptionLabel: PropTypes.node,
      formatMissingOptionLabel: PropTypes.func,
      addDisabled: PropTypes.bool,
      shouldAdd: PropTypes.func,
    });

    static defaultProps = Object.assign({}, superClass.defaultProps, {
      addMissingOptionLabel: i18n.t(
        'frontproductstream.autocomplete.add_option.label',
        { defaultValue: 'Add' }
      ),
      addDisabled: false,
      shouldAdd: () => false,
    });

    formatMissingLabel() {
      const { formatMissingOptionLabel, addMissingOptionLabel } = this.props;
      const { search } = this.state;
      if (formatMissingOptionLabel) {
        return formatMissingOptionLabel(search);
      }
      return (
        <span>
          <em>{addMissingOptionLabel}</em> "{search}"
        </span>
      );
    }

    onSelect(data) {
      const { onAdd, shouldAdd } = this.props;
      if (
        (data.key === AUTOCOMPLETE_ADD_OPTION_KEY ||
          (shouldAdd && isFunction(shouldAdd) && shouldAdd(data))) &&
        onAdd &&
        isFunction(onAdd)
      ) {
        onAdd(data.value?.search, data);
      } else {
        super.onSelect(data);
      }
      this.setState({ search: null });
    }

    search(currentValue) {
      const promise = super.search(currentValue);
      if (promise) {
        promise.then(() => {
          this.setState({ search: currentValue });
        });
      }
    }

    formatList(list) {
      return this.withAddMissingOption(super.formatList(list));
    }

    withAddMissingOption(options) {
      const { search } = this.state;
      const { addDisabled } = this.props;

      if (addDisabled === true) {
        return options;
      }

      if (
        isString(search) &&
        search.length > 0 &&
        this.hasNoExactMatch(search, options)
      ) {
        return [
          ...options,
          {
            key: AUTOCOMPLETE_ADD_OPTION_KEY,
            value: { search },
            label: this.formatMissingLabel(),
          },
        ];
      }
      return options;
    }

    hasNoExactMatch(search, list) {
      if (!isString(search)) {
        return true;
      }
      const _search = search.trim().toLowerCase();
      return !list.some(({ label }) => label.toLowerCase() === _search);
    }
  };
}
