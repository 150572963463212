import { List } from 'immutable';
import { memo } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import { HistoryDiffLine } from 'modules/history';
import { DiffImmutable } from 'types/diff';
import { i18n } from 'utils/i18n';

import { DataOpsPatch, DataOpsPatchAmendingStatus } from '../../types';

import './diff-lines.scss';

interface Props {
  patch: DataOpsPatch;
  diffList: List<DiffImmutable>;
  amendingStatus?: DataOpsPatchAmendingStatus;
  disabled?: boolean;
  onAcceptPatch: (patches: DataOpsPatch[]) => void;
  onRefusePatch: (patches: DataOpsPatch[]) => void;
  isDataOpsPatcher?: boolean;
  isDataOpsReceiver?: boolean;
}

export const DataOpsDiffBlockLines = memo(function DataOpsDiffBlockLines({
  patch,
  diffList,
  amendingStatus,
  disabled,
  onRefusePatch,
  onAcceptPatch,
  isDataOpsPatcher,
}: Props) {
  const { isAccepting, isRefusing } = amendingStatus || {};
  const isRequesting = isAccepting || isRefusing;
  const isDisabled = disabled || isRequesting;
  // this trick is meant for the few cases when in complex fields, the model (path) of a diff is not unique
  const diffKeys = {};
  return (
    <tr
      key={`${patch.productKeyId}-${patch.fieldName}`}
      className="DataOpsDiffBlockLines__patchDiffsRow"
      data-model={patch.fieldName}
      data-product-key={patch.productKeyId}
    >
      <td className="DataOpsDiffBlockLines__patchDiffsCol">
        {diffList.map((diff, index) => {
          if (diff) {
            const model = diff.get('model');
            const key = model in diffKeys ? `${model}-${index}` : model;
            diffKeys[key] = true;
            return (
              <HistoryDiffLine
                key={key}
                diff={diff}
                withCurrent={isDataOpsPatcher}
                notHidden
                currentPosition="last"
              />
            );
          }
          return null;
        })}
      </td>
      <td className="DataOpsDiffBlockLines__patchActionsCol">
        <div className="alk-flex alk-flex-center">
          <div className="Spinner--small">
            {isRequesting && <Spinner small />}
          </div>
          <Button
            testid="refuse-patch"
            onClick={() => {
              if (!isDisabled) {
                onRefusePatch([patch]);
              }
            }}
            disabled={isDisabled}
            link
          >
            {i18n.t(
              'frontproductstream.product_collaborative_edit.diff_block_table_row_reject_a_patch.button',
              { defaultValue: 'Refuse' }
            )}
          </Button>
          <Button
            testid="accept-patch"
            onClick={() => {
              if (!isDisabled) {
                onAcceptPatch([patch]);
              }
            }}
            disabled={isDisabled}
            secondary
          >
            {i18n.t(
              'frontproductstream.product_collaborative_edit.diff_block_table_row_accept_a_patch.button',
              { defaultValue: 'Accept' }
            )}
          </Button>
        </div>
      </td>
    </tr>
  );
});
