// MODAL
export const OPEN_TARGET_REQUEST_MODAL = 'OPEN_TARGET_REQUEST_MODAL';
export const CLOSE_TARGET_REQUEST_MODAL = 'CLOSE_TARGET_REQUEST_MODAL';

// FORM

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';

export const ADD_EMAIL = 'ADD_EMAIL';
export const REMOVE_EMAIL = 'REMOVE_EMAIL';

export const SELECT_RECIPIENT = 'SELECT_RECIPIENT';
export const UNSELECT_RECIPIENT = 'UNSELECT_RECIPIENT';

export const TOGGLE_FORCE_ASSIGNATION = 'TOGGLE_FORCE_ASSIGNATION';

// SAGAS

export const SEND_ASSIGNATION = 'SEND_ASSIGNATION';
export const CANCEL_SEND_ASSIGNATION = 'CANCEL_SEND_ASSIGNATION';

export const START_SENDING_ASSIGNATION = 'START_SENDING_ASSIGNATION';
export const STOP_SENDING_ASSIGNATION = 'STOP_SENDING_ASSIGNATION';

// STORE

export const TARGET_REQUEST_MODAL_STORE_KEY = 'targetRequestModal';
