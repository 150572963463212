import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
  persistFilteredFiltersState,
  persistFiltersCollapsedState,
  persistFiltersPaginationState,
  persistOnlyDuplicatedProducts,
  persistWithArchivedProducts,
} from 'core/modules/list/sagas/filters-config';
import { persistItemsPerPage } from 'core/modules/list/sagas/pagination';
import { logError } from 'utils/logging';

import {
  COLLAPSE_FILTERS,
  EXPORT_REPORT,
  FETCH_PRODUCTS_AGGREGATIONS,
  FETCH_REPORT,
  REDIRECT_TO_CATALOG,
  UPDATE_FILTERED_FILTERS,
  UPDATE_FILTERS_PAGINATION,
  UPDATE_ITEMS_PER_PAGE,
  UPDATE_ONLY_DUPLICATED_PRODUCTS,
  UPDATE_SELECTED_FILTERS,
  UPDATE_WITH_ARCHIVED_PRODUCTS,
} from '../actions/types';
import { FILTERS_CONFIG_KEY, ITEMS_PER_PAGE_KEY } from '../constants';

import { fetchProductsAggregationsSaga } from './products';
import { redirectToCatalogSaga } from './redirect';
import { exportReportSaga, fetchReportSaga } from './reports';

export default function* reportingGenericMainSaga() {
  try {
    yield takeLatest(
      [
        FETCH_REPORT,
        UPDATE_SELECTED_FILTERS,
        UPDATE_WITH_ARCHIVED_PRODUCTS,
        UPDATE_ONLY_DUPLICATED_PRODUCTS,
      ],
      fetchReportSaga
    );
    yield takeEvery(FETCH_PRODUCTS_AGGREGATIONS, fetchProductsAggregationsSaga);
    yield takeEvery(REDIRECT_TO_CATALOG, redirectToCatalogSaga);
    yield takeLatest(
      COLLAPSE_FILTERS,
      persistFiltersCollapsedState,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_FILTERED_FILTERS,
      persistFilteredFiltersState,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_FILTERS_PAGINATION,
      persistFiltersPaginationState,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_ITEMS_PER_PAGE,
      persistItemsPerPage,
      ITEMS_PER_PAGE_KEY
    );
    yield takeLatest(
      UPDATE_WITH_ARCHIVED_PRODUCTS,
      persistWithArchivedProducts,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(
      UPDATE_ONLY_DUPLICATED_PRODUCTS,
      persistOnlyDuplicatedProducts,
      FILTERS_CONFIG_KEY
    );
    yield takeLatest(EXPORT_REPORT, exportReportSaga);
  } catch (err) {
    logError(err);
  }
}
