import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { canSetExportable } from 'core/api/retailerproductversion';
import { canValidateProduct, isRetailer } from 'core/api/user';
import BulkValidate from 'modules/bulk-validate';
import { showBulkValidate } from 'modules/bulk-validate/actions';
import { FEATURE_PERMISSIONS_V3_PRODUCT } from 'modules/feature-flag/constants';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  PRODUCT_PERMISSION,
  VALIDATE_PERMISSION,
} from 'modules/permissions/const';
import i18n from 'utils/i18n';

import '../index.scss';

const mapDispatchToProps = {
  showBulkValidate,
};

const getSelectedProductsMap = (productMap, selectedMap) =>
  productMap.filter((pv) => selectedMap.has(pv.get('id')));

class SetAsExportableAction extends PureComponent {
  static propTypes = {
    showBulkValidate: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    isRetailer(user) &&
    (hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)
      ? hasPermissionModule(user, PRODUCT_PERMISSION, VALIDATE_PERMISSION)
      : canValidateProduct(user));

  static isEnabled = ({ productMap, selectedMap }) =>
    !selectedMap?.isEmpty() &&
    getSelectedProductsMap(productMap, selectedMap).some(canSetExportable);

  setExportable = () => {
    const { productMap, selectedMap, disabled } = this.props;
    if (disabled) {
      return;
    }
    this.props.showBulkValidate(
      getSelectedProductsMap(productMap, selectedMap)
    );
  };

  render() {
    const { productMap, selectedMap } = this.props;

    const selectPVs = getSelectedProductsMap(productMap, selectedMap);

    return (
      <>
        <div className="ActionOption" onClick={this.setExportable}>
          {i18n.t('Set {{count}} sheet(s) as exportable', {
            count: selectPVs.size,
          })}
        </div>
        <BulkValidate />
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(SetAsExportableAction);
