import classNames from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { LazyTooltip, TooltipProps } from '@alkem/react-ui-tooltip';

import './link.scss';

interface Props {
  to: string;
  id?: string;
  children?: ReactNode;
  icon?: string;
  className?: string;
  secondary?: boolean;
  withBorder?: boolean;
  tip?: ReactNode;
  tipPlace?: TooltipProps['place'];
}

export function LinkButton({
  to,
  id,
  children,
  icon,
  className,
  secondary,
  withBorder,
  tip,
  tipPlace,
}: Props) {
  const link = (
    <Link
      to={to}
      id={id}
      className={classNames(
        'LinkButton',
        'alk-flex',
        'alk-flex-center',
        className,
        secondary && 'LinkButton--secondary',
        withBorder && 'LinkButton--border'
      )}
    >
      {icon && <i className={`LinkButton__icon mdi mdi-${icon}`} />}
      {children}
    </Link>
  );
  if (tip) {
    return (
      <LazyTooltip
        id={`link-button-tip-${id || uuid()}`}
        tooltipLabel={tip}
        place={tipPlace}
        block
      >
        {link}
      </LazyTooltip>
    );
  }
  return link;
}

export default LinkButton;
