import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import { RECEIVE_SEGMENT_LIST } from 'constants/events/segment';
import segmentHierarchyApi from 'resources/segmentHierarchyApi';

export const getProductSegments =
  (queries, useCache = true) =>
  (dispatch) => {
    dispatch({ type: START_LOADING });
    return segmentHierarchyApi
      .list({
        queries,
        useCache,
      })
      .then(
        (response) => {
          const list = response.data.data;
          dispatch({ type: RECEIVE_SEGMENT_LIST, list });
          dispatch({ type: STOP_LOADING });
        },
        () => dispatch({ type: STOP_LOADING })
      );
  };
