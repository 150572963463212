import classNames from 'classnames';
import Immutable from 'immutable';
import { identity } from 'lodash/fp';
import { memo, useMemo } from 'react';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { ProgressBar } from '@alkem/react-ui-progress';

import {
  isKO,
  isSuggestionDisplayedAsWarning,
} from 'components/ui/form/plugins/validator/utils';
import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
  SHARING_UNIT,
} from 'constants/fields';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import { RuleApplicationStatus, ruleEntities } from '../../constants';
import ProgressByLanguage from '../progress-by-language';

import './results.scss';

interface Props {
  availableFields?: Immutable.Map<string, any>;
  availableLanguages?: any[];
  byLanguage?: boolean;
  currentLanguage?: any;
  hidePrefix?: boolean;
  onLanguageSelection?: (...args: any[]) => any;
  results?: Immutable.Map<string, any>;
  selectedRecipients?: any;
}

export const ValidationResults = memo(
  ({
    availableFields = Immutable.Map(),
    availableLanguages,
    byLanguage,
    currentLanguage,
    hidePrefix = false,
    onLanguageSelection = () => {},
    results,
    selectedRecipients,
  }: Props) => {
    const warningSuggestions: number = useMemo<number>(() => {
      const languageCode = currentLanguage ? currentLanguage.code : null;

      if (!results) {
        return 0;
      }

      const filteredFields = {
        [ruleEntities.CONSUMER_UNIT]: get(availableFields, [CONSUMER_UNIT]),
        [ruleEntities.LOGISTICAL_UNIT]: get(availableFields, [LOGISTICAL_UNIT]),
        [ruleEntities.DISPLAY_UNIT]: get(availableFields, [DISPLAY_UNIT]),
      };
      const rules: Immutable.List<any> =
        results.get('rules') || Immutable.List();

      return rules.filter((rule: Immutable.Map<string, any>) => {
        if (
          !isSuggestionDisplayedAsWarning(rule) ||
          !isKO(rule, languageCode)
        ) {
          return false;
        }

        // Handle sharing unit fields (recipient specific filtering)
        const ruleEntityType = rule.get('entityType');
        const ruleField = rule.get('root_field');

        if (ruleEntityType === ruleEntities.SHARING_UNIT) {
          const suFieldsPerRecipient = availableFields.get(
            SHARING_UNIT,
            Immutable.Map()
          );
          if (!suFieldsPerRecipient.size) {
            return true;
          }

          const resultPerRecipient: Immutable.Map<number, any> =
            rule.getIn(['status_by_recipients', 'specific']) || Immutable.Map();
          if (!resultPerRecipient || !resultPerRecipient.size) {
            return false;
          }

          return resultPerRecipient
            .map(
              (result: Immutable.Map<string, any>, recipientId) =>
                result.get('status') === RuleApplicationStatus.KO &&
                availableFields
                  .get(SHARING_UNIT, Immutable.Map())
                  .get(recipientId, Immutable.List())
                  .contains(ruleField)
            )
            .some(identity);
        }

        // Other entities filtering
        const fields = filteredFields[ruleEntityType] || Immutable.List();
        return !fields.size || fields.contains(ruleField);
      }).size;
    }, [availableFields, currentLanguage, results]);

    const staticWarning: number = get(results, ['staticWarning']) ?? 0;
    const warnings: number = warningSuggestions + staticWarning;
    const successful: number = get(results, ['successful']) ?? 0;
    const failed: number = get(results, ['failed']) ?? 0;
    const ruleSets: Immutable.List<any> = get(results, ['ruleSets']);

    const notRequestedFailed: number = useMemo<number>(
      () =>
        ruleSets
          ? ruleSets.reduce<number>((count, ruleSet) => {
              if (
                ruleSet.get('type') === 'MATURITY' &&
                ruleSet.get('requested') === false
              ) {
                const ruleFailed = ruleSet.get('failed') ?? 0;
                return count + ruleFailed;
              }
              return count;
            }, 0)
          : 0,
      [ruleSets]
    );

    // If no rules were found, this is empty.
    const hasRules: boolean = successful + failed > 0;

    const renderSelectedRecipients = () => {
      if (!selectedRecipients || !selectedRecipients.size) {
        return null;
      }
      let text: string;
      if (selectedRecipients.size === 1) {
        text = selectedRecipients.first().get('label');
      } else {
        text = i18n.t(
          'frontproductstream.product_page_footer.validation_summary_selected_retailers_count.text',
          {
            count: selectedRecipients.size,
            defaultValue: '{{count}} selected retailers',
          }
        );
      }
      return (
        <Ellitips id="product-footer-validation-recipients" label={text} />
      );
    };

    const renderWarnings = () => {
      if (!warnings) {
        return null;
      }
      const msg = i18n.t(
        'frontproductstream.product_page_footer.validation_summary_suggestions_count.text',
        {
          count: warnings,
          defaultValue: '{{count}} suggestion',
        }
      );
      return (
        <div className="ValidationResults__warnings">
          <i className="mdi mdi-alert ValidationResults__warningIcon" />
          <span data-value={warnings}>{msg}</span>
        </div>
      );
    };

    if (!byLanguage && !hasRules) {
      return (
        <div className="ValidationResults">
          <div className="ValidationResults--empty">
            {i18n.t(
              'frontproductstream.product_page_footer.validation_summary_zero_rules_to_apply.text',
              { defaultValue: 'No applicable quality checks found' }
            )}
            {!hidePrefix && renderSelectedRecipients()}
          </div>
          {renderWarnings()}
        </div>
      );
    }

    return (
      <div
        className={classNames(
          'ValidationResults',
          'ValidationResults--grow',
          warnings === 0 && 'ValidationResults--noWarnings'
        )}
      >
        {!hidePrefix && (
          <div className="ValidationResults__prefix">
            <span className="ValidationResults__prefixLabel">
              {i18n.t(
                'frontproductstream.product_page_footer.data_quality_progress_bar.title',
                { defaultValue: 'Mandatory fields' }
              )}
            </span>
            {renderSelectedRecipients()}
          </div>
        )}
        <div
          className={classNames(
            'ValidationResults__progressBar',
            notRequestedFailed > 0 &&
              notRequestedFailed === failed &&
              'ValidationResults__progressBar--notRequested'
          )}
        >
          {byLanguage ? (
            <ProgressByLanguage
              values={
                get(results, ['required', 'by_language']) || Immutable.Map()
              }
              languages={availableLanguages}
              selected={currentLanguage}
              onLanguageClick={onLanguageSelection}
            />
          ) : (
            <ProgressBar
              value={successful}
              max={successful + failed}
              color={failed > 0 ? 'danger' : 'default'}
            />
          )}
        </div>
        {renderWarnings()}
      </div>
    );
  }
);
