import { Moment } from 'moment';

import { getTypePackagingCode } from 'constants/typePackaging';
import { allPrices } from 'modules/price-waterfalls/constants';
import { get } from 'utils/immutable';

import { ClosedRfps } from './components/list/answersStatusModal';

export const extractFilterValues = (selectedFilterMap, key) => {
  let data = [];

  // Answer statuses
  const selectedAnswersStatuses = get(selectedFilterMap, key);

  if (selectedAnswersStatuses) {
    data = selectedAnswersStatuses.keySeq().toArray();
  }
  return data;
};

export const getProductType = (answer) => {
  if (get(answer, 'product.version.isDisplayUnit')) {
    return 'DISPLAY_UNIT';
  }
  return getTypePackagingCode(get(answer, 'product.version.typePackaging'));
};

export const translatePriceWaterfall = (data) => {
  const priceLabelsById: { [x: number]: string } = allPrices.reduce(
    (acc, p) => {
      return { ...acc, ...{ [p.id]: p.label } };
    },
    {}
  );
  return data.reduce((acc, c) => {
    if (c.code === 'FLATTENED_PRICE_WATERFALLS') {
      acc.push({ ...c, label: get(priceLabelsById, c.id) });
    } else {
      acc.push(c);
    }
    return acc;
  }, []);
};

export const getRfpsList = (
  datesToUpdate: { [key: number]: Moment },
  closedRfps: { [key: number]: ClosedRfps }
) => {
  const rfps: {
    id: number;
    businessUnitId: number;
    supplierId: number;
    limitDate: Moment;
  }[] = Object.entries(datesToUpdate)
    .filter(([rfpId]) => closedRfps[rfpId])
    .map(([rfpId, date]) => ({
      id: parseInt(rfpId),
      businessUnitId: closedRfps[rfpId].businessUnitId,
      supplierId: closedRfps[rfpId].supplierId,
      limitDate: date,
    }));
  return rfps;
};
