import { List } from 'immutable';
import { cloneDeep, set } from 'lodash';
import { createReducer } from 'redux-create-reducer';

import { ENTITY_TYPE_PRODUCTVERSION_BULKEDIT } from 'constants/entities';
import { UPDATE_ENTITY } from 'constants/events/entity';
import { updateReferenceOfPath } from 'utils/reducers/entity';

import {
  CLOSE_PRODUCT_VERSION_BULK_EDIT,
  OPEN_PRODUCT_VERSION_BULK_EDIT,
  PRODUCT_VERSION_BULK_EDIT_CANCEL_SAVE,
  PRODUCT_VERSION_BULK_EDIT_COMPLETE,
  PRODUCT_VERSION_BULK_EDIT_DELETE_DISPLAY_GROUP,
  PRODUCT_VERSION_BULK_EDIT_DISPLAY_GROUP_GET,
  PRODUCT_VERSION_BULK_EDIT_INIT,
  PRODUCT_VERSION_BULK_EDIT_PROGRESS,
  PRODUCT_VERSION_BULK_EDIT_SAVE,
  PRODUCT_VERSION_BULK_EDIT_SELECT_DISPLAY_GROUP,
  RESET_PRODUCT_VERSION_BULK_EDIT,
} from '../constants/events';

const initialState = {
  entity: {},
  displayGroups: [],
  selectedDisplayGroups: [],
  saving: false,
  errors: [],
  savedCount: 0,
  totalCount: 0,
  isOpen: false,
  productVersions: List(),
};

export default createReducer(initialState, {
  [UPDATE_ENTITY]: (state, action) => {
    if (action.entityKind === ENTITY_TYPE_PRODUCTVERSION_BULKEDIT) {
      const newState = { ...state };
      newState.entity = { ...newState.entity };

      // Update content and path
      newState.entity = updateReferenceOfPath(newState.entity, action.key);

      set(newState.entity, action.key, cloneDeep(action.value));
      return newState;
    }
    return state;
  },
  [PRODUCT_VERSION_BULK_EDIT_INIT]: (state) => {
    const newState = { ...state };
    newState.entity = {};
    newState.selectedDisplayGroups = [];
    newState.saving = false;
    newState.errors = [];
    newState.savedCount = 0;
    newState.totalCount = 0;
    return newState;
  },
  [PRODUCT_VERSION_BULK_EDIT_DISPLAY_GROUP_GET]: (state, action) => {
    const newState = { ...state };
    newState.displayGroups = action.data;
    return newState;
  },
  [PRODUCT_VERSION_BULK_EDIT_SELECT_DISPLAY_GROUP]: (state, action) => {
    const newState = { ...state };
    newState.selectedDisplayGroups = [
      ...newState.selectedDisplayGroups,
      action.displayGroup,
    ];
    return newState;
  },
  [PRODUCT_VERSION_BULK_EDIT_DELETE_DISPLAY_GROUP]: (state, action) => {
    const newState = { ...state };
    newState.selectedDisplayGroups = newState.selectedDisplayGroups.filter(
      (dg) => dg.id !== action.displayGroupId
    );
    return newState;
  },
  [PRODUCT_VERSION_BULK_EDIT_SAVE]: (state, action) => {
    const newState = { ...state };
    newState.saving = true;
    newState.errors = [];
    newState.savedCount = 0;
    newState.totalCount = action.count;
    return newState;
  },
  [PRODUCT_VERSION_BULK_EDIT_CANCEL_SAVE]: (state) => {
    const newState = { ...state };
    newState.saving = false;
    newState.errors = [];
    newState.savedCount = 0;
    return newState;
  },
  [PRODUCT_VERSION_BULK_EDIT_PROGRESS]: (state, action) => {
    const newState = { ...state };
    newState.savedCount += 1;
    if (action.error) {
      newState.errors.push(action.error);
    }
    return newState;
  },
  [PRODUCT_VERSION_BULK_EDIT_COMPLETE]: (state) => {
    const newState = { ...state };
    newState.saving = false;
    newState.savedCount = newState.totalCount;
    return newState;
  },
  [OPEN_PRODUCT_VERSION_BULK_EDIT]: (state, { payload }) => {
    const newState = { ...state, isOpen: true };
    if (List.isList(payload)) {
      newState.productVersions = payload;
    }
    return newState;
  },
  [CLOSE_PRODUCT_VERSION_BULK_EDIT]: (state) => ({ ...state, isOpen: false }),
  [RESET_PRODUCT_VERSION_BULK_EDIT]: () => initialState,
});
