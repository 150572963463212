import { createAction } from 'utils/redux';

import { ReminderData } from '../types';

import {
  CLOSE_BULK_MESSAGE_MODAL,
  SEND_BULK_MESSAGE,
  SHOW_BULK_MESSAGE_MODAL,
} from './types';

export const showBulkMessageModal = createAction(SHOW_BULK_MESSAGE_MODAL);
export const closeBulkMessageModal = createAction(CLOSE_BULK_MESSAGE_MODAL);

export const sendBulkMessage = (
  formattedInfoBulkMessage: ReminderData,
  message: string
) => ({
  type: SEND_BULK_MESSAGE,
  formattedInfoBulkMessage,
  message,
});
