import PropTypes from 'prop-types';
import processString from 'react-process-string';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import TurboSelect from '@alkem/react-ui-turbo-select';

import i18n from 'utils/i18n';

import './recipientsSelector.scss';

const mapStateToProps = createStructuredSelector({
  retailers: (state) =>
    state.recipients
      .get('list')
      .filter((recipient) => recipient.get('listing')),
});

function RecipientsSelector(props) {
  const onSelect = (selectedRecipient) => {
    props.onSelectRetailer(selectedRecipient);
  };

  let availableRecipients = [];
  for (let recipient of props.retailers) {
    availableRecipients.push({
      label: recipient.get('nameLegal'),
      value: recipient.get('id'),
    });
  }

  const selectedProducts = processString([
    {
      regex: /__N__/,
      fn: () => (
        <span className="RecipientsSelector__productsNumber">
          {props.selectedProductsNumber}
        </span>
      ),
    },
  ])(i18n.t('Selected products: {{n}}', { n: '__N__' }));

  return (
    <div className="RecipientsSelector">
      <div>
        <p>{selectedProducts}</p>
        <p className="RecipientsSelector__helpMessage">
          {i18n.t(
            'Select a retailer for whom you will create a new listing. Note: You can only create a listing for products that already have at least one logistical hierarchy defined.'
          )}
        </p>
        <div className="RecipientsSelector__dropdown">
          <TurboSelect
            id="recipients-selector"
            placeholder={i18n.t('Select a retailer')}
            noOptionsMessage={() => i18n.t('No available option')}
            options={availableRecipients}
            onChange={onSelect}
            value={props.selectedRetailer}
            isSearchable={true}
            isClearable={false}
            getOptionLabel={(recipient) => recipient.label}
            getOptionValue={(recipient) => recipient.value}
          />
        </div>
      </div>
    </div>
  );
}

RecipientsSelector.propTypes = {
  onSelectRetailer: PropTypes.func.isRequired,
  retailers: PropTypes.object.isRequired,
  selectedRetailer: PropTypes.object,
  selectedProductsNumber: PropTypes.number,
};

export default connect(mapStateToProps)(RecipientsSelector);
