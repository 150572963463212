import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import { notificationSuccess } from 'actions/notification';
import { MessageIdToMessageInfo, NoMessages } from 'constants/message';
import {
  getIsEligibleForSourcing,
  getProductGTIN,
} from 'core/api/productversion';
import { isRetailer, isSuperAdmin } from 'core/api/user';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { track } from 'utils/tracking';

import { SOURCING } from '../../../../../constants/context';
import ActionHpp from '../action-hpp';
import ActionMessage from '../action-message';

import './index.scss';

const mapDispatchToProps = {
  notifySuccess: notificationSuccess,
};

class SourcingActionsColumn extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    productVersion: ImmutablePropTypes.map.isRequired,
    notifySuccess: PropTypes.func.isRequired,
  };

  onNotifyNotEligible = () => {
    const { productVersion, notifySuccess } = this.props;
    notifySuccess(
      i18n.t(
        'You will get notified as soon as the product is available on the network'
      )
    );
    track({
      category: 'sourcing',
      action: 'sourcing_notify_not_eligible',
      reference: getProductGTIN(productVersion),
    });
  };

  displayMessage() {
    const { productVersion, user } = this.props;
    if (!isRetailer(user)) {
      return null;
    }
    if (!getIsEligibleForSourcing(productVersion) && !isSuperAdmin(user)) {
      return null;
    }
    const productVersionId = get(productVersion, 'id');
    const ownerId = get(productVersion, ['owner', 'id']);
    return (
      <ActionMessage preSelectedOrganizationId={ownerId}>
        <Button className="CatalogSourcingActionsButton">
          <LazyTooltip
            id={`${productVersionId}_comment`}
            tooltipLabel={`${MessageIdToMessageInfo[NoMessages.id].name}`}
          >
            <i
              className={`mdi ${
                MessageIdToMessageInfo[NoMessages.id].icon
              } SourcingMessageColumn SourcingMessageColumn--${
                MessageIdToMessageInfo[NoMessages.id].id
              }`}
            />
          </LazyTooltip>
        </Button>
      </ActionMessage>
    );
  }

  displaySeeHpp() {
    const { productVersion, user } = this.props;
    const productVersionId = get(productVersion, 'id');
    if (!getIsEligibleForSourcing(productVersion) && !isSuperAdmin(user)) {
      return null;
    }
    return (
      <ActionHpp productVersion={productVersion} user={user}>
        <Button className="CatalogSourcingActionsButton CatalogSourcingActionsButton--hpp">
          <LazyTooltip
            id={`${productVersionId}_hpp`}
            tooltipLabel={i18n.t('View HPP')}
          >
            <i className="mdi mdi-eye" />
          </LazyTooltip>
        </Button>
      </ActionHpp>
    );
  }

  displayNotEligible() {
    const { productVersion, user } = this.props;
    if (getIsEligibleForSourcing(productVersion) || isSuperAdmin(user)) {
      return null;
    }
    const productVersionId = get(productVersion, 'id');
    return (
      <Button
        className="CatalogSourcingActionsButton CatalogSourcingActionsButton--hpp"
        onClick={this.onNotifyNotEligible}
      >
        <LazyTooltip
          id={`${productVersionId}_hpp`}
          tooltipLabel={i18n.t(
            'Get notified when product is available in the product network'
          )}
        >
          <i className="mdi mdi-bell" />
        </LazyTooltip>
      </Button>
    );
  }

  render() {
    return (
      <td className="CatalogSourcingActionColumn">
        <div
          className="CatalogSourcingActionColumn__content"
          style={{
            width: `${SOURCING.size.action.width}px`,
            height: `${SOURCING.size.row.height}px`,
          }}
        >
          {this.displaySeeHpp()}
          {this.displayMessage()}
          {this.displayNotEligible()}
        </div>
      </td>
    );
  }
}

export default connect(null, mapDispatchToProps)(SourcingActionsColumn);
