import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';
import { Authentication } from '@alkem/sdk-dashboard';

import HppModal from 'components/hpp/modal';
import { selectHasHpp } from 'modules/feature-flag/selectors';
import {
  selectCurrentLanguage,
  selectProductId,
  selectProductVersionId,
} from 'reducers/productVersion';
import { getHPPSrc } from 'utils/hpp';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import { SUPPORTED_LANGUAGES } from './constants';

const isLanguageSupported = (currentLanguage) =>
  Object.keys(SUPPORTED_LANGUAGES).includes(currentLanguage.normalizedCode);

const togglePreviewModal = (isOpen, setIsOpen) => {
  track({
    category: 'product-page-tracking',
    action: 'preview (HPP)',
  });
  setIsOpen(!isOpen);
};

export const PreviewModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage: any = useSelector(selectCurrentLanguage);
  const hasHppFeature: boolean = useSelector(selectHasHpp);
  const productId: number = useSelector(selectProductId);
  const productVersionId: number = useSelector(selectProductVersionId);

  if (!(currentLanguage && hasHppFeature)) {
    return null;
  }

  const token = Authentication.getAccessTokenByPriority();
  const language = (currentLanguage as any).normalizedCode;
  const hppSrc = getHPPSrc(productId, productVersionId, language, token);

  return (
    <>
      <span data-tip data-for={`previewButton-${productVersionId}`}>
        <Button
          secondary
          className="btn-hpp"
          onClick={() => togglePreviewModal(isOpen, setIsOpen)}
          disabled={!isLanguageSupported(currentLanguage)}
        >
          {i18n.t('Preview')}
        </Button>
      </span>
      {!isLanguageSupported(currentLanguage) && (
        <Tooltip place="left" id={`previewButton-${productVersionId}`}>
          {i18n.t(
            `Preview is only available in the following languages: ${Object.values(
              SUPPORTED_LANGUAGES
            )
              .map((lang) => lang.name)
              .join(', ')}`
          )}
        </Tooltip>
      )}
      {isOpen && (
        <HppModal
          onClose={() => togglePreviewModal(isOpen, setIsOpen)}
          hppSrc={hppSrc}
        />
      )}
    </>
  );
};

export default PreviewModal;
