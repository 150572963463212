import PropTypes from 'prop-types';

import i18n from 'utils/i18n';

import { FEATURE_MANUFACTURER_DUPLICATION } from '../../constants';
import FeatureModal from '../modal';

import Preview from './preview';

const FeatureProductDuplicationModal = ({ onClose }) => (
  <FeatureModal
    onClose={onClose}
    title={i18n.t('Gain productivity by duplicating products')}
    flag={FEATURE_MANUFACTURER_DUPLICATION}
    withBilling
  >
    <Preview />
  </FeatureModal>
);

FeatureProductDuplicationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FeatureProductDuplicationModal;
