import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Select } from '@alkem/react-ui-select';

import { updateEntity } from 'actions/entity';
import Form from 'components/ui/form';
import { getCleanedValue } from 'components/ui/form/field/utils/clean';
import { getDefaultValue } from 'components/ui/form/field/utils/seed';
import Tipster from 'components/ui/tipster';
import { ENTITY_TYPE_PRODUCTVERSION_BULKEDIT } from 'constants/entities';
import { selectCurrentLanguage } from 'reducers/productVersion';
import { getAsReadOnly } from 'utils/displayGroup';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { deleteDisplayGroup, selectDisplayGroup } from '../../actions';
import {
  selectDisplayGroups,
  selectEntity,
  selectSelectedDisplayGroups,
} from '../../selectors';

import './edit.scss';

const mapStateToProps = (state) => ({
  entity: selectEntity(state),
  displayGroups: selectDisplayGroups(state),
  selectedDisplayGroups: selectSelectedDisplayGroups(state),
  currentLanguage: selectCurrentLanguage(state),
});

const mapDispatchToProps = {
  selectDisplayGroup,
  deleteDisplayGroup,
  updateEntity,
};

export class BulkEditModalEdit extends PureComponent {
  static entityId = -1;

  static entityKind = ENTITY_TYPE_PRODUCTVERSION_BULKEDIT;

  static propTypes = {
    entity: PropTypes.object.isRequired,
    displayGroups: PropTypes.array.isRequired,
    selectedDisplayGroups: PropTypes.array.isRequired,
    readOnly: PropTypes.bool.isRequired,
    currentLanguage: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      selectDisplayGroup: PropTypes.func.isRequired,
      deleteDisplayGroup: PropTypes.func.isRequired,
      updateEntity: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.selectDisplayGroup = this.selectDisplayGroup.bind(this);
    this.renderOneDisplayGroup = this.renderOneDisplayGroup.bind(this);
  }

  removeDisplayGroup = (displayGroup) => () => {
    const { entity, actions } = this.props;
    // Remove the display group from the selected ones.
    actions.deleteDisplayGroup(displayGroup.id);
    // Clean the entity.
    displayGroup.items.forEach((field) => {
      const value = get(entity, field.model);
      actions.updateEntity(
        field.model,
        getCleanedValue(value, field),
        BulkEditModalEdit.entityId,
        BulkEditModalEdit.entityKind
      );
    });
  };

  selectDisplayGroup(displayGroup) {
    const { actions } = this.props;
    actions.selectDisplayGroup(displayGroup);
    // Seed values in the entity.
    displayGroup.items.forEach((field) => {
      const { defaultValue } = getDefaultValue(field);
      actions.updateEntity(
        field.model,
        defaultValue,
        BulkEditModalEdit.entityId,
        BulkEditModalEdit.entityKind
      );
    });
  }

  renderModifiedFieldInfo() {
    const { readOnly, selectedDisplayGroups } = this.props;
    if (!readOnly) {
      return null;
    }
    return (
      <div className="BulkEditModalEdit__summary">
        <Tipster
          info={i18n.t(
            'frontproductstream.product_bulk_edit.modal.summary_information_message',
            {
              defaultValue:
                'All data left empty will be erased from the selected products.',
            }
          )}
        />
        <div className="BulkEditModalEdit__summaryText">
          <span>
            {i18n.t('frontproductstream.product_bulk_edit.modal.summary_text', {
              defaultValue: 'You will edit: ',
            })}
          </span>
          <span className="BulkEditModalEdit__summaryText--success">
            {i18n.t(
              'frontproductstream.product_bulk_edit.modal.summary_text_success',
              {
                defaultValue: '{{ number }} field(s) (or group(s) of fields)',
                number: selectedDisplayGroups.length,
              }
            )}
          </span>
        </div>
      </div>
    );
  }

  renderDisplayGroups() {
    const { selectedDisplayGroups } = this.props;
    return [
      ...selectedDisplayGroups.map(this.renderOneDisplayGroup),
      this.renderSelect(),
    ];
  }

  renderOneDisplayGroup(displayGroup) {
    const { entity, readOnly, currentLanguage } = this.props;
    return (
      <div className="BulkEditModalEdit__displayGroup" key={displayGroup.id}>
        <div className="BulkEditModalEdit__displayGroupContent">
          <Form
            deepIndex={2}
            entity={entity}
            entityId={BulkEditModalEdit.entityId}
            entityKind={BulkEditModalEdit.entityKind}
            formGroup={readOnly ? getAsReadOnly(displayGroup) : displayGroup}
            condensed
            currentLanguage={currentLanguage}
          />
        </div>
        {this.renderDeleteButton(displayGroup)}
      </div>
    );
  }

  renderDeleteButton(displayGroup) {
    const { readOnly } = this.props;
    if (readOnly) {
      return null;
    }
    if (!displayGroup) {
      return (
        <div className="BulkEditModalEdit__deleteBtn BulkEditModalEdit__deleteBtn--hidden">
          <i className="mdi mdi-delete" />
        </div>
      );
    }

    return (
      <div
        className="BulkEditModalEdit__deleteBtn"
        onClick={this.removeDisplayGroup(displayGroup)}
      >
        <i className="mdi mdi-delete" />
      </div>
    );
  }

  renderSelect() {
    const { displayGroups, readOnly, selectedDisplayGroups } = this.props;
    if (readOnly) {
      return null;
    }
    const selectedIds = selectedDisplayGroups.map((dg) => dg.id);
    const remainingDisplayGroups = displayGroups.filter(
      (dg) => !selectedIds.includes(dg.id)
    );

    // Don't display the select if nothing is available.
    if (!remainingDisplayGroups.length) {
      return (
        <div className="BulkEditModalEdit__select--empty" key="select-empty">
          {i18n.t(
            'frontproductstream.product_bulk_edit.modal_empty_select_fields.text',
            { defaultValue: 'All available fields have been selected.' }
          )}
        </div>
      );
    }

    return (
      <div className="BulkEditModalEdit__selectWrapper" key="select">
        <div className="row BulkEditModalEdit__select">
          <div className="col-xs-4 InputField__label">
            <i className="BulkEditModalEdit__selectIcon mdi mdi-plus-circle" />
            {i18n.t(
              'frontproductstream.product_bulk_edit.modal.select_fields.label',
              { defaultValue: 'Fields to edit' }
            )}
          </div>
          <div className="col-xs-8 InputField__input">
            <Select
              id="bulk-edit-model-select-field"
              placeholder={i18n.t(
                'frontproductstream.product_bulk_edit.modal.select_fields.placeholder',
                { defaultValue: 'Add a field...' }
              )}
              options={remainingDisplayGroups}
              onValueAdd={this.selectDisplayGroup}
              onValueDelete={noop}
              inputable
            />
          </div>
        </div>
        {this.renderDeleteButton()}
      </div>
    );
  }

  render() {
    const { displayGroups } = this.props;
    // In this case, the display groups have not been loaded yet.
    if (!displayGroups.length) {
      return null;
    }
    return (
      <div className="BulkEditModalEdit">
        {this.renderModifiedFieldInfo()}
        {this.renderDisplayGroups()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(BulkEditModalEdit);
