import { List } from 'immutable';

import { BRAND_NO_BRAND, BRAND_PRODUCT_BRAND } from 'constants/brands';
import { DIMENSION_KIND, DIMENSION_TARGET_MARKET } from 'constants/dimensions';
import { ProductValidationStatusUnknown } from 'constants/productValidationStatus';
import * as SHARING_STATUSES from 'constants/sharedStatus';
import { TypePackagingLabels } from 'constants/typePackaging';
import {
  RELEASE_CASE_AS_BASE_UNIT,
  RELEASE_PACK_AS_BASE_UNIT,
} from 'modules/feature-flag/constants';
import i18n from 'utils/i18n';
import { get, size } from 'utils/immutable';
import { isEmpty } from 'utils/isEmpty';

import { parseNumber } from '../../utils';

export const getId = (productVersion) => get(productVersion, 'id');

export const getProductVersionId = (productVersion) => getId(productVersion);

export const getProductId = (productVersion) =>
  get(productVersion, 'specializes.id');

export const getProductGTIN = (productVersion) =>
  get(productVersion, 'specializes.isIdentifiedBy.0.reference') ||
  get(productVersion, 'gtin');

export const getHighlight = (productVersion) =>
  get(productVersion, 'highlight');

export const getProductIdentifier = (productVersion) =>
  get(productVersion, 'productIdentifier');

export const getPrimaryInternalReference = (productVersion) =>
  get(productVersion, 'primaryInternalReference');

export const getProductProductKeyId = (productVersion) =>
  get(productVersion, 'product_key.id');

export const getSourceProductKeyId = (productVersion) =>
  get(productVersion, ['isSharedFrom', 0, 'sourceProductKey', 'id']);

export const getProductGTINWithFallbackOnId = (productVersion) =>
  getProductGTIN(productVersion) || `${getProductId(productVersion)}`;

export const getProductGTINWithFallbackOnInternal = (productVersion) =>
  getProductGTIN(productVersion) ||
  getProductIdentifier(productVersion) ||
  getPrimaryInternalReference(productVersion);

export const getIsEligibleForSourcing = (productVersion) =>
  get(productVersion, 'alkemics.sourcing.eligibleFor', false);

export const getIsDisplayableForTarget = (productVersion) =>
  get(productVersion, 'alkemics.recipients.0.is_displayable_for_target', false);

export const getIsRequested = (productVersion) =>
  get(productVersion, 'alkemics.isRequested');

export const getBrandName = (productVersion) => {
  const brand = get(productVersion, 'brand');
  if (brand) {
    switch (get(brand, 'id')) {
      case BRAND_PRODUCT_BRAND.id:
        return '';
      case BRAND_NO_BRAND.id:
        return BRAND_NO_BRAND.label;
      default:
        return get(brand, 'label');
    }
  }
  return '';
};

export const getBrandId = (productVersion) => get(productVersion, 'brand.id');

export const getKindId = (productVersion) => get(productVersion, 'kind.id');

export const getTypePackaging = (productVersion) =>
  get(productVersion, 'typePackaging');

export const getTypePackagingId = (productVersion): number =>
  get(productVersion, ['typePackaging', 'id']);

export const getIsConsumerUnit = (productVersion) =>
  get(productVersion, 'isConsumerUnit');

export const getIsDisplayUnit = (productVersion) =>
  get(productVersion, 'isDisplayUnit');

export const getIsDespatchUnit = (productVersion) =>
  get(productVersion, 'isDespatchUnit');

export const getIsBaseUnit = (productVersion) =>
  get(productVersion, 'isBaseUnit', false);

export const getNonAllowedTypePackagingsForChildren = (productVersion) => {
  if (getIsDisplayUnit(productVersion)) {
    return [TypePackagingLabels.PALLET.id]; // TODO: should use a set
  } else if (
    getIsConsumerUnit(productVersion) &&
    getTypePackagingId(productVersion) === TypePackagingLabels.PACK.id
  ) {
    return [TypePackagingLabels.PALLET.id, TypePackagingLabels.CASE.id];
  } else {
    return [];
  }
};

export const getContentOwnerId = (productVersion) =>
  get(productVersion, 'tags.contentOwner.id');

export const getTargetMarketId = (productVersion) =>
  get(productVersion, 'tags.targetMarket.id');

export const getTargetMarket = (productVersion) =>
  get(productVersion, 'tags.targetMarket');

export const getOwnerId = (productVersion) => get(productVersion, 'owner.id');
export const getOwnerUuid = (productVersion) =>
  get(productVersion, 'owner.uuid');
export const getOwnerName = (productVersion) =>
  get(productVersion, 'owner.name');

export const getLanguageValue = (value, currentLanguage) => {
  if (!currentLanguage || !value) {
    return null;
  }
  const extractedValue = value.find(
    (item) =>
      get(item, 'expressedIn.id') === get(currentLanguage, 'id') ||
      (get(currentLanguage, 'code') &&
        get(item, 'expressedIn.code') === get(currentLanguage, 'code'))
  );
  return extractedValue ? get(extractedValue, 'data') : null;
};

export const getLanguageCode = (value, currentLanguage) => {
  if (!currentLanguage || !value) {
    return '';
  }
  const extractedValue = value.find(
    (item) =>
      get(item, 'expressedIn.id') === get(currentLanguage, 'id') ||
      (get(currentLanguage, 'code') &&
        get(item, 'expressedIn.code') === get(currentLanguage, 'code'))
  );
  return extractedValue
    ? get(extractedValue, 'expressedIn.normalizedCode')
    : '';
};

export const getLanguageValueFromMultiple = (
  declinableFieldValue,
  languages
) => {
  let data = null;
  let localeCode = '';
  if (!declinableFieldValue || isEmpty(declinableFieldValue)) {
    return { data, localeCode };
  }

  if (!languages) {
    return { data: get(declinableFieldValue.first(), 'data'), localeCode };
  }

  // Look inside the data all the given locales by order priority
  data = languages
    .map((locale) => getLanguageValue(declinableFieldValue, locale))
    .filter((languageData) => languageData)
    .first();

  localeCode = languages
    .map((locale) => getLanguageCode(declinableFieldValue, locale))
    .filter((languageData) => languageData)
    .first();

  // If no locale was found, we fallback on the first one of the data.
  if (isEmpty(data)) {
    data = get(declinableFieldValue.first(), 'data');
    localeCode = get(declinableFieldValue.first(), 'expressedIn.code');
    if (isEmpty(data)) {
      data = null;
      localeCode = '';
    }
  }

  return { data, localeCode };
};

export const getLabelInLanguage = (
  data,
  targetMarket,
  localesByTargetMarket
) => {
  const targetMarketId = get(targetMarket, 'id');
  const locales = get(localesByTargetMarket, `${targetMarketId}`) || List();
  const { data: result } = getLanguageValueFromMultiple(data, locales);
  return result;
};

// This is not meant to be displayed as is, use `getDisplayName` or `getDefaultDisplayName` for data meant to be displayed.
export const getDisplayNameWithoutFallback = (
  productVersion,
  currentLanguage
) => {
  let name = '';
  if (productVersion) {
    const namePublicLong = get(productVersion, 'namePublicLong');
    if (namePublicLong && size(namePublicLong)) {
      name = getLanguageValue(namePublicLong, currentLanguage);
    }
    const nameLegal = get(productVersion, 'nameLegal');
    if (!name && nameLegal && size(nameLegal)) {
      name = getLanguageValue(nameLegal, currentLanguage);
    }
  }
  return name;
};

export const displayNameFallback = i18n.t(
  'frontproductstream.core.product_version.no_display_name',
  { defaultValue: 'Commercial name not available' }
);
export const displayNameNotEligibleFallBack = i18n.t(
  'frontproductstream.core.product_version.not_available_on_network',
  {
    defaultValue:
      'This product exists on the Alkemics platform but has not been made available on the network yet',
  }
);

export const getDefaultDisplayName = (productVersion, locales) => {
  const marketLocales =
    get(locales, `${getTargetMarketId(productVersion)}`) || List();
  return (
    marketLocales
      .map((locale) => getDisplayNameWithoutFallback(productVersion, locale))
      .filter((name) => !!name)
      .first() || displayNameFallback
  );
};

export const getDisplayName = (productVersion, currentLanguage) =>
  getDisplayNameWithoutFallback(productVersion, currentLanguage) ||
  displayNameFallback;

export const getPackaging = (productVersion, currentLanguage) =>
  productVersion
    ? getLanguageValue(get(productVersion, 'packaging'), currentLanguage)
    : undefined;

export const getNetContent = (productVersion) => ({
  value: get(productVersion, 'netContent.0.data'),
  unit: get(productVersion, 'netContent.0.expressedIn.code'),
});

export const getActiveShares = (productVersion) => {
  const isSharedWith = get(productVersion, 'isSharedWith') || [];
  return isSharedWith.filter((share) =>
    [SHARING_STATUSES.pending.id, SHARING_STATUSES.accepted.id].includes(
      get(share, ['status', 'id'])
    )
  );
};

export const getDimensions = (
  productVersion,
  dimensionTypes: string[] = []
) => {
  const dimensions: any = {};
  if (!productVersion) {
    return dimensions;
  }
  const productVersionData = productVersion.edited || productVersion.source;
  if (!productVersionData) {
    return dimensions;
  }
  if (dimensionTypes.indexOf(DIMENSION_KIND) !== -1) {
    const kindId = getKindId(productVersionData);
    if (kindId) {
      dimensions.kind_id = kindId;
    }
  }
  if (dimensionTypes.indexOf(DIMENSION_TARGET_MARKET) !== -1) {
    const targetMarket = productVersionData.tags
      ? productVersionData.tags.targetMarket || {}
      : {};
    if (targetMarket.id) {
      dimensions.target_market_id = targetMarket.id;
    }
  }
  return dimensions;
};

/**
 * returns list of string
 */
export const getTagList = (productVersion) =>
  get(productVersion, 'isTaggedBy', []);

export const hasTag = (pv, tag) => getTagList(pv).includes(tag);

export const getValidationStatus = (pv) => {
  const status = get(pv, 'validation.status');
  if (!status && status !== 0) {
    return ProductValidationStatusUnknown.id;
  }
  return status;
};

export const getIsMadeOf = (productVersion) =>
  get(productVersion, ['isMadeOf']);

export const getAllergenTypeList = (productVersion) =>
  get(productVersion, ['allergenTypeList']);

export const getUserLabels = (productVersion) =>
  get(productVersion, 'isUserLabeledBy');

export const getProductVersionTargetProductStatus = (productVersion) =>
  get(productVersion, 'targetProductStatus') as string;

export const getProductVersionTargetMarketId = (productVersion) =>
  get(productVersion, 'tags.targetMarket.id');

export const getPictureURI = (productVersion) =>
  get(productVersion, 'alkemics.icon_url');

export const getSourceProductStatus = (productVersion) =>
  get(productVersion, ['recipients_source_status']) ||
  get(productVersion, ['alkemics', 'statuses', 'source']);

export const getSourcingLabels = (productVersion) =>
  get(productVersion, 'alkemics.recipients.0.sourcing_labels');

export const getIsMadeOfError = (productVersion, flags = {}) => {
  const isMadeOf = getIsMadeOf(productVersion) || [];
  const isBaseUnit = getIsBaseUnit(productVersion);
  const isDisplayUnit = getIsDisplayUnit(productVersion);
  const isDespatchUnit = getIsDespatchUnit(productVersion);
  const hasFeaturePackAsBaseUnit = flags[RELEASE_PACK_AS_BASE_UNIT];
  const hasFeatureCaseAsBaseUnit = flags[RELEASE_CASE_AS_BASE_UNIT];
  const typePackagingId = getTypePackagingId(productVersion);
  const noChildren = !isMadeOf || size(isMadeOf) === 0;
  const someChildrenAreIncomplete =
    !noChildren &&
    isMadeOf.some(
      (child) =>
        !get(child, ['quantity']) ||
        !get(child, ['targetProduct']) ||
        (parseNumber(get(child, ['quantity'])) || 0) < 1
    );

  if ((!isBaseUnit && !isDespatchUnit) || isDisplayUnit) {
    if (noChildren) {
      return i18n.t('frontproductstream.core.product_version.mandatory_child', {
        defaultValue:
          'Consumer pack or display units must have at least one child product defined',
      });
    } else if (someChildrenAreIncomplete) {
      return i18n.t(
        'frontproductstream.core.product_version.incomplete_children',
        {
          defaultValue:
            'Every child must have a quantity (of at least 1) and a child product specified',
        }
      );
    }
  }

  if (
    isBaseUnit &&
    ((typePackagingId === TypePackagingLabels.CASE.id &&
      hasFeatureCaseAsBaseUnit) ||
      (typePackagingId === TypePackagingLabels.PACK.id &&
        hasFeaturePackAsBaseUnit)) &&
    size(
      isMadeOf.filter(
        (product) => !!product.targetProduct || !!product.quantity
      )
    ) > 0
  ) {
    return i18n.t('frontproductstream.core.product_version.forbidden_child', {
      defaultValue: 'Base unit product should not contain any child.',
    });
  }

  return null;
};
