import { List } from 'immutable';
import { isArray } from 'lodash';
import { v4 as uuid } from 'uuid';

import './list.scss';
import { DiffSeparator } from './separator';

type Props = {
  label: string | string[] | string[][];
  type?: 'div' | 'ul';
  labelClassName?: string;
};

export const DiffList = ({
  label,
  type = 'div',
  labelClassName = 'DiffList__label',
}: Props) => {
  let list: any[] = !isArray(label) ? [label] : label;
  list = list.map((l) => {
    if (isArray(l)) {
      return l.map((elt, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={i} data-diff={`label${i}`}>
          {i > 0 ? <DiffSeparator /> : null}
          {elt}
        </span>
      ));
    }
    return (
      <span key={l} data-diff="label0">
        {l}
      </span>
    );
  });
  const items = List(list)
    .toSet() // TODO did we need this only to remove duplicates ?
    .toList()
    .map((l) =>
      type === 'ul' ? (
        <li
          data-testid={'li'}
          key={`diff-item-${uuid()}`}
          className={labelClassName}
        >
          {l}
        </li>
      ) : (
        <div key={`diff-item-${uuid()}`} className={labelClassName}>
          {l}
        </div>
      )
    );
  return type === 'ul' ? (
    <ul className="DiffList">{items}</ul>
  ) : (
    <div className="DiffList">{items}</div>
  );
};
