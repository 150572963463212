import { Map, fromJS } from 'immutable';
import { once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { SwitchButton } from '@alkem/react-ui-button';

import { STATUS_PENDING, STATUS_REFUSED } from 'constants/assignation';
import { assignationStatusFilter } from 'core/modules/list/constants/filters/assignation-status';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import i18n from 'utils/i18n';

import CollapsibleFilter from '../collapsible';
import FilterItem from '../simple/item';

import './index.scss';

export class AssignationStatusFilter extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    collapsed: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    filterQueryValues: PropTypes.array,
    withRefused: PropTypes.bool,
    onChangeWithRefused: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    collapsed: true,
    filterQueryValues: [],
    withRefused: false,
  };

  filterKey = assignationStatusFilter.key;

  updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: this.props.filterQueryValues,
      filterList: this.filterList,
      filterKey: this.filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) =>
        `${this.filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => filter,
    }).then((filtersFromQuery) => {
      this.props.onChange(filtersFromQuery, true);
    });
  });

  filterList = fromJS(
    [
      {
        id: STATUS_PENDING,
        name: i18n.t(
          'frontproductstream.core.list_filter_assignation_status.pending',
          { defaultValue: 'Pending' }
        ),
        icon: '',
      },
      {
        id: STATUS_REFUSED,
        name: i18n.t(
          'frontproductstream.core.list_filter_assignation_status.refused',
          { defaultValue: 'Refused' }
        ),
        icon: '',
      },
    ].map((filter) => ({
      id: filter.id,
      label: filter.name,
      iconClassName: `AssignationStatusFilterIcon ${filter.icon}`,
    }))
  );

  filterLabel = i18n.t(
    'frontproductstream.core.list_filter_assignation_status.label',
    { defaultValue: 'Status' }
  );

  componentDidMount() {
    this.updateSelectionFromQuery();
  }

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.filterKey, collapsed);
  };

  onChange = (filter, selected) => {
    this.props.onChange({
      key: this.filterKey,
      value: filter.get('id'),
      label: `${this.filterLabel}: ${filter.get('label')}`,
      add: selected,
      data: filter,
    });
  };

  onChangeShowRefused = (isSelected) => {
    const { onChangeWithRefused, selectedFilterMap, onChange } = this.props;
    onChangeWithRefused(isSelected);
    if (
      !isSelected &&
      selectedFilterMap.getIn([this.filterKey, STATUS_REFUSED])
    ) {
      onChange(
        {
          key: this.filterKey,
          value: STATUS_REFUSED,
          add: false,
        },
        true
      );
    }
  };

  render() {
    const { collapsed, aggregations, selectedFilterMap, withRefused } =
      this.props;
    const hasDocCount = this.filterList.some(
      (filter) =>
        aggregations.getIn([this.filterKey, filter.get('id'), 'doc_count']) >= 0
    );
    return (
      <CollapsibleFilter
        id="list-filter-assignation-status"
        label={this.filterLabel}
        collapsed={collapsed}
        onCollapse={this.onCollapse}
      >
        <div className="AssignationStatusFilter__switch">
          <SwitchButton
            content={i18n.t(
              'frontproductstream.core.list_filter_assignation_status.show_refused_assignations_switch',
              { defaultValue: 'Show refused assignations' }
            )}
            onChange={this.onChangeShowRefused}
            checked={withRefused}
          />
        </div>
        {this.filterList.map((filter) =>
          filter.get('id') === STATUS_REFUSED ? (
            <FilterItem
              key={`list-simple-filter-${this.filterKey}-${filter.get('id')}`}
              filterKey={this.filterKey}
              filter={filter}
              aggregation={aggregations.getIn([
                this.filterKey,
                filter.get('id'),
              ])}
              selected={
                !!selectedFilterMap.getIn([this.filterKey, filter.get('id')]) &&
                withRefused
              }
              disabled={!withRefused}
              hasDocCount={withRefused && hasDocCount}
              onChange={this.onChange}
            />
          ) : (
            <FilterItem
              key={`list-simple-filter-${this.filterKey}-${filter.get('id')}`}
              filterKey={this.filterKey}
              filter={filter}
              aggregation={aggregations.getIn([
                this.filterKey,
                filter.get('id'),
              ])}
              selected={
                !!selectedFilterMap.getIn([this.filterKey, filter.get('id')])
              }
              hasDocCount={hasDocCount}
              onChange={this.onChange}
            />
          )
        )}
      </CollapsibleFilter>
    );
  }
}
