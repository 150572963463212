import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Modal as BaseModal } from '@alkem/react-ui-modal';
import { ProgressBar } from '@alkem/react-ui-progress';

import Modal from 'components/ui/modal';
import Notification from 'components/ui/notification';
import { selectLocalesByTargetMarket } from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { bulkSetAsPublic, reset, show } from '../actions';
import {
  selectCount,
  selectInProgress,
  selectIsDone,
  selectIsVisible,
  selectProductVersions,
  selectStatus,
  selectVersionsInError,
  selectVersionsSuccessful,
} from '../selectors';

import './index.scss';
import BulkSetAsPublicProductInfo from './product-info';

const mapStateToProps = (state) => ({
  isVisible: selectIsVisible(state),
  productVersions: selectProductVersions(state),
  inProgress: selectInProgress(state),
  inProgressCount: selectCount(state),
  inProgressStatus: selectStatus(state),
  isDone: selectIsDone(state),
  locales: selectLocalesByTargetMarket(state),
  errors: selectVersionsInError(state),
  success: selectVersionsSuccessful(state),
});

const mapDispatchToProps = {
  reset,
  bulkSetAsPublic,
  show,
};

export class BulkSetAsPublic extends PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    isDone: PropTypes.bool.isRequired,
    productVersions: ImmutablePropTypes.map.isRequired,
    inProgressCount: PropTypes.number.isRequired,
    inProgressStatus: PropTypes.string.isRequired,
    locales: ImmutablePropTypes.map.isRequired,
    errors: ImmutablePropTypes.map.isRequired,
    success: ImmutablePropTypes.map.isRequired,
    actions: PropTypes.shape({
      reset: PropTypes.func.isRequired,
      bulkSetAsPublic: PropTypes.func.isRequired,
      show: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentWillUnmount() {
    this.props.actions.reset();
  }

  close = () => {
    const { actions } = this.props;
    actions.reset();
  };

  retry = () => {
    const { actions, errors, productVersions } = this.props;
    actions.reset();
    actions.show(
      errors
        .keySeq()
        .reduce(
          (map, productVersionId) =>
            map.set(productVersionId, productVersions.get(productVersionId)),
          Map()
        )
    );
    actions.bulkSetAsPublic();
  };

  render() {
    const {
      isVisible,
      actions,
      productVersions,
      inProgress,
      inProgressCount,
      inProgressStatus,
      isDone,
      locales,
      errors,
      success,
    } = this.props;
    if (!isVisible) {
      return null;
    }
    const productsCount = productVersions.size;
    const hasErrors = errors.size > 0;
    let contentLabel;
    let buttonLabel;
    let retryLabel;
    let errorLabel;
    if (productsCount === 1) {
      contentLabel = i18n.t(
        'Are you sure you want to make this product visible on the Alkemics network?'
      );
      buttonLabel = i18n.t('Make the product visible on the Alkemics network');
    } else {
      contentLabel = i18n.t(
        'Are you sure you want to make those {{count}} products visible on the Alkemics network?',
        {
          count: productsCount,
        }
      );
      buttonLabel = i18n.t(
        'Make {{count}} products visible on the Alkemics network',
        {
          count: productsCount,
        }
      );
    }
    if (isDone && errors.size === 1) {
      retryLabel = i18n.t('Retry updating the product');
      errorLabel = i18n.t(
        'We have encountered an error while updating this product. Please retry:'
      );
    } else if (isDone && errors.size > 1) {
      retryLabel = i18n.t('Retry updating {{count}} products', {
        count: errors.size,
      });
      errorLabel = i18n.t(
        'We have encountered an error while updating some products. Please retry:'
      );
    }
    return (
      <BaseModal
        modalStyle="dynamic"
        className="BulkSetAsPublic"
        title={i18n.t('Make visible on the Alkemics network')}
        confirmButtonText={
          (isDone && i18n.t('OK')) ||
          (!isDone && buttonLabel) ||
          (hasErrors && retryLabel)
        }
        closeButtonText={!isDone && Modal.defaultProps.closeButtonText}
        isProcessing={inProgress}
        onConfirm={
          (!isDone && actions.bulkSetAsPublic) ||
          (hasErrors && this.retry) ||
          this.close
        }
        onClose={this.close}
      >
        <Notification context="modal" absolute />
        <div>{contentLabel}</div>
        <ul className="BulkSetAsPublic__products">
          {productVersions.keySeq().map((productVersionId) => (
            <BulkSetAsPublicProductInfo
              key={productVersionId}
              productVersion={productVersions.get(productVersionId)}
              locales={locales}
              success={success.has(productVersionId)}
              error={errors.has(productVersionId)}
            />
          ))}
        </ul>

        <ProgressBar
          value={inProgressCount}
          max={productsCount}
          color={inProgressStatus}
          height="medium"
        />
        {hasErrors && isDone && (
          <div className="BulkSetAsPublic__errorsLabel">{errorLabel}</div>
        )}
      </BaseModal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(BulkSetAsPublic);
