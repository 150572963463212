// React
import { noop } from 'lodash';
import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';
import { Referential } from '@alkem/sdk-dashboard';

import { getId } from 'components/ui/form/field/utils/clean';
// Outer modules
import InputText from 'components/ui/input/text';
import i18n from 'utils/i18n';

// Inner module
import { BRACKET_COLUMNS_DISPLAYED } from '../../../constants';
import { displayPriceValue, getValue, getValueAtIndex } from '../../../utils';
import { valuesAreEqual } from '../../price-waterfall/estimate';

import './price.scss';

interface PriceLevelProps {
  model: string;
  sharingUnitId?: number;
  value: {
    description: string;
    type: Referential;
    unit: Referential;
    uuid: string;
    values: {
      uuid: string;
      value: string;
    }[];
  };
  onUpdate?: (model: string, value: any, ...args: any) => void;
  brackets: [];
  bracketStartIndex: number;
  disabled?: boolean;
  estimations: [];
  estimationFailed?: boolean;
  hasEstimationFeature?: boolean;
  showEstimationInCell?: boolean;
  description?: string | null;
  entityId?: number;
  entityType?: string;
}

export default class PriceLevel extends PureComponent<PriceLevelProps> {
  static defaultProps = {
    estimationFailed: false,
    showEstimationInCell: false,
  };

  onUpdateValue = (model) => (e) => this.props.onUpdate?.(model, getValue(e));

  onUpdateValueFromEstimation = (model, idx) => () => {
    const { onUpdate, estimations } = this.props;
    const estimation = estimations[idx];
    if (estimation) {
      onUpdate?.(model, estimation);
    }
  };

  renderValues() {
    const {
      model,
      entityId,
      entityType,
      value,
      brackets,
      bracketStartIndex,
      disabled,
      hasEstimationFeature,
      estimations,
      estimationFailed,
      showEstimationInCell,
    } = this.props;
    if (!brackets) {
      return null;
    }
    const { values } = value;

    return brackets
      .slice(bracketStartIndex, bracketStartIndex + BRACKET_COLUMNS_DISPLAYED)
      .map((_bracket, index) => {
        const realIndex = index + bracketStartIndex;
        const localValue = getValueAtIndex(values, realIndex);
        const localModel = `${model}.values.${realIndex}.value`;

        const estimationDiffers =
          estimations &&
          !valuesAreEqual(estimations[realIndex], localValue.value);

        const actuallyShowEstimationInCell =
          hasEstimationFeature &&
          !estimationFailed &&
          estimations &&
          estimations[realIndex] != null &&
          showEstimationInCell;
        let cellEstimation;
        if (actuallyShowEstimationInCell) {
          cellEstimation = estimations[realIndex];
        }

        return (
          <div key={localValue.uuid} className="PriceLevel__value">
            <InputText
              id={getId(localModel, entityType, entityId)}
              value={
                actuallyShowEstimationInCell
                  ? cellEstimation
                  : displayPriceValue(localValue.value)
              }
              onChange={
                actuallyShowEstimationInCell
                  ? noop
                  : this.onUpdateValue(localModel)
              }
              type="number"
              disabled={disabled}
            />
            {hasEstimationFeature && estimationFailed && (
              <span className="PriceLevel__estimation">
                <i
                  className="mdi mdi-alert PriceLevel__estimationIcon PriceLevel__estimationIcon--fail"
                  data-tip
                  data-for={`price-estimation-${localValue.uuid}`}
                />
                <Tooltip
                  id={`price-estimation-${localValue.uuid}`}
                  className="PriceLevel__estimationToolTip"
                  hoverable
                >
                  <div className="PriceLevel__estimation__failureText">
                    {i18n.t(
                      'The estimation failed. Please, make sure all the data needed to estimate your prices is filled.'
                    )}
                  </div>
                </Tooltip>
              </span>
            )}
            {hasEstimationFeature &&
              !estimationFailed &&
              estimations &&
              estimations[realIndex] != null && (
                <span className="PriceLevel__estimation">
                  {estimationDiffers ? (
                    <>
                      <i
                        className="mdi mdi-close-circle PriceLevel__estimationIcon PriceLevel__estimationIcon--wrong"
                        data-tip
                        data-for={`price-estimation-${localValue.uuid}`}
                      />
                      <Tooltip
                        id={`price-estimation-${localValue.uuid}`}
                        className="PriceLevel__estimationToolTip"
                        hoverable
                      >
                        <strong>{i18n.t('Suggested value')}</strong>
                        <br />
                        <Button
                          link
                          content={`${estimations[realIndex]}`}
                          onClick={this.onUpdateValueFromEstimation(
                            localModel,
                            realIndex
                          )}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <i className="mdi mdi-check-circle PriceLevel__estimationIcon PriceLevel__estimationIcon--correct" />
                  )}
                </span>
              )}
          </div>
        );
      });
  }

  renderLabel() {
    const { value, description } = this.props;

    return (
      <div className="PriceLevel__labelWrapper">
        <div className="PriceLevel__label">
          <span>{description || value.description}</span>
        </div>
      </div>
    );
  }

  render() {
    const { model, entityId, entityType } = this.props;
    return (
      <div className="PriceLevel">
        {this.renderLabel()}
        <div
          className="PriceLevel__valueWrapper"
          id={getId(`${model}.values`, entityType, entityId)}
        >
          {this.renderValues()}
        </div>
      </div>
    );
  }
}
