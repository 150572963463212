import { List } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Modal from 'components/ui/modal';
import {
  resetDiffModal,
  submitPhysicalCheck,
} from 'modules/contribution/actions';
import {
  getDiffModalOpen,
  getDiffModalSaving,
} from 'modules/contribution/selectors';
import { getFailedFieldFiltered } from 'modules/contribution/selectors/check-fields';
import { HistoryDiffHeader, HistoryDiffLine } from 'modules/history';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import './index.scss';

const mapStateToProps = createStructuredSelector({
  opened: getDiffModalOpen,
  saving: getDiffModalSaving,
  failedField: getFailedFieldFiltered,
});

const mapDispatchToProps = {
  reset: resetDiffModal,
  submit: submitPhysicalCheck,
};

export class ContributionDiffModal extends PureComponent {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    failedField: ImmutablePropTypes.map,
    actions: PropTypes.shape({
      reset: PropTypes.func.isRequired,
      submit: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    opened: false,
    saving: false,
  };

  onReset = () => {
    this.props.actions.reset();
  };

  onSubmit = () => {
    this.props.actions.submit();
  };

  shouldFail() {
    const { failedField } = this.props;
    return failedField.get('failed').size > 0;
  }

  submitText() {
    if (this.shouldFail()) {
      return i18n.t(
        'frontproductstream.physical_check_difference_dialog.mark_check_as_a_failure.button',
        { defaultValue: 'Fail' }
      );
    }
    return i18n.t(
      'frontproductstream.physical_check_difference_dialog.mark_check_as_a_pass.button',
      { defaultValue: 'Pass' }
    );
  }

  renderDiffLine = (diff) => (
    <HistoryDiffLine
      key={`PhysicalCheckDiff_FailedField__field_diff--${JSON.stringify(
        diff.get('label')
      )}`}
      diff={diff}
    />
  );

  renderFailedDiffs() {
    const { failedField } = this.props;
    return failedField
      .get('failed')
      .reduce(
        (acc, diffs) => acc.concat(diffs.map(this.renderDiffLine)),
        List()
      );
  }

  renderEmptyLine = (label, model) => (
    <div
      className="ProductHistoryDiffCard__row row "
      key={`PhysicalCheckDiff_FailedField__field_diff_empty--${JSON.stringify(
        label
      )}`}
      id={`PhysicalCheckDiff_FailedField__field_diff_empty--${model}`}
    >
      <div className="col-xs-4 ProductHistoryDiffCard__key">{label}</div>
      <div className="col-ws-8 ProductHistoryDiffCard__value">
        <span className="PhysicalCheckDiff_FailedField__field_diff__value">
          {i18n.t(
            'frontproductstream.physical_check_difference_dialog.failed_value_changed_to_passed.text',
            { defaultValue: 'Value passed' }
          )}
        </span>
        <span className="PhysicalCheckDiff_FailedField__field_diff__desc">
          {' '}
          {i18n.t(
            'frontproductstream.physical_check_difference_dialog.failed_value_changed_to_passed_reason.text',
            { defaultValue: 'since within the tolerance' }
          )}
        </span>
      </div>
    </div>
  );

  renderEmptyDiffs() {
    const { failedField } = this.props;
    return failedField.get('tolerance').map(this.renderEmptyLine).valueSeq();
  }

  render() {
    const { opened, saving, failedField } = this.props;
    if (failedField === null || !opened) {
      return null;
    }
    return (
      <Modal
        modalStyle="dynamic"
        title={i18n.t(
          'frontproductstream.physical_check_difference_dialog.header.text',
          { defaultValue: 'Change summary' }
        )}
        className="ContributionDiffModal"
        confirmButtonText={this.submitText()}
        isProcessing={saving}
        onConfirm={this.onSubmit}
        onClose={this.onReset}
      >
        <div className="ContributionDiffModal__body">
          <HistoryDiffHeader />
          {this.renderFailedDiffs()}
          {this.renderEmptyDiffs()}
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ContributionDiffModal);
