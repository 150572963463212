import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import Modal from 'components/ui/modal';
import { SALES_VIDEO_EMAIL } from 'constants/emails';
import { isSuperAdmin, isSuperAdminLoggedAs } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import FeatureModal from 'modules/feature-flag/components/manufacturer-video/modal';
import { FEATURE_MANUFACTURER_VIDEO } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';

import VideoModal from './video-modal';

interface Props {
  user: UserImmutable;
  action: {
    content: string;
  };
  productId: number;
  contentOwnerId: number;
  targetMarketId: number;
  productKeyId: number;
  updateEntities: (assetType: string) => void;
}

interface State {
  isOpen: boolean;
}

export default class ActionVideo extends PureComponent<Props, State> {
  public state = {
    isOpen: false,
  };

  private showModal = () => {
    this.setState({ isOpen: true });
  };

  private hideModal = () => {
    this.setState({ isOpen: false });
  };

  private onSuccess = () => {
    this.props.updateEntities('ProductVideo');
    this.hideModal();
  };

  private formatAction = (content, hasFeatureVideo) => {
    const upgradeTranslation = i18n.t(
      'frontproducstream.asset_list.feature_upgrade.suffix',
      { defaultValue: 'Upgrade!' }
    );
    return !hasFeatureVideo ? `${content} - ${upgradeTranslation}` : content;
  };

  private renderSupportModal() {
    const { action } = this.props;
    return (
      <Modal
        modalStyle="dynamic"
        title={action.content}
        onClose={this.hideModal}
        hideFooter
      >
        <div>
          <h3>
            {i18n.t('frontproducstream.asset_list.feature_modal.title', {
              defaultValue: 'New feature !',
            })}
          </h3>
        </div>
        <div className="Actions__supportModal">
          <span>
            {i18n.t('frontproducstream.asset_list.feature_modal.content', {
              defaultValue:
                'Please get in touch with our teams to learn more about video enrichment. ',
            })}
            <a href={`mailto:${SALES_VIDEO_EMAIL}?Subject=Video%20upload`}>
              {i18n.t('frontproducstream.asset_list.feature_modal.action', {
                defaultValue: 'Click here',
              })}
            </a>
          </span>
        </div>
      </Modal>
    );
  }

  private renderModal(hasFeatureVideo) {
    const { productKeyId, user } = this.props;
    const isAlkAdmin = isSuperAdmin(user) || isSuperAdminLoggedAs(user);
    if (!hasFeatureVideo) {
      return <FeatureModal onClose={this.hideModal} />;
    }
    if (!isAlkAdmin) {
      return this.renderSupportModal();
    }
    return (
      <VideoModal
        title=""
        productKeyId={productKeyId}
        onSuccess={this.onSuccess}
        onClose={this.hideModal}
      />
    );
  }

  public render() {
    const { action } = this.props;
    const { isOpen } = this.state;
    const hasFeatureVideo = hasFeature(
      this.props.user,
      FEATURE_MANUFACTURER_VIDEO
    );
    return (
      <div>
        <Button secondary className="Button--addVideo" onClick={this.showModal}>
          {this.formatAction(action.content, hasFeatureVideo)}
        </Button>
        {isOpen && this.renderModal(hasFeatureVideo)}
      </div>
    );
  }
}
