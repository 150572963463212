import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { REASSIGN } from 'constants/assignation';
import { TargetProductStatusWaitingToBeShared } from 'constants/targetProductStatus';
import {
  getLastPendingAssignationEmails,
  getLastPendingAssignationOrganizationId,
  getSharedOrganizationId,
  getTargetProductStatus,
} from 'core/api/retailerproductversion';
import { toComparableWithTrim } from 'utils/string';

import {
  ADD_EMAIL,
  CANCEL_SEND_ASSIGNATION,
  CLOSE_TARGET_REQUEST_MODAL,
  OPEN_TARGET_REQUEST_MODAL,
  REMOVE_EMAIL,
  SELECT_RECIPIENT,
  START_SENDING_ASSIGNATION,
  STOP_SENDING_ASSIGNATION,
  TOGGLE_FORCE_ASSIGNATION,
  UNSELECT_RECIPIENT,
  UPDATE_MESSAGE,
} from './constants';

// -------
// REDUCER
// -------

export const initialState = fromJS({
  title: '',
  products: {},
  chaseType: '',
  modalIsOpen: false,
  sendingAssignation: false,
  form: {
    message: '',
    emails: [],
    recipientId: undefined,
    forceAssignation: false,
  },
});

export const getRecipientId = function ({ products }) {
  // We handle only one recipient, it is up to the client to send only products with same recipientId
  if (products.isEmpty()) {
    return undefined;
  }
  const product = products.first();
  return getTargetProductStatus(product) ===
    TargetProductStatusWaitingToBeShared.id
    ? getSharedOrganizationId(product)
    : getLastPendingAssignationOrganizationId(product);
};

export const getEmails = function ({ chaseType, products }) {
  // We handle only one recipient, it is up to the client to send only products with same emails
  if (chaseType === REASSIGN && !products.isEmpty()) {
    return getLastPendingAssignationEmails(products.first());
  }
  // If chaseType === CHASE Chase we do not specify email as a recipient already claimed the product
  return fromJS([]);
};

export default createReducer(initialState, {
  [OPEN_TARGET_REQUEST_MODAL]: (state, action) =>
    state.withMutations((newState) =>
      newState
        .set('title', action.payload.title)
        .set('chaseType', action.payload.chaseType)
        .set('products', action.payload.products)
        .set('modalIsOpen', true)
        .setIn(
          ['form', 'recipientId'],
          getRecipientId({ products: action.payload.products })
        )
        .setIn(
          ['form', 'emails'],
          getEmails({
            products: action.payload.products,
            chaseType: action.payload.chaseType,
          })
        )
    ),
  [CLOSE_TARGET_REQUEST_MODAL]: () => initialState,
  [UPDATE_MESSAGE]: (state, action) =>
    state.setIn(['form', 'message'], action.payload),
  [ADD_EMAIL]: (state, action) => {
    if (
      state
        .getIn(['form', 'emails'])
        .some(
          (email) =>
            toComparableWithTrim(email) === toComparableWithTrim(action.payload)
        )
    ) {
      return state;
    }
    return state.updateIn(['form', 'emails'], (emails) =>
      emails.push(action.payload)
    );
  },
  [REMOVE_EMAIL]: (state, action) =>
    state.updateIn(['form', 'emails'], (emails) =>
      emails.delete(action.payload.index)
    ),
  [SELECT_RECIPIENT]: (state, action) =>
    state.setIn(['form', 'recipientId'], action.payload.id),
  [UNSELECT_RECIPIENT]: (state) =>
    state.setIn(['form', 'recipientId'], undefined),
  [START_SENDING_ASSIGNATION]: (state) => state.set('sendingAssignation', true),
  [STOP_SENDING_ASSIGNATION]: (state) => state.set('sendingAssignation', false),
  [CANCEL_SEND_ASSIGNATION]: (state) => state.set('sendingAssignation', false),
  [TOGGLE_FORCE_ASSIGNATION]: (state) =>
    state.setIn(
      ['form', 'forceAssignation'],
      !state.getIn(['form', 'forceAssignation'])
    ),
});
