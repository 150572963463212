import { isInteger, isString } from 'lodash';

import i18n from 'utils/i18n';

import { isValidGtin } from './gtin';

export function checkErrorProductIdentifier(productIdentifier) {
  if (isValidGtin(productIdentifier)) {
    return i18n.t(
      'frontproductstream.validator_utils.product_identifier.not_gtin_error',
      { defaultValue: 'An identifier cannot be a valid GTIN' }
    );
  }
  if (!isString(productIdentifier) && !isInteger(productIdentifier)) {
    return i18n.t(
      'frontproductstream.validator_utils.product_identifier.alphanumeric_error',
      { defaultValue: 'An identifier should be alphanumerique characters' }
    );
  }
  if (isInteger(productIdentifier)) {
    productIdentifier += '';
  }
  if (productIdentifier.length > 16) {
    return i18n.t(
      'frontproductstream.validator_utils.product_identifier.max_length_error',
      { defaultValue: 'An identifier cannot be more than 16 characters' }
    );
  }
  // Test that we only have alphanumeric characters
  if (!/^[A-Z0-9-_]+$/.test(productIdentifier)) {
    return i18n.t(
      'frontproductstream.validator_utils.product_identifier.allowed_characters_error',
      {
        defaultValue:
          'Only alphanumeric (A-Z, 0-9), dash (-) and underscore (_) allowed',
      }
    );
  }
  return null;
}
