import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutableProptypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';

import './failed-gtins.scss';

export default class RaguelFailedGtins extends PureComponent {
  static propTypes = {
    gtinErrors: ImmutableProptypes.map.isRequired,
    onGtinClick: PropTypes.func,
  };

  onGtinClick = (gtin) => () => this.props.onGtinClick(gtin);

  renderGtin = ([gtin, count]) => [
    gtin,
    <li key={gtin}>
      {this.props.onGtinClick ? (
        <Button link onClick={this.onGtinClick(gtin)}>
          {gtin}
        </Button>
      ) : (
        gtin
      )}
      &nbsp;
      <span>
        (
        {count === 1
          ? i18n.t(
              'frontproductstream.plugins.validator.failed_gtins.one_label',
              { defaultValue: '1 error' }
            )
          : i18n.t(
              'frontproductstream.plugins.validator.failed_gtins.many_label',
              { defaultValue: '{{count}} errors', count }
            )}
        )
      </span>
    </li>,
  ];

  render() {
    const { gtinErrors } = this.props;
    if (!gtinErrors || gtinErrors.size === 0) {
      return null;
    }
    return (
      <ul className="Raguel__FailedGtins">
        {gtinErrors.mapEntries(this.renderGtin).toList()}
      </ul>
    );
  }
}
