import { curry } from 'lodash';
import {
  filter,
  flatten,
  flattenDeep,
  flow,
  get,
  isArray,
  join,
  map,
  uniq,
} from 'lodash/fp';

import {
  AMBIGOUS_UNIT,
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  HETEROGENEOUS_UNIT,
  LOGISTICAL_UNIT,
} from 'constants/unitTypes';
import { isRetailer } from 'core/api/user';
import { FEATURE_PRODUCT_REVIEW, hasFeature } from 'modules/feature-flag';
import { VersionData } from 'modules/logistical-hierarchies/structures';
import { UserImmutable } from 'types';
import { Diff } from 'types/diff';
import { i18n } from 'utils/i18n';

import { PRODUCT_TO_REVIEW_STATUSES } from './constants';
import {
  ActionToReview,
  BlockToReview,
  BlockToReviewStatus,
  BlockToReviewType,
  FieldToReviewStatus,
} from './types';

export const getUnitType = (data: VersionData) => {
  if (data?.isDisplayUnit && data?.isConsumerUnit) {
    return AMBIGOUS_UNIT;
  } else if (data?.isDisplayUnit) {
    return DISPLAY_UNIT;
  } else if (data?.isConsumerUnit) {
    return CONSUMER_UNIT;
  } else if (data?.isHeterogeneousUnit) {
    return HETEROGENEOUS_UNIT;
  }
  return LOGISTICAL_UNIT;
};

// Get the diffs of rejected fields
export const getRejectedDiffs: (action: ActionToReview) => any[] = flow(
  get('blocks'),
  map(
    flow(
      get('fields'),
      filter(({ status }) => status === FieldToReviewStatus.REJECTED),
      map(get('diffs'))
    )
  ),
  flatten
);

// Get the diffs of rejected fields
export const getRejectedListings = (action: ActionToReview) => {
  let i = 1;
  return flow(
    get('blocks'),
    filter({ type: BlockToReviewType.LISTING }),
    map(({ status }: BlockToReview) => ({
      status: status,
      name:
        i18n.t(
          'frontproductstream.product_review.block_to_review_type.listing_label',
          { defaultValue: 'Listing' }
        ) +
        ' - ' +
        i++,
    })),
    filter({ status: BlockToReviewStatus.REJECTED }),
    map(({ name }: { name: string }) => name)
  )(action);
};

// Get all flattened labels of nested diffs.
export const getDiffsFieldLabels: (diffs: Diff[][]) => string[] = flow(
  flatten,
  map(
    flow(
      get('label'),
      (label) => (isArray(label) ? label : [label]),
      flattenDeep,
      join(' - ')
    )
  )
);

export const isActionToReview = (action: ActionToReview) =>
  // No blocks means the user doesn't have the permissions to review.
  !!action.blocks &&
  action.blocks.length > 0 &&
  PRODUCT_TO_REVIEW_STATUSES.includes(action.status);

export const hasFeatureProductReview = (user: UserImmutable) =>
  isRetailer(user) && hasFeature(user, FEATURE_PRODUCT_REVIEW);

export const getHierarchyInternalIds = curry(
  (hierarchyMap, internalId: string) => {
    const childIds = hierarchyMap[internalId].map((e) => e.id);
    return uniq([
      internalId,
      ...flatten(childIds.map(getHierarchyInternalIds(hierarchyMap))),
    ]) as string[];
  }
);

export const getBlockId = (block: BlockToReview) =>
  `${block.type}-${block.main_transaction_id}`;
