import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_ORGANIZATION_ENABLE_LEGALIDENTIFIER_MODAL,
  FEATURE_ORGANIZATION_MANAGE_MAILDOMAIN,
} from 'modules/feature-flag/constants';

export function hasMailDomainManagement(user) {
  return hasFeature(user, FEATURE_ORGANIZATION_MANAGE_MAILDOMAIN);
}

export function hasLegalIdentifiersReminderModal(user) {
  return hasFeature(user, FEATURE_ORGANIZATION_ENABLE_LEGALIDENTIFIER_MODAL);
}
