import { flow, get, identity, set, unset, update } from 'lodash/fp';

import { ActionPayload, GlobalState } from 'types/redux';

import { cancelDataOpsPatch, cancelDataOpsPatchDone } from '../actions';
import { DataOpsState, PendingMessage } from '../types';

import { patchKey } from './utils';

export const dataOpsCancelPatch = (
  { patch }: ActionPayload<typeof cancelDataOpsPatch>,
  state: DataOpsState
) => {
  const pendingMessages = get(
    ['pendingMessages', state.productKeyIdToEntityId[patch.productKeyId]],
    state
  );
  return flow<[GlobalState], DataOpsState, DataOpsState>(
    unset(['patches', patchKey(patch, state)]),
    pendingMessages
      ? update(
          ['pendingMessages', state.productKeyIdToEntityId[patch.productKeyId]],
          (pM: PendingMessage[]) => pM.filter((m) => m.path !== patch.fieldName)
        )
      : identity
  );
};

export const dataOpsCancelPatchDone = (
  { patch, error }: ActionPayload<typeof cancelDataOpsPatchDone>,
  state: DataOpsState
) =>
  error ? set(['patches', patchKey(patch, state), 'data', 0], patch) : identity;
