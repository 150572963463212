import i18n from 'utils/i18n';

import IconCase from '../components/ui/hierarchy/icon/images/case_50x50.png';
import IconEach from '../components/ui/hierarchy/icon/images/each_50x50.png';
import IconPack from '../components/ui/hierarchy/icon/images/pack_50x50.png';
import IconPallet from '../components/ui/hierarchy/icon/images/pallet_50x50.png';

export const TypePackagingLabels = {
  EACH: {
    id: 0,
    value: 'EACH',
    label: i18n.t(
      'frontproductstream.constants.packaging_type.base_unit_label',
      {
        defaultValue: 'Base unit',
      }
    ),
    singular: i18n.t(
      'frontproductstream.constants.packaging_type.one_base_unit',
      {
        defaultValue: 'product',
      }
    ),
    plural: i18n.t(
      'frontproductstream.constants.packaging_type.many_base_units',
      {
        defaultValue: 'products',
      }
    ),
    demonstrative: i18n.t(
      'frontproductstream.constants.packaging_type.this_base_unit',
      {
        defaultValue: 'this product',
      }
    ),
  },
  PACK: {
    id: 1,
    value: 'PACK',
    label: i18n.t('frontproductstream.constants.packaging_type.pack_label', {
      defaultValue: 'Pack',
    }),
    singular: i18n.t('frontproductstream.constants.packaging_type.one_pack', {
      defaultValue: 'pack',
    }),
    logisticalSingular: i18n.t(
      'frontproductstream.constants.packaging_type.one_logistical_pack',
      {
        defaultValue: 'logistical pack',
      }
    ),
    plural: i18n.t('frontproductstream.constants.packaging_type.many_packs', {
      defaultValue: 'packs',
    }),
    logisticalPlural: i18n.t(
      'frontproductstream.constants.packaging_type.many_logistical_packs',
      {
        defaultValue: 'logistical packs',
      }
    ),
    demonstrative: i18n.t(
      'frontproductstream.constants.packaging_type.this_pack',
      {
        defaultValue: 'this pack',
      }
    ),
    logisticalDemonstrative: i18n.t(
      'frontproductstream.constants.packaging_type.this_logistical_pack',
      {
        defaultValue: 'this logistical pack',
      }
    ),
  },
  CASE: {
    id: 2,
    value: 'CASE',
    label: i18n.t('frontproductstream.constants.packaging_type.case_label', {
      defaultValue: 'Case',
    }),
    singular: i18n.t('frontproductstream.constants.packaging_type.one_case', {
      defaultValue: 'case',
    }),
    plural: i18n.t('frontproductstream.constants.packaging_type.many_cases', {
      defaultValue: 'cases',
    }),
    demonstrative: i18n.t(
      'frontproductstream.constants.packaging_type.this_case',
      { defaultValue: 'this case' }
    ),
  },
  PALLET: {
    id: 3,
    value: 'PALLET',
    label: i18n.t('frontproductstream.constants.packaging_type.pallet_label', {
      defaultValue: 'Pallet',
    }),
    singular: i18n.t('frontproductstream.constants.packaging_type.one_pallet', {
      defaultValue: 'pallet',
    }),
    plural: i18n.t('frontproductstream.constants.packaging_type.many_pallets', {
      defaultValue: 'pallets',
    }),
    demonstrative: i18n.t(
      'frontproductstream.constants.packaging_type.this_pallet',
      {
        defaultValue: 'this pallet',
      }
    ),
  },
  DISPLAY_SHIPPER: {
    id: 4,
    value: 'DISPLAY_SHIPPER',
    label: i18n.t(
      'frontproductstream.constants.packaging_type.display_shipper_label',
      {
        defaultValue: 'Display shipper',
      }
    ),
    singular: i18n.t(
      'frontproductstream.constants.packaging_type.one_display_shipper',
      {
        defaultValue: 'display shipper',
      }
    ),
    plural: i18n.t(
      'frontproductstream.constants.packaging_type.many_display_shippers',
      {
        defaultValue: 'display shippers',
      }
    ),
    demonstrative: i18n.t(
      'frontproductstream.constants.packaging_type.this_display_shipper',
      {
        defaultValue: 'this display shipper',
      }
    ),
  },
};

export const TypePackagingIcons = {
  [TypePackagingLabels.EACH.id]: IconEach,
  [TypePackagingLabels.PACK.id]: IconPack,
  [TypePackagingLabels.CASE.id]: IconCase,
  [TypePackagingLabels.PALLET.id]: IconPallet,
};

export const availablePackagingTypes = Object.values(
  TypePackagingLabels
).filter((packagingType) => packagingType.id < 4);

export const getTypePackagingCode = (typePackaging) => {
  if (typePackaging && typePackaging.code) {
    // If the code is given, return that.
    return typePackaging.code;
  } else if (typePackaging && (typePackaging.id || typePackaging.id === 0)) {
    // Otherwise, infer it from the id.
    const typePackagingCodes = {
      0: 'EACH',
      1: 'PACK',
      2: 'CASE',
      3: 'PALLET',
      // Unfortunately needed as some transactions still have it
      4: 'CASE',
    };
    return typePackagingCodes[typePackaging.id];
  }
  // Else, we cannot infer anything.
  return '';
};

export const getLogisticalTypePackagingLabel = (
  code,
  isLogisticalUnit,
  singular
) => {
  const obj = TypePackagingLabels[code];
  if (!obj) {
    return null;
  }

  if (singular) {
    return isLogisticalUnit
      ? obj.logisticalSingular || obj.singular
      : obj.singular;
  }

  return isLogisticalUnit ? obj.logisticalPlural || obj.plural : obj.plural;
};

export const getLogisticalTypePackagingDemonstrative = (code) => {
  const obj = TypePackagingLabels[code];
  if (!obj) {
    return null;
  }

  return obj.logisticalDemonstrative || obj.demonstrative;
};
