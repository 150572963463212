import { Map, fromJS } from 'immutable';

import { TypePackagingLabels } from 'constants/typePackaging';
import i18n from 'utils/i18n';

export const errorMessages = {
  caseNotMultipleOfPack: i18n.t(
    'frontproductstream.product_page.sharing_unit_hierarchy_case_not_multiple_of_pack.error',
    {
      defaultValue:
        'The field "Case" must be a multiple of the field "Inner pack"',
    }
  ),
  palletNotMultipleOfPrevious: i18n.t(
    'frontproductstream.product_page.sharing_unit_hierarchy_pallet_not_multiple_of_previous.error',
    {
      defaultValue:
        'The field "Pallet" must be a multiple of the closest previous field',
    }
  ),
};

export const checkLinearHierarchy = (linearHierarchy) => {
  const errors = [];

  // check qttyPerCase
  if (
    linearHierarchy.get('qttyPerCase') > 0 &&
    linearHierarchy.get('qttyPerPack') > 0 &&
    linearHierarchy.get('qttyPerCase') % linearHierarchy.get('qttyPerPack') !==
      0
  ) {
    errors.push(errorMessages.caseNotMultipleOfPack);
  }

  // check qttyPerPallet
  const value =
    linearHierarchy.get('qttyPerCase') > 0
      ? linearHierarchy.get('qttyPerCase')
      : linearHierarchy.get('qttyPerPack');
  if (
    linearHierarchy.get('qttyPerPallet') > 0 &&
    value > 0 &&
    linearHierarchy.get('qttyPerPallet') % value !== 0
  ) {
    errors.push(errorMessages.palletNotMultipleOfPrevious);
  }

  return errors;
};

const getChildQuantity = (child) => {
  if (child.get('children') && child.get('children').size !== 0) {
    return (
      child.get('quantity') * getChildQuantity(child.getIn(['children', 0]))
    );
  }
  return child.get('quantity');
};

/*
 Crafts a "linear hierarchy" from a tree-style hierarchy.
 No error is raised if the hierarchy passed as argument is not linear per say, i.e.
 if a node has 2 or more children, only the first one (and its descendants) is taken
 into account.

 Pain points:
 - In the ALkemics world, pallet layer is not a type packaging. A layer is simply an attribute
 of the pallet
 - PCB is not the number of pack per case, but the number of product per case
 */
export const toLinearHierarchy = (hierarchy) => {
  const linearHierarchy = {
    gtinPallet: '',
    gtinCase: '',
    gtinPack: '',
    gtinEach: '',

    qttyPerPallet: '',
    qttyLayerPerPallet: '',
    qttyPerCase: '',
    qttyPerPack: '',
  };

  let hlevel = fromJS(hierarchy);
  while (hlevel && hlevel.get('children') && hlevel.get('children').size > 0) {
    // Handle the pallet
    if (
      hlevel.getIn(['typePackaging', 'id']) === TypePackagingLabels.PALLET.id
    ) {
      linearHierarchy.gtinPallet =
        hlevel.getIn(['isIdentifiedBy', 0, 'reference']) || '';
      linearHierarchy.qttyPerPallet = getChildQuantity(
        hlevel.getIn(['children', 0])
      );
      if (hlevel.get('quantityOfCompleteLayersContainedInATradeItem') !== 0) {
        linearHierarchy.qttyLayerPerPallet = hlevel.get(
          'quantityOfCompleteLayersContainedInATradeItem'
        );
      }
    }
    // Handle the case
    if (hlevel.getIn(['typePackaging', 'id']) === TypePackagingLabels.CASE.id) {
      linearHierarchy.gtinCase =
        hlevel.getIn(['isIdentifiedBy', 0, 'reference']) || '';
      linearHierarchy.qttyPerCase = getChildQuantity(
        hlevel.getIn(['children', 0])
      );
    }

    // Handle the pack
    if (hlevel.getIn(['typePackaging', 'id']) === TypePackagingLabels.PACK.id) {
      linearHierarchy.gtinPack =
        hlevel.getIn(['isIdentifiedBy', 0, 'reference']) || '';
      linearHierarchy.qttyPerPack = getChildQuantity(
        hlevel.getIn(['children', 0])
      );
    }

    // Get down one level
    hlevel = hlevel.getIn(['children', 0]);
  }

  if (
    hlevel &&
    hlevel.getIn(['typePackaging', 'id']) === TypePackagingLabels.EACH.id
  ) {
    linearHierarchy.gtinEach =
      hlevel.getIn(['isIdentifiedBy', 0, 'reference']) || '';
  }

  return fromJS(linearHierarchy);
};

const addLevel = (child, typePackagingId, quantity, gtin) => {
  let qtty = parseInt(quantity, 10);
  if (Number.isNaN(Number(qtty)) || qtty <= 0) {
    return child;
  }
  // Handle new hierarchy table where we have the number of product for each node
  // We need to divide the quantity by the number of product in the child
  if (child.get('children')) {
    if (qtty % child.getIn(['children', 0]).get('quantity') !== 0) {
      return child;
    }
    qtty /= getChildQuantity(child.getIn(['children', 0]));
  }

  let newHierarchy = fromJS({
    typePackaging: { id: typePackagingId },
  });
  if (gtin && gtin.length > 0) {
    newHierarchy = newHierarchy.set(
      'isIdentifiedBy',
      fromJS([{ reference: gtin }])
    );
  }

  return newHierarchy.set('children', fromJS([child.set('quantity', qtty)]));
};

export const fromLinearHierarchy = (linearHierarchy) => {
  let hierarchy = fromJS({});

  if (!linearHierarchy) {
    return Map();
  }

  if (
    linearHierarchy.get('gtinEach') &&
    linearHierarchy.get('gtinEach').length > 0
  ) {
    hierarchy = fromJS({
      typePackaging: { id: TypePackagingLabels.EACH.id },
      isIdentifiedBy: [{ reference: linearHierarchy.get('gtinEach') }],
    });
  }

  // Push pack
  hierarchy = addLevel(
    hierarchy,
    TypePackagingLabels.PACK.id,
    linearHierarchy.get('qttyPerPack'),
    linearHierarchy.get('gtinPack')
  );

  // Push case
  hierarchy = addLevel(
    hierarchy,
    TypePackagingLabels.CASE.id,
    linearHierarchy.get('qttyPerCase'),
    linearHierarchy.get('gtinCase')
  );

  // Push pallet
  hierarchy = addLevel(
    hierarchy,
    TypePackagingLabels.PALLET.id,
    linearHierarchy.get('qttyPerPallet'),
    linearHierarchy.get('gtinPallet')
  );

  // Push layer
  if (
    hierarchy.getIn(['typePackaging', 'id']) === TypePackagingLabels.PALLET.id
  ) {
    if (linearHierarchy.get('qttyLayerPerPallet') > 0) {
      hierarchy = hierarchy.set(
        'quantityOfCompleteLayersContainedInATradeItem',
        parseInt(linearHierarchy.get('qttyLayerPerPallet'), 10)
      );
    } else {
      hierarchy = hierarchy.set(
        'quantityOfCompleteLayersContainedInATradeItem',
        0
      );
    }
  }

  return hierarchy;
};
