import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectDefaultTargetMarket } from 'reducers/user/selectors';
import { parseNumber } from 'utils';

export function useTargetMarket(): number {
  const defaultTargetMarket = useSelector(selectDefaultTargetMarket);
  const [queries] = useSearchParams();
  return parseNumber(
    queries.get('targetMarket') || defaultTargetMarket.get('id')
  ) as number;
}
