import {
  call,
  cancel,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { getProductKeyId } from 'core/api/product';
import serviceProductApi from 'resources/serviceProductApi';
import { requestWithHeaders } from 'utils/api';
import { withCatch } from 'utils/saga';

import * as actions from './actions';
import * as events from './events';
import * as selectors from './selectors';

export default function* bulkSetAsPublicMainSaga() {
  let bulkSetAsPublicTask;

  function* _start() {
    bulkSetAsPublicTask = yield fork(withCatch(bulkSetAsPublic));
  }

  function* _cancel() {
    if (bulkSetAsPublicTask) {
      yield cancel(bulkSetAsPublicTask);
    }
  }

  yield takeEvery(events.CANCEL, _cancel);
  yield takeLatest(events.START, _start);
}

export function* bulkSetAsPublic() {
  const _versions = yield select(selectors.selectProductVersions);
  const versions = _versions.valueSeq().toJS();

  for (let i = 0; i < versions.length; i += 1) {
    const productKeyId = getProductKeyId(versions[i]);
    const { error } = yield call(
      requestWithHeaders,
      serviceProductApi,
      'post',
      '/product/v2/products/visibility',
      {
        product_key_ids: [productKeyId],
        exclusive: false,
      }
    );
    let status = 'success';
    if (error) {
      status = 'danger';
    }
    yield put(
      actions.updateProgress({
        productVersionId: versions[i].id,
        count: i + 1,
        status,
      })
    );
  }

  yield put(actions.done());
}
