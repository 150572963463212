import PropTypes from 'prop-types';

import Autocomplete from 'components/ui/autocomplete';
import { getReference } from 'core/api/product';
import {
  getProductId,
  getDefaultDisplayName as getProductVersionDefaultDisplayName,
  getDisplayName as getProductVersionDisplayName,
} from 'core/api/productversion';
import { searchApi } from 'resources/searchApi';
import { dedupe } from 'utils';
import { isEAN } from 'utils/actions/list';

export class SearchAutocomplete extends Autocomplete {
  static propTypes = Object.assign({}, Autocomplete.propTypes, {
    sort: PropTypes.string,
    path: PropTypes.string,
    limit: PropTypes.number,
    minInput: PropTypes.number,
    dataFormatter: PropTypes.func,
    currentLanguage: PropTypes.object,
    locales: PropTypes.object,
  });

  static defaultProps = Object.assign({}, Autocomplete.defaultProps, {
    limit: 9,
    dataFormatter: null,
  });

  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this);
  }

  formatter = (item) => {
    let label;
    if (this.props.locales && this.props.locales.size > 0) {
      label = `${getReference(item)} - ${getProductVersionDefaultDisplayName(
        item,
        this.props.locales
      )}`;
    } else {
      label = `${getReference(item)} - ${getProductVersionDisplayName(
        item,
        this.props.currentLanguage
      )}`;
    }

    const id = getProductId(item);
    const value = {
      id,
      key: item.id,
      label,
      isIdentifiedBy: item.specializes.isIdentifiedBy,
      product_key_id: item.product_key.id,
    };

    if (this.props.body && this.props.body.filter_source_include) {
      for (const source of this.props.body.filter_source_include) {
        value[source] = item[source];
      }
    }

    return {
      label,
      key: item.id,
      value,
    };
  };

  parseBody(search) {
    const body = { ...this.props.body };
    if (search) {
      if (isEAN(search)) {
        body.filter_gtins_in = search.split(',').map((gtin) => gtin.trim());
      } else {
        body.q = search;
      }
    }
    body.offset = 0;
    body.limit = this.props.limit;
    body.filter_source_include = dedupe(
      (body.filter_source_include || []).concat([
        'id',
        'namePublicLong',
        'gtin',
        'typePackaging',
        'specializes.id',
        'specializes.isIdentifiedBy',
        'tags.targetMarket.id',
        'product_key.id',
        'productIdentifier',
      ])
    );
    return body;
  }

  getList(search) {
    const { path, minInput, dataFormatter } = this.props;

    if (minInput && search !== undefined && search.length < minInput) {
      return null;
    }
    const promise = searchApi.post(path, this.parseBody(search));
    promise.then((response) => {
      const formatter = dataFormatter || this.formatter;
      const list = response.data.data.map((result) => formatter(result));
      this.updateList(list, search === '');
    });
    return promise;
  }
}

export default SearchAutocomplete;
