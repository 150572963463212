import { List, Map } from 'immutable';
import { isUndefined } from 'lodash';
import { createSelector } from 'reselect';

import { APPLICATION_TYPE_CONTRIBUTION } from 'constants/appStore';
import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { TRANSACTION_STATUS_REJECTED } from 'constants/transaction';
import { selectSourceProductVersion } from 'reducers/productVersion';
import { selectUser } from 'reducers/user/selectors';
import { get } from 'utils/immutable';

import {
  CONTRIBUTION_TYPE_PHYSICAL_CHECKER,
  MODULE_NAME,
  PHYSICAL_CHECK_FIELD,
} from '../constants';

export const getContribution = (state) =>
  state[MODULE_REDUCER_KEY][MODULE_NAME];

export const getStateMachine = createSelector(getContribution, (contribution) =>
  contribution.get('stateMachine')
);

export const getActiveApplicationsTypeContribution = createSelector(
  selectUser,
  (user) =>
    get(user, ['applications'], List()).filter(
      (app) => get(app, ['type']) === APPLICATION_TYPE_CONTRIBUTION.id
    )
);

export const getPhysicalCheckFields = createSelector(
  getContribution,
  (contribution) => contribution.get('physicalCheckFields')
);

export const getReviewFields = createSelector(getContribution, (contribution) =>
  contribution.get('reviewFields')
);

export const getSelectedFields = createSelector(
  getPhysicalCheckFields,
  (fields) => fields.filter((value) => !isUndefined(value))
);

export const getPhysicalCheckContribution = createSelector(
  getContribution,
  (contribution) =>
    contribution.getIn([
      'contributionsByContributionType',
      String(CONTRIBUTION_TYPE_PHYSICAL_CHECKER),
    ]) || Map()
);

export const getPhysicalCheckFailedFields = createSelector(
  getPhysicalCheckFields,
  (fields) => fields.filter((value) => value === PHYSICAL_CHECK_FIELD.FAIL)
);

export const getPhysicalCheckPassedFields = createSelector(
  getPhysicalCheckFields,
  (fields) => fields.filter((value) => value === PHYSICAL_CHECK_FIELD.PASS)
);

export const getPhysicalCheckFailedFieldNames = createSelector(
  getPhysicalCheckFailedFields,
  (fields) => fields.keySeq()
);

export const getProductVersionDataForFailedField = createSelector(
  getPhysicalCheckFailedFieldNames,
  selectSourceProductVersion,
  (failedFieldNames, productversion) =>
    failedFieldNames.reduce((acc, failedFieldName) => {
      acc[failedFieldName] = productversion[failedFieldName];
      return acc;
    }, {})
);

export const hasPhysicalCheckCanCannotCheck = createSelector(
  getPhysicalCheckFields,
  (fields) => fields.count() === 0
);

export const getCannotCheckModalOpen = createSelector(
  getContribution,
  (contribution) => contribution.getIn(['cannotCheckModal', 'isOpen'], false)
);

export const getCannotCheckModalSaving = createSelector(
  getContribution,
  (contribution) => contribution.getIn(['cannotCheckModal', 'isSaving'], false)
);

export const getDisputeModalOpen = createSelector(
  getContribution,
  (contribution) => contribution.getIn(['disputeModal', 'isOpen'], false)
);

export const getDisputeModalSaving = createSelector(
  getContribution,
  (contribution) => contribution.getIn(['disputeModal', 'isSaving'], false)
);

export const getDiffModalOpen = createSelector(
  getContribution,
  (contribution) => contribution.getIn(['diffModal', 'isOpen'], false)
);

export const getDiffModalSaving = createSelector(
  getContribution,
  (contribution) => contribution.getIn(['diffModal', 'isSaving'], false)
);

export const getDisputedFields = createSelector(
  getPhysicalCheckContribution,
  (contributions) =>
    contributions
      .get('fields', List())
      .filter((field) => field.get('status') === TRANSACTION_STATUS_REJECTED)
      .map((field) => field.get('name'))
);
