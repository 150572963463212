import { takeLatest } from 'redux-saga/effects';

import { logError } from 'utils/logging';

import { SEND_ASSIGNATION } from '../constants';

import sendAssignationSaga from './send-assignation';

export default function* targetRequestModalMainSaga() {
  try {
    yield takeLatest(SEND_ASSIGNATION, sendAssignationSaga);
  } catch (e) {
    logError(e);
  }
}
