import { List } from 'immutable';

import {
  SETTING_ACCEPTED_SOURCING,
  SETTING_ALLOW_PUSH,
  SETTING_ASSIGNATION,
  SETTING_ASSORTMENT_MANAGEMENT,
  SETTING_AUTO_ACCEPT_SHARE_CREATE,
  SETTING_AUTO_ACCEPT_SHARE_UPDATE,
  SETTING_AUTO_PUBLISH,
  SETTING_BIG_MANUFACTURER,
  SETTING_ELIGIBLE_FOR_SOURCING,
  SETTING_ENABLE_VALUE,
  SETTING_EXCLUSIVE_PRODUCTS,
  SETTING_GROUP_PERMISSIONS,
  SETTING_INFORMATION_REQUEST,
  SETTING_LEGACY_TEXTILE,
  SETTING_LISTING,
  SETTING_LISTING_COMPULSORY,
  SETTING_MARKET_PERMISSIONS,
  SETTING_ORGANIZATION_CHAT,
  SETTING_PRICE_DISPLAYUNITS,
  SETTING_PRICE_DISPLAYUNITS_ALL_VALUE,
  SETTING_PRICE_DISPLAYUNITS_CONSUMERUNITS_VALUE,
  SETTING_PRICE_DISPLAYUNITS_DISPLAY_VALUE,
  SETTING_PRICE_HLU,
  SETTING_PRICE_HLU_ALL_VALUE,
  SETTING_PRICE_HLU_CONSUMERUNITS_VALUE,
  SETTING_PRICE_HLU_HETEROGENEOUS_VALUE,
  SETTING_PRODUCT_COMMENT,
  SETTING_READONLY_FIELDS,
  SETTING_RFP,
  SETTING_SKU_GTIN_ZPCK,
  SETTING_SOURCINGLABEL_SHARE,
  SETTING_SOURCING_DATA,
  SETTING_VIEW_SOURCING,
  SINGLE_EXPORTABLE_GTIN,
  SUPPLIER_DIRECTORY,
  TARIFF,
} from 'constants/organization-settings';
import { get, getKey } from 'utils/immutable';

export const LISTING_HIERARCHY_SETTING = 'listing.hierarchy';

/*
 * PLEASE READ!
 *
 * Some setting keys are containing dots, we need to use getKey in order to work
 * For example
 * - getKey({'some.key': 1}, 'some.key') === 1, but
 * - get({'some.key': 1}, 'some.key') === undefined
 */

export const hasListing = (settings) =>
  getKey(settings, SETTING_LISTING) === SETTING_ENABLE_VALUE;

export const hasCompulsoryListing = (settings) =>
  !!getKey(settings, SETTING_LISTING_COMPULSORY);

export const hasAllowPush = (settings) =>
  !!getKey(settings, SETTING_ALLOW_PUSH);

export const hasAutoAcceptShareCreate = (settings) =>
  getKey(settings, SETTING_AUTO_ACCEPT_SHARE_CREATE) === SETTING_ENABLE_VALUE;

export const hasAutoAcceptShareUpdate = (settings) =>
  getKey(settings, SETTING_AUTO_ACCEPT_SHARE_UPDATE) === SETTING_ENABLE_VALUE;

export const hasMatureListing = (settings) =>
  getKey(settings, LISTING_HIERARCHY_SETTING) === 'mature';

export const hasImmatureListing = (settings) =>
  getKey(settings, LISTING_HIERARCHY_SETTING) === 'immature';

export const hasViewSourcing = (settings) =>
  getKey(settings, SETTING_VIEW_SOURCING) === SETTING_ENABLE_VALUE;

export const isEligibleForSourcing = (settings) =>
  getKey(settings, SETTING_ELIGIBLE_FOR_SOURCING) === SETTING_ENABLE_VALUE;

export const hasAssortmentManagement = (settings) =>
  getKey(settings, SETTING_ASSORTMENT_MANAGEMENT) === SETTING_ENABLE_VALUE;

export const hasOnlyExclusiveProducts = (settings) =>
  getKey(settings, SETTING_EXCLUSIVE_PRODUCTS) === SETTING_ENABLE_VALUE;

export const hasLegacyTextile = (settings) =>
  !!getKey(settings, SETTING_LEGACY_TEXTILE);

export const hasAcceptedSourcing = (settings) =>
  getKey(settings, SETTING_ACCEPTED_SOURCING) === SETTING_ENABLE_VALUE;

export const getSourcingData = (settings) =>
  get(settings, SETTING_SOURCING_DATA);

export const getOrganizationSourcingListColumns = (settings) =>
  settings.getIn(['listcolumns', 'sourcing'], List());

export const getOrganizationListColumns = (settings, columnsKey) =>
  settings.getIn(['listcolumns', columnsKey], List());

export const hasAssignation = (settings) =>
  settings.get(SETTING_ASSIGNATION) === SETTING_ENABLE_VALUE;

export const hasInformationRequest = (settings) =>
  settings.get(SETTING_INFORMATION_REQUEST, false) === true;

export const hasProductCommentSetting = (settings) =>
  settings.get(SETTING_PRODUCT_COMMENT, true) === true;

export const hasOrganizationChatSetting = (settings) =>
  settings.get(SETTING_ORGANIZATION_CHAT, true) === true;

export const hasTagOnShareSetting = (settings) =>
  settings.get('tagonshare', 'disable') === SETTING_ENABLE_VALUE;

export const hasCustomSkuGtinZpckSetting = (settings) => {
  return settings.get(SETTING_SKU_GTIN_ZPCK) === true;
};

export const hasAllPriceDisplayUnitSetting = (settings) =>
  getKey(settings, SETTING_PRICE_DISPLAYUNITS) ===
  SETTING_PRICE_DISPLAYUNITS_ALL_VALUE;

export const hasDisplayPriceDisplayUnitSetting = (settings) =>
  getKey(settings, SETTING_PRICE_DISPLAYUNITS) ===
  SETTING_PRICE_DISPLAYUNITS_DISPLAY_VALUE;

export const hasCuPriceDisplayUnitSetting = (settings) =>
  getKey(settings, SETTING_PRICE_DISPLAYUNITS) ===
  SETTING_PRICE_DISPLAYUNITS_CONSUMERUNITS_VALUE;

export const hasNoPriceDisplayUnitSetting = (settings) =>
  !getKey(settings, SETTING_PRICE_DISPLAYUNITS);

export const hasAllPriceHluSetting = (settings) =>
  getKey(settings, SETTING_PRICE_HLU) === SETTING_PRICE_HLU_ALL_VALUE;

export const hasHeterogeneousPriceHluSetting = (settings) =>
  getKey(settings, SETTING_PRICE_HLU) === SETTING_PRICE_HLU_HETEROGENEOUS_VALUE;

export const hasCuPriceHluSetting = (settings) =>
  getKey(settings, SETTING_PRICE_HLU) === SETTING_PRICE_HLU_CONSUMERUNITS_VALUE;

export const hasNoPriceHluSetting = (settings) =>
  !getKey(settings, SETTING_PRICE_HLU);

export const hasAutoPublish = (settings) =>
  getKey(settings, SETTING_AUTO_PUBLISH) === SETTING_ENABLE_VALUE;

export const hasSourcingLabelShare = (settings) =>
  getKey(settings, SETTING_SOURCINGLABEL_SHARE) === SETTING_ENABLE_VALUE;

export const hasRfp = (settings) => getKey(settings, SETTING_RFP) === true;

export const isBigManufacturer = (settings) =>
  getKey(settings, SETTING_BIG_MANUFACTURER) === true;

export const hasGroupPermissions = (settings) =>
  getKey(settings, SETTING_GROUP_PERMISSIONS) === SETTING_ENABLE_VALUE;

export const hasMarketPermissions = (settings) =>
  getKey(settings, SETTING_MARKET_PERMISSIONS) === SETTING_ENABLE_VALUE;

export const getReadOnlyCustomFields = (settings) =>
  settings.getIn([SETTING_READONLY_FIELDS, 'add_custom'], List());

export const getReadOnlyFields = (settings) =>
  settings.getIn([SETTING_READONLY_FIELDS, 'add_field_ids'], List());

export const hasTariff = (settings) => getKey(settings, TARIFF) === true;

export const hasSupplierDirectory = (settings) =>
  get(settings, `${SUPPLIER_DIRECTORY}.enabled`) === true;

export const hasSingleExportableGtin = (settings) =>
  getKey(settings, SINGLE_EXPORTABLE_GTIN) === true;
