import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { addFieldCommentToPendingMessage } from 'modules/product-page/modules/normalized-comments/actions';
import { acceptSuggestion } from 'modules/product-page/modules/product-suggestions/actions';
import { RuleTemplateLabel, ValidationWorkflowType } from 'modules/validation';
import { selectCurrentLanguage } from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { track } from 'utils/tracking';

import {
  getSuggestedLabel,
  isBlocking,
  isNormalizedComment,
  isRealtimeInternalTaxonomySuggestion,
  isSuggestionDisplayedAsHelp,
  isSuggestionDisplayedAsWarning,
} from '../utils';

import HelpSuggestion from './help';
import InternalTaxonomySuggestion from './internal-taxonomy';
import WarningSuggestion from './warning';

const mapStateToProps = (state) => ({
  currentLanguage: selectCurrentLanguage(state),
});

const mapDispatchToProps = {
  acceptSuggestion,
  addFieldCommentToPendingMessage,
};

export class RaguelSuggestion extends PureComponent {
  static propTypes = {
    currentLanguage: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }),
    label: PropTypes.string.isRequired,
    ruleResult: ImmutablePropTypes.map.isRequired,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    dismiss: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isRetailer: PropTypes.bool,
    displayActions: PropTypes.bool,
    index: PropTypes.number,
    actions: PropTypes.shape({
      acceptSuggestion: PropTypes.func.isRequired,
      addFieldCommentToPendingMessage: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    readOnly: false,
    displayActions: true,
  };

  state = {
    accepted: false,
  };

  componentDidMount() {
    this.trackEvent('displayed');
  }

  onAcceptSuggestion = () => {
    const { entityId, ruleResult, actions, readOnly, label, currentLanguage } =
      this.props;
    if (!readOnly) {
      actions.acceptSuggestion(ruleResult);
    } else if (readOnly) {
      const msgData = {
        type: ValidationWorkflowType.SUGGESTION,
        label,
        value: ruleResult.getIn([
          'error_by_language',
          currentLanguage.code,
          'errorLabel',
        ]),
      };
      msgData.formattedMessage = [
        RuleTemplateLabel.SIMPLE,
        RuleTemplateLabel.VALUE,
      ].includes(ruleResult.get('templateLabel'))
        ? i18n.t(
            'frontproductstream.plugins.suggestion.feedback_message.equals_text',
            {
              defaultValue: '"{{label}}" should be "{{value}}"',
              label,
              value: msgData.value,
            }
          )
        : i18n.t(
            'frontproductstream.plugins.suggestion.feedback_message.contains_text',
            {
              defaultValue: '"{{label}}" should contain "{{value}}"',
              label,
              value: msgData.value,
            }
          );

      actions.addFieldCommentToPendingMessage(msgData, entityId);
      this.setState({ accepted: true });
    }
    this.trackEvent('accepted');
  };

  onDismissSuggestion = () => {
    const { ruleResult, dismiss } = this.props;
    dismiss(ruleResult, isNormalizedComment(ruleResult));
    this.trackEvent('dismissed');
  };

  trackEvent(event) {
    const { entityId, ruleResult, currentLanguage } = this.props;
    // in suggested rules, the shortest path corresponds to the base path, i.e. the fieldmetadata key
    // ruleResult.paths looks like: { a: ["kind.id"], b: ["kind"] }
    const fmd = ruleResult
      .get('paths')
      .filter((p) => p.size > 0)
      .minBy((e) => e.get(0).length)
      .get(0);

    if (isNormalizedComment(ruleResult)) {
      track({
        category: 'product',
        action: `product_normalized_comment_${event}`,
        label: `product_version#${entityId}`,
        fieldmetadata: fmd,
        targetOrganizationId: ruleResult.get('targetOrganizationId'),
      });
    } else {
      track({
        category: 'product',
        action: `product_suggestion_${event}`,
        label: `product_version#${entityId}`,
        fieldmetadata: fmd,
        value: getSuggestedLabel(
          ruleResult.getIn([
            'data',
            'suggestedValueByLanguage',
            currentLanguage.code,
          ]),
          ruleResult.get('templateLabel')
        ),
      });
    }
  }

  render() {
    const {
      ruleResult,
      readOnly,
      isRetailer,
      displayActions,
      currentLanguage,
      index,
    } = this.props;

    const isNC = isNormalizedComment(ruleResult);
    const isBlockingRule = isBlocking(ruleResult);

    if (!currentLanguage) {
      return null;
    }
    return (
      <div>
        {(isSuggestionDisplayedAsWarning(ruleResult) || isBlockingRule) && (
          <WarningSuggestion
            languageCode={currentLanguage.code}
            ruleResult={ruleResult}
            readOnly={readOnly}
            isRetailer={isRetailer}
            displayActions={displayActions}
            onAcceptSuggestion={this.onAcceptSuggestion}
            onDismissSuggestion={this.onDismissSuggestion}
            isNormalizedComment={isNC}
            isBlockingRule={isBlockingRule}
            accepted={this.state.accepted}
          />
        )}
        {isSuggestionDisplayedAsHelp(ruleResult) && (
          <HelpSuggestion
            ruleResult={ruleResult}
            readOnly={readOnly}
            isRetailer={isRetailer}
            displayActions={displayActions}
            onAcceptSuggestion={this.onAcceptSuggestion}
            onDismissSuggestion={this.onDismissSuggestion}
            accepted={this.state.accepted}
            languageCode={currentLanguage.code}
          />
        )}
        {isRealtimeInternalTaxonomySuggestion(ruleResult) && (
          <InternalTaxonomySuggestion
            ruleResult={ruleResult}
            readOnly={readOnly}
            isRetailer={isRetailer}
            displayActions={displayActions}
            onDismissSuggestion={this.onDismissSuggestion}
            languageCode={currentLanguage.code}
            index={index}
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(RaguelSuggestion);
