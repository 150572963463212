import Immutable from 'immutable';
import { map, pickBy, update } from 'lodash/fp';
import { createSelector } from 'reselect';

import { KIND_BASE } from 'constants/concepts';
import { getEditedLogisticalUnitsAsTree } from 'modules/logistical-hierarchies/selectors';
import { selectSharingunitsList } from 'modules/product-page/modules/retailer-sharing-units/selectors';
import { selectRFPs } from 'modules/product-page/modules/rfps/selectors';
import { selectAllRecipientSpecificData } from 'modules/recipient-specific-block/selectors';
import { getSharingUnitsIndexed } from 'modules/sharing-units/selectors';
import { selectAssets, selectSpecificAssets } from 'reducers/media';
import {
  selectCurrentLanguage,
  selectEditedProductVersion,
  selectProductKeyId,
} from 'reducers/productVersion';
import { selectIsRetailer } from 'reducers/user/selectors';

function seedPVInHierarchy(logisticalUnit, _data) {
  const lu = logisticalUnit;
  if (logisticalUnit.gtin === _data.gtin) {
    lu.version = { ..._data };
  } else if (logisticalUnit.children && logisticalUnit.children.length) {
    lu.children = logisticalUnit.children.map((child) =>
      seedPVInHierarchy(child, _data)
    );
  }
  return lu;
}

export const removeEmptyAssetLists = map(
  update(
    'data',
    pickBy((assetList: any[]) => assetList.length)
  )
);

export const selectEnrichedData = createSelector(
  selectIsRetailer,
  selectEditedProductVersion,
  selectCurrentLanguage,
  selectAssets,
  getSharingUnitsIndexed,
  selectSharingunitsList,
  getEditedLogisticalUnitsAsTree,
  selectAllRecipientSpecificData,
  selectSpecificAssets,
  selectProductKeyId,
  selectRFPs,
  (
    isRetailer: boolean,
    productVersion: undefined | any,
    language: any,
    assets: any,
    manufacturerSharingUnits: Immutable.Map<string, any>,
    retailerSharingUnits: Immutable.List<any>,
    logisticalHierarchies: any[],
    allRecipientSpecificData: Immutable.List<any>,
    specificAssets: any,
    productKeyId: number,
    rfps: any
  ) => {
    if (!productVersion) {
      return { data: null, language: null };
    }

    const data = Object.assign({}, productVersion);

    // add assets
    data.assets = assets;

    // logistical hierarchies
    data.logisticalHierarchies = logisticalHierarchies.map((lh) =>
      seedPVInHierarchy(lh, data)
    );

    // sharing units
    data.sharingUnits = isRetailer
      ? retailerSharingUnits.toJS()
      : manufacturerSharingUnits.toList().toJS();

    // add specificData
    data.specificData = allRecipientSpecificData.toJS();

    // add (cleaned) specificAssets
    data.specificAssets = removeEmptyAssetLists(specificAssets);

    // put in default kind
    if (!data.kind) {
      data.kind = { id: KIND_BASE };
    }

    // RFPs.
    data.rfps = rfps;

    // Product key.
    data.product_key = { id: productKeyId };

    return { data, language };
  }
);
