import { Component } from 'react';

import { SimpleSelect } from '@alkem/react-ui-select';
import { Referential } from '@alkem/sdk-dashboard';

import { SelectCountries } from 'components/ui/autocomplete/countries/select-countries';
import InputWithLabel from 'components/ui/input/input-with-label';
import InputText from 'components/ui/input/text';
import i18n from 'utils/i18n';

import { LegalIdentifierType } from './legal-identifier-type';

type CountryReferential = Referential<{}>;

interface Props {
  legalIdentifierTypes: LegalIdentifierType[];
  updateValue: (type) => (Event) => void;
  getValue: (type: string) => string;
  setCountry: (country) => void;
  getCountry: () => CountryReferential | null;
}

interface State {
  selectedType: string;
}

export default class AddSingleLegalIdentifier extends Component<Props, State> {
  public state: State = {
    selectedType: '',
  };

  private updateType = (_type) => {
    this.setState({ selectedType: _type.id });
  };

  private findSelectedType = () => {
    const { selectedType } = this.state;
    return this.props.legalIdentifierTypes.find(
      (o) => o !== undefined && o.id === selectedType
    );
  };

  public render() {
    const { selectedType } = this.state;
    const selectedCountry = this.props.getCountry();
    return (
      <>
        <div>
          <InputWithLabel
            inputId="field-tags"
            label={i18n.t(
              'frontproductstream.add_legal_identifier.legal_identifier_type.text',
              {
                defaultValue: 'Legal Identifier Type',
              }
            )}
            data-testid="add-legalidentifier-type"
          >
            <SimpleSelect
              id="add-legalidentifier-type"
              onSelect={this.updateType}
              options={this.props.legalIdentifierTypes}
              value={this.findSelectedType()}
              placeholder={i18n.t(
                'frontproductstream.add_legal_identifier.select.placeholder',
                {
                  defaultValue: 'Select...',
                }
              )}
            />
          </InputWithLabel>
          {selectedType === 'VAT' && (
            <InputWithLabel
              inputId="vat-country"
              label={i18n.t(
                'frontproductstream.add_legal_identifier.select_country.placeholder',
                {
                  defaultValue: 'Select country',
                }
              )}
              data-testid="vat-country"
            >
              <SelectCountries
                ariaLabel="vat-country"
                value={selectedCountry ? selectedCountry : null}
                onChange={this.props.setCountry}
              />
            </InputWithLabel>
          )}
          <InputText
            id="add-legalidentifier-value"
            data-testid="add-legalidentifier-value"
            label={i18n.t(
              'frontproductstream.add_legal_identifier.legal_identifier_value.text',
              {
                defaultValue: 'Legal Identifier Value',
              }
            )}
            onChange={this.props.updateValue(selectedType)}
            value={this.props.getValue(selectedType)}
          />
        </div>
      </>
    );
  }
}
