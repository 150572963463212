import classNames from 'classnames';
import { flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';

import {
  DISPUTE_1_TO_VALIDATE,
  DISPUTE_2_TO_VALIDATE,
  DISPUTE_3_TO_VALIDATE,
  FAIL,
} from 'constants/physicalCheckerStatus';
import { withUserType } from 'hocs';
import { AnchoredSection } from 'modules/anchored';
import { HistoryDiffLine } from 'modules/history';
import i18n from 'utils/i18n';

import { confirmReview, setReviewField } from '../../actions';
import { getPhysicalCheckContribution, getReviewFields } from '../../selectors';
import DisputeModal from '../modals/dispute-modal';

import './physicalCheckDiff.scss';

const mapStateToProps = createStructuredSelector({
  contribution: getPhysicalCheckContribution,
  reviewFields: getReviewFields,
});

const mapDispatchToProps = {
  setReviewField,
  confirmReview,
};

const enhance = flow(
  connect(mapStateToProps, mapDispatchToProps),
  withUserType
);

export class PhysicalCheckDiff extends Component {
  static propTypes = {
    contribution: ImmutablePropTypes.map.isRequired,
    isPhysicalCheckManufacturer: PropTypes.bool.isRequired,
    reviewFields: ImmutablePropTypes.map.isRequired,
    setReviewField: PropTypes.func.isRequired,
    confirmReview: PropTypes.func.isRequired,
    order: PropTypes.number,
  };

  setReviewfield = (transactionId, isAccepted) => () =>
    this.props.setReviewField(transactionId, isAccepted);

  renderHeader() {
    return (
      <div className="PhysicalCheckDiff_FailedField__header row">
        <div className="col-xs-10">
          <div className="row">
            <div className="col-xs-4">
              {i18n.t(
                'frontproductstream.physical_check_review_table_section.column_field_name.label',
                { defaultValue: 'Field name' }
              )}
            </div>
            <div className="col-xs-4">
              {i18n.t(
                'frontproductstream.physical_check_review_table_section.column_old_value.label',
                { defaultValue: 'Old value' }
              )}
            </div>
            <div className="col-xs-4">
              {i18n.t(
                'frontproductstream.physical_check_review_table_section.column_new_value.label',
                { defaultValue: 'New value' }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDiffButtons(field) {
    const { reviewFields, contribution } = this.props;

    return (
      <div className="PhysicalCheckDiff_FailedField__button btn-group col-xs-2">
        {DISPUTE_3_TO_VALIDATE.id !== contribution.get('status') && (
          <Button
            secondary
            className={classNames({
              selected: reviewFields.get(field.get('transaction_id')) === false,
            })}
            onClick={this.setReviewfield(field.get('transaction_id'), false)}
            id={`#PhysicalCheckDiff_FailedField__button_refuse--${field.get(
              'name'
            )}`}
          >
            <span>
              {i18n.t(
                'frontproductstream.physical_check_review_table_section.dispute_a_correction.button',
                { defaultValue: 'Dispute' }
              )}
            </span>
          </Button>
        )}
        <Button
          secondary
          className={classNames({
            selected: reviewFields.get(field.get('transaction_id')) === true,
          })}
          onClick={this.setReviewfield(field.get('transaction_id'), true)}
          id={`PhysicalCheckDiff_FailedField__button_accept--${field.get(
            'name'
          )}`}
        >
          <span>
            {i18n.t(
              'frontproductstream.physical_check_review_table_section.accept_a_correction.button',
              { defaultValue: 'Accept' }
            )}
          </span>
        </Button>
      </div>
    );
  }

  renderDiff(field) {
    return (
      <>
        <div className="PhysicalCheckDiff_FailedField__diff col-xs-10">
          {field.get('diff').map((diff) => (
            <HistoryDiffLine
              key={`PhysicalCheckDiff_FailedField__field_diff--${JSON.stringify(
                diff.get('label')
              )}`}
              diff={diff}
            />
          ))}
        </div>
        {this.renderDiffButtons(field)}
      </>
    );
  }

  renderEmpty(field) {
    this.props.setReviewField(field.get('transaction_id'), true);
    return (
      <div className="PhysicalCheckDiff_FailedField__diff--empty col-xs-12">
        {field.get('label')}{' '}
        {i18n.t(
          'frontproductstream.physical_check_review_table_section.a_correction_is_already_accepted.text',
          { defaultValue: 'is already accepted' }
        )}
      </div>
    );
  }

  renderField(field) {
    return (
      <div
        className="PhysicalCheckDiff_FailedField__field row"
        key={`PhysicalCheckDiff_FailedField__field--${field.get('name')}`}
      >
        {field.get('diff') &&
          field.get('diff').size > 0 &&
          this.renderDiff(field)}
        {field.get('diff') &&
          field.get('diff').size === 0 &&
          this.renderEmpty(field)}
      </div>
    );
  }

  renderButton() {
    const { contribution, reviewFields } = this.props;
    const remainingFieldsCount =
      contribution.get('fields').size - reviewFields.size;
    if (remainingFieldsCount > 0) {
      return (
        <div className="PhysicalCheckDiff_ButtonBar">
          <span className="PhysicalCheckDiff_ButtonBar--warning-message">{`${remainingFieldsCount} ${i18n.t(
            'frontproductstream.physical_check_review_footer_section.warn_some_fields_are_pending_review.button',
            { defaultValue: 'fields need to be approved' }
          )}`}</span>
        </div>
      );
    }
    return (
      <div className="PhysicalCheckDiff_ButtonBar">
        <Button
          primary
          onClick={this.props.confirmReview}
          content={i18n.t(
            'frontproductstream.physical_check_review_footer_section.confirm.button',
            { defaultValue: 'Confirm this review' }
          )}
          disabled={false}
          displaySpinner={false}
        />
      </div>
    );
  }

  render() {
    const { contribution, isPhysicalCheckManufacturer, order } = this.props;
    if (!isPhysicalCheckManufacturer) {
      return null;
    }
    if (
      ![
        FAIL.id,
        DISPUTE_1_TO_VALIDATE.id,
        DISPUTE_2_TO_VALIDATE.id,
        DISPUTE_3_TO_VALIDATE.id,
      ].includes(contribution.get('status'))
    ) {
      return null;
    }
    return (
      <AnchoredSection
        collapsible
        section={i18n.t(
          'frontproductstream.physical_check_review_header_section.header.text',
          { defaultValue: 'Physical checker review' }
        )}
        id="physicalChecherReview"
        order={order}
      >
        <div className="PhysicalCheckDiff_FailedField">
          <h2>
            {i18n.t(
              'frontproductstream.physical_check_review_header_section.description.text',
              { defaultValue: 'Field with wrong value' }
            )}
          </h2>
          {this.renderHeader()}
          {contribution.get('fields').map((field) => this.renderField(field))}
          {this.renderButton()}
        </div>
        <DisputeModal />
      </AnchoredSection>
    );
  }
}

export default enhance(PhysicalCheckDiff);
