import { combineReducers } from 'redux';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import referentials from 'core/modules/with-referential/reducers';
// reducers from modules
import anchored from 'modules/anchored/reducer';
import assets from 'modules/assets/reducers';
import { LOGOUT } from 'modules/auth/shared/actions/types';
import displayGroups from 'modules/display-groups/reducers';
import logisticalHierarchies from 'modules/logistical-hierarchies/reducers';
import userNotificationUnread from 'modules/notification-unread/reducer';
import priceWaterfalls from 'modules/price-waterfalls/reducers';
import productHierarchy from 'modules/product-hierarchy/reducer';
import productPage from 'modules/product-page/reducer';
import recipients from 'modules/recipients/reducers';
import { redirectReducer as redirect } from 'modules/redirect';
import sharingUnits from 'modules/sharing-units/reducer';
import { validationReducer } from 'modules/validation';
import viewAs from 'modules/view-as/reducer';

// Import all reducers defined in the following folders
import brands from './brands';
import media from './media';
import navigation from './navigation';
import notification from './notification';
import organization from './organization';
import policy from './policy';
import productVersion from './productVersion';
import segment from './segment';
import segments from './segments';
import template from './template';
import user from './user';

const setIfNotEmpty = (asyncReducers) =>
  asyncReducers && Object.keys(asyncReducers).length > 0
    ? combineReducers(asyncReducers)
    : {};

const appReducer = (asyncReducers) =>
  combineReducers({
    [MODULE_REDUCER_KEY]: setIfNotEmpty(asyncReducers),
    anchored,
    assets,
    brands,
    displayGroups,
    logisticalHierarchies,
    media,
    navigation,
    notification,
    organization,
    policy,
    priceWaterfalls,
    productHierarchy,
    productPage,
    productVersion,
    recipients,
    redirect,
    referentials,
    segment,
    segments,
    sharingUnits,
    template,
    user,
    userNotification: combineReducers({ unread: userNotificationUnread }),
    validation: validationReducer,
    viewAs,
  });

export default (asyncReducers) => (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(asyncReducers)(undefined, action);
  }
  return appReducer(asyncReducers)(state, action);
};
