import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';

import { notificationError, notificationSuccess } from 'actions/notification';
import Anchor from 'components/ui/basic/anchor';
import Modal from 'components/ui/modal';
import { FILE_TYPE_MAP } from 'constants/media';
import { selectHasReleaseProductpageFlywheelMedia } from 'modules/feature-flag/selectors';
import mediaApi from 'resources/mediaApi';
import { StringOrNumber } from 'types';
import i18n from 'utils/i18n';
import moment from 'utils/moment';

import DocumentModal from '../actions/document/document-modal';

const mapStateToProps = createStructuredSelector({
  hasReleaseProductpageFlywheelMedia: selectHasReleaseProductpageFlywheelMedia,
});

class DocumentAsset extends PureComponent {
  static propTypes = {
    asset: PropTypes.object.isRequired,
    productKeyId: StringOrNumber.isRequired,
    updateEntities: PropTypes.func,
    readOnly: PropTypes.bool,
    action: PropTypes.object, // action from DG for update modal.
    dispatch: PropTypes.func,
    deleting: PropTypes.bool,
    onDelete: PropTypes.func,
    index: PropTypes.number,
    hasReleaseProductpageFlywheelMedia: PropTypes.bool,
  };

  static defaultProps = {
    action: null,
    readOnly: false,
  };

  state = {
    deleteModalState: false,
    showModal: false,
  };

  successModal = () => {
    this.props.updateEntities('ProductDocument');
    this.hideModal();
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  editDocument = () => {
    const { asset, action, productKeyId, index, readOnly } = this.props;
    return (
      <DocumentModal
        title={
          readOnly
            ? i18n.t('frontproducstream.asset_list.document_modal.view_title', {
                defaultValue: 'View document',
              })
            : i18n.t('frontproducstream.asset_list.document_modal.edit_title', {
                defaultValue: 'Edit document',
              })
        }
        action={action}
        media={asset}
        productKeyId={productKeyId}
        onSuccess={this.successModal}
        onClose={this.hideModal}
        index={index}
        readOnly={readOnly}
      />
    );
  };

  hideDeleteConfirmationModal = () => {
    this.setState({ deleteModalState: false });
  };

  openDeleteConfirmationModal = () => {
    this.setState({ deleteModalState: true });
  };

  deleteEntity = () => {
    const { onDelete, asset, dispatch, updateEntities } = this.props;
    this.hideDeleteConfirmationModal();
    onDelete(true);
    return mediaApi.ProductDocumentDelete(asset.id).then(
      () => {
        updateEntities(asset._type);
        onDelete(false);
        return dispatch(
          notificationSuccess(
            i18n.t(
              'frontproducstream.asset_list.document.delete_notification.success',
              { defaultValue: 'Document deleted' }
            )
          )
        );
      },
      (error) => {
        onDelete(false);
        if (error.status === 403) {
          return dispatch(
            notificationError(
              i18n.t(
                'frontproducstream.asset_list.document.delete_notification.permission_error',
                {
                  defaultValue:
                    "You don't have the permission to delete this document",
                }
              )
            )
          );
        }
        return dispatch(
          notificationError(
            i18n.t('frontproducstream.asset_list.delete_notification.error', {
              defaultValue: 'An error occured:',
            }) + error.message
          )
        );
      }
    );
  };

  downloadFile = () => {
    const { asset } = this.props;
    window.open(asset.uniformResourceIdentifier);
  };

  render() {
    const { asset, readOnly, deleting } = this.props;
    const buttons = readOnly ? (
      <div className="Asset__buttons">
        <div
          className="Asset__button--rightMargin"
          id={`view-document-${asset.id}`}
        >
          <Button
            primary
            onClick={this.openModal}
            disabled={deleting}
            className="Button--editDocument"
            testid="view-button"
          >
            {i18n.t('frontproducstream.asset_list.view_button.label', {
              defaultValue: 'View',
            })}
          </Button>
          {this.state.showModal && this.editDocument()}
        </div>
      </div>
    ) : (
      <div className="Asset__buttons">
        <div
          className="Asset__button--rightMargin"
          id={`edit-document-${asset.id}`}
        >
          <Button
            secondary
            onClick={this.openModal}
            disabled={deleting}
            className="Button--editDocument"
            testid="edit-button"
          >
            {i18n.t('frontproducstream.asset_list.edit_button.label', {
              defaultValue: 'Edit',
            })}
          </Button>
          {this.state.showModal && this.editDocument()}
        </div>
        <div id={`delete-document-${asset.id}`}>
          <Button
            secondary
            content={i18n.t(
              'frontproducstream.asset_list.delete_button.label',
              { defaultValue: 'Delete' }
            )}
            onClick={this.openDeleteConfirmationModal}
            disabled={deleting}
            displaySpinner={deleting}
            className="Button--deleteDocument"
            testid="delete-button"
          />
          {this.state.deleteModalState && (
            <Modal
              modalStyle="dynamic"
              title={i18n.t(
                'frontproducstream.asset_list.delete_document_modal.title',
                { defaultValue: 'Delete document' }
              )}
              onConfirm={this.deleteEntity}
              onClose={this.hideDeleteConfirmationModal}
            >
              {i18n.t(
                'frontproducstream.asset_list.delete_document_modal.content',
                {
                  defaultValue:
                    'Are you sure you want to delete this document ?',
                }
              )}
            </Modal>
          )}
        </div>
      </div>
    );

    const tags = Array.isArray(asset.tags) && (
      <p className="Asset__tags">{asset.tags.join(', ')}</p>
    );
    const data = `${asset.uniformResourceIdentifier}#toolbar=0&scrollbar=0&messages=0&status=0&navpanes=0&page=1`;
    const fileType = FILE_TYPE_MAP[asset.fileType];
    return (
      <div className="Asset__document-wrapper">
        <p>
          <Anchor
            href={asset.uniformResourceIdentifier}
            className="Asset__documentContainer"
          >
            {asset.fileType === 'application/pdf' ? (
              <object
                data={data}
                type="application/pdf"
                width={
                  this.props.hasReleaseProductpageFlywheelMedia
                    ? '200px'
                    : '100%'
                }
                height="100%"
                className="Asset__document"
              >
                <i className="mdi mdi-file Asset__document" />
              </object>
            ) : (
              <i className="mdi mdi-file Asset__document" />
            )}
          </Anchor>
        </p>
        <div
          className={classNames({
            Asset__documentBody: !this.props.hasReleaseProductpageFlywheelMedia,
            'Asset__documentBody-rfp':
              this.props.hasReleaseProductpageFlywheelMedia,
          })}
        >
          <p className="Asset__tight"> {asset.fileName} </p>
          <p className="Asset__tight"> {fileType} </p>
          {tags}
          <p className="Asset__dateAdd">
            {i18n.t('frontproducstream.asset_list.document.date_added.label', {
              defaultValue: 'Added on',
            })}{' '}
            {moment(asset.createdAt).format('LL')}
          </p>
          <div className="Asset__download" id={`download-document-${asset.id}`}>
            <Button
              secondary
              content={i18n.t(
                'frontproducstream.asset_list.document.download_button.label',
                { defaultValue: 'Download' }
              )}
              onClick={this.downloadFile}
              disabled={deleting}
              className="Button--downloadDocument"
              testid="download-button"
            />
          </div>
          {buttons}
        </div>
      </div>
    );
  }
}

export { DocumentAsset };
export default connect(mapStateToProps)(DocumentAsset);
