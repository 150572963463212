import classNames from 'classnames';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';

import { selectAssetHasErrorByAssetId } from 'modules/validation';
import i18n from 'utils/i18n';

import AssetContext from '../context';

import './AssetV2.scss';
import { ViewOrEditButton } from './ViewOrEditButton';
import { AssetTypeV2, Field } from './types';
import { getAssetDerivedData } from './utils';

type AssetV2Props = {
  asset: AssetTypeV2;
  isSelected: boolean;
  field: Field;
  entityId: number;
  onSelectChange: (id: number) => void;
  updateEntities: () => void;
};

export const AssetV2 = ({
  asset,
  field,
  isSelected,
  entityId,
  onSelectChange,
  updateEntities,
}: AssetV2Props) => {
  const assetHasErrorByAssetId = useSelector(selectAssetHasErrorByAssetId);

  const hasError = assetHasErrorByAssetId[asset.id];

  const { isArchived, previewUri, label, fileType } =
    getAssetDerivedData(asset);

  const { iconName, fileTypelabel } = fileType;

  const handleChange = useCallback(() => {
    onSelectChange(asset.id);
  }, [asset.id, onSelectChange]);

  const isCheckSupported = asset._type === 'ProductPicture';

  return (
    <div className={classNames(['AssetV2', { 'AssetV2--error': hasError }])}>
      <div className="AssetV2__preview">
        {previewUri ? (
          <img
            className="AssetV2__preview__image"
            src={previewUri}
            alt={i18n.t('frontproducstream.asset_list.picture.image.alt', {
              defaultValue: 'Product image',
            })}
          />
        ) : (
          <i className={`mdi ${iconName} AssetV2__preview__placeholder`} />
        )}
        <div className="AssetV2__preview__backdrop" />
        <div className="AssetV2__preview__button">
          <AssetContext.Provider value={{ formGroup: field, entityId }}>
            <ViewOrEditButton
              updateEntities={updateEntities}
              asset={asset}
              field={field}
            />
          </AssetContext.Provider>
        </div>
      </div>
      <div className="AssetV2__row">
        {isCheckSupported && (
          <Checkbox
            id={`checkbox-picture-assetv2-${asset.id}`}
            checked={isSelected}
            onChange={handleChange}
          />
        )}
        <span className="AssetV2__label">{label}</span>
      </div>

      <div className="AssetV2__row">
        <i className={`mdi mdi-${iconName}`} />
        <span className="AssetV2__file-type-label">{fileTypelabel}</span>
      </div>
      {isArchived ? (
        <div className="AssetV2__chip AssetV2__chip--archived">
          <span>
            {i18n.t(
              'frontproductstream.asset_list.see_and_edit_all_media.assetV2.archived.label',
              {
                defaultValue: 'Archived',
              }
            )}
          </span>
        </div>
      ) : (
        <span>{/* error message / warning */}</span>
      )}
    </div>
  );
};
