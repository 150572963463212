import { call, put, select } from 'redux-saga/effects';

import { ROOM_TYPE_PRODUCTVERSION } from 'constants/room';
import {
  getSharedOrganizationId,
  getSharedProductVersionId,
  isActivelyShared,
} from 'core/api/retailerproductversion';
import { sendQuickMessage } from 'modules/chat';
import { RELEASE_DATA_OPS_MESSAGES } from 'modules/feature-flag/constants';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import { selectSourceProductVersion } from 'reducers/productVersion';
import contributionApi from 'resources/contributionApi';
import { ActionPayload } from 'types';
import { i18n } from 'utils/i18n';

import {
  cancelDataOpsPatch,
  cancelDataOpsPatchDone,
  removeDataOpsPatches,
} from '../actions';
import { DataOpsPatch } from '../types';

import { hasPatchPermission } from './utils';

export function* cancelPatch({
  payload: { patch, label },
}: {
  payload: ActionPayload<typeof cancelDataOpsPatch>;
}) {
  const canPatch = yield call(hasPatchPermission, { withNotif: true });
  if (!canPatch) {
    return;
  }
  try {
    if ('id' in patch) {
      yield call(() =>
        contributionApi.delete(
          `/contribution/v1/product/${patch.productKeyId}/patches/${patch.id}`
        )
      );
    }

    yield put(cancelDataOpsPatchDone({ patch }));

    const canSendMessage = yield select(
      selectHasFeature(RELEASE_DATA_OPS_MESSAGES)
    );

    if ('id' in patch && canSendMessage && label) {
      const productVersion = yield select(selectSourceProductVersion);
      const productIsShared = isActivelyShared(productVersion);
      const sharedProductVersionId = getSharedProductVersionId(productVersion);
      const manufacturerId = getSharedOrganizationId(productVersion);
      const message = i18n.t(
        'frontproductstream.product_collaborative_edit.delete_patch_success.notification',
        {
          defaultValue: '"{{label}}" patch was deleted',
          label,
        }
      );

      if (productIsShared) {
        yield put(
          sendQuickMessage(
            message,
            manufacturerId,
            sharedProductVersionId,
            ROOM_TYPE_PRODUCTVERSION
          )
        );
      }
    }
  } catch (error) {
    yield put(cancelDataOpsPatchDone({ patch, error }));
    throw error;
  }
}

export function* cancelPatches({
  payload: { patches },
}: {
  payload: { patches: DataOpsPatch[] };
}) {
  const canPatch = yield select(hasPatchPermission);
  if (!canPatch) {
    return;
  }
  for (const patch of patches) {
    yield call(cancelPatch, { payload: { patch, label: '' } });
    yield put(removeDataOpsPatches([patch]));
  }
}
