import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { ProgressBar } from '@alkem/react-ui-progress';

import ProductReference from 'components/ui/product-reference';
import Tipster from 'components/ui/tipster';
import {
  getDisplayName,
  getProductGTIN,
  getProductVersionId,
} from 'core/api/productversion';
import i18n from 'utils/i18n';

import {
  selectDisplayGroups,
  selectErrors,
  selectSavedCount,
  selectSaving,
  selectTotalCount,
} from '../../selectors';
import ProductError from '../error';

import './save.scss';

const mapStateToProps = (state) => ({
  displayGroups: selectDisplayGroups(state),
  saving: selectSaving(state),
  errors: selectErrors(state),
  savedCount: selectSavedCount(state),
  totalCount: selectTotalCount(state),
  currentLanguage: state.productVersion.currentLanguage,
});

export class BulkEditModalSave extends PureComponent {
  static STATUS_SAVING = 0;

  static STATUS_SUCCESS = 1;

  static STATUS_FAILURE = 2;

  static propTypes = {
    displayGroups: PropTypes.array.isRequired,
    saving: PropTypes.bool,
    errors: PropTypes.array,
    savedCount: PropTypes.number,
    totalCount: PropTypes.number,
    currentLanguage: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.renderOneProduct = this.renderOneProduct.bind(this);
    this.renderOneError = this.renderOneError.bind(this);
  }

  getStatus() {
    const { saving, errors } = this.props;
    if (saving) {
      return BulkEditModalSave.STATUS_SAVING;
    } else if (errors.length) {
      return BulkEditModalSave.STATUS_FAILURE;
    }
    return BulkEditModalSave.STATUS_SUCCESS;
  }

  reduceErrors() {
    const { errors } = this.props;
    const reducedErrors = [];
    errors.forEach((error) => {
      const index = reducedErrors.findIndex((e) =>
        this.areErrorsIdentical(e.errors, error.errors)
      );
      if (index === -1) {
        reducedErrors.push({
          productVersions: [error.productVersion],
          errors: error.errors,
        });
      } else {
        reducedErrors[index].productVersions.push(error.productVersion);
      }
    });
    return reducedErrors;
  }

  areErrorsIdentical(errorsA, errorsB) {
    if (errorsA.length !== errorsB.length) {
      return false;
    }
    const identicalErrors = errorsA.filter(
      (e, i) => e.field === errorsB[i].field && e.error === errorsB[i].error
    );
    return identicalErrors.length === errorsA.length;
  }

  renderStatus() {
    let message;
    const classes = {
      BulkEditModalSave__status: true,
    };
    switch (this.getStatus()) {
      case BulkEditModalSave.STATUS_SUCCESS:
        message = i18n.t(
          'frontproductstream.product_bulk_edit.modal_save.success_status',
          { defaultValue: 'All products have been successfully modified.' }
        );
        classes['BulkEditModalSave__status--success'] = true;
        break;
      case BulkEditModalSave.STATUS_FAILURE:
        message = i18n.t(
          'frontproductstream.product_bulk_edit.modal_save.failure_status',
          { defaultValue: 'Some products could not be modified.' }
        );
        classes['BulkEditModalSave__status--error'] = true;
        break;
      default:
        message = i18n.t(
          'frontproductstream.product_bulk_edit.modal_save.default_status',
          { defaultValue: 'Saving product(s). It should take a few seconds.' }
        );
        break;
    }

    return <div className={classNames(classes)}>{message}</div>;
  }

  renderProgressBar() {
    const { savedCount, totalCount } = this.props;
    let progressColor;
    switch (this.getStatus()) {
      case BulkEditModalSave.STATUS_SUCCESS:
        progressColor = 'success';
        break;
      case BulkEditModalSave.STATUS_FAILURE:
        progressColor = 'danger';
        break;
      default:
        progressColor = 'info';
        break;
    }
    return (
      <div className="BulkEditModalSave__progressBar">
        <ProgressBar
          value={savedCount}
          max={totalCount}
          color={progressColor}
          height="medium"
        />
      </div>
    );
  }

  renderOneProduct(productVersion, index) {
    const { currentLanguage } = this.props;
    return (
      <div className="BulkEditModalSave__errorProduct" key={index}>
        <ProductReference reference={getProductGTIN(productVersion)} />
        <span className="BulkEditModalSave__errorSeparator">-</span>
        <Ellitips
          id={`bulk-edit-product-ellitips-${getProductVersionId(
            productVersion
          )}`}
          label={getDisplayName(productVersion, currentLanguage)}
        />
      </div>
    );
  }

  renderOneError(error, index) {
    const { displayGroups } = this.props;
    const { productVersions, errors } = error;

    return (
      <div className="BulkEditModalSave__error" key={index}>
        {productVersions.map(this.renderOneProduct)}
        <ul className="BulkEditModalSave__errorMessages">
          {errors.map((e) => (
            <li key={e}>
              <ProductError displayGroups={displayGroups} {...e} />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderErrors() {
    const { errors } = this.props;
    const reducedErrors = this.reduceErrors(errors);
    return (
      <div className="BulkEditModalSave__errors">
        {reducedErrors.map(this.renderOneError)}
      </div>
    );
  }

  render() {
    return (
      <div className="BulkEditModalSave">
        <Tipster
          info={i18n.t(
            'frontproductstream.product_bulk_edit.modal_save.tipster_info_message',
            {
              defaultValue:
                'You can go back and edit your information at anytime to cancel the current saving process.',
            }
          )}
        />
        {this.renderStatus()}
        {this.renderProgressBar()}
        {this.renderErrors()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(BulkEditModalSave);
