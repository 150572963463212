import classNames from 'classnames';
import { Component } from 'react';

import Search from 'components/ui/input/search';
import Modal from 'components/ui/modal';
import referentialApi from 'resources/referentialApi';
import { compareNames } from 'utils';
import i18n from 'utils/i18n';

import ConceptLabel from '../concept-label';
import { Entity, Label } from '../types';

import './addlabel.scss';

interface AddLabelProps {
  productKindId?: number;
  close(): void;
  addLabels(labels: Label[]): void;
  selectedLabels: Label[];
  searchPlaceholder?: string;
  targetMarketId: number;
}

interface AddLabelState {
  selectedLabels: { [key: string]: Label };
  labelCategories: {
    id: string;
    label: string;
    entities: Entity[];
  }[];
  searchQuery: string | null;
  message: string;
  selectedCatIndex: number | null;
}

export default class AddLabel extends Component<AddLabelProps, AddLabelState> {
  static defaultProps = {
    searchPlaceholder: 'search',
  };

  constructor(props: AddLabelProps) {
    super(props);
    const selectedLabels = {};
    if (props.selectedLabels) {
      for (const label of props.selectedLabels) {
        if (label.isConceptualizedBy) {
          selectedLabels[label.isConceptualizedBy.id] = label;
        }
      }
    }

    this.state = {
      selectedLabels,
      labelCategories: [],
      selectedCatIndex: null,
      searchQuery: '',
      message: '',
    };
    this.addLabels = this.addLabels.bind(this);
    this.searchLabels = this.searchLabels.bind(this);
  }

  componentDidMount() {
    const productKindId = this.props.productKindId || 80726; // Base Kind
    const { targetMarketId } = this.props;
    referentialApi
      .ReferentialGetCategories('labels', {
        kindId: productKindId,
        targetMarkets: [targetMarketId],
      })
      .then((response) => {
        const labelCategories = response.data.data.toJS().sort(compareNames);
        this.setState({ labelCategories });
      });
  }

  selectCategory = (index: number) => () => {
    this.setState({ selectedCatIndex: index, searchQuery: null });
  };

  updateSelectedLabels = (entity: Entity) => () => {
    const state = { ...this.state };

    if (entity.id in state.selectedLabels) {
      delete state.selectedLabels[entity.id];
      state.message = '';
    } else {
      state.selectedLabels[entity.id] = { isConceptualizedBy: entity };
    }
    this.setState(state);
  };

  addLabels() {
    const { selectedLabels } = this.state;
    const labels = Object.keys(selectedLabels)
      .filter((id) => !!selectedLabels[id])
      .map((id) => selectedLabels[id]);
    this.props.addLabels(labels);
    this.props.close();
  }

  searchLabels(query: string) {
    this.setState({
      searchQuery: query,
      selectedCatIndex: null,
    });
  }

  removeDuplicates(array: Entity[]) {
    return array.filter(
      (elem, index, arr) => arr.findIndex((e) => e.id === elem.id) === index
    );
  }

  renderCategories() {
    const { labelCategories, selectedCatIndex } = this.state;
    return labelCategories.map((cat, i) => {
      const catClasses = {
        AddLabel__category: true,
        'AddLabel__category--selected': selectedCatIndex === i,
      };
      return (
        <div
          className={classNames(catClasses)}
          key={`category-${cat.label}`}
          onClick={this.selectCategory(i)}
        >
          <div className="AddLabel__categoryLabel">{cat.label}</div>
          <div className="AddLabel__categoryCount">{`${
            cat.entities.length
          } ${i18n.t('frontproductstream.labels.count_in_category.text', {
            defaultValue: 'labels',
          })}`}</div>
        </div>
      );
    });
  }

  renderLabels() {
    const { labelCategories, selectedCatIndex, selectedLabels, searchQuery } =
      this.state;
    let labels: Entity[];
    if (searchQuery !== null) {
      labels = labelCategories.reduce(
        (lst, cat) =>
          lst.concat(
            cat.entities.filter(
              (label) =>
                label.label.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1
            )
          ),
        [] as Entity[]
      );
    } else if (selectedCatIndex !== null) {
      labels = labelCategories[selectedCatIndex].entities;
    } else {
      return null;
    }
    labels = this.removeDuplicates(labels);
    return labels.map((entity: Entity) => (
      <div className="col-md-6 AddLabel__label" key={`label-${entity.id}`}>
        <ConceptLabel
          label={{ isConceptualizedBy: entity }}
          selectable
          selected={!!selectedLabels[entity.id]}
          onClick={this.updateSelectedLabels(entity)}
        />
      </div>
    ));
  }

  renderSearch() {
    const { searchPlaceholder } = this.props;
    const { message, searchQuery } = this.state;

    return (
      <>
        <div className="AddLabel__search">
          <Search
            query={searchQuery || ''}
            placeholder={searchPlaceholder}
            updateSearchQuery={this.searchLabels}
            small
          />
        </div>
        {message && (
          <span className="AddLabel__message">
            <span>
              <i className="mdi mdi-information AddLabel__icon" />
            </span>
            <span>{message}</span>
          </span>
        )}
      </>
    );
  }

  render() {
    const { close } = this.props;
    if (!this.state.labelCategories) {
      return null;
    }
    return (
      <Modal
        title={i18n.t('frontproductstream.labels.add_modal.title', {
          defaultValue: 'Add other labels',
        })}
        modalStyle="fullHeight"
        className="AddLabel"
        confirmButtonText={i18n.t(
          'frontproductstream.labels.add_modal.button.label',
          { defaultValue: 'Add' }
        )}
        onConfirm={this.addLabels}
        onClose={close}
        additionalFooterContent={this.renderSearch()}
      >
        <div className="row">
          <div className="col-md-4 AddLabel__col">
            {this.renderCategories()}
          </div>
          <div className="col-md-8 AddLabel__col">
            <div className="row">{this.renderLabels()}</div>
          </div>
        </div>
      </Modal>
    );
  }
}
