import i18n from 'utils/i18n';

export const unknown = {
  name: i18n.t(
    'frontproductstream.constants.synchronization_status.not_synchronized',
    { defaultValue: 'Not synchronized' }
  ),
  id: 'UNKNOWN',
  icon: 'mdi mdi-sync-off',
};

export const inProgress = {
  name: i18n.t(
    'frontproductstream.constants.synchronization_status.in_progress',
    { defaultValue: 'In progress' }
  ),
  id: 'IN_PROGRESS',
  icon: 'mdi mdi-sync',
};

export const success = {
  name: i18n.t(
    'frontproductstream.constants.synchronization_status.synchronized',
    { defaultValue: 'Synchronized' }
  ),
  id: 'SUCCESS',
  icon: 'mdi mdi-sync',
};

export const error = {
  name: i18n.t('frontproductstream.constants.synchronization_status.error', {
    defaultValue: 'An error occured',
  }),
  id: 'ERROR',
  icon: 'mdi mdi-sync-alert',
};

export const review = {
  name: i18n.t('frontproductstream.constants.synchronization_status.review', {
    defaultValue: 'Review',
  }),
  id: 'CORRECTION_REQUESTED_BY_RECIPIENT',
  icon: 'mdi mdi-playlist-remove',
};

export const SynchronizationStatusMap = {
  unknown,
  inProgress,
  error,
  success,
  review,
};

export const SynchronizationStatusIdToInfo = {
  [unknown.id]: unknown,
  [inProgress.id]: inProgress,
  [error.id]: error,
  [success.id]: success,
  [review.id]: review,
};
