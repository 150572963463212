import { useSelector } from 'react-redux';

import FeatureManufacturerRecipeModal from 'modules/feature-flag/components/manufacturer-recipe/modal';
import FeatureManufacturerVideoModal from 'modules/feature-flag/components/manufacturer-video/modal';
import {
  selectHasRecipeFeature,
  selectHasVideoFeature,
} from 'modules/feature-flag/selectors';

import DocumentModal from '../../actions/document/document-modal';
import { PictureModal } from '../../actions/picture/picture-modal';
import RecipeModal from '../../actions/recipe/recipe-modal';
import VideoModal from '../../actions/video/video-modal';
import { AssetTypeV2, FieldAction } from '../types';

type AddMediaModalProps = {
  fieldAction: FieldAction;
  productKeyId: number;
  assets: AssetTypeV2[];
  onCloseModal: () => void;
  onSuccess: () => void;
};

export const AddMediaModal = ({
  fieldAction,
  productKeyId,
  onCloseModal,
  onSuccess,
  assets,
}: AddMediaModalProps) => {
  const hasRecipeFeature = useSelector(selectHasRecipeFeature);
  const hasVideoFeature = useSelector(selectHasVideoFeature);

  switch (fieldAction.render) {
    case 'addPicture':
      return (
        <PictureModal
          title={fieldAction.content}
          productKeyId={productKeyId}
          onSuccess={onSuccess}
          onClose={onCloseModal}
          fieldActionItems={fieldAction.items}
          count={
            assets.filter((item) => item._type === 'ProductPicture').length
          }
        />
      );
    case 'addVideo':
      if (!hasVideoFeature) {
        return <FeatureManufacturerVideoModal onClose={onCloseModal} />;
      }

      return (
        <VideoModal
          title=""
          productKeyId={productKeyId}
          onSuccess={onSuccess}
          onClose={onCloseModal}
        />
      );
    case 'addRecipe':
      if (!hasRecipeFeature) {
        return <FeatureManufacturerRecipeModal onClose={onCloseModal} />;
      }

      return (
        <RecipeModal
          title={fieldAction.content}
          productKeyId={productKeyId}
          onSuccess={onSuccess}
          onClose={onCloseModal}
        />
      );
    case 'addDocument':
      return (
        <DocumentModal
          title={fieldAction.content}
          action={fieldAction}
          productKeyId={productKeyId}
          onSuccess={onSuccess}
          onClose={onCloseModal}
        />
      );
  }
};
