import { update } from 'lodash/fp';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import Spinner from 'core/components/spinner';
import { openFormGroup } from 'modules/display-groups/actions';
import { selectHasLoadOnDemandEnabled } from 'modules/feature-flag/selectors';
import { scrollSmoothly } from 'utils/scroll';
import { track } from 'utils/tracking';

import { selectNumberOfErrors, selectSortedErrors } from '../selectors';
import { findMessage } from '../utils';

import './stepper.scss';

export const FORWARD = 1;
export const BACKWARD = -1;

const trackingLabel = {
  FORWARD: 'next',
  BACKWARD: 'previous',
};

const mapStateToProps = (state) => ({
  errors: selectSortedErrors(state),
  numberOfErrors: selectNumberOfErrors(state),
  hasLoadOnDemand: selectHasLoadOnDemandEnabled(state),
});

const mapDispatchToProps = {
  openFormGroup,
};

export class ValidationStepper extends PureComponent {
  static propTypes = {
    errors: PropTypes.array,
    numberOfErrors: PropTypes.number.isRequired,
    hasLoadOnDemand: PropTypes.bool,
    openFormGroup: PropTypes.func.isRequired,
  };

  state = {
    direction: FORWARD,
    isWaiting: false,
    currentIndex: undefined,
    skipped: 0,
    errorHistory: [],
  };

  componentDidUpdate(prevProps) {
    const { errors } = this.props;
    if (errors.length !== prevProps.errors.length) {
      this.shiftHistory();
    }
  }

  shiftHistory = () => {
    const { errors } = this.props;
    this.setState((prevState) => ({
      errorHistory: [errors, prevState.errorHistory[0]],
    }));
  };

  onClickPrevious = () => {
    this.navigate(BACKWARD);
  };

  onClickNext = () => {
    this.navigate(FORWARD);
  };

  hasErrorRemoved = (direction) => {
    const { errors } = this.props;
    const { currentIndex = -1 } = this.state;

    const lastErrors = this.state.errorHistory[1];
    if (!lastErrors || lastErrors.length === errors.length) {
      return false;
    }

    const removedIndex = errors.findIndex(
      (err, index) =>
        lastErrors[index] && err.fieldName !== lastErrors[index].fieldName
    );
    return direction === FORWARD && removedIndex <= currentIndex;
  };

  getNextIndex = (direction, needsAdjustment, errorElements) => {
    const { currentIndex, skipped } = this.state;

    if (typeof currentIndex === 'undefined') {
      return direction === FORWARD ? 0 : errorElements.length - 1;
    }

    return needsAdjustment
      ? currentIndex
      : (errorElements.length -
          Object.keys(skipped).length +
          currentIndex +
          direction) %
          errorElements.length;
  };

  navigate = (direction) => {
    track({
      category: 'product-page-tracking',
      action: 'error stepper',
      label: trackingLabel[direction],
    });

    const { currentIndex = -1 } = this.state;
    const { errors } = this.props;

    const needsAdjustment = this.hasErrorRemoved(direction);
    if (needsAdjustment) {
      this.shiftHistory();
    }

    if (this.props.hasLoadOnDemand) {
      const nextIndex = currentIndex + direction;
      if (nextIndex >= 0 && nextIndex < errors.length) {
        this.setState({ direction });
        this.goToCurrentError(needsAdjustment ? currentIndex : nextIndex);
        return;
      }
    }
    this.goToNextError(direction, needsAdjustment);
  };

  async goToCurrentError(nextIndex) {
    // reset loading indicator
    this.setState({ isWaiting: false });

    // select all errors that will be selected by stepper
    const { errors } = this.props;

    // uncomment to print error table to the console
    // printErrors('errors', errors, nextIndex);

    // find error message for next index
    const currentError = errors[nextIndex];
    let currentMessage = findMessage(currentError);

    // if message not found, display group is closed
    if (!currentMessage && !currentError?.recipientId) {
      const displayGroupId = (currentError?.path || '').split('.')[0];
      if (displayGroupId) {
        this.props.openFormGroup(displayGroupId);
        await new Promise((resolve) => setTimeout(resolve, 20));
        currentMessage = findMessage(currentError);
        this.setState({ isWaiting: true });
        let interval;
        await Promise.race([
          new Promise((resolve) => {
            interval = setInterval(async () => {
              currentMessage = findMessage(currentError);
              if (currentMessage) {
                resolve();
              }
            }, 20);
          }),
          new Promise((resolve) => {
            setTimeout(resolve, 5000);
          }),
        ]);
        if (!currentMessage) {
          this.setState(
            update(['skipped'], (skipped) => ({
              ...skipped,
              [currentError.fieldName]: true,
            }))
          );
        }
        clearInterval(interval);
        this.setState({ isWaiting: false });
      }
    }

    // remove current class from previous message
    if (errors.length > 1) {
      document.querySelectorAll('.Raguel__message--current').forEach((el) => {
        el.classList.remove('Raguel__message--current');
      });
    }

    // scroll to next message
    if (currentMessage) {
      const offset = 200;
      const top =
        currentMessage.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset;
      scrollSmoothly({ top });
      currentMessage.classList.add('Raguel__message--current');
    }

    if (this.state.currentIndex !== nextIndex) {
      this.setState({ currentIndex: nextIndex });
    }
  }

  goToNextError(direction, needsAdjustment) {
    const { currentIndex, skipped } = this.state;
    // Sort the errors based on their position on the page.
    // We need to do this every time we click to make sure the fields are properly mounted.
    const errorElements = this.locateErrors();

    const nextIndex = this.getNextIndex(
      direction,
      needsAdjustment,
      errorElements
    );

    if (typeof currentIndex !== 'undefined') {
      // Remove from all elements to be sure to remove the selection even
      // if the error count change (viewas filtering etc...)
      errorElements.forEach((errorElem) =>
        errorElem.element.classList.remove('Raguel__message--current')
      );
    }

    if (!errorElements[nextIndex]) {
      return;
    }

    const { element } = errorElements[nextIndex];
    // If we have located the error and not the input we need a bigger offset.
    const offset = 200;
    const top =
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset;
    scrollSmoothly({ top });
    element.classList.add('Raguel__message--current');

    const realNextIndex = nextIndex + Object.values(skipped).length;
    if (realNextIndex !== currentIndex) {
      this.setState({ currentIndex: realNextIndex });
    }
  }

  locateErrors() {
    return [
      ...document.querySelectorAll(
        '.Raguel__message:not(.Raguel__message--disabled)'
      ),
    ]
      .filter((e) => !!e.offsetParent)
      .map((e) => ({ element: e }))
      .sort(
        (a, b) =>
          a.element.getBoundingClientRect().top -
          b.element.getBoundingClientRect().top
      );
  }

  render() {
    const { numberOfErrors } = this.props;
    const { currentIndex } = this.state;
    const { direction, isWaiting } = this.state;

    if (numberOfErrors === 0) {
      return null;
    }
    return (
      <div className="ValidationStepper">
        <Button
          secondary
          className="ValidationStepper__button--left"
          onClick={this.onClickPrevious}
          disabled={currentIndex === undefined || currentIndex <= 0}
          testid="ValidationStepperPrevious"
        >
          {isWaiting && direction === BACKWARD ? (
            <Spinner scale={0.5} />
          ) : (
            <span className="mdi mdi-chevron-left" />
          )}
        </Button>
        <Button
          secondary
          className="ValidationStepper__button--right"
          onClick={this.onClickNext}
          testid="ValidationStepperNext"
        >
          {isWaiting && direction === FORWARD ? (
            <Spinner scale={0.5} />
          ) : (
            <span className="mdi mdi-chevron-right" />
          )}
        </Button>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidationStepper);
