import { call, put, race, select, take } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import { waitExportGenerationAndDownload } from 'modules/catalog/product/sagas/list';
import { selectOrganizationId } from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { takeEverySafe } from 'utils/saga';
import { track } from 'utils/tracking';

import api, { transformParamsForList } from '../api';
import {
  EXPORT,
  EXPORT_BULK,
  EXPORT_BULK_FINISHED,
  EXPORT_BULK_PROGRESS,
  EXPORT_BULK_TARIFF_ONE_FILE,
  EXPORT_CANCEL,
  EXPORT_FAILURE,
  EXPORT_SUCCESS,
  EXPORT_WAIT,
} from '../constants';
import {
  selectExportFormat,
  selectIsBulkExportProgress,
  selectSelectedTariffIds,
  selectSelectedTariffUuids,
  selectUuid,
} from '../selectors';

import { getListParams } from './get';

export function* watchExport() {
  yield takeEverySafe(EXPORT, exportTariff);
  yield takeEverySafe(EXPORT_WAIT, waitForExportSaga);
  yield takeEverySafe(EXPORT_BULK, exportTariffs);
  yield takeEverySafe(EXPORT_BULK_TARIFF_ONE_FILE, exportBulkTariffOneFile);
  yield takeEverySafe(EXPORT_BULK_PROGRESS, verifyBulkProgress);
}

export function* exportTariff() {
  const uuid = yield select(selectUuid);

  const { result, error } = yield call(api.exportTariff, uuid);

  if (error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.export_notification.error',
          { defaultValue: 'An error occured exporting the tariff' }
        )
      )
    );
    yield put({ type: EXPORT_FAILURE, error });
    yield call(logError, error);
    return;
  }

  const {
    tracker: { id: trackerId },
  } = result;

  try {
    yield call(waitExportGenerationAndDownload, trackerId);
    yield call(track, {
      category: 'tariff',
      action: 'tariff_exported',
      label: uuid,
    });
    yield put({
      type: EXPORT_SUCCESS,
    });
  } catch (err) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.export_notification.error',
          { defaultValue: 'An error occured exporting the tariff' }
        )
      )
    );
    yield put({ type: EXPORT_FAILURE, error: err });
    yield call(logError, err);
  }
}

export function* exportBulkTariffOneFile() {
  const format = yield select(selectExportFormat);
  const org_id = yield select(selectOrganizationId);
  const selectedTariffUuids = yield select(selectSelectedTariffUuids);

  const { searchQuery, filterValues } = yield call(getListParams);
  const filters = transformParamsForList({
    searchQuery,
    ...filterValues,
    uuids: selectedTariffUuids,
  });

  const data = {
    file_format: format.name,
    organization_id: org_id,
    filter_params: filters,
  };

  const { result, error } = yield call(api.exportBulkTariff, data);

  if (error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.export_notification.error',
          { defaultValue: 'An error occured exporting the tariff' }
        )
      )
    );
    yield put({ type: EXPORT_FAILURE, error });
    yield call(logError, error);
    return;
  }

  const {
    data: {
      tracker: { id: trackerId },
    },
  } = result;

  try {
    yield call(waitExportGenerationAndDownload, trackerId);
    yield put({
      type: EXPORT_SUCCESS,
    });
  } catch (err) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.export_notification.error',
          { defaultValue: 'An error occured exporting the tariff' }
        )
      )
    );
    yield put({ type: EXPORT_FAILURE, error: err });
    yield call(logError, err);
  }
}

export function* exportTariffs({ tariffs }) {
  for (const tariff of tariffs) {
    const { result, error } = yield call(api.exportTariff, tariff.get('uuid'));
    if (error) {
      yield put({
        type: EXPORT_BULK_PROGRESS,
        progress: {
          item: tariff,
          inProgress: false,
          error,
        },
      });
      return;
    }

    const {
      tracker: { id: trackerId },
    } = result;
    yield put({ type: EXPORT_WAIT, trackerId, tariff });
    yield put({
      type: EXPORT_BULK_PROGRESS,
      progress: {
        item: tariff,
        inProgress: true,
      },
    });
  }
}

export function* waitForExportSaga({ trackerId, tariff }) {
  yield race({
    task: call(waitForDownload, { trackerId, tariff }),
    cancel: take(EXPORT_CANCEL),
  });
}

export function* waitForDownload({ trackerId, tariff }) {
  let error;
  try {
    yield call(waitExportGenerationAndDownload, trackerId);
  } catch (err) {
    error = err;
  }
  yield put({
    type: EXPORT_BULK_PROGRESS,
    progress: {
      item: tariff,
      inProgress: false,
      error,
    },
  });
}

export function* verifyBulkProgress() {
  const bulkExportProgress = yield select(selectIsBulkExportProgress);
  const selectedTariffsId = yield select(selectSelectedTariffIds);

  const isFinished =
    bulkExportProgress.length === selectedTariffsId.length &&
    bulkExportProgress.every((item) => !item.inProgress);

  if (isFinished) {
    yield put({
      type: EXPORT_BULK_FINISHED,
    });
  }
}
