import { get, isString } from 'lodash';
import { call, put, race, select, take } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import { ASSIGN, REASSIGN } from 'constants/assignation';
import { getProductGTIN, getProductVersionId } from 'core/api/productversion';
import { fetchList } from 'modules/catalog/product/actions';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { track } from 'utils/tracking';

import {
  closeTargetRequestModal,
  startSendingAssignation,
  stopSendingAssignation,
} from '../actions';
import { assign, chase } from '../api/assignation';
import { CANCEL_SEND_ASSIGNATION } from '../constants';
import { selectChaseType, selectForm, selectProducts } from '../selectors';

// --------
// HELPERS
// --------

export const getValidationError = (form) => {
  const noEmailProvided = !form.get('emails').size;
  const noRecipientProvided = !form.get('recipientId');

  if (noEmailProvided && noRecipientProvided) {
    return i18n.t('No recipients');
  }
  return ''; // no error
};

export const collectEventData = ({ form, products }) => ({
  gtins: products.valueSeq().map(getProductGTIN).toList(),
  targetOrganization: { id: form.get('recipientId') },
  message: form.get('message'),
  emails: form.get('emails'),
  forceAssignation: form.get('forceAssignation'),
});

export const sendTrackingData = ({ action, eventData, products }) => {
  const productVersionsIds = products.map(getProductVersionId).toList();

  const targetTypes = [];
  if (eventData.targetOrganization.id) {
    targetTypes.push('organization');
  }
  if (eventData.emails.size > 0) {
    targetTypes.push('user');
  }

  const source = productVersionsIds.size > 1 ? 'bulk_edit' : 'simple';

  for (const pvid of productVersionsIds) {
    track({
      category: 'product',
      action,
      label: `product_version#${pvid}`,
      target_type: targetTypes.join(),
      source,
      message: eventData.message,
    });
  }
};

// ------
// SAGAS
// ------

export function* sendAssignationTask() {
  const form = yield select(selectForm);
  const chaseType = yield select(selectChaseType);
  const products = yield select(selectProducts);
  const validationError = getValidationError(form);
  try {
    if (validationError) {
      yield put(notificationError(validationError, { context: 'modal' }));
      return;
    }
    yield put(startSendingAssignation());
    const eventData = collectEventData({
      form,
      products,
    });
    if (chaseType === ASSIGN || chaseType === REASSIGN) {
      const { error } = yield call(assign, { data: eventData });
      if (error) {
        yield call(processApiError, { error });
        return;
      }
      sendTrackingData({
        action: 'product_assigned',
        eventData,
        products,
      });
      yield call(processApiSuccess);
    } else {
      const { error } = yield call(chase, { data: eventData });
      if (error) {
        yield call(processApiError, { error });
        return;
      }
      sendTrackingData({
        action: 'product_chased',
        eventData,
        products,
      });
      yield call(processApiSuccess);
    }
  } catch (err) {
    logError(err);
  }
}

export function* processApiSuccess() {
  yield put(stopSendingAssignation());
  yield put(closeTargetRequestModal());
  yield put(
    notificationSuccess(
      i18n.t('Your request is being processed, and may take a minute.')
    )
  );
  yield put(fetchList());
}

export function* processApiError({ error }) {
  yield put(stopSendingAssignation());
  let errorMessage = get(error, 'data.message');
  if (!isString(errorMessage) || errorMessage.length === 0) {
    errorMessage = i18n.t('An error occured');
  }
  yield put(notificationError(errorMessage, { context: 'modal' }));
}

export default function* sendAssignationSaga() {
  yield race({
    task: call(sendAssignationTask),
    cancel: take(CANCEL_SEND_ASSIGNATION),
  });
}
