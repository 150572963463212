import { useCallback, useEffect, useState } from 'react';

import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';
import { Referential } from '@alkem/sdk-dashboard';

import { fetchReferentials } from 'resources/referentialApi';

/**
 * Creates Unicode flag from a two-letter ISO country code.
 * https://stackoverflow.com/questions/24050671/how-to-put-japan-flag-character-in-a-string
 * @param  {string} country — A two-letter ISO country code (case-insensitive).
 * @return {string}
 */
function getCountryFlag(country: string): string {
  return (
    getRegionalIndicatorSymbol(country[0]) +
    getRegionalIndicatorSymbol(country[1])
  );
}
/**
 * Converts a letter to a Regional Indicator Symbol.
 * @param  {string} letter
 * @return {string}
 */
function getRegionalIndicatorSymbol(letter: string): string {
  return String.fromCodePoint(
    0x1f1e6 - 65 + letter.toUpperCase().charCodeAt(0)
  );
}

type CountryReferential = Referential<{}>;

interface Props {
  ariaLabel?: string;
  value?: CountryReferential | null;
  onChange: (country?: CountryReferential | null) => void;
  isClearable?: boolean;
  defaultCode?: string;
}

export function SelectCountries({
  ariaLabel,
  value,
  onChange,
  isClearable = true,
  defaultCode,
}: Props) {
  const [countries, setCountries] = useState<CountryReferential[]>([]);

  useEffect(
    () => {
      let aborted = false;
      const fetchRef = async () => {
        const { referentials, error } = await fetchReferentials(
          'isocountries',
          {
            immutable: false,
          }
        );
        if (!aborted && !error) {
          const localCountries = referentials as CountryReferential[];
          setCountries(localCountries);
          if (!value && defaultCode) {
            const defaultCountry = localCountries.find(
              (country) => country.code === defaultCode
            );
            if (defaultCountry) {
              onChange(defaultCountry);
            }
          }
        }
      };
      fetchRef();
      return () => {
        aborted = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getOptionLabel = useCallback(
    (country: CountryReferential) =>
      `${getCountryFlag(country.code)} ${country.label}`,
    []
  );

  const getOptionValue = useCallback(
    (country: CountryReferential) => country.code,
    []
  );

  const onLocalChange = useCallback(
    (opt: TurboSelectOption<CountryReferential>) => {
      onChange(opt as CountryReferential | undefined | null);
    },
    [onChange]
  );

  // init value with first target market in the list

  return (
    <TurboSelect<CountryReferential>
      aria-label={ariaLabel}
      value={value}
      options={countries}
      onChange={onLocalChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isClearable={isClearable}
      isSearchable
    />
  );
}
