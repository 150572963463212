import { Map, fromJS } from 'immutable';
import { call, delay, put, race, select, take } from 'redux-saga/effects';

import { UPDATE_USERSETTING } from 'constants/events/user';
import { getUserSettings, isLoggedAs } from 'core/api/user';
import { selectUser } from 'reducers/user/selectors';
import { upsertUserSetting } from 'resources/authApi';
import { isProduction } from 'utils';
import { get } from 'utils/immutable';
import { logError } from 'utils/logging';

import {
  trackAction,
  updateColumnSelection,
  upsertListColumns,
} from '../actions';
import { CANCEL_UPSERT_LIST_COLUMNS } from '../actions/types';
import { selectCatalogContext } from '../selectors';
import { getCode, getId } from '../selectors/referential';

export function* updateListColumnsSaga({ payload = {} } = {}) {
  const {
    referential,
    selected,
    userPreferredColumns,
    organizationAllowedColumns,
  } = payload;
  try {
    // Compute new user prefered columns for list
    let newUserPreferredListColumns;
    if (userPreferredColumns) {
      newUserPreferredListColumns = [...userPreferredColumns];
    } else {
      // Fallback on organization allowed columns if the user does not have settings yet
      newUserPreferredListColumns = [...organizationAllowedColumns];
    }
    const referentialId = getId(referential);
    const referentialIndex = newUserPreferredListColumns.indexOf(referentialId);
    if (selected && referentialIndex === -1) {
      newUserPreferredListColumns.push(referentialId);
    }
    if (!selected && referentialIndex > -1) {
      newUserPreferredListColumns = newUserPreferredListColumns.filter(
        (refId) => refId !== referentialId
      );
    }
    const user = yield select(selectUser);
    const userSettings = getUserSettings(user);
    const listColumnsSettings = get(userSettings, 'listcolumns') || Map();
    const context = yield select(selectCatalogContext);
    const value = listColumnsSettings.set(
      context.userColumns,
      fromJS(newUserPreferredListColumns)
    );
    // Update store for user
    yield put({ type: UPDATE_USERSETTING, key: 'listcolumns', value });
    // Update store for column settings
    yield put(updateColumnSelection({ referential, selected }));
    yield put(upsertListColumns(value));
    yield put(
      trackAction({
        action: 'columns_updated',
        label: `${getCode(referential)}:${selected ? 'added' : 'removed'}`,
      })
    );
  } catch (err) {
    logError(err);
  }
}

function* upsertListColumnsTask(value) {
  try {
    const user = yield select(selectUser);
    if (!(isLoggedAs(user) && isProduction())) {
      yield delay(1000);
      const { error } = yield call(upsertUserSetting, {
        key: 'listcolumns',
        value,
      });
      if (error) {
        logError(error);
      }
    }
  } catch (err) {
    logError(err);
  }
}

export function* upsertListColumnsSaga({ payload } = {}) {
  yield race({
    task: call(upsertListColumnsTask, payload),
    cancel: take(CANCEL_UPSERT_LIST_COLUMNS),
  });
}
