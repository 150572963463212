import { Map } from 'immutable';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Checkbox } from '@alkem/react-ui-inputs';
import TurboSelect, { TurboSelectOption } from '@alkem/react-ui-turbo-select';

import CollapsibleFilter from 'core/modules/list/components/filter/collapsible';
import {
  FilterStatuses,
  recipientsScenariosFilter,
} from 'core/modules/list/constants/filters/recipients-scenarios';
import { validationApiNotImmutable } from 'resources/validationApi';
import i18n from 'utils/i18n';

import './index.scss';

interface ScenarioBasicDetail {
  id: number;
  label: string;
}

interface Props {
  onChange: (filter: any, fromQuery?: boolean) => void;
  onCollapse: (key: string, collapsed: boolean) => void;
  collapsed: boolean;
  filterQueryValues?: { scenario_id: number; status: FilterStatuses };
  scenarioAggregations?: Map<number, any>;
  selectedFilterMap: Map<number, Map<string, any>>;
  aggregations: Map<string, { doc_count: string }>;
}

export const RecipientsScenariosFilter = ({
  onChange,
  onCollapse,
  collapsed,
  filterQueryValues,
  scenarioAggregations,
  selectedFilterMap = Map(),
  aggregations = Map(),
}: Props) => {
  const [selectableScenarios, setSelectableScenarios] = useState<
    ScenarioBasicDetail[]
  >([]);

  const scenarioFilterState = useMemo(() => {
    const scenarioId = Number(selectedFilterMap.keySeq().first());
    if (Number.isInteger(scenarioId) && selectableScenarios.length) {
      const scenario = selectableScenarios.find((m) => m.id === scenarioId);
      if (scenario) {
        return {
          scenario,
          status: selectedFilterMap.first().get('status'),
        };
      }
    }

    return {};
  }, [selectableScenarios, selectedFilterMap]);

  const filterLabel = i18n.t(
    'frontproductstream.catalog_filter_scenarios.filter.title',
    {
      defaultValue: 'Scenarios',
    }
  );

  const computeScenariosInfos = async (listScenarioIds: number[]) => {
    const scenariosResponse =
      await validationApiNotImmutable.listInfosRuleSetsIn(listScenarioIds);

    const scenarios = scenariosResponse.data?.data?.rulesets;

    if (scenarios) {
      setSelectableScenarios(scenarios);
    }
  };

  // Fetch scenarios infos list only if there is a change in the aggregation list
  useEffect(() => {
    if (scenarioAggregations && scenarioAggregations.keySeq().size) {
      const scenarioIds = scenarioAggregations.keySeq().toJS();
      if (
        scenarioIds.length !== selectableScenarios.length ||
        !selectableScenarios
          .map((scenario) => scenario.id)
          .every((id) => scenarioIds.includes(id))
      )
        computeScenariosInfos(scenarioIds);
    } else {
      setSelectableScenarios([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioAggregations]);

  const updateFilter = useCallback(
    (
      scenario: ScenarioBasicDetail,
      status: FilterStatuses,
      fromQuery = false
    ) => {
      onChange(
        {
          add: true,
          singleValue: true,
          data: {
            scenario_id: scenario.id,
            status,
          },
          key: recipientsScenariosFilter.key,
          label: `${filterLabel}: ${scenario.label}`,
          value: scenario.id,
          valuePath: recipientsScenariosFilter.valuePath,
        },
        fromQuery
      );
    },
    [onChange, filterLabel]
  );

  const onChangeScenario = (
    scenario: TurboSelectOption<ScenarioBasicDetail>
  ) => {
    if (scenario) {
      updateFilter(scenario as ScenarioBasicDetail, FilterStatuses.none);
    } else {
      onChange({
        add: false,
        key: recipientsScenariosFilter.key,
        value: scenarioFilterState.scenario?.id,
      });
    }
  };

  const onChangeSatisfying = (event: ChangeEvent<any>) => {
    let status: FilterStatuses;
    if (event.target.checked) {
      if (scenarioFilterState.status === FilterStatuses.error) {
        status = FilterStatuses.all;
      } else {
        status = FilterStatuses.success;
      }
    } else {
      if (scenarioFilterState.status === FilterStatuses.all) {
        status = FilterStatuses.error;
      } else {
        status = FilterStatuses.none;
      }
    }
    if (scenarioFilterState.scenario) {
      // should always be true as checkboxes are disabled when no scenario is set
      updateFilter(scenarioFilterState.scenario, status);
    }
  };

  const onChangeNotSatisfying = (event: ChangeEvent<any>) => {
    let status: FilterStatuses;
    if (event.target.checked) {
      if (scenarioFilterState.status === FilterStatuses.success) {
        status = FilterStatuses.all;
      } else {
        status = FilterStatuses.error;
      }
    } else {
      if (scenarioFilterState.status === FilterStatuses.all) {
        status = FilterStatuses.success;
      } else {
        status = FilterStatuses.none;
      }
    }
    if (scenarioFilterState.scenario) {
      // should always be true as checkboxes are disabled when no scenario is set
      updateFilter(scenarioFilterState.scenario, status);
    }
  };

  const initFiltersFromQuery = useCallback(() => {
    if (
      !scenarioFilterState.scenario?.id &&
      filterQueryValues &&
      selectableScenarios.length
    ) {
      const scenarioId = filterQueryValues.scenario_id;

      const scenario = selectableScenarios.find((sc) => sc.id === scenarioId);
      if (scenario) {
        updateFilter(scenario, filterQueryValues.status, true);
      }
    }
  }, [
    filterQueryValues,
    scenarioFilterState,
    selectableScenarios,
    updateFilter,
  ]);

  const formatAggregation = (label: string, type: string, bypass: boolean) => {
    const count = aggregations.getIn([type, 'doc_count']);
    if (!bypass && count >= 0) {
      return `${label} (${count})`;
    }
    return label;
  };

  useEffect(() => {
    if (selectableScenarios.length) {
      initFiltersFromQuery();
    }
  }, [initFiltersFromQuery, selectableScenarios]);

  const disabled = !scenarioFilterState.scenario?.id;
  return (
    <CollapsibleFilter
      id="scenarios-filter"
      collapsed={collapsed}
      label={filterLabel}
      onCollapse={(isCollapsed: boolean) =>
        onCollapse(recipientsScenariosFilter.key, isCollapsed)
      }
    >
      <TurboSelect
        id="scenarios-filter-autocomplete"
        placeholder={i18n.t(
          'frontproductstream.catalog_filter_scenarios.dropdown.placeholder',
          { defaultValue: 'Select a scenario' }
        )}
        noOptionsMessage={() =>
          i18n.t(
            'frontproductstream.catalog_filter_scenarios.dropdown_no_scenarios.text',
            {
              defaultValue: 'No available scenario',
            }
          )
        }
        isSearchable
        value={scenarioFilterState.scenario}
        options={selectableScenarios}
        onChange={onChangeScenario}
        menuPlacement="bottom"
      />
      <div className="RecipientsScenariosFilter__status">
        <Checkbox
          id="scenarios-filter-satisfying"
          label={formatAggregation(
            i18n.t(
              'frontproductstream.catalog_filter_scenarios.satisfying_checkbox.label',
              { defaultValue: 'Satisfying' }
            ),
            FilterStatuses.success,
            disabled
          )}
          checked={[FilterStatuses.success, FilterStatuses.all].includes(
            scenarioFilterState.status
          )}
          disabled={disabled}
          onChange={onChangeSatisfying}
        />
        <Checkbox
          id="scenario-filter-not-satisfying"
          label={formatAggregation(
            i18n.t(
              'frontproductstream.catalog_filter_scenarios.not_satisfying_checkbox.label',
              { defaultValue: 'Not satisfying' }
            ),
            FilterStatuses.error,
            disabled
          )}
          checked={[FilterStatuses.error, FilterStatuses.all].includes(
            scenarioFilterState.status
          )}
          disabled={disabled}
          onChange={onChangeNotSatisfying}
        />
      </div>
    </CollapsibleFilter>
  );
};
