import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import './tipster.scss';

const classNameSuffixes = {
  information: 'info',
  success: 'success',
  warning: 'warning',
  error: 'danger',
  help: 'help',
};

const icons = {
  information: 'information-outline',
  success: 'checkbox-marked-circle-outline',
  warning: 'alert-circle',
  error: 'alert-circle',
  help: 'help-circle',
};

class Tipster extends Component {
  static propTypes = {
    className: PropTypes.string,
    info: PropTypes.node,
    type: PropTypes.string,
    iconTop: PropTypes.bool,
    icon: PropTypes.string,
    compact: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    info: '',
    type: 'information',
    icon: null,
    compact: false,
    children: null,
  };

  getClassNames() {
    const { className, type, iconTop, compact } = this.props;
    const suffix = classNameSuffixes[type];
    const classes = {
      Tipster: true,
      'Tipster--iconTop': iconTop,
      [`Tipster--${suffix}`]: !!suffix,
      'Tipster--compact': !!compact,
    };
    if (className) {
      classes[className] = true;
    }
    return classNames(classes);
  }

  renderIcon() {
    const { type, icon } = this.props;
    const iconName = icon || icons[type];
    if (!iconName) {
      return null;
    }
    return <i className={`Tipster__icon mdi mdi-${iconName}`} />;
  }

  render() {
    const { info, children } = this.props;
    if (!info && !children) {
      return null;
    }
    return (
      <div className={this.getClassNames()}>
        {this.renderIcon()}
        <div className="Tipster__text">{info || children}</div>
      </div>
    );
  }
}

export default Tipster;
