import { combineReducers, compose } from 'redux';
import { fork } from 'redux-saga/effects';

import { isNotThirdParty } from 'core/api/user';
import { withRestrictedFeature } from 'core/modules/restricted';
import { selectUser } from 'reducers/user/selectors';
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { REDUCER_KEY } from './constants';
import { GENERIC_REDUCER_KEY, genericReducers, genericSagas } from './generic';
import {
  ReportingWrapper,
  WRAPPER_REDUCER_KEY,
  wrapperReducers,
  wrapperSagas,
} from './wrapper';

registerModuleReducer(
  REDUCER_KEY,
  combineReducers({
    [GENERIC_REDUCER_KEY]: genericReducers,
    [WRAPPER_REDUCER_KEY]: wrapperReducers,
  })
);

registerSaga(function* mainReportingSaga() {
  yield fork(genericSagas);
  yield fork(wrapperSagas);
});

export default withRestrictedFeature({
  View: ReportingWrapper,
  selectors: [compose(isNotThirdParty, selectUser)],
});
