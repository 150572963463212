import { negate, set, update } from 'lodash/fp';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { SwitchButton } from '@alkem/react-ui-button';

import { notificationError, notificationSuccess } from 'actions/notification';
import Anchor from 'components/ui/basic/anchor';
import InputWithLabel from 'components/ui/input/input-with-label';
import InputText from 'components/ui/input/text';
import Modal from 'components/ui/modal';
import {
  ASSET_ORIGIN_STREAM_USER,
  ASSET_SCOPE,
  PRODUCT_VIDEO_TYPE_BRAINSONIC,
} from 'constants/media';
import { selectHasSpecificMediaFeature } from 'modules/feature-flag/selectors';
import { setFilterRuleSetsFields } from 'modules/view-as/actions';
import { selectFilterRuleSetsFields } from 'modules/view-as/selectors';
import { selectIsRetailer, selectUser } from 'reducers/user/selectors';
import mediaApi from 'resources/mediaApi';
import { GlobalState, UserImmutable } from 'types';
import i18n from 'utils/i18n';

import AssetFormGroup from '../form-group';

import './video-modal.scss';

interface Video {
  uniformResourceIdentifier?: string;
  scope: string;
  productKeyId?: string;
  thumbnailUniformResourceIdentifier?: string;
}
interface VideoModalProps {
  asset?: Video;
  title: string;
  productKeyId: string | number;
  onSuccess: () => void;
  onClose: () => void;
  showAdditionalFieldsSwitch?: boolean;
}

interface VideoModalState {
  isAdding: boolean;
  isModifying: boolean;
  video: Video;
}

interface StateProps {
  filterRuleSetsFields: boolean;
  user: UserImmutable;
  hasSpecificMediaFeature?: boolean;
  showAdditionalFieldsSwitch: boolean;
}

interface DispatchProps {
  notificationSuccess: (message: string) => void;
  notificationError: (message: string, options?: object) => void;
  setFilterRuleSetsFields: typeof setFilterRuleSetsFields;
}

const mapStateToProps = createStructuredSelector<GlobalState, StateProps>({
  hasSpecificMediaFeature: selectHasSpecificMediaFeature,
  user: selectUser,
  filterRuleSetsFields: selectFilterRuleSetsFields,
  showAdditionalFieldsSwitch: negate(selectIsRetailer),
});

const mapDispatchToProps = {
  notificationError,
  notificationSuccess,
  setFilterRuleSetsFields,
};
export class VideoModal extends PureComponent<
  VideoModalProps & StateProps & DispatchProps,
  VideoModalState
> {
  state: VideoModalState = {
    isAdding: false,
    isModifying: false,
    video: {
      ...this.props.asset,
      scope: ASSET_SCOPE.PUBLIC,
    },
  };

  handleRequest(method, payload) {
    method(payload).then(
      () => {
        this.props.notificationSuccess(
          i18n.t(
            'frontproducstream.asset_list.video_modal.update_notification.success',
            { defaultValue: 'Video successfully edited.' }
          )
        );
        this.props.onSuccess();
      },
      () => {
        this.props.notificationError(
          i18n.t(
            'frontproducstream.asset_list.video_modal.update_notification.error',
            { defaultValue: 'An error occured while editing the video.' }
          )
        );
        this.props.onClose();
      }
    );
  }

  onChangeField = (path, value) => {
    this.setState(update(['video'], set(path, value)));
  };

  onChangeScope = (checked) => {
    this.onChangeField(
      'scope',
      checked ? ASSET_SCOPE.PUBLIC : ASSET_SCOPE.SPECIFIC
    );
  };

  onChangeFormGroup = (model, value) => {
    this.onChangeField(model, value);
  };

  onChangeVideoId = (event) => {
    this.onChangeField('uniformResourceIdentifier', event.target.value);
  };

  onChangeVideoURL = (event) => {
    this.onChangeField(
      'thumbnailUniformResourceIdentifier',
      event.target.value
    );
  };

  onCheckOptionalField = (checked: boolean) => {
    this.props.setFilterRuleSetsFields(!checked);
  };

  addVideo = () => {
    const { hasSpecificMediaFeature } = this.props;
    const { video } = this.state;

    if (video.uniformResourceIdentifier) {
      const { productKeyId } = this.props;
      const productVideo = {
        ...video,
        _type: 'ProductVideo',
        type: PRODUCT_VIDEO_TYPE_BRAINSONIC.id,
        typeName: PRODUCT_VIDEO_TYPE_BRAINSONIC.name,
        product_key_id: productKeyId,
        origin: ASSET_ORIGIN_STREAM_USER.id,
        scope: hasSpecificMediaFeature ? video.scope : null,
      };

      this.setState(set(['isAdding'], true));

      mediaApi.ProductVideoCreate(productVideo).then(
        () => {
          this.setState(set(['isAdding'], false));
          this.props.onSuccess();
          this.props.notificationSuccess(
            i18n.t(
              'frontproducstream.asset_list.video_modal.create_notification.success',
              { defaultValue: 'Video has been added' }
            )
          );
        },
        () => {
          this.setState(set(['isAdding'], false));
          this.props.notificationError(
            i18n.t(
              'frontproducstream.asset_list.video_modal.create_notification.error',
              { defaultValue: 'Video could not be added' }
            )
          );
        }
      );
    } else {
      this.props.notificationError(
        i18n.t(
          'frontproducstream.asset_list.video_modal.create_notification.missing_uri_error',
          { defaultValue: 'Video ID must be specified' }
        ),
        {
          context: 'modal',
        }
      );
    }
  };

  updateVideo = () => {
    this.setState(set(['isAdding'], true));
    const method = mediaApi.ProductVideoUpdate.bind(mediaApi);
    const payload = {
      ...this.state.video,
      product_key_id: this.state.video.productKeyId,
    };
    this.handleRequest(method, payload);
  };

  render() {
    const {
      title,
      hasSpecificMediaFeature,
      asset,
      filterRuleSetsFields = true,
      showAdditionalFieldsSwitch = true,
    } = this.props;
    const { isAdding, video, isModifying } = this.state;
    const additionalFooterContent = showAdditionalFieldsSwitch && (
      <SwitchButton
        content={i18n.t(
          'frontproducstream.asset_list.modal.show_more_fields_button.label',
          {
            defaultValue:
              'Show optional fields to market more efficiently your product',
          }
        )}
        onChange={this.onCheckOptionalField}
        checked={!filterRuleSetsFields}
      />
    );

    return asset ? (
      <Modal
        title={title}
        isProcessing={isModifying}
        onConfirm={this.updateVideo}
        onClose={this.props.onClose}
        additionalFooterContent={additionalFooterContent}
      >
        <div className="VideoModal ProductPage__modal">
          <div className="row">
            <span className="col-xs-4 alk-txt-right">
              {i18n.t('frontproducstream.asset_list.video.uri.label', {
                defaultValue: 'Video ID',
              })}
            </span>
            <span className="col-xs-8">{video.uniformResourceIdentifier}</span>
          </div>
          <div className="row">
            <span className="col-xs-4 alk-txt-right">
              {i18n.t(
                'frontproducstream.asset_list.video_modal.preview_url.label',
                { defaultValue: 'Preview picture URL (Optional)' }
              )}
            </span>
            <span className="col-xs-8">
              {video.thumbnailUniformResourceIdentifier}
            </span>
          </div>
          <AssetFormGroup
            entity={video}
            controller="video"
            onChangeField={this.onChangeFormGroup}
          />
        </div>
      </Modal>
    ) : (
      <Modal
        title={title}
        isProcessing={isAdding}
        onConfirm={this.addVideo}
        onClose={this.props.onClose}
        additionalFooterContent={additionalFooterContent}
      >
        <div className="VideoModal ProductPage__modal">
          {hasSpecificMediaFeature && (
            <InputWithLabel
              childId="ProductVideoForm-scope"
              label={i18n.t(
                'frontproducstream.asset_list.modal.shared_scoped.label',
                { defaultValue: 'Shared by default' }
              )}
            >
              <div className="ProductVideoForm__scope">
                <SwitchButton
                  onChange={this.onChangeScope}
                  checked={video.scope === ASSET_SCOPE.PUBLIC}
                />
              </div>
            </InputWithLabel>
          )}
          <InputText
            id="videoId"
            label={i18n.t('frontproducstream.asset_list.video.uri.label', {
              defaultValue: 'Video ID',
            })}
            onChange={this.onChangeVideoId}
            value={video.uniformResourceIdentifier}
            placeholder="Video ID"
          />
          <InputText
            id="videoURL"
            label={i18n.t(
              'frontproducstream.asset_list.video_modal.preview_url.label',
              { defaultValue: 'Preview picture URL (Optional)' }
            )}
            onChange={this.onChangeVideoURL}
            value={video.thumbnailUniformResourceIdentifier}
            placeholder="URL"
          />
          <AssetFormGroup
            entity={video}
            controller="video"
            onChangeField={this.onChangeFormGroup}
          />
          <div className="col-md-12 VideoModal__link">
            <Anchor href="https://dam-cvc1-admin-alkemics.wedia-group.com">
              <span>
                {i18n.t(
                  'frontproducstream.asset_list.video_modal.add_link.label',
                  { defaultValue: 'Add a video on Brainsonic' }
                )}
              </span>
            </Anchor>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect<StateProps, DispatchProps, VideoModalProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(VideoModal);
