import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import i18n from 'utils/i18n';

import './styles.scss';

function CreateSharingUnitButton(props) {
  return (
    <button
      type="button"
      className="BigDottedButton AddSharingUnitButton"
      onClick={props.create(props.retailer)}
    >
      <i className="mdi mdi-plus-circle" />
      {i18n.t(
        'frontproductstream.product_page.sharing_unit_create_listing.button',
        {
          defaultValue: 'Add new {{retailer}} listing',
          retailer: props.retailer.get('nameLegal'),
        }
      )}
    </button>
  );
}

CreateSharingUnitButton.propTypes = {
  create: PropTypes.func.isRequired,
  retailer: ImmutablePropTypes.map.isRequired,
};

export default CreateSharingUnitButton;
