import type Immutable from 'immutable';
import { matchesProperty } from 'lodash';
import {
  ComponentProps,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';
import { Referential } from '@alkem/sdk-dashboard';

import { fetchReferential } from 'core/modules/with-referential/actions';
import { selectReferential } from 'core/modules/with-referential/selectors';
import { useDispatch } from 'hooks/useDispatch';
import i18n from 'utils/i18n';
import { get, size, toJsIfImmutable } from 'utils/immutable';
import { capitalize } from 'utils/string';

import { dietTypeFilter } from '../../../constants/filters';
import ListCollapsibleAdvancedFilter from '../advanced';

import './diet-type.scss';

interface DietTypeFilterProps {
  key: string;
  filterConfig?: {
    collapsed?: boolean;
    page?: number;
    query?: string;
  };
  filterQueryValues?: string[];
  selectedFilterMap?: Immutable.Map<string, boolean>;
  aggregations: Record<string, { id: string; doc_count: number }>;
}

const REFERENTIAL = 'EthicsAndPractices';

const formatLabel = (filterName: string = '', optionLabel: string = '') =>
  `${i18n.t('frontproductstream.core.list_filter_diet_type.label_prefix', {
    defaultValue: 'Diet',
  })}: ${filterName} - ${optionLabel}`;

const getOptions = (referentialId: number | string) => [
  {
    id: `${referentialId}_yes`,
    label: i18n.t('frontproductstream.core.list_filter_diet_type.yes', {
      defaultValue: 'Yes',
    }),
  },
  {
    id: `${referentialId}_no`,
    label: i18n.t('frontproductstream.core.list_filter_diet_type.no', {
      defaultValue: 'No',
    }),
  },
  {
    id: `${referentialId}_na`,
    label: i18n.t(
      'frontproductstream.core.list_filter_diet_type.not_applicable',
      {
        defaultValue: 'N/A',
      }
    ),
  },
];

export const DietTypeFilter: FC<
  Omit<
    ComponentProps<typeof ListCollapsibleAdvancedFilter>,
    | 'id'
    | 'filters'
    | 'filterKey'
    | 'filterLabel'
    | 'searchPlaceholder'
    | 'selectors'
  > &
    DietTypeFilterProps
> = memo((props) => {
  const { onChange, aggregations, selectedFilterMap } = props;
  const dispatch = useDispatch();

  const referentialEntities: { entities: Referential[] } = toJsIfImmutable(
    useSelector<any, Function>(selectReferential)(REFERENTIAL)
  );

  const diets = useMemo(
    () =>
      referentialEntities?.entities.map(({ id, label }) => ({
        id,
        name: label || '',
      })),
    [referentialEntities]
  );

  const selectedFilters = useMemo(
    () => size(selectedFilterMap) ?? 0,
    [selectedFilterMap]
  );

  const queryFilters = useMemo(
    () => size(props.filterQueryValues) ?? 0,
    [props.filterQueryValues]
  );

  useEffect(() => {
    referentialEntities || dispatch(fetchReferential(REFERENTIAL));
  }, [dispatch, referentialEntities]);

  useEffect(() => {
    if (!selectedFilters && queryFilters && diets) {
      const filters =
        props.filterQueryValues?.map((value) => {
          const referentialId = value.match(/(\d+)/)?.[0];
          const entity = diets.find(
            (diet) => diet.id.toString() === referentialId
          );
          const options = getOptions(referentialId || '');
          const selectedOption = options.find(matchesProperty('id', value));
          return {
            key: dietTypeFilter.key,
            value: value,
            add: true,
            useAndOperator: true,
            label: formatLabel(entity?.name || '', selectedOption?.label || ''),
          };
        }) || [];
      onChange(filters);
    }
  }, [onChange, selectedFilters, props.filterQueryValues, queryFilters, diets]);

  const renderItem = useCallback(
    (filter: { id: string | number; name: string }) => {
      const options = getOptions(filter.id);
      return (
        <li className="DietTypeItem" key={filter.name}>
          <span className="DietTypeItem_title">{capitalize(filter.name)}</span>
          <span className="DietTypeItem_options">
            {options.map((option) => (
              <Checkbox
                onChange={(checked) => {
                  onChange({
                    key: dietTypeFilter.key,
                    value: option.id,
                    type: 'term',
                    label: formatLabel(filter.name, option.label),
                    add: checked,
                    useAndOperator: true,
                  });
                }}
                checked={get(selectedFilterMap, [option.id]) ?? false}
                disabled={selectedFilterMap?.some(
                  (_, key = '') =>
                    key.startsWith(`${filter.id}_`) && key !== option.id
                )}
                key={option.id}
                id={option.id}
                label={`${option.label} (${
                  aggregations?.[option.id]?.doc_count || 0
                })`}
              />
            ))}
          </span>
        </li>
      );
    },
    [onChange, selectedFilterMap, aggregations]
  );

  return (
    <ListCollapsibleAdvancedFilter
      {...props}
      aggregations={null}
      id="list-filter-diettype"
      filterKey={dietTypeFilter.key}
      filterLabel={i18n.t(
        'frontproductstream.core.list_filter_diet_type.label',
        {
          defaultValue: 'Diet type',
        }
      )}
      filters={diets}
      searchPlaceholder={i18n.t(
        'frontproductstream.core.list_filter_diet_type.search_placeholder',
        {
          defaultValue: 'Search for diets',
        }
      )}
      searchQuery={get(props.filterConfig, 'query')}
      page={get(props.filterConfig, 'page')}
      collapsed={get(props.filterConfig, 'collapsed')}
      renderItem={renderItem}
      withPagination
    />
  );
});
