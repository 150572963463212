import { List, Map, Set } from 'immutable';

import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
  PRODUCT_DOCUMENT,
  PRODUCT_PICTURE,
  PRODUCT_VIDEO,
  SHARING_UNIT,
  TARIFF,
} from 'constants/fields';
import { get, size } from 'utils/immutable';

export function getLocalizedOrganizationName(organization) {
  const nameLegal = get(organization, 'nameLegal');
  const targetMarkets = get(organization, 'worksOnTargetMarkets');
  if (targetMarkets && size(targetMarkets) === 1) {
    const targetMarketLabel = get(targetMarkets, '0.label');
    if (targetMarketLabel) {
      return `${nameLegal} (${targetMarketLabel})`;
    }
  }
  return nameLegal;
}

export const getOrganizationFields = (organization) =>
  Map({
    [CONSUMER_UNIT]: Set(),
    [LOGISTICAL_UNIT]: Set(),
    [SHARING_UNIT]: Set(),
    [TARIFF]: Set(),
    [DISPLAY_UNIT]: Set(),
    [PRODUCT_PICTURE]: Set(),
    [PRODUCT_DOCUMENT]: Set(),
    [PRODUCT_VIDEO]: Set(),
  }).withMutations((dict) => {
    const usesFields = get(organization, 'usesFields') || List();
    for (const field of usesFields) {
      const entityType = field.get('entityType');
      if (!dict.has(entityType)) {
        dict.set(entityType, Set());
      }
      dict.update(entityType, (fieldNames) =>
        fieldNames.add(field.get('name'))
      );
    }
  });

export const getOrganizationMainCurrency = (organization) =>
  get(organization, 'mainCurrency');

export const getOrganizationWorksOnTargetMarket = (targetMarketId) => (o) =>
  (get(o, 'worksOnTargetMarkets') || []).some(
    (tm) => tm.get('id') === targetMarketId
  );

export const getOrganizationsForTargetMarket = (
  organizations,
  targetMarketId
) => organizations.filter(getOrganizationWorksOnTargetMarket(targetMarketId));

export const getOrganizationAddress = (organization) =>
  organization.get('address');
export const getOrganizationAddressSecondary = (organization) =>
  organization.get('addressSecondary');
export const getOrganizationPostCode = (organization) =>
  organization.get('postCode');
export const getOrganizationCity = (organization) => organization.get('city');
export const getOrganizationCountry = (organization) =>
  organization.get('country');
