import i18n from 'utils/i18n';

export const MODULE_NAME = 'logistical-hierarchies';

export const ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT = 'LogisticalHierarchyUnit';
export const ENTITY_TYPE_LOGISTICAL_HIERARCHY_DIMENSIONS =
  'LogisticalHierarchyDimensions';
export const ENTITY_TYPE_LOGISTICAL_HIERARCHY_WEIGHTS =
  'LogisticalHierarchyWeights';

export const SECTION_TITLE = i18n.t(
  'frontproductstream.logistical_hierarchies.section.title',
  { defaultValue: 'Logistical Hierarchies' }
);
export const SECTION_ICON = 'truck';

export const RESET = 'logistical-hierarchies/RESET';
export const LOGISTICAL_HIERARCHIES_INIT = 'LOGISTICAL_HIERARCHIES_INIT';
export const LIST_HIERARCHIES = 'LIST_HIERARCHIES';
export const LOGISTICAL_HIERARCHIES_CREATE_UNIT =
  'LOGISTICAL_HIERARCHIES_CREATE_UNIT';
export const LOGISTICAL_HIERARCHIES_CREATE_TEMPLATE =
  'LOGISTICAL_HIERARCHIES_CREATE_TEMPLATE';
export const LOGISTICAL_HIERARCHIES_DELETE_UNIT =
  'LOGISTICAL_HIERARCHIES_DELETE_UNIT';
export const LOGISTICAL_HIERARCHIES_UPDATE_QUANTITY =
  'LOGISTICAL_HIERARCHIES_UPDATE_QUANTITY';
export const LOGISTICAL_HIERARCHIES_UPDATE_REFERENCE =
  'LOGISTICAL_HIERARCHIES_UPDATE_REFERENCE';
export const LOGISTICAL_HIERARCHIES_SET_EDITED_UNIT =
  'LOGISTICAL_HIERARCHIES_SET_EDITED_UNIT';
export const LOGISTICAL_HIERARCHIES_SET_DELETED_ROOT =
  'LOGISTICAL_HIERARCHIES_SET_DELETED_ROOT';
export const LOGISTICAL_HIERARCHIES_RECEIVE_DIFFS =
  'LOGISTICAL_HIERARCHIES_RECEIVE_DIFFS';
export const LOAD_DISPLAY_GROUPS = 'logistical-hierarchies/LOAD_DISPLAY_GROUPS';
export const RECEIVE_DISPLAY_GROUPS =
  'logistical-hierarchies/RECEIVE_DISPLAY_GROUPS';
export const BULK_UPDATE = 'logistical-hierarchies/BULK_UPDATE';
export const TOGGLE = 'logistical-hierarchies/TOGGLE';
export const LOGISTICAL_HIERARCHIES_PRIVATE_FIELD_EDIT =
  'logistical-hierarchies/PRIVATE_FIELD_EDIT';
