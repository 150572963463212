import {
  hasInformationRequest,
  hasSupplierDirectory,
} from 'core/api/organization-settings';
import {
  importsGenericInformationRequestReport,
  importsGenericSuppliersReport,
} from 'routes';
import i18n from 'utils/i18n';

export const REDUCER_KEY = 'imports-generic';

// Pagination
export const PAGINATION_LOCALSTORAGE_KEY = `${REDUCER_KEY}/PAGINATION_GENERIC_IMPORTS`;

// Type import
export const INFORMATION_REQUEST_IMPORT = 'Request-engine-import';
export const SUPPLIER_DIRECTORY_IMPORT = 'Supplier-directory-import';

// Type report
export const SUPPLIER_DIRECTORY_REPORT = 'Supplier';
export const INFORMATION_REQUEST_REPORT = 'Information Request';
export const ERROR_STATUS_TYPES = ['ERROR', 'TIMEOUT'];

export const EMPTY_STATUS = 'EMPTY';

// Name modal
export const CHOICE_MODAL = 'generic-imports-choice-modal';
export const UPLOAD_MODAL = 'generic-imports-upload-modal';

// Import available
export const allImportsAvailable = [
  {
    typeImport: INFORMATION_REQUEST_IMPORT,
    isAvailable: [hasInformationRequest, hasSupplierDirectory],
    modalLabel: i18n.t('Request engine'),
  },
  {
    typeImport: SUPPLIER_DIRECTORY_IMPORT,
    isAvailable: [hasSupplierDirectory],
    modalLabel: i18n.t('Supplier directory'),
  },
];

// Report available
export const allReportsAvailable = [
  {
    typeReport: INFORMATION_REQUEST_REPORT,
    isAvailable: [hasInformationRequest, hasSupplierDirectory],
    label: i18n.t('Requests'),
    pathname: importsGenericInformationRequestReport,
  },
  {
    typeReport: SUPPLIER_DIRECTORY_REPORT,
    isAvailable: [hasSupplierDirectory],
    label: i18n.t('Suppliers'),
    pathname: importsGenericSuppliersReport,
  },
];
