import PropTypes from 'prop-types';

import { Organization, User } from '@alkem/sdk-dashboard';

import {
  ENTITY_TYPE_PRODUCTVERSION,
  ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
} from 'constants/entities';
import { ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT } from 'modules/logistical-hierarchies/constants';
import { Diff } from 'types/diff';

export enum DataOpsPatchType {
  PRODUCT_VERSION = 'productVersion',
}

export enum DataOpsEntityType {
  PRODUCTVERSION = ENTITY_TYPE_PRODUCTVERSION,
  LOGISTICAL_HIERARCHY_UNIT = ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT,
  PRODUCTVERSION_OVERRIDE = ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
}

export enum DataOpsPatchStatus {
  ENABLED = 1,
  DISABLED = 0,
}

export enum DataOpsDiffStatus {
  PATCH_PENDING_STATUS = 'patch_pending_status',
  PATCH_RESOLVED_STATUS = 'patch_resolved_status',
  PATCH_DISPUTE_STATUS = 'patch_dispute_status',
  PATCH_CANCELLED_STATUS = 'patch_cancelled_status',
  PATCH_IGNORED_STATUS = 'patch_ignored_status',
}

export type DataOpsPatchOrganization = Pick<
  Organization,
  'id' | 'nameLegal' | 'settings'
>;

interface DataOpsPatchUser
  extends Pick<User, 'id' | 'username' | 'firstname' | 'lastname'> {
  belongsTo: [DataOpsPatchOrganization];
}

export interface DataOpsPatch {
  id: number;
  key: string;
  productKeyId: number;
  sourceProductKeyId?: number;
  retailerProductKeyId?: number;
  fieldName: string;
  type: DataOpsPatchType;
  entityType: DataOpsEntityType;
  dataOld: any;
  dataNew: any;
  currentValue?: any;
  comment?: string;
  status: DataOpsPatchStatus;
  createdAt: string;
  updatedAt: string;
  user: DataOpsPatchUser;
  diffOld: Diff[];
  diffNew: Diff[];
  diffStatus: DataOpsDiffStatus;
  acceptedAt?: null | string;
  refusedAt?: null | string;
  ignoredAt?: null | string;

  // populated by the frontend to be able to display collaborative edits on specific values
  appliesOnEntityId?: number;

  // populated by the frontend to be able to accept collaborative edits on specific values
  isSpecific?: boolean;
}

export const DataOpsPatchPropType = PropTypes.shape({
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      productKeyId: PropTypes.number,
      fieldName: PropTypes.string,
      type: PropTypes.oneOf([DataOpsPatchType.PRODUCT_VERSION]),
      dataOld: PropTypes.any,
      dataNew: PropTypes.any,
      comment: PropTypes.string,
      status: PropTypes.oneOf([
        DataOpsPatchStatus.ENABLED,
        DataOpsPatchStatus.DISABLED,
      ]),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    })
  ),
  dirty: PropTypes.bool,
  editable: PropTypes.bool,
});

export type DataOpsNewPatch = Pick<
  DataOpsPatch,
  'productKeyId' | 'fieldName' | 'type' | 'dataOld' | 'dataNew' | 'comment'
>;

export type DataOpsPatchOrNewPatch = DataOpsPatch | DataOpsNewPatch;

export interface DataOpsPatchState {
  data: DataOpsPatchOrNewPatch[];
  dirty?: boolean;
  editable?: boolean;
}

export interface DataOpsPatchesState {
  [key: string]: DataOpsPatchState;
}

export const DataOpsPatchesPropType = PropTypes.shape({});

export interface PendingMessage {
  type: string;
  currentValue: any;
  label: string;
  formattedMessage: string;
  path: string;
  sent?: boolean;
  cancel?: boolean;
}

export interface DataOpsPatchAmendingStatus {
  isAccepting?: boolean;
  isRefusing?: boolean;
}

export interface DataOpsAmendingStatus {
  [key: number]: DataOpsPatchAmendingStatus;
}

export interface DataOpsState {
  pendingMessages: {
    [productVersionId: string]: PendingMessage[];
    [productVersionId: number]: PendingMessage[];
  };
  patches: DataOpsPatchesState;
  productKeyId?: number | string;
  productKeyIdToEntityId: { [key: number]: number | string };
  entityIdToProductKeyId: { [key: string]: number; [key: number]: number };
  amendingStatus: DataOpsAmendingStatus;
}

export const DataOpsExceptions = ['kind', 'GPC', 'typePackaging', 'isMadeOf'];
