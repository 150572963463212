import { getHash, getLocation } from './location';
import qs, { withQuery } from './query';

export const NAVIGATE_EVENT = 'root_app:navigate';

export const navigate = (opt: {
  to: string;
  queries?: { [key: string]: any };
  replace?: boolean;
}) => {
  window.postMessage(
    {
      type: NAVIGATE_EVENT,
      to: withQuery(opt.to, opt.queries || {}),
      replace: opt.replace || false,
    },
    window.location.origin
  );
};

export const push = (to: string, queries?: { [key: string]: any }) =>
  navigate({ to, queries });

export const replace = (to: string, queries?: { [key: string]: any }) =>
  navigate({ to, queries, replace: true });

export const getPathname = (): string => getHash(true).split('?')[0];

export const getSearch = (): string => {
  const [, ...queries] = getHash(true).split('?');
  return queries.join('?');
};

export const getQuery = (): { [key: string]: any } => qs.parse(getSearch());

export const goBack = () => window.history.back();

export const getProtocol = () => getLocation().protocol;

export const redirect = (url: string) => getLocation().replace(url);

export const shouldResetScroll = (
  from: { pathname?: string },
  to: { pathname?: string }
) => {
  if (
    from.pathname &&
    to.pathname &&
    from.pathname === '/listings/create' &&
    /\/listings\/\d+/.test(to.pathname)
  ) {
    return false;
  }
  return from !== to;
};
