import i18n from 'utils/i18n';

import EmailIcon from './email-icon';
import './index.scss';
import ProductPreviews from './product-previews';

const Preview = () => (
  <div className="Preview">
    <div style={{ textAlign: 'center' }}>
      <EmailIcon />

      <h4 className="Preview__headline">{i18n.t('Recipient')}</h4>

      <p className="Preview__introduction">
        <b>{i18n.t('Retailer')}</b>{' '}
        {i18n.t('invites you to accept or fill the following product(s)')}.
      </p>

      <ProductPreviews />
    </div>
  </div>
);

export default Preview;
