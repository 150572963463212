import { Map } from 'immutable';
import { Component } from 'react';
import { connect } from 'react-redux';

import { ProgressBar } from '@alkem/react-ui-progress';

import { notificationError, notificationSuccess } from 'actions/notification';
import Modal from 'components/ui/modal';
import ProductReference from 'components/ui/product-reference';
import {
  getDefaultDisplayName,
  getProductGTINWithFallbackOnId,
} from 'core/api/productversion';
import {
  hasAnyProductUpdatePermission,
  isManufacturer,
  isPrivateLabel,
} from 'core/api/user';
import { selectLocalesByTargetMarket } from 'reducers/user/selectors';
import serviceProductApi from 'resources/serviceProductApi';
import { requestWithHeaders } from 'utils/api';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { isValidGtin } from 'utils/validator/gtin';

import './style.scss';

const mapStateToProps = (state) => ({
  locales: selectLocalesByTargetMarket(state),
});

const mapDispatchToProps = {
  notificationSuccess,
  notificationError,
};

type ProductVersion = object;

export type SelectedMap = Map<string, boolean>;

export type ProductMap = Map<string | undefined, Map<string, any>>;

interface Props {
  productMap: ProductMap;
  selectedMap: SelectedMap;
  locales: object;
  disabled: boolean;
  notificationSuccess: (message: string) => void;
  notificationError: (message: string, options: { context: string }) => void;
}

interface State {
  showModal: boolean;
  isProcessing: boolean;
  processedItems: number;
}

export class BulkActionPublish extends Component<Props, State> {
  public static shouldBeDisplayed = ({ user }) =>
    (isManufacturer(user) || isPrivateLabel(user)) &&
    hasAnyProductUpdatePermission(user);

  public state = {
    showModal: false,
    isProcessing: false,
    processedItems: 0,
  };

  private cancelled = false;

  public componentWillUnmount() {
    this.cancelled = true;
  }

  private getLabel() {
    const { selectedMap } = this.props;
    const count = selectedMap.size;
    if (count === 1) {
      return i18n.t('Publish 1 product');
    } else {
      return i18n.t('Publish {{count}} products', { count });
    }
  }

  private openModal = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({ showModal: true });
  };

  private closeModal = () => {
    this.setState({ showModal: false });
  };

  private resetModal = () => {
    this.setState({
      processedItems: 0,
      isProcessing: false,
    });
  };

  private publishProduct = async (productVersion) => {
    if (productVersion) {
      const product_key_id = productVersion.getIn(['product_key', 'id']);

      const { error } = await requestWithHeaders(
        serviceProductApi,
        'post',
        '/product/v3/products/publish',
        {
          product_key_id,
        }
      );

      if (error) {
        this.props.notificationError(
          i18n.t('Something went wrong while publishing the products'),
          { context: 'modal' }
        );
        logError(error);
      }
    }

    if (this.cancelled) {
      return;
    }

    this.setState((prevState) => ({
      processedItems: prevState.processedItems + 1,
    }));
  };

  private publishProducts = async () => {
    const { selectedMap } = this.props;
    const productVersions = this.getSelectedProductVersions();
    const count = selectedMap.size;
    this.setState({ isProcessing: true });
    await Promise.all(productVersions.map(this.publishProduct));
    setTimeout(() => {
      if (this.cancelled) {
        return;
      }
      this.closeModal();
      this.resetModal();
      this.props.notificationSuccess(
        count === 1
          ? i18n.t('1 product published successfully')
          : i18n.t('{{count}} products published successfully', { count })
      );
    }, 1000);
  };

  private getSelectedProductVersions = () => {
    const { selectedMap, productMap } = this.props;
    return selectedMap.map((_, pvId) => productMap.get(pvId)).toArray();
  };

  private renderDisplayName(pv?: ProductVersion) {
    const { locales } = this.props;
    return <b className="display-name">{getDefaultDisplayName(pv, locales)}</b>;
  }

  private renderProductReference(pv) {
    const reference = getProductGTINWithFallbackOnId(pv);
    if (isValidGtin(reference)) {
      return <ProductReference inline reference={reference} />;
    } else {
      return reference;
    }
  }

  private renderSelectedProducts() {
    const { selectedMap } = this.props;
    const count = selectedMap.size;
    const separator = ' • ';
    return (
      <>
        <p>
          {i18n.t(
            'Are you sure you want to publish {{count}} product(s) to all their activated recipients?',
            { count }
          )}
        </p>
        <ul>
          {this.getSelectedProductVersions()
            .filter((pv) => !!pv)
            .map((pv) => (
              <li key={pv && pv.get('id')}>
                {this.renderDisplayName(pv)}
                {separator}
                {this.renderProductReference(pv)}
              </li>
            ))}
        </ul>
      </>
    );
  }

  private renderProgressBar() {
    const { isProcessing, processedItems } = this.state;
    if (!isProcessing) {
      return null;
    }
    const { selectedMap } = this.props;
    return (
      <>
        <br />
        <ProgressBar
          value={processedItems}
          max={selectedMap.size}
          color="success"
          height="medium"
        />
      </>
    );
  }

  public render() {
    const { showModal, isProcessing } = this.state;
    return (
      <>
        <div className="ActionOption" onClick={this.openModal}>
          {this.getLabel()}
        </div>
        {showModal && (
          <Modal
            title={i18n.t('Publish products')}
            confirmButtonText={this.getLabel()}
            isProcessing={isProcessing}
            onConfirm={this.publishProducts}
            onClose={this.closeModal}
          >
            {this.renderSelectedProducts()}
            {this.renderProgressBar()}
          </Modal>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionPublish);
