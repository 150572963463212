import classNames from 'classnames';
import { List, Map } from 'immutable';
import { useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import {
  RELEASE_NEW_MATURITY_COMPONENT,
  hasFeature,
} from 'modules/feature-flag';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { sortDesc } from 'utils/sort';
import { track } from 'utils/tracking';

import PercentageCell from '../percentage';

import './index.scss';
import { MaturityPanel } from './maturity-panel';

export interface MaturitiesSummary {
  total: number;
  successful: number;
  featured_upcoming_scenario: MaturitySummary | null;
  active_scenarios: MaturitySummary[];
  upcoming_scenarios: MaturitySummary[];
  not_requested?: boolean;
}

interface Props {
  data: Map<string, any>;
  colorRange?: List<number>;
  rowIdentifier?: number;
  clickable?: boolean;
  user?: UserImmutable;
}

export interface MaturitySummary {
  id: number;
  label: string;
  deadline: string | null;
  successful: number;
  total: number;
  // recipient_id and recipient_name are only filled for manufacturers
  recipient_id?: number;
  recipient_name?: string;
}

export default function MaturityCell({
  data,
  colorRange,
  rowIdentifier,
  user,
  clickable = false,
}: Props) {
  const id = useMemo(() => uuid(), []);
  const maturitiesSummary: MaturitiesSummary = data.toJS();
  const ref = useRef<HTMLDivElement>(null);

  const [isPanelVisible, setPanelVisibilityTo] = useState<boolean>(false);

  const trackDisplayMaturitiesInfo = (displayType: string) => {
    track({
      category: 'product',
      action: 'display_scenarios_info_on_catalog',
      modal_type: displayType,
    });
  };

  const openPanel = () => {
    trackDisplayMaturitiesInfo('panel');
    setPanelVisibilityTo(true);
  };

  const closePanel = () => {
    setPanelVisibilityTo(false);
  };

  const userHasRightToDisplayPanel = user
    ? hasFeature(user, RELEASE_NEW_MATURITY_COMPONENT) && clickable
    : false;

  const body = (
    <PercentageCell
      data={data as Map<string, number>}
      countPath="successful"
      totalPath="total"
      colorRange={colorRange}
      className={classNames(
        maturitiesSummary.not_requested && 'Maturity__not-requested'
      )}
    />
  );

  if (maturitiesSummary.active_scenarios.length && userHasRightToDisplayPanel) {
    return (
      <>
        <div
          className={classNames(
            'MaturityCell MaturityCell--clickable alk-flex alk-flex-center alk-flex-justify-center',
            isPanelVisible && 'MaturityCell--clicked'
          )}
          ref={ref}
          onClick={openPanel}
        >
          {body}
        </div>
        {isPanelVisible && (
          <MaturityPanel
            cellRef={ref}
            rowIdentifier={rowIdentifier}
            onClose={closePanel}
            colorRange={colorRange}
            data={maturitiesSummary}
          />
        )}
      </>
    );
  }

  if (
    maturitiesSummary.active_scenarios.length &&
    !userHasRightToDisplayPanel
  ) {
    const maturitiesToDisplay = maturitiesSummary.active_scenarios;

    const maxMaturitiesToDisplayInTooltip = 5;

    const message = (
      <>
        {maturitiesToDisplay
          .sort((a, b) =>
            sortDesc(`${a.total - a.successful}`, `${b.total - b.successful}`)
          )
          .slice(0, maxMaturitiesToDisplayInTooltip)
          .map((maturity: MaturitySummary) => (
            <div
              key={`maturity-${maturity.id}-${id}`}
              className={classNames(
                'alk-flex',
                'alk-flex-center',
                'MaturityCell__details',
                maturity.successful === maturity.total
                  ? 'MaturityCell__details--ok'
                  : 'MaturityCell__details--ko'
              )}
            >
              <div>{maturity.label || maturity.id}</div>
              <div>
                {maturity.successful} / {maturity.total}
              </div>
              <i
                className={
                  maturity.successful === maturity.total
                    ? 'ProductValidationStatusOk'
                    : 'ProductValidationStatusKo'
                }
              />
            </div>
          ))}
        {maturitiesToDisplay.length - maxMaturitiesToDisplayInTooltip > 0 && (
          <div className="MaturityCell__rest">
            {i18n.t('+{{count}} more', {
              count:
                maturitiesToDisplay.length - maxMaturitiesToDisplayInTooltip,
            })}
          </div>
        )}
      </>
    );
    return (
      <div className="MaturityCell alk-flex alk-flex-center alk-flex-justify-center">
        <LazyTooltip
          id={`tooltip-${id}`}
          tooltipLabel={message}
          afterShow={() => trackDisplayMaturitiesInfo('tooltip')}
          block
        >
          {body}
        </LazyTooltip>
      </div>
    );
  }

  return (
    <div className="MaturityCell alk-flex alk-flex-center alk-flex-justify-center">
      {body}
    </div>
  );
}
