import type { Action, AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';

export const asAction = <
  ReturnType,
  State,
  ExtraThunkArg,
  BasicAction extends Action
>(
  thunkAction: ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>
) => thunkAction as any as AnyAction;
