import { map } from 'lodash/fp';

import { AuthApi } from '@alkem/sdk-dashboard';

import { config as contextConfig } from 'constants/context';
import { CGU_TYPE_ALKEMICS } from 'constants/permissions';
import { getLocalizedOrganizationName } from 'core/api/organization';
import i18n, { getUserLanguage as acceptLanguage } from 'utils/i18n';
import { get } from 'utils/immutable';

import { fetchReferentials } from './referentialApi';

const options = {
  acceptLanguage,
  cacheEnabled: true,
};

export const authApi = new AuthApi(options);
export const authApiImmutable = new AuthApi({ ...options, immutable: true });
export default authApi;

export const upsertUserSetting = ({
  key,
  value,
}: { key?: string; value?: any } = {}) =>
  authApiImmutable.UserSettingUpsert({ key, value });

export const fetchCguByType = async (type) => {
  try {
    const { data: cgu } = await authApi.CGUShow({ type });
    return { cgu };
  } catch (error) {
    return { error };
  }
};

export const fetchAlkemicsCgu = () => fetchCguByType(CGU_TYPE_ALKEMICS);

export const fetchPasswordValidation = async ({
  password,
  organizationId,
  token = undefined,
  isPublic = false,
}) => {
  try {
    const body: {
      password?: string;
      organization_id?: number;
      token?: string;
    } = { password };
    if (organizationId) {
      body.organization_id = organizationId;
    }
    if (token) {
      body.token = token;
    }
    const method = isPublic
      ? 'UserValidatePasswordPublic'
      : 'UserValidatePassword';
    await authApi[method](body);
    return {};
  } catch (error) {
    return { error };
  }
};

export const fetchOrganizationGuess = async (
  username,
  targetMarkets: any[] | null = null
) => {
  try {
    const response = await authApi.OrganizationGuess(username, {
      targetMarkets,
    });
    const organization = get(response, 'data.data');
    return {
      organization: {
        id: get(organization, 'id'),
        label: getLocalizedOrganizationName(organization),
      },
    };
  } catch (error) {
    return { error };
  }
};

export const postSignup = async ({ body, isInvited = false }) => {
  try {
    if (isInvited) {
      return await authApi.UserSignUpInvitation(body);
    }
    return await authApi.UserSignUp(body);
  } catch (error) {
    return { error };
  }
};

export const fetchUserActivation = async (username, token) => {
  try {
    return await authApi.UserActivate(username, token);
  } catch (error) {
    return { error };
  }
};

export const fetchUserToken = async (username, password) => {
  try {
    const response = await authApi.UserLogin(username, password);
    return {
      accessToken: get(response, 'data.access_token'),
      refreshToken: get(response, 'data.refresh_token'),
    };
  } catch (error) {
    return { error };
  }
};

export const fetchUserTokenFromOrganization = async (orgId: string) => {
  try {
    const response = await authApi.userLoginFromOrganization(orgId);
    return {
      accessToken: get(response, 'data.access_token'),
      refreshToken: get(response, 'data.refresh_token'),
    };
  } catch (error) {
    return { error };
  }
};

export const postForgottenPassword = async (username) => {
  try {
    return await authApi.UserForgotten(username, contextConfig.platform);
  } catch (error) {
    return { error };
  }
};

export const postResetPassword = async ({ password, username, token }) => {
  try {
    return await authApi.UserResetPassword({
      password,
      reset_token: token,
      username,
    });
  } catch (error) {
    return { error };
  }
};

export const fetchUserActivationCheck = async ({ username, token }) => {
  try {
    const response = await authApi.UserActivationCheck(username, token);
    return { user: get(response, 'data.data') };
  } catch (error) {
    return { error };
  }
};

export const fetchUsers = async ({
  queries,
  cache = true,
}: {
  queries?: {
    offset?: number;
    limit?: number;
    search?: string;
    with_permissions?: 1 | 0;
    permissions?: string[];
    filter_pending_invitations?: (string | number)[];
    filter_relation_status?: (string | number)[];
  };
  cache?: boolean;
}) => {
  try {
    const response = await authApi.OrganizationUserList({ queries, cache });
    return {
      list: get(response, 'data.data'),
      totalResults: get(response, 'data.totalResults'),
    };
  } catch (error) {
    return { error };
  }
};

export const acceptUserInOrganization = async ({
  accept = true,
  userId,
  organizationId,
}) => {
  try {
    if (accept) {
      await authApi.UserAcceptInOrganization(userId, organizationId);
    } else {
      await authApi.UserRefuseInOrganization(userId, organizationId);
    }
    return {};
  } catch (error) {
    return { error };
  }
};

export const connectAs = async ({ email, password, targetUser }) => {
  try {
    const response = await authApi.ConnectAs(email, password, targetUser);
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  } catch (error) {
    return { error };
  }
};

export const resendConfirmationEmail = async ({ username }) => {
  try {
    await authApi.UserSignUpConfirmationMail(username);
    return {};
  } catch (error) {
    return { error };
  }
};

export const listTargetMarketsPermissions = async ({ userId }) => {
  try {
    const response = await authApi.ListTargetMarketsPermissions(userId);
    const data = get(response, 'data.data') || {};
    return {
      targetMarketIds: data.target_market_ids || [],
    };
  } catch (error) {
    return { error };
  }
};

export const getUserOrganizationPick = async ({
  username,
  password,
  logAs,
}: {
  username: string;
  password: string;
  logAs?: string;
}) => {
  try {
    const response = await authApi.userOrganizationPick(
      username,
      password,
      logAs
    );
    return {
      organizationPickToken: response.data.organization_pick_token,
      organizations: response.data.organizations,
    };
  } catch (getUserOrganizationPickError) {
    return { getUserOrganizationPickError };
  }
};

export const loginWithOrganizationPickToken = async ({
  organizationId,
  organizationPickToken,
}) => {
  try {
    const response = await authApi.userLoginFromOrganizationPickToken(
      organizationId,
      organizationPickToken
    );
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  } catch (error) {
    return { error };
  }
};

export const fetchAllowedUserLanguages = async (
  admin: boolean = false
): Promise<{ value: string; label: string }[]> => {
  const { referentials, error } = await fetchReferentials('userlanguages', {
    immutable: false,
  });
  if (error) {
    return [];
  }
  let languages = map(
    (e) => ({ value: e.code, label: e.label }),
    referentials || []
  );
  if (admin) {
    languages = [
      ...languages,
      {
        value: 'noop',
        label: i18n.t(
          'frontproductstream.auth_api.user_languages.no_tranlsations',
          { defaultValue: 'No translations' }
        ),
      },
    ];
  }
  return languages;
};
