import i18n from 'utils/i18n';

export const prelaunch = {
  name: i18n.t('frontproductstream.constants.lifecycle_filter.prelaunch', {
    defaultValue: 'Prelaunch',
  }),
  id: 0,
  icon: 'mdi mdi-star',
};
export const purchasable = {
  name: i18n.t('frontproductstream.constants.lifecycle_filter.purchasable', {
    defaultValue: 'Purchasable',
  }),
  id: 1,
  icon: 'mdi mdi-cart',
};
export const notPurchasable = {
  name: i18n.t(
    'frontproductstream.constants.lifecycle_filter.not_purchasable',
    {
      defaultValue: 'Not Purchasable',
    }
  ),
  id: 2,
  icon: 'mdi mdi-close-octagon',
};
export const archived = {
  name: i18n.t('frontproductstream.constants.lifecycle_filter.archived', {
    defaultValue: 'Archived',
  }),
  id: 3,
  icon: 'mdi mdi-eye-off',
};

export const LifeCycleMap = {
  prelaunch,
  purchasable,
  notPurchasable,
  archived,
};

export const LifeCycleMapWithoutArchived = {
  prelaunch,
  purchasable,
  notPurchasable,
};

export const LifeCycleIdsWithoutArchived = Object.values(
  LifeCycleMapWithoutArchived
).map((l) => l.id);
