import {
  STATUS_BAR_ARCHIVED,
  STATUS_BAR_DANGER,
  STATUS_BAR_DEFAULT,
  STATUS_BAR_PENDING,
  STATUS_BAR_SUCCESS,
  STATUS_BAR_UPDATED,
} from 'components/ui/status-bar';
import i18n from 'utils/i18n';

export const MODULE_NAME = 'sharing-unit-tariffs';

export const FETCH_DISPLAY_GROUPS_SUCCESS = `${MODULE_NAME}/FETCH_DISPLAY_GROUPS_SUCCESS`;
export const FETCH_DISPLAY_GROUPS_FAILURE = `${MODULE_NAME}/FETCH_DISPLAY_GROUPS_FAILURE`;

export const FETCH_TEMPLATE = `${MODULE_NAME}/FETCH_TEMPLATE`;
export const FETCH_TEMPLATE_SUCCESS = `${MODULE_NAME}/FETCH_TEMPLATE_SUCCESS`;
export const FETCH_TEMPLATE_FAILURE = `${MODULE_NAME}/FETCH_TEMPLATE_FAILURE`;

export const SELECT_RECIPIENT = `${MODULE_NAME}/SELECT_RECIPIENT`;

export const CLEAR_STORE = `${MODULE_NAME}/CLEAR_STORE`;
export const SAVE = `${MODULE_NAME}/SAVE`;
export const SAVE_SUCCESS = `${MODULE_NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${MODULE_NAME}/SAVE_FAILURE`;
export const VALIDATE = `${MODULE_NAME}/VALIDATE`;
export const VALIDATE_SUCCESS = `${MODULE_NAME}/VALIDATE_SUCCESS`;
export const VALIDATE_FAILURE = `${MODULE_NAME}/VALIDATE_FAILURE`;

// Publication
export const OPEN_PUBLISH_MODAL = `${MODULE_NAME}/OPEN_PUBLISH_MODAL`;
export const CLOSE_PUBLISH_MODAL = `${MODULE_NAME}/CLOSE_PUBLISH_MODAL`;
export const PUBLISH = `${MODULE_NAME}/PUBLISH`;
export const PUBLISH_SUCCESS = `${MODULE_NAME}/PUBLISH_SUCCESS`;
export const PUBLISH_FAILURE = `${MODULE_NAME}/PUBLISH_FAILURE`;

export const DELETE = `${MODULE_NAME}/DELETE`;
export const DELETE_SUCCESS = `${MODULE_NAME}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${MODULE_NAME}/DELETE_FAILURE`;

export const OPEN_DUPLICATE_MODAL = `${MODULE_NAME}/OPEN_DUPLICATE_MODAL`;
export const CLOSE_DUPLICATE_MODAL = `${MODULE_NAME}/CLOSE_DUPLICATE_MODAL`;
export const DUPLICATE = `${MODULE_NAME}/DUPLICATE`;
export const DUPLICATE_SUCCESS = `${MODULE_NAME}/DUPLICATE_SUCCESS`;
export const DUPLICATE_FAILURE = `${MODULE_NAME}/DUPLICATE_FAILURE`;
export const ARCHIVE = `${MODULE_NAME}/ARCHIVE`;

// Products
export const FETCH_PRODUCTS = `${MODULE_NAME}/FETCH_PRODUCTS`;
export const CANCEL_FETCH_PRODUCTS = `${MODULE_NAME}/CANCEL_FETCH_PRODUCTS`;
export const CANCEL_PRODUCTS_POLLING = `${MODULE_NAME}/CANCEL_PRODUCTS_POLLING`;
export const PRODUCTS_POLLING_FINISHED = `${MODULE_NAME}/PRODUCTS_POLLING_FINISHED`;
export const POLL_PRODUCTS = `${MODULE_NAME}/POLL_PRODUCTS`;
export const FETCH_PRODUCTS_SUCCESS = `${MODULE_NAME}/FETCH_PRODUCTS_SUCCESS`;
export const UPDATE_CURRENT_PRODUCTS_PAGE = `${MODULE_NAME}/UPDATE_CURRENT_PRODUCTS_PAGE`;
export const UPDATE_SEARCH = `${MODULE_NAME}/UPDATE_SEARCH`;
export const ERROR_SEARCHING_PRODUCT = `${MODULE_NAME}/ERROR_SEARCHING_PRODUCT`;
export const UPDATE_FILTER_IN_ERROR = `${MODULE_NAME}/UPDATE_FILTER_IN_ERROR`;
export const DELETE_SHARING_UNIT = `${MODULE_NAME}/DELETE_SHARING_UNIT`;
export const RELOAD_SHARING_UNIT = `${MODULE_NAME}/RELOAD_SHARING_UNIT`;

// Add product
export const ADD_PRODUCT_SUCCESS = `${MODULE_NAME}/ADD_PRODUCT_SUCCESS`;
export const HIERARCHIES_RELOADED = `${MODULE_NAME}/HIERARCHIES_RELOADED`;

// List
export const FILTERS_CONFIG_KEY = 'sharingUnitTemplatesFiltersConfig';
export const UPDATE_FILTER_SEARCH = `${MODULE_NAME}/UPDATE_FILTER_SEARCH`;
export const FETCH_TEMPLATES = `${MODULE_NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_SUCCESS = `${MODULE_NAME}/FETCH_TEMPLATES_SUCCESS`;
export const FETCH_TEMPLATES_FAILURE = `${MODULE_NAME}/FETCH_TEMPLATES_FAILURE`;
export const COLLAPSE_FILTERS = `${MODULE_NAME}/COLLAPSE_FILTERS`;
export const UPDATE_SELECTED_FILTERS = `${MODULE_NAME}/UPDATE_SELECTED_FILTERS`;
export const UPDATE_FILTER_PAGE = `${MODULE_NAME}/UPDATE_FILTER_PAGE`;
export const UPDATE_CURRENT_PAGE = `${MODULE_NAME}/UPDATE_CURRENT_PAGE`;
export const SELECT_TARIFFS_IN_LIST = `${MODULE_NAME}/SELECT_TARIFFS_IN_LIST`;
export const UNSELECT_TARIFFS_IN_LIST = `${MODULE_NAME}/UNSELECT_TARIFFS_IN_LIST`;
export const SEARCH_LIST = `${MODULE_NAME}/SEARCH_LIST`;

// Export
export const EXPORT = `${MODULE_NAME}/EXPORT`;
export const EXPORT_WAIT = `${MODULE_NAME}/EXPORT_WAIT`;
export const EXPORT_SUCCESS = `${MODULE_NAME}/EXPORT_SUCCESS`;
export const EXPORT_FAILURE = `${MODULE_NAME}/EXPORT_FAILURE`;
export const EXPORT_BULK = `${MODULE_NAME}/EXPORT_BULK`;
export const EXPORT_BULK_PROGRESS = `${MODULE_NAME}/EXPORT_BULK_PROGRESS`;
export const EXPORT_BULK_FINISHED = `${MODULE_NAME}/EXPORT_BULK_FINISHED`;
export const EXPORT_CANCEL = `${MODULE_NAME}/EXPORT_CANCEL`;
export const EXPORT_FORMAT = `${MODULE_NAME}/EXPORT_FORMAT`;
export const EXPORT_BULK_TARIFF_ONE_FILE = `${MODULE_NAME}/EXPORT_BULK_TARIFF_ONE_FILE`;

// Reject
export const OPEN_REJECT_MODAL = `${MODULE_NAME}/OPEN_REJECT_MODAL`;
export const CLOSE_REJECT_MODAL = `${MODULE_NAME}/CLOSE_REJECT_MODAL`;
export const REJECT = `${MODULE_NAME}/REJECT`;
export const REJECT_SUCCESS = `${MODULE_NAME}/REJECT_SUCCESS`;
export const REJECT_FAILURE = `${MODULE_NAME}/REJECT_FAILURE`;

// Accept
export const ACCEPT = `${MODULE_NAME}/ACCEPT`;
export const ACCEPT_SUCCESS = `${MODULE_NAME}/ACCEPT_SUCCESS`;
export const ACCEPT_FAILURE = `${MODULE_NAME}/ACCEPT_FAILURE`;

// Publication report
export const FETCH_PUBLICATION_RESULTS = `${MODULE_NAME}/FETCH_PUBLICATION_RESULTS`;
export const FETCH_PUBLICATION_RESULTS_SUCCESS = `${MODULE_NAME}/FETCH_PUBLICATION_RESULTS_SUCCESS_SUCCESS`;
export const FETCH_PUBLICATION_RESULTS_FAILURE = `${MODULE_NAME}/FETCH_PUBLICATION_RESULTS_SUCCESS_FAILURE`;

// Statuses
export const Statuses = {
  DRAFT: {
    id: 'DRAFT',
    name: i18n.t(
      'frontproductstream.sharingunit_tariff_constant.status_draft.name',
      { defaultValue: 'Draft' }
    ),
    statusBarType: STATUS_BAR_DEFAULT,
    icon: 'file',
  },
  PUBLISHED: {
    id: 'PUBLISHED',
    name: i18n.t(
      'frontproductstream.sharingunit_tariff_constant.status_published.name',
      { defaultValue: 'Published' }
    ),
    statusBarType: STATUS_BAR_PENDING,
    icon: 'import',
  },
  REPUBLISHED: {
    id: 'REPUBLISHED',
    name: i18n.t(
      'frontproductstream.sharingunit_tariff_constant.status_updated.name',
      { defaultValue: 'Updated' }
    ),
    statusBarType: STATUS_BAR_UPDATED,
    icon: 'cached',
  },
  ACCEPTED: {
    id: 'ACCEPTED',
    name: i18n.t(
      'frontproductstream.sharingunit_tariff_constant.status_accepted.name',
      { defaultValue: 'Accepted' }
    ),
    statusBarType: STATUS_BAR_SUCCESS,
    icon: 'check-circle-outline',
  },
  REJECTED: {
    id: 'REJECTED',
    name: i18n.t(
      'frontproductstream.sharingunit_tariff_constant.status_rejected.name',
      { defaultValue: 'Rejected' }
    ),
    statusBarType: STATUS_BAR_DANGER,
    icon: 'block-helper',
  },
  ARCHIVED: {
    id: 'ARCHIVED',
    name: i18n.t(
      'frontproductstream.sharingunit_tariff_constant.status_archived.name',
      { defaultValue: 'Archived' }
    ),
    statusBarType: STATUS_BAR_ARCHIVED,
    icon: 'archive',
  },
};

// Type
export const Types = {
  TARIFF: {
    id: 0,
    name: i18n.t(
      'frontproductstream.sharingunit_tariff_constant.type_tariff.name',
      { defaultValue: 'Tariff' }
    ),
  },
  TEMPLATE: {
    id: 1,
    name: i18n.t(
      'frontproductstream.sharingunit_tariff_constant.type_template.name',
      { defaultValue: 'Template' }
    ),
  },
};

// error
export const TEMPLATE_LIST_PRODUCT_FILTER_TO_BIG =
  'TEMPLATE_LIST_PRODUCT_FILTER_TO_BIG';
