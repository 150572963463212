import { call, put, select, takeEvery } from 'redux-saga/effects';

import { TypePackagingLabels } from 'constants/typePackaging';
import {
  selectHasFlatHierarchies,
  selectHasLogisticalHierarchiesAdvancedPrivateFields,
} from 'modules/feature-flag/selectors';
import { applyRulesSaga } from 'modules/validation';
import { selectContentOwnerId } from 'reducers/productVersion';
import displayGroupApi from 'resources/displayGroupApi';
import { logError } from 'utils/logging';
import { takeFirst } from 'utils/saga';

import {
  LOAD_DISPLAY_GROUPS,
  LOGISTICAL_HIERARCHIES_DELETE_UNIT,
  RECEIVE_DISPLAY_GROUPS,
} from './constants';
import { selectDisplayGroups } from './selectors';

export default function* logisticalHierarchiesSaga() {
  yield takeFirst(LOAD_DISPLAY_GROUPS, loadDisplayGroups);
  yield takeEvery(LOGISTICAL_HIERARCHIES_DELETE_UNIT, triggerCheck);
}
function* triggerCheck() {
  yield call(applyRulesSaga);
}
function* loadDisplayGroups() {
  const prevAllDisplayGroups = yield select(selectDisplayGroups);
  const contentOwnerId = yield select(selectContentOwnerId);
  const hasFlatHierarchies = yield select(selectHasFlatHierarchies);
  const hasLogisticalHierarchyFieldEdition = yield select(
    selectHasLogisticalHierarchiesAdvancedPrivateFields
  );
  if (Object.keys(prevAllDisplayGroups).length > 0) {
    return;
  }
  try {
    const allDisplayGroups = {};
    for (const typePackagingId of [
      TypePackagingLabels.PACK.id,
      TypePackagingLabels.CASE.id,
      TypePackagingLabels.PALLET.id,
    ]) {
      const queries = {
        typePackagingId,
        isConsumerUnit: false,
        isDisplayUnit: false,
        contentOwnerId,
        no_private_fields:
          hasFlatHierarchies && !hasLogisticalHierarchyFieldEdition,
      };
      const { data: displayGroups } = yield call(
        [displayGroupApi, displayGroupApi.DisplayGroupList],
        queries,
        true
      );
      allDisplayGroups[typePackagingId] = displayGroups;
    }
    yield put({
      type: RECEIVE_DISPLAY_GROUPS,
      displayGroups: allDisplayGroups,
    });
  } catch (e) {
    logError(e);
  }
}
