import { put } from 'redux-saga/effects';

import rfpApi from 'resources/rfpApi';
import { logError } from 'utils/logging';
import { takeLatestSafe } from 'utils/saga';

import { receiveHasRfpModule } from './actions';
import { FETCH_RFP_MODULE_AVAILABLITY } from './constants';

function* fetchRfpModuleAvailablity({ payload: { organizationId } }) {
  try {
    const {
      data: { data },
    } = yield rfpApi.post('/rfp/v2/event/list', {
      organization_id: organizationId,
      limit: 1,
    });
    yield put(receiveHasRfpModule(data.length > 0));
  } catch (error) {
    logError(error);
  }
}

export function* UserInfoSaga() {
  yield takeLatestSafe(
    [FETCH_RFP_MODULE_AVAILABLITY],
    fetchRfpModuleAvailablity
  );
}
