import contributionApi from 'resources/contributionApi';
import { Diff } from 'types/diff';

import { DataOpsPatchOrNewPatch } from './types';

export async function fetchPatchesDiff(
  productKeyId: number,
  patches: DataOpsPatchOrNewPatch[]
) {
  const response: {
    data: {
      data: {
        [fieldname: string]: {
          diff_old: Diff[];
          diff_new: Diff[];
        };
      };
      errors: any[];
    };
  } = await contributionApi.post(`/contribution/v2/patch/diff`, {
    product_key_id: productKeyId,
    patches,
    by_patch_id: false,
  });
  return response.data.data;
}
