import { Set, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  ACCEPTED_ALL_SUGGESTIONS,
  ACCEPTED_SUGGESTION,
  ACCEPTING_ALL_SUGGESTIONS,
  ACCEPTING_SUGGESTION,
  DISMISSED_ALL_SUGGESTIONS,
  DISMISSED_SUGGESTION,
  DISMISSING_ALL_SUGGESTIONS,
  DISMISSING_SUGGESTION,
  SUGGESTIONS_DIFFS_COMPUTED,
} from './constants';

const initialState = fromJS({
  diffs: [],
  accepting: Set(),
  dismissing: Set(),
  updatingFields: Set(),
  acceptingAll: false,
  dismissingAll: false,
});

export default createReducer(initialState, {
  [ACCEPTING_SUGGESTION]: (state, { model, ruleId }) =>
    state.withMutations((s) => {
      s.update('accepting', (accepting) => accepting.add(ruleId));
      s.update('updatingFields', (updatingFields) => updatingFields.add(model));
    }),
  [ACCEPTED_SUGGESTION]: (state, { model, ruleId }) =>
    state.withMutations((s) => {
      s.update('accepting', (accepting) => accepting.delete(ruleId));
      s.update('updatingFields', (updatingFields) =>
        updatingFields.delete(model)
      );
    }),

  [DISMISSING_SUGGESTION]: (state, { model, ruleId }) =>
    state.withMutations((s) => {
      s.update('dismissing', (dismissing) => dismissing.add(ruleId));
      s.update('updatingFields', (updatingFields) => updatingFields.add(model));
    }),
  [DISMISSED_SUGGESTION]: (state, { model, ruleId }) =>
    state.withMutations((s) => {
      s.update('dismissing', (dismissing) => dismissing.delete(ruleId));
      s.update('updatingFields', (updatingFields) =>
        updatingFields.delete(model)
      );
    }),

  [ACCEPTING_ALL_SUGGESTIONS]: (state) => state.set('acceptingAll', true),
  [ACCEPTED_ALL_SUGGESTIONS]: (state) => state.set('acceptingAll', false),

  [DISMISSING_ALL_SUGGESTIONS]: (state) => state.set('dismissingAll', true),
  [DISMISSED_ALL_SUGGESTIONS]: (state) => state.set('dismissingAll', false),

  [SUGGESTIONS_DIFFS_COMPUTED]: (state, { diffs }) => state.set('diffs', diffs),
});
