import { get } from 'lodash';
import { call, put } from 'redux-saga/effects';

import { fetchReferentials } from 'resources/referentialApi';

import { receiveExportFileFormats } from '../actions';

export function* fetchExportFileFormatsSaga() {
  const { referentials, error } = yield call(
    fetchReferentials,
    'exportfileformats'
  );
  if (error) {
    throw error;
  }
  if (referentials) {
    const formats = referentials.toJS().map((ref) => {
      const format = { label: ref.label, value: ref.code };
      const maxTotalProducts = get(ref, ['data', 'maxTotalProducts']);
      const extraOptions = get(ref, ['data', 'extraOptions']);
      const flag = get(ref, ['data', 'flag']);
      if (maxTotalProducts) {
        format.maxTotalProducts = maxTotalProducts;
      }
      if (extraOptions) {
        format.extraOptions = extraOptions;
      }
      if (flag) {
        format.flag = flag;
      }
      return format;
    });
    yield put(receiveExportFileFormats(formats));
  }
}
