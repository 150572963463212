import { isManufacturer, isPrivateLabel, isRetailer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_DATA_OPS,
  RELEASE_DATA_OPS_MANUFACTURERS,
} from 'modules/feature-flag/constants';

export const patchFilter = {
  key: 'patch_summary.has_patch',
  query: 'patch-filter',

  isAvailable: ({ user } = {}) =>
    (isRetailer(user) && hasFeature(user, FEATURE_DATA_OPS)) ||
    ((isManufacturer(user) || isPrivateLabel(user)) &&
      hasFeature(user, RELEASE_DATA_OPS_MANUFACTURERS)),
};
