import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { hasOnlyExclusiveProducts } from 'core/api/organization-settings';
import {
  getOrganizationSettings,
  hasAnyProductUpdatePermission,
  isManufacturer,
} from 'core/api/user';
import BulkSetAsPublic from 'modules/bulk-set-as-public';
import { show } from 'modules/bulk-set-as-public/actions';
import i18n from 'utils/i18n';

const mapDispatchToProps = {
  show,
};

export class SetAsPublicAction extends PureComponent {
  static propTypes = {
    show: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    isManufacturer(user) &&
    hasAnyProductUpdatePermission(user) &&
    !hasOnlyExclusiveProducts(getOrganizationSettings(user));

  getLabel(nb) {
    return i18n.t('Make {{count}} product visible on SupplierXM network', {
      count: nb,
    });
  }

  setAsPublic = () => {
    const { selectedMap, productMap, disabled } = this.props;
    if (disabled) {
      return;
    }
    const checkedProducts = productMap.filter(
      (pv, i) => selectedMap.get(i) === true
    );

    this.props.show(checkedProducts);
  };

  render() {
    const label = this.getLabel(this.props.selectedMap.size);

    return (
      <>
        <div className="ActionOption" onClick={this.setAsPublic}>
          {label}
        </div>
        <BulkSetAsPublic />
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(SetAsPublicAction);
