import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { ASSIGN } from 'constants/assignation';
import { TargetProductStatusNotAssigned } from 'constants/targetProductStatus';
import { hasAssignation } from 'core/api/organization-settings';
import { getTargetProductStatus } from 'core/api/retailerproductversion';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { openTargetRequestModal } from 'modules/target-request-modal/actions';
import i18n from 'utils/i18n';

import '../index.scss';

const mapDispatchToProps = {
  openTargetRequestModal,
};

class AssignAction extends PureComponent {
  static propTypes = {
    openTargetRequestModal: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
    filtersQueryMap: PropTypes.object.isRequired,
  };

  static shouldBeDisplayed = ({ user }) =>
    isRetailer(user) && hasAssignation(getOrganizationSettings(user));

  static isEnabled = ({ productMap, selectedMap }) => {
    // only if selection contains some product with status in TargetProductStatusNotAssigned
    const selectedProducts = selectedMap?.filter((v) => v === true);
    if (!selectedProducts.size) {
      return false;
    }
    return selectedProducts.every(
      (v, pvId) =>
        TargetProductStatusNotAssigned.id ===
        getTargetProductStatus(productMap.get(pvId))
    );
  };

  constructor(props) {
    super(props);
    this.assign = this.assign.bind(this);
  }

  getLabel(nb) {
    return i18n.t('Assign {{count}} product(s)', { count: nb });
  }

  getAssignableProducts() {
    const { selectedMap, productMap } = this.props;
    return productMap.filter(
      (pv, i) =>
        selectedMap.get(i) === true &&
        TargetProductStatusNotAssigned.id === getTargetProductStatus(pv)
    );
  }

  assign() {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    const products = this.getAssignableProducts();

    this.props.openTargetRequestModal({
      title: i18n.t('Assigning {{ count }} product(s)', {
        count: products.size,
      }),
      products,
      chaseType: ASSIGN,
    });
  }

  render() {
    const assignableProducts = this.getAssignableProducts();
    const label = this.getLabel(assignableProducts.size);
    return (
      <div className="ActionOption" onClick={this.assign}>
        {label}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(AssignAction);
