import { Map } from 'immutable';
import { once } from 'lodash';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { ALL_COMPLETED_STAGE_GATE_ID } from 'constants/requiredFieldsCategory';
import { requiredFieldsCategoryFilter } from 'core/modules/list/constants/filters/required-fields-category';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import i18n from 'utils/i18n';

import CollapsibleAdvancedFilter from '../advanced';

export class RequiredFieldsCategoryFilter extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    filterConfig: ImmutablePropTypes.map.isRequired,
    filterQueryValues: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onSort: PropTypes.func,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    filterConfig: Map(),
    filterQueryValues: [],
  };

  filterKey = requiredFieldsCategoryFilter.key;

  selectOptions = memoize((aggregations) => aggregations.valueSeq().toList());

  updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: this.props.filterQueryValues,
      filterList: this.selectOptions(this.props.aggregations),
      filterKey: this.filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) =>
        `${this.filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => [filter.get('id')],
    }).then((filtersFromQuery) => {
      this.props.onChange(filtersFromQuery, true);
    });
  });

  filterLabel = i18n.t(
    'frontproductstream.core.list_filter_required_fields_category.label',
    { defaultValue: 'Required fields category' }
  );

  componentDidMount() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  componentDidUpdate() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  shouldUpdateSelectionFromQuery = () => {
    const { aggregations, selectedFilterMap } = this.props;
    return !aggregations.isEmpty() && selectedFilterMap.isEmpty();
  };

  processAggregations = (aggregations) =>
    // Translate harcoded stage gates
    aggregations.map((aggregation) =>
      aggregation.get('id') === ALL_COMPLETED_STAGE_GATE_ID
        ? aggregation.set('name', aggregation.get('name'))
        : aggregation
    );

  render() {
    const { aggregations } = this.props;
    const processedAggregations = this.processAggregations(aggregations);
    return (
      <CollapsibleAdvancedFilter
        id="list-filter-required-fields-category"
        filterKey={this.filterKey}
        filterLabel={this.filterLabel}
        filters={this.selectOptions(processedAggregations)}
        selectedFilterMap={this.props.selectedFilterMap}
        aggregations={processedAggregations}
        searchPlaceholder={i18n.t(
          'frontproductstream.core.list_filter_required_fields_category.search_placeholder',
          { defaultValue: 'Search for a required field category' }
        )}
        searchQuery={this.props.filterConfig.get('query')}
        page={this.props.filterConfig.get('page')}
        collapsed={this.props.filterConfig.get('collapsed')}
        onChange={this.props.onChange}
        onCollapse={this.props.onCollapse}
        onFilter={this.props.onFilter}
        onChangePage={this.props.onChangePage}
        onSort={this.props.onSort}
        withPagination
      />
    );
  }
}
