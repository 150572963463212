import { Action } from 'redux';

import { ActionWithPayload } from 'types';

export const BULK_ACTION_SHOW = 'BULK_ACTION_SHOW';
export const BULK_ACTION_RESET = 'BULK_ACTION_RESET';
export const BULK_ACTION_IN_PROGRESS = 'BULK_ACTION_IN_PROGRESS';
export const BULK_ACTION_DONE = 'BULK_ACTION_DONE';

export type BulkActionInProgressActionPayload = {
  count: number;
  entityId: string | number;
  status: {
    code: 'success' | 'danger';
    message: string;
  };
};

export type BulkActionShowAction = Action<typeof BULK_ACTION_SHOW>;
export type BulkActionResetAction = Action<typeof BULK_ACTION_RESET>;
export type BulkActionInProgressAction<
  Payload extends BulkActionInProgressActionPayload
> = Action<typeof BULK_ACTION_IN_PROGRESS> & ActionWithPayload<Payload>;
export type BulkActionDoneAction = Action<typeof BULK_ACTION_DONE>;
