import { Map, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useMemo } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import SimpleFilter from 'core/modules/list/components/filter/simple';
import { patchFilter } from 'core/modules/list/constants/filters/patch';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import i18n from 'utils/i18n';

function PatchFilterComponent({
  collapsed = false,
  aggregations,
  filterQueryValues = [],
  onChange,
  onCollapse,
  selectedFilterMap = Map(),
}) {
  const filterKey = patchFilter.key;
  const filterLabel = i18n.t(
    'frontproductstream.core.list_filter_patch.label',
    { defaultValue: 'Patch' }
  );

  const onLocalCollapse = useCallback(
    (isCollapsed) => {
      onCollapse(filterKey, isCollapsed);
    },
    [onCollapse, filterKey]
  );

  const filterList = useMemo(
    () =>
      fromJS([
        {
          id: 'true',
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_patch_option',
            { defaultValue: 'haspatch' }
          ),
        },
        {
          id: 'false',
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_no_patch_option',
            { defaultValue: 'hasnopatch' }
          ),
        },
      ]),
    []
  );

  useEffect(() => {
    buildFiltersFromQuery({
      filterQueryValues,
      filterList,
      filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) => `${filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => filter,
    }).then((filtersFromQuery) => {
      onChange(
        filtersFromQuery.map((filter) => ({
          ...filter,
        })),
        true
      );
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SimpleFilter
      id="list-filter-patchFilter"
      filterList={filterList}
      filterKey={filterKey}
      filterLabel={filterLabel}
      selectedFilterMap={selectedFilterMap}
      aggregations={aggregations.get(filterKey)}
      collapsed={collapsed}
      onChange={onChange}
      onCollapse={onLocalCollapse}
    />
  );
}

PatchFilterComponent.propTypes = {
  selectedFilterMap: ImmutablePropTypes.map,
  aggregations: ImmutablePropTypes.map,
  collapsed: PropTypes.bool,
  filterQueryValues: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
};

export const PatchFilter = memo(PatchFilterComponent);
