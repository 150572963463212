import classNames from 'classnames';
import { List, Map } from 'immutable';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';

import { Button, SwitchButton } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';
import {
  OrganizationGroupRelationStatus,
  OrganizationSettingSpecificExportType,
} from '@alkem/sdk-dashboard';

import { ImageIconExcel } from 'components/ui/image/icon';
import { hasAllowPush, hasListing } from 'core/api/organization-settings';
import CatalogLink from 'modules/catalog/product/components/catalog-link';
import CorrectLink from 'modules/view-as/components/correct-link';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { isSpecificExportByType } from 'utils/organization/recipient';
import { track } from 'utils/tracking';

import './recipient-row.scss';

class RecipientRow extends PureComponent {
  static propTypes = {
    productId: PropTypes.number.isRequired,

    isAvailable: PropTypes.bool,
    isAdded: PropTypes.bool,
    isPublished: PropTypes.bool.isRequired,
    isBusy: PropTypes.bool,
    hasSpinner: PropTypes.bool,

    canUpdateSharingUnit: PropTypes.bool.isRequired,

    recipient: ImmutablePropTypes.contains({
      id: PropTypes.number.isRequired,
      nameLegal: PropTypes.string.isRequired,
      hasProductInActiveRange: PropTypes.bool.isRequired,
      isSynced: PropTypes.bool.isRequired,
      toggled: PropTypes.bool.isRequired,
      canBeToggled: PropTypes.bool.isRequired,
      requiresManualToggle: PropTypes.bool.isRequired,
      canBeUnshared: PropTypes.bool.isRequired,
      settings: PropTypes.object.isRequired,
    }).isRequired,

    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    onUnshare: PropTypes.func,

    validation: ImmutablePropTypes.map,
    groupRelationStatus: PropTypes.number,
    isBigManufacturer: PropTypes.bool,
    subscriptions: PropTypes.array,
  };

  static defaultProps = {
    onAdd: null,
    onRemove: null,
    isBusy: false,
    validation: Map(),
    isAvailable: false,
    hasSpinner: false,
    isAdded: false,
  };

  state = {
    accessRequested: false,
  };

  onAdd = () => {
    const { onAdd, recipient } = this.props;
    if (onAdd) {
      onAdd(recipient);
    }
  };

  onRemove = () => {
    const { onRemove, recipient } = this.props;
    if (onRemove) {
      onRemove(recipient);
    }
  };

  onUnshare = () => {
    this.props.onUnshare(this.props.recipient);
  };

  requestAccess = () => {
    const { recipient } = this.props;
    const { accessRequested } = this.state;
    if (!accessRequested) {
      track({
        category: 'billing',
        action: 'bigmanufacturer_recipient_access_request',
        recipient_id: recipient.get('id'),
        recipient_name: recipient.get('nameLegal'),
      });
      this.setState({
        accessRequested: true,
      });
    }
  };

  computeActionDisabled(isRecipientActive) {
    const {
      onAdd,
      onRemove,
      isAvailable,
      isBusy,
      canUpdateSharingUnit,
      recipient,
    } = this.props;
    const action = isRecipientActive ? onRemove : onAdd;

    // Never allow users without sharing unit update permission to activate listing recipients.
    if (!canUpdateSharingUnit && hasListing(recipient.get('settings'))) {
      return true;
    }

    return !isAvailable || !action || isBusy;
  }

  formatSwitchStatus(isRecipientActive, hasSpinner) {
    const { recipient, isPublished, validation } = this.props;
    const blockingIssues = validation.get('failedBlocking') || 0;

    let label;
    if (isRecipientActive && !hasSpinner) {
      if (blockingIssues > 0) {
        label = i18n.t(
          'frontproductstream.publication_summary.recipient_status_blocked.text',
          { defaultValue: 'Blocked' }
        );
      } else if (isPublished) {
        label = recipient.get('isSynced')
          ? i18n.t(
              'frontproductstream.publication_summary.recipient_status_shared.text',
              { defaultValue: 'Shared' }
            )
          : i18n.t(
              'frontproductstream.publication_summary.recipient_status_pending_share.text',
              { defaultValue: 'Pending share' }
            );
      } else {
        label = i18n.t(
          'frontproductstream.publication_summary.recipient_status_added.text',
          { defaultValue: 'Recipient added' }
        );
      }
    } else if (
      isSpecificExportByType(
        recipient,
        OrganizationSettingSpecificExportType.XLSX
      )
    ) {
      label = i18n.t(
        'frontproductstream.publication_summary.recipient_status_to_add_ctx_excel.text',
        { defaultValue: 'Add recipient' }
      );
    } else if (
      hasListing(recipient.get('settings')) &&
      hasAllowPush(recipient.get('settings'))
    ) {
      label = isPublished
        ? i18n.t(
            'frontproductstream.publication_summary.recipient_status_to_share_ctx_listing.text',
            { defaultValue: 'Share to recipient' }
          )
        : i18n.t(
            'frontproductstream.publication_summary.recipient_status_to_add_ctx_listing.text',
            { defaultValue: 'Add recipient' }
          );
    } else {
      label = isPublished
        ? i18n.t(
            'frontproductstream.publication_summary.recipient_status_to_share_ctx_active_range.text',
            { defaultValue: 'Share to recipient' }
          )
        : i18n.t(
            'frontproductstream.publication_summary.recipient_status_to_add_ctx_active_range.text',
            { defaultValue: 'Add recipient' }
          );
    }

    return (
      <>
        {recipient.get('toggled') && !recipient.get('canBeToggled') && (
          <>
            <i
              className="mdi mdi-alert RecipientRow__Warnings"
              data-tip
              data-for={`no-longer-available-recipient-${recipient.get('id')}`}
            />
            <Tooltip
              id={`no-longer-available-recipient-${recipient.get('id')}`}
            >
              {i18n.t(
                'frontproductstream.publication_summary.recipient_not_requiring_product.help',
                {
                  defaultValue:
                    'This recipient is no longer requiring this product.',
                }
              )}
            </Tooltip>
          </>
        )}
        {label}
        {hasSpinner && <i className="mdi mdi-loading mdi-spin" />}
      </>
    );
  }

  renderMessage(isRecipientActive) {
    const { productId, isPublished, recipient, validation } = this.props;

    const issues = validation.get('failed') || 0;
    const blockingIssues = validation.get('failedBlocking') || 0;
    const failedChildrenGtins =
      validation.get('failedConsumerUnitGtins') || List();

    let message = null;
    if (
      recipient.get('toggled') &&
      (issues > 0 || failedChildrenGtins.size > 0)
    ) {
      message = (
        <span className="RecipientRow__Issues alk-flex alk-flex-center">
          {blockingIssues > 0 && (
            <>
              <i className="mdi mdi-alert" />
              <span>&nbsp;</span>
              <strong className="RecipientRow__blockedProduct">
                {i18n.t(
                  'frontproductstream.publication_summary.blocked_product.text',
                  { defaultValue: 'Blocked product:' }
                )}
              </strong>
              <span>&nbsp;</span>
            </>
          )}
          {issues > 0 && (
            <CorrectLink
              productIdOrReference={productId}
              targetOrganizationIds={[recipient.get('id')]}
            >
              {i18n.t(
                'frontproductstream.publication_summary.actions_to_perform.text',
                {
                  defaultValue: '{{count}} actions to perform',
                  count: issues,
                }
              )}
            </CorrectLink>
          )}
          {issues > 0 && failedChildrenGtins.size > 0 && (
            <i className="RecipientRow__bullet mdi mdi-circle" />
          )}
          {failedChildrenGtins.size > 0 && (
            <CatalogLink gtins={failedChildrenGtins}>
              {i18n.t(
                'frontproductstream.publication_summary.failed_gtins.text',
                {
                  defaultValue: '{{count}} invalid child items',
                  count: failedChildrenGtins.size,
                }
              )}
            </CatalogLink>
          )}
        </span>
      );
    } else if (
      recipient.get('hasProductInActiveRange') &&
      (!isPublished || !isRecipientActive)
    ) {
      message = (
        <strong className="RecipientRow__Sync">
          <i className="mdi mdi-alert" />{' '}
          {i18n.t(
            'frontproductstream.publication_summary.expecting_product.text',
            { defaultValue: 'Expecting product' }
          )}
        </strong>
      );
    } else if (
      recipient.get('hasVariantInActiveRange') &&
      (!isPublished || !isRecipientActive)
    ) {
      message = (
        <strong className="RecipientRow__Sync">
          <i className="mdi mdi-alert" />{' '}
          {i18n.t(
            'frontproductstream.publication_summary.expecting_variant.text',
            { defaultValue: 'Expecting at least one variant' }
          )}
        </strong>
      );
    }

    return message ? (
      <>
        <span>{message}</span>
        <i className="RecipientRow__bullet mdi mdi-circle" />
      </>
    ) : null;
  }

  renderSwitch(isRecipientActive, isActionDisabled) {
    const {
      isAvailable,
      hasSpinner,
      recipient,
      groupRelationStatus,
      isBigManufacturer,
      subscriptions,
    } = this.props;

    const { accessRequested } = this.state;
    if (!isAvailable) {
      return (
        <>
          <div
            className="alk-flex"
            data-tip
            data-for={`unavailable-recipient-${recipient.get('id')}`}
          >
            <span className="RecipientRow__switchStatus">
              {i18n.t(
                'frontproductstream.publication_summary.recipient_status_product_not_requested.text',
                { defaultValue: 'The recipient doesn’t request this product' }
              )}
            </span>
            <i
              className="RecipientRow__helpIconSwitch mdi mdi-help-circle"
              data-tip
              data-for={`unavailable-recipient-${recipient.get('id')}`}
            />
            <Tooltip
              id={`unavailable-recipient-${recipient.get('id')}`}
              place="left"
            >
              {i18n.t(
                'frontproductstream.publication_summary.recipient_status_product_not_requested.help',
                {
                  defaultValue:
                    "This recipient can only view products they requested. Since they didn't request this product, you can't activate it.",
                }
              )}
            </Tooltip>
            <SwitchButton onChange={noop} checked={false} disabled />
          </div>
        </>
      );
    }

    if (
      recipient.get('isPayingRecipient') &&
      ![
        OrganizationGroupRelationStatus.ACCEPTED,
        OrganizationGroupRelationStatus.DEACTIVATION_REQUESTED,
      ].includes(groupRelationStatus)
    ) {
      return (
        <>
          <span>
            {i18n.t(
              'frontproductstream.publication_summary.recipient_status_paying.text',
              { defaultValue: 'Paying recipient' }
            )}
          </span>
          {isBigManufacturer ? (
            <>
              <span>&nbsp;|&nbsp;</span>
              <Button
                onClick={this.requestAccess}
                content={
                  accessRequested
                    ? i18n.t(
                        'frontproductstream.publication_summary.publication_big_manufacturer_access_requested_link.text',
                        { defaultValue: 'Access requested' }
                      )
                    : i18n.t(
                        'frontproductstream.publication_summary.publication_big_manufacturer_request_access_link.text',
                        { defaultValue: 'Request access' }
                      )
                }
                disabled={accessRequested}
                link
              />
            </>
          ) : (
            subscriptions &&
            subscriptions.length > 0 && (
              <>
                <span>&nbsp;|&nbsp;</span>
                <Link to={routes.billingRecipientsAdmin}>
                  {i18n.t(
                    'frontproductstream.publication_summary.publication_billing_recipients_link.text',
                    { defaultValue: 'Learn more' }
                  )}
                </Link>
              </>
            )
          )}
        </>
      );
    }

    return (
      <>
        <span className="RecipientRow__switchStatus">
          {this.formatSwitchStatus(isRecipientActive, hasSpinner)}
        </span>
        <SwitchButton
          onChange={isRecipientActive ? this.onRemove : this.onAdd}
          checked={isRecipientActive}
          disabled={isActionDisabled}
        />
      </>
    );
  }

  renderUnshareButton() {
    const { onUnshare, recipient } = this.props;
    return onUnshare && recipient.get('canBeUnshared') ? (
      <>
        <Button onClick={this.onUnshare} link warning>
          {i18n.t('frontproductstream.publication_summary.unshare.button', {
            defaultValue: 'Unshare',
          })}
        </Button>
        <i className="RecipientRow__bullet mdi mdi-circle" />
      </>
    ) : null;
  }

  render() {
    const { recipient, isAvailable, isAdded, isBusy, hasSpinner } = this.props;

    const isRecipientActive =
      (recipient.get('toggled') || isAdded) && isAvailable;
    const isActionDisabled = this.computeActionDisabled(isRecipientActive);

    return (
      <div
        id={`publication-summary-recipient-${recipient.get('id')}`}
        className={classNames(
          'RecipientRow',
          !isAvailable && 'RecipientRow--unavailable'
        )}
        data-is-available={isAvailable}
        data-is-active={isRecipientActive}
        data-is-disabled={isActionDisabled}
        data-is-activating={isBusy || hasSpinner}
      >
        <div className="alk-flex alk-flex-center">
          <span>{recipient.get('nameLegal')}</span>
          {recipient.get('help') && (
            <>
              <i
                className="RecipientRow__helpIcon mdi mdi-help-circle"
                data-tip
                data-for={`recipient-tooltip-${recipient.get('id')}`}
              />
              <Tooltip
                place="right"
                id={`recipient-tooltip-${recipient.get('id')}`}
                hoverable
              >
                <span className="RecipientRow__help">
                  {recipient.get('help')}
                </span>
              </Tooltip>
            </>
          )}
          {isSpecificExportByType(
            recipient,
            OrganizationSettingSpecificExportType.XLSX
          ) && <ImageIconExcel className="RecipientRow__exportIcon" />}
        </div>
        <div className="alk-flex alk-flex-center">
          {this.renderUnshareButton()}
          {this.renderMessage(isRecipientActive)}
          {this.renderSwitch(isRecipientActive, isActionDisabled)}
        </div>
      </div>
    );
  }
}

export default RecipientRow;
