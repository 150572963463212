import { createAction, createActionWithPayload } from 'utils/redux';

import { AUTHORIZED, LOGOUT, NOT_AUTHORIZED, TRY_AUTHORIZATION } from './types';

export const tryAuthorization = createAction(TRY_AUTHORIZATION);
export const authorized = createAction(AUTHORIZED);
export const notAuthorized = createAction(NOT_AUTHORIZED);
export const logout = createActionWithPayload<
  { nextlocation: string } | undefined
>(LOGOUT);
