import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { REASSIGN } from 'constants/assignation';
import { TargetProductStatusPendingAssignation } from 'constants/targetProductStatus';
import { hasAssignation } from 'core/api/organization-settings';
import {
  getLastPendingAssignationEmails,
  getLastPendingAssignationOrganizationId,
  getTargetProductStatus,
} from 'core/api/retailerproductversion';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { openTargetRequestModal } from 'modules/target-request-modal/actions';
import i18n from 'utils/i18n';

import '../index.scss';

const mapDispatchToProps = {
  openTargetRequestModal,
};

class ReassignAction extends PureComponent {
  static propTypes = {
    openTargetRequestModal: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    isRetailer(user) && hasAssignation(getOrganizationSettings(user));

  static isEnabled = ({ productMap, selectedMap }) => {
    const pvs = productMap.filter((pv, i) => selectedMap.get(i) === true);
    if (
      !pvs.size ||
      !pvs.every(
        (pv) =>
          TargetProductStatusPendingAssignation.id ===
          getTargetProductStatus(pv)
      )
    ) {
      // all product must match status
      return false;
    }
    const pendingAssignationProductVersions = pvs;
    // every pendingAssignation products in selection must share assignation targets
    const manufacturerId = getLastPendingAssignationOrganizationId(
      pendingAssignationProductVersions.first()
    );
    const emails = getLastPendingAssignationEmails(
      pendingAssignationProductVersions.first()
    );
    return pendingAssignationProductVersions.every(
      (pv) =>
        getLastPendingAssignationEmails(pv).equals(emails) &&
        getLastPendingAssignationOrganizationId(pv) === manufacturerId
    );
  };

  constructor(props) {
    super(props);
    this.reassign = this.reassign.bind(this);
  }

  getLabel(nb) {
    return i18n.t('Re-send {{count}} assignation(s)', { count: nb });
  }

  getReassignableProducts() {
    const { selectedMap, productMap } = this.props;
    return productMap.filter(
      (pv, i) =>
        selectedMap.get(i) === true &&
        TargetProductStatusPendingAssignation.id === getTargetProductStatus(pv)
    );
  }

  reassign() {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    const products = this.getReassignableProducts();

    this.props.openTargetRequestModal({
      title: i18n.t('Reassigning {{count}} products', {
        count: products.size,
      }),
      products,
      chaseType: REASSIGN,
    });
  }

  render() {
    const reassignableProducts = this.getReassignableProducts();
    const label = this.getLabel(reassignableProducts.size);
    return (
      <div className="ActionOption" onClick={this.reassign}>
        {label}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(ReassignAction);
