import classNames from 'classnames';
import { func, node, string } from 'prop-types';

import './style.scss';

function SimpleModal({ children, className, size, onClose }) {
  return (
    <div
      className={classNames(
        'SimpleModal',
        size && `SimpleModal--${size}`,
        className
      )}
    >
      {children}
      {!!onClose && (
        <i className="SimpleModal__close mdi mdi-close" onClick={onClose} />
      )}
    </div>
  );
}

SimpleModal.propTypes = {
  children: node,
  className: string,
  size: string,
  onClose: func,
};

export default SimpleModal;
