import {
  RELEASE_REQUEST_ENGINE_INTERFACE,
  hasFeature,
} from 'modules/feature-flag';

import { isAdmin, isRetailer } from '../../core/api/user';
import { UserImmutable } from '../../types';
import { get } from '../../utils/immutable';

export const hasFeatureInformationRequestList = (user: UserImmutable) =>
  isRetailer(user) &&
  hasFeature(user, RELEASE_REQUEST_ENGINE_INTERFACE) &&
  isAdmin(user);

export const extractFilterValues = (selectedFilterMap, key) => {
  let data = [];
  const values = get(selectedFilterMap, key);
  if (values) {
    data = values.keySeq().toArray();
  }
  return data;
};
