import { fromJS } from 'immutable';
import { filter, get, map, set, update } from 'lodash/fp';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError, notificationSuccess } from 'actions/notification';
import { Types } from 'modules/sharing-unit-tariffs/constants';
import { RECEIVE_RULE_RESULTS } from 'modules/validation';
import { selectLocales, selectOrganizationId } from 'reducers/user/selectors';
import * as routes from 'routes';
import { push } from 'utils/history';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';
import { takeLatestSafe, withCatch } from 'utils/saga';

import {
  CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_SHARED,
  CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_UNIQ,
  CREATE_SHARING_UNIT_TEMPLATES,
  FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES,
  GET_SHARING_UNIT_TEMPLATES,
  LIST_RETAILERS,
  LIST_SHARING_UNIT_TEMPLATES,
  SAVE_SHARING_UNIT_TEMPLATES,
  SAVE_SHARING_UNIT_TEMPLATES_PRODUCT,
  SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
  TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_MODAL,
  VALIDATE_SHARING_UNIT_TEMPLATE,
  sharingUnitTemplatesFetchProduct as actionFetchProducts,
  checkSharingUnitTemplatesProductShared,
  checkSharingUnitTemplatesProductUniq,
  fetchDisplayGroupSharingUnitTemplatesDone,
  fetchSharingUnitTemplatesProductDone,
  fetchSharingUnitTemplatesProductError,
  getSharingUnitTemplatesDone,
  initSharingUnitTemplatesProduct,
  listRetailersDone,
  listSharingUnitTemplatesDone,
  loadSharingUnitTemplates,
  loadSharingUnitTemplatesFetchProduct,
  sharingUnitTemplateIsCreating,
  sharingUnitTemplatesFetchProductDone,
  startLoadSharingUnitTemplatesProduct,
  stopLoadSharingUnitTemplates,
  stopLoadSharingUnitTemplatesFetchProduct,
  stopLoadSharingUnitTemplatesProduct,
  toggleSharingUnitTemplatesProductModal,
  updateSharingUnitTemplatesProductErrors,
  validateSharingUnitTemplateDone,
} from './actions';
import {
  fetchDisplayGroups as apiFetchDisplayGroup,
  check_productshared,
  check_uniqproduct,
  create as createTemplates,
  fetchProducts,
  fetchSharingUnit,
  get as getTemplates,
  listRetailers,
  listSharingUnitTemplates,
  update as updateTemplates,
  upsert_product,
  validate,
} from './api';
import {
  DATE_FILTER_KEY,
  STATUS_FILTER_KEY,
  retailerFilter,
} from './components/views/list/filters/constants';
import {
  selectCurrentAttachedProduct,
  selectPagination,
  selectSearch,
  selectSelectedFilterMap,
  selectSelectedSUTProductsInErrorFilter,
  selectSelectedSUTProductsPagination,
  selectSelectedSUTProductsSearchQuery,
  selectSelectedSharingUnitTemplates,
} from './selectors';

const errorStrategy = {
  withNotification: true,
  stopLoadingOnCatch: true,
  errorMessage: (err: any) =>
    get(['data', 'message'], err) || get(['message'], err),
  stopLoadingEvent: stopLoadSharingUnitTemplates,
};

export function* fetchSharingUnitTemplatesSaga() {
  yield put(loadSharingUnitTemplates());
  const pagination = yield select(selectPagination);
  const search = yield select(selectSearch);

  const selectedFilterMap = yield select(selectSelectedFilterMap);
  const selectedRetailers = get(retailerFilter.key, selectedFilterMap);

  let retailersFilter: any[] = [];
  if (selectedRetailers) {
    retailersFilter = Object.keys(selectedRetailers);
  }
  const res = yield call(listSharingUnitTemplates, {
    pagination,
    filters: {
      retailer: retailersFilter,
      search,
      fromDate: get(`${DATE_FILTER_KEY}.from`, selectedFilterMap),
      toDate: get(`${DATE_FILTER_KEY}.to`, selectedFilterMap),
      statuses: get(STATUS_FILTER_KEY, selectedFilterMap),
    },
  });
  yield put(
    listSharingUnitTemplatesDone({
      data: res.data.data,
      totalResults: res.data.totalResults,
    })
  );
  yield put(stopLoadSharingUnitTemplates());
}

export function* fetchRetailers() {
  const res = yield call(listRetailers);
  yield put(listRetailersDone(res));
}

export function* createSharingUnitTemplates({
  payload: { name, retailer },
}: {
  payload: { name: string; retailer: number };
}) {
  const org_id = yield select(selectOrganizationId);
  const data = yield call(
    createTemplates,
    name,
    org_id,
    retailer,
    {},
    Types.TEMPLATE.id
  );
  yield put(sharingUnitTemplateIsCreating());
  yield call(push, fill(routes.SharingUnitTemplatesDetails, data.id));
}

export function* getSharingUnitTemplates({ payload }: { payload: number }) {
  yield put(loadSharingUnitTemplates());
  yield put(getSharingUnitTemplatesDone(yield call(getTemplates, payload)));
  yield call(validateSharingUnitTemplateSaga);
  yield put(stopLoadSharingUnitTemplates());
}

export function* saveSharingUnitTemplates() {
  const sut = yield select(selectSelectedSharingUnitTemplates);
  const res = yield call(updateTemplates, sut.id, sut.name, sut.data);
  if (res.error) {
    throw res.error;
  }
  yield put(getSharingUnitTemplatesDone(res));
  yield put(
    notificationSuccess(
      i18n.t(
        'frontproductstream.sharing_unit_templates_listing.save_model_success.notification',
        { defaultValue: 'Model saved' }
      )
    )
  );
}

export function* fetchDisplayGroups({ payload }: { payload: number }) {
  yield put(
    fetchDisplayGroupSharingUnitTemplatesDone({
      retailer_id: payload,
      data: yield call(apiFetchDisplayGroup, payload),
    })
  );
}

export function* validateSharingUnitTemplateSaga() {
  yield delay(500); // used for debouncing in conjunction with takeLatest;
  const sut = yield select(selectSelectedSharingUnitTemplates);
  const languages = yield select(selectLocales);

  const res = yield call(validate, {
    data: sut,
    languages: languages.toJS(),
  });

  yield put({
    type: RECEIVE_RULE_RESULTS,
    data: fromJS(res.result).setIn(['entity', 'id'], sut.id),
    versionId: sut.id,
    sharingUnits: [],
    logisticalUnits: [],
  });
  yield put(validateSharingUnitTemplateDone(res));
}

export function* saveSharingUnitTemplatesProduct() {
  yield put(startLoadSharingUnitTemplatesProduct());

  const sut = yield select(selectSelectedSharingUnitTemplates);
  const attachedProduct = yield select(selectCurrentAttachedProduct);

  const SpecificPW = update(
    ['levels'],
    map(
      update(
        ['items'],
        filter((item: { locked?: boolean }) => !item.locked)
      )
    ),
    get(['data', 'priceWaterfalls', 0], attachedProduct)
  );

  const hierarchyProductId = get(
    ['data', 'hierarchyProduct', 'id'],
    attachedProduct
  );
  const res = yield call(upsert_product, sut.id, [
    {
      ...(attachedProduct.id ? { id: attachedProduct.id } : {}),
      source_product_key: {
        id: get(['product', 'product_key_id'], attachedProduct),
      },
      data: {
        ...attachedProduct.data,
        ...(hierarchyProductId && {
          hierarchyProduct: {
            id: hierarchyProductId,
          },
        }),
        priceWaterfalls: [SpecificPW],
      },
    },
  ]);

  if (res.error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharing_unit_template_detail.add_product_modal.error',
          { defaultValue: 'Unable to attach this product' }
        ),
        { context: 'modal' }
      )
    );
    yield put(stopLoadSharingUnitTemplatesProduct());
  } else {
    yield all([
      put(toggleSharingUnitTemplatesProductModal(false)),
      put(
        notificationSuccess(
          i18n.t(
            'frontproductstream.sharing_unit_template_detail.add_product_modal.success',
            { defaultValue: 'Product successfully attached' }
          )
        )
      ),
      put(actionFetchProducts()),
    ]);
  }
}

export function* sharingUnitTemplatesFetchProduct() {
  yield put(startLoading());
  yield put(loadSharingUnitTemplatesFetchProduct());

  const sut = yield select(selectSelectedSharingUnitTemplates);
  const pagination = yield select(selectSelectedSUTProductsPagination);
  const isShowErrors = yield select(selectSelectedSUTProductsInErrorFilter);
  const searchQuery = yield select(selectSelectedSUTProductsSearchQuery);

  const res = yield call(
    fetchProducts,
    sut.id,
    pagination.limit,
    (pagination.page - 1) * pagination.limit,
    searchQuery,
    isShowErrors
  );
  yield put(
    sharingUnitTemplatesFetchProductDone({
      data: res.data,
      totalResults: res.totalResults,
    })
  );
  yield put(stopLoadSharingUnitTemplatesFetchProduct());
  yield put(stopLoading());
}

export function* checkSharingUnitTemplatesProductSharedSaga({
  payload,
}: {
  payload: { template_id: number; source_product_key_id: number };
}) {
  yield put(startLoadSharingUnitTemplatesProduct());
  const res = yield call(
    check_productshared,
    payload.template_id,
    payload.source_product_key_id
  );
  yield put(
    updateSharingUnitTemplatesProductErrors({
      isProductShared: res.shared === true,
    })
  );
  yield put(stopLoadSharingUnitTemplatesProduct());
}

export function* checkSharingUnitTemplatesProductUniqSaga({
  payload,
}: {
  payload: {
    template_id: number;
    source_product_key_id: number;
    hierarchy_id?: number;
    sharing_unit_id?: number;
  };
}) {
  yield put(startLoadSharingUnitTemplatesProduct());
  const res = yield call(
    check_uniqproduct,
    payload.template_id,
    payload.source_product_key_id,
    payload.hierarchy_id,
    payload.sharing_unit_id
  );
  yield put(
    updateSharingUnitTemplatesProductErrors({
      isProductUniq: res.uniq === true,
    })
  );
  yield put(stopLoadSharingUnitTemplatesProduct());
}

export function* loadSharingUnitForAttachModal({
  payload,
}: {
  payload: { isOpen: boolean; sharingUnitId?: number };
}) {
  if (payload.isOpen !== true) {
    return;
  } else if (!payload.sharingUnitId) {
    yield put(initSharingUnitTemplatesProduct());
  } else {
    const res = yield call(fetchSharingUnit, payload.sharingUnitId, true);

    if (!res.error) {
      const sut = yield select(selectSelectedSharingUnitTemplates);
      const sutAllowanceItemsUUIDs = [].concat(
        ...(get(['data', 'priceWaterfalls', 0, 'levels'], sut) || [])
          .filter(
            (level: { type: { code: string } }) =>
              get(['type', 'code'], level) === 'allowance'
          )
          .map((level) => level.items.map((item) => item.uuid))
      );

      // Add `locked` param on template levels to disable them in the attached modal
      const suWithLockedTemplateAllowances = update(
        ['data', 'priceWaterfalls', '0'],
        update(
          ['levels'],
          map((level: { type: { code: string }; items: any[] }) =>
            get(['type', 'code'], level) === 'allowance'
              ? update(
                  ['items'],
                  map((item: { uuid: never }) =>
                    sutAllowanceItemsUUIDs.includes(item.uuid)
                      ? set(['locked'], true, item)
                      : item
                  ),
                  level
                )
              : level
          )
        ),
        res
      );

      yield put(
        fetchSharingUnitTemplatesProductDone(suWithLockedTemplateAllowances)
      );
      yield put(
        checkSharingUnitTemplatesProductShared({
          template_id: sut.id,
          source_product_key_id: get(['product', 'product_key_id'], res),
        })
      );
      yield put(
        checkSharingUnitTemplatesProductUniq({
          template_id: sut.id,
          source_product_key_id: get(['product', 'product_key_id'], res),
          hierarchy_id: get(['data', 'hierarchyProduct', 'id'], res) || null,
          sharing_unit_id: get(['id'], res),
        })
      );
    } else {
      yield put(fetchSharingUnitTemplatesProductError());
    }
  }
}

export function* sharingUnitTemplatesSaga() {
  yield takeLatest(
    [LIST_SHARING_UNIT_TEMPLATES],
    withCatch(fetchSharingUnitTemplatesSaga, errorStrategy)
  );
  yield takeLatest(LIST_RETAILERS, fetchRetailers);
  yield takeLatestSafe(
    CREATE_SHARING_UNIT_TEMPLATES,
    createSharingUnitTemplates,
    {
      withNotification: true,
      errorMessage: i18n.t(
        'frontproductstream.sharing_unit_templates_listing.create_model_error.notification',
        { defaultValue: 'Unable to create a new model' }
      ),
    }
  );
  yield takeLatestSafe(
    GET_SHARING_UNIT_TEMPLATES,
    withCatch(getSharingUnitTemplates, errorStrategy)
  );
  yield takeLatestSafe(
    FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES,
    fetchDisplayGroups
  );
  yield takeLatestSafe(SAVE_SHARING_UNIT_TEMPLATES, saveSharingUnitTemplates, {
    withNotification: true,
    errorMessage: i18n.t(
      'frontproductstream.sharing_unit_templates_listing.save_model_error.notification',
      { defaultValue: 'Unable to save this model' }
    ),
  });
  yield takeLatestSafe(
    SAVE_SHARING_UNIT_TEMPLATES_PRODUCT,
    saveSharingUnitTemplatesProduct
  );
  yield takeLatestSafe(
    SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
    sharingUnitTemplatesFetchProduct
  );
  yield takeLatestSafe(
    VALIDATE_SHARING_UNIT_TEMPLATE,
    validateSharingUnitTemplateSaga,
    {
      withNotification: true,
      errorMessage: i18n.t(
        'frontproductstream.sharing_unit_templates_listing.validate_model_error.notification',
        { defaultValue: 'Unable to validate this model' }
      ),
    }
  );
  yield takeLatestSafe(
    CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_SHARED,
    checkSharingUnitTemplatesProductSharedSaga
  );
  yield takeLatestSafe(
    CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_UNIQ,
    checkSharingUnitTemplatesProductUniqSaga
  );
  yield takeLatestSafe(
    TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_MODAL,
    loadSharingUnitForAttachModal
  );
}
