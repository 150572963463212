import { get } from 'lodash';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { hasViewSourcing } from 'core/api/organization-settings';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { selectIsAuthorized } from 'modules/auth/shared';
import { getNextLocation, resetNextLocation } from 'modules/auth/shared/utils';
import { selectUser } from 'reducers/user/selectors';
import { fetchProductVersions } from 'resources/searchApi';
import * as routes from 'routes';
import type { GlobalState, UserImmutable } from 'types';

interface ConnectedProps {
  user: UserImmutable;
  isAuthorized: boolean;
}

interface State {
  redirectTo: string | null;
}

const mapStateToProps = createStructuredSelector<GlobalState, ConnectedProps>({
  user: selectUser,
  isAuthorized: selectIsAuthorized,
});

export class Landing extends PureComponent<ConnectedProps, State> {
  state = {
    redirectTo: null,
  };

  componentDidMount() {
    const { isAuthorized, user } = this.props;
    const nextLocation = getNextLocation();
    let redirectTo;

    if (isAuthorized === false) {
      redirectTo = routes.login;
    } else if (nextLocation && nextLocation.split('?')[0] !== routes.landing) {
      redirectTo = nextLocation;
      resetNextLocation();
    } else if (
      user &&
      isRetailer(user) &&
      hasViewSourcing(getOrganizationSettings(user))
    ) {
      this.handleSourcingRetailerRedirection();
    } else {
      redirectTo = routes.catalog;
    }

    if (redirectTo) {
      this.setState({ redirectTo });
    }
  }

  async handleSourcingRetailerRedirection() {
    // We want to redirect retailer with no product and sourcing
    // to product network page
    try {
      const response = await fetchProductVersions({
        limit: 0,
        withSourceInclude: false,
      });
      this.setState({
        redirectTo:
          get(response, 'total') === 0 ? routes.productNetwork : routes.catalog,
      });
    } catch (e) {
      this.setState({ redirectTo: routes.catalog });
    }
  }

  render() {
    const { redirectTo } = this.state;
    if (redirectTo) {
      return <Navigate to={redirectTo} />;
    }
    return null;
  }
}

export default connect<ConnectedProps, {}, {}, GlobalState>(mapStateToProps)(
  Landing
);
