import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import './index.scss';

export default class ListCollapsibleFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func.isRequired,
  };

  static defaultProps = {
    collapsed: true,
  };

  constructor(props) {
    super(props);
    this.onCollapse = this.onCollapse.bind(this);
  }

  onCollapse() {
    this.props.onCollapse(!this.props.collapsed);
  }

  render() {
    return (
      <div
        className={classNames('ListCollapsibleFilter', {
          'ListCollapsibleFilter--collapsed': this.props.collapsed,
          'ListCollapsibleFilter--expanded': !this.props.collapsed,
        })}
        id={this.props.id}
        data-testid="collapsible-filter"
      >
        <Button
          className="ListCollapsibleFilter__header"
          onClick={this.onCollapse}
        >
          <i
            className={classNames(
              'ListCollapsibleFilter__icon mdi mdi-play',
              !this.props.collapsed && 'ListCollapsibleFilter__icon--expanded'
            )}
          />
          <span className="ListCollapsibleFilter__label">
            {this.props.label}
          </span>
        </Button>
        {!this.props.collapsed && (
          <div className="ListCollapsibleFilter__body">
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}
