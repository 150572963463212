import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { notificationError } from 'actions/notification';
import Email from 'components/ui/input/email';
import i18n from 'utils/i18n';

import { addEmail, removeEmail } from '../../actions';
import { selectEmails } from '../../selectors';

export const mapStateToProps = (state) => ({
  emails: selectEmails(state),
});

export const mapDispatchToProps = {
  onAdd: addEmail,
  onDelete: (index) => removeEmail({ index }),
  onInvalidEmail: (value) => (dispatch) =>
    dispatch(
      notificationError(i18n.t('{{value}} is not a valid email', { value }), {
        context: 'modal',
      })
    ),
};

export const Emails = ({ onAdd, onDelete, onInvalidEmail, emails }) => (
  <Email
    onAdd={onAdd}
    onInvalidEmail={onInvalidEmail}
    onDelete={onDelete}
    placeholder={i18n.t('Emails')}
    emails={emails.toJS()}
    id="toEmail"
    label={i18n.t('Manufacturer email(s)')}
    labelWidth={3}
    className="RequestModal__emails"
  />
);

Emails.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onInvalidEmail: PropTypes.func.isRequired,
  emails: ImmutablePropTypes.list,
};

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
