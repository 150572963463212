import { omit, reduce, set, update } from 'lodash/fp';

import { Field } from '@alkem/sdk-dashboard';

import { ActionWithPayload } from 'types/redux';
import { createReducer } from 'utils/redux';

import {
  ADD_VARIANT_FIELD,
  CREATE,
  CREATE_FAILURE,
  CREATE_SUCCESS,
  REMOVE_VARIANT_FIELD,
  SET_VARIANT_FIELDS,
  TOGGLE_VARIANT,
} from './constants';

interface ModuleState {
  toggledVariantProductKeyId: number | null;
  variantFields: object;
  isCreatingVariant: boolean;
}

const initialState: ModuleState = {
  toggledVariantProductKeyId: null,
  variantFields: {},
  isCreatingVariant: false,
};

export default createReducer<ModuleState>(initialState, {
  [CREATE]: set(['isCreatingVariant'], true),
  [CREATE_FAILURE]: set(['isCreatingVariant'], false),
  [CREATE_SUCCESS]: set(['isCreatingVariant'], false),
  [SET_VARIANT_FIELDS]: (
    state: ModuleState,
    { variantFields }: { variantFields: Field[] }
  ): ModuleState =>
    set(
      'variantFields',
      reduce(
        (acc: object, f: Field) => set(f.model, f, acc),
        {} as object,
        variantFields
      ),
      state
    ),
  [ADD_VARIANT_FIELD]: (
    state: ModuleState,
    { field }: { field: Field }
  ): ModuleState => set(`variantFields.${field.model}`, field, state),
  [REMOVE_VARIANT_FIELD]: (
    state: ModuleState,
    { field }: { field: Field }
  ): ModuleState => update('variantFields', omit([field.model]), state),
  [TOGGLE_VARIANT]: (
    state: ModuleState,
    { payload: variantProductKeyId }: ActionWithPayload<number>
  ): ModuleState =>
    update(
      ['toggledVariantProductKeyId'],
      (kId) => (kId === variantProductKeyId ? null : variantProductKeyId),
      state
    ),
});
