import { Map } from 'immutable';

import {
  TargetProductStatusAccepted,
  TargetProductStatusRefused,
  TargetProductStatusSubmitted,
  TargetProductStatusUpdated,
} from 'constants/targetProductStatus';
import { getTargetProductStatus } from 'core/api/retailerproductversion';
import { isManufacturer } from 'core/api/user';

export function getDownloadablePictures(selectedMap, productMap, user) {
  let productsWithPictures = Map();
  if (selectedMap === undefined || selectedMap.size === 0) {
    return productsWithPictures;
  }
  productsWithPictures = selectedMap.filter(
    (v, pvId) =>
      v === true &&
      (productMap.getIn([pvId, 'assets', 'pictures', 0]) ||
        productMap.getIn([pvId, 'alkemics', 'icon_url'])) &&
      (isManufacturer(user) ||
        [
          TargetProductStatusAccepted.id,
          TargetProductStatusUpdated.id,
          TargetProductStatusSubmitted.id,
          TargetProductStatusRefused.id,
        ].includes(getTargetProductStatus(productMap.get(pvId))))
  );
  return productsWithPictures;
}

export function getDownloadablePackshots(selectedMap, productMap, user) {
  if (selectedMap === undefined || selectedMap.size === 0) {
    return Map();
  }
  const productsWithPictures = getDownloadablePictures(
    selectedMap,
    productMap,
    user
  );
  return productsWithPictures.filter((v, pvId) =>
    productMap.getIn([pvId, 'alkemics', 'icon_url'])
  );
}

export function getProductKeyIds(
  packshotOnly = false,
  productMap,
  selectedMap,
  user
) {
  const products = packshotOnly
    ? getDownloadablePackshots(selectedMap, productMap, user)
    : getDownloadablePictures(selectedMap, productMap, user);
  const productKeyIds = products.reduce(
    (acc, _, pvId) =>
      acc.concat([productMap.getIn([pvId, 'product_key', 'id'])]),
    []
  );
  return productKeyIds;
}

export function download(
  packshotOnly = false,
  selectedMap,
  productMap,
  user,
  actions
) {
  const productKeyIds = getProductKeyIds(
    packshotOnly,
    productMap,
    selectedMap,
    user
  );

  if (productKeyIds.length === 0) {
    return;
  }

  const limit = 20;
  const totalPictures = productKeyIds.length;
  const expectedArchives = Math.floor((totalPictures - 1) / limit + 1);

  actions.downloadPictures({
    productKeyIds,
    limit,
    expectedArchives,
    packshotOnly,
  });
  actions.trackAction({
    category: 'product',
    action: 'product_pictures_exported',
    label: `productKeyIds=${productKeyIds.join()}&packshotOnly=${packshotOnly}`,
  });
}
