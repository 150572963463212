import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Modal from 'components/ui/modal';
import { ROOM_TYPE_ORGANIZATION } from 'constants/room';
import { Chat } from 'modules/chat';
import i18n from 'utils/i18n';

import './index.scss';

export default class SourcingCellMessageActionWrapper extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    preSelectedOrganizationId: PropTypes.number,
  };

  state = {
    previewOpened: false,
  };

  togglePreviewModal = () => {
    this.setState((prevState) => ({ previewOpened: !prevState.previewOpened }));
  };

  renderChat() {
    return (
      <Modal
        hideFooter
        modalStyle="large"
        title={i18n.t('Sourcing Comment')}
        onClose={this.togglePreviewModal}
      >
        <div className="CatalogSourcingCellActionMessageWrapperModal__body">
          <Chat
            roomType={ROOM_TYPE_ORGANIZATION}
            preSelectedOrganizationId={this.props.preSelectedOrganizationId}
          />
        </div>
      </Modal>
    );
  }

  render() {
    const { children, className } = this.props;
    return (
      <>
        <a
          className={classNames(
            'CatalogSourcingCellActionMessageWrapper',
            'CatalogSourcingCellActionMessageWrapper--link',
            className
          )}
          onClick={this.togglePreviewModal}
        >
          {children}
        </a>
        {this.state.previewOpened && this.renderChat()}
      </>
    );
  }
}
