import { fromJS } from 'immutable';
import { get } from 'lodash/fp';

import BuyingUnitApi from 'resources/buyingUnitApi';
import DisplayGroupApi from 'resources/displayGroupApi';
import ServiceProductApi from 'resources/serviceProductApi';
import qs from 'utils/query';

import track from './track';

export default {
  async createSharingUnit(
    sourceProductKeyId,
    { targetOrganizationId, templateId }
  ) {
    try {
      const response = await ServiceProductApi.createSharingUnit({
        source_product_key_id: sourceProductKeyId,
        target_organization_id: targetOrganizationId,
        template_id: templateId,
      });
      const sharingUnit = response.data.data;
      track.create(sharingUnit.id);
      return { result: { sharingUnit } };
    } catch (error) {
      return { error };
    }
  },
  async deleteSharingUnit({ id, force = false } = {}) {
    try {
      await BuyingUnitApi.delete(
        `/buyingunit/v2/sharingunits/${id}${qs.stringify({ force }, true)}`
      );
      return { result: { sharingUnitId: id } };
    } catch (error) {
      return { error };
    }
  },
  async fetchDisplayGroups(targetOrganizationId, isTariff) {
    try {
      const response = await DisplayGroupApi.DisplayGroupSharingUnitList(
        targetOrganizationId,
        isTariff ? 'tariff' : 'sharingunit'
      );
      return { result: { displayGroups: get('data.data', response) } };
    } catch (error) {
      return { error };
    }
  },
  async fetchSharingUnitStatus(sharingUnitId) {
    try {
      const response = await BuyingUnitApi.get(
        '/buyingunit/v3/product/sharingunits/statuses',
        qs.stringify({ sharing_unit_id: sharingUnitId }, true)
      );
      return {
        sharingUnit: fromJS(response.data.sharing_unit),
      };
    } catch (error) {
      return { error };
    }
  },
};
