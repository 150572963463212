import { filter, flow, map, reduce, reverse, set, sortBy } from 'lodash/fp';
import { memo, useCallback, useMemo, useState } from 'react';

import { Button } from '@alkem/react-ui-button';

import Helper from 'components/ui/helper';
import Modal from 'components/ui/modal';
import ProductReference from 'components/ui/product-reference';
import Tipster from 'components/ui/tipster';
import {
  ProductReferenceType,
  ProductReferenceTypes,
} from 'constants/product-reference-types';
import { getDisplayName } from 'core/api/productversion';
import { dedupe } from 'utils';
import { sanitize } from 'utils/DOMPurify';
import i18n from 'utils/i18n';
import { getControlDigit, getFirstDigit, getRoot } from 'utils/validator/gtin';

import { getReference } from '../../../../core/api/product';
import { isConsumerOrDisplayUnit } from '../../helpers';

import './gtin-generator.scss';

interface GTINGeneratorProps {
  baseGtin: string;
  currentLanguage: object;
  rootInternalId: string;
  dataMap: Record<
    string,
    {
      gtin: string;
      productIdentifier: string;
      version: {};
    }
  >;
  hierarchyMap: Record<string, { id: string }[]>;
  onUpdateGTIN(
    internalId: string,
    gtin: string,
    referenceType: ProductReferenceType
  ): void;
}

export default memo(function GTINGenerator(props: GTINGeneratorProps) {
  const {
    currentLanguage,
    dataMap,
    baseGtin,
    rootInternalId,
    hierarchyMap,
    onUpdateGTIN,
  } = props;

  const [isModalOpen, openModal] = useState(false);

  const canGenerateGtin = useMemo(
    () => canGenerate(baseGtin, dataMap, rootInternalId, hierarchyMap),
    [baseGtin, dataMap, hierarchyMap, rootInternalId]
  );

  const gtinMap = useMemo(
    () =>
      canGenerateGtin
        ? generateGTINs(
            baseGtin,
            getInternalIdsWithoutReferences(
              rootInternalId,
              dataMap,
              hierarchyMap
            ),
            getAvailableDigits(baseGtin, dataMap)
          )
        : [],
    [baseGtin, canGenerateGtin, dataMap, hierarchyMap, rootInternalId]
  );

  const onToggleModal = useCallback(() => {
    openModal(!isModalOpen);
  }, [isModalOpen]);

  const onConfirm = useCallback(() => {
    for (const [internalId, gtin] of Object.entries<string>(gtinMap)) {
      onUpdateGTIN(internalId, gtin, ProductReferenceTypes.GTIN);
    }
    onToggleModal();
  }, [gtinMap, onToggleModal, onUpdateGTIN]);

  const gtins = Object.entries<string>(gtinMap);
  const itemsWithoutGtinCount = useMemo(
    () =>
      Object.keys(
        getInternalIdsWithoutReferences(rootInternalId, dataMap, hierarchyMap)
      ).length,
    [dataMap, hierarchyMap, rootInternalId]
  );

  if (!gtins.length && !canGenerateGtin) {
    if (isGtin8(baseGtin) && itemsWithoutGtinCount > 0) {
      return (
        <Helper
          type="Warning"
          left={
            <>
              <i
                className={`GTINGenerator__icon GTINGenerator__icon--warning mdi mdi-alert-circle-outline`}
              />
              {i18n.t(
                'frontproductstream.logistical_hierarchies_gtin_generator.gtin_length_warning.text',
                {
                  defaultValue:
                    'We cannot deduce valid GTINs based on a GTIN-8 (GTIN starting by 6 zeros). Please contact GS1 support to get logistical hierarchies GTINs',
                }
              )}
            </>
          }
        />
      );
    } else {
      return null;
    }
  }
  return (
    <>
      <Helper
        left={
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(
                i18n.t(
                  'frontproductstream.logistical_hierarchies_gtin_generator.gtin_generation.help',
                  {
                    defaultValue:
                      "You don't have GTINs for you logistical units? We can generate them for you",
                  }
                ),
                { ADD_ATTR: ['target'] }
              ),
            }}
          />
        }
        right={
          <Button onClick={onToggleModal} primary>
            {i18n.t(
              'frontproductstream.logistical_hierarchies_gtin_generator.gtin_generation.button',
              { defaultValue: 'Generate GTINs' }
            )}
          </Button>
        }
      />
      {isModalOpen && (
        <Modal
          title={i18n.t(
            'frontproductstream.logistical_hierarchies_gtin_generator.gtin_generation.title',
            { defaultValue: 'GTIN generation' }
          )}
          onClose={onToggleModal}
          onConfirm={onConfirm}
          confirmButtonText={i18n.t(
            'frontproductstream.logistical_hierarchies_gtin_generator.gtin_generation_confirmation.title',
            { defaultValue: 'Generate GTINs' }
          )}
        >
          <div className="GTINGenerator__modalBody">
            <Tipster>
              <span>
                {i18n.t(
                  'frontproductstream.logistical_hierarchies_gtin_generator.gtin_generation_modal.help',
                  { defaultValue: 'Not sure what you are doing?' }
                )}{' '}
                <a data-elevio-article="119">
                  {i18n.t(
                    'frontproductstream.logistical_hierarchies_gtin_generator.gtin_generation_modal_documentation.help',
                    { defaultValue: 'Check our documentation' }
                  )}
                </a>
              </span>
            </Tipster>
            <p>
              {i18n.t(
                'frontproductstream.logistical_hierarchies_gtin_generator.modal_generated_gtins.help',
                { defaultValue: 'Generated GTINs for your products:' }
              )}
            </p>
            {gtins.map(([internalId, gtin]) => (
              <div key={gtin} className="GTINGenerator__unit">
                <ProductReference reference={gtin} />
                <span>
                  {i18n.t(
                    'frontproductstream.logistical_hierarchies_gtin_generator.modal_unit.text',
                    { defaultValue: 'for' }
                  )}
                </span>
                <span className="GTINGenerator__unitName">
                  {getDisplayName(dataMap[internalId].version, currentLanguage)}
                </span>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
});

function generateGTIN(baseGtin: string, firstDigit: string | null) {
  if (firstDigit == null) {
    return '';
  }
  // Replace the first digit.
  const newGtin = `${firstDigit}${getRoot(baseGtin)}0`;
  // Recompute the control digit.
  return `${newGtin.substring(0, 13)}${getControlDigit(newGtin)}`;
}

function generateGTINs(
  baseGtin: string,
  internalIdsWithoutGTINs,
  availableDigits: number[]
) {
  return flow(
    Object.entries,
    sortBy(([, rank]) => -rank),
    map(([id]) => [
      id,
      generateGTIN(baseGtin, availableDigits.shift()?.toString() || null),
    ]),
    filter(([, gtin]) => !!gtin),
    reverse,
    reduce((acc, [id, gtin]) => set([id], gtin, acc), {})
  )(internalIdsWithoutGTINs);
}

function getAvailableDigits(
  baseGtin: string,
  dataMap: GTINGeneratorProps['dataMap']
) {
  if (!baseGtin) {
    return [];
  }
  const allDigits: number[] = [];
  while (allDigits.length < 9) {
    allDigits.push(allDigits.length + 1);
  }
  const usedDigits = Object.values(dataMap)
    .map((d) => d.gtin)
    .filter((g) => g && getRoot(baseGtin) === getRoot(g))
    .map((g) => getFirstDigit(g));
  return allDigits.filter((digit) => !usedDigits.includes(digit));
}

function getInternalIdsWithoutReferences(
  rootInternalId: string,
  dataMap: GTINGeneratorProps['dataMap'],
  hierarchyMap: GTINGeneratorProps['hierarchyMap'],
  _internalId = '',
  rank = 0
) {
  const internalId = _internalId || rootInternalId;
  if (isConsumerOrDisplayUnit(dataMap[internalId].version)) {
    return {};
  }
  const childIds = dedupe(hierarchyMap[internalId].map((e) => e.id));
  let agg = getReference(dataMap[internalId]) ? {} : { [internalId]: rank };
  for (const id of childIds) {
    agg = Object.assign(
      agg,
      getInternalIdsWithoutReferences(
        rootInternalId,
        dataMap,
        hierarchyMap,
        id,
        rank + 1
      )
    );
  }
  return agg;
}

function canGenerate(
  baseGtin: string,
  dataMap: GTINGeneratorProps['dataMap'],
  rootInternalId: string,
  hierarchyMap: GTINGeneratorProps['hierarchyMap']
) {
  // Base gtin is always 14 chars long since already processed by the back.
  // Cannot generate if gtin is 8 chars long.
  if (isGtin8(baseGtin)) {
    return false;
  }
  const noReferences = Object.keys(
    getInternalIdsWithoutReferences(rootInternalId, dataMap, hierarchyMap)
  ).length;
  const availableDigits = getAvailableDigits(baseGtin, dataMap);
  return noReferences > 0 && availableDigits.length > 0;
}

function isGtin8(baseGtin: string) {
  return baseGtin && baseGtin.startsWith('000000');
}
