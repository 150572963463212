import { createAction } from 'utils/redux';

import {
  CLOSE_TARGET_CATALOG_IMPORT_MODAL,
  OPEN_TARGET_CATALOG_IMPORT_FOLLOW_UP_MODAL,
  OPEN_TARGET_CATALOG_IMPORT_MODAL,
  RESTART_TARGET_CATALOG_IMPORT_MODAL,
  TARGET_CATALOG_IMPORT_SELECT_FILE,
  TARGET_CATALOG_IMPORT_UPLOAD,
  TARGET_CATALOG_IMPORT_UPLOAD_DONE,
} from './constants';

export const uploadTargetCatalog = createAction(TARGET_CATALOG_IMPORT_UPLOAD);

export const uploadTargetCatalogDone = createAction(
  TARGET_CATALOG_IMPORT_UPLOAD_DONE
);

export const closeTargetCatalogImportModal = createAction(
  CLOSE_TARGET_CATALOG_IMPORT_MODAL
);

export const openTargetCatalogImportModal = (format) => ({
  type: OPEN_TARGET_CATALOG_IMPORT_MODAL,
  format,
});

export const selectFileInTargetCatalogImportModal = createAction(
  TARGET_CATALOG_IMPORT_SELECT_FILE
);

export const openFollowUpImportModal = createAction(
  OPEN_TARGET_CATALOG_IMPORT_FOLLOW_UP_MODAL
);

export const restartTargetCatalogImportModal = createAction(
  RESTART_TARGET_CATALOG_IMPORT_MODAL
);
