import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

const asyncModule = async () =>
  registerModule(
    await import(/* webpackChunkName: "product-creation" */ './async')
  );

export const ProductCreation = asyncComponent(asyncModule);
export const AssignProductToTargetMarket = asyncComponent(asyncModule, {
  componentPath: 'AssignProductToTargetMarket',
});

export { OROPHistoryTypeClaim } from './constants';
