import classNames from 'classnames';
import { List } from 'immutable';

import { CircularProgressbar } from 'components/ui/circular-progressbar';
import {
  MaturitiesSummary,
  MaturitySummary,
} from 'modules/catalog/product/components/list/table/cell/maturity/index';
import { MaturityCompletion } from 'modules/catalog/product/components/list/table/cell/maturity/maturity-panel/maturity-completion';
import { computePercentageAndQuality } from 'modules/catalog/product/components/list/table/cell/percentage';
import i18n from 'utils/i18n';

import styles from './index.module.scss';

interface RecipientMaturities {
  recipientId: number;
  recipientName: string;
  successful: number;
  total: number;
  scenarios: MaturitySummary[];
}

export const ActiveScenarios = ({
  data,
  colorRange,
}: {
  data: MaturitiesSummary;
  colorRange?: List<number>;
}) => {
  const globalCompletion = computePercentageAndQuality(
    data.successful,
    data.total,
    colorRange
  );

  const renderMaturities = (maturities: MaturitySummary[]) =>
    maturities.map((maturity) => (
      <MaturityCompletion
        key={maturity.id}
        maturity={maturity}
        colorRange={colorRange}
        includeLateLabel={true}
      />
    ));

  const recipientMaturities = data.active_scenarios.reduce(
    (recipientGroups: RecipientMaturities[], maturity: MaturitySummary) => {
      if (!maturity.recipient_id || !maturity.recipient_name) {
        return recipientGroups;
      }

      let recipientGroup = recipientGroups.find(
        (candidate) => candidate.recipientId === maturity.recipient_id
      );

      if (!recipientGroup) {
        recipientGroup = {
          recipientId: maturity.recipient_id,
          recipientName: maturity.recipient_name,
          successful: 0,
          total: 0,
          scenarios: [],
        };
        recipientGroups.push(recipientGroup);
      }
      recipientGroup.scenarios.push(maturity);
      recipientGroup.total += maturity.total;
      recipientGroup.successful += maturity.successful;

      return recipientGroups;
    },
    []
  );

  let details;
  if (recipientMaturities.length) {
    details = (
      <div
        className={classNames('alk-flex', 'alk-flex-column', styles.recipients)}
      >
        {recipientMaturities.map((recipientGroup) => (
          <div key={recipientGroup.recipientId} className={styles.recipient}>
            <div className={styles.recipientTitle}>
              {recipientGroup.recipientName} :{' '}
              {
                computePercentageAndQuality(
                  recipientGroup.successful,
                  recipientGroup.total
                ).percentage
              }
              %{' '}
              {i18n.t(
                'frontproductstream.maturity_panel.retailer_completion.text',
                {
                  defaultValue: 'completed',
                }
              )}
            </div>
            {renderMaturities(recipientGroup.scenarios)}
          </div>
        ))}
      </div>
    );
  } else if (data.active_scenarios.length) {
    details = <>{renderMaturities(data.active_scenarios)}</>;
  }

  const completionStyleByQuality = {
    low: styles.globalCompletionBar__low,
    medium: styles.globalCompletionBar__medium,
    good: styles.globalCompletionBar__good,
  };

  return (
    <>
      <div className={styles.globalCompletion}>
        <div className={styles.globalCompletionTitle}>
          {i18n.t('frontproductstream.maturity_panel.global_completion.text', {
            defaultValue: 'Global completion',
          })}
        </div>
        <CircularProgressbar
          value={globalCompletion.percentage}
          text={`${globalCompletion.percentage}%`}
          className={classNames(
            styles.globalCompletionBar,
            completionStyleByQuality[globalCompletion.quality]
          )}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.detailsTitle}>
          {i18n.t('frontproductstream.maturity_panel.details_title.text', {
            defaultValue: 'Details',
          })}
        </div>
        <div className={styles.detailsList}>{details}</div>
      </div>
    </>
  );
};
