import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';
import { Ellitips } from '@alkem/react-ui-ellitips';
import { TurboSelect } from '@alkem/react-ui-turbo-select';

import { InputReference } from 'components/ui/input/reference';
import InputText from 'components/ui/input/text';
import {
  ProductReferenceType,
  ProductReferenceTypes,
} from 'constants/product-reference-types';
import {
  getLogisticalTypePackagingLabel,
  getTypePackagingCode,
} from 'constants/typePackaging';
import {
  displayNameFallback,
  getDisplayName,
  getTypePackagingId,
} from 'core/api/productversion';
import { selectHasEditableHierarchies } from 'modules/feature-flag/selectors';
import {
  CurrentVersionInfo,
  Data as LogisticalHierarchyData,
} from 'modules/logistical-hierarchies/structures';
import * as routes from 'routes';
import { parseNumber, toProductIdentifier } from 'utils';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';

import { getReference } from '../../../../core/api/product';
import { isConsumerOrDisplayUnit } from '../../helpers';
import { LogisticalHierarchyIcon } from '../icon';

import './unit.scss';

export interface LogisticalHierarchyUnitProps {
  hasFeatureNoGtin?: boolean;
  referenceTypeOption?: ReferenceTypeOption;
  path?: string;
  internalId?: string;
  parentInternalId?: string;
  rootInternalId?: string;
  data: LogisticalHierarchyData;
  quantity?: number;
  currentVersionInfo: CurrentVersionInfo;
  isMainHierarchy?: boolean;
  isParentMainHierarchyUnit?: boolean;
  hasChildren?: boolean;
  childrenGtins?: string;
  parentPackagingTypeCode?: string;
  parentIsLogisticalUnit?: boolean;
  targetMarketId?: number;
  onUpdateReferenceTypeOption?: (
    referenceTypeOption: ReferenceTypeOption
  ) => void;
  onEdit?: (
    internalId: string,
    parentInternalId?: string,
    rootInternalId?: string
  ) => void;
  onUpdateQuantity?: (
    parentInternalId: string | undefined,
    internalId: string | undefined,
    quantity: number | null,
    isUsedInAgreedSharingUnit: boolean | undefined
  ) => void;
  onUpdateReference?: (
    internalId: string,
    reference: string,
    referenceType: ProductReferenceType,
    parentInternalId?: string,
    isUsedInAgreedSharingUnit?: boolean
  ) => void;
  onDelete?: (
    parentInternalId?: string,
    internalId?: string,
    isUsedInAgreedSharingUnit?: boolean
  ) => void;
  isAgreed?: boolean;
  readOnly: boolean;
  noActions?: boolean;
  isCreated?: boolean;
  parentIsCreated?: boolean;
  hasFlatHierarchies?: boolean;
  isPatchedHierarchy?: boolean;
  isHeterogeneousLogisticalUnit?: boolean;
}

interface ReferenceTypeOption {
  id: string;
  label: string;
}

const LogisticalHierarchyUnitDisplayName = ({
  path,
  data,
  currentVersionInfo,
}: LogisticalHierarchyUnitProps) => {
  const getLabel = () => {
    if (data.gtin === currentVersionInfo.gtin) {
      return currentVersionInfo.displayName || displayNameFallback;
    }
    return getDisplayName(data.version, currentVersionInfo.currentLanguage);
  };
  return (
    <div className="LogisticalHierarchyUnit__label">
      <Ellitips id={`${path}-label`} label={getLabel()} />
    </div>
  );
};

interface ReferenceTypeOption {
  id: string;
  label: string;
}

const getReferenceTypeOptions = () => ({
  productIdentifier: {
    label: ProductReferenceTypes.IDENTIFIER.displayValue(),
    id: ProductReferenceTypes.IDENTIFIER.fieldName,
  },
  gtin: {
    label: ProductReferenceTypes.GTIN.displayValue(),
    id: ProductReferenceTypes.GTIN.fieldName,
  },
});

const mapReferenceTypeOptionsToProductReferenceType = (
  option?: ReferenceTypeOption
): ProductReferenceType => {
  switch (option?.id) {
    case ProductReferenceTypes.GTIN.fieldName:
      return ProductReferenceTypes.GTIN;
    case ProductReferenceTypes.IDENTIFIER.fieldName:
      return ProductReferenceTypes.IDENTIFIER;
    default:
      return ProductReferenceTypes.UNKNOWN;
  }
};

const LogisticalHierarchyUnitReference = ({
  path,
  data,
  readOnly,
  isAgreed,
  isHeterogeneousLogisticalUnit,
  internalId,
  parentInternalId,
  onUpdateReference,
  referenceTypeOption,
}: LogisticalHierarchyUnitProps) => {
  const hasEditableHierarchies: boolean = useSelector(
    selectHasEditableHierarchies
  );

  const gtinClasses = {
    LogisticalHierarchyUnit__reference: true,
    'LogisticalHierarchyUnit__reference--empty': !getReference(data),
  };

  const referenceType =
    mapReferenceTypeOptionsToProductReferenceType(referenceTypeOption);

  const onUpdate = (event) => {
    const newValue =
      referenceType === ProductReferenceTypes.IDENTIFIER
        ? toProductIdentifier(event.target.value)
        : event.target.value;
    internalId &&
      newValue !== getReference(data.version) &&
      onUpdateReference?.(
        internalId,
        newValue,
        referenceType,
        parentInternalId,
        hasEditableHierarchies && isAgreed
      );
  };

  const disabled =
    readOnly ||
    isConsumerOrDisplayUnit(data.version) ||
    isHeterogeneousLogisticalUnit ||
    (!hasEditableHierarchies && isAgreed);

  return (
    <div className={classNames(gtinClasses)}>
      <InputReference
        id={`${path}-${referenceType.fieldName}`}
        value={getReference(data)}
        referenceType={referenceType}
        placeholder={referenceType.displayValue()}
        onChange={onUpdate}
        invalid={!getReference(data)}
        disabled={disabled}
        enableValidator
      />
    </div>
  );
};

const getDefaultProductReferenceTypeOption = (
  currentVersionInfo: CurrentVersionInfo,
  logisticalHierarchyData: LogisticalHierarchyData,
  hasFeatureNoGtin?: boolean
): ReferenceTypeOption => {
  const referenceTypeOptions = getReferenceTypeOptions();
  if (!hasFeatureNoGtin) {
    return referenceTypeOptions.gtin;
  }

  if (
    !logisticalHierarchyData.gtin &&
    !logisticalHierarchyData.productIdentifier
  ) {
    return currentVersionInfo.productIdentifier
      ? referenceTypeOptions.productIdentifier
      : referenceTypeOptions.gtin;
  }

  return logisticalHierarchyData.productIdentifier
    ? referenceTypeOptions.productIdentifier
    : referenceTypeOptions.gtin;
};

export const LogisticalHierarchyUnitReferentialTypeSelector = ({
  data,
  currentVersionInfo,
  isAgreed,
  readOnly,
  isHeterogeneousLogisticalUnit,
  onUpdateReferenceTypeOption,
  isCreated,
  hasFeatureNoGtin,
}: LogisticalHierarchyUnitProps) => {
  const [selectedReferenceType, setSelectedReferenceType] = useState(() =>
    getDefaultProductReferenceTypeOption(
      currentVersionInfo,
      data,
      hasFeatureNoGtin
    )
  );
  const hasEditableHierarchies = useSelector(selectHasEditableHierarchies);
  const isUsedInAgreedSharingUnit = !hasEditableHierarchies && isAgreed;
  const disabled =
    readOnly ||
    isConsumerOrDisplayUnit(data.version) ||
    isHeterogeneousLogisticalUnit ||
    isUsedInAgreedSharingUnit ||
    isCreated;

  const onChangeReferenceType = (newReferenceTypeOption) => {
    setSelectedReferenceType(newReferenceTypeOption);
    onUpdateReferenceTypeOption?.(newReferenceTypeOption);
  };

  const referenceTypeOptions = useMemo(
    () => Object.values(getReferenceTypeOptions()),
    []
  );

  return (
    <TurboSelect
      className="LogisticalHierarchyUnit__productReferenceTypeSelector"
      value={selectedReferenceType}
      onChange={onChangeReferenceType}
      options={referenceTypeOptions}
      isDisabled={disabled}
      isClearable={false}
      isSearchable={false}
    />
  );
};

export const LogisticalHierarchyUnitLabel = (
  props: LogisticalHierarchyUnitProps
) => {
  const [selectedReferenceType, setSelectedReferenceType] = useState(() =>
    getDefaultProductReferenceTypeOption(
      props.currentVersionInfo,
      props.data,
      props.hasFeatureNoGtin
    )
  );

  const onChangeReferenceType = (newReferenceType: {
    id: string;
    label: string;
  }) => {
    setSelectedReferenceType(newReferenceType);
  };

  return (
    <div className="LogisticalHierarchyUnit__infoFirstRow">
      <LogisticalHierarchyUnitDisplayName {...props} />
      <LogisticalHierarchyUnitReference
        {...props}
        referenceTypeOption={selectedReferenceType}
      />
      {props.hasFeatureNoGtin && (
        <LogisticalHierarchyUnitReferentialTypeSelector
          {...props}
          onUpdateReferenceTypeOption={onChangeReferenceType}
        />
      )}
    </div>
  );
};

const getIdPrefix = (parentInternalId, internalId) => {
  return `logistical-unit-${parentInternalId || 'root'}-${internalId}`;
};

const getVersionData = (data, currentVersionInfo) => {
  if (data.gtin && data.gtin === currentVersionInfo.gtin) {
    return currentVersionInfo;
  }
  return data.version;
};

export const LogisticalHierarchyUnit = ({
  noActions = false,
  ...props
}: LogisticalHierarchyUnitProps) => {
  const {
    path,
    data,
    currentVersionInfo,
    internalId,
    parentInternalId,
    rootInternalId,
    childrenGtins,
    quantity,
    readOnly,
    onUpdateQuantity,
    onDelete,
    onEdit,
    parentPackagingTypeCode,
    parentIsLogisticalUnit,
    hasFlatHierarchies,
    isAgreed,
    isPatchedHierarchy,
    isHeterogeneousLogisticalUnit,
    isParentMainHierarchyUnit,
    isMainHierarchy,
    isCreated,
    parentIsCreated,
  } = props;

  const hasEditableHierarchies: boolean = useSelector(
    selectHasEditableHierarchies
  );
  const idPrefix = getIdPrefix(parentInternalId, internalId);
  const versionData = getVersionData(data, currentVersionInfo);
  const typePackagingCode = getTypePackagingCode(versionData.typePackaging);

  const _onUpdateQuantity = (event) =>
    onUpdateQuantity?.(
      parentInternalId,
      internalId,
      parseNumber(event.target.value),
      hasEditableHierarchies && isAgreed
    );

  const _onDelete = () =>
    onDelete?.(
      parentInternalId,
      internalId,
      hasEditableHierarchies && isAgreed
    );

  const _onEdit = () =>
    internalId && onEdit?.(internalId, parentInternalId, rootInternalId);

  const getQuantityLabel = () => {
    const label = getLogisticalTypePackagingLabel(
      typePackagingCode,
      !isConsumerOrDisplayUnit(versionData),
      quantity === 1
    );
    const parentLabel = getLogisticalTypePackagingLabel(
      parentPackagingTypeCode,
      parentIsLogisticalUnit,
      true
    );
    if (!label || !parentLabel) {
      return quantity === 1
        ? i18n.t(
            'frontproductstream.logistical_hierarchy_unit.single_quantity.text',
            { defaultValue: 'unit / parent' }
          )
        : i18n.t(
            'frontproductstream.logistical_hierarchy_unit.multiple_quantity.text',
            { defaultValue: 'units / parent' }
          );
    }
    return `${label} / ${parentLabel}`;
  };

  const getActions = (currentVersionOrVariant, consumerOrDisplayUnit, root) => {
    const actions: JSX.Element[] = [];
    if (
      (consumerOrDisplayUnit || isHeterogeneousLogisticalUnit) &&
      !currentVersionOrVariant &&
      onEdit
    ) {
      actions.push(
        <Link
          id={`${idPrefix}-link-redirect`}
          key="redirect"
          to={fill(routes.productPage, data.product_key_id)}
          target="_blank"
        >
          {i18n.t(
            'frontproductstream.logistical_hierarchy_unit.redirect_to_product_page.link',
            { defaultValue: 'View product' }
          )}
        </Link>
      );
    }
    if (
      !consumerOrDisplayUnit &&
      !currentVersionOrVariant &&
      !hasFlatHierarchies &&
      onEdit
    ) {
      actions.push(
        <Button
          key="form"
          id={`${idPrefix}-btn-form`}
          testid="LogisticalHierarchyUnitEditButton"
          content={
            readOnly
              ? i18n.t(
                  'frontproductstream.logistical_hierarchy_unit.readonly.button',
                  { defaultValue: 'View' }
                )
              : i18n.t(
                  'frontproductstream.logistical_hierarchy_unit.edit.button',
                  { defaultValue: 'Enter data' }
                )
          }
          onClick={_onEdit}
          secondary
        />
      );
    }

    // Display the delete button if:
    //  - current version is the main unit or the hierarchy has not been created yet
    //  - logistical unit
    //  - non logistical unit and (current version or variant) and not root
    if (
      !readOnly &&
      (isMainHierarchy || !isCreated || !parentIsCreated) &&
      (hasEditableHierarchies || !isAgreed) &&
      (!consumerOrDisplayUnit || (currentVersionOrVariant && !root)) &&
      // if patched only add delete action on root
      (isPatchedHierarchy ? root : true) &&
      onDelete
    ) {
      actions.push(
        <i
          key="remove"
          id={`${idPrefix}-btn-remove`}
          data-testid="LogisticalHierarchyUnitDeleteButton"
          className="mdi mdi-delete LogisticalHierarchyUnit__deleteBtn"
          onClick={_onDelete}
        />
      );
    }
    return actions;
  };

  const renderQuantity = () => {
    // No quantity if root.
    if (!parentInternalId) {
      return null;
    }
    const labelClasses = {
      LogisticalHierarchyUnit__quantityLabel: true,
      'LogisticalHierarchyUnit__quantityLabel--empty': !quantity,
    };
    return (
      <div className="LogisticalHierarchyUnit__quantity">
        <InputText
          id={`${path}-quantity`}
          value={quantity}
          placeholder="0"
          onChange={_onUpdateQuantity}
          type="number"
          invalid={!quantity}
          disabled={
            readOnly ||
            isParentMainHierarchyUnit ||
            (!hasEditableHierarchies && isAgreed)
          }
        />
        <div className={classNames(labelClasses)}>{getQuantityLabel()}</div>
      </div>
    );
  };

  const renderActions = () => {
    const currentVersion =
      getReference(data) === getReference(currentVersionInfo);
    const consumerOrDisplayUnit = isConsumerOrDisplayUnit(data.version);
    const root = !parentInternalId;
    const isVariant = currentVersionInfo.textileVariantGTINs?.includes(
      data.gtin
    );
    return (
      <div className="LogisticalHierarchyUnit__actions">
        {getActions(currentVersion || isVariant, consumerOrDisplayUnit, root)}
      </div>
    );
  };

  const gtin = getReference(data);

  return (
    <div
      id={idPrefix}
      className="LogisticalHierarchyUnit"
      data-logistical-hierarchy-unit={gtin}
      data-logistical-hierarchy-unit-type={typePackagingCode}
      data-logistical-hierarchy-unit-children={childrenGtins}
      data-logistical-hierarchy-unit-quantity={quantity}
      aria-label={i18n.t(
        'frontproductstream.logistical_hierarchy_unit.aria_label',
        { defaultValue: 'Logistical unit with gtin {{gtin}}', gtin }
      )}
    >
      <LogisticalHierarchyIcon
        packagingTypeId={getTypePackagingId(versionData)}
      />
      <div className="LogisticalHierarchyUnit__info">
        <LogisticalHierarchyUnitLabel {...props} />
        {renderQuantity()}
      </div>
      {!noActions && renderActions()}
    </div>
  );
};

export default LogisticalHierarchyUnit;
