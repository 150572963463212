import classNames from 'classnames';
import PropTypes from 'prop-types';

const PreviewTemplate = ({ previewImage, paragraphs, withStrongText }) => (
  <div className="FeatureModal__container">
    <div className="FeatureModal__leftPanel">
      <div className="FeatureModal__preview">
        <img src={previewImage} alt="" />
      </div>
    </div>
    <div className="FeatureModal__rightPanel">
      {paragraphs.map((text, index) => (
        <p
          key={text}
          className={classNames(
            'FeatureModal__text',
            withStrongText && index === 1 && 'FeatureModal__text--strong'
          )}
        >
          {text}
        </p>
      ))}
    </div>
  </div>
);

PreviewTemplate.propTypes = {
  previewImage: PropTypes.node.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  withStrongText: PropTypes.bool,
};

export default PreviewTemplate;
