import classNames from 'classnames';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  SERVICE_CORE,
  SERVICE_ETL,
  SERVICE_MEDIA,
  SERVICE_PRODUCT,
  SERVICE_SALMON,
  SERVICE_TRANSACTION,
} from 'constants/user';
import {
  hasAutoAcceptShareCreate as hasAutoAcceptShareCreateFeature,
  hasAutoAcceptShareUpdate as hasAutoAcceptShareUpdateFeature,
} from 'core/api/organization-settings';
import {
  getOrganizationSettings,
  isRetailer as isUserRetailer,
} from 'core/api/user';
import { selectIsLoggedAs, selectUser } from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import moment from 'utils/moment';

import {
  HISTORY_TRANSACTION_ACCEPTED_ACTION,
  HISTORY_TRANSACTION_CONTRIBUTION_ACCEPT,
  HISTORY_TRANSACTION_DATA_MIGRATION,
  HISTORY_TRANSACTION_DELETED_ACTION,
  HISTORY_TRANSACTION_IMPORTED_BY,
  HISTORY_TRANSACTION_REFUSED_ACTION,
} from '../../constants';

import './diff-card.scss';
import DiffHeader from './header';
import { HistoryDiffLine } from './history-diff-line';
import { formatCause, isEmptyDiff } from './utils';

const KNOWN_TYPES = {
  ProductVersionLogisticalHierarchies: i18n.t('Logistical Hierarchies'),
  SharingUnit: i18n.t('Listing'),
};

const _getDiffType = (dataType) =>
  KNOWN_TYPES[dataType] || i18n.t('Product Information');

type Props = {
  groupedDiff: Map<string, any>;
  isRetailer?: boolean;
  hasAutoAcceptShareCreate?: boolean;
  hasAutoAcceptShareUpdate?: boolean;
  loggedAs?: boolean;
  getDiffType?: (dataType?: string) => any;
};

const ProductHistoryDiffCard = (props: Props) => {
  const user = useSelector(selectUser);
  const loggedAs = useSelector(selectIsLoggedAs) || false;
  const organizationSetting = getOrganizationSettings(user);

  const {
    groupedDiff,
    isRetailer = isUserRetailer(user),
    hasAutoAcceptShareUpdate = hasAutoAcceptShareUpdateFeature(
      organizationSetting
    ),
    hasAutoAcceptShareCreate = hasAutoAcceptShareCreateFeature(
      organizationSetting
    ),
    getDiffType = _getDiffType,
  } = props;

  const computeAuthor = (diff, defaultAuthor) => {
    // Special award. We need this one since that user is used by @pserpy and it's orga is being changed
    // to different organizations. In case it's no longer in the same orga, the name is removed and is displayed as
    // the retailer...
    if (diff.getIn(['user', 'id']) === SERVICE_SALMON.id) {
      return SERVICE_SALMON.name;
    }
    const firstname = diff.getIn(['user', 'firstname']);
    const lastname = diff.getIn(['user', 'lastname']);
    const organization = diff.getIn(['user', 'organization', 'nameLegal']);
    const names = [firstname, lastname].filter((e) => e);
    if (names.length > 0) {
      return names.join(' ');
    }
    if (organization) {
      return organization;
    }
    return defaultAuthor;
  };

  const computeAuthorModificationsManufacturer = (diff) => {
    const userIdsImport = [
      SERVICE_PRODUCT.id,
      SERVICE_CORE.id,
      SERVICE_ETL.id,
      SERVICE_TRANSACTION.id,
      SERVICE_MEDIA.id,
    ];
    const userId = diff.getIn(['user', 'id']);
    const count = diff.get('diff').size;
    const entityType = getDiffType(diff.get('dataType'));
    const plural = count > 1;

    let author;
    let modifications;

    // Compute the author
    author = computeAuthor(diff, i18n.t('The retailer'));

    const status = diff.getIn(['cause', 'action']);
    const extraStatus = diff.getIn(['cause', 'extra', 0, 'action']);

    if (status === HISTORY_TRANSACTION_REFUSED_ACTION) {
      modifications = plural
        ? i18n.t('{{ count }} refused modifications', { count })
        : i18n.t('{{ count }} refused modification', { count });
    } else if (status === HISTORY_TRANSACTION_DELETED_ACTION) {
      modifications = plural
        ? i18n.t('{{ count }} deletions', { count })
        : i18n.t('{{ count }} deletion', { count });
    } else if (
      [
        HISTORY_TRANSACTION_ACCEPTED_ACTION,
        HISTORY_TRANSACTION_CONTRIBUTION_ACCEPT,
      ].includes(status)
    ) {
      modifications = plural
        ? i18n.t('{{ count }} accepted modifications', { count })
        : i18n.t('{{ count }} accepted modification', { count });
    } else if (status === HISTORY_TRANSACTION_DATA_MIGRATION) {
      modifications = plural
        ? i18n.t('{{ count }} maintenance operations', { count })
        : i18n.t('{{ count }} maintenance operation', { count });
    } else if (status === HISTORY_TRANSACTION_IMPORTED_BY) {
      const firstname = diff.getIn(['cause', 'user', 'firstname']);
      const lastname = diff.getIn(['cause', 'user', 'lastname']);
      const names = [firstname, lastname].filter((e) => e);
      if (names.length > 0) {
        author = names.join(' ');
        modifications = plural
          ? i18n.t('{{ count }} modifications imported', { count })
          : i18n.t('{{ count }} modification imported', { count });
      }
    } else if (extraStatus === HISTORY_TRANSACTION_IMPORTED_BY) {
      const firstname = diff.getIn(['cause', 'extra', 0, 'user', 'firstname']);
      const lastname = diff.getIn(['cause', 'extra', 0, 'user', 'lastname']);
      const names = [firstname, lastname].filter((e) => e);
      if (names.length > 0) {
        author = names.join(' ');
      }
      modifications = plural
        ? i18n.t('{{ count }} modifications imported', { count })
        : i18n.t('{{ count }} modification imported', { count });
    } else if (userIdsImport.includes(userId)) {
      // Special case where we do not want to display "The retailer" but nothing
      author = null;
      modifications = plural
        ? i18n.t('{{ count }} modifications imported', { count })
        : i18n.t('{{ count }} modification imported', { count });
    }

    if (!modifications) {
      modifications = plural
        ? i18n.t('{{ count }} modifications done', { count })
        : i18n.t('{{ count }} modification done', { count });
    }

    return { author, modifications, entityType, status };
  };

  const computeAuthorModificationsRetailer = (
    diff,
    _hasAutoAcceptShareCreate,
    _hasAutoAcceptShareUpdate
  ) => {
    const count = diff.get('diff').size;
    const entityType = getDiffType(diff.get('dataType'));
    const plural = count > 1;

    let status;
    let modifications;

    // Compute the author
    const author = computeAuthor(diff, i18n.t('The supplier'));

    // Logic for formatting
    status = diff.getIn(['cause', 'action']);
    if (status === HISTORY_TRANSACTION_REFUSED_ACTION) {
      modifications = plural
        ? i18n.t('{{ count }} refused modifications', { count })
        : i18n.t('{{ count }} refused modification', { count });
    } else if (status === HISTORY_TRANSACTION_DATA_MIGRATION) {
      modifications = plural
        ? i18n.t('{{ count }} maintenance operations', { count })
        : i18n.t('{{ count }} maintenance operation', { count });
    } else if (_hasAutoAcceptShareUpdate || _hasAutoAcceptShareCreate) {
      // old archives could not be stamped therefore we fallback on accept
      status = null;
      modifications = plural
        ? i18n.t('{{ count }} modifications done', { count })
        : i18n.t('{{ count }} modification done', { count });
    } else {
      status = HISTORY_TRANSACTION_ACCEPTED_ACTION;
      modifications = plural
        ? i18n.t('{{ count }} accepted modifications', { count })
        : i18n.t('{{ count }} accepted modification', { count });
    }
    return { author, modifications, entityType, status };
  };

  const Contributor = ({ diff }) => {
    const status = diff.getIn(['cause', 'action']);
    if (status !== HISTORY_TRANSACTION_CONTRIBUTION_ACCEPT) {
      return null;
    }

    let contributorNameLegal = diff.getIn([
      'cause',
      'contribution',
      'contributor',
      'nameLegal',
    ]);
    if (!contributorNameLegal) {
      contributorNameLegal = diff.getIn(['contributor', 'nameLegal']);
    }
    return (
      <span>
        {' '}
        {i18n.t('from')}{' '}
        <span className="ProductHistoryDiffCard__sentenceBold ProductHistoryDiffCard__contributorName">
          {contributorNameLegal}
        </span>
      </span>
    );
  };

  const Author = ({ author }) => {
    if (!author) {
      return null;
    }
    return (
      <span>
        {' '}
        {i18n.t('by')}{' '}
        <span className="ProductHistoryDiffCard__sentenceBold ProductHistoryDiffCard__username">
          {author}
        </span>
      </span>
    );
  };

  const EntityType = ({ entityType }) => {
    if (!entityType) {
      return null;
    }
    return (
      <span>
        {' '}
        {i18n.t('on')}{' '}
        <span className="ProductHistoryDiffCard__sentenceBold ProductHistoryDiffCard__entityType">
          {entityType}
        </span>
      </span>
    );
  };

  const Intro = ({ diff }) => {
    const date = moment.utc(diff.get('date')).local();
    const hour = date.format('LT');

    let authorModifications;

    if (isRetailer) {
      authorModifications = computeAuthorModificationsRetailer(
        diff,
        hasAutoAcceptShareCreate,
        hasAutoAcceptShareUpdate
      );
    } else {
      authorModifications = computeAuthorModificationsManufacturer(diff);
    }

    return (
      <div className="alk-flex alk-flex-space-between">
        <p className="ProductHistoryDiffCard__sentence">
          <span
            className={classNames({
              ProductHistoryDiffCard__sentenceColored: true,
              [`ProductHistoryDiffCard__sentenceColored--${authorModifications.status}`]:
                !!authorModifications.status,
            })}
          >
            {authorModifications.modifications}
          </span>
          <EntityType entityType={authorModifications.entityType} />
          <Author author={authorModifications.author} />
          <Contributor diff={diff} />
          <span>
            {' '}
            {i18n.t('at', { context: 'date' })}{' '}
            <span className="ProductHistoryDiffCard__sentenceBold">{hour}</span>
          </span>
        </p>
        {loggedAs && (
          <div className="ProductHistoryDiffCard__id">
            ((admin) transaction id: {diff.get('id')})
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="ProductHistoryDiffCard card">
      <h2>{groupedDiff.get('formattedDate')}</h2>
      {groupedDiff.get('diffs').map((diff) => {
        const count = diff.get('diff').size;
        if (count === 0) {
          return null;
        }
        return (
          <div className="ProductHistoryDiffCard__changes" key={diff.get('id')}>
            <Intro diff={diff} />
            <DiffHeader />
            {diff
              .get('diff')
              .filter(isEmptyDiff)
              .map((item) => (
                <HistoryDiffLine
                  cause={formatCause(diff.getIn(['cause']))}
                  key={`diff-header-${uuid()}`}
                  diff={item}
                />
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default ProductHistoryDiffCard;
export { ProductHistoryDiffCard };
