import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';

import { selectAvailableFields } from 'modules/view-as/selectors/fields';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { selectEnrichedData } from 'utils/selectors/product-data';

import { resetValidation } from '../../actions/common';
import { applyRulesForViewAsRecipients } from '../../actions/view-as';
import { selectValidationResultsForViewAsSettings } from '../../selectors';
import { ValidationResults } from '../results';

import './retailer-dashboard.scss';

const mapStateToProps = (state) => ({
  validationData: selectValidationResultsForViewAsSettings(state),
  fullProductData: selectEnrichedData(state).data,
  availableFields: selectAvailableFields(state),
});

const mapDispatchToProps = {
  applyRulesForViewAsRecipients,
  resetValidation,
};

const dataQualitySectionTitle = i18n.t(
  'frontproductstream.retailer_product_dashboard.data_quality_section.title',
  { defaultValue: 'Data quality control' }
);

export class RetailerValidationDashboard extends PureComponent {
  // TODO: Remove this component once we are sure it is unused.
  // It seems to be only accessible by the routes.productDashboard url on a
  // retailer product page. There seems to be nothing that the user can
  // interact with to get here.

  static propTypes = {
    productVersionId: PropTypes.number.isRequired,
    validationData: PropTypes.object,
    fullProductData: PropTypes.object,
    availableFields: ImmutablePropTypes.map,
    actions: PropTypes.shape({
      applyRulesForViewAsRecipients: PropTypes.func.isRequired,
      resetValidation: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.validate();
  }

  componentDidUpdate(prevProps) {
    const { productVersionId, fullProductData } = this.props;
    if (
      prevProps.productVersionId !== productVersionId ||
      prevProps.fullProductData !== fullProductData
    ) {
      this.validate();
    }
  }

  validate() {
    const { actions } = this.props;
    actions.resetValidation();
    actions.applyRulesForViewAsRecipients();
  }

  renderPlaceholder() {
    return (
      <div className="RetailerValidationDashboard RetailerValidationDashboard--loading">
        <h2 className="RetailerValidationDashboard__title">
          {dataQualitySectionTitle}
        </h2>
        <Spinner medium />
      </div>
    );
  }

  render() {
    const { validationData, availableFields } = this.props;
    if (validationData.size === 0) {
      // Display a loading placeholcer.
      return this.renderPlaceholder();
    }
    return (
      <div className="RetailerValidationDashboard">
        <h2 className="RetailerValidationDashboard__title">
          {dataQualitySectionTitle}
        </h2>
        <ValidationResults
          results={validationData}
          availableFields={availableFields}
          hidePrefix
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(RetailerValidationDashboard);
