import { BRAND_NO_BRAND, BRAND_PRODUCT_BRAND } from 'constants/brands';

export const reorderBrandTypes = (brands) => {
  const productBrand = brands
    .filter((brand) => brand.id === BRAND_PRODUCT_BRAND.id)
    .map((brand) => ({
      ...brand,
      label: BRAND_PRODUCT_BRAND.label,
    }));
  const noBrand = brands
    .filter((brand) => brand.id === BRAND_NO_BRAND.id)
    .map((brand) => ({
      ...brand,
      label: BRAND_NO_BRAND.label,
    }));
  return [
    ...productBrand,
    ...noBrand,
    ...brands.filter(
      (brand) =>
        brand.id !== BRAND_PRODUCT_BRAND.id && brand.id !== BRAND_NO_BRAND.id
    ),
  ];
};
