import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { getTargetMarketId } from 'core/api/productversion';
import { selectHasMultiTargetMarketFeature } from 'modules/feature-flag/selectors';
import {
  selectLocales,
  selectLocalesByTargetMarket,
} from 'reducers/user/selectors';

export const PRODUCT_VERSION_BULK_EDIT_STORE_KEY = 'productVersionBulkEdit';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][PRODUCT_VERSION_BULK_EDIT_STORE_KEY];
}

export const selectEntity = createSelector(
  selectState,
  (state) => state.entity
);

export const selectDisplayGroups = createSelector(
  selectState,
  (state) => state.displayGroups
);

export const selectSelectedDisplayGroups = createSelector(
  selectState,
  (state) => state.selectedDisplayGroups
);

export const selectSaving = createSelector(
  selectState,
  (state) => state.saving
);

export const selectErrors = createSelector(
  selectState,
  (state) => state.errors
);

export const selectSavedCount = createSelector(
  selectState,
  (state) => state.savedCount
);

export const selectTotalCount = createSelector(
  selectState,
  (state) => state.totalCount
);

export const selectIsOpen = createSelector(
  selectState,
  (state) => state.isOpen
);

export const selectProductVersions = createSelector(
  selectState,
  (state) => state.productVersions
);

const selectSelectedTargetMarketId = createSelector(
  selectProductVersions,
  (pvs) => getTargetMarketId(pvs.get(0))
);

export const selectLanguages = createSelector(
  selectHasMultiTargetMarketFeature,
  selectSelectedTargetMarketId,
  selectLocalesByTargetMarket,
  selectLocales,
  (
    hasMultiTargetMarket,
    selectedTargetMarketId,
    localesByTargetMarket,
    supportedLocales
  ) =>
    hasMultiTargetMarket && selectedTargetMarketId
      ? localesByTargetMarket.get(String(selectedTargetMarketId)).toJS()
      : supportedLocales.toJS()
);
