import { func, shape, string } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { separateActions } from 'utils/redux';

import { openModal } from '../../actions';
import { getModalName } from '../../utils';
import PromoteProductsModal from '../modal';

const mapStateToProps = (state) => ({
  buttonText: getModalName(state),
});
const mapDispatchToProps = {
  openModal,
};

export class PromoteProductsButton extends PureComponent {
  static propTypes = {
    buttonText: string.isRequired,
    actions: shape({
      openModal: func.isRequired,
    }).isRequired,
  };

  onClick = () => {
    const { actions } = this.props;
    actions.openModal();
  };

  render() {
    const { buttonText } = this.props;
    return (
      <>
        <Button
          id="promote-products"
          content={buttonText}
          onClick={this.onClick}
          secondary
        />
        <PromoteProductsModal />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(PromoteProductsButton);
