import classNames from 'classnames';
import PropTypes from 'prop-types';

import i18n from 'utils/i18n';

import { Statuses } from '../constants';

import './status-badge.scss';

const PriceTemplateStatusBadge = ({
  status,
  isTemplatePublishing,
  hasFailedPublication,
  isTarget,
}) => {
  if (!Statuses[status]) {
    return null;
  }
  return (
    <span
      className={classNames('PriceTemplateStatusBadge', {
        [`PriceTemplateStatusBadge--${Statuses[status].id}`]: true,
      })}
    >
      {Statuses[status].name}
      {!isTarget && isTemplatePublishing && (
        <em>
          {' - '}
          {i18n.t(
            'frontproductstream.sharingunit_tariff.status_badge_publishing.text',
            { defaultValue: 'publishing' }
          )}
        </em>
      )}
      {!isTarget && hasFailedPublication && (
        <em>
          {' - '}
          {i18n.t(
            'frontproductstream.sharingunit_tariff.status_badge_publishing_failed.text',
            { defaultValue: 'publication failed' }
          )}
        </em>
      )}
    </span>
  );
};

PriceTemplateStatusBadge.propTypes = {
  status: PropTypes.string,
  isTemplatePublishing: PropTypes.bool,
  hasFailedPublication: PropTypes.bool,
  isTarget: PropTypes.bool,
};

export default PriceTemplateStatusBadge;
