import { flow, set } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import {
  notificationClearReset,
  notificationDisplayed,
  notificationHidden,
  notificationRemove,
} from 'actions/notification';

import { Notification } from './notification';
import './notification.scss';

const mapStateToProps = (state) => ({
  notification: state.notification,
});

class ConnectedNotification extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    context: PropTypes.string,
    absolute: PropTypes.bool,
  };

  state = {
    ready: true,
    current: { message: '' },
  };

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, notification } = this.props;
    const { ready } = this.state;

    // Displayed a new message, remove it
    if (ready === false && prevState.ready === true) {
      dispatch(notificationRemove(0));
    }

    // Component is ready again. Push a new notification or clear.
    if (ready === true && prevState.ready === false) {
      // Get second notification
      if (this.props.notification.messages.length > 0) {
        const contextualMessage = this.getNextContextualMessage(
          this.props.notification.messages
        );
        if (contextualMessage) {
          this.updateNotification(contextualMessage);
        }
      } else {
        dispatch(notificationHidden());
      }
    }

    // New notification is pushed and component is ready to display a notification
    if (
      prevState.ready &&
      notification.messages.length > 0 &&
      prevProps.notification.messages.length !== notification.messages.length
    ) {
      const contextualMessage = this.getNextContextualMessage(
        notification.messages
      );
      if (contextualMessage) {
        this.updateNotification(contextualMessage);
        dispatch(notificationDisplayed());
      }
    }

    // Reset if needed.
    if (notification.clear && !prevProps.notification.clear) {
      this.clearTimer();
      this.onDiscardNotification();
      dispatch(notificationClearReset());
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  onDiscardNotification = () => {
    this.setState(set(['ready'], true));
  };

  onShowDetails = () => {
    this.clearTimer();
  };

  getNextContextualMessage(messages) {
    return messages.find((msg) => msg.context === this.props.context);
  }

  clearTimer() {
    window.clearTimeout(this._timer);
  }

  updateNotification(current) {
    this.setState(flow(set(['ready'], false), set(['current'], current)));
    if (!current.sticky) {
      this.clearTimer();
      this._timer = window.setTimeout(
        () => this.setState(set(['ready'], true)),
        5000
      );
    }
  }

  render() {
    const { context, absolute } = this.props;
    const { current, ready } = this.state;
    return (
      <CSSTransition
        in={!ready}
        timeout={400}
        classNames="NotificationAnimation"
        mountOnEnter
        unmountOnExit
      >
        <Notification
          absolute={absolute}
          context={context}
          error={current.error}
          message={current.message}
          onDiscard={this.onDiscardNotification}
          onShowDetails={this.onShowDetails}
          sticky={current.sticky}
          type={current.style}
        />
      </CSSTransition>
    );
  }
}

export default connect(mapStateToProps)(ConnectedNotification);
export { ConnectedNotification, Notification };
