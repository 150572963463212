import { List, Map, fromJS } from 'immutable';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';

import Image from 'components/ui/image';
import { isManufacturer } from 'core/api/user';
import { selectUser } from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { formatMedia } from 'utils/links';
import { separateActions } from 'utils/redux';

import {
  dispatchProducts,
  updateExportFormat,
  updateProductsToExport,
} from '../../../../../../actions';
import {
  selectExportFileFormats,
  selectExportInProgress,
  selectPagination,
  selectProductMap,
  selectSelectedMap,
} from '../../../../../../selectors';

const mapStateToProps = createStructuredSelector({
  productMap: selectProductMap,
  selectedProducts: selectSelectedMap,
  totalProducts: (state) => selectPagination(state).get('total'),
  isExportInProgress: selectExportInProgress,
  isManufacturerUser: (state) => isManufacturer(selectUser(state)),
  fileFormats: selectExportFileFormats,
  user: (state) => state.user,
  recipients: (state) => state.recipients,
});

const mapDispatchToProps = {
  updateExportFormat,
  updateProductsToExport,
  dispatchProducts,
};

export class CatalogExportModalV2Step1 extends PureComponent {
  static propTypes = {
    productMap: ImmutablePropTypes.map.isRequired,
    selectedProducts: ImmutablePropTypes.map.isRequired,
    totalProducts: PropTypes.number.isRequired,
    fileFormats: PropTypes.array,
    onPickExport: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      updateExportFormat: PropTypes.func.isRequired,
      updateProductsToExport: PropTypes.func.isRequired,
      dispatchProducts: PropTypes.func.isRequired,
    }),
    recipients: ImmutablePropTypes.map.isRequired,
  };

  static defaultProps = {
    totalProducts: 0,
    fileFormats: [],
  };

  DEFAULT_MAX_TOTAL_PRODUCTS = 1000;

  groupExports = memoize(
    (fileFormats, recipients) =>
      recipients
        .get('list', List())
        .push(
          fromJS({
            group: { id: 'alkemics', name: 'Alkemics' },
            id: 7,
            isActive: true,
            settings: {
              specific_exports: [
                {
                  label: i18n.t('Alkemics XLSX'),
                  name: 'xlsx',
                  type: 'xlsx',
                },
                { label: i18n.t('Alkemics XML'), name: 'xml2', type: 'xml' },
                {
                  label: i18n.t('Alkemics JSON'),
                  name: 'json',
                  type: 'json',
                },
                {
                  label: i18n.t('Alkemics CSV'),
                  name: 'csv_advanced',
                  type: 'csv',
                },
              ],
            },
          }) // add fake Alkemics group for generic exports
        )
        .groupBy(
          (recipient) => recipient.get('group') || fromJS({ name: 'no-group' })
        ) // group by group
        .mapKeys((group, groupRecipients) =>
          group.set('isActive', groupRecipients.getIn([0, 'isActive'], true))
        ) // set isActive in the group, the value should be the same for all recipients of a given group
        .map((recipientGroup) =>
          recipientGroup.flatMap(
            // flatten
            (recipient) =>
              recipient
                .getIn(['settings', 'specific_exports'], List()) // pick only export formats
                .filter((exp) =>
                  fileFormats.some((f) => f.value === exp.get('name'))
                ) // filtering exports not found in fileFormats
                .map((exp) =>
                  fromJS(
                    fileFormats.find((f) => f.value === exp.get('name'), Map())
                  )
                    .merge(exp)
                    .set('targetOrgId', recipient.get('id'))
                    .set(
                      'isPayingRecipient',
                      recipient.get('isPayingRecipient', false)
                    )
                    .set(
                      'isPaidRecipient',
                      recipientGroup.get('relation_status') === 1
                    )
                ) // enriched with attributes coming from fileFormats (referential) and targetOrgId
                .map((exp) => {
                  if (recipient.get('id') === 7) {
                    return exp;
                  }
                  return exp.set(
                    'extraOptions',
                    exp
                      .get('extraOptions', Map())
                      .set('target_org_id', exp.get('targetOrgId'))
                  );
                }) // add target_org_id for non-alkemics (generic) exports
          )
        )
        .filter((groupExports) => groupExports.count() > 0) // keep only recipients which have at least one xlsx export
  );

  createOnSelectExport = (exportFormat) => () => {
    const { selectedProducts, productMap, actions } = this.props;
    const selectedFormat = exportFormat.toJS();
    actions.updateExportFormat(selectedFormat);

    const selectedGTINs = selectedProducts
      .filter((v) => v)
      .map((v, k) => productMap.get(k))
      .valueSeq()
      .map((product) =>
        product.getIn(['specializes', 'isIdentifiedBy', 0, 'reference'])
      )
      .toJS();

    const fullSelectedProducts = selectedGTINs
      .filter((v) => v)
      .map((gtin) =>
        productMap.find(
          (p) =>
            p.getIn(['specializes', 'isIdentifiedBy', 0, 'reference']) === gtin
        )
      );

    const isSharedWithRecipient = (product) =>
      selectedFormat.targetOrgId === 7 ||
      product
        .getIn(['alkemics', 'recipients'], List())
        .some(
          (recipient) =>
            recipient.getIn(['targetOrganization', 'id']) ===
              selectedFormat.targetOrgId &&
            recipient.getIn(['publicationSummary', 'toggled'], false)
        );
    const hasRulesFailingForRecipient = (product) =>
      selectedFormat.targetOrgId !== 7 &&
      product
        .getIn(['alkemics', 'recipients'], List())
        .some(
          (recipient) =>
            recipient.getIn(['targetOrganization', 'id']) ===
              selectedFormat.targetOrgId &&
            recipient.getIn(['validation', 'rules_by_status', 'failed'], List())
              .size > 0
        );
    const hasBlockingRulesFailingForRecipient = (product) =>
      selectedFormat.targetOrgId !== 7 &&
      product
        .getIn(['alkemics', 'recipients'], List())
        .some(
          (recipient) =>
            recipient.getIn(['targetOrganization', 'id']) ===
              selectedFormat.targetOrgId &&
            recipient.getIn(
              ['validation', 'rules_by_status', 'failedBlocking'],
              List()
            ).size > 0
        );

    const sharedValidProducts = fullSelectedProducts.filter(
      (p) =>
        isSharedWithRecipient(p) &&
        !hasRulesFailingForRecipient(p) &&
        !hasBlockingRulesFailingForRecipient(p)
    );
    const sharedInvalidProducts = fullSelectedProducts.filter(
      (p) =>
        isSharedWithRecipient(p) &&
        hasRulesFailingForRecipient(p) &&
        !hasBlockingRulesFailingForRecipient(p)
    );
    const blockedProducts = fullSelectedProducts.filter(
      (p) => isSharedWithRecipient(p) && hasBlockingRulesFailingForRecipient(p)
    );
    const unsharedProducts = fullSelectedProducts.filter(
      (p) => !isSharedWithRecipient(p)
    );

    actions.dispatchProducts({
      sharedValidProducts,
      sharedInvalidProducts,
      blockedProducts,
      unsharedProducts,
    });

    actions.updateProductsToExport(
      sharedValidProducts
        .concat(sharedInvalidProducts)
        .map((product) => product.getIn(['product_key', 'id']))
    );
    this.props.onPickExport();
  };

  renderErrorMessage = (mustPay, tooManyProducts, maxTotalProducts) => {
    if (mustPay) {
      return (
        <div className="CatalogExportModalV2_ExportWarning">
          <i className="mdi mdi-alert" />
          {i18n.t('Group not activated')}
        </div>
      );
    }
    if (tooManyProducts) {
      return (
        <div className="CatalogExportModalV2_ExportWarning">
          <i className="mdi mdi-alert" />
          {i18n.t("You can't export more than {{maxTotalProducts}} products", {
            maxTotalProducts,
          })}
        </div>
      );
    }
    return null;
  };

  renderGroup = (groupExports, group) => {
    const { selectedProducts, totalProducts } = this.props;
    const totalProductsToExport = selectedProducts.size || totalProducts;
    return (
      <div
        key={`group-key-${group && group.get('name')}`}
        className="CatalogExportModalV2__Group"
      >
        {group ? (
          <div className="CatalogExportModalV2__GroupHeader">
            <Image
              src={formatMedia(`/group/picture/${group.get('id')}/logo.png`)}
              className="CatalogExportModalV2__GroupLogo"
            />
            <div className="CatalogExportModalV2__GroupLabel">
              {group.get('name')}
            </div>
          </div>
        ) : null}
        {groupExports.map((ef) => {
          const maxTotalProducts = ef.get(
            'maxTotalProducts',
            this.DEFAULT_MAX_TOTAL_PRODUCTS
          );
          const tooManyProducts =
            maxTotalProducts > 0 && totalProductsToExport > maxTotalProducts;
          const mustPay =
            ef.get('isPayingRecipient') && !ef.get('isPaidRecipient');
          const canExport = !mustPay && !tooManyProducts;
          return (
            <div
              key={`recipient-${ef.get('label')}`}
              className="CatalogExportModalV2__Export"
            >
              <div className="CatalogExportModalV2__ExportLabel">
                {ef.get('label')}
              </div>
              {this.renderErrorMessage(
                mustPay,
                tooManyProducts,
                maxTotalProducts
              )}
              <Button
                content={i18n.t('Select')}
                secondary
                onClick={this.createOnSelectExport(ef)}
                className="CatalogExportModalV2__ExportButton"
                disabled={!canExport}
              />
            </div>
          );
        })}
      </div>
    );
  };

  render = () => {
    const { fileFormats, recipients } = this.props;
    const groupedExports = this.groupExports(fileFormats, recipients);

    return (
      <div>
        {groupedExports
          .map((groupExports, group) => this.renderGroup(groupExports, group))
          .toList()}
      </div>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(CatalogExportModalV2Step1);
