import { Map } from 'immutable';
import { isEmpty, isEqual } from 'lodash';
import memoize from 'memoize-one';

import { PRODUCTS_NOT_DUPLICATED } from 'constants/duplicated-products';
import { REFUSED_ASSIGNATION_STATUS } from 'constants/filters/assignation-status';
import { packCasePallet as packCasePalletFilter } from 'constants/filters/displayUnits';
import { archived as archivedProductFilter } from 'constants/filters/lifeCycle';
import { hasLegacyTextile } from 'core/api/organization-settings';
import {
  getOrganizationSettings,
  isManufacturer,
  isPrivateLabel,
} from 'core/api/user';

import {
  assignationStatusFilter,
  displayUnitsFilter,
  duplicatedProductsFilter,
  filters,
  lifecycleFilter,
} from '../../constants';

function parseQuery(query) {
  let value = query;
  let not = false;
  if (typeof value === 'string' && value.indexOf('-') === 0) {
    value = value.substring(1);
    not = true;
  }
  return {
    value,
    not,
  };
}

export function buildFiltersFromQuery({
  filterQueryValues = [],
  filterList,
  filterKey,
  selectFilterValue,
  selectFilterLabel,
  selectFilterData,
  singleValue = false,
}) {
  return new Promise((resolve) => {
    const filtersFromQuery = filterQueryValues.reduce((acc, filterValue) => {
      const { value, not } = parseQuery(filterValue);
      const filter = filterList.find(
        (f) =>
          selectFilterValue(f).toString() === value ||
          (Map.isMap(selectFilterValue(f)) &&
            isEqual(selectFilterValue(f).toJS(), value))
      );
      if (filter) {
        acc.push({
          key: filterKey,
          value: selectFilterValue(filter, { not }),
          label: selectFilterLabel(filter, { not }),
          add: true,
          data: selectFilterData(filter, { not }),
          not,
          singleValue,
        });
      }
      return acc;
    }, []);
    if (filtersFromQuery.length) {
      resolve(filtersFromQuery);
    }
  });
}

export const addArchivedProductsFilter = ({
  user,
  withArchivedProducts,
  selectedFilters,
  bypass = false,
  force = false,
}) => {
  if (bypass) {
    return selectedFilters;
  }
  let newSelectedFilters = selectedFilters;
  if (force) {
    return newSelectedFilters
      .filterNot((filter) => filter.get('key') === lifecycleFilter.key)
      .push(
        Map({
          key: lifecycleFilter.key,
          value: archivedProductFilter.id,
        })
      );
  }
  if (lifecycleFilter.isAvailable({ user }) && !withArchivedProducts) {
    newSelectedFilters = newSelectedFilters.filterNot(
      (filter) =>
        filter.get('key') === lifecycleFilter.key &&
        filter.get('value').toString() === archivedProductFilter.id.toString()
    );
    if (
      newSelectedFilters
        .filter((filter) => filter.get('key') === lifecycleFilter.key)
        .isEmpty()
    ) {
      newSelectedFilters = newSelectedFilters.push(
        Map({
          key: lifecycleFilter.key,
          not: true,
          value: archivedProductFilter.id,
        })
      );
    }
  }
  return newSelectedFilters;
};

export const addDuplicatedProductsFilter = ({
  user,
  hasDuplicatedProducts,
  selectedFilters,
  bypass = false,
  force = false,
}) => {
  if (bypass) {
    return selectedFilters;
  }
  let newSelectedFilters = selectedFilters;
  if (force) {
    return newSelectedFilters
      .filterNot((filter) => filter.get('key') === duplicatedProductsFilter.key)
      .push(
        Map({
          key: duplicatedProductsFilter.key,
          value: PRODUCTS_NOT_DUPLICATED.id,
        })
      );
  }
  if (
    duplicatedProductsFilter.isAvailable({ user }) &&
    !hasDuplicatedProducts
  ) {
    newSelectedFilters = newSelectedFilters.filterNot(
      (filter) =>
        filter.get('key') === duplicatedProductsFilter.key &&
        filter.get('value').toString() === PRODUCTS_NOT_DUPLICATED.id.toString()
    );
    if (
      newSelectedFilters
        .filter((filter) => filter.get('key') === duplicatedProductsFilter.key)
        .isEmpty()
    ) {
      newSelectedFilters = newSelectedFilters.push(
        Map({
          key: duplicatedProductsFilter.key,
          not: true,
          value: PRODUCTS_NOT_DUPLICATED.id,
        })
      );
    }
  }
  return newSelectedFilters;
};

export const addAllTypesFilter = ({
  user,
  withAllTypes,
  selectedFilters,
  bypass = false,
}) => {
  if (bypass) {
    return selectedFilters;
  }
  let newSelectedFilters = selectedFilters;
  if (displayUnitsFilter.isAvailable({ user }) && !withAllTypes) {
    newSelectedFilters = newSelectedFilters.filterNot(
      (filter) =>
        filter.get('key') === displayUnitsFilter.key &&
        filter.get('value').toString() === packCasePalletFilter.id.toString()
    );
    if (
      newSelectedFilters
        .filter((filter) => filter.get('key') === displayUnitsFilter.key)
        .isEmpty()
    ) {
      newSelectedFilters = newSelectedFilters.push(
        Map({
          key: displayUnitsFilter.key,
          not: true,
          value: packCasePalletFilter.id,
        })
      );
    }
  }
  return newSelectedFilters;
};

export const addRefusedAssignationFilter = ({
  user,
  withRefusedAssignations,
  selectedFilters,
  bypass = false,
}) => {
  let newSelectedFilters = selectedFilters;
  if (bypass) {
    return newSelectedFilters;
  }
  if (
    assignationStatusFilter.isAvailable({ user }) &&
    !withRefusedAssignations
  ) {
    newSelectedFilters = newSelectedFilters.filterNot(
      (filter) =>
        filter.get('key') === assignationStatusFilter.key &&
        filter.get('value').toString() ===
          REFUSED_ASSIGNATION_STATUS.id.toString()
    );
    if (
      newSelectedFilters
        .filter((filter) => filter.get('key') === assignationStatusFilter.key)
        .isEmpty()
    ) {
      newSelectedFilters = newSelectedFilters.push(
        Map({
          key: assignationStatusFilter.key,
          not: true,
          value: REFUSED_ASSIGNATION_STATUS.id,
        })
      );
    }
  }
  return newSelectedFilters;
};

export const hasTextileVariantsFilter = (user) =>
  hasLegacyTextile(getOrganizationSettings(user));

export const getFiltersCustomFieldMapping = memoize((user) => {
  const defaultWithCustomField = (opt) =>
    isManufacturer(opt.user) || isPrivateLabel(opt.user);
  const customFields = filters
    .filter(
      (filter) =>
        !filter.customQuery &&
        filter.customField &&
        (filter.withCustomField || defaultWithCustomField)({ user })
    )
    .reduce((acc, filter) => {
      acc[filter.key] = filter.customField;
      if (filter.extraKeys) {
        filter.extraKeys.forEach((key) => {
          acc[key] = filter.customField;
        });
      }
      return acc;
    }, {});
  return isEmpty(customFields) ? null : customFields;
});
