import copyToClipboard from 'clipboard-copy';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { notificationError, notificationSuccess } from 'actions/notification';

import { getAuditContextDatas } from '../../api';
import { AuditContext as AuditContextType } from '../../types';

interface Props {
  contextDatas: AuditContextType;
  auditID: number | string;
}

export const AuditContext = ({ contextDatas, auditID }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, startLoading] = useState(false);

  const getContextDatas = async () => {
    startLoading(true);

    const auditContextDatas = await getAuditContextDatas({
      auditId: auditID.toString(),
      contextId: contextDatas.id,
    });
    const product_data = auditContextDatas.data?.product_data || null;
    if (product_data) {
      const stringifiedDatas = JSON.stringify(product_data);
      copyToClipboard(stringifiedDatas);
      dispatch(notificationSuccess('Data copied to clipboard'));
    } else {
      dispatch(
        notificationError('No data found! Contact Kalitey for more details.')
      );
    }

    startLoading(false);
  };

  return (
    <div
      key={'key-audit-info-context-' + contextDatas.id}
      className="alk-flex alk-flex-row ProductAdminAudit__elementContext"
      data-testid="audit-context-datas"
      id="audit-context-datas"
    >
      <div className="alk-flex-1">{contextDatas.id}</div>
      <div className="alk-flex-1">{contextDatas.name}</div>
      <div className="alk-flex-1">
        <div className="ProductAdminAuditContext__workflowName">
          {contextDatas.workflow_name}
        </div>
      </div>
      <div className="alk-flex-1">
        {moment.utc(contextDatas.created_at).format('LLL')}
      </div>
      <Button
        id="product-admin-copy-audit-context-button"
        testid="copy-data-context-button"
        onClick={getContextDatas}
        displaySpinner={isLoading}
        small
        link
      >
        {!isLoading && <i className={`mdi mdi-content-copy`} />}
      </Button>
    </div>
  );
};
