import { Map } from 'immutable';
import { useState } from 'react';

import { Button } from '@alkem/react-ui-button';

import { Modal } from 'components/ui/modal';
import i18n from 'utils/i18n';

import { AssetV2 } from '../../../AssetV2';
import { AddMediaDropdown } from '../../../AssetV2/AddMediaDropdown';
import { AssetTypeV2, Field } from '../../../AssetV2/types';
import { areAssetReadOnly } from '../../../AssetV2/utils';
import AssetListButtonsBar from '../../../buttons-bar';

import './seeEditAllAsset.scss';

type SeeEditAllAssetProps = {
  onClose: () => void;
  items: AssetTypeV2[];
  productKeyId: number;
  recipients: Map<'id', number>[];
  statusIsPending: boolean;
  field: Field;
  onToggleArchived: () => void;
  updateEntities: () => void;
  entityId: number;
  isShowingExpiredMedia: boolean;
  expiredPictureCount: number;
};

export const SeeEditAllAsset = ({
  onClose,
  items,
  productKeyId,
  recipients,
  statusIsPending,
  field,
  onToggleArchived,
  entityId,
  isShowingExpiredMedia,
  updateEntities,
  expiredPictureCount,
}: SeeEditAllAssetProps) => {
  const [selectedMediaIds, setSelectedMediaIds] = useState<number[]>([]);

  const onMediaSelectionChange = (id: number) => {
    const selectedIndex = selectedMediaIds.findIndex((mId) => mId === id);

    if (selectedIndex === -1) {
      setSelectedMediaIds((mIds) => [...mIds, id]);

      return;
    }

    setSelectedMediaIds((mIds) => mIds.filter((_, i) => i !== selectedIndex));
  };

  const arePicturesReadOnly = areAssetReadOnly({ field, assetType: 'picture' });

  return (
    <Modal
      modalStyle="default"
      title={i18n.t(
        'frontproductstream.asset_list.see_and_edit_all_media.title',
        { defaultValue: 'Media' }
      )}
      onClose={onClose}
      onConfirm={onClose}
      closeButtonText={i18n.t(
        'frontproductstream.asset_list.see_and_edit_all_media.button',
        { defaultValue: 'Close' }
      )}
    >
      <div className="SeeEditAllAsset">
        <div className="SeeEditAllAsset__buttons">
          <AddMediaDropdown
            items={items}
            updateEntities={updateEntities}
            fieldActions={field.actions}
            productKeyId={productKeyId}
          />
          {expiredPictureCount > 0 && (
            <Button
              secondary
              onClick={onToggleArchived}
              content={
                isShowingExpiredMedia
                  ? i18n.t(
                      'frontproductstream.asset_list.hide_archived_button.label',
                      { defaultValue: 'Hide archived media' }
                    )
                  : i18n.t(
                      'frontproductstream.asset_list.show_archived_button.label',
                      { defaultValue: 'Display archived media' }
                    )
              }
            />
          )}
        </div>
        <AssetListButtonsBar
          productKeyId={productKeyId}
          pictureReadOnly={arePicturesReadOnly}
          statusIsPending={statusIsPending}
          selectedPictureIds={selectedMediaIds}
          updateEntities={updateEntities}
          disableDownloadPictures={!items.length}
          recipients={recipients}
        />
        <div className="SeeEditAllAsset__assets">
          {items.map((item) => (
            <AssetV2
              entityId={entityId}
              key={`${item.id}`}
              updateEntities={updateEntities}
              asset={item}
              field={field}
              isSelected={selectedMediaIds.includes(item.id)}
              onSelectChange={onMediaSelectionChange}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
