import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { MODULE_NAME } from './constants';

export const selectModuleState = (state) =>
  state[MODULE_REDUCER_KEY][MODULE_NAME];

export const selectLoading = createSelector(
  selectModuleState,
  (state) => state.loading
);

export const selectProcessing = createSelector(
  selectModuleState,
  (state) => state.processing
);

export const selectAssignationInfo = createSelector(
  selectModuleState,
  (state) => state.assignation
);

export const selectAssignationReference = createSelector(
  selectAssignationInfo,
  (state) => state.reference
);

export const selectAssignationTargetMarketId = createSelector(
  selectAssignationInfo,
  (state) => state.targetMarketId
);

export const selectAssignationInformationRequestOnly = createSelector(
  selectAssignationInfo,
  (state) => state.informationRequestsOnly
);

export const selectAssignationFromCatalog = createSelector(
  selectAssignationInfo,
  (state) => state.fromCatalog
);
