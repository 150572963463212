import { fromJS } from 'immutable';
import { isString } from 'lodash';

import { buyingUnitApiImmutable } from 'resources/buyingUnitApi';
import i18n from 'utils/i18n';
import { set } from 'utils/immutable';

export const priceRoundingRules = [
  { id: 'nearest', label: i18n.t('Round to the nearest'), option: 'nearest' },
  { id: 'down', label: i18n.t('Round down'), option: 'down' },
  { id: 'up', label: i18n.t('Round up'), option: 'up' },
];

export const priceRoundingDecimalPlaces = [
  { id: '2', label: '2', option: '2' },
  { id: '3', label: '3', option: '3' },
  { id: '4', label: '4', option: '4' },
];

export const priceRoundingRulesDefaultIndex = 0;
export const priceRoundingDecimalPlacesDefaultIndex = 2;

export const estimatePrices = async (
  priceWaterfall,
  taxInformation,
  roundingRule = priceRoundingRules[priceRoundingRulesDefaultIndex],
  decimalPlaces = priceRoundingDecimalPlaces[
    priceRoundingDecimalPlacesDefaultIndex
  ]
) => {
  let estimations;
  let error;

  let { version } = priceWaterfall.product;
  if (taxInformation) {
    version = {
      ...version,
      ...taxInformation.data,
      VATrate: taxInformation.VATRate,
    };
  }
  const updatedPriceWaterfall = set(priceWaterfall, 'product.version', version);

  try {
    const res = await buyingUnitApiImmutable.post(
      '/buyingunit/v3/pricewaterfall/estimate',
      {
        priceWaterfall: updatedPriceWaterfall,
        rounding_method: roundingRule.id,
        decimal_places: decimalPlaces.id,
      }
    );
    estimations = res.data;
  } catch (e) {
    error = e;
  }

  return { estimations, error };
};

export const estimatePricesBulk = async (
  priceWaterfallsToEstimate,
  roundingRule = priceRoundingRules[priceRoundingRulesDefaultIndex],
  decimalPlaces = priceRoundingDecimalPlaces[
    priceRoundingDecimalPlacesDefaultIndex
  ]
) => {
  let priceWaterfalls;
  let error;

  try {
    const { data } = await buyingUnitApiImmutable.post(
      '/buyingunit/v3/pricewaterfall/estimate/bulk',
      {
        price_waterfalls: priceWaterfallsToEstimate.toJS(),
        rounding_method: roundingRule.id,
        decimal_places: decimalPlaces.id,
      }
    );
    priceWaterfalls = fromJS(data);
  } catch (e) {
    error = e;
  }

  return { priceWaterfalls, error };
};

export const trimZeros = (n) => {
  if (!isString(n)) {
    return n;
  }

  let trimmed = n.replace(new RegExp('^0+', 'g'), '');
  if (!trimmed.includes('.')) {
    return trimmed;
  }

  trimmed = trimmed.replace(new RegExp('0+$', 'g'), '');
  if (trimmed.endsWith('.')) {
    return trimmed.substring(0, trimmed.length - 1);
  }
  return trimmed;
};

export const valuesAreEqual = (estimation, value) =>
  trimZeros(`${estimation}`) === trimZeros(`${value}`);
