import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import FormGroup from 'components/ui/form/form-group';
import { ENTITY_TYPE_PRODUCT_PICTURE } from 'constants/entities';
import { MIGRATED_FIELDS } from 'constants/productPicture';
import ProductPictureForm from 'modules/assets/components/picture/form';
import { validatePicture } from 'modules/assets/components/picture/utils';

import AssetContext from '../../context';
import AssetFormGroup from '../form-group';

function PictureForm({
  index,
  picture,
  readOnly,
  onUpdateField,
  onUpdatePackshot,
  onUpdateFormGroup,
  entityIndex,
  fields,
  hasJsonStoreInputFeature,
  hasMediaIpRights,
}) {
  const updateField = useCallback(
    (...args) => {
      onUpdateField(index, ...args);
    },
    [onUpdateField, index]
  );

  const updatePackshot = useCallback(
    (...args) => {
      onUpdatePackshot(index, ...args);
    },
    [onUpdatePackshot, index]
  );

  const updateFormGroup = useCallback(
    (...args) => {
      onUpdateFormGroup(index, ...args);
    },
    [onUpdateFormGroup, index]
  );

  const updatedFields = useMemo(() => {
    return fields
      ? fields.filter((field) => {
          if (field.model === 'sequenceNumber') {
            return !!picture.id;
          } else if (field.model === 'ipUsageRights') {
            return hasMediaIpRights;
          }
          return true;
        })
      : [];
  }, [picture.id, fields, hasMediaIpRights]);

  const formGroup = useMemo(
    () => ({
      kind: 'DisplayGroup',
      label: 'Picture',
      items: updatedFields,
      id: `pictureForm-${picture.product_key_id}`,
    }),
    [picture.product_key_id, updatedFields]
  );

  return hasJsonStoreInputFeature ? (
    <div data-crc32={picture.crc32} className="AddPicture__container">
      <ProductPictureForm
        picture={picture}
        readOnly={readOnly}
        errors={[]}
        onUpdateField={updateField}
      >
        <AssetContext.Consumer>
          {({ entityId } = {}) => (
            <FormGroup
              entity={picture}
              entityId={entityId}
              entityKind={ENTITY_TYPE_PRODUCT_PICTURE}
              formGroup={formGroup}
              deepIndex={2}
              showTitle={false}
              onChangeField={updateField}
              noDispatch={false}
              entityIndex={entityIndex}
              disableDataOps
            />
          )}
        </AssetContext.Consumer>
        <AssetFormGroup
          entity={picture}
          controller="picture"
          onChangeField={updateFormGroup}
          entityIndex={entityIndex}
          ignoreFieldNames={readOnly ? [] : MIGRATED_FIELDS}
          entityKind={ENTITY_TYPE_PRODUCT_PICTURE}
          noDispatch={false}
        />
      </ProductPictureForm>
    </div>
  ) : (
    <div data-crc32={picture.crc32} className="AddPicture__container">
      <ProductPictureForm
        picture={picture}
        readOnly={readOnly}
        errors={picture ? validatePicture(picture) : []}
        onUpdateField={updateField}
        onUpdatePackshot={updatePackshot}
      >
        <AssetFormGroup
          entity={picture}
          controller="picture"
          onChangeField={updateFormGroup}
          entityIndex={entityIndex}
          ignoreFieldNames={MIGRATED_FIELDS}
        />
      </ProductPictureForm>
    </div>
  );
}

PictureForm.propTypes = {
  index: PropTypes.number.isRequired,
  picture: PropTypes.object,
  readOnly: PropTypes.bool,
  onUpdateField: PropTypes.func.isRequired,
  onUpdatePackshot: PropTypes.func.isRequired,
  onUpdateFormGroup: PropTypes.func.isRequired,
  entityIndex: PropTypes.number,
  displayGroups: PropTypes.array,
  fields: PropTypes.array,
  hasJsonStoreInputFeature: PropTypes.bool,
  hasMediaIpRights: PropTypes.bool,
};

export default PictureForm;
