import { filters } from 'core/modules/list/constants';

export const filterQueryMap = filters.reduce(
  (acc, filter) =>
    Object.assign(acc, {
      [filter.query]: filter,
    }),
  {}
);

export const filterKeyMap = filters.reduce(
  (acc, filter) =>
    Object.assign(acc, {
      [filter.key]: filter,
    }),
  {}
);
