import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { ADMIN_REDUCER_KEY } from './constants';
import adminReducer from './reducers/admin';
import billingAdminMainSaga from './sagas/admin';

const billingModule = async () =>
  registerModule(await import(/* webpackChunkName: "billing" */ './async'));

registerSaga(billingAdminMainSaga);
registerModuleReducer(ADMIN_REDUCER_KEY, adminReducer);

export const PricingPlanCheckout = asyncComponent(billingModule, {
  componentPath: 'PricingPlanCheckout',
});

export const BillingAdministration = asyncComponent(billingModule, {
  componentPath: 'BillingAdministration',
});

export const BillingRecipientsForSubscription = asyncComponent(billingModule, {
  componentPath: 'BillingRecipientsForSubscription',
});

export const BillingRecipientsForAdmin = asyncComponent(billingModule, {
  componentPath: 'BillingRecipientsForAdmin',
});

export { hasBillingFeature } from './utils';
export { fetchAdminCustomer } from './actions/admin';
export {
  selectAdminCustomer,
  selectAdminSubscriptions,
} from './selectors/admin';
