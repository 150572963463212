import memoize from 'memoize-one';
import { compose } from 'redux';

import { ONBOARDING_EMAIL, SUPPORT_EMAIL } from 'constants/emails';
import { TERMS_AND_CONDITIONS_LINK } from 'constants/links';
import {
  SOURCE_ALKEMICS,
  SOURCE_PRODUCTDNA,
} from 'constants/organization-source';
import { getOrganizationSource } from 'core/api/user';
import leclercBackground from 'images/leclerc-background.jpg';
import leclercLogo from 'images/leclerc-logo.png';
import productDnaWhiteLogo from 'images/logo-productdna-white.png';
import productDnaLogo from 'images/logo-productdna.png';
import salsifyDarkLogo from 'images/logo-salsify-dark.png';
import salsifyLogo from 'images/logo-salsify.png';
import { UserImmutable } from 'types';
import i18n, { getByLocale } from 'utils/i18n';
import { get } from 'utils/immutable';
import { getAppHostname, getHostname } from 'utils/location';
import qs from 'utils/query';

export const getAlkemicsConfig = () => ({
  source: SOURCE_ALKEMICS,
  email: {
    onboarding: ONBOARDING_EMAIL,
    support: SUPPORT_EMAIL,
  },
  logo: {
    src: {
      login: salsifyLogo,
      loginDark: salsifyDarkLogo,
      loginUK: salsifyDarkLogo,
      navbar: salsifyLogo,
    },
    alt: 'Salsify',
  },
  withNavbarLogo: false,
  footer: {
    public: {
      links: [
        {
          label: i18n.t('frontproductstream.layout_public.footer.about_link', {
            defaultValue: 'About',
          }),
          url: getByLocale({
            en: 'https://www.salsify.com/about-salsify',
            fr: 'https://www.salsify.com/fr/a-propos-de-salsify',
          }),
        },
        {
          label: i18n.t('frontproductstream.layout_public.footer.press_link', {
            defaultValue: 'Press resources',
          }),
          url: getByLocale({
            en: 'https://www.salsify.com/press-salsify',
            fr: 'https://www.salsify.com/fr/presse-salsify',
          }),
        },
        {
          label: i18n.t(
            'frontproductstream.layout_public.footer.careers_link',
            { defaultValue: 'Careers' }
          ),
          url: getByLocale({
            en: 'https://www.salsify.com/careers',
            fr: 'https://www.salsify.com/fr/recrutement',
          }),
        },
        {
          label: i18n.t(
            'frontproductstream.layout_public.footer.contact_link',
            { defaultValue: 'Contact' }
          ),
          url: getByLocale({
            en: 'https://www.salsify.com/en/contact-alkemics',
            fr: 'https://www.salsify.com/fr/contacter-alkemics',
          }),
        },
        {
          label: i18n.t(
            'frontproductstream.layout_public.footer.general_conditions_link',
            { defaultValue: 'General conditions' }
          ),
          url: TERMS_AND_CONDITIONS_LINK,
        },
        {
          label: i18n.t(
            'frontproductstream.layout_public.footer.privacy_link',
            { defaultValue: 'Privacy' }
          ),
          url: getByLocale({
            en: 'https://www.salsify.com/privacy-policy',
            fr: 'https://www.salsify.com/fr/confidentialit%C3%A9',
          }),
        },
      ],
    },
  },
  url: {
    tc: getByLocale({
      en: 'https://www.salsify.com/terms-of-service-2022-v1',
      fr: 'https://www.salsify.com/fr/terms-of-service-2022-v1',
    }),
  },
});

export const getProductDnaConfig = () => {
  const tcUrl =
    'https://www.gs1uk.org/~/media/documents/marketing-documents/gs1_uk_supplier_subscription_terms.pdf';
  return {
    source: SOURCE_PRODUCTDNA,
    email: {
      onboarding: 'productdna@gs1uk.org',
      support: 'support@gs1uk.org',
    },
    logo: {
      src: {
        login: productDnaLogo,
        loginDark: productDnaLogo,
        loginUK: productDnaLogo,
        navbar: productDnaWhiteLogo,
      },
      alt: 'ProductDNA',
    },
    withNavbarLogo: false,
    footer: {
      public: {
        links: [
          {
            label: i18n.t(
              'frontproductstream.layout_public.footer.contact_link',
              { defaultValue: 'Contact' }
            ),
            url: 'mailto:productdna@gs1uk.org',
          },
          {
            label: i18n.t(
              'frontproductstream.layout_public.footer.general_conditions_link',
              { defaultValue: 'General conditions' }
            ),
            url: tcUrl,
          },
        ],
      },
    },
    url: {
      tc: tcUrl,
    },
    title: 'productDNA',
  };
};

export const getLeclercConfig = () => {
  return {
    ...getAlkemicsConfig(),

    logo: {
      src: {
        login: leclercLogo,
        loginDark: leclercLogo,
        loginUK: leclercLogo,
        navbar: salsifyLogo,
      },
      alt: 'Leclerc',
      large: true,
    },
    title: 'Leclerc',
    loginExtraContent: i18n.t(
      'frontproductstream.branding.leclerc.login_extra_content',
      { defaultValue: 'Alkemics is Leclerc RBA unique process tool' }
    ),
    footer: {
      public: {
        links: [
          {
            label: i18n.t(
              'frontproductstream.layout_public.footer.contact_link',
              { defaultValue: 'Contact' }
            ),
            url: getByLocale({
              en: 'https://www.salsify.com/en/contact-alkemics',
              fr: 'https://www.salsify.com/fr/contacter-alkemics',
            }),
          },
          {
            label: i18n.t(
              'frontproductstream.layout_public.footer.general_conditions_link',
              { defaultValue: 'General conditions' }
            ),
            url: TERMS_AND_CONDITIONS_LINK,
          },
        ],
        addon: {
          title: i18n.t('frontproductstream.branding.leclerc.help_title', {
            defaultValue: 'Need help?',
          }),
          background: leclercBackground,
          primaryColor: '#0a70b5',
          secondaryColor: '#ed8b18',
          links: [
            {
              title: i18n.t(
                'frontproductstream.branding.leclerc.training_link',
                { defaultValue: 'Subscribe to training' }
              ),
              url: 'https://www.alkemics.com/france/eleclerc',
            },
            {
              title: i18n.t(
                'frontproductstream.branding.leclerc.guide_book_link',
                { defaultValue: 'Download the guide book' }
              ),
              url: `https://go.${getAppHostname()}/hubfs/eleclerc-RBA/guide-utilisateur-eleclerc.pdf`,
            },
          ],
        },
      },
    },
  };
};

const getConfigByHost = () => {
  if (getHostname().endsWith(`productdna.${getAppHostname()}`)) {
    return getProductDnaConfig();
  }
  if ('leclerc' in qs.parse(location.search)) {
    return getLeclercConfig();
  }
  return getAlkemicsConfig();
};

const getConfigByUser = (user) => {
  switch (getOrganizationSource(user)) {
    case SOURCE_PRODUCTDNA:
      return getProductDnaConfig();
    case SOURCE_ALKEMICS:
    default:
      return getAlkemicsConfig();
  }
};

export const getBrandingConfig = memoize((user?) => {
  if (user) {
    return getConfigByUser(user);
  }
  return getConfigByHost();
});

export const getSource: (user?: UserImmutable) => any = compose(
  (config) => get(config, 'source'),
  getBrandingConfig
);

export const getEmail = compose(
  (config) => get(config, 'email'),
  getBrandingConfig
);

export const getOnboardingEmail = compose(
  (email) => get(email, 'onboarding'),
  getEmail
);

export const getSupportEmail = compose(
  (email) => get(email, 'support'),
  getEmail
);

export const userWithAlkemicsSource = (user) => {
  const source = get(getBrandingConfig(user), 'source');
  return source === SOURCE_ALKEMICS;
};

export const isProductDnaUser = (user) => {
  const source = get(getBrandingConfig(user), 'source');
  return source === SOURCE_PRODUCTDNA;
};
