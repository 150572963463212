import { hasFeature } from 'modules/feature-flag';
import { FEATURE_PRODUCT_REVIEW } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import { i18n } from 'utils/i18n';

type FilterValues = {
  [value: string]: {
    id: any;
  };
};

export const reviewFirstShareFilter = {
  key: 'alkemics.review_process.is_first_share',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_review_first_share.label',
    { defaultValue: 'Timeline' }
  ),
  query: 'review_is_first_share',
  customQuery: (selectedValues: FilterValues = {}) => {
    const values = Object.values(selectedValues).map(({ id }) => id);
    return values.length === 1
      ? {
          isReviewFirstShare: Boolean(values[0]),
        }
      : null;
  },
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    hasFeature(user, FEATURE_PRODUCT_REVIEW),
};
