import { flow, get, set } from 'lodash/fp';

import { ActionPayload } from 'types';

import { filterDataOpsPatchesByProductKeyId } from '../actions';
import { DataOpsPatch, DataOpsState } from '../types';

import { dataOpsInitialState } from './initial-state';
import { dataOpsPatchesReceivedReducer } from './patches-received';

export const dataOpsFilterPatchesOnRecipientReducer = (
  { productKeyId }: ActionPayload<typeof filterDataOpsPatchesByProductKeyId>,
  state: DataOpsState
) => {
  const patches: DataOpsPatch[] = [];
  Object.values(get(['patches'], state)).forEach((dict) => {
    const current: DataOpsPatch[] = dict.data as DataOpsPatch[];
    patches.push(
      ...current.filter((p) => p.sourceProductKeyId !== productKeyId)
    );
  });
  return flow(
    set(['patches'], dataOpsInitialState.patches),
    (_state: DataOpsState) =>
      dataOpsPatchesReceivedReducer({ patches }, _state)(_state)
  );
};
