import { noop } from 'lodash';
import { set } from 'lodash/fp';
import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import { Estimations, Level } from 'modules/price-waterfalls/types';
import { map, update } from 'utils/fp';
import i18n from 'utils/i18n';

import { levelTypePrice } from '../../constants';

import './validate-estimations-button.scss';

interface ValidateEstimationsButtonProps {
  estimations: Estimations;
  estimationFailed: boolean;
  value: {
    levels?: Level[];
  };
  model: string;
  onUpdate?: (model: string, value: any) => void;
  onHover?: (hover: boolean) => void;
}

class ValidateEstimationsButton extends PureComponent<ValidateEstimationsButtonProps> {
  static defaultProps = {
    onHover: noop,
  };

  onValidateEstimations = () => {
    const { estimations, value, model, onUpdate } = this.props;

    const levels = map((level) => {
      if (level.type.id !== levelTypePrice.id || level.items.length !== 1) {
        return level;
      }

      const itemEstimations = estimations[level.items[0].type.key];
      if (!itemEstimations) {
        return level;
      }

      return update(
        'items.0',
        update('values', (values) =>
          // utils/fp/map does not work with indexes
          values.map((v, idx) => set('value', `${itemEstimations[idx]}`, v))
        ),
        level
      );
    }, value.levels);

    onUpdate?.(`${model}.levels`, levels);
  };

  onMouseEnter = () => this.props.onHover?.(true);

  onMouseLeave = () => this.props.onHover?.(false);

  render() {
    const { estimations, estimationFailed } = this.props;
    return (
      <div
        className="ValidateEstimationsButton"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Button
          link
          content={i18n.t('Validate all estimations')}
          onClick={this.onValidateEstimations}
          disabled={estimationFailed || !estimations}
        />
      </div>
    );
  }
}

export default ValidateEstimationsButton;
