import { List, Map } from 'immutable';
import { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';

import { ASSIGN, REASSIGN } from 'constants/assignation';
import {
  TargetProductStatusNotAssigned,
  TargetProductStatusPendingAssignation,
  TargetProductStatusWaitingToBeShared,
} from 'constants/targetProductStatus';
import { hasAssignation } from 'core/api/organization-settings';
import { getProductVersionTargetProductStatus } from 'core/api/productversion';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import ListTableCell from 'core/modules/list/components/table/cell';
import { PRODUCT_TO_REVIEW_STATUSES } from 'modules/product-review/constants';
import { catalogToReview as catalogToReviewRoute } from 'routes';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { getOr } from 'utils/immutable';

import Action from '../action';

import './index.scss';

type Props = {
  productVersion: Map<string, any>;
  user: UserImmutable;
};

export const CatalogProductActionColumn = memo(
  ({ productVersion, user }: Props) => {
    const { pathname } = useLocation();
    const isCatalogToReview = useMemo(
      () => pathname.startsWith(catalogToReviewRoute),
      [pathname]
    );
    const targetProductStatus = useMemo(
      () =>
        isRetailer(user)
          ? getProductVersionTargetProductStatus(productVersion)
          : null,
      [user, productVersion]
    );
    const isProductToReview = useMemo(
      () =>
        Boolean(
          getOr(
            List(),
            ['alkemics', 'review_process', 'action_states'],
            productVersion
          ).find((state) =>
            PRODUCT_TO_REVIEW_STATUSES.includes(state.get('status'))
          )
        ),
      [productVersion]
    );

    const {
      primary = false,
      secondary = false,
      className,
      label,
    } = useMemo(() => {
      if (isCatalogToReview && isProductToReview) {
        return {
          primary: true,
          label: i18n.t('Verify product'),
          className: 'review',
        };
      }
      switch (targetProductStatus) {
        case TargetProductStatusNotAssigned.id:
          return {
            secondary: true,
            label: i18n.t('Assign'),
            className: ASSIGN,
          };
        case TargetProductStatusPendingAssignation.id:
          return {
            secondary: true,
            label: i18n.t('Remind'),
            className: REASSIGN,
          };
        case TargetProductStatusWaitingToBeShared.id:
          return {
            secondary: true,
            label: i18n.t('See comments'),
            className: 'dashboard',
          };
        default:
          return {
            primary: true,
            label: i18n.t('See product'),
            className: 'productpage',
          };
      }
    }, [isCatalogToReview, isProductToReview, targetProductStatus]);

    if (!productVersion) {
      return <td className="CatalogProductActionColumn" />;
    }

    if (
      !hasAssignation(getOrganizationSettings(user)) &&
      targetProductStatus &&
      [
        TargetProductStatusNotAssigned.id,
        TargetProductStatusPendingAssignation.id,
        TargetProductStatusWaitingToBeShared.id,
      ].includes(targetProductStatus)
    ) {
      return <ListTableCell className="CatalogProductActionColumn" />;
    }

    return (
      <ListTableCell className="CatalogProductActionColumn">
        <Action productVersion={productVersion} user={user}>
          <Button
            primary={primary}
            secondary={secondary}
            className={`CatalogProductActionColumn__${className}`}
          >
            <span className="CatalogProductActionColumn__btnTxt">{label}</span>
            <span className="mdi mdi-chevron-right CatalogProductActionColumn__btnChevron" />
          </Button>
        </Action>
      </ListTableCell>
    );
  }
);

export default CatalogProductActionColumn;
