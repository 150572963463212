import {
  TargetProductStatusMap,
  TargetProductStatusNotAssigned,
  TargetProductStatusPendingAssignation,
} from 'constants/targetProductStatus';
import { hasAssignation } from 'core/api/organization-settings';
import { getOrganizationSettings, isRetailer } from 'core/api/user';

import { isAvailableForProductStatus } from '../../utils/filters/product-status';

export const rejectionStatusFilter = {
  key: 'alkemics.assignation.rejectionStatus',
  query: 'lastrefused',
  isAvailable: ({ user, selectedFilterTargetProductStatus } = {}) =>
    isRetailer(user) &&
    hasAssignation(getOrganizationSettings(user)) &&
    isAvailableForProductStatus(
      selectedFilterTargetProductStatus,
      Object.keys(TargetProductStatusMap).filter((statusId) =>
        [
          TargetProductStatusNotAssigned.id,
          TargetProductStatusPendingAssignation.id,
        ].includes(statusId)
      )
    ),
};
