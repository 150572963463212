import { ProductReviewApi } from '@alkem/sdk-dashboard';

import {
  ActionToReviewStatus,
  FieldToReviewStatus,
} from 'modules/product-review/types';
import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  acceptLanguage,
  cacheEnabled: true,
};

export const productReviewApi = new ProductReviewApi(options);

export const getReviewDetails = async (productKeyId: number) => {
  try {
    return await productReviewApi.getReview(productKeyId);
  } catch (e: any) {
    if (e.status === 404) {
      return null;
    }
    throw e;
  }
};

export const getReviewSummary = (productKeyId: number) =>
  productReviewApi.getSummary(productKeyId);

export const validateReview = (
  productKeyId: number,
  {
    stateId,
    status,
    lastUpdatedAt,
    message = null,
    rejectedFieldIds = null,
  }: {
    stateId: number;
    status: ActionToReviewStatus;
    lastUpdatedAt: string | null;
    message?: string | null;
    rejectedFieldIds?: number[] | null;
  }
) =>
  productReviewApi.post(
    `/review/pending/v1/${productKeyId}/action-states/statuses`,
    [
      {
        id: stateId,
        status: status,
        last_updated_at: lastUpdatedAt,
        message: message,
        rejected_field_ids: rejectedFieldIds,
      },
    ]
  );

export const validateTransactionReview = (
  productKeyId: number,
  transactions: { id; status }[]
) =>
  productReviewApi.post(
    `/review/pending/v1/transactions/${productKeyId}/statuses`,
    transactions
  );

export const validateFieldReview = (
  productKeyId: number,
  fieldReviews: { fieldId; status }[]
) =>
  productReviewApi.post(
    `/review/pending/v1/${productKeyId}/fields/statuses`,
    fieldReviews
  );

export const getPendingReviewFields = (
  targetProductKeys: number[],
  status: FieldToReviewStatus = FieldToReviewStatus.REJECTED
) =>
  productReviewApi.post(`/review/pending/v1/fields/statuses`, {
    product_key_ids: targetProductKeys,
    status,
  });
