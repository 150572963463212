import { List, Map } from 'immutable';

import { getProductId, getProductVersionId } from 'core/api/productversion';
import { resetPage, resetSelection } from 'core/modules/list/reducers/list';
import { updateAggregations } from 'core/modules/list/utils/aggregations';

export const fetchList = (state, { payload = {} } = {}) =>
  state.withMutations((newState) => {
    newState.set('isFetchingList', true);
    if (payload.next) {
      newState.updateIn(['pagination', 'page'], (page) => page + 1);
    } else if (payload.previous) {
      newState.updateIn(['pagination', 'page'], (page) => page - 1);
    }
    resetSelection(newState);
  });

export const cancelFetchList = (state) => state.set('isFetchingList', false);

export const receiveList = (state, { payload }) => {
  const { list, dict } = payload.list.reduce(
    (acc, product) => {
      const id = getProductVersionId(product);
      return {
        list: acc.list.push(id),
        dict: acc.dict.set(id, product),
      };
    },
    { list: List(), dict: Map() }
  );
  return state.withMutations((newState) => {
    newState
      .set('productList', list)
      .set('productMap', dict)
      .set('isFetchingList', false)
      .set('isFirstTime', payload.isFirstTime)
      .update('pagination', (pagination) =>
        pagination
          .set('total', payload.total)
          .set('pages', Math.ceil(payload.total / pagination.get('limit')))
      );
    if (payload.fullAggregations) {
      updateAggregations(newState, payload.fullAggregations);
    }
    updateAggregations(newState, payload.aggregations);
  });
};

export const sortList = (state, { payload }) =>
  state.set('sorting', payload.referential.set('asc', payload.asc));

export const search = (state, { payload }) =>
  state.withMutations((newState) => {
    newState.set('search', payload);
    resetPage(newState);
    resetSelection(newState);
    return newState;
  });

export const updateUserLabels = (state, action) => {
  const { attachedLabels, detachedLabels, updatedProductIds } = action.payload;
  return state.withMutations((newState) => {
    newState
      .get('productMap')
      .filter((pv) => updatedProductIds.includes(getProductId(pv)))
      .forEach((pv) => {
        newState.updateIn(
          ['productMap', pv.get('id'), 'isUserLabeledBy'],
          (userLabels) =>
            userLabels
              .filter(
                (label) =>
                  !detachedLabels.some(
                    (detached) => detached.get('id') === label.get('id')
                  )
              )
              .concat(
                attachedLabels.filter(
                  (attached) =>
                    !userLabels.some(
                      (label) => attached.get('id') === label.get('id')
                    )
                )
              )
        );
      });
  });
};
