import classNames from 'classnames';
import { isUndefined } from 'lodash';
import { connect } from 'react-redux';

import { Radio } from '@alkem/react-ui-inputs';

import Field from 'components/ui/form/field';

import './radio.scss';

const mapStateToProps = () => ({});

class FormRadio extends Field<{
  isEntity: boolean;
}> {
  static defaultProps = Object.assign({}, Field.defaultProps, {
    isEntity: false,
  });

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue(event) {
    const { value } = event.currentTarget;

    if (this.props.isEntity) {
      return { id: Number(value) };
    } else if (value === 'false') {
      return false;
    } else if (value === 'true') {
      return true;
    } else if (value === 'null') {
      return null;
    } else if (value === 'undefined') {
      return null;
    } else if (Number(value).toString() === value) {
      return Number(value);
    }

    if (isUndefined(value)) {
      return null;
    }

    return value;
  }

  render() {
    const { value, field, isEntity } = this.props;
    if (!field) {
      return null;
    }
    const isReadOnly = this.isReadOnly();

    // Default to null if not entity and not value.
    let localValue = value;
    const { defaultValue } = field.inputKind;
    if (!isEntity && typeof value === 'undefined') {
      localValue = defaultValue || null;
    } else if (
      isReadOnly &&
      isEntity &&
      !localValue &&
      typeof defaultValue !== 'undefined'
    ) {
      localValue = { id: defaultValue };
    }

    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };

    const options = field.inputKind.values;

    return (
      <div
        className={classNames(
          this.getClasses({ 'InputField FormRadio row': true })
        )}
      >
        {renderedLabel}
        <div className={classNames(classes)}>
          <Radio
            id={this.getId()}
            value={isEntity && localValue ? localValue.id : localValue}
            onChange={this.handleChange}
            options={options}
            vertical={field.inputKind.vertical}
            disabled={isReadOnly}
            className="FormRadio__radio"
          />
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormRadio);
