import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import UnreadCount from 'components/unread-count';
import * as routes from 'routes';

import {
  cancelFetchCountUnacked,
  fetchCountUnacked,
  getCountUnacked,
} from '../../shared';

import './index.scss';

const mapStateToProps = createStructuredSelector({
  countUnacked: getCountUnacked,
});

const mapDispatchToProps = {
  fetchCountUnacked,
  cancelFetchCountUnacked,
};

export class CollaborationLink extends Component {
  static propTypes = {
    fetchCountUnacked: PropTypes.func.isRequired,
    cancelFetchCountUnacked: PropTypes.func.isRequired,
    countUnacked: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.props.fetchCountUnacked();
  }

  componentWillUnmount() {
    this.props.cancelFetchCountUnacked();
  }

  render() {
    const { countUnacked } = this.props;
    const forumClasses = {
      mdi: true,
      'mdi-forum': true,
      CollaborationUnread__forum: true,
      'CollaborationUnread__forum--animate': this.props.countUnacked > 0,
    };

    return (
      <li>
        <div className="CollaborationUnread">
          <NavLink
            to={routes.chat}
            className={({ isActive }) =>
              classNames(
                'Navbar__CollaborationLink',
                isActive && 'Navbar__CollaborationLink-active'
              )
            }
          >
            <i className={classNames(forumClasses)} />
            <UnreadCount absolute count={countUnacked} />
          </NavLink>
        </div>
      </li>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationLink);
