import { isUndefined } from 'lodash';

import {
  PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_HIGH,
  PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_STANDARD,
} from 'constants/media';
import i18n from 'utils/i18n';

export function validatePicture(data) {
  const errors = [];

  // check file extension
  const validPaths = /.*\.(jpg|jpeg|png|tiff)$/;
  if (
    !data.uniformResourceIdentifier ||
    !data.uniformResourceIdentifier.match(validPaths)
  ) {
    errors.push(
      i18n.t('frontproductstream.assets.picture.validate_file_type', {
        context: 'Picture validation',
        defaultValue: 'File type must be tiff, png or jpg',
      })
    );
  }

  // WARNING: THIS IS NOT WORKING BECAUSE NEW PICS HAVE NO FILE TYPE AND BEFORE PICTUREINFORMATION NEVER CONTAINED FILE TYPE ANYWAY
  // TODO - check with levine expected behavior
  // // check dimensions and resolution match
  // const size = Math.min(data.width, data.height);
  // switch (data.fileType) {
  //   case PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_STANDARD.id: {
  //     const min = 900;
  //     const max = 2400;
  //     if (size < min || max < size) {
  //       errors.push(
  //         i18n.t(
  //           'Standard definition requires image size to be between {{min}}px and {{max}}px, current size is {{size}}px.',
  //           { size, min, max }
  //         )
  //       );
  //     }
  //     break;
  //   }
  //   case PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_HIGH.id: {
  //     const min = 2401;
  //     const max = 4800;
  //     if (size < min || max < size) {
  //       errors.push(
  //         i18n.t(
  //           'High definition requires image size to be between {{min}}px and {{max}}px, current size is {{size}}px.',
  //           { size, min, max }
  //         )
  //       );
  //     }
  //     break;
  //   }
  //   default:
  //     break;
  // }

  // check productFace is set
  if (isUndefined(data.contentType) || data.contentType === null) {
    errors.push(
      i18n.t('frontproductstream.assets.picture.validate_information_type', {
        context: 'Picture validation',
        defaultValue: 'Field "Type of information" must be set',
      })
    );
  }

  // check productFace is set
  if (isUndefined(data.productFace) || data.productFace === null) {
    errors.push(
      i18n.t('frontproductstream.assets.picture.validate_product_face', {
        context: 'Picture validation',
        defaultValue: 'Field "Product Face" must be set',
      })
    );
  }

  // check angleVertical is set
  if (isUndefined(data.angleVertical) || data.angleVertical === null) {
    errors.push(
      i18n.t('frontproductstream.assets.picture.validate_vertical_angle', {
        context: 'Picture validation',
        defaultValue: 'Field "Vertical angle" must be set',
      })
    );
  }

  // check angleHorizontal is set
  if (isUndefined(data.angleHorizontal) || data.angleHorizontal === null) {
    errors.push(
      i18n.t('frontproductstream.assets.picture.validate_horizontal_angle', {
        context: 'Picture validation',
        defaultValue: 'Field "Horizontal angle" must be set',
      })
    );
  }

  // check Validity start date is set
  if (
    isUndefined(data.fileEffectiveStartDateTime) ||
    data.fileEffectiveStartDateTime === null
  ) {
    errors.push(
      i18n.t('frontproductstream.assets.picture.validate_start_date', {
        context: 'Picture validation',
        defaultValue: 'Field "Validity start date" must be set',
      })
    );
  }

  // check Validity dates are coherent
  if (
    data.fileEffectiveStartDateTime &&
    data.fileEffectiveEndDateTime &&
    data.fileEffectiveStartDateTime > data.fileEffectiveEndDateTime
  ) {
    errors.push(
      i18n.t('frontproductstream.assets.picture.validate_end_date', {
        context: 'Picture validation',
        defaultValue: 'Start validity date cannot come after end validity date',
      })
    );
  }
  return errors;
}

export const computeFileType = (picture) => {
  let fileType = null;
  const size = Math.min(picture.width, picture.height);
  if (size >= 900 && size <= 2400) {
    fileType = PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_STANDARD.id;
  } else if (size >= 2401 && size <= 4800) {
    fileType = PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_HIGH.id;
  }
  return fileType;
};
