import classNames from 'classnames';
import Immutable from 'immutable';
import memoize from 'memoize-one';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Tooltip from '@alkem/react-ui-tooltip';

import { UnreadCount } from 'components/unread-count';
import prefs from 'constants/prefs';
import { hasProductCommentSetting } from 'core/api/organization-settings';
import { withUserType } from 'hocs';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT,
  FEATURE_PRODUCT_INSIGHTS,
  FEATURE_SHARING_STATUS_HISTORY,
} from 'modules/feature-flag/constants';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import { usePermission } from 'modules/permissions';
import {
  CHAT_PERMISSION,
  HISTORY_PERMISSION,
  PRODUCT_PERMISSION,
  UPDATES_VALIDATE_PERMISSION,
} from 'modules/permissions/const';
import { ProductReviewDot } from 'modules/product-review';
import { hasFeatureProductReview } from 'modules/product-review/utils';
import { selectGroups } from 'modules/publication-summary/selectors';
import {
  selectCanShareProduct,
  selectCanUpdateProduct,
  selectIsHeterogeneousLogisticalUnit,
  selectIsLogisticalUnit,
  selectProductIsWaitingToBeShared,
  selectSharedProductVersionId,
} from 'reducers/productVersion';
import {
  selectIsLoggedAs,
  selectOrganizationSettings,
  selectUser,
} from 'reducers/user/selectors';
import * as routes from 'routes';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import { HeaderNavLink } from './header-nav-link';
import './product-header-navigation.scss';

interface Props {
  isRetailer: boolean;
  isThirdParty: boolean;
}

const retailersAwaitingForProduct = memoize(
  (retailerGroups) =>
    retailerGroups
      .filter((group) => group.get('status') !== 'ACTIVE')
      .map((group) =>
        group
          .get('members')
          .map((member) => member.get('hasProductInActiveRange'))
      )
      .flatten()
      .filter((inActiveRange) => inActiveRange === true).size
);

export const ProductHeaderNavigation = ({
  isRetailer,
  isThirdParty,
}: Props) => {
  const showNavigation = get(prefs, 'product.header.navigation');
  const params = useParams();
  const productKeyId = params.productKeyId as string;
  const canUpdateProduct: boolean = useSelector(selectCanUpdateProduct);
  const canShareProduct: boolean = useSelector(selectCanShareProduct);
  const sharedProductVersionId: number | any = useSelector(
    selectSharedProductVersionId
  );
  const organizationSettings: Immutable.Map<string, any> = useSelector(
    selectOrganizationSettings
  );
  const productIsWaitingToBeShared: boolean = useSelector(
    selectProductIsWaitingToBeShared
  );
  const isLogisticalUnit: boolean = useSelector(selectIsLogisticalUnit);
  const isHeterogeneousLogisticalUnit: boolean = useSelector(
    selectIsHeterogeneousLogisticalUnit
  );
  const hasHeterogeneousLogisticalUnitFeature: boolean = useSelector(
    selectHasFeature(FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT)
  );
  const isLoggedAs: boolean = useSelector(selectIsLoggedAs);
  const user: UserImmutable = useSelector(selectUser);
  const groups: Immutable.Map<string, any> = useSelector(selectGroups);
  const { hasPermission, hasLegacyPermissions } = usePermission({
    module: PRODUCT_PERMISSION,
  });

  if (!showNavigation) {
    return null;
  }

  const publicationNotificationCount = retailersAwaitingForProduct(groups);
  return (
    <div
      className={classNames('ProductHeaderNavigation', {
        'ProductHeaderNavigation--retailer': isRetailer,
        'ProductHeaderNavigation--maker': !isRetailer,
      })}
    >
      <HeaderNavLink
        productKeyId={productKeyId}
        when={!(isRetailer && productIsWaitingToBeShared)}
        to={routes.productPage}
        title={i18n.t('Product Page')}
        exact
      />
      {(!isLogisticalUnit ||
        (isHeterogeneousLogisticalUnit &&
          hasHeterogeneousLogisticalUnitFeature)) && (
        <HeaderNavLink
          productKeyId={productKeyId}
          when={!isRetailer && (canShareProduct || isLoggedAs)}
          to={routes.productDashboard}
          title={i18n.t('Publication')}
        >
          {Boolean(publicationNotificationCount) && (
            <>
              <span
                data-for="publicationNotificationCount"
                data-tip
                className="PublicationNotificationCount"
              >
                <UnreadCount
                  count={publicationNotificationCount}
                  absolute
                  top
                />
              </span>
              <Tooltip id="publicationNotificationCount" delayShow={0}>
                {publicationNotificationCount === 1
                  ? i18n.t('1 retailer is expecting your product')
                  : i18n.t('{{count}} retailers are expecting your product', {
                      count: publicationNotificationCount,
                    })}
              </Tooltip>
            </>
          )}
        </HeaderNavLink>
      )}
      <HeaderNavLink
        productKeyId={productKeyId}
        when={
          !(isRetailer && productIsWaitingToBeShared) &&
          (hasLegacyPermissions
            ? true
            : hasPermission({
                permissions: HISTORY_PERMISSION,
                entityId: productKeyId,
              }))
        }
        to={routes.productHistory}
        title={i18n.t('History')}
      />
      {hasFeature(user, FEATURE_SHARING_STATUS_HISTORY) && (
        <HeaderNavLink
          productKeyId={productKeyId}
          when={!isRetailer && !isThirdParty}
          to={routes.productStatusHistory}
          title={i18n.t('Status History')}
        />
      )}
      <HeaderNavLink
        productKeyId={productKeyId}
        when={
          (!isRetailer || hasProductCommentSetting(organizationSettings)) &&
          !(productIsWaitingToBeShared && !sharedProductVersionId) &&
          ((hasLegacyPermissions
            ? canUpdateProduct
            : hasPermission({
                permissions: CHAT_PERMISSION,
                entityId: productKeyId,
              })) ||
            isThirdParty ||
            isLoggedAs)
        }
        to={routes.productChat}
        title={i18n.t('Messages')}
      />
      <HeaderNavLink
        productKeyId={productKeyId}
        when={isRetailer && hasFeature(user, FEATURE_PRODUCT_INSIGHTS)}
        to={routes.productInsights}
        title={i18n.t('Insights')}
      />
      <HeaderNavLink
        productKeyId={productKeyId}
        when={
          hasFeatureProductReview(user) &&
          hasPermission({
            permissions: UPDATES_VALIDATE_PERMISSION,
            entityId: productKeyId,
          })
        }
        to={routes.productReview}
        title={i18n.t('Review', { context: 'ReviewProcess' })}
      >
        <ProductReviewDot />
      </HeaderNavLink>
      <HeaderNavLink
        productKeyId={productKeyId}
        when={isLoggedAs}
        to={routes.productAdmin}
        title={i18n.t('Admin')}
        logasVisibility={true}
      />
    </div>
  );
};

export default withUserType(ProductHeaderNavigation);
