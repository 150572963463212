import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { cancelUnsync, unsync } from '../../actions';
import {
  selectLoadingInProgress,
  selectTargetRecipient,
} from '../../selectors';
import '../index.scss';

const mapStateToProps = createStructuredSelector({
  targetRecipient: selectTargetRecipient,
  loadingInProgress: selectLoadingInProgress,
});

const mapDispatchToProps = {
  cancelUnsync,
  unsync,
};

export class ConfirmUnsynchronizationModal extends PureComponent {
  static propTypes = {
    loadingInProgress: PropTypes.bool.isRequired,
    targetRecipient: ImmutablePropTypes.map.isRequired,
    actions: PropTypes.shape({
      cancelUnsync: PropTypes.func.isRequired,
      unsync: PropTypes.func.isRequired,
    }).isRequired,
  };

  cancelUnsync = () => {
    const { loadingInProgress, actions } = this.props;
    if (loadingInProgress) {
      return;
    }
    actions.cancelUnsync();
  };

  render() {
    const { actions, targetRecipient, loadingInProgress } = this.props;

    return (
      <Modal
        modalStyle="dynamic"
        title={i18n.t('Unsynchronize product')}
        confirmButtonText={i18n.t('Unsynchronize')}
        isProcessing={loadingInProgress}
        onConfirm={actions.unsync}
        onClose={this.cancelUnsync}
      >
        <div>
          {i18n.t(
            "This action will unsync the product from {{recipientName}}, product editions won't be forwarded anymore.",
            { recipientName: targetRecipient.get('nameLegal') }
          )}
          <br />
          {i18n.t('Proceed to unsynchronization anyway ?')}
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ConfirmUnsynchronizationModal);
