import { OrderedMap, fromJS } from 'immutable';

import { get, has, remove, set, size } from 'utils/immutable';

export const mergeAggregation = (
  oldAggregation,
  newAggregation,
  withCounts = true
) => {
  Object.keys(newAggregation || {}).forEach((key) => {
    const aggregList = Array.isArray(newAggregation[key])
      ? newAggregation[key]
      : [];
    const updatedCount = {};
    if (!has(oldAggregation, [key])) {
      oldAggregation = set(oldAggregation, [key], OrderedMap());
    }
    aggregList.forEach((aggreg) => {
      const aggregPath = [key, aggreg.id];
      const countPath = aggregPath.concat('doc_count');
      if (has(oldAggregation, countPath)) {
        if (get(oldAggregation, countPath) !== aggreg.doc_count) {
          oldAggregation = set(oldAggregation, countPath, aggreg.doc_count);
        }
      } else {
        oldAggregation = set(oldAggregation, aggregPath, fromJS(aggreg));
        if (!withCounts) {
          oldAggregation = remove(oldAggregation, countPath);
        }
      }
      if (withCounts) {
        updatedCount[aggreg.id] = true;
      }
    });
    if (
      withCounts &&
      has(oldAggregation, [key]) &&
      size(get(oldAggregation, [key])) !== Object.keys(updatedCount).length
    ) {
      get(oldAggregation, [key])
        .keySeq()
        .forEach((aggregKey) => {
          if (!updatedCount[aggregKey]) {
            oldAggregation = set(
              oldAggregation,
              [key, aggregKey, 'doc_count'],
              0
            );
          }
        });
    }
  });
  return oldAggregation;
};

export function updateAggregations(
  newState,
  aggregations,
  { withCounts = true } = {}
) {
  set(
    newState,
    'aggregations',
    mergeAggregation(get(newState, 'aggregations'), aggregations, withCounts)
  );
}
