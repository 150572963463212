import { Map } from 'immutable';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import HelpTooltip from '@alkem/react-ui-helptooltip';
import { ResponseWithBody } from '@alkem/sdk-dashboard';

import { startLoading, stopLoading } from 'actions/loading';
import { notificationError, notificationSuccess } from 'actions/notification';
import { DownloadPicturesModal } from 'components/download-pictures-modal';
import Dropdown from 'components/ui/dropdown';
import Modal from 'components/ui/modal';
import prefs from 'constants/prefs';
import { loadReferentials } from 'modules/assets/actions';
import { selectReferentials } from 'modules/assets/selectors';
import { FEATURE_PICTURE_DERIVATIVES_DOWNLOAD } from 'modules/feature-flag/constants';
import { selectUserFeature } from 'modules/feature-flag/selectors';
import { selectIsRetailer } from 'reducers/user/selectors';
import mediaApi from 'resources/mediaApi';
import i18n from 'utils/i18n';

import './buttons-bar.scss';

type Props = {
  productKeyId: number;
  pictureReadOnly?: boolean;
  statusIsPending: boolean;
  disableDownloadPictures?: boolean;
  updateEntities: () => void;
  selectedPictureIds?: number[];
  recipients?: Map<'id', number>[];
};

export type PictureSpecification = {
  code: string;
  label: any;
  data?: {
    organizationId: number;
    shopId: number;
    secondary: boolean;
    specsUrl: string;
  };
};

export const DownloadRetailerSpecs = ({ recipients = [] }: Partial<Props>) => {
  const referentials: {
    picturesspecification: PictureSpecification[];
  } = useSelector(selectReferentials);
  const dispatch = useDispatch();

  useEffect(() => {
    if (referentials) {
      return;
    }
    dispatch(loadReferentials());
  }, [dispatch, referentials]);

  const picturesSpecsOptions: PictureSpecification[] =
    referentials?.picturesspecification || [];
  const recipientIds: number[] = recipients.map((r) => r.get('id'));
  let options = picturesSpecsOptions.filter(
    (spec) => spec.data && recipientIds.indexOf(spec.data.organizationId) >= 0
  );
  if (options.length === 0) {
    options = [
      {
        code: 'empty-state',
        label: (
          <span className="NoResult">
            {i18n.t(
              'frontproductstream.asset_list.button_bar.retailer_specs.empty_text',
              { defaultValue: 'No image specification for your retailers' }
            )}
          </span>
        ),
      },
    ];
  }

  const downloadPicturesSpecs = (option: PictureSpecification) => {
    if (option.code === 'empty-state' || !option.data?.specsUrl) {
      return;
    }
    window.open(option.data.specsUrl);
  };

  return (
    <div className="AssetListButtonsBar__button">
      <Dropdown
        label={i18n.t(
          'frontproductstream.asset_list.button_bar.retailer_specs.download_button',
          { defaultValue: 'Download images’ specifications' }
        )}
        options={options}
        onClickOption={downloadPicturesSpecs}
      />
    </div>
  );
};

export const DownloadAllPictures = ({
  productKeyId,
  statusIsPending,
  disableDownloadPictures,
}: Partial<Props>) => {
  const hasPictureDerivatives = useSelector(
    selectUserFeature(FEATURE_PICTURE_DERIVATIVES_DOWNLOAD)
  );
  const [downloadingImages, setDownloadingImages] = useState(false);
  const [openPictureModal, setDownloadPictureModalOpened] = useState(false);
  const productKeyIds = productKeyId ? [productKeyId] : [];
  const dispatch = useDispatch();

  const disabled =
    disableDownloadPictures || downloadingImages || statusIsPending;

  const openDownloadPictureModal = () => {
    setDownloadPictureModalOpened(true);
  };

  const closeDownloadPictureModal = () => {
    setDownloadPictureModalOpened(false);
  };

  const download = async () => {
    setDownloadingImages(true);
    dispatch(startLoading());
    try {
      const response: ResponseWithBody =
        await mediaApi.ProductPictureDownloadAll({
          product_key_ids: productKeyIds,
          packshot_only: false,
        });
      const filename = `${moment().format('YYYY-MM-DD')}.zip`;
      (window as any).saveAs(response.data, filename);
      dispatch(
        notificationSuccess(
          i18n.t(
            'frontproductstream.asset_list.button_bar.download_pictures_notification.success',
            { defaultValue: 'Pictures have been downloaded' }
          )
        )
      );
    } catch (error: any) {
      if (error.error?.status === 404) {
        dispatch(
          notificationError(
            i18n.t(
              'frontproductstream.asset_list.button_bar.download_pictures_notification.missing_error',
              { defaultValue: 'No pictures found' }
            )
          )
        );
      } else {
        dispatch(
          notificationError(
            i18n.t(
              'frontproductstream.asset_list.button_bar.download_pictures_notification.error',
              { defaultValue: 'An error occured while downloading pictures' }
            )
          )
        );
      }
    } finally {
      dispatch(stopLoading());
      setDownloadingImages(false);
    }
  };

  return (
    <div className="AssetListButtonsBar__button">
      <Button
        id="downloadAllPictures"
        content={i18n.t(
          'frontproductstream.asset_list.button_bar.download_pictures_button.label',
          { defaultValue: 'Download pictures' }
        )}
        onClick={hasPictureDerivatives ? openDownloadPictureModal : download}
        displaySpinner={downloadingImages}
        disabled={disabled}
        secondary
        testid="downloadAllPictures"
      />
      {hasPictureDerivatives && openPictureModal && (
        <DownloadPicturesModal
          onClose={closeDownloadPictureModal}
          productKeyIds={productKeyIds}
        />
      )}
      {statusIsPending && (
        <HelpTooltip
          id="AssetList_helptooltip"
          message={i18n.t(
            'frontproductstream.asset_list.button_bar.download_pictures_button.tooltip',
            {
              defaultValue:
                'You can download images once your product has been validated.',
            }
          )}
        />
      )}
    </div>
  );
};

export const DeleteSelectedPictures = ({
  updateEntities,
  selectedPictureIds = [],
}: Partial<Props>) => {
  const [deletePicturesConfirmModalOpened, setDeletePictureModalOpened] =
    useState(false);
  const [deletingImages, setDeletingImages] = useState(false);
  const dispatch = useDispatch();

  const openSelectedPicturesConfirmModal = () => {
    setDeletePictureModalOpened(true);
  };

  const closeSelectedPicturesConfirmModal = () => {
    setDeletePictureModalOpened(false);
  };

  const deleteSelectedPicturesConfirm = async () => {
    setDeletingImages(true);
    dispatch(startLoading());

    const promises = selectedPictureIds.map((pictureId) =>
      mediaApi.EntityPictureDelete(pictureId)
    );

    try {
      await Promise.all(promises);
      dispatch(
        notificationSuccess(
          i18n.t(
            'frontproductstream.asset_list.button_bar.delete_multiple_pictures_notification.success',
            { defaultValue: 'Selected pictures deleted successfully' }
          )
        )
      );
    } catch {
      dispatch(
        notificationError(
          i18n.t(
            'frontproductstream.asset_list.button_bar.delete_multiple_pictures_notification.error',
            {
              defaultValue:
                'An error occured while deleting the selected pictures',
            }
          )
        )
      );
    } finally {
      updateEntities && updateEntities();
      dispatch(stopLoading());
      setDeletingImages(false);
      closeSelectedPicturesConfirmModal();
    }
  };

  let buttonContent;
  let confirmActionMessage;

  if (selectedPictureIds.length === 1) {
    buttonContent = i18n.t(
      'frontproductstream.asset_list.button_bar.delete_multiple_pictures_modal.one_title',
      { defaultValue: 'Delete 1 picture' }
    );
    confirmActionMessage = i18n.t(
      'frontproductstream.asset_list.button_bar.delete_multiple_pictures_modal.one_content',
      { defaultValue: 'Are you sure you want to delete this picture ?' }
    );
  } else {
    buttonContent = i18n.t(
      'frontproductstream.asset_list.button_bar.delete_multiple_pictures_modal.many_title',
      {
        defaultValue: 'Delete {{count}} pictures',
        count: selectedPictureIds.length,
      }
    );
    confirmActionMessage = i18n.t(
      'frontproductstream.asset_list.button_bar.delete_multiple_pictures_modal.many_content',
      {
        defaultValue:
          'Are you sure you want to delete these {{count}} pictures ?',
        count: selectedPictureIds.length,
      }
    );
  }

  return (
    <div className="AssetListButtonsBar__button AssetListButtonsBar__button--red">
      {deletePicturesConfirmModalOpened && (
        <Modal
          modalStyle="dynamic"
          title={buttonContent}
          confirmButtonText={i18n.t(
            'frontproductstream.asset_list.delete_button.label',
            { defaultValue: 'Delete' }
          )}
          isProcessing={deletingImages}
          onConfirm={deleteSelectedPicturesConfirm}
          onClose={closeSelectedPicturesConfirmModal}
        >
          {confirmActionMessage}
        </Modal>
      )}
      <Button
        onClick={openSelectedPicturesConfirmModal}
        content={buttonContent}
        secondary
      />
    </div>
  );
};

export const AssetListButtonsBar = memo((props: Props) => {
  const {
    productKeyId,
    pictureReadOnly,
    statusIsPending,
    disableDownloadPictures,
    updateEntities,
    selectedPictureIds = [],
    recipients,
  } = props;

  const isRetailer: boolean = useSelector(selectIsRetailer);
  const hasSelectedPictures: boolean = Boolean(
    !pictureReadOnly && selectedPictureIds.length
  );
  const hasRetailerSpecs: boolean = Boolean(
    !isRetailer && !prefs.organization.isMDD
  );

  return (
    <div className="AssetListButtonsBar">
      {hasSelectedPictures && (
        <DeleteSelectedPictures
          updateEntities={updateEntities}
          selectedPictureIds={selectedPictureIds}
        />
      )}
      <DownloadAllPictures
        productKeyId={productKeyId}
        statusIsPending={statusIsPending}
        disableDownloadPictures={disableDownloadPictures}
      />
      {hasRetailerSpecs && <DownloadRetailerSpecs recipients={recipients} />}
    </div>
  );
});

export default AssetListButtonsBar;
