import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { AUTHORIZED, LOGOUT, NOT_AUTHORIZED } from '../actions/types';

export const initialState = fromJS({
  authorized: undefined,
});

export default createReducer(initialState, {
  [AUTHORIZED]: (state) => state.set('authorized', true),
  [NOT_AUTHORIZED]: (state) => state.set('authorized', false),
  [LOGOUT]: () => initialState,
});
