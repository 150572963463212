import {
  ADD_PENDING_FIELD_COMMENT,
  DISMISS_NORMALIZED_COMMENT,
  REMOVE_PENDING_FIELD_COMMENT,
} from 'constants/events/suggestions';
import { createAction } from 'utils/redux';

import {
  NORMALIZED_COMMENTS_OPEN_MODAL,
  NORMALIZED_COMMENTS_REQUEST_CHANGES,
  NORMALIZED_COMMENTS_REQUEST_CHANGES_DONE,
  NORMALIZED_COMMENTS_STORE_DEPRECATEDS,
} from './constants';

export const addFieldCommentToPendingMessage = (
  suggestion,
  productVersionId
) => ({
  type: ADD_PENDING_FIELD_COMMENT,
  suggestion,
  productVersionId,
});

export const removeFieldCommentFromPendingMessage = (
  label,
  productVersionId
) => ({
  type: REMOVE_PENDING_FIELD_COMMENT,
  label,
  productVersionId,
});

export const requestChanges = createAction(NORMALIZED_COMMENTS_REQUEST_CHANGES);
export const requestChangesDone = createAction(
  NORMALIZED_COMMENTS_REQUEST_CHANGES_DONE
);

export const dismissNormalizedComment = createAction(
  DISMISS_NORMALIZED_COMMENT
);

export const openMessageModal = createAction(NORMALIZED_COMMENTS_OPEN_MODAL);

export const storeDeprecatedNormalizedComments = createAction(
  NORMALIZED_COMMENTS_STORE_DEPRECATEDS
);
