import {
  concat,
  flow,
  get,
  identity,
  omit,
  set,
  uniq,
  update,
} from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';
import { v4 as uuid } from 'uuid';

import { UPDATE_ENTITY } from 'constants/events/entity';
import { ProductReferenceTypes } from 'constants/product-reference-types';
import {
  getLogisticalTypePackagingLabel,
  getTypePackagingCode,
} from 'constants/typePackaging';
import { SAVE_PRODUCT_SUCCESS } from 'modules/product-page/constants';
import { parseIfNumber } from 'utils';
import { toJsIfImmutable } from 'utils/immutable';
import { updateIsSizedByInReducer } from 'utils/reducers/entity';

import {
  BULK_UPDATE,
  ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT,
  LIST_HIERARCHIES,
  LOGISTICAL_HIERARCHIES_CREATE_TEMPLATE,
  LOGISTICAL_HIERARCHIES_CREATE_UNIT,
  LOGISTICAL_HIERARCHIES_DELETE_UNIT,
  LOGISTICAL_HIERARCHIES_INIT,
  LOGISTICAL_HIERARCHIES_PRIVATE_FIELD_EDIT,
  LOGISTICAL_HIERARCHIES_RECEIVE_DIFFS,
  LOGISTICAL_HIERARCHIES_SET_DELETED_ROOT,
  LOGISTICAL_HIERARCHIES_SET_EDITED_UNIT,
  LOGISTICAL_HIERARCHIES_UPDATE_QUANTITY,
  LOGISTICAL_HIERARCHIES_UPDATE_REFERENCE,
  RECEIVE_DISPLAY_GROUPS,
  RESET,
  TOGGLE,
} from './constants';
import {
  extractDataFromHierarchies,
  findUnitByRootAndReference,
  getReferenceToInternalIds,
  isConsumerOrDisplayUnit,
  parseLogisticalUnitEntityId,
} from './helpers';

/*
  UPDATERS
 */

const swap = (previousItem, newItem) => (list) => {
  for (const i in list) {
    if (list[i] === previousItem) {
      return set([i], newItem, list);
    }
  }
  return list;
};

const toggle = (internalId) => (expandedIds) =>
  expandedIds.includes(internalId)
    ? expandedIds.filter((id) => id !== internalId)
    : [...expandedIds, internalId];

const addRoot = (internalId, insertFirst) => (roots) =>
  insertFirst ? [internalId, ...roots] : [...roots, internalId];

const removeRoot = (internalId) => (roots) =>
  roots.filter((id) => id !== internalId);

const addChild =
  (internalId, quantity = 0) =>
  (children) =>
    [...children, { id: internalId, quantity }];

const removeChild = (internalId) => (children) =>
  children.filter((child) => child.id !== internalId);

const addUnit = (
  internalId,
  gtin,
  productIdentifier = null,
  version,
  isPatch,
  isDirty = true
) =>
  flow(
    set(['dataMap', internalId], {
      internalId,
      gtin,
      productIdentifier,
      reference: gtin || productIdentifier,
      version,
      isDirty,
    }),
    isPatch ? set(['dataMap', internalId, 'isPatch'], true) : identity,
    set(['hierarchyMap', internalId], [])
  );

const purgeUnit = (internalId) =>
  flow(
    update(['dataMap'], omit(internalId)),
    update(['hierarchyMap'], omit(internalId))
  );

// Generate a name for new units based on the type packaging and the current unit name.
const enrichVersionWithGeneratedName = (
  version,
  currentVersionDisplayName,
  currentVersionCurrentLanguage
) => {
  if (isConsumerOrDisplayUnit(version)) {
    return version;
  }
  const typePackagingLabel = getLogisticalTypePackagingLabel(
    getTypePackagingCode(version.typePackaging),
    true, // isLogisticalUnit
    true // singular
  );
  const generatedName = currentVersionDisplayName
    ? `${typePackagingLabel} - ${currentVersionDisplayName}`
    : typePackagingLabel;
  return {
    ...version,
    namePublicLong: [
      {
        data: generatedName,
        expressedIn: currentVersionCurrentLanguage,
      },
    ],
  };
};

const updateRootPathsToIgnore = (rootPath, isDirty, ignoreField) => (paths) => {
  if (!ignoreField) {
    return paths;
  }
  const localPaths = paths || [];
  if (!isDirty) {
    // Add the root path if not a user action.
    return [...localPaths, rootPath];
  }
  // Remove the root path since the action was performed by a user.
  return localPaths.filter((e) => e !== rootPath);
};

/*
  INITIAL STATE
 */

/* Looks like:
  {
    // Holds the roots of the hierarchies.
    roots: ['1'],

    // Holds the data of each unit.
    dataMap: {
      '1': { gtin, version },
      '2': { gtin, version },
      '3': { gtin, version },
    },

    // Holds the structure of the hierarchy.
    hierarchyMap: {
      '1': [{ id: '2', quantity: 3 }],
      '2': [{ id: '3', quantity: 5 }],
    },
  }
  All units are referenced by an internal Id.
  We don't use the product id / gtin to be able to handle creations and gtin updates.
  Those are generated upon creation and reception.
*/
const initialDataState = {
  roots: [],
  dataMap: {},
  hierarchyMap: {},
};
const initialProductState = {
  isDirty: false,
  source: initialDataState,
  edited: initialDataState,
  diffs: [],
  editedUnit: null, // { rootInternalId, internalId }
  rootPathsToIgnore: {}, // Paths not edited by user, indexed by internal ID.
  deletedRoots: [], // productKeyIds of deleted roots.
  agreedEditedUnits: [],
  expandedInternalIds: [],
  updatedPrivateFields: {},
};
export const initialState = {
  ...initialProductState,
  displayGroups: {}, // Display groups by packaging type id.
};

/*
  REDUCERS
 */
export default createReducer(initialState, {
  [RESET]: (state) => {
    return { ...state, ...initialProductState };
  },
  [TOGGLE]: (state, { internalId }) =>
    update(['expandedInternalIds'], toggle(internalId), state),
  [LOGISTICAL_HIERARCHIES_INIT]: (state, { currentVersionInfo, children }) => {
    const referenceMap = getReferenceToInternalIds(state.edited.dataMap);
    // Seed the current version unit data.
    if (
      (!currentVersionInfo.gtin || referenceMap[currentVersionInfo.gtin]) &&
      (!currentVersionInfo.productIdentifier ||
        referenceMap[currentVersionInfo.productIdentifier])
    ) {
      return state;
    }
    const internalId = uuid();
    const version = {
      isConsumerUnit: currentVersionInfo.isConsumerUnit,
      isDisplayUnit: currentVersionInfo.isDisplayUnit,
      typePackaging: currentVersionInfo.typePackaging,
      displayName: currentVersionInfo.displayName,
    };
    const updates = [
      update(
        ['source'],
        addUnit(
          internalId,
          currentVersionInfo.gtin,
          currentVersionInfo.productIdentifier,
          version,
          false,
          false
        )
      ),
      update(
        ['edited'],
        addUnit(
          internalId,
          currentVersionInfo.gtin,
          currentVersionInfo.productIdentifier,
          version,
          false,
          false
        )
      ),
    ];
    // Also register the children of the current unit if not already done.
    if (children && children.length) {
      for (const child of children) {
        if (child.gtin && !referenceMap[child.gtin]) {
          const childInternalId = uuid();
          updates.push(
            update(
              ['source'],
              addUnit(
                childInternalId,
                child.gtin,
                child.productIdentifier,
                child.version,
                false,
                false
              )
            )
          );
          updates.push(
            update(
              ['edited'],
              addUnit(
                childInternalId,
                child.gtin,
                child.productIdentifier,
                child.version,
                false,
                false
              )
            )
          );
          updates.push(
            update(
              ['source', 'hierarchyMap', internalId],
              addChild(childInternalId, child.quantity)
            )
          );
          updates.push(
            update(
              ['edited', 'hierarchyMap', internalId],
              addChild(childInternalId, child.quantity)
            )
          );
        }
      }
    }
    return flow(...updates)(state);
  },
  [LIST_HIERARCHIES]: (state, { hierarchies, permissions = [] }) => {
    const rawHierarchies = toJsIfImmutable(hierarchies);
    // For new hierarchies on target side, we need to use the last request.
    const mergedRawHierarchy = rawHierarchies.map((hierarchy) =>
      Object.keys(hierarchy.version).length === 0
        ? hierarchy.lastRequest
        : hierarchy
    );
    const source = extractDataFromHierarchies(
      mergedRawHierarchy,
      permissions,
      state.edited.dataMap
    );
    return {
      ...state,
      source,
      edited: source,
      isDirty: false,
      rootPathsToIgnore: {},
      deletedRoots: [],
    };
  },
  [LOGISTICAL_HIERARCHIES_RECEIVE_DIFFS]: (state, { diffs }) =>
    set(['diffs'], diffs, state),
  [LOGISTICAL_HIERARCHIES_CREATE_UNIT]: (
    state,
    {
      parentInternalId,
      gtin,
      productIdentifier,
      version,
      insertFirst,
      currentVersionDisplayName,
      currentVersionCurrentLanguage,
      removeFromRoot = false,
      isPatch,
    }
  ) => {
    const [referenceMap, patchedReferenceMap] = getReferenceToInternalIds(
      state.edited.dataMap,
      { splitByPatch: true }
    );
    const reference = gtin || productIdentifier;
    let internalId = isPatch
      ? patchedReferenceMap[reference]
      : referenceMap[reference];
    const newUnit = !reference || !internalId;
    if (newUnit) {
      internalId = uuid();
    }
    const updates = [];
    if (!parentInternalId) {
      updates.push(
        update(['edited', 'roots'], addRoot(internalId, insertFirst))
      );
      updates.push(update(['expandedInternalIds'], toggle(internalId)));
    } else {
      updates.push(
        update(
          ['edited', 'hierarchyMap', parentInternalId],
          addChild(internalId)
        )
      );
      if (removeFromRoot) {
        updates.push(update(['edited', 'roots'], removeRoot(internalId)));
      }
    }
    if (newUnit) {
      updates.push(
        update(
          ['edited'],
          addUnit(
            internalId,
            gtin,
            productIdentifier,
            enrichVersionWithGeneratedName(
              version,
              currentVersionDisplayName,
              currentVersionCurrentLanguage
            ),
            isPatch
          )
        )
      );
    }
    return flow(...updates, set(['isDirty'], true))(state);
  },
  [LOGISTICAL_HIERARCHIES_CREATE_TEMPLATE]: (
    state,
    {
      reference,
      versions,
      addCurrentProduct,
      insertFirst,
      currentVersionDisplayName,
      currentVersionCurrentLanguage,
      isPatch,
    }
  ) => {
    const referenceMap = getReferenceToInternalIds(state.edited.dataMap);
    let internalId;
    let parentInternalId = null;
    const updates = [];
    versions.forEach((version) => {
      internalId = uuid();
      if (!parentInternalId) {
        updates.push(
          update(['edited', 'roots'], addRoot(internalId, insertFirst))
        );
        updates.push(update(['expandedInternalIds'], toggle(internalId)));
      } else {
        updates.push(
          update(
            ['edited', 'hierarchyMap', parentInternalId],
            addChild(internalId)
          )
        );
      }
      updates.push(
        update(
          ['edited'],
          addUnit(
            internalId,
            null,
            null,
            enrichVersionWithGeneratedName(
              version,
              currentVersionDisplayName,
              currentVersionCurrentLanguage
            ),
            isPatch
          )
        )
      );
      parentInternalId = internalId;
    });
    // add current product to the end
    if (addCurrentProduct) {
      updates.push(
        update(
          ['edited', 'hierarchyMap', parentInternalId],
          addChild(referenceMap[reference])
        )
      );
    }
    return flow(...updates, set(['isDirty'], true))(state);
  },
  [LOGISTICAL_HIERARCHIES_DELETE_UNIT]: (
    state,
    {
      currentVersionReference,
      parentInternalId,
      internalId,
      isUsedInAgreedSharingUnit,
    }
  ) => {
    const updates = [];
    if (parentInternalId) {
      // Delete from parent if given.
      updates.push(
        update(
          ['edited', 'hierarchyMap', parentInternalId],
          removeChild(internalId)
        )
      );
    } else {
      // If there is only one children and it's not referenced else where,
      // then, make the child the new root.
      updates.push((localState) => {
        const dataMap = get(['edited', 'dataMap'], localState);
        const hierarchyMap = get(['edited', 'hierarchyMap'], localState);
        const childInternalId =
          get([internalId], hierarchyMap).length === 1
            ? hierarchyMap[internalId][0].id
            : null;
        if (
          childInternalId &&
          get([childInternalId, 'gtin'], dataMap) !== currentVersionReference &&
          get([childInternalId, 'productIdentifier'], dataMap) !==
            currentVersionReference &&
          !Object.entries(hierarchyMap).some(
            ([id, cs]) =>
              id !== internalId && cs.some((c) => c.id === childInternalId)
          )
        ) {
          return flow(
            update(['edited', 'roots'], swap(internalId, childInternalId)),
            update(['expandedInternalIds'], swap(internalId, childInternalId))
          )(localState);
        }
        // Delete from roots.
        return update(['edited', 'roots'], removeRoot(internalId))(localState);
      });
    }

    // Completely delete that unit if not present anymore (in roots or as children).
    // Don't delete if it's the current version.
    updates.push((localState) => {
      const roots = get(['edited', 'roots'], localState);
      const hierarchyMap = get(['edited', 'hierarchyMap'], localState);
      const dataMap = get(['edited', 'dataMap'], localState);
      const present =
        roots.includes(internalId) ||
        Object.values(hierarchyMap).some((cs) =>
          cs.some((c) => c.id === internalId)
        );
      if (
        !present &&
        get([internalId, 'gtin'], dataMap) !== currentVersionReference &&
        get([internalId, 'productIdentifier'], dataMap) !==
          currentVersionReference
      ) {
        const purgeUpdates = [update(['edited'], purgeUnit(internalId))];
        if (!parentInternalId && get([internalId, 'product_key_id'], dataMap)) {
          // Save the product key id to be deleted
          // No product id => hierarchy not yet saved => nothing to delete in the db
          purgeUpdates.push(
            update(['deletedRoots'], (deletedRoots) => [
              ...deletedRoots,
              get([internalId, 'product_key_id'], dataMap),
            ])
          );
        }
        return flow(...purgeUpdates)(localState);
      }
      return localState;
    });
    return flow(
      ...updates,
      update('agreedEditedUnits', (units) => {
        if (!isUsedInAgreedSharingUnit) {
          return units;
        }
        return units.includes(internalId) ? units : [...units, internalId];
      }),
      set(['isDirty'], true)
    )(state);
  },
  [LOGISTICAL_HIERARCHIES_UPDATE_QUANTITY]: (
    state,
    { parentInternalId, internalId, quantity, isUsedInAgreedSharingUnit }
  ) => {
    const level = state.edited.hierarchyMap[parentInternalId] || [];
    const index = level.findIndex((child) => child.id === internalId);
    if (index === -1) {
      return state;
    }
    return flow(
      update(['agreedEditedUnits'], (units) => {
        if (!isUsedInAgreedSharingUnit) {
          return units;
        }
        return units.includes(internalId) ? units : [...units, internalId];
      }),
      set(
        ['edited', 'hierarchyMap', parentInternalId, index, 'quantity'],
        quantity
      ),
      set(['edited', 'dataMap', internalId, 'isDirty'], true),
      set(['isDirty'], true)
    )(state);
  },
  [LOGISTICAL_HIERARCHIES_UPDATE_REFERENCE]: (
    state,
    {
      internalId,
      reference,
      productReferenceType,
      isUsedInAgreedSharingUnit,
      copyVersionFromId,
    }
  ) => {
    const productReferenceTypeFieldToErase =
      productReferenceType === ProductReferenceTypes.IDENTIFIER
        ? ProductReferenceTypes.GTIN.fieldName
        : ProductReferenceTypes.IDENTIFIER.fieldName;

    return flow(
      update('agreedEditedUnits', (units) => {
        if (!isUsedInAgreedSharingUnit) {
          return units;
        }
        return units.includes(internalId) ? units : [...units, internalId];
      }),
      update(
        ['edited', 'dataMap', internalId],
        flow(
          set(['version', productReferenceType.fieldName], reference),
          set(['version', 'reference'], reference),
          set(['version', productReferenceTypeFieldToErase], null),
          set([productReferenceType.fieldName], reference),
          set(['reference'], reference),
          set([productReferenceTypeFieldToErase], null),
          set(['isDirty'], true)
        )
      ),
      set(['isDirty'], true),
      (currentState) => {
        const version = get(
          ['edited', 'dataMap', copyVersionFromId, 'version'],
          currentState
        );
        if (version) {
          return update(
            ['edited', 'dataMap', internalId],
            flow(set(['isPatchableVersion'], true), set(['version'], version)),
            currentState
          );
        }
        return currentState;
      }
    )(state);
  },
  [LOGISTICAL_HIERARCHIES_SET_DELETED_ROOT]: (state, { productKeyId }) =>
    update(['deletedRoots'], flow(concat([productKeyId]), uniq))(state),
  [LOGISTICAL_HIERARCHIES_SET_EDITED_UNIT]: (state, { editedUnit }) =>
    set(['editedUnit'], editedUnit)(state),
  [UPDATE_ENTITY]: (state, action) => {
    const { entityKind, entityId, key, value, isDirty, ignoreField, isPatch } =
      action;
    if (entityKind !== ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT) {
      return state;
    }
    const [rootInternalId, reference] = parseLogisticalUnitEntityId(entityId);
    const { dataMap, hierarchyMap } = state.edited;
    const [unit, internalId] = findUnitByRootAndReference({
      dataMap,
      hierarchyMap,
      rootInternalId,
      reference,
    });
    if (!unit || !internalId) {
      return state;
    }
    const path = key.split('.').map(parseIfNumber);
    const rootPath = path[0];
    return flow(
      update(
        ['edited', 'dataMap', internalId],
        flow(
          update(
            ['version'],
            flow(set(path, value), updateIsSizedByInReducer(action))
          ),
          set(['isDirty'], isDirty)
        )
      ),
      // Update the root paths to ignore based on the action type.
      update(
        ['rootPathsToIgnore', internalId],
        updateRootPathsToIgnore(rootPath, isDirty, ignoreField)
      ),
      set(['edited', 'dataMap', internalId, 'isDirty'], isDirty),
      typeof isPatch !== 'undefined'
        ? set(['edited', 'dataMap', internalId, 'isPatch'], isPatch)
        : identity,
      set(['isDirty'], isDirty)
    )(state);
  },
  [BULK_UPDATE]: (state, { updates, isDirty, ignoreField }) => {
    if (updates.length === 0) {
      return state;
    }
    const stateUpdates = updates.reduce(
      (acc, { internalId, path, value }) =>
        acc.concat([
          update(
            ['rootPathsToIgnore', internalId],
            updateRootPathsToIgnore(path[0], isDirty, ignoreField)
          ),
          update(
            ['edited', 'dataMap', internalId],
            flow(set(['version', ...path], value), set(['isDirty'], isDirty))
          ),
        ]),
      []
    );
    return flow(...stateUpdates, set(['isDirty'], isDirty))(state);
  },
  [RECEIVE_DISPLAY_GROUPS]: (state, { displayGroups }) =>
    set(['displayGroups'], displayGroups, state),
  [LOGISTICAL_HIERARCHIES_PRIVATE_FIELD_EDIT]: (
    state,
    { internalId, field, isDirty }
  ) =>
    flow(
      set(['updatedPrivateFields', internalId, field], isDirty),
      update(['edited', 'roots'], flow(concat([internalId]), uniq))
    )(state),
  [SAVE_PRODUCT_SUCCESS]: (state) => set(['updatedPrivateFields'], {}, state),
});
