import classNames from 'classnames';
import { flatten, flow, negate, set, update } from 'lodash/fp';
import { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button, SwitchButton } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import { notificationError, notificationSuccess } from 'actions/notification';
import Modal from 'components/ui/modal';
import {
  ASSET_ORIGIN_STREAM_USER,
  ASSET_SCOPE,
  PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_STANDARD,
  PRODUCT_PICTURE_TYPE_OF_CONTENT_PACKAGED,
  PRODUCT_PICTURE_TYPE_OF_CONTENT_UNPACKAGED,
} from 'constants/media';
import { JSON_TO_LEGACY_FIELDS_MAP } from 'constants/productPicture';
import { validatePicture } from 'modules/assets/components/picture/utils';
import {
  selectHasJsonStoreInputFeature,
  selectHasMediaIpRights,
  selectHasSpecificMediaFeature,
} from 'modules/feature-flag/selectors';
import { setFilterRuleSetsFields } from 'modules/view-as/actions';
import { selectFilterRuleSetsFields } from 'modules/view-as/selectors';
import { selectIsRetailer, selectUser } from 'reducers/user/selectors';
import mediaApi from 'resources/mediaApi';
import { GlobalState, UserImmutable } from 'types';
import { Media } from 'types/media';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import { AssetTypeV2, FieldAction, PictureAssetV2 } from '../../AssetV2/types';
import { getIdenticalPicture } from '../../utils';

import PictureForm from './picture-form';
import './picture-modal.scss';

const mapStateToProps = createStructuredSelector({
  hasSpecificMediaFeature: selectHasSpecificMediaFeature,
  hasJsonStoreInputFeature: selectHasJsonStoreInputFeature,
  hasMediaIpRights: selectHasMediaIpRights,
  filterRuleSetsFields: selectFilterRuleSetsFields,
  user: selectUser,
  showAdditionalFieldsSwitch: negate(selectIsRetailer),
});

interface ConnectedProps {
  hasSpecificMediaFeature?: boolean;
  hasJsonStoreInputFeature?: boolean;
  hasMediaIpRights?: boolean;
  filterRuleSetsFields?: boolean;
  user: UserImmutable;
  showAdditionalFieldsSwitch?: boolean;
}

interface OwnProps {
  title: string;
  productKeyId: number | string;
  onSuccess: () => void;
  onClose: () => void;
  count: number;
  items?: AssetTypeV2[];
  fieldActionItems: FieldAction['items'];
  media?: PictureAssetV2 | null;
  readOnly?: boolean;
  index?: number;
}

type Props = ConnectedProps & OwnProps & { dispatch: (action: any) => void };

interface PictureModalState {
  loading: boolean;
  pictures: Media[];
  isAdding: boolean;
  isDirty: boolean;
}

export class LegacyPictureModal extends PureComponent<
  Props,
  PictureModalState
> {
  static defaultProps = {
    index: 0,
    media: null,
    items: [],
  };

  constructor(props) {
    super(props);
    this.updatePicture = this.updatePicture.bind(this);
    this.addPictureValid = this.addPictureValid.bind(this);
    this.uploadPicture = this.uploadPicture.bind(this);
    this.state = {
      loading: false,
      pictures: props.media ? [props.media] : [],
      isAdding: false,
      isDirty: false,
    };
  }

  onUpdateField = (index, path, value) => {
    if (path !== 'isPackshot') {
      this.setState(update(['pictures', index], set(path, value)));
    } else {
      this.onUpdatePackshot(index, value);
    }
  };

  onUpdateFormGroup = (
    index,
    model,
    value,
    _entityId,
    _entityKind,
    isDirty
  ) => {
    if (!this.state.isDirty && isDirty) {
      this.setState(set(['isDirty'], true));
    }
    this.onUpdateField(index, model, value);
  };

  onUpdatePackshot = (index, value) => {
    const checked = value;
    this.setState((state) => {
      const { media, items, count, dispatch } = this.props;
      if (!checked) {
        // We need to check if a packshot is already set outside of the model.
        // If no => do not delete this one.
        // Check media when it is an edit
        // Check items/counts when it is a create
        const identicalPicture = getIdenticalPicture(
          state.pictures[index],
          items
        );
        if (
          (media && media.isPackshot) ||
          (identicalPicture && identicalPicture.isPackshot) ||
          count === 0
        ) {
          dispatch(
            notificationError(
              i18n.t(
                'frontproducstream.asset_list.picture_modal.update_packshot_notification.error',
                {
                  defaultValue:
                    'This picture is the current packshot: choose another picture to change it.',
                }
              ),
              { context: 'modal' }
            )
          );
          return state;
        }
        // We untoggle all pictures except if the current packshot is among the uploaded pictures
        return update(
          ['pictures'],
          (pictures) =>
            pictures.map((picture) => {
              const identical = getIdenticalPicture(picture, items);
              return set(
                ['isPackshot'],
                identical ? identical.isPackshot : false,
                picture
              );
            }),
          state
        );
      }
      // We toggle the selected picture and untoggle the others
      return update(
        ['pictures'],
        (pictures) =>
          pictures.map((picture, i) =>
            set(['isPackshot'], i === index, picture)
          ),
        state
      );
    });
  };

  // The four method below must be extracted in actions/sagas
  addPictureValid() {
    const { productKeyId, hasJsonStoreInputFeature } = this.props;
    this.setState(set(['isAdding'], true));
    this.state.pictures.forEach((picture) => {
      const method = mediaApi.ProductPictureCreate.bind(mediaApi);
      const contentTypeValue = hasJsonStoreInputFeature
        ? get(picture, ['fileContentTypeCode', 'id'], null)
        : picture.contentType;
      const fileTypeValue = hasJsonStoreInputFeature
        ? get(picture, ['fileTypeCode', 'id'], null)
        : picture.fileType;
      const payload = {
        ...picture,
        product_key_id: productKeyId,
        origin: ASSET_ORIGIN_STREAM_USER.id,
        typeOfInformation:
          contentTypeValue === 1
            ? PRODUCT_PICTURE_TYPE_OF_CONTENT_PACKAGED.id
            : PRODUCT_PICTURE_TYPE_OF_CONTENT_UNPACKAGED.id,
        fileType:
          fileTypeValue ||
          PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_STANDARD.id,
        isFileBackgroundTransparent: false,
        canFilesBeEdited: false,
      };
      this.handleRequest(method, payload);
    });
  }

  updatePicture() {
    const { hasJsonStoreInputFeature } = this.props;
    this.setState(set(['isAdding'], true));
    // We should only have one picture here
    const picture = this.state.pictures[0];
    const contentTypeValue = hasJsonStoreInputFeature
      ? get(picture, ['fileContentTypeCode', 'id'], null)
      : picture.contentType;
    // Duplicate content
    picture.typeOfInformation =
      contentTypeValue === 1
        ? PRODUCT_PICTURE_TYPE_OF_CONTENT_PACKAGED.id
        : PRODUCT_PICTURE_TYPE_OF_CONTENT_UNPACKAGED.id;

    this.convertSequenceNumberToInt(picture);
    const method = mediaApi.ProductPictureUpdate.bind(mediaApi);
    this.handleRequest(method, picture);
  }

  uploadPicture(pictures) {
    // If “Add” button clicked (instead of file dropped), do nothing (wait for
    // actual file feed). In this case `pictures` is a SyntheticMouseEvent
    // (instead of an array).
    if (!pictures.length) {
      return;
    }
    const {
      productKeyId,
      count,
      items,
      fieldActionItems,
      dispatch,
      hasSpecificMediaFeature,
      hasJsonStoreInputFeature,
    } = this.props;
    this.setState(set(['loading'], true));
    pictures.forEach((file, i) => {
      const payload = {
        // The entity id is no longer relevant for uploading
        // product pictures but is there to maintain logging and
        // ACL checks. The uploaded picture is not stored or
        // assigned to anyone in the db at this point.
        entity_id: productKeyId,
      };
      mediaApi.ProductPictureUpload(payload, file).then(
        (response) => {
          const { data } = response.data;
          const picture = {
            ...data,
            product_key_id: productKeyId,
            uniformResourceIdentifier: data.url,
          };
          // Fill form default values
          fieldActionItems?.forEach((form) => {
            if (hasJsonStoreInputFeature && form.model) {
              picture[form.model] = form.defaultValue;
            } else {
              if (form.name) {
                picture[form.name] = form.defaultValue;
              }
            }
          });
          // The first picture ever uploaded for the current product will be set
          // as packshot by default if another one is not chosen manually by the
          // user
          // If picture was already uploaded, take the previous value
          const identicalPicture = getIdenticalPicture(picture, items);

          if (hasSpecificMediaFeature) {
            picture.scope = ASSET_SCOPE.PUBLIC;
          }

          picture.isPackshot = identicalPicture
            ? identicalPicture.isPackshot
            : count === 0 && i === 0;

          this.setState(
            flow(
              update(['pictures'], (pics) => [...pics, picture]),
              set(['loading'], false)
            )
          );
        },
        ({ data: { status, data } }) => {
          if (status === 413) {
            dispatch(
              notificationError(
                i18n.t(
                  'frontproducstream.asset_list.picture_modal.upload_notification.too_big_error',
                  {
                    defaultValue:
                      'Image contains too many pixels. Actual pixels quantity : {{actual}}. Maximum : {{max}}',
                    ...data,
                  }
                ),
                {
                  context: 'modal',
                }
              )
            );
          } else {
            dispatch(
              notificationError(
                i18n.t(
                  'frontproducstream.asset_list.picture_modal.upload_notification.error',
                  {
                    defaultValue:
                      'An error occured while uploading the picture.',
                  }
                ),
                {
                  context: 'modal',
                }
              )
            );
          }
          this.setState(set(['loading'], false));
        }
      );
    });
  }

  handleRequest(method, payload) {
    const { dispatch, media, hasJsonStoreInputFeature } = this.props;
    (hasJsonStoreInputFeature ? Object.values : Object.keys)(
      JSON_TO_LEGACY_FIELDS_MAP
    ).forEach((field) => {
      if (String(field) in payload) {
        delete payload[String(field)];
      }
    });
    method(payload).then(
      () => {
        dispatch(
          notificationSuccess(
            media
              ? i18n.t(
                  'frontproducstream.asset_list.picture_modal.save_notification.edited_success',
                  { defaultValue: 'Picture successfully edited.' }
                )
              : i18n.t(
                  'frontproducstream.asset_list.picture_modal.save_notification.created_success',
                  { defaultValue: 'Picture successfully added.' }
                )
          )
        );
        this.props.onSuccess();
      },
      () => {
        dispatch(
          notificationError(
            i18n.t(
              'frontproducstream.asset_list.picture_modal.save_notification.error',
              { defaultValue: 'An error occured while creating the picture.' }
            )
          )
        );
        this.props.onClose();
      }
    );
  }

  // The rendering methods below must be replaced by the component in modules/assets/picture/modal
  // The latter component must be enhanced with multi-pictures handling, isAdding handling packshot handling
  renderPictureList() {
    const {
      readOnly,
      index,
      fieldActionItems,
      hasJsonStoreInputFeature,
      hasMediaIpRights,
    } = this.props;
    return this.state.pictures.map((picture, i) => (
      <PictureForm
        key={picture.crc32}
        index={i}
        picture={picture}
        readOnly={readOnly}
        onUpdateField={this.onUpdateField}
        onUpdatePackshot={this.onUpdatePackshot}
        onUpdateFormGroup={this.onUpdateFormGroup}
        entityIndex={index}
        fields={fieldActionItems}
        hasJsonStoreInputFeature={hasJsonStoreInputFeature}
        hasMediaIpRights={hasMediaIpRights}
      />
    ));
  }

  renderPictureDrop() {
    const { readOnly } = this.props;
    return (
      <Dropzone
        multiple={false}
        onDrop={this.uploadPicture}
        disabled={readOnly}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: classNames(
                'AddPicture__dropzone',
                isDragActive && 'AddPicture__dropzone--active'
              ),
            })}
            data-testid="drop-div"
          >
            {this.state.loading ? (
              <Spinner big />
            ) : (
              <>
                <input {...getInputProps()} />
                <span className="Dropzone__label">
                  {i18n.t(
                    'frontproducstream.asset_list.modal.upload_choice.drop_files',
                    { defaultValue: 'Drop some files here' }
                  )}
                </span>
                <span className="Dropzone__button">
                  <span>
                    {i18n.t(
                      'frontproducstream.asset_list.modal.upload_choice.separator',
                      { defaultValue: 'or' }
                    )}
                  </span>
                  <Button
                    content={i18n.t(
                      'frontproducstream.asset_list.modal.upload_choice.select',
                      { defaultValue: 'select them' }
                    )}
                    onClick={this.uploadPicture}
                    primary
                  />
                </span>
              </>
            )}
          </div>
        )}
      </Dropzone>
    );
  }

  renderPictureSection() {
    return this.state.pictures.length > 0
      ? this.renderPictureList()
      : this.renderPictureDrop();
  }

  renderValidationSection(errors) {
    if (errors.length === 0) {
      return null;
    }
    return (
      <div className="FormText row FormField--raguelError">
        <div className="FormField__plugins Raguel">
          {errors.map((error) => (
            <span className="Raguel__message" key={error}>
              {' '}
              {error}{' '}
            </span>
          ))}
        </div>
      </div>
    );
  }

  onCheckOptionalField = (checked: boolean) => {
    const { user, dispatch } = this.props;
    dispatch(setFilterRuleSetsFields(!checked, user));
  };

  convertSequenceNumberToInt = (payload) => {
    if ('sequenceNumber' in payload) {
      const sequenceNumberToInt = parseInt(payload['sequenceNumber'], 10);
      payload['sequenceNumber'] = isNaN(sequenceNumberToInt)
        ? null
        : sequenceNumberToInt;
    }
  };

  render() {
    const {
      title,
      media,
      readOnly,
      hasJsonStoreInputFeature,
      filterRuleSetsFields = true,
      showAdditionalFieldsSwitch = true,
    } = this.props;
    const { isAdding, pictures, isDirty } = this.state;
    // If we use the json fields we do not want any validation calculated in the front
    const errors = hasJsonStoreInputFeature
      ? []
      : flatten(pictures.map((picture) => validatePicture(picture)));
    return (
      <Modal
        title={title}
        modalStyle="fullHeight"
        confirmButtonText={
          media
            ? i18n.t(
                'frontproducstream.asset_list.modal.main_button_save.label',
                { defaultValue: 'Save' }
              )
            : i18n.t(
                'frontproducstream.asset_list.modal.main_button_add.label',
                { defaultValue: 'Add' }
              )
        }
        confirmDisabled={(readOnly ? !isDirty : readOnly) || errors.length > 0}
        isProcessing={isAdding}
        onConfirm={media ? this.updatePicture : this.addPictureValid}
        onClose={this.props.onClose}
        hideFooter={pictures.length === 0}
        additionalFooterContent={
          showAdditionalFieldsSwitch && (
            <SwitchButton
              content={i18n.t(
                'frontproducstream.asset_list.modal.show_more_fields_button.label',
                {
                  defaultValue:
                    'Show optional fields to market more efficiently your product',
                }
              )}
              onChange={this.onCheckOptionalField}
              checked={!filterRuleSetsFields}
            />
          )
        }
      >
        <div className="AddPicture">{this.renderPictureSection()}</div>
      </Modal>
    );
  }
}

export const PictureModal = connect<ConnectedProps, {}, OwnProps, GlobalState>(
  mapStateToProps
)(LegacyPictureModal);
