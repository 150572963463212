import { useCallback } from 'react';

import { Select } from '@alkem/react-ui-select';

import CustomAutocomplete from 'components/ui/autocomplete/custom';
import { getId } from 'components/ui/form/field/utils/clean';
import InputText from 'components/ui/input/text';
import {
  BRACKET_COLUMNS_DISPLAYED,
  unitTypes,
} from 'modules/price-waterfalls/constants';
import { LevelItem } from 'modules/price-waterfalls/types';
import { displayPriceValue } from 'modules/price-waterfalls/utils';
import i18n from 'utils/i18n';

import './style.scss';

function earlyPaymentDiscountFilter(item: {
  data: { isEarlyPaymentDiscount?: boolean };
}) {
  return !!item.data.isEarlyPaymentDiscount;
}

export function EarlyPaymentDiscountRow({
  targetOrganizationId,
  disabled,
  model,
  sharingUnitId,
  value: { type, description, values, unit } = {},
  onUpdate,
  onDelete,
  bracketStartIndex = 0,
}: {
  targetOrganizationId?: number;
  disabled?: boolean;
  model: string;
  sharingUnitId?: number;
  value?: LevelItem;
  onUpdate?: (model: string, value: any) => any;
  onDelete?: () => void;
  bracketStartIndex?: number;
}) {
  const onUpdateSelectWithModel = useCallback(
    (_model: string) => (_value: any) =>
      onUpdate?.(_model, _value.value || _value),
    [onUpdate]
  );
  const onDeleteSelectWithModel = useCallback(
    (_model: string) => () => onUpdate?.(_model, null),
    [onUpdate]
  );
  const onUpdateTextWithModel = useCallback(
    (_model: string) => (_value: any) =>
      onUpdate?.(_model, _value.currentTarget?.value || ''),
    [onUpdate]
  );

  const typeModelId = `${model}.items.0.type`;
  const unitModelId = `${model}.items.0.unit`;
  const descriptionModelId = `${model}.items.0.description`;
  const valueModelId = `${model}.items.0.values`;

  return (
    <div data-testid="EarlyPaymentDiscountRow" className="EarlyPaymentDiscount">
      <div className="EarlyPaymentDiscountHeader">
        <div className="EarlyPaymentDiscountHeader__Type">
          {i18n.t('Early payment discount')}
        </div>
        <div className="EarlyPaymentDiscountHeader__Unit">{i18n.t('Type')}</div>
        <div className="EarlyPaymentDiscountHeader__Description">
          {i18n.t('Description')}
        </div>

        <div className="EarlyPaymentDiscountHeader__Values">
          {i18n.t('Value')}
        </div>
      </div>
      <div className="EarlyPaymentDiscountBody">
        <div className="EarlyPaymentDiscountBody__Metadata">
          <div className="EarlyPaymentDiscountBody__Type">
            <CustomAutocomplete
              testId="EarlyPaymentDiscountType"
              id="EarlyPaymentDiscountType"
              value={type ? [type] : []}
              className="InputField__input"
              onSelect={onUpdateSelectWithModel(typeModelId)}
              onUnselect={onDeleteSelectWithModel(typeModelId)}
              path="/buyingunit/v2/referentials"
              disabled={disabled}
              params={{
                referential: 'priceWatterFallLevelItemTypes',
                organization_id: targetOrganizationId,
              }}
              searchOnClick
              autoSelect
              dataFilterer={earlyPaymentDiscountFilter}
            />
          </div>
          <div className="EarlyPaymentDiscountBody__Unit">
            <Select
              id="EarlyPaymentDiscountBody__Unit"
              onValueAdd={onUpdateSelectWithModel(unitModelId)}
              onValueDelete={onDeleteSelectWithModel(unitModelId)}
              values={unit ? [unit] : []}
              options={unitTypes}
              placeholder=""
              disabled={disabled}
            />
          </div>
          <div className="EarlyPaymentDiscountBody__Description">
            <InputText
              value={description}
              onChange={onUpdateTextWithModel(descriptionModelId)}
              disabled={disabled}
              maxLength={70}
              id="EarlyPaymentDiscountDescription"
            />
          </div>
        </div>
        <div className="EarlyPaymentDiscountBody__Values">
          <i className={`mdi mdi-minus`} />
          {values
            ?.slice(
              bracketStartIndex,
              bracketStartIndex + BRACKET_COLUMNS_DISPLAYED
            )
            .map((value, index) => (
              <div
                key={`${getId(
                  valueModelId,
                  'SharingUnit',
                  sharingUnitId
                )}-values-${index + bracketStartIndex}`}
                className="EarlyPaymentDiscountBody__Values_Item"
              >
                <InputText
                  id="EarlyPaymentDiscountValue"
                  value={displayPriceValue(value.value)}
                  onChange={onUpdateTextWithModel(
                    `${valueModelId}.${index + bracketStartIndex}.value`
                  )}
                  disabled={disabled}
                  type="number"
                />
              </div>
            ))}
        </div>
        <div className="EarlyPaymentDiscountBody__Actions">
          {!disabled && (
            <i
              className="mdi mdi-delete EarlyPaymentDiscountBody__deleteIcon"
              onClick={onDelete}
              role="button"
              tabIndex={-1}
              title={i18n.t('Delete early payment discount')}
            />
          )}
        </div>
      </div>
    </div>
  );
}
