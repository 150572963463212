import Autocomplete from 'components/ui/autocomplete';
import referentialApi from 'resources/referentialApi';

import './pnqAutocomplete.scss';

const className = 'Autocomplete--pnq';

class PnqAutocomplete extends Autocomplete {
  static defaultProps = Object.assign({}, Autocomplete.defaultProps, {
    className,
    delegateSelection: true,
  });

  getList(search) {
    // FIXME quick fix for demo, use ReferentialApi.PATH().NUTRIENTS
    const promise = referentialApi.ReferentialGetList('nutrients', { search });
    promise.then((response) => {
      const list = response.data.data.map((item) => ({
        item: item.toJS(),
        label: item.get('label'),
        rank: item.get('rank'),
        key: item.get('id'),
        value: item.get('id'),
      }));
      this.updateList(list.toJS());
    });
    return promise;
  }
}

export default PnqAutocomplete;
