import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';
import { get, isEmpty } from 'utils/immutable';

import './index.scss';
import SelectedItem from './item';

export default class ListSelectedFilters extends PureComponent {
  static propTypes = {
    selectedFilterList: PropTypes.oneOfType([
      ImmutablePropTypes.list,
      PropTypes.array,
    ]).isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
  };

  onRemoveFilters = () => {
    this.props.onRemoveFilter({ reset: true });
  };

  onRemoveFilter = (filter) => {
    this.props.onRemoveFilter(filter.set('add', false));
  };

  render() {
    const hasFilters = !isEmpty(this.props.selectedFilterList);
    return (
      <div className="ListSelectedFilters">
        <h3>
          {i18n.t('frontproductstream.core.list_filters_selection.title', {
            defaultValue: 'Selected filters',
          })}
        </h3>
        {!hasFilters && (
          <span>
            {i18n.t(
              'frontproductstream.core.list_filters_selection.empty_label',
              { defaultValue: 'No filters selected' }
            )}
          </span>
        )}
        {hasFilters && (
          <Button
            link
            className="ListSelectedFilters_remove"
            onClick={this.onRemoveFilters}
          >
            {i18n.t(
              'frontproductstream.core.list_filters_selection.remove_all_button',
              { defaultValue: 'Remove' }
            )}
          </Button>
        )}
        {hasFilters && (
          <ul className="ListSelectedFilters__list">
            {this.props.selectedFilterList.map((filter) => (
              <SelectedItem
                key={`list-selected-filter-${get(filter, 'key')}-${get(
                  filter,
                  'value'
                )}`}
                filter={filter}
                onRemove={this.onRemoveFilter}
              />
            ))}
          </ul>
        )}
      </div>
    );
  }
}
