import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectHasTextileVariantImportRelease } from 'modules/feature-flag/selectors';
import { SimpleImportModal } from 'modules/import-modal';
import {
  selectCurrentProductVersionGTIN,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectKindId,
  selectLifeCycle,
  selectTargetMarketId,
  selectTypePackagingId,
} from 'reducers/productVersion';
import etlApi from 'resources/etlApi';
import i18n from 'utils/i18n';

const mapStateToProps = createStructuredSelector({
  gtin: selectCurrentProductVersionGTIN,
  targetMarketId: selectTargetMarketId,
  isConsumerUnit: selectIsConsumerUnit,
  isDisplayUnit: selectIsDisplayUnit,
  packagingTypeId: selectTypePackagingId,
  kindId: selectKindId,
  lifeCycle: selectLifeCycle,
  hasTextileVariantImportRelease: selectHasTextileVariantImportRelease,
});

export class TextileVariantImportModal extends PureComponent {
  static propTypes = {
    gtin: PropTypes.string,
    targetMarketId: PropTypes.number,
    isConsumerUnit: PropTypes.bool,
    isDisplayUnit: PropTypes.bool,
    packagingTypeId: PropTypes.number,
    kindId: PropTypes.number,
    lifeCycle: PropTypes.number,
    hasTextileVariantImportRelease: PropTypes.bool,
    onCloseModal: PropTypes.func.isRequired,
    fieldNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };

  generateTemplate = () => {
    const route = this.props.hasTextileVariantImportRelease
      ? '/etl/v3/import/textile/template/download'
      : '/etl/v2/import/textile/template/download';
    return etlApi.getBlob(
      route,
      '',
      {
        field_names: this.props.fieldNames,
        target_market_id: this.props.targetMarketId,
      },
      true
    );
  };

  render() {
    const {
      gtin,
      targetMarketId,
      isConsumerUnit,
      isDisplayUnit,
      packagingTypeId,
      kindId,
      lifeCycle,
      onCloseModal,
    } = this.props;

    const data = {
      model_gtin: gtin,
      extra_data: {
        isConsumerUnit,
        isDisplayUnit,
        typePackaging: { id: packagingTypeId },
        kind: { id: kindId },
        lifeCycle,
      },
      target_market_id: targetMarketId,
    };

    const payload = {
      spider: this.props.hasTextileVariantImportRelease
        ? 'TEXTILEVARIANT2'
        : 'TEXTILEVARIANT',
      merge_mode: JSON.stringify({
        patch: true,
        // Do not remove links that are not sent.
        deep_patch_attributes: ['textileVariantList'],
      }),
      synchronous: false,
      spider_params: JSON.stringify(data),
    };

    return (
      <SimpleImportModal
        modalTitle={i18n.t('Add variants to the model')}
        onCloseModal={onCloseModal}
        processFileUrl="/etl/v2/import/product/web"
        generateTemplate={this.generateTemplate}
        fileName="textile_variant_template.xlsx"
        processFilePayload={payload}
      />
    );
  }
}

export default connect(mapStateToProps)(TextileVariantImportModal);
