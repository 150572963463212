import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';

import { RuleTemplateLabel } from 'modules/validation';
import i18n from 'utils/i18n';

import './help.scss';

export default class HelpSuggestion extends PureComponent {
  static propTypes = {
    ruleResult: ImmutablePropTypes.map.isRequired,
    readOnly: PropTypes.bool,
    isRetailer: PropTypes.bool,
    displayActions: PropTypes.bool,
    onAcceptSuggestion: PropTypes.func.isRequired,
    onDismissSuggestion: PropTypes.func.isRequired,
    accepted: PropTypes.bool.isRequired,
    languageCode: PropTypes.string.isRequired,
  };

  renderActions() {
    const { languageCode } = this.props;
    const {
      readOnly,
      isRetailer,
      ruleResult,
      displayActions,
      onAcceptSuggestion,
      onDismissSuggestion,
    } = this.props;
    const { templateLabel } = ruleResult;

    if (!displayActions || (readOnly && !isRetailer)) {
      return null;
    }

    let acceptLabel = i18n.t(
      'frontproductstream.plugins.suggestion.create_button.read_only_label',
      { defaultValue: 'Request change' }
    );
    if (!readOnly) {
      acceptLabel = [
        RuleTemplateLabel.SIMPLE,
        RuleTemplateLabel.VALUE,
      ].includes(templateLabel)
        ? i18n.t(
            'frontproductstream.plugins.suggestion.create_button.replace_label',
            { defaultValue: 'Replace' }
          )
        : i18n.t(
            'frontproductstream.plugins.suggestion.create_button.add_label',
            { defaultValue: 'Add' }
          );
    }

    let dismissLabel = i18n.t(
      'frontproductstream.plugins.suggestion.dissmiss_button.label',
      { defaultValue: 'Dismiss' }
    );
    if (readOnly) {
      dismissLabel = i18n.t(
        'frontproductstream.plugins.suggestion.dissmiss_button.read_only_label',
        { defaultValue: 'Cancel' }
      );
    }

    return (
      <div className="RaguelHelp__suggestionActionContainer">
        {ruleResult.getIn([
          'data',
          'suggestedValueByLanguage',
          languageCode,
        ]) && (
          <Button
            link
            content={acceptLabel}
            className="RaguelHelp__suggestionAction RaguelHelp__suggestionAction--accept"
            onClick={onAcceptSuggestion}
          />
        )}
        <Button
          link
          content={dismissLabel}
          className="RaguelHelp__suggestionAction RaguelHelp__suggestionAction--dismiss"
          onClick={onDismissSuggestion}
        />
      </div>
    );
  }

  render() {
    const { ruleResult, accepted, languageCode } = this.props;

    return (
      <span
        id={`raguel-suggestion-message-${ruleResult.get('id')}`}
        className="RaguelHelp__suggestionContainer"
      >
        <div className="RaguelHelp__suggestion">
          <div className="RaguelHelp__suggestionLabel">
            <i className="mdi mdi-information-outline RaguelHelp__suggestionIcon" />
            <span className="RaguelHelp__suggestionMessage">
              {ruleResult.getIn([
                'error_by_language',
                languageCode,
                'errorMessage',
              ])}
            </span>
          </div>
          {!accepted && this.renderActions()}
        </div>
      </span>
    );
  }
}
