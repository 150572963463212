import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { SwitchButton } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';

import './visibility-modal.scss';

class VisibilityModal extends PureComponent {
  static propTypes = {
    isBusy: PropTypes.bool.isRequired,
    isEligibleForSourcing: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
  };

  state = { exclusive: false, allCatalog: false };

  onApply = () => {
    const { onApply } = this.props;
    const { exclusive, allCatalog } = this.state;
    onApply(exclusive, allCatalog);
  };

  onChooseExclusive = () => {
    this.setState((prevState) => ({ exclusive: !prevState.exclusive }));
  };

  onChangeAllCatalog = () => {
    this.setState((prevState) => ({ allCatalog: !prevState.allCatalog }));
  };

  renderBody() {
    const { isBusy, isEligibleForSourcing } = this.props;
    const { exclusive } = this.state;
    return (
      <div className="NetworkVisibility">
        {isEligibleForSourcing && (
          <p>
            {i18n.t(
              'Per default, your basic product information can be made available to all retailers that ask for it. If your product is exclusive to some retailers, then opt-out by activating this switch.'
            )}
          </p>
        )}
        {!isEligibleForSourcing && (
          <>
            <p className="ProductCreationNetworkVisibility__help">
              {i18n.t(
                'Per default, your basic product information can be made available to all retailers that ask for it. If your product is exclusive to some retailers, then opt-out by activating this switch.'
              )}
            </p>
          </>
        )}
        <p className="alk-flex">
          <span className="VisibilityModal__switchLabel">
            {i18n.t('Make the product exclusive')}
          </span>
          <SwitchButton
            id="ProductCreationNetworkVisibility-exclusive-button"
            checked={exclusive}
            onChange={this.onChooseExclusive}
            disabled={isBusy}
          />
        </p>
      </div>
    );
  }

  renderCheckbox() {
    const { isBusy } = this.props;
    const { allCatalog } = this.state;
    return (
      <Checkbox
        id="applyToAllCatalog"
        checked={allCatalog}
        onChange={this.onChangeAllCatalog}
        label={i18n.t('Apply to all my undecided products')}
        disabled={isBusy}
      />
    );
  }

  render() {
    const { isBusy } = this.props;
    return (
      <Modal
        title={i18n.t('Product exclusivity')}
        confirmButtonText={isBusy ? i18n.t('Applying...') : i18n.t('Apply')}
        isProcessing={isBusy}
        onConfirm={this.onApply}
        additionalFooterContent={this.renderCheckbox()}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default VisibilityModal;
