import PropTypes from 'prop-types';

import { Button } from '@alkem/react-ui-button';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';

import './index.scss';

function CollapsedListFilters(props) {
  const { onExpand, selectedFilters, action } = props;
  return (
    <div className="CollapsedListFilters">
      {action && (
        <Button
          id="collapsed-list-filters-action"
          href={action.link}
          className="CollapsedListFilters__action"
          onClick={action.onClick}
        >
          <LazyTooltip
            id="collapsed-list-filters-action-tooltip"
            tooltipLabel={action.label}
          >
            <i className="mdi mdi-plus-circle" />
          </LazyTooltip>
        </Button>
      )}
      <div className="CollapsedListFilters__selected" onClick={onExpand}>
        <span>
          {i18n.t(
            'frontproductstream.core.list_filters_collapsed.filter_count',
            {
              defaultValue: 'Filters ({{count}} selected)',
              count: selectedFilters,
            }
          )}
        </span>
      </div>
    </div>
  );
}

CollapsedListFilters.propTypes = {
  selectedFilters: PropTypes.number,
  onExpand: PropTypes.func.isRequired,
  action: PropTypes.shape({
    link: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
  }),
};

CollapsedListFilters.defaultProps = {
  selectedFilters: 0,
};

export default CollapsedListFilters;
