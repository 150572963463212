import classNames from 'classnames';
import { ReactNode } from 'react';

import './style.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

export function NavLabel(props: Props) {
  const className = classNames(
    'AnchorNavItem__content',
    'NavLabel',
    props.className
  );
  return <span className={className}>{props.children}</span>;
}
