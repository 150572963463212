import { RESET_CATALOG } from 'modules/catalog/product/actions/types';
import { Supplier } from 'modules/supplier-directory/types';
import { flow } from 'utils/fp';
import { setFp } from 'utils/immutable';
import { createReducer } from 'utils/redux';

import { MODAL_CHAT_SENT } from './constants';
import {
  CLOSE_MODAL,
  FETCH_SUPPLIER_NOT_RECONCILED,
  FETCH_SUPPLIER_NOT_RECONCILED_SUCCESS,
  GET_REFUSED_INFORMATION, // Modal
  OPEN_MODAL,
  SEND_MESSAGE_DONE,
  SEND_MESSAGE_IN_PROGRESS,
  SEND_MESSAGE_START,
  UPDATE_MESSAGE,
} from './events';
import {
  MessageToSend,
  RefusedRecipient,
  SendMessageInProgressActionPayload,
} from './types';

export interface ModuleState {
  isModalOpen: boolean;
  typeModal: string;
  supplier: Supplier | null;
  supplierId: number | null;
  supplierLoading: boolean;
  refusedRecipients: RefusedRecipient[];
  gtin: string;
  messagesToSend: MessageToSend[];
  messagesResult: SendMessageInProgressActionPayload[];
  inProgress: boolean;
}

export const initialState: ModuleState = {
  isModalOpen: false,
  typeModal: '',
  supplierId: null,
  supplier: null,
  supplierLoading: false,
  refusedRecipients: [],
  gtin: '',
  messagesToSend: [],
  messagesResult: [],
  inProgress: false,
};

const clearState = (state) => {
  return flow(
    setFp('isModalOpen', false),
    setFp('typeModal', ''),
    setFp('supplierLoading', false),
    setFp('supplierId', null),
    setFp('supplier', null),
    setFp('refusedRecipients', []),
    setFp('gtin', ''),
    setFp('messagesToSend', []),
    setFp('messagesResult', []),
    setFp('inProgress', false)
  )(state);
};

export default createReducer<ModuleState>(initialState, {
  [OPEN_MODAL]: (state, { typeModal }) =>
    flow(setFp('isModalOpen', true), setFp('typeModal', typeModal))(state),
  [CLOSE_MODAL]: (state) => clearState(state),
  [RESET_CATALOG]: (state) => clearState(state),
  [FETCH_SUPPLIER_NOT_RECONCILED]: (state, { id }) =>
    flow(setFp('supplierId', id), setFp('supplierLoading', true))(state),
  [FETCH_SUPPLIER_NOT_RECONCILED_SUCCESS]: (state, { supplier, loading }) =>
    flow(setFp('supplier', supplier), setFp('supplierLoading', loading))(state),
  [GET_REFUSED_INFORMATION]: (state, { refusedRecipients, gtin }) =>
    flow(
      setFp('refusedRecipients', refusedRecipients),
      setFp('gtin', gtin)
    )(state),
  [UPDATE_MESSAGE]: (state, action) =>
    flow(setFp('message', action.payload))(state),
  [SEND_MESSAGE_START]: (state, { messagesToSend }) =>
    flow(
      setFp('messagesResult', []),
      setFp('messagesToSend', messagesToSend),
      setFp('inProgress', true),
      setFp('typeModal', MODAL_CHAT_SENT)
    )(state),
  [SEND_MESSAGE_IN_PROGRESS]: (state, messageStatus) =>
    flow(
      setFp('inProgress', true),
      setFp(
        'messagesResult',
        state.messagesResult.concat(messageStatus.messageStatus)
      )
    )(state),
  [SEND_MESSAGE_DONE]: (state) => flow(setFp('inProgress', false))(state),
});
