import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

export const ProductHistory = asyncComponent(async () =>
  registerModule(await import(/* webpackChunkName: "history" */ './async'))
);

export { ProductHistoryDiffCard } from './components/diff/diff-card';
export { default as HistoryDiffHeader } from './components/diff/header';
export { HistoryDiffLine } from './components/diff/history-diff-line';
