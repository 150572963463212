import { Map } from 'immutable';
import { useMemo } from 'react';

import {
  ProductVersion,
  ProductsMap,
  isUnshareable,
} from 'modules/bulk-unshare';

/**
 * Tells which products can be unshared and which cannot
 * @param productMap an id => entity map of product versions
 * @param selectedMap an id => bool map telling which product version are selected
 * @return a pair of products map, the first element is the map of selected products that can be unshared, the second is the map of product version that cannot be unshared
 */
const useSplit = (
  productMap: ProductsMap,
  selectedMap: Map<number, boolean>
): [ProductsMap, ProductsMap] =>
  useMemo(() => {
    const isSelected = (pv: ProductVersion | undefined) =>
      (pv && selectedMap.get(pv.get('id') as number)) || false;

    const onlySelectedPVs = Map(
      productMap.filter((pv) => isSelected(pv))
    ) as unknown as ProductsMap;

    return [
      Map(onlySelectedPVs.filter(isUnshareable)) as unknown as ProductsMap,
      Map(onlySelectedPVs.filterNot(isUnshareable)) as unknown as ProductsMap,
    ];
  }, [productMap, selectedMap]);

export default useSplit;
