import { composeWithDevTools } from 'redux-devtools-extension';

import actionsBlacklist from './blacklist';

const options = {
  // for full list of options see
  // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
  actionsBlacklist,
  // maxAge: 1000,

  // needed not to dispatch actions on each replaceReducer, see
  // https://github.com/reduxjs/redux-devtools/issues/304#issuecomment-251715413
  shouldHotReload: false,
};

export default composeWithDevTools(options);
