import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { notificationError, notificationSuccess } from 'actions/notification';
import { isRetailer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import FeatureModal from 'modules/feature-flag/components/manufacturer-video/modal';
import {
  FEATURE_MANUFACTURER_VIDEO,
  FEATURE_VIDEO_DYNAMIC_FIELDS,
} from 'modules/feature-flag/constants';
import mediaApi from 'resources/mediaApi';
import i18n from 'utils/i18n';
import moment from 'utils/moment';

import VideoModal from '../actions/video/video-modal';

import videoHtmlUrl from './video.html';

const mapStateToProps = (state) => ({
  user: state.user,
});

class VideoAsset extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,

    dispatch: PropTypes.func,
    updateEntities: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    readOnly: false,
    disabled: false,
    updateEntities() {},
    onDelete() {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isFeatureModalOpened: false,
      isOpen: false,
    };
    this.deleteEntity = this.deleteEntity.bind(this);
    this.showFeatureModal = this.toggleFeatureModal.bind(this, true);
    this.hideFeatureModal = this.toggleFeatureModal.bind(this, false);
  }

  deleteEntity() {
    const { onDelete, asset, dispatch, updateEntities } = this.props;
    onDelete(true);
    return mediaApi.ProductVideoDelete(asset.id).then(
      () => {
        updateEntities(asset._type);
        onDelete(false);
        dispatch(
          notificationSuccess(
            i18n.t(
              'frontproductstream.asset_list.video.delete_notification.success',
              { defaultValue: 'Video deleted' }
            )
          )
        );
      },
      (error) => {
        onDelete(false);
        if (error.status === 403) {
          dispatch(
            notificationError(
              i18n.t(
                'frontproductstream.asset_list.video.delete_notification.permission_error',
                {
                  defaultValue:
                    "You don't have the permission to delete this video",
                }
              )
            )
          );
        } else {
          dispatch(
            notificationError(
              i18n.t(
                'frontproductstream.asset_list.delete_notification.error',
                { defaultValue: 'An error occured:' }
              ) + error.message
            )
          );
        }
      }
    );
  }

  hideModal = () => {
    this.setState({ isOpen: false });
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  onSuccess = () => {
    this.props.updateEntities('ProductVideo');
    this.hideModal();
  };

  toggleFeatureModal(isFeatureModalOpened = true) {
    this.setState({ isFeatureModalOpened });
  }

  renderThumbnailComponent = (asset) => {
    const Uri = asset.uniformResourceIdentifier;
    const isBrainSonicUri = !Uri.startsWith('http');
    const thumbnailUrl = asset.thumbnailUniformResourceIdentifier;

    return isBrainSonicUri ? (
      <iframe
        src={`${videoHtmlUrl}?${Uri}`}
        className="Asset__VideoIframe"
        title="Alkemics - Video"
        frameBorder="0"
        scrolling="no"
        data-testid="video-asset-brainsonic"
      ></iframe>
    ) : (
      <img
        className="Asset__video__thumbnail"
        src={thumbnailUrl}
        alt="thumbnail"
        data-testid="video-asset-thumbnail-image"
      />
    );
  };

  renderEditModal(title) {
    const { asset } = this.props;
    return (
      <VideoModal
        asset={asset}
        title={title}
        productKeyId={asset.product_key_id}
        onSuccess={this.onSuccess}
        onClose={this.hideModal}
      />
    );
  }

  render() {
    const { asset, readOnly, user, disabled } = this.props;
    const { isFeatureModalOpened, isOpen } = this.state;

    const openModalButton = (
      <div className="Asset__button--rightMargin" id={`edit-video-${asset.id}`}>
        <Button
          secondary
          content={
            readOnly
              ? i18n.t('frontproductstream.asset_list.view_button.label', {
                  defaultValue: 'View',
                })
              : i18n.t('frontproductstream.asset_list.edit_button.label', {
                  defaultValue: 'Edit',
                })
          }
          onClick={this.openModal}
          disabled={disabled}
          displaySpinner={disabled}
          testid="video-asset-edit"
        />
        {isOpen &&
          this.renderEditModal(
            readOnly
              ? i18n.t('frontproductstream.asset_list.video_modal.view_title', {
                  defaultValue: 'Video',
                })
              : i18n.t('frontproductstream.asset_list.video_modal.edit_title', {
                  defaultValue: 'Edit Video',
                })
          )}
      </div>
    );

    const deleteAssetButton = (
      <div
        className="Asset__button--rightMargin"
        id={`delete-video-${asset.id}`}
      >
        <Button
          secondary
          content={i18n.t('frontproductstream.asset_list.delete_button.label', {
            defaultValue: 'Delete',
          })}
          onClick={this.deleteEntity}
          disabled={disabled}
          displaySpinner={disabled}
          testid="video-asset-delete"
        />
      </div>
    );

    const isARetailer = isRetailer(user);
    const hasFeatureVideo =
      isARetailer || hasFeature(this.props.user, FEATURE_MANUFACTURER_VIDEO);
    const hasDynamicFields = hasFeature(
      this.props.user,
      FEATURE_VIDEO_DYNAMIC_FIELDS
    );

    return (
      <div>
        {hasFeatureVideo ? (
          this.renderThumbnailComponent(asset)
        ) : (
          <>
            <div className="Asset__noVideo" onClick={this.showFeatureModal}>
              <i className="mdi mdi-star-outline" />
              <span>
                {' '}
                {i18n.t('frontproductstream.asset_list.feature_required.text', {
                  defaultValue: 'Upgrade now to reactivate',
                })}{' '}
              </span>
            </div>
            {isFeatureModalOpened && (
              <FeatureModal onClose={this.hideFeatureModal} />
            )}
          </>
        )}
        <div className="Asset__videoBody">
          <p className="Asset__tight Asset__tight--definition">
            <span>
              {i18n.t('frontproductstream.asset_list.creation_date.text', {
                defaultValue: 'Added on',
              })}{' '}
              {moment(asset.createdAt).format('L')}
            </span>
          </p>
          <p className="Asset__tight--definition">
            <span>
              {i18n.t('frontproductstream.asset_list.video.uri.label', {
                defaultValue: 'Video ID',
              })}{' '}
              :
            </span>
            {asset.uniformResourceIdentifier.startsWith('http') ? (
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={asset.uniformResourceIdentifier}
                >
                  {asset.uniformResourceIdentifier}
                </a>
              </span>
            ) : (
              <span>{asset.uniformResourceIdentifier}</span>
            )}
          </p>
          <div className="Asset__buttons">
            {hasFeatureVideo &&
              (isARetailer || hasDynamicFields) &&
              openModalButton}
            {!readOnly && deleteAssetButton}
          </div>
        </div>
      </div>
    );
  }
}

export { VideoAsset };
export default connect(mapStateToProps)(VideoAsset);
