// IDEA (maybe) split this module into smaller chunks
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { NOTIFICATION_UPDATE_INTERVAL } from 'constants/config/default';
import {
  ROOM_TYPE_ORGANIZATION,
  ROOM_TYPE_PRODUCTVERSION,
  ROOM_TYPE_PRODUCTVERSION_SOURCING,
  ROOM_TYPE_RFP,
} from 'constants/room';
import { get } from 'utils/fp';
import { withCatch } from 'utils/saga';

import { selectUser } from '../../../reducers/user/selectors';
import { FEATURE_PERMISSIONS_V3_ORGANIZATION } from '../../feature-flag';
import { hasPermissionModule, hasPermissionV3 } from '../../permissions';
import {
  CHAT_PERMISSION,
  ORGANIZATION_PERMISSION,
} from '../../permissions/const';
import {
  addLocalRoom,
  addMessageToRoom,
  addMessagesToRoom,
  addRemoteRoom,
  addRooms,
  convertToRemoteRoom,
  fetchDataError,
  fetchRooms,
  goToFirstPage,
  noMoreMessages,
  notifyUnackedCount,
  selectRoom,
  setAsFirstRoom,
  setInitialData,
  setMessagesForRoom,
  updateRecipients,
  updateRoom,
} from '../actions';
import * as api from '../api';
import {
  ACK_ROOM,
  CHANGE_ROOM_PAGE,
  FETCH_DATA,
  FILTER_RECIPIENTS,
  FILTER_RECIPIENTS_REQUEST_END,
  FILTER_RECIPIENTS_REQUEST_START,
  LOAD_MORE_MESSAGES,
  ON_SELECT_ROOM,
  ROOMS_PER_PAGE,
  SELECT_RECIPIENT,
  SEND_MESSAGE,
} from '../constants';
import {
  getAllRoomsById,
  getFetchedAhead,
  getLocalRooms,
  getMessagesForSelectedRoom,
  getPendingRequests,
  getRemoteRooms,
  getSelectedRoomId,
  getTotalRemoteRooms,
} from '../selectors/room';
import {
  CANCEL_FETCH_COUNT_UNACKED,
  FETCH_COUNT_UNACKED,
  SEND_QUICK_MESSAGE,
  fetchCountUnacked,
} from '../shared';
import {
  trackAck,
  trackOrganizationMessageSent,
  trackProductComment,
  trackRFPComment,
} from '../tracking';
import { isLocalRoom, prefetchRooms } from '../utils';

export default function* chatMainSaga() {
  const withNotif = { withNotification: true };
  yield takeEvery(FETCH_DATA, withCatch(fetchDataSaga, withNotif));
  yield takeEvery(ON_SELECT_ROOM, withCatch(selectRoomSaga, withNotif));
  yield takeEvery(SELECT_RECIPIENT, withCatch(selectRecipient, withNotif));
  yield takeLatest(FILTER_RECIPIENTS, withCatch(filterRecipients, withNotif));
  yield takeEvery(SEND_MESSAGE, withCatch(sendMessage, withNotif));
  yield takeEvery(SEND_QUICK_MESSAGE, withCatch(sendQuickMessage, withNotif));
  yield takeEvery(FETCH_COUNT_UNACKED, withCatch(pollCountUnacked));
  yield takeEvery(ACK_ROOM, withCatch(ackRoom));
  yield takeEvery(CHANGE_ROOM_PAGE, withCatch(onChangeRoomPage, withNotif));
  yield takeEvery(LOAD_MORE_MESSAGES, withCatch(loadMoreMessages, withNotif));
}

// ---- fetch data ----

export function* fetchDataSaga({ payload }) {
  const { entityId, roomType, recipientId } = payload;

  let recipient;
  if (recipientId) {
    const results = yield call(api.listRecipients, {
      entityId,
      roomType,
      recipientId,
    });
    if (results.size > 0) {
      recipient = results.first();
    }
  }

  try {
    const [recipients, roomData] = yield all([
      call(api.listRecipients, { entityId, roomType }),
      call(fetchRoomData, entityId, roomType),
    ]);
    const { rooms, totalRoomCount, selectedRoomId, messages } = roomData;
    yield put(
      setInitialData(
        recipients,
        rooms,
        totalRoomCount,
        selectedRoomId,
        messages
      )
    );

    if (recipient) {
      yield call(selectRecipient, {
        payload: {
          recipient,
          roomType,
        },
      });
    }
  } catch (e) {
    yield put(fetchDataError());
    throw e;
  }
}

export function* fetchRoomData(entityId, roomType) {
  // we fetch the first two pages initially because we always want to have
  // the current and the next page "fetched"
  const limit = ROOMS_PER_PAGE * 2;
  const { rooms, totalRoomCount } = yield call(api.listRooms, {
    entityId,
    roomType,
    limit,
  });
  if (rooms.size === 0) {
    return { rooms, totalRoomCount };
  }
  const selectedRoomId = rooms.first().get('id');
  const messages = yield call(api.listMessages, { roomId: selectedRoomId });
  return { rooms, totalRoomCount, selectedRoomId, messages };
}

export function* onChangeRoomPage({ payload } = {}) {
  const localRooms = yield select(getLocalRooms);
  const remoteRooms = yield select(getRemoteRooms);
  const totalRemoteRooms = yield select(getTotalRemoteRooms);
  const pendingRequests = yield select(getPendingRequests);
  const fetchedAhead = yield select(getFetchedAhead);

  // calculate number of required rooms and offset to fill next page
  const prefetchParams = [
    ROOMS_PER_PAGE,
    payload.page,
    localRooms.size,
    remoteRooms.size,
    totalRemoteRooms,
    pendingRequests,
    fetchedAhead,
  ];
  const { roomsToLoad, offset } = yield call(prefetchRooms, ...prefetchParams);

  if (roomsToLoad) {
    yield put(fetchRooms());
    const { entityId, roomType } = payload;
    const params = { entityId, roomType, offset, limit: roomsToLoad };
    const { rooms } = yield call(api.listRooms, params);
    yield put(addRooms(rooms));
  }
}

function* filterRecipients({ payload } = {}) {
  const { recipientNameLike, entityId, roomType } = payload;
  yield put({ type: FILTER_RECIPIENTS_REQUEST_START });
  const recipients = yield call(api.listRecipients, {
    recipientNameLike,
    entityId,
    roomType,
  });
  yield put({ type: FILTER_RECIPIENTS_REQUEST_END });
  yield put(updateRecipients(recipients));
}

// ---- select room ----

export function* selectRoomSaga({ payload }) {
  const { roomId } = payload;
  const rooms = yield select(getAllRoomsById);
  const room = rooms.get(roomId);
  if (!isLocalRoom(room)) {
    yield call(fetchMessagesForRoom, roomId);
  }
  yield put(selectRoom(roomId));
}

// ---- select recipient ----

export function* selectRecipient({ payload }) {
  const { recipient, roomType } = payload;
  const roomId = get('room_id', recipient);
  if (roomId) {
    yield call(selectRemoteRoom, roomId, roomType);
  } else {
    yield call(addOrSelectLocalRoom, recipient);
  }
}

export function* selectRemoteRoom(roomId, roomType) {
  const remoteRooms = yield select(getRemoteRooms);
  if (!remoteRooms.has(roomId)) {
    yield call(getRoomFromServer, roomId, roomType);
  }
  yield call(onRemoteRoomSelected, roomId);
}

export function* onRemoteRoomSelected(roomId) {
  yield put(setAsFirstRoom(roomId));
  yield put(goToFirstPage());
  yield put(selectRoom(roomId));
  const messages = yield call(fetchMessagesForRoom, roomId);
  yield put(setMessagesForRoom(roomId, messages));
}

export function* getRoomFromServer(roomId, roomType) {
  const params = { roomType, roomId };
  const { room } = yield call(api.getRoom, params);
  yield put(addRemoteRoom(room));
}

export function* addOrSelectLocalRoom(recipient) {
  const localRooms = yield select(getLocalRooms);
  const recipientId = get('id', recipient);
  if (!localRooms.has(recipientId)) {
    yield put(addLocalRoom(recipient));
    yield put(goToFirstPage());
  }
  yield put(selectRoom(recipientId));
}

// ---- fetch messages ----

export function* fetchMessagesForRoom(roomId) {
  const messages = yield call(api.listMessages, { roomId });
  yield put(setMessagesForRoom(roomId, messages));
}

// ---- send message ----

export function* sendMessage({ payload }) {
  const { room, message, entityId, roomType } = payload;
  let roomId = room.get('id');
  let lastMessage;
  if (isLocalRoom(room)) {
    ({ lastMessage, roomId } = yield call(
      createRemoteRoom,
      roomId,
      entityId,
      roomType,
      message
    ));
  } else {
    lastMessage = yield call(api.createMessage, roomId, message);
  }
  yield put(addMessageToRoom(roomId, lastMessage));
  // Need to Update room acked and messages.
  const createdAt = lastMessage.get('createdAt');
  const ackedBy = lastMessage.get('name');
  yield put(updateRoom(roomId, createdAt, ackedBy, lastMessage));
  if (!isLocalRoom(room)) {
    yield put(fetchCountUnacked());
  }
  switch (roomType) {
    case ROOM_TYPE_ORGANIZATION:
      yield trackOrganizationMessageSent(room, message);
      break;
    case ROOM_TYPE_RFP:
      yield trackRFPComment(room, roomId, message, entityId);
      break;
    case ROOM_TYPE_PRODUCTVERSION:
    case ROOM_TYPE_PRODUCTVERSION_SOURCING:
      yield trackProductComment(room, message, entityId, roomType);
      break;
  }
}

function* createRemoteRoom(organizationId, entityId, roomType, message) {
  const room = yield call(
    api.createRoom,
    organizationId,
    entityId,
    roomType,
    message
  );
  const roomId = get('id', room);
  const lastMessage = get('last_message', room);
  yield put(convertToRemoteRoom(room, organizationId));
  return { lastMessage, roomId };
}

// ---- send quick message ----

export function* sendQuickMessage({ payload }) {
  const { message, entityId, roomType, organizationId } = payload;
  const room = yield call(api.createRoom, organizationId, entityId, roomType);
  yield call(api.createMessage, room.get('id'), message);
  yield trackProductComment(room, message, entityId, roomType, true);
}

// ---- poll count unacked ----

function* pollCountUnackedTask() {
  const unackedCount = yield call(api.organizationCountUnacked);
  yield put(notifyUnackedCount(unackedCount));
  // Poll every n seconds.
  yield delay(NOTIFICATION_UPDATE_INTERVAL);
  yield put(fetchCountUnacked());
}

export function* pollCountUnacked(action) {
  const user = yield select(selectUser);
  if (hasPermissionV3(user, FEATURE_PERMISSIONS_V3_ORGANIZATION)) {
    if (!hasPermissionModule(user, ORGANIZATION_PERMISSION, CHAT_PERMISSION)) {
      return;
    }
  }
  yield race({
    task: call(pollCountUnackedTask, action),
    cancel: take(CANCEL_FETCH_COUNT_UNACKED),
  });
}

// ---- ack room ----

function* ackRoom({ payload }) {
  const { room } = payload;
  const roomId = room.get('id');
  const updatedRoom = yield call(api.acknowledgeRoom, roomId);
  // Need to Update room acked and messages.
  const ackedAt = updatedRoom.getIn(['participant', 'acked_at']);
  const ackedBy = updatedRoom.getIn(['participant', 'acked_name']);
  yield put(updateRoom(roomId, ackedAt, ackedBy));
  yield put(fetchCountUnacked());
  yield trackAck(room);
}

// ---- load more messages ----

function* loadMoreMessages() {
  const roomId = yield select(getSelectedRoomId);
  const messages = yield select(getMessagesForSelectedRoom);
  const moreMessages = yield call(api.listMessages, {
    roomId,
    offset: messages.size,
  });
  if (moreMessages.size) {
    yield put(addMessagesToRoom(roomId, moreMessages));
  } else {
    yield put(noMoreMessages());
  }
}
