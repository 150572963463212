import { hasSourcingLabelShare } from 'core/api/organization-settings';
import { selectOrganizationSettings } from 'reducers/user/selectors';
import i18n from 'utils/i18n';

import {
  selectAllProducts,
  selectEmails,
  selectSourcingLabel,
} from '../selectors';

export function canShareSourcingLabel(state) {
  return hasSourcingLabelShare(selectOrganizationSettings(state));
}

export function getModalName(state) {
  if (canShareSourcingLabel(state)) {
    return i18n.t('Promote my products');
  } else {
    return i18n.t('Invite a retailer to discover my products');
  }
}

export function isFormValid(state) {
  return (
    !!selectEmails(state).size &&
    (selectAllProducts(state) || !!selectSourcingLabel(state))
  );
}
