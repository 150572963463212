import { call, put, putResolve, select } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import {
  notificationClear,
  notificationError,
  notificationSuccess,
} from 'actions/notification';
import {
  fetchProductVersionByKeyId,
  fetchRetailerProductVersionByKeyId,
  setExportableTag,
} from 'actions/productversion';
import { TypePackagingLabels } from 'constants/typePackaging';
import { selectHasDataOps } from 'modules/data-ops';
import { fetchDisplayGroups } from 'modules/display-groups/actions';
import { RELEASE_CASE_AS_CONSUMER_UNIT } from 'modules/feature-flag/constants';
import {
  selectHasFeature,
  selectHasLogisticalHierarchiesAdvancedPrivateFields,
} from 'modules/feature-flag/selectors';
import { ensureValidHierarchies } from 'modules/logistical-hierarchies/actions';
import {
  selectAgreedEditedInternalIds,
  selectDeletedRoots,
  selectLogisticalHierarchiesToSave,
  selectPatchedDeletedRoots,
  selectPatchedLogisticalHierarchiesToSave,
  selectShouldShowLogisticalHierarchies,
} from 'modules/logistical-hierarchies/selectors';
import { selectIsSavingInProgress } from 'modules/product-page/selectors';
import { selectProductVersionSpecificDataToSave } from 'modules/recipient-specific-block/selectors';
// New save
import {
  getAllPresentPriceWaterfallLabels,
  getHasSharingUnitErrors,
  selectDisplayWarningOnSave,
  selectSharingUnits,
  selectSharingUnitsToSave,
} from 'modules/sharing-units/selectors';
import trackSharingUnits from 'modules/sharing-units/track';
import { init as initViewAs } from 'modules/view-as/actions';
import {
  selectCanPatchProduct,
  selectCanUpdateProduct,
  selectCanUpdateSharingUnit,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectProductGTINWithFallbackOnInternal,
  selectProductKeyId,
  selectProductVersionId,
  selectProductVersionIsMadeOfError,
  selectProductVersionToSave,
  selectTextileVariantList,
  selectTypePackagingId,
} from 'reducers/productVersion';
import { selectIsRetailer, selectIsThirdParty } from 'reducers/user/selectors';
import serviceProductApi, {
  saveProduct as saveProductApi,
} from 'resources/serviceProductApi';
import * as routes from 'routes';
import { processSaveErrors } from 'utils/actions/saveErrors';
import { requestWithHeaders } from 'utils/api';
import { CustomError } from 'utils/error';
import { push } from 'utils/history';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';
import { track } from 'utils/tracking';

import * as actions from '../actions';
import { fetchRejectedFields } from '../actions';
import {
  SAVE_PRODUCT_ERROR,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
} from '../constants';
import { confirmHierarchyUpdates } from '../modules/logistical-hierarchies-modal/actions';
import { confirmUnsyncableRecipients } from '../modules/unsyncable-recipients-modal/actions';

export const messages = {
  success: i18n.t('The Product has been saved'),
};

export function* fetchProductDataByUuidSaga({ payload }) {
  const { uuid } = payload;

  yield put(actions.setLoading(true));

  const { result, error } = yield call(
    requestWithHeaders,
    serviceProductApi,
    'post',
    '/product/v2/products/keys/list-from-uuids',
    {
      uuids: [uuid],
    }
  );

  yield put(actions.setLoading(false));

  if (error) {
    yield put(notificationError(error));
    return false;
  }

  if (!result.data || result.data.length === 0) {
    yield put(
      notificationError(i18n.t('No product was found for the requested UUID'))
    );
    return false;
  }

  const productKeyId = result.data[0].key.id;
  yield call(push, fill(routes.productPage, productKeyId));
  return true;
}

export function* fetchProductDataByKeyIdSaga({ payload }) {
  const { productKeyId, withDeleteOld } = payload;
  const isRetailer = yield select(selectIsRetailer);
  yield put(actions.setLoading(true));

  if (isRetailer) {
    yield putResolve(
      fetchRetailerProductVersionByKeyId(productKeyId, withDeleteOld)
    );
    yield putResolve(fetchDisplayGroups());
    yield putResolve(initViewAs());
  } else {
    yield putResolve(fetchProductVersionByKeyId(productKeyId, withDeleteOld));
    yield putResolve(fetchDisplayGroups());
  }

  yield put(fetchRejectedFields());
  yield put(actions.setLoading(false));
}

export function* validateChanges() {
  const confirmUnsyncableRecipientsAction = yield call(
    confirmUnsyncableRecipients
  );
  const confirmedUnsyncableRecipients = yield putResolve(
    confirmUnsyncableRecipientsAction
  );
  if (!confirmedUnsyncableRecipients) {
    return false;
  }

  const agreedEditedInternalIds = yield select(selectAgreedEditedInternalIds);
  const agreedEditedHierarchyProduct = yield select(selectDisplayWarningOnSave);
  if (
    (agreedEditedInternalIds && agreedEditedInternalIds.length > 0) ||
    agreedEditedHierarchyProduct
  ) {
    const confirmed = yield putResolve(confirmHierarchyUpdates());
    if (!confirmed) {
      return false;
    }
  }
  // Get the Product Type Information
  const typePackagingId = yield select(selectTypePackagingId);
  const isDisplayUnit = yield select(selectIsDisplayUnit);
  const isConsumerUnit = yield select(selectIsConsumerUnit);
  const hasFeatureCaseAsConsumerUnit = yield select(
    selectHasFeature(RELEASE_CASE_AS_CONSUMER_UNIT)
  );
  // Check Product Types Restrictions
  const error_message =
    'You need to select one unit type. You cannot select {{packaging}}.';
  switch (typePackagingId) {
    // Base Unit Can only be a Consumer Unit
    case TypePackagingLabels.EACH.id:
      if (!isConsumerUnit) {
        const packaging = TypePackagingLabels.EACH.label;
        yield put(notificationError(i18n.t(error_message, { packaging })));
        return false;
      }
      break;
    // Pack can't be a Display Unit
    case TypePackagingLabels.PACK.id:
      if (isDisplayUnit) {
        const packaging = TypePackagingLabels.PACK.label;
        yield put(notificationError(i18n.t(error_message, { packaging })));
        return false;
      }
      break;
    // Case can't be a Consumer Unit
    case TypePackagingLabels.CASE.id:
      if (isConsumerUnit && !hasFeatureCaseAsConsumerUnit) {
        const packaging = TypePackagingLabels.CASE.label;
        yield put(notificationError(i18n.t(error_message, { packaging })));
        return false;
      }
      break;
    // Pallet can't be a Consumer Unit
    case TypePackagingLabels.PALLET.id:
      if (isConsumerUnit) {
        const packaging = TypePackagingLabels.PALLET.label;
        yield put(notificationError(i18n.t(error_message, { packaging })));
        return false;
      }
      break;
  }
  // if product is a display unit or a pack, make sure we've added children to it
  if (
    isDisplayUnit ||
    (isConsumerUnit && typePackagingId === TypePackagingLabels.PACK.id) ||
    typePackagingId === TypePackagingLabels.CASE.id
  ) {
    const isMadeOfError = yield select(selectProductVersionIsMadeOfError);
    if (isMadeOfError) {
      yield put(notificationError(isMadeOfError));
      return false;
    }
  }
  return true;
}

export function* saveProductSaga({
  notify = true,
  onlyVersion = false,
  publish = false,
  redirectToRoute = null,
  setExportable = false,
} = {}) {
  const saveInProgress = yield select(selectIsSavingInProgress);
  if (saveInProgress) {
    return;
  }

  const isRetailer = yield select(selectIsRetailer);
  const isThirdParty = yield select(selectIsThirdParty);
  if (!isRetailer && !isThirdParty) {
    const hasSharingUnitsErrors = yield select(getHasSharingUnitErrors);
    if (hasSharingUnitsErrors) {
      yield put({ type: SAVE_PRODUCT_ERROR });
      yield put(
        notificationError(
          i18n.t(
            'One of the listing contains an error. Please fix them before saving'
          )
        )
      );
      return;
    }

    const proceedWithSave = yield call(validateChanges);
    if (!proceedWithSave) {
      return;
    }
  }

  if (notify) {
    yield put({ type: SAVE_PRODUCT_REQUEST });
  }
  yield put(startLoading());
  yield put(notificationClear());

  let productVersion;
  let pricewaterfallLabels;
  let sharingUnits;
  let specificData;
  let logisticalHierarchies;
  let deletedRoots = {};
  let segments;

  try {
    const productKeyId = yield select(selectProductKeyId);
    const productVersionId = yield select(selectProductVersionId);
    const currentVersionReference = yield select(
      selectProductGTINWithFallbackOnInternal
    );
    const textileVariantList = yield select(selectTextileVariantList);

    const canUpdateProduct = yield select(selectCanUpdateProduct);
    const canUpdateSharingUnit = yield select(selectCanUpdateSharingUnit);
    const canUpdateLogitisticalUnit = yield select(
      selectShouldShowLogisticalHierarchies
    );
    const canPatchProduct = yield select(selectCanPatchProduct);
    const hasDataOps = yield select(selectHasDataOps);
    const hasAdvancedLogisticalHierarchiesPrivateFields = yield select(
      selectHasLogisticalHierarchiesAdvancedPrivateFields
    );

    // Gather data.
    if (onlyVersion) {
      if (canUpdateProduct) {
        const versionToSave = yield select(selectProductVersionToSave);
        productVersion = versionToSave.version; // eslint-disable-line prefer-destructuring
        segments = versionToSave.segments; // eslint-disable-line prefer-destructuring
      }
    } else {
      if (canUpdateProduct) {
        const versionToSave = yield select(selectProductVersionToSave);
        productVersion = versionToSave.version; // eslint-disable-line prefer-destructuring
        segments = versionToSave.segments; // eslint-disable-line prefer-destructuring
        // specific fields
        specificData = yield select(selectProductVersionSpecificDataToSave);
      }

      if (canUpdateSharingUnit) {
        pricewaterfallLabels = yield select(getAllPresentPriceWaterfallLabels);
        sharingUnits = yield select(selectSharingUnitsToSave);
        if (!sharingUnits || sharingUnits.length === 0) {
          sharingUnits = undefined;
        }
      }

      if (canUpdateLogitisticalUnit) {
        logisticalHierarchies = yield select(selectLogisticalHierarchiesToSave);
        const deletedIds = yield select(selectDeletedRoots);
        deletedRoots = { logisticalHierarchiesDeletedRoots: deletedIds };
      }
    }

    if (
      isRetailer &&
      ((hasDataOps && canPatchProduct) ||
        hasAdvancedLogisticalHierarchiesPrivateFields)
    ) {
      logisticalHierarchies = yield select(
        selectPatchedLogisticalHierarchiesToSave
      );
      const deletedIds = yield select(selectPatchedDeletedRoots);
      deletedRoots = { patchedLogisticalHierarchiesDeletedRoots: deletedIds };
    }

    if (logisticalHierarchies?.length) {
      const hierarchyValidationErrors = yield call(
        ensureValidHierarchies,
        currentVersionReference,
        textileVariantList,
        logisticalHierarchies
      );
      if (hierarchyValidationErrors.length) {
        throw CustomError({
          message: 'error validating hierarchies',
          status: 400,
          data: {
            errors: { LogisticalHierarchies: hierarchyValidationErrors },
          },
        });
      }
      logisticalHierarchies = logisticalHierarchies.map((data) => ({ data }));
    }

    // Save data.
    const { error } = yield call(saveProductApi, {
      product: {
        key: { id: productKeyId },
        data: {
          sharingUnits,
          productVersion: {
            data: productVersion,
            specificData,
          },
          logisticalHierarchies_beta: logisticalHierarchies,
          ...deletedRoots,
        },
        segments,
        pricewaterfallLabels,
      },
      patch: true,
    });

    // Handle errors.
    if (error) {
      if (pricewaterfallLabels) {
        // SharingUnit here are only used for pricewaterfallLabels errors
        const sharingUnitsById = yield select(selectSharingUnits);
        sharingUnits = sharingUnitsById.toList().toJS();
      }
      throw error;
    }

    // Track.
    if (canUpdateProduct) {
      yield call(track, {
        category: 'product',
        action: 'product_saved',
        label: `product_version#${productVersionId}`,
      });
    }
    if (canUpdateSharingUnit && sharingUnits) {
      sharingUnits.forEach(({ id }) => trackSharingUnits.save(id));
    }

    // Wrap up.
    if (publish) {
      yield put(actions.publish());
    } else {
      yield putResolve(fetchProductVersionByKeyId(productKeyId));
    }
    if (notify) {
      yield put(notificationSuccess(messages.success));
      yield put({ type: SAVE_PRODUCT_SUCCESS });
    }

    if (setExportable) {
      yield call(setExportableSaga);
    }

    if (redirectToRoute) {
      yield call(push, redirectToRoute);
    }
  } catch (e) {
    if (notify) {
      yield put(
        processSaveErrors({
          error: e,
          logisticalHierarchies,
          sharingUnits,
          pricewaterfallLabels,
        })
      );
      yield put({ type: SAVE_PRODUCT_ERROR });
    }
  } finally {
    yield put(stopLoading());
  }
}

export function* saveRetailerProductVersionSaga({
  payload: { setExportable = false },
}) {
  yield call(saveProductSaga, {
    notify: true,
    setExportable,
    onlyVersion: true,
  });
}

export function* setExportableSaga() {
  const productKeyId = yield select(selectProductKeyId);
  const productVersionId = yield select(selectProductVersionId);

  try {
    yield putResolve(setExportableTag([productKeyId]));
    yield put(notificationSuccess(i18n.t('The product is now exportable')));
    yield call(track, {
      category: 'product',
      action: 'product_setas_exportable',
      label: `product_version#${productVersionId}`,
    });
  } catch (e: any) {
    let errorMessage;
    switch (e.data.status as number) {
      case 400:
        break; // user tried to do the same action again, let it go
      case 403:
        errorMessage = i18n.t(
          'You do not have the rights to validate the Product.'
        );
        break;
      default:
        errorMessage = i18n.t(
          'An error occured while validating the Product data.'
        );
        break;
    }
    if (errorMessage) {
      yield put(notificationError(errorMessage));
    }
  }
}

export function* publishSaga() {
  const productKeyId = yield select(selectProductKeyId);
  const { error } = yield call(
    requestWithHeaders,
    serviceProductApi,
    'post',
    '/product/v3/products/publish',
    {
      product_key_id: productKeyId,
    }
  );
  if (error) {
    yield put(
      notificationError(i18n.t('An error occured while publishing the product'))
    );
  } else {
    yield put(notificationSuccess(i18n.t('The product has been published')));
    yield put(actions.fetchProductDataByKeyId(productKeyId));
  }
}
