import PropTypes from 'prop-types';

import { ProgressBar } from '@alkem/react-ui-progress';

import Modal from 'components/ui/modal';
import { i18n } from 'utils/i18n';

import './shareStatus.scss';

export default function ShareStatus(props) {
  return (
    <div className="ShareStatus">
      {props.totalSharedUnits !== props.totalSharingUnits ? (
        <>
          <ProgressBar
            value={props.totalSharedUnits}
            max={props.totalSharingUnits}
            height="medium"
            color="success"
          />
          <p className="ShareStatus__labels">
            <div className="ShareStatus__firstLabel">
              {i18n.t('Attribution of logistical hierarchies...')}
            </div>
            <span>
              {i18n.t('Please wait while we are processing your request.')}
            </span>
            <div className="ShareStatus__thirdLabel">
              {i18n.t('Please do not close this modal.')}
            </div>
          </p>
          {props.cancelSharing && (
            <Modal
              danger
              modalStyle="dynamic"
              title={i18n.t('Interrupt share')}
              closeButtonText={i18n.t('Cancel')}
              confirmButtonText={i18n.t('Confirm')}
              onConfirm={props.onConfirmCancelSharing}
              secondaryAction={[i18n.t('Cancel'), props.onCloseCancelSharing]}
            >
              <span className="ShareStatus__cancelMessage">
                {i18n.t(
                  'Are you sure you want to close? Your logistical hierarchies may not have been shared.'
                )}
              </span>
            </Modal>
          )}
        </>
      ) : (
        <div className="ShareStatus__success">
          <i className="ShareStatus__successIcon mdi mdi-checkbox-marked-circle-outline"></i>
          <div>
            {i18n.t(
              'You have successfully attributed logistical hierarchies to:'
            )}
          </div>
          <span className="ShareStatus__shareSummary ShareStatus__shareSummary--green">
            {i18n.t('{{count}} product attributed to {{recipient}}', {
              count: props.totalSharedUnits,
              recipient: props.recipient,
            })}
          </span>
        </div>
      )}
    </div>
  );
}

ShareStatus.propTypes = {
  onConfirmCancelSharing: PropTypes.func,
  onCloseCancelSharing: PropTypes.func,
  totalSharedUnits: PropTypes.number,
  totalSharingUnits: PropTypes.number,
  recipient: PropTypes.string,
  cancelSharing: PropTypes.bool,
};
