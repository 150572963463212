import classNames from 'classnames';

import { ACTION_STATUSES } from '../constants';
import { ActionToReviewStatus } from '../types';

import './product-review-status.scss';

export const ProductReviewStatus = ({
  status,
  className = '',
}: {
  status?: ActionToReviewStatus;
  className?: string;
}) => {
  if (!status) {
    return <></>;
  }
  const { icon, label } = ACTION_STATUSES[status];
  return (
    <div
      className={classNames(
        'ProductReviewStatus alk-flex alk-flex-center',
        className
      )}
    >
      <i
        className={classNames('mdi', `ProductReviewStatus--${status}`, icon)}
      />
      <span className="ProductReviewStatus__label">{label}</span>
    </div>
  );
};
