import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import * as actions from './actions';
import { REDUCER_KEY } from './constants';
import reducer from './reducers';
import saga from './sagas';
import * as selectors from './selectors';

registerModuleReducer(REDUCER_KEY, reducer);
registerSaga(saga);

/* eslint-disable prefer-destructuring */
export const tryAuthorization = actions.tryAuthorization;
export const selectIsAuthorized = selectors.selectIsAuthorized;
/* eslint-enable */
