import LogoDarkSrc from 'images/logo-salsify-dark.png';
import i18n from 'utils/i18n';

import './preview.scss';
import ProductPackshotSrc from './product-packshot.jpg';

export default function Preview() {
  return (
    <div className="PromoteModalPreview">
      <div className="PromoteModalPreview__logo">
        <img src={LogoDarkSrc} alt="Alkemics" />
      </div>

      <div className="PromoteModalPreview__intro">
        {i18n.t(
          'We’re inviting you to discover a selection of products that we have for you on the Alkemics product network.'
        )}
      </div>

      <div className="PromoteModalPreview__productPackshot">
        <img src={ProductPackshotSrc} alt="" />
      </div>

      <div className="PromoteModalPreview__gtin">03663836013643</div>

      <div className="PromoteModalPreview__description">
        {i18n.t(
          'Almond, chocolate and honey organic breakfast biscuits - Alkemics Food 200g'
        )}
      </div>

      <div className="PromoteModalPreview__infos">
        {i18n.t(
          'To browse through the whole selection, please click on the following link:'
        )}
      </div>

      <div className="PromoteModalPreview__button">
        {i18n.t('View the selection')}
      </div>

      <div className="PromoteModalPreview__extraInfos">
        {i18n.t(
          'If you are interested in some of these products, you will easily be able to contact us through the platform, and list them in a few clicks.'
        )}
      </div>
    </div>
  );
}
