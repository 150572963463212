import { hasFeature } from 'modules/feature-flag';
import { FEATURE_SHARING_STATUS_FILTER } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import { i18n } from 'utils/i18n';

export const sharingStatusFilter = {
  key: 'recipients_sharing_status_v3',
  customField: 'recipients_sharing_status_v3',
  query: 'sharing_status',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_sharing_status.label',
    { defaultValue: 'Sharing status' }
  ),
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    hasFeature(user, FEATURE_SHARING_STATUS_FILTER),
};
