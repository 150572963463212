import classNames from 'classnames';
import { List, Map } from 'immutable';
import { defer, flow } from 'lodash/fp';
import memoize from 'memoize-one';
import { bool, func, object, shape } from 'prop-types';
import { Component } from 'react';
import { list } from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Spinner } from '@alkem/react-ui-spinner';

import { withLocation, withNavigate } from 'hocs';
import CoreLayout from 'layouts/CoreLayout';
import BodyLayout from 'layouts/body';
import { selectUser } from 'reducers/user/selectors';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { track } from 'utils/tracking';

import { AUTH_ADMIN } from '../../constants';
import ForbiddenReporting from '../../forbidden';
import { GenericReporting } from '../../generic';
import { fetchReports } from '../actions';
import { selectIsReportListLoaded, selectReportList } from '../selectors';

import './index.scss';

const mapStateToProps = (state) => ({
  user: selectUser(state),
  reportList: selectReportList(state),
  isReportListLoaded: selectIsReportListLoaded(state),
});

const mapDispatchToProps = {
  fetchReports,
};

export class Reporting extends Component {
  static propTypes = {
    location: object.isRequired,
    navigate: func.isRequired,
    reportList: list.isRequired,
    isReportListLoaded: bool,
    actions: shape({
      fetchReports: func.isRequired,
    }).isRequired,
  };

  MAIN_ROUTE = routes.reporting;

  OVERVIEW_TAB = Map({
    label: i18n.t(
      'frontproductstream.reporting_page.overview_report_tab.title',
      { defaultValue: 'Overview' }
    ),
    route: 'overview',
  });

  componentDidMount() {
    const { actions, isReportListLoaded } = this.props;
    if (!isReportListLoaded) {
      actions.fetchReports();
    }
  }

  trackTabs = memoize((tabRoute) => {
    track({
      category: 'reporting',
      action: 'reporting_view',
      reporting_name: tabRoute,
    });
  });

  getTabs() {
    const { reportList } = this.props;
    const tabs = [];
    const asyncTabs = reportList;
    // PRO-615: migration from old manuf-overview to new one
    // we display old one only if authorized, and if new one is not displayed
    return List(tabs).concat(asyncTabs);
  }

  isTabSelected(tab) {
    const { location } = this.props;
    return location.pathname === this.formatRoute(tab);
  }

  formatRoute(tab) {
    return `${this.MAIN_ROUTE}/${tab.get('route')}`;
  }

  redirect = (route) => {
    defer(() => this.props.navigate(route, { replace: true }));
    return null;
  };

  renderTab(tab) {
    return (
      <Link
        key={tab.get('route')}
        to={this.formatRoute(tab)}
        className={classNames(
          'Reporting__tab',
          this.isTabSelected(tab) && 'Reporting__tab--selected',
          tab.get('authorization') === AUTH_ADMIN && 'Reporting__tab--admin'
        )}
      >
        {tab.get('label')}
      </Link>
    );
  }

  renderReport() {
    const { location, isReportListLoaded, reportList } = this.props;
    const currentRoute = location.pathname;
    if (currentRoute === this.MAIN_ROUTE) {
      if (!isReportListLoaded) {
        return (
          <div className="Reporting__spinner">
            <Spinner big />
          </div>
        );
      }
      if (!reportList.isEmpty()) {
        return this.redirect(this.formatRoute(reportList.first()));
      } else {
        return <ForbiddenReporting />;
      }
    } else {
      const tab = this.getTabs().find(
        (t) => currentRoute === this.formatRoute(t)
      );
      if (tab) {
        this.trackTabs(tab.get('route'));
        return this.renderDynamicReport(tab);
      }
    }
    return null;
  }

  renderDynamicReport(tab) {
    const tabType = tab.get('type');
    switch (tabType) {
      case 'table3':
        return <GenericReporting reportConfig={tab.get('config')} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <CoreLayout containerClass="Reporting" absolute>
        <div className="Reporting__tabs">
          {this.getTabs().map((tab) => this.renderTab(tab))}
        </div>
        <BodyLayout className="Reporting__body">
          {this.renderReport()}
        </BodyLayout>
      </CoreLayout>
    );
  }
}

export default flow(
  withLocation,
  withNavigate,
  connect(mapStateToProps, mapDispatchToProps, separateActions)
)(Reporting);
