import {
  REGISTER_BLOCKNAME,
  REGISTER_SECTION,
  SET_BLOCK_POSITION,
  UNREGISTER_BLOCKNAME,
  UNREGISTER_SECTION,
  UPDATE_BLOCKNAME,
} from './constants';

export const registerSection = (sectionName, sectionOrder, sectionIcon) => ({
  type: REGISTER_SECTION,
  payload: {
    sectionName,
    sectionOrder,
    sectionIcon,
  },
});

export const unregisterSection = (sectionName) => ({
  type: UNREGISTER_SECTION,
  payload: {
    sectionName,
  },
});

export const registerBlockName = (
  sectionName,
  blockName,
  blockId,
  position
) => ({
  type: REGISTER_BLOCKNAME,
  payload: {
    sectionName,
    blockName,
    blockId,
    position,
  },
});

export const updateBlockName = (
  sectionName,
  blockName,
  newBlockName,
  blockId
) => ({
  type: UPDATE_BLOCKNAME,
  payload: {
    sectionName,
    blockName,
    newBlockName,
    blockId,
  },
});

export const unregisterBlockName = (sectionName, blockName, blockId) => ({
  type: UNREGISTER_BLOCKNAME,
  payload: {
    sectionName,
    blockName,
    blockId,
  },
});

export const setBlockPosition = (blockName, position, blockId) => ({
  type: SET_BLOCK_POSITION,
  blockName,
  position,
  blockId,
});
