import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

export const OrganizationBrandList = asyncComponent(async () =>
  registerModule(
    await import(
      /* webpackChunkName: "organization-admin-brand-list" */ './list'
    )
  )
);
