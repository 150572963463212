/**
 ** TargetMarket int constants
 */

export const FRANCE = 250;
export const GERMANY = 276;
export const IRELAND = 372;
export const REUNION = 638;
export const SPAIN = 724;
export const SWITZERLAND = 756;
export const UNITED_KINGDOM = 826;
export const UNITED_STATES = 840;
export const PORTUGAL = 620;
export const GLOBAL_MARKET = 1;
export const AUSTRALIA = 36;
