import classNames from 'classnames';
import { List } from 'immutable';
import { RefObject, useMemo, useState } from 'react';

import ListTablePanel from 'core/modules/list/components/table/panel';
import i18n from 'utils/i18n';

import { MaturitiesSummary } from '..';

import './index.scss';
import { ActiveScenarios } from './maturity-panel-active-scenarios';
import { UpcomingDeadlines } from './maturity-panel-deadlines';

interface Props {
  data: MaturitiesSummary;
  colorRange?: List<number>;
  rowIdentifier?: number;
  cellRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}

const MaturityPanelBody = ({
  data,
  colorRange,
}: {
  data: MaturitiesSummary;
  colorRange?: List<number>;
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const isScenarioCompletionTabSelected = useMemo(() => {
    return selectedTabIndex === 0;
  }, [selectedTabIndex]);

  const isUpcomingDeadlinesTabSelected = useMemo(() => {
    return selectedTabIndex === 1;
  }, [selectedTabIndex]);

  const selectScenarioCompletionTab = () => {
    setSelectedTabIndex(0);
  };

  const selectUpcomingDeadlinesTab = () => {
    setSelectedTabIndex(1);
  };

  const numberOfUpComingDeadlines = data.upcoming_scenarios?.length;

  return (
    <div className="MaturityPanel alk-flex alk-flex-column">
      <div className="MaturityPanel__tabs">
        <div
          className={classNames('MaturityPanel__tab', 'alk-flex-1', {
            MaturityPanel__tab__selected: isScenarioCompletionTabSelected,
          })}
          onClick={() => selectScenarioCompletionTab()}
        >
          {i18n.t(
            'frontproductstream.maturity_panel.active_scenarios_tab.title',
            {
              defaultValue: 'Active scenarios',
            }
          )}
        </div>
        <div
          className={classNames('MaturityPanel__tab', 'alk-flex-1', {
            MaturityPanel__tab__selected: isUpcomingDeadlinesTabSelected,
          })}
          onClick={() => selectUpcomingDeadlinesTab()}
        >
          {i18n.t(
            'frontproductstream.maturity_panel.upcoming_deadlines_tab.title',
            {
              defaultValue: 'Upcoming updates',
            }
          )}
          {numberOfUpComingDeadlines > 0 && (
            <span className="MaturityPanel__badge" data-testid="upcomingBadge">
              {numberOfUpComingDeadlines}
            </span>
          )}
        </div>
      </div>
      <div className="MaturityPanel__tabsContents">
        {isScenarioCompletionTabSelected && (
          <ActiveScenarios data={data} colorRange={colorRange} />
        )}
        {isUpcomingDeadlinesTabSelected && (
          <UpcomingDeadlines
            upcomingScenarios={data.upcoming_scenarios}
            colorRange={colorRange}
          />
        )}
      </div>
    </div>
  );
};

export const MaturityPanel = ({
  cellRef,
  rowIdentifier,
  onClose,
  data,
  colorRange,
}: Props) => {
  const header = (
    <div className="alk-flex alk-flex-center">
      <h4 className="MaturityPanel__title">
        {i18n.t('frontproductstream.maturity_panel.title.text', {
          defaultValue: 'Scenarios completion',
        })}
      </h4>
    </div>
  );

  const body = <MaturityPanelBody data={data} colorRange={colorRange} />;

  return (
    <ListTablePanel
      cellRef={cellRef}
      rowIdentifier={rowIdentifier}
      onClose={onClose}
      body={body}
      header={header}
    />
  );
};
