import classNames from 'classnames';
import { List, Map } from 'immutable';
import moment, { Moment } from 'moment';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';

import Modal from 'components/ui/modal';
import { DeadlineSelector } from 'modules/catalog/product/components/list/actions/bulk-action/actions/maturity-rulesets/deadlineSelector';
import i18n from 'utils/i18n';

import { selectUser } from '../../../../../../reducers/user/selectors';
import { UserImmutable } from '../../../../../../types';
import { RuleSetIcon } from '../../../../../view-as/components/rule-set-icon';
import { selectRuleSets } from '../../../../../view-as/selectors';
import { requireMaturityRuleSetsWithDeadlines } from '../../../../actions';
import { PromptedMaturityRuleSet, RuleSetRequirement } from '../../../../types';

import './index.scss';

export interface ActivableMaturityRuleSet {
  id: number;
  checked: boolean;
  withDeadline: boolean;
  defaultDeadline: string;
  ruleSet: Map<string, any> | undefined;
  selectedDeadline?: string;
}

interface Props {
  promptedMaturityRuleSets: List<PromptedMaturityRuleSet>;
  onClose: (e?: MouseEvent) => void;
  onConfirm: () => void;
}

export const MaturityRuleSetActivationModal = ({
  promptedMaturityRuleSets,
  onClose,
  onConfirm,
}: Props) => {
  const user: UserImmutable = useSelector(selectUser);
  const dispatch = useDispatch();
  const minimumDateMoment = moment().add(1, 'day').endOf('day');

  const [checkedRuleSets, setCheckedRuleSets] = useState({}); // keep checkbox statuses
  const [deadlinesByRuleSetId, setDeadlinesByRuleSetId] = useState({});

  const applicableRuleSets: List<Map<string, any>> =
    useSelector(selectRuleSets);

  const handleDeadlineChange = (maturityRuleSet: ActivableMaturityRuleSet) => {
    return (deadline: Moment | null) => {
      if (!deadline) {
        return;
      }
      setDeadlinesByRuleSetId((prevState) => ({
        ...prevState,
        [maturityRuleSet.id]: deadline.toISOString(),
      }));
    };
  };

  const isRuleSetSelected = useCallback(
    (ruleSetId) => {
      return ruleSetId in checkedRuleSets ? checkedRuleSets[ruleSetId] : true;
    },
    [checkedRuleSets]
  );

  const defaultSelectableDeadline = (
    maturityRuleset: PromptedMaturityRuleSet,
    minimumMoment
  ) => {
    const defaultDeadlineMoment = moment(maturityRuleset.defaultDeadline);
    return defaultDeadlineMoment.isAfter(minimumMoment)
      ? maturityRuleset.defaultDeadline
      : minimumMoment.toISOString();
  };

  const activableMaturityRuleSets = useMemo(() => {
    return (promptedMaturityRuleSets || List()).map(
      (promptedMaturityRuleSet) => {
        const prompted = promptedMaturityRuleSet as PromptedMaturityRuleSet;
        const matchingRuleSet = applicableRuleSets.find((ruleSet) => {
          return ruleSet !== undefined && ruleSet.get('id') === prompted.id;
        });
        const ruleSetId = prompted.id;
        return {
          ...prompted,
          defaultDeadline: prompted.withDeadline
            ? defaultSelectableDeadline(prompted, minimumDateMoment)
            : undefined,
          ruleSet: matchingRuleSet,
          checked: isRuleSetSelected(ruleSetId),
          selectedDeadline: deadlinesByRuleSetId[ruleSetId],
        } as ActivableMaturityRuleSet;
      }
    );
  }, [
    minimumDateMoment,
    promptedMaturityRuleSets,
    applicableRuleSets,
    isRuleSetSelected,
    deadlinesByRuleSetId,
  ]);

  const hasRuleSetSelected = useMemo(() => {
    return (promptedMaturityRuleSets || List()).some((prompted) => {
      return prompted && isRuleSetSelected(prompted.id);
    });
  }, [promptedMaturityRuleSets, isRuleSetSelected]);

  const requestRuleSetsWithDeadlines = () => {
    const ruleSetsRequirements = activableMaturityRuleSets
      .filter((maturityRuleSet: ActivableMaturityRuleSet | undefined) => {
        const ruleSet = maturityRuleSet as ActivableMaturityRuleSet;
        return ruleSet.checked;
      })
      .map((activableMaturityRuleSet: ActivableMaturityRuleSet | undefined) => {
        const maturityRuleSet =
          activableMaturityRuleSet as ActivableMaturityRuleSet;
        const deadline =
          maturityRuleSet.selectedDeadline ?? maturityRuleSet?.defaultDeadline;
        const ruleSetId = maturityRuleSet.id;
        return new RuleSetRequirement(
          ruleSetId,
          true,
          deadline ? moment(deadline) : undefined
        );
      });

    if (!ruleSetsRequirements.size) {
      onClose();
      return;
    }
    dispatch(
      requireMaturityRuleSetsWithDeadlines(ruleSetsRequirements.toArray())
    );
    onConfirm();
  };

  function onChecked(ruleSetId: number) {
    return (checked: boolean) => {
      setCheckedRuleSets((prevState) => ({
        ...prevState,
        [ruleSetId]: checked,
      }));
    };
  }

  const renderMaturityRuleSet = (
    maturityRuleSet: ActivableMaturityRuleSet | undefined
  ) => {
    if (!maturityRuleSet) {
      return null;
    }
    const selected = isRuleSetSelected(maturityRuleSet.id);
    return (
      <div
        key={maturityRuleSet?.id}
        className={classNames('MaturityRuleSetActivationModal__ruleSetItem', {
          'MaturityRuleSetActivationModal__ruleSetItem--checked': selected,
        })}
      >
        {promptedMaturityRuleSets.size > 1 && (
          <Checkbox
            checked={maturityRuleSet.checked}
            disabled={false}
            id={`checkbox-${maturityRuleSet.id}`}
            testid={`checkbox-${maturityRuleSet.id}`}
            partiallyChecked={false}
            onChange={onChecked(maturityRuleSet.id)}
          />
        )}
        {maturityRuleSet.ruleSet && (
          <RuleSetIcon
            ruleSet={maturityRuleSet.ruleSet}
            user={user}
            selectedRecipients={[]} // we don't provide recipients here as we're focusing on retailer here
          />
        )}
        <div className="MaturityRuleSetActivationModal__ruleSetItemDetails">
          <span className="MaturityRuleSetActivationModal__ruleSetItemLabel">
            {maturityRuleSet.ruleSet?.get('label')}
          </span>
          {maturityRuleSet?.withDeadline && (
            <DeadlineSelector
              onChange={handleDeadlineChange(maturityRuleSet)}
              defaultDeadline={moment(maturityRuleSet.defaultDeadline)}
              minimumDateMoment={minimumDateMoment}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={i18n.t(
        'frontproductstream.maturity_ruleset_activation_modal.title.text',
        { defaultValue: 'Activate scenario(s)' }
      )}
      modalStyle="default"
      confirmButtonText={i18n.t(
        'frontproductstream.maturity_ruleset_activation_modal.confirm_button.text',
        { defaultValue: 'Confirm' }
      )}
      isProcessing={false}
      confirmDisabled={!hasRuleSetSelected}
      onConfirm={() => requestRuleSetsWithDeadlines()}
      onClose={onClose}
    >
      <div>
        <p>
          {i18n.t(
            'frontproductstream.maturity_ruleset_activation_modal.instructions.text',
            {
              defaultValue:
                'Confirm the activation of your scenario(s) after checking the completion deadline.',
            }
          )}
        </p>
        {activableMaturityRuleSets
          .filter((maturityRuleSet) => !maturityRuleSet?.withDeadline)
          .map(renderMaturityRuleSet)}
        {activableMaturityRuleSets
          .filter((maturityRuleSet) => !!maturityRuleSet?.withDeadline)
          .map(renderMaturityRuleSet)}
      </div>
    </Modal>
  );
};
