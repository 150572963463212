import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import {
  productAdminDeleteAuditDatas,
  productAdminGetAuditContext,
} from '../../actions';
import { selectAuditContext } from '../../selectors';
import {
  AuditContext as AuditContextType,
  Audit as AuditType,
} from '../../types';

import { AuditContext } from './audit-context';

interface Props {
  audit: AuditType;
  productKeyId: string;
}

export const Audit = ({ audit, productKeyId }: Props) => {
  const dispatch = useDispatch();
  const auditsContext: AuditContextType = useSelector(selectAuditContext);
  const startTime = moment(audit.start_time).format('LLL');
  const endTime = moment(audit.end_time).format('LLL');

  const [isOpen, setOpen] = useState(false);
  const icon = isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down';
  const contextDatas = auditsContext[audit.id];

  const toggleContextView = () => {
    if (!contextDatas) {
      dispatch(productAdminGetAuditContext(audit.id.toString()));
    }
    setOpen(!isOpen);
  };

  const deleteAudit = () => {
    dispatch(
      productAdminDeleteAuditDatas({
        auditID: audit.id,
        productKeyId: productKeyId,
      })
    );
  };

  return (
    <div
      key={'audit-' + audit.id}
      className="ProductAdminAudit__element"
      data-testid="product-admin-audit-data-container"
    >
      <div
        className="alk-flex alk-flex-row alk-flex-space-between ProductAdminAudit__elementHeader"
        onClick={toggleContextView}
      >
        <div className="alk-flex alk-flex-row">
          <Button
            id="product-admin-show-audit-context-button"
            testid="show-audit-context-button"
            onClick={toggleContextView}
            small
            link
          >
            <i className={`mdi ${icon}`} />
          </Button>
          <div className="ProductAdminAudit__elementAuditTitle">{audit.id}</div>
        </div>
        <div className="alk-flex alk-flex-row">
          <div>
            <div>
              <b>Started at:</b> {startTime}
            </div>
            <div>
              <b>Ended at:</b> {endTime}
            </div>
          </div>
          <Button
            id="product-admin-delete-audit-button"
            testid="delete-audit-button"
            className="ProductAdminAudit__elementAuditDeleteButton"
            warning
            onClick={deleteAudit}
          >
            <i className={'mdi mdi-delete'} />
          </Button>
        </div>
      </div>

      {isOpen &&
        !!contextDatas &&
        contextDatas.map((context) => (
          <AuditContext
            auditID={audit.id}
            contextDatas={context}
            key={'key-audit-data-context-' + context.id}
          />
        ))}
      {isOpen && contextDatas && contextDatas.length === 0 && (
        <div
          className="alk-flex alk-flex-row alk-flex-space-between ProductAdminAudit__elementContext ProductAdminAudit__elementContextEmpty"
          data-testid="audit-context-empty-datas"
        >
          <div>No contexts data</div>
        </div>
      )}
    </div>
  );
};
