import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReactUiCheckbox from '@alkem/react-ui-checkbox';

import i18n from 'utils/i18n';

import { toggleForceAssignation } from '../../actions';
import { selectToggleForceAssignation } from '../../selectors';

export const mapStateToProps = (state) => ({
  forceAssignation: selectToggleForceAssignation(state),
});
export const mapDispatchToProps = {
  onChange: toggleForceAssignation,
};

export const ForceToggle = ({ forceAssignation, onChange }) => (
  <div className="row">
    <label
      htmlFor="request-modal-force"
      className="InputField__label col-xs-3 TargetRequestModalForceToggle__label"
    >
      {i18n.t('Force assignation')}
    </label>
    <div className="InputField__input InputTextarea InputField col-xs-9">
      <div className="TargetRequestModalForceToggle__checkbox">
        <ReactUiCheckbox
          id="request-modal-force"
          checked={forceAssignation}
          onChange={onChange}
        />
      </div>
    </div>
  </div>
);

ForceToggle.propTypes = {
  forceAssignation: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForceToggle);
