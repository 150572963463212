import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';

import { notificationError } from 'actions/notification';
import 'components/ui/form/asset-list/actions/picture/picture-modal.scss';
import Modal from 'components/ui/modal';
import mediaApi from 'resources/mediaApi';
import i18n from 'utils/i18n';

const mapDispatchToProps = {
  notificationError,
};

class AddOrganizationPicture extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    notificationError: PropTypes.func.isRequired,
    organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    type: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
    pictureFile: null,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.resetPicture();
  }

  selectPicture = (pictures) => {
    if (pictures && pictures.length) {
      const [pictureFile] = pictures;
      pictureFile.preview = URL.createObjectURL(pictureFile);
      this.setState({ pictureFile });
    }
  };

  uploadPicture = () => {
    const { organizationId, type } = this.props;
    const { pictureFile } = this.state;
    this.setState({ loading: true });
    mediaApi
      .OrganizationPictureUpload(
        {
          name: pictureFile.name,
          organization_id: organizationId,
          picture_type: type,
        },
        pictureFile
      )
      .then(() => {
        this.cancel();
        this.props.onClose();
      })
      .catch(({ data: { status, data } }) => {
        this.cancel();
        if (status === 413) {
          this.props.notificationError(
            i18n.t(
              'frontproductstream.media_organization.upload_notification.too_big_error',
              {
                defaultValue:
                  'Image contains too many pixels. Actual pixels quantity : {{actual}}. Maximum : {{max}}',
                ...data,
              }
            ),
            {
              context: 'modal',
            }
          );
        } else {
          this.props.notificationError(
            i18n.t(
              'frontproductstream.media_organization.upload_notification.error',
              { defaultValue: 'Error while uploading the picture' }
            ),
            {
              context: 'modal',
            }
          );
        }
      });
  };

  cancel = () => {
    if (this._isMounted) {
      this.resetPicture();
      this.setState({ pictureFile: null, loading: false });
    }
  };

  resetPicture() {
    const { pictureFile } = this.state;
    if (pictureFile && pictureFile.preview) {
      URL.revokeObjectURL(pictureFile.preview);
    }
  }

  renderSummary() {
    const { pictureFile } = this.state;
    return (
      <div className="row AddPicture__container">
        <div className="col-xs-12 AddPicture__imageContainer">
          <img
            src={pictureFile.preview}
            className="AddPicture__image"
            alt={pictureFile.name}
          />
        </div>
      </div>
    );
  }

  renderDropZone() {
    return (
      <div className="AddPicture">
        <Dropzone onDrop={this.selectPicture}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps({
                className: classNames(
                  'AddPicture__dropzone',
                  isDragActive && 'AddPicture__dropzone--active'
                ),
              })}
            >
              <input {...getInputProps()} />
              <span className="Dropzone__label">
                {i18n.t(
                  'frontproductstream.media_organization.upload_drop_zone.text',
                  { defaultValue: 'Drop or click to select a picture' }
                )}
              </span>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  renderBody() {
    if (this.state.pictureFile) {
      return this.renderSummary();
    }
    return this.renderDropZone();
  }

  render() {
    const { onClose } = this.props;
    return (
      <Modal
        title={i18n.t(
          'frontproductstream.media_organization.upload_modal.title',
          { defaultValue: 'Upload a picture' }
        )}
        confirmButtonText={i18n.t(
          'frontproductstream.media_organization.upload_modal_button.label',
          { defaultValue: 'Validate' }
        )}
        onConfirm={this.uploadPicture}
        isProcessing={this.state.loading}
        onClose={onClose}
        hideFooter={!this.state.pictureFile}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(null, mapDispatchToProps)(AddOrganizationPicture);
