export const REDUCER_KEY = 'billing';
export const ADMIN_REDUCER_KEY = 'billing-admin';

export const stripeJs = 'js.stripe.com';
export const stripeJsSrc = `//${stripeJs}/v3/`;
export const stripeKey = 'pk_live_JhJX436LRXM7O0CMYG46B6lg';
export const stripeTestKey = 'pk_test_KaGjUAFaZgDSzRyGq8gUHg7X';
export const stripeGlobal = 'Stripe';

export const SOURCE_TYPE_SEPA = 'sepa_debit';
export const SOURCE_TYPE_CARD = 'card';

export const STRIPE_SOURCE = 'source';
export const STRIPE_TOKEN = 'token';

export const INTERVAL_MONTH = 'month';
export const INTERVAL_YEAR = 'year';

export const PRICING_TYPE_BASE = 'BASE';
export const PRICING_TYPE_PER_SEAT = 'PER_SEAT';
export const PRICING_TYPE_PER_GTIN = 'PER_GTIN';

export const BILL_ROW_TYPE_PRICE = 'PRICE';
export const BILL_ROW_TYPE_NUMBER = 'NUMBER';

export const OFFER_TYPE_SMB = 'SMB';
export const OFFER_TYPE_ME = 'ME';
