import { List, Map } from 'immutable';
import React, { ComponentType, useCallback } from 'react';

import {
  getIsEligibleForSourcing,
  getLanguageValueFromMultiple,
} from 'core/api/productversion';
import { isRetailer } from 'core/api/user';
import BoolCell from 'core/modules/list/components/table/cell/bool';
import EmptyCell from 'core/modules/list/components/table/cell/empty';
import ReferentialCell from 'core/modules/list/components/table/cell/referential';
import SmartCell, {
  Column,
} from 'core/modules/list/components/table/cell/smart';
import TextCell from 'core/modules/list/components/table/cell/text';
import {
  ARCHIVED_PRODUCTS,
  ASSIGNATION,
  PRODUCTS,
  PRODUCTS_TO_REVIEW,
  PRODUCTS_WITH_MENU,
  SOURCING,
} from 'modules/catalog/product/constants/context';
import {
  getCode,
  getDefault,
  getDefaultForSourcing,
  getPath,
  getType,
  isBold,
  isClickable,
  isLocalized,
} from 'modules/catalog/product/selectors/referential';
import { UserImmutable } from 'types';
import { get } from 'utils/immutable';

import DefaultAction from './action';
import ActionHpp from './action-hpp';
import AssignationDateColumn from './assignation-date';
import BrandColumn from './brand';
import CompanyProfileColumn from './company-profile';
import DateColumn from './date';
import DateTimeColumn from './datetime';
import CatalogExportableTagColumn from './exportable-tag';
import GtinColumn from './gtin';
import LifecycleColumn from './lifecycle';
import { MaturitiesDeadlinesCell } from './maturities-deadlines';
import MaturityColumn from './maturity';
import MaturityDeadlineColumn from './maturity-deadline';
import MessageColumn from './message';
import NutriScoreCell from './nutriscore';
import PercentageColumn from './percentage';
import PhysicalCheckerRetailerStatus from './physical-checker-retailer-status';
import PhysicalCheckerStatus from './physical-checker-status';
import {
  CatalogReviewLastUpdatedAtCell,
  CatalogReviewStatusCell,
} from './product-review';
import RequiredFieldsCategoryColumn from './required-fields-category';
import ScoreColumn from './score';
import SourceOrganizationsPictureCell from './source-organization-picture';
import SourceOrganizationsCell from './source-organizations';
import SourcingLabelsColumn from './sourcing-labels';
import SynchronizationStatusColumn from './synchronization-status';
import UserLabelsColumn from './user-labels';
import ValidationColumn from './validation';

type CatalogColumnProps = {
  product: Map<string, any>;
  referential: Column;
  locales?: List<any>;
  user: UserImmutable;
  context?: typeof PRODUCTS;
};

export const CatalogColumn = React.memo(
  ({
    product: productVersion,
    referential: column,
    locales,
    user,
    context = PRODUCTS,
  }: CatalogColumnProps) => {
    const craftData = useCallback(
      (referential) => {
        const path = getPath(referential) || '';
        const localized = isLocalized(referential);
        let data = get(productVersion, path);
        let localeCode = '';
        if (localized) {
          ({ data, localeCode } = getLanguageValueFromMultiple(data, locales));
        }
        return data == null || data === ''
          ? {
              data:
                context === SOURCING
                  ? getDefaultForSourcing(
                      referential,
                      getIsEligibleForSourcing(productVersion)
                    )
                  : getDefault(referential),
              isDefaultData: true,
              localeCode,
            }
          : { data, isDefaultData: false, localeCode };
      },
      [context, locales, productVersion]
    );

    const craftCell = useCallback(
      (referential): { Cell: any; props: any } => {
        const code = getCode(referential);
        const props = {
          productVersionId: get(productVersion, 'id'),
          code,
          row: productVersion,
        };

        switch (getType(referential)) {
          case 'text':
            return {
              Cell: TextCell,
              props: {
                isBold: isBold(referential),
                ...props,
              },
            };
          case 'bool':
            return { Cell: BoolCell, props };
          case 'referential':
            return { Cell: ReferentialCell, props };
          case 'brand':
            return { Cell: BrandColumn, props };
          case 'gtin':
            return {
              Cell: GtinColumn,
              props: {
                productVersion,
                user,
                ...props,
              },
            };
          case 'userlabels':
            return { Cell: UserLabelsColumn, props };
          case 'quality':
            return { Cell: ValidationColumn, props };
          case 'date':
            return { Cell: DateColumn, props };
          case 'datetime':
            return { Cell: DateTimeColumn, props };
          case 'assignationdate':
            return {
              Cell: AssignationDateColumn,
              props: {
                productVersion,
                ...props,
              },
            };
          case 'exportable_tag':
            return {
              Cell: CatalogExportableTagColumn,
              props: {
                ...props,
                status: get(productVersion, [
                  'alkemics',
                  'isAdvancedExportable',
                ]),
              },
            };
          case 'lifecycle':
            return { Cell: LifecycleColumn, props };
          case 'score':
            return { Cell: ScoreColumn, props };
          case 'physical_checker_status':
            return { Cell: PhysicalCheckerStatus, props };
          case 'physical_checker_retailer_status':
            return { Cell: PhysicalCheckerRetailerStatus, props };
          case 'required_fields_category':
            return { Cell: RequiredFieldsCategoryColumn, props };
          case 'message':
            return {
              Cell: MessageColumn,
              props: {
                productVersion,
                isRetailer: isRetailer(user),
                ...props,
              },
            };
          case 'synchronizationStatus':
            return { Cell: SynchronizationStatusColumn, props };
          case 'assignation_source_organizations':
            return { Cell: SourceOrganizationsCell, props };
          case 'assignation_source_organization_picture':
            return { Cell: SourceOrganizationsPictureCell, props };
          case 'company_profile':
            return { Cell: CompanyProfileColumn, props };
          case 'sourcinglabelsv2':
            return { Cell: SourcingLabelsColumn, props };
          case 'nutriscore':
            return { Cell: NutriScoreCell, props };
          case 'maturities':
            return {
              Cell: MaturityColumn,
              props: {
                user,
                colorRange: referential.getIn(['data', 'color_range']),
                clickable: true,
                rowIdentifier: props.productVersionId,
                ...props,
              },
            };
          case 'maturities_upcoming_deadline':
            return {
              Cell: MaturityDeadlineColumn,
              props: {
                user,
                ...props,
              },
            };
          case 'maturities_deadlines':
            return {
              Cell: MaturitiesDeadlinesCell,
              props: {
                user,
                ...props,
              },
            };
          case 'percentage':
            return {
              Cell: PercentageColumn,
              props: {
                countPath: referential.getIn(['data', 'path_count']),
                totalPath: referential.getIn(['data', 'path_total']),
                colorRange: referential.getIn(['data', 'color_range']),
                ...props,
              },
            };
          case 'review_status':
            return { Cell: CatalogReviewStatusCell, props };
          case 'review_last_updated_at':
            return { Cell: CatalogReviewLastUpdatedAtCell, props };
        }
        return { Cell: EmptyCell, props };
      },
      [user, productVersion]
    );

    const craftAction = useCallback(
      (referential): ComponentType | null => {
        if (!isClickable(referential)) {
          return null;
        }
        let ColumnAction;
        switch (context) {
          case PRODUCTS:
          case PRODUCTS_WITH_MENU:
          case PRODUCTS_TO_REVIEW:
          case ARCHIVED_PRODUCTS:
          case ASSIGNATION:
            ColumnAction = DefaultAction;
            break;
          case SOURCING:
            ColumnAction = ActionHpp;
            break;
          default:
        }
        return ({ children }) => (
          <ColumnAction productVersion={productVersion} user={user}>
            {children}
          </ColumnAction>
        );
      },
      [user, context, productVersion]
    );

    return (
      <SmartCell
        column={column}
        row={productVersion}
        cellCrafter={craftCell}
        dataCrafter={craftData}
        actionCrafter={craftAction}
      />
    );
  }
);

export default CatalogColumn;
