import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Ellitips } from '@alkem/react-ui-ellitips';
import Well from '@alkem/react-ui-well';

import ProductReference from 'components/ui/product-reference';
import { getDefaultDisplayName, getProductGTIN } from 'core/api/productversion';
import i18n from 'utils/i18n';

import './products-summary.scss';

export default class ProductsSummary extends PureComponent {
  static propTypes = {
    locales: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    productVersions: ImmutablePropTypes.list.isRequired,
  };

  renderOneProduct = (productVersion) => {
    const { locales } = this.props;
    return (
      <div className="ProductsSummary__item" key={productVersion.get('id')}>
        <ProductReference reference={getProductGTIN(productVersion)} />
        <span className="ProductsSummary__separator">-</span>
        <Ellitips
          id={`bulk-edit-product-ellitips-${productVersion.get('id')}`}
          label={getDefaultDisplayName(productVersion, locales)}
        />
      </div>
    );
  };

  render() {
    const { productVersions } = this.props;
    let summaryMessage = i18n.t(
      'frontproductstream.product_bulk_edit.modal_summary.multiple_products_message',
      {
        defaultValue: 'Summary of the {{number}} selected products:',
        number: productVersions.size,
      }
    );
    if (productVersions.size === 1) {
      summaryMessage = i18n.t(
        'frontproductstream.product_bulk_edit.modal_summary.single_product_message',
        { defaultValue: 'Summary of the selected product:' }
      );
    }
    return (
      <div className="ProductsSummary">
        <Well
          content={summaryMessage}
          textButtonExpand={i18n.t(
            'frontproductstream.product_bulk_edit.modal_summary.well_expand_button_label',
            { defaultValue: 'View' }
          )}
          textButtonCollapse={i18n.t(
            'frontproductstream.product_bulk_edit.modal_summary.well_collapse_button_label',
            { defaultValue: 'Hide' }
          )}
        >
          <div className="ProductsSummary__list">
            {productVersions.map(this.renderOneProduct)}
          </div>
        </Well>
      </div>
    );
  }
}
