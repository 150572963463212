import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { isManufacturer } from 'core/api/user';
import BulkUnshare, {
  ProductsMap,
  RecipientSelectionModal,
  showBulkUnshare,
} from 'modules/bulk-unshare';
import i18n from 'utils/i18n';

import View from '../view';

import BulkUnshareModalRowComponent from './row';
import useSplit from './useSplit';

const UnshareAction: View = (props) => {
  const { selectedMap, productMap } = props;

  const dispatch = useDispatch();

  const [
    unshareableSelectedProductVersions,
    notUnshareableSelectedProductVersions,
  ] = useSplit(productMap, selectedMap);

  const [showRecipentSelectionModal, setShowRecipientSelectionModal] =
    useState(false);

  const handleRecipientSelection = useCallback(
    (productVersions: ProductsMap, recipientId: number | null) => {
      setShowRecipientSelectionModal(false);
      dispatch(showBulkUnshare({ productVersions, recipientId }));
    },
    [dispatch]
  );

  const handleActionClick = useCallback(() => {
    setShowRecipientSelectionModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setShowRecipientSelectionModal(false);
  }, []);

  return (
    <>
      <div className="ActionOption" onClick={handleActionClick}>
        {i18n.t('Unshare')}
      </div>
      {showRecipentSelectionModal ? (
        <RecipientSelectionModal
          unshareables={unshareableSelectedProductVersions}
          notUnshareables={notUnshareableSelectedProductVersions}
          onConfirm={handleRecipientSelection}
          onClose={onCloseModal}
        />
      ) : (
        <BulkUnshare rowComponent={BulkUnshareModalRowComponent} />
      )}
    </>
  );
};

UnshareAction.isAvailable = ({ user }) => isManufacturer(user);

export default UnshareAction;
