import { MAKER, RETAILER, THIRD_PARTY } from 'constants/types';
import * as routes from 'routes';
import i18n from 'utils/i18n';

export const NOTIFICATION_UPDATE_INTERVAL = Number(
  process.env.NOTIFICATION_UPDATE || 5 * 60 * 1000
);

const catalogTab = {
  path: routes.catalog,
  label: i18n.t('frontproductstream.constants.navbar_menu_tab.catalog', {
    defaultValue: 'My Catalog',
  }),
  key: 'catalog',
  isActive: (match, { pathname }) => {
    if (pathname.startsWith('/products/')) {
      return true;
    }

    return match;
  },
};
const reportingTab = {
  path: routes.reporting,
  label: i18n.t('frontproductstream.constants.navbar_menu_tab.reporting', {
    defaultValue: 'Reporting',
  }),
  key: 'reporting',
};
const productNetworkTab = {
  path: routes.productNetwork,
  label: i18n.t(
    'frontproductstream.constants.navbar_menu_tab.product_network',
    {
      defaultValue: 'Product Network',
    }
  ),
  key: 'productnetwork',
};

const rfpTabV2 = {
  path: routes.rfp,
  label: i18n.t('frontproductstream.constants.navbar_menu_tab.rfp', {
    defaultValue: 'Request for proposal',
  }),
  key: 'rfpV2',
};

const sharingUnitTariffsTab = {
  path: routes.SharingUnitTariffsList,
  label: i18n.t(
    'frontproductstream.constants.navbar_menu_tab.listing_tariffs',
    {
      defaultValue: 'Listing tariffs',
    }
  ),
  key: 'sharingUnitTariffs',
};
const sharingUnitTemplatesTab = {
  path: routes.sharingUnitTemplatesList,
  label: i18n.t(
    'frontproductstream.constants.navbar_menu_tab.listing_templates',
    { defaultValue: 'Listing templates' }
  ),
  key: 'sharingUnitTemplates',
};
const sharingUnitTemplatesAndTariffsTab = {
  path: routes.landing,
  label: i18n.t(
    'frontproductstream.constants.navbar_menu_tab.listing_templates',
    { defaultValue: 'Listing templates' }
  ),
  key: 'sharingUnitTemplatesAndTariffs',
};

const nielsenTab = {
  path: routes.nielsen,
  label: i18n.t('frontproductstream.constants.navbar_menu_tab.nielsen', {
    defaultValue: 'Nielsen',
  }),
  key: 'nielsen',
};
const suppliersTab = {
  path: routes.suppliersList,
  label: i18n.t('frontproductstream.constants.navbar_menu_tab.suppliers', {
    defaultValue: 'Suppliers',
  }),
  key: 'suppliers',
};
const importsGenericTab = {
  path: routes.importsGeneric,
  label: i18n.t(
    'frontproductstream.constants.navbar_menu_tab.generic_imports',
    {
      defaultValue: 'Imports',
    }
  ),
  key: 'importsGeneric',
};

const importsTab = {
  path: routes.imports,
  label: i18n.t('frontproductstream.constants.navbar_menu_tab.imports', {
    defaultValue: 'Imports',
  }),
  key: 'imports',
};

export default {
  organization: {
    isMDD: false,
  },
  version: 2,
  defaultTargetMarket: {
    id: 250, // FR
  },
  brands: {
    viewProducts: false, // Hide the button `View products`
  },
  product: {
    header: {
      moreActions: true,
      displayPreview: true,
      navigation: true,
    },
    historyDiff: false,
  },
  media: {
    showPictures: true,
    showVideos: true,
    showEnrichedContent: true,
    showFilters: true,
  },
  productList: {
    [MAKER]: {
      showFilters: true,
      showActions: true,
      showProductCreateButton: true,
      sizes: {
        filters: 3,
        list: 9,
      },
      requestParams: {},
      row: {
        pictureSize: 62,
        checkbox: true,
        picture: true,
        name: {
          ref: true,
        },
        brand: {
          classification: true,
        },
        ref: false,
        classification: false,
        packaging: true,
        status: true,
        onClickURL: 'MAKER_PRODUCT_DASHBOARD',
      },
    },
    [RETAILER]: {
      legacy: false,
      showActions: false,
      row: {
        pictureSize: 62,
        checkbox: false,
        picture: true,
        name: {
          ref: false,
        },
        ref: true,
        brand: false,
        classification: false,
        packaging: false,
        status: false,
        shared: false,
      },
      filters: [
        // {
        //   label: 'Univers',
        //   type: 1, // pish segment type
        //   filterType: 'restrictedAisleSegmentIds' // map to store
        // }
      ],
      showFilters: {
        hierarchy: true,
      },
    },
  },
  navbar: {
    profileLink: true,
    adminLink: true,
    organizationLink: true,
    resourcesLink: true,
    notificationsLink: true,
    [RETAILER]: [
      catalogTab,
      reportingTab,
      sharingUnitTemplatesAndTariffsTab,
      sharingUnitTariffsTab,
      sharingUnitTemplatesTab,
      rfpTabV2,
      productNetworkTab,
      suppliersTab,
      importsGenericTab,
    ],
    [MAKER]: [
      catalogTab,
      reportingTab,
      sharingUnitTemplatesAndTariffsTab,
      sharingUnitTariffsTab,
      sharingUnitTemplatesTab,
      rfpTabV2,
      nielsenTab,
      productNetworkTab,
      importsTab,
    ],
    [THIRD_PARTY]: [catalogTab, importsTab],
  },
  picture: {
    [MAKER]: {
      shared: false,
    },
    [RETAILER]: {
      shared: false,
    },
  },
  sharing: {
    status: 'name.default',
  },
  features: {
    [MAKER]: {
      product: {
        autoEnrichment: true,
        lifeCycle: true,
        duplicate: true,
        bulkEdit: true,
        dashboard: {
          progress: {
            active: false,
          },
          validation: {
            active: true,
          },
          sharingModule: {
            active: true,
            hideOrganizations: [663],
            enrichingWebsite: {
              active: true,
            },
            permanentListing: {
              active: false,
            },
          },
        },
      },
      productCreate: true,
    },
    [RETAILER]: {},
    notifications: false,
    permissions: {
      readonly: false,
    },
  },
  routes: '*',
};
