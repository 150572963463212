import { CancelablePromise } from 'cancelable-promise';
import { debounce, isFunction } from 'lodash';
import memoize from 'memoize-one';
import { Component } from 'react';

import { Select } from '@alkem/react-ui-select';

import { cancelPromise } from 'utils';
import { sortAsc } from 'utils/sort';

import './autocomplete.scss';

export type AutocompleteProps = {
  className?: string;
  id: string;
  value?: any[];
  multiple?: boolean;
  disabled?: boolean;
  excludeList?: any[];
  onFocus?: any;
  onSelect?: any;
  onUnselect?: any;
  autoFocus?: boolean;
  options?: {
    cacheFullList?: boolean;
    selectableParent?: boolean;
  };
  placeholder?: string;
  searchOnClick?: boolean;
  sort?: string;
  // Key to use to get the labels to display
  keyForSelected?: string;
  // If there is only one option, select it and make field readonly
  autoSelect?: boolean;
  scrollIntoView?: boolean;
  prefetchValues?: any[];
  inputable?: boolean;
  formatLabel?: any;
};

type State = {
  list?: any[];
  displaySpinner?: boolean;
  listPromise: CancelablePromise<any> | null;
  unfilteredResults: any[] | null;
};

abstract class Autocomplete<T = {}> extends Component<
  AutocompleteProps & T,
  State
> {
  static defaultProps = {
    className: 'Autocomplete',
    id: '',
    multiple: false,
    disabled: false,
    excludeList: [],
    options: {},
    placeholder: '',
    searchOnClick: false,
    sort: '',
    autoSelect: false,
    keyForSelected: 'label',
    scrollIntoView: false,
    inputable: true,
  };

  _isMounted: boolean;

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      list: [],
      listPromise: null,
      displaySpinner: false,
      unfilteredResults: null,
    };
    this.onFocus = this.onFocus.bind(this);
    this.search = debounce(this.search.bind(this), 500);
    this.onSelect = this.onSelect.bind(this);
    this.onUnselect = this.onUnselect.bind(this);
  }

  abstract getList(input: string);

  static getDerivedStateFromProps({ prefetchValues }, { list }) {
    if (prefetchValues && prefetchValues.length && !list.length) {
      return { list: prefetchValues };
    } else {
      return null;
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSelect(data) {
    const { onSelect } = this.props;
    if (onSelect && isFunction(onSelect)) {
      onSelect(data);
    }
  }

  onFocus(e) {
    if (this.props.searchOnClick && !this.props.disabled) {
      this.displaySpinner(true); // search is debounced, so we need to set spinner now
      this.search();
    }
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onUnselect(data, event) {
    if (event && isFunction(event.stopPropagation)) {
      event.stopPropagation();
    }
    const { onUnselect } = this.props;
    if (onUnselect && isFunction(onUnselect)) {
      onUnselect(data);
    }
  }

  onRequestDone() {
    if (this._isMounted) {
      this.setState({
        displaySpinner: false,
        listPromise: null,
      });
    }
  }

  getSingleItemOrNull(tree) {
    // For a tree, checks if there is a single leaf node and returns it if yes, else returns false
    if (Array.isArray(tree.children) && tree.children.length > 0) {
      return (
        tree.children.length === 1 && this.getSingleItemOrNull(tree.children[0])
      );
    }
    return tree;
  }

  getSelected = memoize((value) => {
    const { keyForSelected, formatLabel } = this.props;
    if (value) {
      return value.map((item) => {
        const formattedLabel = formatLabel && formatLabel(item);
        const label =
          formattedLabel || (keyForSelected && item[keyForSelected]);
        return { item, label };
      });
    }
    return [];
  });

  search(query?) {
    this.displaySpinner(true);
    if (this.state.listPromise) {
      cancelPromise(this.state.listPromise);
    }
    let promise;
    if (!this.props.searchOnClick && !query) {
      promise = Promise.resolve();
      this.setState({ list: [] });
    } else if (!query && this.state.unfilteredResults) {
      promise = Promise.resolve();
      this.setState((state) => ({
        list: [...(state.unfilteredResults || [])],
      }));
    } else {
      promise = this.getList(query || '');
      this.setState({ listPromise: promise });
    }
    if (promise) {
      // promise can be null
      promise.then(() => this.onRequestDone());
    } else {
      this.displaySpinner(false);
    }
    return promise;
  }

  displaySpinner(displayed) {
    if (this._isMounted) {
      this.setState({ displaySpinner: displayed });
    }
  }

  updateList(list, emptySearch = false) {
    if (!this._isMounted) {
      return;
    }
    const { autoSelect, sort, options } = this.props;
    const excludeListIds = this.props.excludeList?.map((e) => e.key);
    const newList = list.filter(
      (option) => excludeListIds?.indexOf(option.key) === -1
    );
    if (sort === 'alphabetical') {
      newList.sort((a, b) => sortAsc(a.label, b.label));
    }
    if (options && options.cacheFullList && emptySearch) {
      this.setState({ unfilteredResults: newList });
    }

    if (autoSelect && emptySearch && newList.length === 1) {
      // Automatically select the single value of newList and set the field as readonly
      const singleItem = this.getSingleItemOrNull(newList[0]);
      this.setState({ list: newList });
      if (singleItem) {
        this.onSelect(singleItem);
      }
    } else {
      this.setState({ list: newList });
    }
  }

  valueEqualsTreeItem(value, option) {
    // This is meant to draw the relation between an input value and the value of a tree item
    return value.item && option.value && value.item.id === option.value.id;
  }

  isTree(list) {
    return list.some(
      (item) => Array.isArray(item.children) && item.children.length > 0
    );
  }

  formatList(list) {
    return list;
  }

  renderInfo() {
    return null;
  }

  render() {
    const {
      autoFocus,
      className,
      disabled,
      id,
      inputable,
      multiple,
      options,
      placeholder,
      scrollIntoView,
      value,
      onUnselect,
    } = this.props;
    const { list, displaySpinner } = this.state;

    return (
      <div
        className={className}
        onClick={this.onFocus}
        data-testid={`select-ac-${id}`}
      >
        <Select
          id={`select-ac-${id}`}
          onValueAdd={this.onSelect}
          onValueDelete={onUnselect ? this.onUnselect : null}
          values={this.getSelected(value)}
          placeholder={placeholder}
          delegateSearch={this.search}
          multiple={multiple}
          disabled={disabled}
          options={this.formatList(list)}
          extraOptions={options}
          valueEqualsTreeItem={this.valueEqualsTreeItem}
          inputable={inputable}
          isTree={this.isTree(list)}
          onFocus={this.onFocus}
          displaySpinner={displaySpinner}
          scrollIntoView={scrollIntoView}
          autoFocus={autoFocus}
        />
        {this.renderInfo()}
      </div>
    );
  }
}

export default Autocomplete;
