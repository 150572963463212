import { flow, get, pick, set } from 'lodash/fp';
import { call, put, select } from 'redux-saga/effects';

import { ActionPayload } from 'types';
import { track } from 'utils/tracking';

import {
  bulkPatchDataOpsField,
  bulkStoreDataOpsPatch,
  patchDataOpsField,
  storeDataOpsPatch,
} from '../actions';
import {
  selectDataOpsEntityIdToProductKeyId,
  selectDataOpsPatch,
} from '../selectors';
import { DataOpsNewPatch, DataOpsPatch, DataOpsPatchType } from '../types';

import { getEntityById } from './utils';

type EntityIdToProductKeyId = ReturnType<
  typeof selectDataOpsEntityIdToProductKeyId
>;
type CreatePatchData = {
  fieldName: string;
  patch: DataOpsPatch | DataOpsNewPatch;
};
type Patch = ReturnType<typeof selectDataOpsPatch>;

function* createPatch(model: string, entityId: number | string, value: any) {
  const [fieldName] = model.split('.');

  const entityIdToProductKeyId: EntityIdToProductKeyId = yield select(
    selectDataOpsEntityIdToProductKeyId
  );
  const productKeyId = entityIdToProductKeyId[entityId];
  const entity: any = yield call(getEntityById, entityId);

  const currentPatch: Patch = yield select(
    selectDataOpsPatch([entityId, fieldName])
  );

  const data = currentPatch?.data?.[0]
    ? { [fieldName]: currentPatch?.data[0].dataNew }
    : pick([fieldName], entity);

  const dataNew = flow(set(model, value), get(fieldName))(data);

  const patch: DataOpsPatch | DataOpsNewPatch = currentPatch?.data?.[0]
    ? set(['dataNew'], dataNew, currentPatch.data[0])
    : {
        productKeyId,
        fieldName,
        type: DataOpsPatchType.PRODUCT_VERSION,
        dataOld: get([fieldName], entity),
        dataNew,
      };

  return { fieldName, patch } as CreatePatchData;
}

export function* patchFieldSaga({
  payload: [label, model, value, entityId, entityKind],
}: {
  payload: ActionPayload<typeof patchDataOpsField>;
}) {
  const { fieldName, patch }: CreatePatchData = yield call(
    createPatch,
    model,
    entityId,
    value
  );

  yield put(
    storeDataOpsPatch({
      entityId,
      fieldName,
      patch,
      label,
      entityKind,
    })
  );
}

export function* bulkPatchFieldSaga({
  payload: fieldsToPatch,
}: {
  payload: ActionPayload<typeof bulkPatchDataOpsField>;
}) {
  const bulkPatchPayload: ActionPayload<typeof bulkStoreDataOpsPatch> = [];

  for (const [model, value, entityId, entityKind] of fieldsToPatch) {
    const { fieldName, patch }: CreatePatchData = yield call(
      createPatch,
      model,
      entityId,
      value
    );
    bulkPatchPayload.push({
      entityId,
      fieldName,
      patch,
      entityKind,
    });
  }

  yield put(bulkStoreDataOpsPatch(bulkPatchPayload));
}

export function trackReceivedPatches({ payload }) {
  payload.patches?.forEach((patch) => {
    track({
      action: 'first_correction_suggestion_to_manuf',
      category: 'Data Ops',
      product_key_id: patch.productKeyId,
      patch_id: patch.id,
      field_name: patch.fieldName,
    });
  });
}
