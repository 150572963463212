import { List, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { RECEIVE_PRODUCT_VERSION } from 'constants/events/productversion';
import {
  DATA_OPS_SAVE_PATCHES,
  DATA_OPS_SAVE_PATCHES_DONE,
} from 'modules/data-ops';
import { SELECTED_HETEROGENEOUS_UNIT } from 'modules/display-groups/constants';
import { productIsHeterogeneousLogisticalUnit } from 'modules/logistical-hierarchies/helpers';

import {
  CLEAR_PROMPTED_MATURITY_RULE_SETS_WITH_DEADLINES,
  FETCH_REJECTED_FIELDS,
  PROMPT_MATURITY_RULE_SETS_WITH_DEADLINES,
  RECEIVE_REJECTED_FIELDS,
  REQUIRE_MATURITY_RULES,
  REQUIRE_MATURITY_RULES_DONE,
  REQUIRE_MATURITY_RULES_WITH_DEADLINES,
  REQUIRE_MATURITY_RULES_WITH_DEADLINES_DONE,
  SAVE_PRODUCT_ERROR,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
  SAVE_VISIBILITY,
  SAVE_VISIBILITY_ERROR,
  SAVE_VISIBILITY_SUCCESS,
  SET_LOADING,
} from './constants';

const initialState = fromJS({
  isLoading: true,
  savingInProgress: false,
  savingSuccessful: false,
  isHeterogeneousUnitSelected: false,
  settingVisibility: false,
});

export default createReducer(initialState, {
  [SET_LOADING]: (state, { payload }: any) =>
    state.set('isLoading', payload.isLoading),

  [SAVE_PRODUCT_REQUEST]: (state) =>
    state.set('savingInProgress', true).set('savingSuccessful', false),

  [SAVE_PRODUCT_SUCCESS]: (state) =>
    state.set('savingInProgress', false).set('savingSuccessful', true),

  [SAVE_PRODUCT_ERROR]: (state) =>
    state.set('savingInProgress', false).set('savingSuccessful', false),

  [SAVE_VISIBILITY]: (state) => state.set('settingVisibility', true),
  [SAVE_VISIBILITY_SUCCESS]: (state) => state.set('settingVisibility', false),
  [SAVE_VISIBILITY_ERROR]: (state) => state.set('settingVisibility', false),

  [REQUIRE_MATURITY_RULES]: (state) => state.set('savingInProgress', true),
  [REQUIRE_MATURITY_RULES_DONE]: (state) =>
    state.set('savingInProgress', false),
  [REQUIRE_MATURITY_RULES_WITH_DEADLINES]: (state) =>
    state.set('savingInProgress', true),
  [REQUIRE_MATURITY_RULES_WITH_DEADLINES_DONE]: (state) =>
    state.set('savingInProgress', false),

  [DATA_OPS_SAVE_PATCHES]: (state) => state.set('savingInProgress', true),
  [DATA_OPS_SAVE_PATCHES_DONE]: (state) => state.set('savingInProgress', false),
  [SELECTED_HETEROGENEOUS_UNIT]: (state, payload: any) =>
    state.set('isHeterogeneousUnitSelected', payload.value),
  [RECEIVE_PRODUCT_VERSION]: (state, payload: any) =>
    state.set(
      'isHeterogeneousUnitSelected',
      productIsHeterogeneousLogisticalUnit(payload, 'productVersion')
    ),
  [FETCH_REJECTED_FIELDS]: (state) => state.set('rejectedFields', undefined),
  [RECEIVE_REJECTED_FIELDS]: (state, { payload }: any) =>
    state.set('rejectedFields', payload),
  [PROMPT_MATURITY_RULE_SETS_WITH_DEADLINES]: (state, { payload }: any) =>
    state.set('promptedMaturityRuleSetsWithDeadlines', payload),
  [CLEAR_PROMPTED_MATURITY_RULE_SETS_WITH_DEADLINES]: (state) =>
    state.set('promptedMaturityRuleSetsWithDeadlines', List()),
});
