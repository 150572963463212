import EmptyState from '@alkem/react-ui-empty-state';

import i18n from 'utils/i18n';

export default function CatalogDefaultEmptyTable() {
  return (
    <EmptyState
      title={i18n.t('No product found')}
      text={i18n.t(
        'No results available for this search. Please try with another query.'
      )}
    />
  );
}
