import { remove as removeDiacritics } from 'diacritics';
import { curry, flow, isString } from 'lodash/fp';

export const capitalize = (txt: string = '') =>
  `${txt[0].toUpperCase()}${txt.substring(1).toLowerCase()}`;

export const toTitleCase = <T = unknown>(str: T) =>
  isString(str) ? str.replace(/[^\-'_\s]+/g, capitalize) : str;

export const toSafeLowerCase = <T = unknown>(str: T) =>
  isString(str) ? str.toLowerCase() : str;

export const safeTrim = <T = unknown>(str: T) =>
  isString(str) ? str.trim() : str;

export const safeSubstr = curry<number, number, string, string>(
  (start, end, str) => (isString(str) ? str.substring(start, end) : str)
);

export const safeRemoveDiacritics = <T = unknown>(str: T) =>
  isString(str) ? (removeDiacritics as (s: string) => string)(str) : str;

export const toComparable = flow(toSafeLowerCase, safeRemoveDiacritics);
export const toComparableWithTrim = flow(
  safeTrim,
  toSafeLowerCase,
  safeRemoveDiacritics
);

export const replaceLineBreaks = (str: string) =>
  str.replace(/\r\n\t|\r\t|\r\n|\r|\n|\t/gm, ' ');
