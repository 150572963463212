import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { Spinner } from '@alkem/react-ui-spinner';

import Form from 'components/ui/form';
import { getTypePackagingCode } from 'constants/typePackaging';
import {
  getDisplayName,
  getTypePackaging,
  getTypePackagingId,
} from 'core/api/productversion';
import {
  RELEASE_LOGISTICAL_HIERARCHIES_ADVANCED_PRIVATE_FIELDS,
  RELEASE_LOGISTICAL_HIERARCHIES_STICKY_FORM_HEADER,
} from 'modules/feature-flag/constants';
import { formatLogisticalUnitEntityId } from 'modules/logistical-hierarchies/helpers';
import { Data, VersionData } from 'modules/logistical-hierarchies/structures';
import { excludeFilter, filterDisplayGroups } from 'modules/view-as/utils';
import { selectFlags } from 'reducers/user/selectors';
import { Language } from 'types/language';
import { getAsReadOnly } from 'utils/displayGroup';
import i18n from 'utils/i18n';

import { fetchDisplayGroupsIfRequired } from '../../actions';
import { LOGISTICAL_HIERARCHIES_PRIVATE_FIELD_EDIT } from '../../constants';
import * as constants from '../../constants';
import LogisticalHierarchyIcon from '../icon';
import '../unit/unit.scss';

import './form.scss';

export interface LogisticalHierarchyFormProps {
  hasFeatureNoGtin: boolean;
  internalId: string;
  rootInternalId: string;
  gtin: Data['gtin'];
  productIdentifier: Data['productIdentifier'];
  data: VersionData;
  currentLanguage?: Language;
  allDisplayGroups: object;
  readOnly: boolean;
  hasFlatHierarchies?: boolean;
  excludedFieldNames: string[];
  patches?: {};
  disableDataOps?: boolean;
}

const PAGE_NAVBAR_HEIGHT = 40;
const HORIZONTAL_PADDING = 24;

export function LogisticalHierarchyForm(props: LogisticalHierarchyFormProps) {
  const {
    hasFeatureNoGtin,
    readOnly,
    internalId,
    data,
    gtin,
    productIdentifier,
    rootInternalId,
    currentLanguage,
    allDisplayGroups,
    hasFlatHierarchies,
    excludedFieldNames,
    patches,
    disableDataOps,
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    fetchDisplayGroupsIfRequired()(dispatch);
  }, [dispatch]);

  const hasStickyHeader = (
    useSelector(selectFlags) as { [key: string]: boolean }
  )[RELEASE_LOGISTICAL_HIERARCHIES_STICKY_FORM_HEADER];

  const hasLogisticalHierarchiesPrivateFieldEdit = (
    useSelector(selectFlags) as { [key: string]: boolean }
  )[RELEASE_LOGISTICAL_HIERARCHIES_ADVANCED_PRIVATE_FIELDS];

  const typePackagingId = getTypePackagingId(data);

  const onChangeField = useCallback(
    (
      field: string,
      value: any,
      _entityId: string,
      _entityKind: string,
      isDirty: boolean,
      _ignoreField: boolean,
      isPrivate: boolean
    ) => {
      if (hasLogisticalHierarchiesPrivateFieldEdit && isPrivate)
        dispatch({
          type: LOGISTICAL_HIERARCHIES_PRIVATE_FIELD_EDIT,
          field,
          internalId,
          value,
          isDirty,
        });
    },
    [dispatch, hasLogisticalHierarchiesPrivateFieldEdit, internalId]
  );

  const displayGroups = useMemo(() => {
    const filtered = excludedFieldNames?.length
      ? filterDisplayGroups(
          [excludeFilter({ fields: excludedFieldNames })],
          allDisplayGroups[typePackagingId]
        )
      : allDisplayGroups[typePackagingId];
    return readOnly ? filtered?.map((e) => getAsReadOnly(e)) : filtered;
  }, [allDisplayGroups, excludedFieldNames, readOnly, typePackagingId]);

  const formContent = useMemo(() => {
    if (!allDisplayGroups[typePackagingId]) {
      return <Spinner />;
    }
    // The reference is used by validation and updateEntity.
    // Maybe we could improve this and remove this check.
    if (hasFlatHierarchies && !gtin && !productIdentifier) {
      if (hasFeatureNoGtin) {
        return i18n.t(
          'frontproductstream.logistical_hierarchy_form.edit_unit_no_gtin.error',
          { defaultValue: 'You need to fill the reference to edit this unit.' }
        );
      }
      return i18n.t(
        'frontproductstream.logistical_hierarchy_form.edit_unit_gtin.error',
        { defaultValue: 'You need to fill the GTIN to edit this unit.' }
      );
    }

    if (displayGroups.length === 0) {
      return i18n.t(
        'frontproductstream.logistical_hierarchy_form.edit_unit_fields.error',
        { defaultValue: 'No fields visible.' }
      );
    }

    return displayGroups.map((displayGroup) => (
      <Form
        key={displayGroup.id}
        entity={data}
        entityKind={constants.ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT}
        entityId={formatLogisticalUnitEntityId(
          rootInternalId,
          gtin,
          productIdentifier
        )}
        formGroup={displayGroup}
        showTitle={false}
        currentLanguage={currentLanguage}
        patches={patches}
        disableDataOps={disableDataOps}
        onChangeField={onChangeField}
      />
    ));
  }, [
    allDisplayGroups,
    typePackagingId,
    hasFlatHierarchies,
    gtin,
    productIdentifier,
    displayGroups,
    data,
    rootInternalId,
    currentLanguage,
    patches,
    disableDataOps,
    onChangeField,
    hasFeatureNoGtin,
  ]);

  if (!hasFlatHierarchies) {
    return (
      <div className="LogisticalHierarchyForm" role="form">
        {formContent}
      </div>
    );
  }
  const typePackagingCode = getTypePackagingCode(getTypePackaging(data));
  const reference = gtin || productIdentifier;
  const label = `${getDisplayName(data, currentLanguage)} - ${reference}`;

  if (hasStickyHeader) {
    return (
      <div
        id={`LogisticalHierarchyForm-${rootInternalId}-${internalId}`}
        className="LogisticalHierarchyForm LogisticalHierarchyForm--new"
      >
        <div
          className={`LogisticalHierarchyForm__content LogisticalHierarchyForm__content--${typePackagingCode}`}
        >
          <StickyContainer>
            <Sticky
              bottomOffset={PAGE_NAVBAR_HEIGHT}
              topOffset={-PAGE_NAVBAR_HEIGHT}
            >
              {({ style, isSticky }) => (
                <div
                  style={{
                    ...style,
                    top: PAGE_NAVBAR_HEIGHT + (style.top || 0),
                    width: isSticky ? HORIZONTAL_PADDING + style.width : null,
                  }}
                  className={classNames('LogisticalHierarchyForm__header', {
                    sticky: isSticky,
                  })}
                >
                  <div className="content">
                    <LogisticalHierarchyIcon
                      packagingTypeId={getTypePackagingId(data)}
                    />
                    <Ellitips
                      id={`LogisticalHierarchyForm-${rootInternalId}-${internalId}-label`}
                      label={label}
                    />
                  </div>
                  <div className="separator" />
                </div>
              )}
            </Sticky>
            {formContent}
          </StickyContainer>
        </div>
      </div>
    );
  }

  return (
    <div
      id={`LogisticalHierarchyForm-${rootInternalId}-${internalId}`}
      className="LogisticalHierarchyForm LogisticalHierarchyForm--new"
    >
      <LogisticalHierarchyIcon packagingTypeId={getTypePackagingId(data)} />
      <div
        className={`LogisticalHierarchyForm__content LogisticalHierarchyForm__content--${typePackagingCode}`}
      >
        {formContent}
      </div>
    </div>
  );
}

export default LogisticalHierarchyForm;
