import PropTypes from 'prop-types';
// frameworks
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

// components
import Modal from 'components/ui/modal';
// constants
import { CHASE } from 'constants/assignation';
import { selectHasAttributionPerOrganizationFeature } from 'modules/feature-flag/selectors';
// Recipients
import { getRecipients } from 'modules/recipients/actions';
import { getAll as getAllRecipients } from 'modules/recipients/reducers';
// data connectors
import { selectIsSuperAdminLoggedAs } from 'reducers/user/selectors';
// utils
import i18n from 'utils/i18n';

// css
import './TargetRequestModal.scss';
import { closeTargetRequestModal, sendAssignation } from './actions';
import Emails from './components/form/emails';
import ForceToggle from './components/form/forceToggle';
// components
import Manufacturer from './components/form/manufacturer';
import Message from './components/form/message';
import Preview from './components/preview';
import {
  selectChaseType,
  selectModalIsOpen,
  selectSendingAssignation,
  selectTitle,
} from './selectors';

const mapStateToProps = (state) => ({
  modalIsOpen: selectModalIsOpen(state),
  title: selectTitle(state),
  chaseType: selectChaseType(state),
  sendingAssignation: selectSendingAssignation(state),
  hasAttributionPerOrganization:
    selectHasAttributionPerOrganizationFeature(state),
  recipients: getAllRecipients(state),
  isSuperAdminLoggedAs: selectIsSuperAdminLoggedAs(state),
});

const mapDispatchToProps = {
  closeTargetRequestModal,
  getRecipients,
  sendAssignation,
};

export class TargetRequestModal extends PureComponent {
  static propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    chaseType: PropTypes.string.isRequired,
    sendingAssignation: PropTypes.bool.isRequired,
    closeTargetRequestModal: PropTypes.func.isRequired,
    hasAttributionPerOrganization: PropTypes.bool.isRequired,
    recipients: ImmutablePropTypes.list.isRequired,
    getRecipients: PropTypes.func.isRequired,
    sendAssignation: PropTypes.func.isRequired,
    isSuperAdminLoggedAs: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (!this.props.recipients.size) {
      // TODO: don't load recipients here, make this work with autocomplete.
      this.props.getRecipients();
    }
  }

  renderDescription() {
    const { chaseType, hasAttributionPerOrganization, recipients } = this.props;
    if (!recipients.size) {
      return null;
    }
    if (!hasAttributionPerOrganization) {
      return (
        <p className="TargetRequestModal__description">
          {chaseType === CHASE
            ? i18n.t(
                'Put manufacturer email(s) to request the sharing of the selected product(s)'
              )
            : i18n.t(
                'Put manufacturer email(s) to attribute the selected product(s)'
              )}
        </p>
      );
    }
    return (
      <p className="TargetRequestModal__description">
        {chaseType === CHASE
          ? i18n.t(
              'Select a manufacturer and/or put email(s) to request the sharing of the selected product(s)'
            )
          : i18n.t(
              'Select a manufacturer and/or put email(s) to attribute the selected product(s)'
            )}
      </p>
    );
  }

  render() {
    const {
      title,
      sendingAssignation,
      modalIsOpen,
      chaseType,
      hasAttributionPerOrganization,
      recipients,
      isSuperAdminLoggedAs,
    } = this.props;
    if (!modalIsOpen) {
      return null;
    }
    return (
      <Modal
        modalStyle="dynamic"
        title={title}
        className="TargetRequestModal"
        confirmButtonText={i18n.t('Send')}
        onConfirm={this.props.sendAssignation}
        isProcessing={sendingAssignation}
        onClose={this.props.closeTargetRequestModal}
      >
        <div className="TargetRequestModal__body">
          {this.renderDescription()}
          <div className="row">
            <div className="col-sm-7">
              {(hasAttributionPerOrganization || chaseType === CHASE) && (
                <Manufacturer
                  disabled={chaseType === CHASE}
                  recipients={recipients}
                />
              )}
              <Emails />
              <Message />
              {isSuperAdminLoggedAs && <ForceToggle />}
            </div>
            <div className="col-sm-5">
              <Preview />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetRequestModal);
