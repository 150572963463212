import { isInteger, isString, sum } from 'lodash';

const FAKE_GTINS = [
  '00000000000000',
  '01111111111116',
  '11111111111113',
  '22222222222226',
  '33333333333339',
  '44444444444442',
  '55555555555555',
  '66666666666668',
  '77777777777771',
  '88888888888884',
  '99999999999999',
];
const isDigitsString = (str) => /^\d+$/.test(str);

export function isValidGtin(_gtin) {
  let gtin = _gtin;
  if (!isString(gtin) && !isInteger(gtin)) {
    return false;
  }
  if (isInteger(gtin)) {
    gtin += '';
  }
  gtin = gtin.padStart(14, '0');
  if (
    gtin.length !== 14 ||
    FAKE_GTINS.includes(gtin) ||
    !isDigitsString(gtin)
  ) {
    return false;
  }

  // GS1 Doc.: Restricted distribution (MO defined)
  // Those GTIN are reused locally by the Retailer, Product may be Banana in shop 1 and Apple in Shop 2
  // We are not compatible (yet) with this data
  if (gtin.startsWith('0020')) {
    return false;
  }

  const control = Number(gtin.slice(-1));
  return control === getControlDigit(gtin);
}

export function getControlDigit(gtin) {
  const gtinLength = gtin.length;
  const total = sum(
    gtin
      .slice(0, -1)
      .split('')
      .map(Number)
      .map((digit, i) => digit * (3 - ((gtinLength + i) % 2) * 2))
  );
  return (10 - (total % 10)) % 10;
}

export function getFirstDigit(gtin) {
  if (gtin.length !== 14) {
    return 0;
  }
  return parseInt(gtin.substring(0, 1), 10);
}

export function getRoot(_gtin) {
  const gtin = _gtin.padStart(14, '0');
  return gtin.substring(1, 13);
}
