import { map, mapValues } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';
import { createSelector } from 'reselect';

import {
  ASSETS_RECEIVE_ALL,
  UPDATE_ENTITY,
  UPDATE_NEW_PACKSHOT,
  UPDATE_NEW_SPECIFIC_PACKSHOT,
  UPDATE_SPECIFIC_ASSET,
} from 'constants/events/media';
import { DELETE_PRODUCT_VERSION } from 'constants/events/productversion';
import { ASSET_SCOPE, ASSET_STATUS } from 'constants/media';
import {
  ADD_SPECIFIC_ASSETS,
  DELETE_SPECIFIC_ASSETS,
} from 'modules/recipient-specific-block/events';
import { get, set, update } from 'utils/immutable';

// helpers

const resolveStatusFromScope = (scope) =>
  scope === ASSET_SCOPE.PUBLIC ? ASSET_STATUS.ACTIVE : ASSET_STATUS.INACTIVE;

const transferAssetsToSpecific = mapValues(
  map((asset) => set(asset, 'status', resolveStatusFromScope(asset.scope)))
);

// state

export const initialState = {
  assets: {
    pictures: [],
    videos: [],
    documents: [],
    enriched_contents: [],
  },
  specificData: [],
  totalExpired: 0,
};

// reducer

export default createReducer(initialState, {
  [ASSETS_RECEIVE_ALL]: (
    state,
    { payload: { assets, totalExpired, specificData } }
  ) => ({
    assets,
    totalExpired,
    specificData:
      // make sure we dont override specific data that has been set locally only
      specificData && specificData.length > 0
        ? specificData
        : state.specificData,
  }),

  [UPDATE_NEW_SPECIFIC_PACKSHOT]: (state, { payload }) => {
    const { pictureId, recipientId, newSpecificPackshot } = payload;
    const specificRecipientIndex = state.specificData.findIndex(
      (elem) => elem.targetOrganization.id === recipientId
    );
    const specificPictures =
      state.specificData[specificRecipientIndex].data.pictures;

    const newSpecificPackshotIndex = specificPictures.findIndex(
      (elem) => elem.id === pictureId
    );

    let newState = {};
    // If the specific packshot is not in the state add it
    if (newSpecificPackshotIndex < 0) {
      let newSpecificPictures = [...specificPictures, newSpecificPackshot];
      newState = set(
        state,
        `specificData.${specificRecipientIndex}.data.pictures`,
        newSpecificPictures
      );
    } else {
      newState = set(
        state,
        `specificData.${specificRecipientIndex}.data.pictures.${newSpecificPackshotIndex}.isPackshot`,
        true
      );
      newState = set(
        newState,
        `specificData.${specificRecipientIndex}.data.pictures.${newSpecificPackshotIndex}.overriden`,
        true
      );
    }

    const oldSpecificPackshotIndex = specificPictures.findIndex(
      (elem) => elem.isPackshot
    );

    if (oldSpecificPackshotIndex >= 0) {
      newState = set(
        newState,
        `specificData.${specificRecipientIndex}.data.pictures.${oldSpecificPackshotIndex}.isPackshot`,
        false
      );
    }

    return newState;
  },

  [UPDATE_NEW_PACKSHOT]: (state, { payload }) => {
    const { pictureId } = payload;
    const { pictures } = state.assets;
    const newPackshotIndex = pictures.findIndex(
      (elem) => elem.id === pictureId
    );
    if (newPackshotIndex === -1) {
      return state; // picture id not found
    }

    // add packshot flag to new picture asset
    let newState = set(
      state,
      `assets.pictures.${newPackshotIndex}.isPackshot`,
      true
    );

    // remove packshot flag from previous picture asset
    const oldPackshotIndex = pictures.findIndex((elem) => elem.isPackshot);
    if (oldPackshotIndex >= 0) {
      newState = set(
        newState,
        `assets.pictures.${oldPackshotIndex}.isPackshot`,
        false
      );
    }

    return newState;
  },

  [UPDATE_SPECIFIC_ASSET]: (state, { payload }) => {
    const { recipientId, category, asset } = payload;
    const { specificData } = state;

    const recipientIndex = specificData.findIndex(
      (item) => item.targetOrganization.id === recipientId
    );

    if (recipientIndex < 0) {
      return state; // recipient not found
    }

    const assets = get(specificData, [recipientIndex, 'data', category], []);
    let assetIndex = assets.findIndex(({ id }) => id === asset.id);
    if (assetIndex < 0) {
      assetIndex = assets.length;
    }

    const updatedRecipient = set(
      specificData[recipientIndex],
      ['data', category],
      set(assets, assetIndex, asset)
    );

    return set(
      state,
      'specificData',
      set(specificData, recipientIndex, updatedRecipient)
    );
  },

  [ADD_SPECIFIC_ASSETS]: (state, { payload }) =>
    update(state, 'specificData', (specificData) => {
      const newSpecificData = specificData.filter(
        (item) => item.targetOrganization.id !== payload.recipientId
      );
      newSpecificData.push({
        targetOrganization: { id: payload.recipientId },
        data: transferAssetsToSpecific(state.assets),
      });
      return newSpecificData;
    }),

  [DELETE_SPECIFIC_ASSETS]: (state, { payload }) =>
    update(state, 'specificData', (specificData) =>
      specificData.filter(
        (item) => item.targetOrganization.id !== payload.recipientId
      )
    ),

  [DELETE_PRODUCT_VERSION]: () => initialState,

  [UPDATE_ENTITY]: (state, action) => {
    const pictureIndex = state.assets.pictures.findIndex(
      (picture) => picture.id === action.assetId
    );
    switch (action.entityKind) {
      case 'ProductPicture':
        return update(
          state,
          `assets.pictures[${pictureIndex}].${action.key}`,
          () => action.value
        );
      default:
        return state;
    }
  },
});

// selectors

const selectLocalState = (state) => state.media;

export const selectAssets = createSelector(selectLocalState, (state) =>
  get(state, 'assets')
);

export const selectPictures = createSelector(selectAssets, (assets) =>
  get(assets, 'pictures')
);

export const selectVideos = createSelector(selectAssets, (assets) =>
  get(assets, 'videos')
);

export const selectDocuments = createSelector(selectAssets, (assets) =>
  get(assets, 'documents')
);

export const selectEnrichedContents = createSelector(selectAssets, (assets) =>
  get(assets, 'enriched_contents')
);

export const selectAssetExpiredCount = createSelector(
  selectLocalState,
  (state) => get(state, 'totalExpired')
);

export const selectSpecificAssets = createSelector(selectLocalState, (state) =>
  get(state, 'specificData')
);

export const selectAmountOfPictures = createSelector(
  selectPictures,
  (pictures) => pictures.length
);
