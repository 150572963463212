import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { getAll } from 'modules/recipients/reducers';

import { KEY } from './reducers';

export const selectState = (state) => state[MODULE_REDUCER_KEY][KEY];

export const selectIsOpen = createSelector(selectState, (state) =>
  state.get('isOpen')
);

export const selectIsAssigning = createSelector(selectState, (state) =>
  state.get('isAssigning')
);

export const selectIsDone = createSelector(selectState, (state) =>
  state.get('done')
);

export const selectProductVersions = createSelector(selectState, (state) =>
  state.get('productVersions')
);

export const selectGroups = createSelector(getAll, (recipients) =>
  recipients
    .map((r) => r.get('group'))
    .filter((g) => g)
    .reduce((acc, g) => acc.set(g.get('id'), g), Map())
    .valueSeq()
);

export const selectGroupOptions = createSelector(selectGroups, (groups) =>
  groups.map((g) => ({ id: g.get('id'), label: g.get('name') })).toArray()
);

export const selectSelectedGroupIds = createSelector(selectState, (state) =>
  state.get('selectedGroupIds')
);

export const selectSelectedGroupOptions = createSelector(
  selectGroupOptions,
  selectSelectedGroupIds,
  (groupOptions, selectedGroupIds) =>
    groupOptions.filter((g) => selectedGroupIds.includes(g.id))
);

export const selectRemainingGroupOptions = createSelector(
  selectGroupOptions,
  selectSelectedGroupIds,
  (groupOptions, selectedGroupIds) =>
    groupOptions.filter((g) => !selectedGroupIds.includes(g.id))
);
