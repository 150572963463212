import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';

import './index.scss';

export class BaseCommentModal extends PureComponent {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    submitText: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    actions: PropTypes.shape({
      submit: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    opened: false,
    saving: false,
    submitText: i18n.t(
      'frontproductstream.physical_check_generic_modal.submit.button',
      { defaultValue: 'Submit' }
    ),
    title: i18n.t(
      'frontproductstream.physical_check_generic_modal.header.text',
      { defaultValue: 'Please leave a comment' }
    ),
    description: i18n.t(
      'frontproductstream.physical_check_generic_modal.message_box.description',
      { defaultValue: 'Reason for action' }
    ),
    placeholder: i18n.t(
      'frontproductstream.physical_check_generic_modal.message_box.placeholder',
      { defaultValue: 'e.g. The product does not fit the requirements.' }
    ),
  };

  state = {
    message: '',
  };

  onMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  onSubmit = () => {
    this.props.actions.submit(this.state.message.trim());
  };

  onCancel = () => {
    this.props.actions.reset();
  };

  submitIsDisabled = () =>
    this.state.message ? isEmpty(this.state.message.trim()) : false;

  render() {
    const { opened, saving, title, description, placeholder, submitText } =
      this.props;
    if (!opened) {
      return null;
    }
    return (
      <Modal
        modalStyle="dynamic"
        title={title}
        className="BaseCommentModal"
        confirmDisabled={this.submitIsDisabled()}
        confirmButtonText={submitText}
        isProcessing={saving}
        onConfirm={this.onSubmit}
        onClose={this.onCancel}
      >
        <div className="BaseCommentModal__body">
          <div className="BaseCommentModal__label">{description}</div>
          <div className="BaseCommentModal__textarea">
            <textarea
              id="base-comment-modal-textarea"
              placeholder={placeholder}
              value={this.state.message}
              onChange={this.onMessageChange}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
