import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { pageview } from 'utils/tracking';

export function PageView() {
  const { pathname } = useLocation();

  useEffect(() => {
    pageview(global.location.href, pathname);
  }, [pathname]);

  return null;
}
