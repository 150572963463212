// React
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Select } from '@alkem/react-ui-select';

import CustomAutocomplete from 'components/ui/autocomplete/custom';
import { getId } from 'components/ui/form/field/utils/clean';
// Outer modules
import InputText from 'components/ui/input/text';

// Inner module
import { BRACKET_COLUMNS_DISPLAYED, unitTypes } from '../../../constants';
import { displayPriceValue, getValue, getValueAtIndex } from '../../../utils';

import './allowance.scss';

export default class AllowanceLevel extends PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    entityId: PropTypes.number,
    entityType: PropTypes.string,
    value: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    onDeleteLevel: PropTypes.func,
    levelIndex: PropTypes.number.isRequired,
    brackets: PropTypes.array.isRequired,
    bracketStartIndex: PropTypes.number.isRequired,
    bracketsNumDisplayed: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    targetOrganizationId: PropTypes.number,
  };

  static defaultProps = {
    bracketsNumDisplayed: BRACKET_COLUMNS_DISPLAYED,
  };

  onDeleteItem = (index) => () => {
    const { model, value, onUpdate, levelIndex, onDeleteLevel } = this.props;
    const newItems = [...value.items].filter((e, i) => i !== index);
    if (!newItems.length) {
      return onDeleteLevel(levelIndex);
    }
    return onUpdate(`${model}.items`, newItems);
  };

  onUpdateAutocomplete = (model) => (v) =>
    this.props.onUpdate(model, v.value || v);

  onClearAutocomplete = (model) => () => this.props.onUpdate(model, null);

  onUpdateText = (model) => (e) =>
    this.props.onUpdate(model, e.target.value || null);

  onUpdateValue = (model) => (e) => this.props.onUpdate(model, getValue(e));

  allowanceFilterer = (item) => !item.data?.isEarlyPaymentDiscount;

  renderType = (item, index) => {
    const { model, entityId, entityType, disabled, targetOrganizationId } =
      this.props;
    const localModel = `${model}.items.${index}.type`;
    return (
      <div className="AllowanceLevel__type">
        <CustomAutocomplete
          id={getId(localModel, entityType, entityId)}
          value={item.type ? [item.type] : []}
          className="InputField__input"
          onSelect={this.onUpdateAutocomplete(localModel)}
          onUnselect={this.onClearAutocomplete(localModel)}
          path="/buyingunit/v2/referentials"
          placeholder=""
          disabled={disabled || item.locked}
          params={{
            referential: 'priceWatterFallLevelItemTypes',
            organization_id: targetOrganizationId,
          }}
          searchOnClick
          disableOnClickOutside
          delegateSelection
          autoSelect
          dataFilterer={this.allowanceFilterer}
        />
      </div>
    );
  };

  renderUnit = (item, index) => {
    const { model, entityId, entityType, disabled } = this.props;
    const localModel = `${model}.items.${index}.unit`;
    return (
      <div className="AllowanceLevel__unit">
        <Select
          id={getId(localModel, entityType, entityId)}
          onValueAdd={this.onUpdateAutocomplete(localModel)}
          onValueDelete={this.onClearAutocomplete(localModel)}
          values={item.unit ? [item.unit] : []}
          options={unitTypes}
          placeholder=""
          disabled={disabled || item.locked}
        />
      </div>
    );
  };

  renderDescription = (item, index) => {
    const { model, entityId, entityType, disabled } = this.props;
    const localModel = `${model}.items.${index}.description`;
    return (
      <div className="AllowanceLevel__description">
        <InputText
          id={getId(localModel, entityType, entityId)}
          value={item.description}
          onChange={this.onUpdateText(localModel)}
          disabled={disabled || item.locked}
          maxLength={70}
        />
      </div>
    );
  };

  renderMetadata() {
    const { value, levelIndex } = this.props;
    return (
      <div className="AllowanceLevel__metadata">
        <div className="AllowanceLevel__rankWrapper">
          <span className="AllowanceLevel__rank">{levelIndex}</span>
        </div>
        <div className="AllowanceLevel__items">
          {value.items.map((item, itemIndex) => (
            <div key={item.uuid} className="AllowanceLevel__item">
              {this.renderType(item, itemIndex)}
              {this.renderUnit(item, itemIndex)}
              {this.renderDescription(item, itemIndex)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderValues() {
    const {
      model,
      entityId,
      entityType,
      value,
      brackets,
      bracketStartIndex,
      bracketsNumDisplayed,
      disabled,
      onDeleteLevel,
    } = this.props;
    return value.items.map((item, itemIndex) => (
      <div
        key={item.uuid}
        className="AllowanceLevel__itemValues"
        id={getId(`${model}.values`, entityType, entityId)}
      >
        {item.type && this.renderSign(item)}
        {brackets
          .slice(bracketStartIndex, bracketStartIndex + bracketsNumDisplayed)
          .map((bracket, bracketIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={bracketIndex} className="AllowanceLevel__itemValue">
              {this.renderValue(
                item,
                itemIndex,
                bracketIndex + bracketStartIndex
              )}
            </div>
          ))}
        {!disabled && !!onDeleteLevel && this.renderDeleteIcon(itemIndex)}
      </div>
    ));
  }

  renderValue(item, itemIndex, bracketIndex) {
    const { model, entityId, entityType, disabled } = this.props;
    const localValue = getValueAtIndex(item.values, bracketIndex);
    const localModel = `${model}.items.${itemIndex}.values.${bracketIndex}.value`;
    return (
      <InputText
        id={getId(localModel, entityType, entityId)}
        value={displayPriceValue(localValue.value)}
        onChange={this.onUpdateValue(localModel)}
        disabled={disabled || item.locked}
        type="number"
      />
    );
  }

  renderSign(item) {
    const sign = item.type.data?.isCharge === true ? 'plus' : 'minus';
    return <i className={`mdi mdi-${sign}`} />;
  }

  renderDeleteIcon(itemIndex) {
    return (
      <i
        className="mdi mdi-delete AllowanceLevel__deleteIcon"
        onClick={this.onDeleteItem(itemIndex)}
      />
    );
  }

  render() {
    const { value } = this.props;
    if (!value || !value.items || value.items.length === 0) {
      return null;
    }
    return (
      <div className="AllowanceLevel">
        {this.renderMetadata()}
        <div className="AllowanceLevel__values">{this.renderValues()}</div>
      </div>
    );
  }
}
