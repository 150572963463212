import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import { fetchProductVersionByKeyId } from 'actions/productversion';
import { ROOM_TYPE_PRODUCTVERSION } from 'constants/room';
import { sendQuickMessage } from 'modules/chat';
import { filterDataOpsPatchesByProductKeyId } from 'modules/data-ops';
import { fetchSummary } from 'modules/publication-summary/actions';
import {
  selectProductKeyId,
  selectProductVersionId,
} from 'reducers/productVersion';
import { serviceProductApi } from 'resources';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import {
  setConfirmUnsyncModalOpened,
  setLoadingInProgress,
  setTargetRecipient,
  setUnavailableUnsyncModalOpened,
} from './actions';
import {
  CANCEL_UNSYNC,
  ERROR_PRODUCT_LINKED_TO_PUBLISHED_TARIFF,
  OPEN_CONFIRM_UNSYNC_MODAL,
  OPEN_UNAVAILABLE_UNSYNC_MODAL,
  SEND_RETAILER_MESSAGE,
  UNSYNC,
} from './constants';
import { selectTargetRecipient } from './selectors';

export default function* dashboardPublicationSummaryMainSaga() {
  yield takeLatest(OPEN_CONFIRM_UNSYNC_MODAL, openConfirmUnsyncModal);
  yield takeLatest(OPEN_UNAVAILABLE_UNSYNC_MODAL, openUnavailableUnsyncModal);
  yield takeLatest(CANCEL_UNSYNC, cancelUnsync);
  yield takeLatest(UNSYNC, unsync);
  yield takeLatest(SEND_RETAILER_MESSAGE, sendRetailerMessage);
}

function* openConfirmUnsyncModal({ payload }) {
  yield put(setTargetRecipient({ targetRecipient: payload.targetRecipient }));
  yield put(setConfirmUnsyncModalOpened({ opened: true }));
}

function* openUnavailableUnsyncModal({ payload }) {
  yield put(setTargetRecipient({ targetRecipient: payload.targetRecipient }));
  yield put(setUnavailableUnsyncModalOpened({ opened: true }));
}

function* cancelUnsync() {
  yield put(setConfirmUnsyncModalOpened({ opened: false }));
  yield put(setTargetRecipient({ targetRecipient: null }));
}

function* unsync() {
  try {
    yield put(setLoadingInProgress({ loading: true }));

    const targetRecipient = yield select(selectTargetRecipient);
    const productKeyId = yield select(selectProductKeyId);

    yield call(
      serviceProductApi.unsynchronizeVersion.bind(serviceProductApi),
      [productKeyId],
      [targetRecipient.get('id')]
    );
    yield put(fetchSummary());
    yield put(filterDataOpsPatchesByProductKeyId({ productKeyId }));

    yield putResolve(fetchProductVersionByKeyId(productKeyId));

    yield put(setLoadingInProgress({ loading: false }));
    yield put(setConfirmUnsyncModalOpened({ opened: false }));
    yield put(setTargetRecipient({ targetRecipient: null }));

    yield put(
      notificationSuccess(i18n.t('Product unsynchronized successfully.'))
    );
  } catch (err) {
    yield put(setLoadingInProgress({ loading: false }));
    if (err.data?.error === ERROR_PRODUCT_LINKED_TO_PUBLISHED_TARIFF) {
      yield put(setConfirmUnsyncModalOpened({ opened: false }));
      yield put(
        notificationError(
          i18n.t(
            'This product belongs to a logistical hierarchy linked to a published tariff for this recipient'
          )
        )
      );
    } else {
      logError(err);
      yield put(
        notificationError(
          i18n.t('An error occured while unsynchronizing your product.'),
          { context: 'modal' }
        )
      );
    }
  }
}

function* sendRetailerMessage({ payload }) {
  const { message } = payload;
  const targetRecipient = yield select(selectTargetRecipient);
  const productVersionId = yield select(selectProductVersionId);

  try {
    yield put(setLoadingInProgress({ loading: true }));

    yield put(
      sendQuickMessage(
        message,
        targetRecipient.get('id'),
        productVersionId,
        ROOM_TYPE_PRODUCTVERSION
      )
    );

    // Success
    yield put(notificationSuccess(i18n.t('Message sent to retailer.')));
    yield put(setUnavailableUnsyncModalOpened({ opened: false }));
    yield put(setTargetRecipient({ targetRecipient: null }));
    yield put(setLoadingInProgress({ loading: false }));
  } catch (err) {
    // Error
    yield put(setLoadingInProgress({ loading: false }));
    logError(err);
    yield put(
      notificationError(
        i18n.t('An error occured while sending a message to the retailer.'),
        { context: 'modal' }
      )
    );
  }
}
