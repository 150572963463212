import classNames from 'classnames';
import { Map } from 'immutable';
import { update } from 'lodash/fp';
import { connect } from 'react-redux';

import { Select } from '@alkem/react-ui-select';

import { setAnchorNavigationSection } from 'actions/navigation';
import Field from 'components/ui/form/field';
import RaguelStatic from 'components/ui/form/plugins/validator/static';
import Tipster from 'components/ui/tipster';
import { selectHasEditableHierarchies } from 'modules/feature-flag/selectors';
import CreateHierarchyModal from 'modules/logistical-hierarchies/components/create-modal';
import { displayWarningOnSave } from 'modules/sharing-units/actions';
import { selectSharingUnitIsAgreed } from 'modules/sharing-units/selectors';
import { isCustomFieldReadOnly } from 'reducers/user/selectors';
import i18n from 'utils/i18n';

import * as selectors from './selectors';

const createNewHierarchyOptions = [
  {
    label: i18n.t(
      'frontproductstream.product_page.sharing_unit_create_new_logistical_hierarchy.label',
      { defaultValue: 'Create a new logistical hierarchy' }
    ),
  },
];

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setAnchorNavigationSection: (...args) =>
    dispatch(setAnchorNavigationSection(...args)),
  displayWarningOnSave: () => dispatch(displayWarningOnSave()),
});

const mapStateToProps = (state, { entityId }) => ({
  selectableHierarchyUnits: selectors.getFormattedList(state),
  sharingUnitIsAgreed: selectSharingUnitIsAgreed(state)(entityId),
  hasEditableHierarchies: selectHasEditableHierarchies(state),
  areLogisticalUnitsReadOnly: isCustomFieldReadOnly('logisticalunit')(state),
});

interface LogisticalHierarchySelectorProps {
  selectableHierarchyUnits: {
    label: string;
  }[];
  sharingUnitIsAgreed: boolean;
  hasEditableHierarchies?: boolean;
  setAnchorNavigationSection: (section: string) => void;
  displayWarningOnSave: () => void;
  areLogisticalUnitsReadOnly: boolean;
}

interface LogisticalHierarchySelectorState {
  isCreateModalOpen: boolean;
}

export class LogisticalHierarchySelector extends Field<
  LogisticalHierarchySelectorProps,
  LogisticalHierarchySelectorState
> {
  constructor(props) {
    super(props, { isCreateModalOpen: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.selectableHierarchyUnits !==
        this.props.selectableHierarchyUnits ||
      nextProps.sharingUnitIsAgreed !== this.props.sharingUnitIsAgreed ||
      super.shouldComponentUpdate(nextProps, nextState)
    );
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  openCreateModal = () => {
    this.setState({ isCreateModalOpen: true });
  };

  closeCreateModal = () => {
    this.props.setAnchorNavigationSection('Logistical Hierarchies');
    this.setState({ isCreateModalOpen: false });
  };

  updateLogisticalHierarchy(hierarchy) {
    const { sharingUnitIsAgreed } = this.props;
    this.dispatchChange(hierarchy);
    if (sharingUnitIsAgreed) {
      this.props.displayWarningOnSave();
    }
  }

  setLogisticalHierarchy = (selectedHierarchy) => {
    if (selectedHierarchy && !selectedHierarchy.value) {
      return this.openCreateModal();
    }
    const referenceType = selectedHierarchy.referenceType;
    let option = Map({
      label: selectedHierarchy.label,
      id: selectedHierarchy.id,
    });
    option = option.set(referenceType, selectedHierarchy.value);
    return this.updateLogisticalHierarchy(selectedHierarchy ? option : null);
  };

  removeLogisticalHierarchy = () => this.updateLogisticalHierarchy(null);

  findValueMatchingProductReference(
    productReference,
    selectableHierarchyUnits
  ) {
    return selectableHierarchyUnits.find(({ value }) =>
      value.match(new RegExp(`^0*${productReference}$`))
    );
  }

  isDisabled = () => {
    const { sharingUnitIsAgreed, hasEditableHierarchies } = this.props;
    return (
      this.isReadOnly() || (!hasEditableHierarchies && sharingUnitIsAgreed)
    );
  };

  renderInvalidHierarchyWarning(_disabled, offset) {
    const { value, selectableHierarchyUnits } = this.props;
    if (!value || (!value.gtin && !value.productIdentifier)) {
      return null;
    }
    const found = this.findValueMatchingProductReference(
      value.gtin || value.productIdentifier,
      selectableHierarchyUnits
    );
    if (found) {
      return null;
    }
    return (
      <RaguelStatic
        className={classNames({ 'offset-xs-4': offset })}
        message={i18n.t(
          'frontproductstream.product_page.sharing_unit_logistical_hierarchy_invalid_or_not_present.warning',
          {
            defaultValue:
              'This logistical hierarchy is either invalid or not present anymore.',
          }
        )}
        warning
      />
    );
  }

  render() {
    const {
      value,
      field,
      selectableHierarchyUnits,
      areLogisticalUnitsReadOnly,
    } = this.props;
    if (!field) {
      return null;
    }

    let options = selectableHierarchyUnits;
    if (!options.length && !areLogisticalUnitsReadOnly) {
      options = createNewHierarchyOptions;
    }
    const disabled = this.isDisabled();

    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      InputField__input: true,
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };
    const warningMessage = this.renderInvalidHierarchyWarning(
      disabled,
      !!renderedLabel
    );
    const mainClasses = update(
      ['FormField--raguelWarning'],
      (e) => !!e || !!warningMessage,
      this.getClasses({ 'HierarchyProduct row': true })
    );

    return (
      <>
        {!disabled && (
          <Tipster
            info={i18n.t(
              'frontproductstream.product_page.sharing_unit_logistical_hierarchy_consumer_pack_display_unit.info',
              {
                defaultValue:
                  'If your objective is to share a consumer pack or a display unit that contains this product, please go on the product page of the consumer pack or the display. You can select here logistical hierarchies that only contain this product.',
              }
            )}
            type="information"
          />
        )}
        <div className={classNames(mainClasses)}>
          {renderedLabel}
          <div className={classNames(classes)}>
            <Select
              id={this.getId()}
              onValueAdd={this.setLogisticalHierarchy}
              onValueDelete={this.removeLogisticalHierarchy}
              values={value ? [value] : []}
              placeholder={field.placeholder || field.label}
              disabled={disabled}
              options={options}
            />
            {this.renderPlugins()}
          </div>
          {warningMessage}
        </div>
        {this.state.isCreateModalOpen && (
          <CreateHierarchyModal onClose={this.closeCreateModal} />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogisticalHierarchySelector);
