import Immutable, { List } from 'immutable';
import { flatten } from 'lodash';
import memoize from 'memoize-one';
import { createSelector } from 'reselect';

import { SHARING_UNIT } from 'constants/fields';
import { hasTariff } from 'core/api/organization-settings';
import {
  createSelectorAreFiltersCollapsed,
  createSelectorFiltersConfig,
  createSelectorSelectedFilterList,
  createSelectorSelectedFilterMap,
} from 'core/modules/list/selectors';
import {
  selectHasMultiTargetMarketFeature,
  selectHasTariffFilterSearch,
  selectHasTemplateTypeRelease,
} from 'modules/feature-flag/selectors';
import {
  getAll as selectAllRecipients,
  getB2BRecipients as selectListingRecipients,
} from 'modules/recipients/reducers';
import {
  baseFieldName,
  selectFields,
  traverseDisplaygGroups,
} from 'modules/sharing-units/utils/core';
import {
  selectIsRetailer,
  selectLocales,
  selectLocalesByTargetMarket,
  selectOrganization,
} from 'reducers/user/selectors';
import { RecipientImmutable, RecipientsImmutable } from 'types';
import { flow, get } from 'utils/fp';
import { MODULE_REDUCER_KEY } from 'utils/modules/reducer';

import { MODULE_NAME, Statuses } from './constants';

const selectModuleState = (state) => state[MODULE_REDUCER_KEY][MODULE_NAME];

export const filterRecipientsWithTariffs = (
  recipients: RecipientsImmutable = List()
) =>
  recipients.filter((r) =>
    hasTariff(r?.get('settings'))
  ) as RecipientsImmutable;

export const filterRecipientsEligibleForTemplate = (
  recipients: RecipientsImmutable = List()
) =>
  recipients
    .filter((r) => Boolean(r?.get('listing')))
    .filter((r) =>
      Boolean(
        r
          ?.get('usesFields')
          .some(
            (f) =>
              f?.get('entityType') === SHARING_UNIT && !f?.get('overridable')
          )
      )
    );

export const selectSourceOrganizations = createSelector(
  selectModuleState,
  (state) => state.get('sourceOrganizations')
);

export const selectSourceOrganizationIds = createSelector(
  selectSourceOrganizations,
  (sourceOrganizations) => sourceOrganizations.map((o) => o.id)
);

export const selectAvailableRecipients = createSelector(
  selectIsRetailer,
  selectAllRecipients,
  selectListingRecipients,
  selectSourceOrganizationIds,
  selectHasTemplateTypeRelease,
  (
    isRetailer,
    allRecipients,
    listingRecipients,
    sourceOrganizationIds,
    hasTemplateTypeRelease
  ): List<RecipientImmutable> => {
    if (isRetailer) {
      return allRecipients.filter(
        (r) => r && sourceOrganizationIds.includes(r.get('id'))
      ) as RecipientsImmutable;
    }

    let res: RecipientsImmutable =
      filterRecipientsWithTariffs(listingRecipients);
    if (res && listingRecipients && hasTemplateTypeRelease) {
      const recipientsIds = res.map((r) => r?.get('id'));
      res = res.concat(
        filterRecipientsEligibleForTemplate(
          listingRecipients.filter(
            (r) => !recipientsIds.includes(r?.get('id'))
          ) as RecipientsImmutable
        )
      ) as RecipientsImmutable;
    }
    return res || List();
  }
);

export const selectSelectedRecipientId = createSelector(
  selectModuleState,
  (state) => state.get('recipientId')
);

export const selectSelectedRecipient = createSelector(
  selectSelectedRecipientId,
  selectAvailableRecipients,
  selectOrganization,
  (recipientId, recipients, organization) => {
    // Target organization case.
    if (organization.get('id') === recipientId) {
      return organization;
    }
    // Source organization case.
    return recipients.find((r) => r?.get('id') === recipientId);
  }
);

export const selectIsTarget = createSelector(
  selectSelectedRecipientId,
  selectOrganization,
  (recipientId, organization) => organization.get('id') === recipientId
);

export const selectSharingUnitTariffData = createSelector(
  selectModuleState,
  (state) => state.get('data')
);

export const selectIsTemplatePublishing = createSelector(
  selectModuleState,
  (state) => state.get('isTemplatePublishing')
);

export const selectHasFailedPublication = createSelector(
  selectModuleState,
  (state) => state.get('hasFailedPublication')
);

export const selectCustomData = createSelector(selectModuleState, (state) =>
  state.get('customData')
);

export const selectSharingUnitTariffUpdatedAt = createSelector(
  selectModuleState,
  (state) => state.get('updatedAt')
);

export const selectIsDirty = createSelector(selectModuleState, (state) =>
  state.get('isDirty')
);

export const selectIsSaving = createSelector(selectModuleState, (state) =>
  state.get('isSaving')
);

export const selectIsPublishing = createSelector(selectModuleState, (state) =>
  state.get('isPublishing')
);

export const selectIsPublicationModalOpen = createSelector(
  selectModuleState,
  (state) => state.get('publishModalOpen')
);

export const selectIsDeleting = createSelector(selectModuleState, (state) =>
  state.get('isDeleting')
);

export const selectIsDuplicationModalOpen = createSelector(
  selectModuleState,
  (state) => state.get('duplicateModalOpen')
);

export const selectIsDuplicating = createSelector(selectModuleState, (state) =>
  state.get('isDuplicating')
);

export const selectIsValidating = createSelector(selectModuleState, (state) =>
  state.get('isValidating')
);

export const selectValidationResult = createSelector(
  selectModuleState,
  (state) => state.get('validationResult')
);

export const selectFailedBlockingHeader = createSelector(
  selectModuleState,
  (state) => state.get('failedBlockingHeader')
);

export const selectIsAddingProduct = createSelector(
  selectModuleState,
  (state) => state.get('isAddingProduct')
);

export const selectId = createSelector(selectModuleState, (state) =>
  state.get('id')
);

export const selectUuid = createSelector(selectModuleState, (state) =>
  state.get('uuid')
);

export const selectName = createSelector(selectModuleState, (state) =>
  state.get('name')
);

export const selectDisplayOrganizationName = createSelector(
  selectModuleState,
  selectIsTarget,
  selectSelectedRecipient,
  (state, isTarget, selectedRecipient) =>
    isTarget
      ? state.getIn(['sourceOrganization', 'nameLegal'])
      : (selectedRecipient || Immutable.Map()).get('nameLegal')
);

export const selectStatus = createSelector(selectModuleState, (state) =>
  state.get('status')
);

export const selectType = createSelector(selectModuleState, (state) =>
  state.get('type')
);

export const selectIsTariff = createSelector(selectType, (typ) => typ === 0);

export const selectIsEditable = createSelector(
  selectStatus,
  selectIsTarget,
  (status, isTarget) =>
    !isTarget &&
    (!Statuses[status] ||
      Statuses[status].id === Statuses.DRAFT.id ||
      Statuses[status].id === Statuses.REJECTED.id)
);

export const selectLanguages = createSelector(
  selectHasMultiTargetMarketFeature,
  selectLocalesByTargetMarket,
  selectLocales,
  (hasMultiTargetMarket, localesByTargetMarket, supportedLocales) =>
    memoize((targetMarketId) =>
      hasMultiTargetMarket && targetMarketId
        ? localesByTargetMarket.get(String(targetMarketId)).toJS()
        : supportedLocales.toJS()
    )
);

export const selectRecipientLanguage = createSelector(
  selectSelectedRecipient,
  (recipient) =>
    recipient ? recipient.getIn(['supportsLocales', 0]).toJS() : {}
);

// Products
export const selectProducts = createSelector(selectModuleState, (state) =>
  state.getIn(['products', 'products'])
);

export const selectProductsAreLoading = createSelector(
  selectModuleState,
  (state) => state.getIn(['products', 'isLoading'])
);

export const selectProductsPagination = createSelector(
  selectModuleState,
  (state) => state.getIn(['products', 'pagination'])
);

export const selectTotalProducts = createSelector(
  selectProductsPagination,
  (pagination) => (pagination ? pagination.totalResults : 0)
);

export const selectProductsSearch = createSelector(selectModuleState, (state) =>
  state.getIn(['products', 'search'])
);

export const selectErrorInProductsSearch = createSelector(
  selectModuleState,
  (state) => state.getIn(['products', 'errorInProductSearch'])
);

export const selectProductsFilterInError = createSelector(
  selectModuleState,
  (state) => state.getIn(['products', 'filterInError'])
);

export const selectProductsInErrorCount = createSelector(
  selectModuleState,
  (state) => state.getIn(['products', 'totalInError'])
);

export const selectIsPollingProducts = createSelector(
  selectModuleState,
  (state) => state.getIn(['products', 'isPolling'])
);

export const selectHierarchies = createSelector(selectModuleState, (state) =>
  state.getIn(['products', 'hierarchies'])
);

/**
 * List
 */

export const selectTemplates = createSelector(selectModuleState, (state) =>
  state.get('list')
);

export const selectIsFetching = createSelector(selectModuleState, (state) =>
  state.get('isFetching')
);

export const selectPagination = createSelector(selectModuleState, (state) =>
  state.get('pagination')
);

export const selectFiltersConfig =
  createSelectorFiltersConfig(selectModuleState);

export const selectAreFiltersCollapsed =
  createSelectorAreFiltersCollapsed(selectModuleState);

export const selectSelectedFilterList =
  createSelectorSelectedFilterList(selectModuleState);

export const selectSelectedFilterMap =
  createSelectorSelectedFilterMap(selectModuleState);

export const selectFilterPages = createSelector(selectModuleState, (state) =>
  state.get('listFilterPages')
);

export const selectAggregations = flow(
  selectModuleState,
  get(['aggregations'])
);

export const selectRecipients = (state) =>
  selectIsRetailer(state) && selectHasTariffFilterSearch(state)
    ? Immutable.List()
    : selectAvailableRecipients(state);

export const selectFilterSearch = flow(
  selectModuleState,
  get(['filterSearch'])
);

export const selectSearchQuery = flow(selectModuleState, get(['search']));

const flattenFields = (e) =>
  e.kind === 'DisplayGroup' ? flatten(e.items.map(flattenFields)) : [e];

const keepFields = (fieldNames) => (item, path) =>
  item.kind !== 'Field' ||
  fieldNames.includes(baseFieldName(path) || item.model)
    ? item
    : null;

export const selectCommonFields = createSelector(
  selectSelectedRecipient,
  selectIsTariff,
  (recipient, isTariff) =>
    selectFields(recipient, isTariff, (f) => !f?.get('overridable'))
);

const selectDisplayGroupsInternal = createSelector(
  selectModuleState,
  (state) => state.get('displayGroups') || []
);

export const selectDisplayGroups = createSelector(
  selectDisplayGroupsInternal,
  selectCommonFields,
  (displayGroups, commonFields) => {
    return traverseDisplaygGroups(displayGroups, keepFields(commonFields));
  }
);

export const selectPerProductFields = createSelector(
  selectDisplayGroupsInternal,
  selectSelectedRecipient,
  selectIsTariff,
  (displayGroups, recipient, isTariff) => {
    const fieldNames = selectFields(recipient, isTariff, (f) =>
      f.get('overridable')
    );
    return flatten(
      traverseDisplaygGroups(displayGroups, keepFields(fieldNames)).map(
        flattenFields
      )
    );
  }
);

export const selectIsExporting = createSelector(selectModuleState, (state) =>
  state.get('isExporting')
);

export const selectIsBulkExportProgress = createSelector(
  selectModuleState,
  (state) => state.get('bulkExportProgress')
);

export const selectExportFormat = createSelector(selectModuleState, (state) =>
  state.get('exportFormat')
);

export const selectSelectedTariffIds = createSelector(
  selectModuleState,
  (state) => state.get('selectedTariffIds')
);

export const selectSelectedTariffUuids = createSelector(
  selectModuleState,
  (state) => state.get('selectedTariffUuids')
);

// Reject
export const selectRejectionReason = createSelector(
  selectModuleState,
  (state) => state.get('rejectionReason')
);

export const selectIsRejectModalOpen = createSelector(
  selectModuleState,
  (state) => state.get('rejectModalOpen')
);

export const selectIsRejecting = createSelector(selectModuleState, (state) =>
  state.get('isRejecting')
);

// Accept
export const selectIsAccepting = createSelector(selectModuleState, (state) =>
  state.get('isAccepting')
);

// Publication report
export const selectPublicationReport = createSelector(
  selectModuleState,
  (state) => state.get('publicationReport')
);

export const selectPublicationReportFetching = createSelector(
  selectPublicationReport,
  (state) => state.get('fetching')
);

export const selectPublicationReportResults = createSelector(
  selectPublicationReport,
  (state) => state.get('results')
);

export const selectPublicationReportTotalResults = createSelector(
  selectPublicationReport,
  (state) => state.get('totalResults')
);
