import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Ellitips } from '@alkem/react-ui-ellitips';

import ProductReference from 'components/ui/product-reference';
import { getDefaultDisplayName, getProductGTIN } from 'core/api/productversion';
import ProductPackshot from 'core/components/product/packshot';
import { selectLocalesByTargetMarket } from 'reducers/user/selectors';
import i18n from 'utils/i18n';

import { selectProducts } from '../../selectors';

import './product-previews.scss';

export const mapStateToProps = (state) => ({
  products: selectProducts(state),
  locales: selectLocalesByTargetMarket(state),
});

export const ProductPreviews = ({ products, locales }) => (
  <div className="ProductPreviews">
    <div className="ProductPreviews_content_wrapper">
      {products
        .valueSeq()
        .slice(0, 6)
        .map((product) => (
          <div
            className="ProductPreviews_content"
            key={`ProductPreviews-${product.get('id')}`}
          >
            <ProductPackshot assets={product.get('assets')} />
            <Ellitips
              label={getDefaultDisplayName(product, locales)}
              id={`name-${product.get('id')}`}
            />
            <ProductReference reference={getProductGTIN(product)} />
            <span className="ProductPreviews__fakeLink">
              {i18n.t('See the form')}
            </span>
          </div>
        ))}
    </div>
    <div className="ProductPreviews_footer">
      {products.size > 6 &&
        `+ ${products.size - 6} ${i18n.t('product(s) not shown')}`}
    </div>
  </div>
);

ProductPreviews.propTypes = {
  products: ImmutablePropTypes.map.isRequired,
  locales: PropTypes.object,
};

export default connect(mapStateToProps)(ProductPreviews);
