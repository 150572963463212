import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { OPEN_FORM_GROUP } from 'modules/display-groups/constants';
import { selectHasProductPageAccordionEnabled } from 'modules/feature-flag/selectors';
import { applyRulesForViewAsRecipients } from 'modules/validation';

import { closeFormGroup } from '../actions';
import { selectCollapsedFormGroups } from '../selectors';

export default function* watchOpenDisplayGroup() {
  yield takeEvery(OPEN_FORM_GROUP, onOpenCollapsed);
}

function* onOpenCollapsed({ payload }) {
  yield put(applyRulesForViewAsRecipients());
  yield call(closeOpenDisplayGroup, payload.formGroupId);
}

function* closeOpenDisplayGroup(formGroupId) {
  yield delay(1); // FIXME what was this for exactly ?
  const hasAccordionEnabled = yield select(
    selectHasProductPageAccordionEnabled
  );

  if (hasAccordionEnabled) {
    // do something
    const formGroupEl = document.querySelector(`#form-group-${formGroupId}`);

    // determine element position
    const oldElemPos = formGroupEl.getBoundingClientRect().y;

    // close other dg
    const collapsed = yield select(selectCollapsedFormGroups);
    const fgIds = Object.entries(collapsed).filter((entry) => !entry[1]);
    const fgId = fgIds.find(([id]) => String(id) !== String(formGroupId));
    if (fgId) {
      yield put(closeFormGroup(Number(fgId[0])));

      // scroll to new body pos
      const newElemPos = formGroupEl.getBoundingClientRect().y;
      window.scrollBy(0, newElemPos - oldElemPos);
    }
  }
}
