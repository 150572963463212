import { List, Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { get } from 'utils/immutable';
import { sortAsc } from 'utils/sort';

import { CATALOG } from '../../../constants/user-labels';
import {
  DELETE_USER_LABEL,
  RECEIVE_ALL_USER_LABELS,
  RESET_USER_LABEL_MODAL,
  SAVE_USER_LABELS,
  SAVE_USER_LABELS_ERROR,
  SELECT_USER_LABEL,
  SET_USER_LABELS_CONTEXT,
  SHOW_USER_LABEL_MODAL,
  UPDATE_USER_LABEL,
  USER_LABELS_CREATED,
} from '../actions/types';
import { UserLabel } from '../types';

export const initialState = fromJS({
  visible: false,
  productVersionIds: [],
  productIds: [],
  inProgress: false,
  selected: {},
  done: false,
  labels: null,
  sortedLabels: null,
  userLabelsContext: CATALOG,
});

export default createReducer(initialState, {
  [SHOW_USER_LABEL_MODAL]: (state, { payload }) => {
    const { productVersionIds, selectedLabelIds, productKeyIds } = payload;
    return state.withMutations((newState) => {
      newState
        .set('visible', true)
        .set('productVersionIds', List(productVersionIds))
        .set('productKeyIds', List(productKeyIds))
        .set(
          'selected',
          selectedLabelIds.reduce(
            (acc, label) =>
              acc.set(
                label.id,
                Map({
                  partial: label.partial || false,
                  value: true,
                  updated: false,
                })
              ),
            Map()
          )
        );
    });
  },
  [RECEIVE_ALL_USER_LABELS]: (state, { payload }) => {
    if (List.isList(payload)) {
      return state.withMutations((newState) => {
        newState
          .set(
            'labels',
            payload.reduce(
              (acc, label) => acc.set(label.get('id'), label),
              Map()
            )
          )
          .set(
            'sortedLabels',
            payload
              .sort((a, b) => {
                const selected = state.get('selected');
                const nameA = a.get('name');
                const prefixA = selected.getIn([a.get('id'), 'value']) ? 0 : 1;
                const nameB = b.get('name');
                const prefixB = selected.getIn([b.get('id'), 'value']) ? 0 : 1;
                return sortAsc(`${prefixA}${nameA}`, `${prefixB}${nameB}`);
              })
              .map((lbl) => lbl.get('id'))
          );
      });
    }
    return state;
  },
  [SELECT_USER_LABEL]: (state, { payload }) => {
    const { userLabel, selected } = payload;
    return state.updateIn(['selected'], (selectedMap) =>
      selectedMap
        .updateIn([userLabel.get('id')], (status) => status || Map())
        .setIn([userLabel.get('id'), 'partial'], false)
        .setIn([userLabel.get('id'), 'value'], selected)
        .setIn([userLabel.get('id'), 'updated'], true)
    );
  },
  [USER_LABELS_CREATED]: (
    state,
    { payload: { labels = List(), selected = true } = {} }
  ) =>
    state.withMutations((newState: typeof initialState) => {
      const newIds: number[] = [];
      labels.forEach((lbl: Map<string, any>) => {
        newState.update('labels', (labelsMap: Map<number, any>) =>
          labelsMap.set(lbl.get('id'), lbl)
        );
        newState.update('selected', (selectedMap: Map<number, any>) =>
          selectedMap.set(
            lbl.get('id'),
            Map({ value: selected, updated: selected })
          )
        );
        const sortedLabels = newState.get('sortedLabels');
        if (sortedLabels && !sortedLabels.includes(lbl.get('id'))) {
          newIds.push(lbl.get('id'));
        }
      });
      if (newIds.length) {
        newState.update('sortedLabels', (sortedLabels: List<number>) =>
          List(newIds).concat(List(sortedLabels))
        );
      }
    }),
  [SAVE_USER_LABELS]: (state) => state.set('inProgress', true),
  [SAVE_USER_LABELS_ERROR]: (state) => state.set('inProgress', false),
  [RESET_USER_LABEL_MODAL]: (state) =>
    initialState
      .set('labels', state.get('labels'))
      .set('sortedLabels', state.get('sortedLabels'))
      .set('userLabelsContext', state.get('userLabelsContext')),
  [SET_USER_LABELS_CONTEXT]: (state, { payload }) =>
    state.set('userLabelsContext', payload),
  [UPDATE_USER_LABEL]: (state, { payload }) => {
    const label = payload as UserLabel;
    const id = get(label, ['id']);
    const entity = 'labels';
    if (state.hasIn([entity, id])) {
      return state.setIn([entity, id], label);
    }
    return state;
  },
  [DELETE_USER_LABEL]: (state, { payload }) =>
    state.withMutations((newState: typeof state) => {
      const id: number | string = get(payload, ['id']);
      return newState
        .deleteIn(['labels', id])
        .deleteIn(['selected', id])
        .update('sortedLabels', (sortedIds: List<number>) =>
          sortedIds.filter((sortedId) => sortedId !== id)
        );
    }),
});
