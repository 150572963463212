import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import Dropdown from 'components/ui/dropdown';
import i18n from 'utils/i18n';

import './node.scss';

const POSITION_VERTICAL = 1;
const POSITION_HORIZONTAL = 2;

class DropdownTreeNode extends Component {
  static propTypes = {
    onSelectionChange: PropTypes.func.isRequired,
    entities: PropTypes.array.isRequired,
    labelKey: PropTypes.string.isRequired,
    childPosition: PropTypes.number,
    leafLabels: PropTypes.array,
    compulsory: PropTypes.bool,
  };

  static defaultProps = {
    childPosition: POSITION_VERTICAL,
    entities: [],
    leafLabels: [],
    compulsory: false,
  };

  constructor(props) {
    super(props);

    const { entities } = this.props;
    this.state = { entities: cloneDeep(entities) };

    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onSubSelectionChange = this.onSubSelectionChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { entities } = this.props;
    if (prevProps.entities !== entities) {
      this.setState({ entities }); // eslint-disable-line
    }
  }

  onSelectionChange(selectedEntityId) {
    this.setState((prevState) => ({
      entities: prevState.entities.map((entity) => {
        const updatedEntity = entity;
        updatedEntity.selected = entity.id === selectedEntityId;
        return updatedEntity;
      }),
    }));
    this.props.onSelectionChange(selectedEntityId);
  }

  // When a subNode selection changed
  onSubSelectionChange(selectedSubEntityId) {
    let selectedEntityId = selectedSubEntityId;
    if (!selectedEntityId) {
      selectedEntityId = this.findSelectedEntity().id;
    }
    this.props.onSelectionChange(selectedEntityId);
  }

  findSelectedEntity() {
    const { entities } = this.state;
    return entities.find((entity) => entity.selected);
  }

  renderLeaf(selectedEntity) {
    let { leafLabels } = this.props;
    if (selectedEntity && selectedEntity.children.length > 0) {
      return null;
    }
    leafLabels = leafLabels.map((label) => (
      <li key={label} className="DropdownTree__LeafLabel">
        <span>{label}</span>
      </li>
    ));
    return <ul className="DropdownTree__Leaf">{leafLabels}</ul>;
  }

  renderSubNode(entity) {
    let subNode = null;
    if (entity && entity.children.length > 0) {
      subNode = (
        <DropdownTreeNode
          entities={entity.children}
          onSelectionChange={this.onSubSelectionChange}
          leafLabels={this.props.leafLabels}
          labelKey={this.props.labelKey}
          compulsory={this.props.compulsory}
        />
      );
    }
    return subNode;
  }

  render() {
    const { entities } = this.state;
    const { labelKey, childPosition, compulsory } = this.props;

    const childPositionClassName = classNames({
      Dropdown__Node: true,
      'DropdownTree__Node--vertical': childPosition === POSITION_VERTICAL,
      'DropdownTree__Node--horizontal': childPosition === POSITION_HORIZONTAL,
    });

    const defaultChoice = {
      id: null,
      label: compulsory
        ? i18n.t(
            'frontproductstream.dropdown_tree.default_choice_compulsory.label',
            { defaultValue: 'Choose' }
          )
        : i18n.t(
            'frontproductstream.dropdown_tree.default_choice_optional.label',
            { defaultValue: 'Choose (optionnal)' }
          ),
      onClick: this.onSelectionChange.bind(this, null), // eslint-disable-line
    };

    const choices = [defaultChoice];

    entities.forEach((entity) => {
      choices.push({
        id: entity.id,
        label: entity[labelKey],
        onClick: this.onSelectionChange.bind(this, entity.id), // eslint-disable-line
      });
    });

    const selectedEntity = this.findSelectedEntity();
    const defaultLabel = compulsory
      ? i18n.t('frontproductstream.dropdown_tree.compulsory.label', {
          defaultValue: 'Choose',
        })
      : i18n.t('frontproductstream.dropdown_tree.optional.label', {
          defaultValue: 'Choose (optionnal)',
        });
    const dropdownLabel = selectedEntity
      ? selectedEntity[labelKey]
      : defaultLabel;

    return (
      <div>
        <div className={childPositionClassName}>
          <Dropdown label={dropdownLabel} options={choices} />
          {selectedEntity ? this.renderSubNode(selectedEntity) : null}
        </div>
        {this.renderLeaf(selectedEntity)}
      </div>
    );
  }
}

export default DropdownTreeNode;
