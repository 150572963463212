// OrganizationSettings keys
export const SETTING_ALLOW_PUSH = 'allow_push';
export const SETTING_LISTING = 'listing';
export const SETTING_LISTING_COMPULSORY = 'listing.compulsory';
export const SETTING_ASSIGNATION = 'assignation';
export const SETTING_INFORMATION_REQUEST = 'information-request';
export const SETTING_PRODUCT_COMMENT = 'chat.productversion.enabled';
export const SETTING_ORGANIZATION_CHAT = 'chat.organization.enabled';
export const SETTING_VIEW_SOURCING = 'viewsourcing';
export const SETTING_ELIGIBLE_FOR_SOURCING = 'eligibleforsourcing';
export const SETTING_ACCEPTED_SOURCING = 'acceptedsourcing';
export const SETTING_SOURCING_DATA = 'sourcingdata';
export const SETTING_AUTO_ACCEPT_SHARE_CREATE = 'auto_accept_share_create';
export const SETTING_AUTO_ACCEPT_SHARE_UPDATE = 'auto_accept_share_update';
export const SETTING_PRICE_DISPLAYUNITS = 'price.displayunits';
export const SETTING_PRICE_HLU = 'price.heterogeneous-logistical-unit';
export const SETTING_AUTO_PUBLISH = 'autopublish';
export const SETTING_EXPORT_SYNC_STATUS = 'export_synchronization_status';
export const SETTING_SOURCINGLABEL_SHARE = 'sourcinglabel.share';
export const SETTING_RFP = 'rfpV2';
export const SETTING_BIG_MANUFACTURER = 'bigmanufacturer';
export const SETTING_UI_MAX_IDLE_TIME = 'ui_max_idle_time';
export const SETTING_GROUP_PERMISSIONS = 'grouppermissions';
export const SETTING_MARKET_PERMISSIONS = 'marketpermissions';
export const SETTING_ASSORTMENT_MANAGEMENT = 'assortment_management';
export const SETTING_EXCLUSIVE_PRODUCTS = 'exclusive_products';
export const SETTING_SKU_GTIN_ZPCK = 'custom_SKU_GTIN_ZPCK';
export const SETTING_READONLY_FIELDS = 'readonly_fields';
export const TARIFF = 'tariff';
export const SETTING_LEGACY_TEXTILE = 'legacy_textile';
export const SUPPLIER_DIRECTORY = 'supplier-directory';
export const SINGLE_EXPORTABLE_GTIN = 'single_exportable_gtin';
// OrganizationSettings values
export const SETTING_ENABLE_VALUE = 'enable';
export const SETTING_DISABLE_VALUE = 'disable';
export const SETTING_PRICE_DISPLAYUNITS_ALL_VALUE = 'all';
export const SETTING_PRICE_DISPLAYUNITS_DISPLAY_VALUE = 'display';
export const SETTING_PRICE_DISPLAYUNITS_CONSUMERUNITS_VALUE = 'consumerunits';
export const SETTING_PRICE_HLU_ALL_VALUE = 'all';
export const SETTING_PRICE_HLU_HETEROGENEOUS_VALUE = 'heterogeneous';
export const SETTING_PRICE_HLU_CONSUMERUNITS_VALUE = 'consumerunits';
