import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Spinner } from '@alkem/react-ui-spinner';

import CustomAutocomplete from 'components/ui/autocomplete/custom';
import i18n from 'utils/i18n';

import { selectCreatingPriceWaterfall } from '../../../selectors';

import './add.scss';

const mapStateToProps = (state, { productId }) => ({
  creating: selectCreatingPriceWaterfall(state)(productId),
});

class AddPriceWaterfall extends PureComponent {
  static propTypes = {
    onCreate: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
    creating: PropTypes.bool,
    recipientId: PropTypes.number.isRequired,
  };

  state = {
    priceWaterfallToDuplicate: null,
  };

  onClick = () => {
    this.props.onCreate(this.props.productId);
  };

  onDuplicate = async () => {
    const { onCreate, productId } = this.props;
    const { priceWaterfallToDuplicate } = this.state;
    onCreate(productId, priceWaterfallToDuplicate);
  };

  onChange = (priceWaterfallToDuplicate) => {
    this.setState({ priceWaterfallToDuplicate });
  };

  renderCreating() {
    return (
      <div className="AddPriceWaterfall__creating">
        {i18n.t('Creating price waterfall...')}
        <Spinner small />
      </div>
    );
  }

  renderDuplication = () => {
    const { priceWaterfallToDuplicate } = this.state;
    const tooltipMessage = i18n.t(
      'Select the right price waterfall you already created for a listing of another product'
    );
    return (
      <div className="AddPriceWaterfall__copy">
        <div className="AddPriceWaterfall__copy__title">
          {i18n.t('Duplicate an existing price waterfall')}
          <HelpTooltip
            id="AddPriceWaterfall__copy__tooltip"
            message={tooltipMessage}
          />
        </div>
        <div className="AddPriceWaterfall__copy__selection">
          <span className="AddPriceWaterfall__copy__selection__autocomplete">
            <CustomAutocomplete
              className="InputField__input"
              value={
                priceWaterfallToDuplicate ? [priceWaterfallToDuplicate] : []
              }
              onSelect={this.onChange}
              onUnselect={this.onChange}
              path="/buyingunit/v3/pricewaterfalllabels/search"
              params={{
                recipient_id: this.props.recipientId,
                include_deleted: false,
              }}
              searchParam="q"
              placeholder={i18n.t('Price waterfall to duplicate')}
              sort="alphabetical"
              searchOnClick
            />
          </span>
          <span className="AddPriceWaterfall__copy__selection__button">
            <Button
              secondary
              content={i18n.t('Duplicate')}
              onClick={this.onDuplicate}
              disabled={!priceWaterfallToDuplicate}
            />
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { creating } = this.props;

    return (
      <div className="AddPriceWaterfall__container">
        {creating ? (
          this.renderCreating()
        ) : (
          <>
            <div
              className="AddPriceWaterfall__createButton"
              onClick={this.onClick}
            >
              <i className="mdi mdi-plus-circle" />
              <span>{i18n.t('Add a price waterfall')}</span>
            </div>
            <div>{i18n.t('Or')}</div>
            {this.renderDuplication()}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(AddPriceWaterfall);
