import { List, Map } from 'immutable';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RaguelStatic from 'components/ui/form/plugins/validator/static';
import { ENTITY_TYPE_RECIPIENT } from 'constants/entities';
import { selectSharingunitsMap } from 'modules/product-page/modules/retailer-sharing-units/selectors';
import {
  RuleApplicationStatus,
  applyRulesForCurrentProduct,
  selectValidationResultsByEntity,
} from 'modules/validation';
import { selectContentOwnerId } from 'reducers/productVersion';
import { GlobalState } from 'types/redux';
import { separateActions } from 'utils/redux';

// Common component.

interface Actions {
  validate: () => void;
}

type Props = {
  recipientId: number;
  sharingUnits: any;
  resultsByEntity: any;
  readOnly?: boolean;
  isRecipient?: boolean;
} & { actions: Actions };

export const RecipientValidation = ({
  recipientId,
  resultsByEntity,
  sharingUnits,
  actions,
  readOnly,
  isRecipient,
}: Props) => {
  // Filter rules specific to recipient.
  const [rules, setRules] = useState<any>(List());
  useEffect(() => {
    let localRules = resultsByEntity
      .filter(
        (e) =>
          e.getIn(['entity', 'id']) === recipientId &&
          e.getIn(['entity', '_type']) === ENTITY_TYPE_RECIPIENT
      )
      .map((e) => e.get('rules') || Map())
      .map((e) => e.valueSeq())
      .flatten(1);

    if (isRecipient) {
      localRules = localRules.concat(
        resultsByEntity
          .map((e) => e.get('rules') || Map())
          .valueSeq()
          .flatten(1)
          .filter((r) =>
            r
              .get('paths')
              .some((ps) => ps.some((p) => p.startsWith('__tariffs')))
          )
      );

      localRules = localRules.concat(
        resultsByEntity
          .map((e) => e.get('rules') || Map())
          .valueSeq()
          .flatten(1)
          .filter((r) =>
            r
              .get('paths')
              .some((ps) => ps.some((p) => p.startsWith('__sharingUnits')))
          )
      );
    }

    if (readOnly) {
      localRules = localRules.map((r) => r.set('bypassable', false));
    }

    setRules(localRules);
  }, [recipientId, resultsByEntity, readOnly, isRecipient]);

  // Revalidate when data changes.
  const prevSharingUnits = useRef<any>(sharingUnits);
  useEffect(() => {
    if (rules.size && sharingUnits !== prevSharingUnits.current) {
      actions.validate();
    }
  }, [sharingUnits, actions.validate, prevSharingUnits, rules.size, actions]);

  const failedRules = rules.filter(
    (r) => r.get('status') === RuleApplicationStatus.KO
  );

  if (failedRules.size === 0) {
    return null;
  }

  return (
    <div className="RecipientValidation">
      {failedRules
        .map((rule) => (
          <RaguelStatic key={rule.get('id')} rule={rule} withBackground />
        ))
        .toArray()}
    </div>
  );
};

const mapDispatch = {
  validate: applyRulesForCurrentProduct,
};

// Manufacturer component.

interface ManufacturerConnectedProps {
  resultsByEntity: Props['resultsByEntity'];
}

const mapManufacturerState = createStructuredSelector<
  GlobalState,
  ManufacturerConnectedProps
>({
  resultsByEntity: selectValidationResultsByEntity,
});

export const ManufacturerRecipientValidation = connect<
  ManufacturerConnectedProps,
  Actions,
  {
    recipientId: Props['recipientId'];
    sharingUnits: Props['sharingUnits'];
  },
  Props,
  GlobalState
>(
  mapManufacturerState,
  mapDispatch,
  separateActions
)(RecipientValidation);

// Retailer component.

interface RetailerConnectedProps {
  recipientId: Props['recipientId'];
  sharingUnits: Props['sharingUnits'];
  resultsByEntity: Props['resultsByEntity'];
}

const mapRetailerState = createStructuredSelector<
  GlobalState,
  RetailerConnectedProps
>({
  recipientId: selectContentOwnerId,
  sharingUnits: selectSharingunitsMap,
  resultsByEntity: selectValidationResultsByEntity,
});

export const RetailerRecipientValidation = connect(
  mapRetailerState,
  mapDispatch,
  separateActions
)((props: Props) => <RecipientValidation {...props} readOnly isRecipient />);
