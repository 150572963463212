import { DELETE_PRODUCT_VERSION } from 'constants/events/productversion';
import { createFpReducer } from 'utils/redux';

import {
  DATA_OPS_ACCEPT_PATCHES,
  DATA_OPS_ACCEPT_PATCHES_DONE,
  DATA_OPS_BULK_STORE_PATCH,
  DATA_OPS_CANCEL_PATCH,
  DATA_OPS_CANCEL_PATCH_DONE,
  DATA_OPS_EDIT_FIELD,
  DATA_OPS_FILTER_PATCHES_RECIPIENTS,
  DATA_OPS_INIT,
  DATA_OPS_PATCHES_RECEIVED,
  DATA_OPS_REFUSE_PATCHES,
  DATA_OPS_REFUSE_PATCHES_DONE,
  DATA_OPS_REMOVE_PATCHES,
  DATA_OPS_SAVE_PATCHES_DONE,
  DATA_OPS_STORE_PATCH,
} from '../constants';
import { DataOpsState } from '../types';

import { dataOpsAmendingStatusReducer } from './amend';
import { dataOpsCancelPatch, dataOpsCancelPatchDone } from './cancel-patch';
import { dataOpsEditFieldReducer } from './edit-field';
import { dataOpsFilterPatchesOnRecipientReducer } from './filter-recipient';
import { dataOpsInitReducer } from './init';
import { dataOpsInitialState } from './initial-state';
import { dataOpsPatchesReceivedReducer } from './patches-received';
import { dataOpsRemovePatchesReducer } from './remove';
import { dataOpsResetReducer } from './reset';
import { dataOpsSavePatchesDone } from './save';
import {
  dataOpsStorePatchReducer,
  dataOpsStorePatchesReducer,
} from './store-patch';

export const dataOpsReducers = createFpReducer<DataOpsState>(
  dataOpsInitialState,
  {
    [DATA_OPS_INIT]: dataOpsInitReducer,
    [DATA_OPS_PATCHES_RECEIVED]: dataOpsPatchesReceivedReducer,
    [DATA_OPS_EDIT_FIELD]: dataOpsEditFieldReducer,
    [DATA_OPS_STORE_PATCH]: dataOpsStorePatchReducer,
    [DATA_OPS_BULK_STORE_PATCH]: dataOpsStorePatchesReducer,
    [DATA_OPS_SAVE_PATCHES_DONE]: dataOpsSavePatchesDone,
    [DATA_OPS_CANCEL_PATCH]: dataOpsCancelPatch,
    [DATA_OPS_CANCEL_PATCH_DONE]: dataOpsCancelPatchDone,
    [DELETE_PRODUCT_VERSION]: dataOpsResetReducer,
    [DATA_OPS_ACCEPT_PATCHES]: dataOpsAmendingStatusReducer(
      'isAccepting',
      true
    ),
    [DATA_OPS_ACCEPT_PATCHES_DONE]: dataOpsAmendingStatusReducer(
      'isAccepting',
      false
    ),
    [DATA_OPS_REFUSE_PATCHES]: dataOpsAmendingStatusReducer('isRefusing', true),
    [DATA_OPS_REFUSE_PATCHES_DONE]: dataOpsAmendingStatusReducer(
      'isRefusing',
      false
    ),
    [DATA_OPS_REMOVE_PATCHES]: dataOpsRemovePatchesReducer,
    [DATA_OPS_FILTER_PATCHES_RECIPIENTS]:
      dataOpsFilterPatchesOnRecipientReducer,
  }
);
