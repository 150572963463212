import { flow, get, identity, reduce, set, update } from 'lodash/fp';

import { ValidationWorkflowType } from 'modules/validation';
import { ActionPayload } from 'types/redux';
import i18n from 'utils/i18n';

import { bulkStoreDataOpsPatch, storeDataOpsPatch } from '../actions';
import { dataOpsPatchKey } from '../selectors';
import { DataOpsPatchesState, DataOpsState, PendingMessage } from '../types';

export const dataOpsStorePatchReducer = (
  {
    fieldName,
    entityId,
    patch,
    label,
    entityKind,
  }: ActionPayload<typeof storeDataOpsPatch>,
  state: DataOpsState
) => {
  const msgData: PendingMessage = {
    type: ValidationWorkflowType.PATCH,
    currentValue: patch.dataNew,
    label,
    path: fieldName,
    formattedMessage: i18n.t(
      'frontproductstream.product_collaborative_edit.pending_patch_message.text',
      {
        defaultValue: '"{{label}}" looks incorrect and was patched',
        label,
      }
    ),
  };
  const pendingMessages = get(['pendingMessages', entityId], state);
  const patchKey = dataOpsPatchKey(entityId, fieldName);
  return flow(
    set(
      ['pendingMessages', entityId],
      pendingMessages
        ? [...pendingMessages.filter((m) => m.path !== msgData.path), msgData]
        : [msgData]
    ),
    update(
      ['patches', patchKey],
      flow(
        set(
          ['data', 0],
          flow(
            'key' in patch ? identity : set(['key'], patchKey),
            'entityType' in patch ? identity : set(['entityType'], entityKind)
          )(patch)
        ),
        set(['dirty'], true)
      )
    )
  );
};

export const dataOpsStorePatchesReducer = (
  patchList: ActionPayload<typeof bulkStoreDataOpsPatch>
) =>
  update(['patches'], (patches: DataOpsPatchesState) =>
    reduce(
      (acc, { fieldName, entityId, patch, entityKind }) => {
        const patchKey = dataOpsPatchKey(entityId, fieldName);
        return update(
          [patchKey],
          flow(
            set(
              ['data', 0],
              flow(
                'key' in patch ? identity : set(['key'], patchKey),
                'entityType' in patch
                  ? identity
                  : set(['entityType'], entityKind)
              )(patch)
            ),
            set(['dirty'], true)
          ),
          acc
        );
      },
      patches,
      patchList
    )
  );
