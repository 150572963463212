import { Map, fromJS } from 'immutable';
import { curry } from 'lodash/fp';

import { set } from 'utils/immutable';
import storage from 'utils/storage';

import { ITEMS_PER_PAGE_OPTIONS } from '../../constants';

export const initPaginationState = curry((storageKey, state) =>
  set(
    state,
    'pagination',
    fromJS({
      limit: storage.getItem(storageKey, ITEMS_PER_PAGE_OPTIONS[0]),
      page: 1,
      pages: 1,
      total: undefined,
    })
  )
);

export const updateItemsPerPage = (state, { payload } = {}) =>
  state
    .setIn(['pagination', 'limit'], payload)
    .setIn(['pagination', 'keys'], Map());
