import {
  TargetProductStatusMap,
  TargetProductStatusNotAssigned,
} from 'constants/targetProductStatus';
import { isRetailer } from 'core/api/user';

import { isAvailableForProductStatus } from '../../utils/filters/product-status';

export const manufacturersFilter = {
  key: 'manufacturerInfo.id',
  aggregation: {
    value_field: 'manufacturerInfo.name.raw',
  },
  query: 'manufacturer',
  isAvailable: ({ user, selectedFilterTargetProductStatus } = {}) =>
    isRetailer(user) &&
    isAvailableForProductStatus(
      selectedFilterTargetProductStatus,
      Object.keys(TargetProductStatusMap).filter(
        (statusId) => statusId !== TargetProductStatusNotAssigned.id
      )
    ),
};
