import { string } from 'prop-types';

import i18n from 'utils/i18n';

import PreviewTemplate from '../preview';

import previewImage from './product-duplication-preview.gif';

const FeatureProductDuplicationPreview = ({ imageSrc }) => (
  <PreviewTemplate
    previewImage={imageSrc}
    paragraphs={[
      i18n.t(
        'The duplication feature allows you to start from an already existing product page and speed up your creation process!'
      ),
      i18n.t(
        'Boost your productivity, by upgrading to one of our enterprise packages!'
      ),
      i18n.t(
        'Unleash your productivity with Alkemics extended features. Contact us for further information.'
      ),
    ]}
    withStrongText
  />
);

FeatureProductDuplicationPreview.propTypes = {
  imageSrc: string,
};
FeatureProductDuplicationPreview.defaultProps = {
  imageSrc: previewImage,
};

export default FeatureProductDuplicationPreview;
