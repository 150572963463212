import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  REMOVE_FROM_ACTIVE_RANGE_DONE,
  REMOVE_FROM_ACTIVE_RANGE_IN_PROGRESS,
  REMOVE_FROM_ACTIVE_RANGE_RESET,
  REMOVE_FROM_ACTIVE_RANGE_SHOW,
} from '../actions/types';

export const KEY = 'removeFromActiveRange';

export const initialState = fromJS({
  visible: false,
  productVersions: {},
  inProgress: false,
  count: 0,
  status: 'info',
  errors: {},
  success: {},
  done: false,
});

export default createReducer(initialState, {
  [REMOVE_FROM_ACTIVE_RANGE_SHOW]: (state, { payload }) =>
    state.withMutations((newState) =>
      newState.set('visible', true).set('productVersions', payload)
    ),
  [REMOVE_FROM_ACTIVE_RANGE_RESET]: () => initialState,
  [REMOVE_FROM_ACTIVE_RANGE_IN_PROGRESS]: (state, { payload = {} }) =>
    state.withMutations((newState) => {
      newState
        .set('inProgress', true)
        .set('count', payload.count || initialState.get('count'))
        .set('status', payload.status || initialState.get('status'));
      if (payload.status === 'danger') {
        newState.setIn(['errors', payload.productVersionId], true);
      } else {
        newState.setIn(['success', payload.productVersionId], true);
      }
      return newState;
    }),
  [REMOVE_FROM_ACTIVE_RANGE_DONE]: (state) =>
    state.withMutations((newState) =>
      newState.set('inProgress', false).set('done', true)
    ),
});
