import { func } from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchKindParents } from 'actions/productversion';
import Field from 'components/ui/form/field';
import { selectKindParentIds } from 'reducers/productVersion';
import { get } from 'utils/immutable';

import FormFullList from '../full-list';

export const filter = (kindParentIds) => (v) =>
  v.allergenTypeCode &&
  v.allergenTypeCode.data &&
  (get(v, ['allergenPresenceCode', 'id']) !== 3 ||
    (v.allergenTypeCode.data.mandatory &&
      v.allergenTypeCode.data.mandatory.some((id) =>
        kindParentIds.includes(id)
      )));

const mapStateToProps = createStructuredSelector({
  kindParentIds: selectKindParentIds,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetchParentKind: () => dispatch(fetchKindParents()),
});

class AllergenTypeList extends Field {
  static propTypes = {
    // from store
    kindParentIds: ImmutablePropTypes.list,

    // actions
    fetchParentKind: func.isRequired,
  };

  componentDidMount() {
    this.props.fetchParentKind();

    super.componentDidMount();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.kindParentIds !== this.props.kindParentIds) {
      return true;
    }
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    if (!this.props.kindParentIds) {
      return null;
    }
    return (
      <FormFullList {...this.props} filter={filter(this.props.kindParentIds)} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllergenTypeList);
