import { Radio } from '@alkem/react-ui-inputs';
import { Referential } from '@alkem/sdk-dashboard';

import CustomAutocomplete from 'components/ui/autocomplete/custom';
import { getId } from 'components/ui/form/field/utils/clean';
import InputText from 'components/ui/input/text';
import i18n from 'utils/i18n';

import { BRACKET_COLUMNS_DISPLAYED } from '../../constants';

import './brackets.scss';
import BracketsCarousel from './carousel';

type Props = {
  model?: string;
  entityId?: number;
  entityType?: string;
  value: any[];
  unit?: Referential | null;
  bracketStartIndex: number;
  onBracketStartIndexChange: any;
  onUpdate?: any;
  onDeleteBracket?: any;
  disabled: boolean;
  bracketType?: { id: any };
  bracketTypeOptions?: any;
  editableFirstBracket?: boolean;
};

export const Brackets = ({
  value,
  model,
  bracketType,
  bracketTypeOptions,
  bracketStartIndex,
  entityId,
  entityType,
  unit,
  editableFirstBracket,
  disabled,
  onDeleteBracket,
  onBracketStartIndexChange,
  onUpdate,
}: Props) => {
  const onUpdateAutocomplete = (v) => onUpdate(`${model}.bracketUnit`, v.value);

  const onClearAutocomplete = () => onUpdate(`${model}.bracketUnit`, null);

  const onUpdateText = (_model) => (e) => onUpdate(_model, e.target.value);

  const onUpdateType = (event) => {
    const _model = `${model}.bracketType`;
    if (event.currentTarget.value === 'null') {
      onUpdate(_model, null);
    } else {
      onUpdate(_model, { id: event.currentTarget.value });
    }
  };

  const hasBrackets = () => value && value.length > 1;

  const renderHeaders = () => (
    <div className="Brackets__header">
      <div className="Brackets__headerTitle">{i18n.t('Bracket')}</div>
      <BracketsCarousel
        value={value}
        bracketStartIndex={bracketStartIndex}
        onBracketStartIndexChange={onBracketStartIndexChange}
        onDeleteBracket={onDeleteBracket}
        disabled={disabled}
      />
    </div>
  );

  const renderValues = () => (
    <div className="Brackets__data">
      <div className="Brackets__unit">
        {i18n.t('Minimal number of')}
        <CustomAutocomplete
          id={getId(`${model}.bracketUnit`, entityType, entityId)}
          value={unit ? [unit] : []}
          className="InputField__input"
          onSelect={onUpdateAutocomplete}
          onUnselect={onClearAutocomplete}
          path="/core/v3/referentials/pricewaterfallbracketunits"
          placeholder=""
          disabled={disabled}
          searchOnClick
          autoSelect
        />
      </div>
      <div
        className="Brackets__values"
        id={getId(`${model}-brackets`, entityType, entityId)}
      >
        {value
          .slice(
            bracketStartIndex,
            bracketStartIndex + BRACKET_COLUMNS_DISPLAYED
          )
          .map((_, index) => {
            const realIndex = index + bracketStartIndex;
            const localModel = `${model}.brackets.${realIndex}.min`;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="Brackets__value">
                <InputText
                  id={getId(localModel, entityType, entityId)}
                  value={value[realIndex].min}
                  onChange={onUpdateText(localModel)}
                  type="number"
                  disabled={
                    disabled || (!editableFirstBracket && realIndex === 0)
                  }
                />
              </div>
            );
          })}
      </div>
    </div>
  );

  const renderType = () => (
    <div className="BracketType">
      <div className="BracketType__label">
        {i18n.t('The bracket applies to')}
      </div>
      <div>
        <Radio
          id={getId(`${model}.included`, entityType, entityId)}
          value={bracketType ? bracketType.id : null}
          onChange={onUpdateType}
          options={bracketTypeOptions}
          disabled={disabled}
        />
      </div>
    </div>
  );

  const _hasBrackets = hasBrackets();
  return (
    <div className="Brackets">
      {_hasBrackets && bracketTypeOptions && renderType()}
      {_hasBrackets && renderHeaders()}
      {_hasBrackets && renderValues()}
    </div>
  );
};

export default Brackets;
