import PropTypes from 'prop-types';

import Autocomplete from 'components/ui/autocomplete';
import { sourcingLabelApiImmutable } from 'resources/sourcingLabelApi';

export default class SourcingLabelAutocomplete extends Autocomplete {
  static propTypes = {
    ...Autocomplete.propTypes,
    filters: PropTypes.object,
  };

  static defaultProps = {
    ...Autocomplete.defaultProps,
    filters: {},
  };

  getList(input) {
    const { filters } = this.props;
    filters.name = input;

    return sourcingLabelApiImmutable
      .list({
        queries: filters,
        offset: 0,
        limit: 20,
      })
      .then((response) => {
        this.updateList(
          response.data.data
            .map((sourcingLabel) => ({
              label: sourcingLabel.get('name'),
              id: sourcingLabel.get('id'),
            }))
            .toJS()
        );
      });
  }
}
