import { once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Checkbox } from '@alkem/react-ui-checkbox';

import { AddableCustomAutocomplete } from 'components/ui/autocomplete/custom';
import Anchor from 'components/ui/basic/anchor';
import InputWithLabel from 'components/ui/input/input-with-label';
import { BRAND_NO_BRAND, BRAND_PRODUCT_BRAND } from 'constants/brands';
import searchBaseApi from 'resources/searchApi';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { hash } from 'utils/routing';
import { track } from 'utils/tracking';

import SuggestionsAutocomplete from '../plugins/suggestions';

import './brand.scss';

class BrandAutocomplete extends PureComponent {
  static propTypes = {
    inputId: PropTypes.string.isRequired,
    onAdd: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    isUserManufacturer: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    hasCompanyProfile: PropTypes.bool,
    organizationId: PropTypes.number,
  };

  static defaultProps = {
    isReadOnly: false,
    isUserManufacturer: true,
    hasCompanyProfile: false,
    organizationId: null,
  };

  constructor(props) {
    super(props);
    this.checkNoBrand = this.checkNoBrand.bind(this);
    this.getMostFrequentBrands = this.getMostFrequentBrands.bind(this);
    this.onSuggestionClick = this.onSuggestionClick.bind(this);

    this.state = {
      mostFrequentBrands: [],
      suggestionAccepted: false,
    };
  }

  trackSuggestionDisplay = once(() => {
    track({
      category: 'product',
      action: 'product_creation_brand_suggestion_displayed',
    });
  });

  componentDidMount() {
    this.getMostFrequentBrands();
  }

  onSuggestionClick(value, rank) {
    const { onChange } = this.props;
    track({
      category: 'product',
      action: 'product_creation_brand_suggestion_clicked',
      value: value.value,
      rank,
    });
    onChange(value);
    this.setState({ suggestionAccepted: true });
  }

  getMostFrequentBrands() {
    const { organizationId } = this.props;

    if (!organizationId) {
      return;
    }

    const body = {
      advanced_search: {
        must: [{ query: organizationId, fields: ['owner.id'] }],
      },
      aggregations: [
        {
          key_field: 'brand.id',
          key_size: 3,
          top_hits_fields: ['brand'],
        },
      ],
      limit: 0,
    };
    const promise = searchBaseApi.post('/search/v2/productversion', body);
    promise.then((response) => {
      this.setState({
        mostFrequentBrands: response.data.aggregations['brand.id'].map(
          (e) => e.top_hits[0].brand
        ),
      });
    });
  }

  isNoBrand() {
    return (
      this.props.value &&
      this.props.value.length > 0 &&
      this.props.value[0].id === BRAND_NO_BRAND.id
    );
  }

  isProductBrand() {
    return (
      this.props.value &&
      this.props.value.length > 0 &&
      this.props.value[0].id === BRAND_PRODUCT_BRAND.id
    );
  }

  isDisabled() {
    return this.props.isReadOnly || this.isNoBrand();
  }

  checkNoBrand(value) {
    if (value === true) {
      this.props.onChange({ value: { id: BRAND_NO_BRAND.id } });
    } else {
      this.props.onChange({ value: null });
    }
  }

  shouldRenderSuggestions() {
    const { mostFrequentBrands, suggestionAccepted } = this.state;
    return !(
      this.isDisabled() ||
      suggestionAccepted ||
      !mostFrequentBrands ||
      (mostFrequentBrands && mostFrequentBrands.length === 0)
    );
  }

  renderBrandCreationMessage() {
    const { isUserAdmin, hasCompanyProfile } = this.props;

    if (!isUserAdmin) {
      return (
        <span>
          {i18n.t(
            'frontproductstream.brand_autocomplete.brand_creation_not_permitted.label',
            {
              defaultValue: 'Contact your admin to create a brand.',
            }
          )}
        </span>
      );
    } else if (!hasCompanyProfile) {
      return (
        <Anchor href={hash(routes.organizationAdmin)}>
          {i18n.t(
            'frontproductstream.brand_autocomplete.create_from_administration.link',
            {
              defaultValue: 'Create a brand in the administration page.',
            }
          )}
        </Anchor>
      );
    }
    return (
      <Anchor href={hash(routes.companyProfileEdit)}>
        {i18n.t(
          'frontproductstream.brand_autocomplete.create_from_company_profile.link',
          { defaultValue: 'Create a brand in your company profile.' }
        )}
      </Anchor>
    );
  }

  renderBrandHelpNode() {
    const { isUserManufacturer, isReadOnly } = this.props;
    if (!isUserManufacturer) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-xs-4" />
        <div className="ProductCreationProductIdentity__brandHelp col-xs-8">
          <div className="ProductCreationProductIdentity__brandHelp__NoBrand">
            <Checkbox
              type="checkbox"
              onChange={this.checkNoBrand}
              id="nobrand"
              checked={this.isNoBrand()}
              label={i18n.t(
                'frontproductstream.brand_autocomplete.no_brand.label',
                { defaultValue: 'The product does not have a brand' }
              )}
              disabled={isReadOnly}
            />
          </div>
          <div className="ProductCreationProductIdentity__brandHelp__CreateBrand">
            {i18n.t(
              'frontproductstream.brand_autocomplete.not_finding_brand.label',
              { defaultValue: 'Can’t find your brand ?' }
            )}{' '}
            {this.renderBrandCreationMessage()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { inputId, isUserAdmin, onAdd, onChange } = this.props;
    const { mostFrequentBrands } = this.state;

    const value = [...this.props.value];
    if (this.isNoBrand()) {
      value[0].label = i18n.t(
        'frontproductstream.brand_autocomplete.no_brand.label',
        { defaultValue: 'The product does not have a brand' }
      );
    } else if (this.isProductBrand()) {
      value[0].label = '';
    }
    if (this.shouldRenderSuggestions()) {
      this.trackSuggestionDisplay();
    }
    return (
      <div>
        <InputWithLabel
          childId={inputId}
          label={i18n.t('frontproductstream.brand_autocomplete.input.label', {
            defaultValue: 'Commercial brand',
          })}
          help={i18n.t('frontproductstream.brand_autocomplete.input.help', {
            defaultValue: 'Commercial brand of the product',
          })}
        >
          <AddableCustomAutocomplete
            id={inputId}
            className="InputField__input"
            value={value}
            onAdd={onAdd}
            onSelect={onChange}
            onUnselect={onChange}
            path="/core/v3/referentials/brands"
            placeholder={i18n.t(
              'frontproductstream.brand_autocomplete.input.placeholder',
              { defaultValue: 'Start typing to find your brand' }
            )}
            sort="alphabetical"
            delegateSelection
            searchOnClick
            minInput={2}
            disabled={this.isDisabled()}
            addDisabled={!onAdd || !isUserAdmin}
            addMissingOptionLabel={i18n.t(
              'frontproductstream.brand_autocomplete.add_brand.label',
              { defaultValue: 'Add brand' }
            )}
          />
        </InputWithLabel>
        {this.shouldRenderSuggestions() && (
          <SuggestionsAutocomplete
            suggestedEntities={mostFrequentBrands}
            onSuggestionClick={this.onSuggestionClick}
            toolTipMessage={i18n.t(
              'frontproductstream.brand_autocomplete.suggested_brands.tooltip',
              { defaultValue: 'Most frequent brands in your organization' }
            )}
          />
        )}
        {this.renderBrandHelpNode()}
      </div>
    );
  }
}

export default BrandAutocomplete;
