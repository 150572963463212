import { BrandAdmin } from 'modules/admin/brand';
import { OrganizationAdmin } from 'modules/admin/organization';
import {
  UserListView,
  UserOwnProfileView,
  UserProfileView,
  UserSupportView,
} from 'modules/admin/users';
import { AppStore } from 'modules/app-store';
import {
  BillingRecipientsForAdmin,
  BillingRecipientsForSubscription,
  PricingPlanCheckout,
} from 'modules/billing';
import { BillingActivationSummary } from 'modules/billing-activation';
import { Catalog, Sourcing } from 'modules/catalog';
import { Collaboration } from 'modules/chat';
import { CompanyProfileEdit } from 'modules/company-profile/admin';
import { CompanyProfile } from 'modules/company-profile/public-wrapper';
import { Imports } from 'modules/imports';
import { GenericImports } from 'modules/imports-generic';
import { ProductInsights } from 'modules/insights';
import { Nielsen } from 'modules/nielsen';
import { NotificationList } from 'modules/notification-list';
import { ProductCreation } from 'modules/product-creation';
import Product from 'modules/product-page';
import { Redirect } from 'modules/redirect';
import Reporting from 'modules/reporting';
import { RFP } from 'modules/rfp';
import {
  SharingUnitTariffsDetails,
  SharingUnitTariffsList,
} from 'modules/sharing-unit-tariffs';
import { SharingUnitTemplatesView } from 'modules/sharing-unit-templates';
import { SupplierDirectory } from 'modules/supplier-directory';
import * as routes from 'routes';

export default [
  /* admin */
  { path: routes.organizationAdmin, component: OrganizationAdmin },
  { path: routes.productBrandEdit, component: BrandAdmin },
  { path: routes.userProfile, component: UserProfileView },
  { path: routes.userOwnProfile, component: UserOwnProfileView },
  { path: routes.userSupport, component: UserSupportView },
  { path: routes.organization, component: UserListView },

  /* product */
  { path: routes.catalog, component: Catalog },
  { path: routes.productCreate, component: ProductCreation },
  { path: routes.productPageLegacy, component: Product },
  { path: routes.productPageWithUuid, component: Product, exact: true },
  { path: routes.productPage, component: Product },

  /* imports */
  { path: routes.imports, component: Imports, exact: true },

  /* imports onboarding */
  { path: routes.importsGeneric, component: GenericImports },

  /* reporting */
  { path: routes.reporting, component: Reporting },
  { path: routes.reportingOverview, component: Reporting },
  { path: `${routes.reporting}/*`, component: Reporting },

  /* sourcing */
  { path: routes.productNetwork, component: Sourcing },

  /* rfp */
  { path: routes.rfp, component: RFP },
  /* sharing unit tariffs */
  {
    path: routes.SharingUnitTariffsDetails,
    component: SharingUnitTariffsDetails,
  },
  {
    path: routes.SharingUnitTariffsList,
    component: SharingUnitTariffsList,
    exact: true,
  },
  /* sharing unit templates */
  {
    path: routes.sharingUnitTemplatesList,
    component: SharingUnitTemplatesView,
  },
  /* supplier directory */
  {
    path: routes.suppliersList,
    component: SupplierDirectory,
    exact: true,
  },

  /* messages */
  { path: routes.chat, component: Collaboration },
  { path: routes.notifications, component: NotificationList },

  /* apps */
  { path: routes.appStore, component: AppStore },
  { path: routes.nielsen, component: Nielsen },

  /* company profile */
  { path: routes.companyProfileEdit, component: CompanyProfileEdit },
  { path: routes.companyProfile, component: CompanyProfile },

  /* insights */
  { path: routes.productInsights, component: ProductInsights },

  /* billing */
  {
    path: routes.billingSummary,
    component: BillingActivationSummary,
    exact: true,
  },
  { path: routes.billingCheckout, component: PricingPlanCheckout },
  { path: routes.billingSubscription, component: PricingPlanCheckout },
  {
    path: routes.billingRecipients,
    component: BillingRecipientsForSubscription,
  },
  { path: routes.billingRecipientsAdmin, component: BillingRecipientsForAdmin },

  /* other */
  { path: routes.privateRedirect, component: Redirect },
];
