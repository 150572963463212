import Immutable from 'immutable';
import { memo, useCallback } from 'react';

import { reviewFirstShareFilter } from 'core/modules/list/constants/filters';
import { FilterOnChange, FilterOnCollapse, FilterOptions } from 'types';
import { i18n } from 'utils/i18n';

import { SelectedFilterMap } from '../generic/types';
import { useQueryFilter } from '../generic/utils';
import ListSimpleFilter from '../simple';

export const REVIEW_FIRST_SHARE = {
  id: 1,
  label: i18n.t(
    'frontproductstream.core.list_filter_review_first_share.first_share_option',
    { defaultValue: 'First Share' }
  ),
};

export const REVIEW_UPDATE = {
  id: 0,
  label: i18n.t(
    'frontproductstream.core.list_filter_review_first_share.update_option',
    { defaultValue: 'Update' }
  ),
};

export const filterList: FilterOptions = Immutable.fromJS([
  REVIEW_FIRST_SHARE,
  REVIEW_UPDATE,
]);

const { key, filterLabel } = reviewFirstShareFilter;

type Props = {
  collapsed: boolean;
  filterQueryValues: string[];
  hasRecipient?: boolean;
  onChange: FilterOnChange;
  onCollapse: FilterOnCollapse;
  selectedFilterMap?: SelectedFilterMap;
};

export const ReviewFirstShareFilter = memo(
  ({
    collapsed,
    filterQueryValues,
    onChange,
    onCollapse,
    selectedFilterMap,
  }: Props) => {
    const onSelectStatus = useCallback<FilterOnChange>(
      (...args) => {
        onChange(...args);
      },
      [onChange]
    );

    const onLocalCollapse = useCallback(
      (isCollapsed) => {
        onCollapse(key, isCollapsed);
      },
      [onCollapse]
    );

    useQueryFilter({
      options: filterList,
      filter: { key, filterLabel },
      filterQueryValues,
      selectedFilterMap,
      onChange,
    });

    return (
      <ListSimpleFilter
        id="list-filter-review-first-share"
        filterList={filterList}
        filterKey={key}
        filterLabel={filterLabel}
        selectedFilterMap={selectedFilterMap}
        collapsed={collapsed}
        onChange={onSelectStatus}
        onCollapse={onLocalCollapse}
      />
    );
  }
);
