import { update } from 'lodash/fp';

import { ActionPayload } from 'types/redux';

import { dataOpsPatchesReceived } from '../actions';
import { DataOpsPatch, DataOpsPatchesState, DataOpsState } from '../types';

import { patchKey } from './utils';

export const dataOpsPatchesReceivedReducer = (
  { patches }: ActionPayload<typeof dataOpsPatchesReceived> = {
    patches: [],
  },
  state: DataOpsState
) =>
  update(['patches'], (currentPatches: DataOpsPatchesState) => {
    const initKey = new Set();
    return patches.reduce<DataOpsPatchesState>((newPatches, patch) => {
      const key = patchKey(patch, state);
      const patchWithKey: DataOpsPatch = { ...patch, key };
      return update(
        [key, 'data'],
        (data: DataOpsPatch[] = []) => {
          if (!initKey.has(key)) {
            initKey.add(key);
            return [patchWithKey];
          }
          return data.concat([patchWithKey]);
        },
        newPatches
      );
    }, currentPatches);
  });
