import { List } from 'immutable';
import { defer } from 'lodash';
import { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';

import AnchoredBlock from 'components/ui/anchor-sticky-nav/anchored-block';
import CollapsiblePageSection from 'components/ui/collapsible/collapsible-page-section';

import { registerSection, unregisterSection } from '../../actions';
import { selectBlocks } from '../../selectors';

interface Props {
  section: string;
  icon?: string;
  children: ReactNode;
  selected?: string;
  collapsed?: boolean;
  blockNames: List<string>;
  order?: number;
  collapsible: boolean;
  header?: { icon: string; text: string };
  disabled?: boolean;
  registerSection: (...args: any[]) => void;
  unregisterSection: (...args: any[]) => void;
}

interface State {
  collapsed: boolean;
}

const mapStateToProps = (state, props) => ({
  selected: state.navigation.selectedAnchoredSection,
  blockNames: selectBlocks(state).get(props.section),
});

const mapDispatchToProps = {
  registerSection,
  unregisterSection,
};

export class Section extends PureComponent<Props, State> {
  public static defaultProps = {
    collapsible: false, // is the section collapsible
    collapsed: false, // initial collapsed state
    blockNames: List() as List<string>,
    registerSection: () => {},
    unregisterSection: () => {},
  };

  state = {
    collapsed: this.props.collapsed || false,
  };

  public componentDidMount() {
    this.props.registerSection(
      this.props.section,
      this.props.order,
      this.props.icon
    );
  }

  public componentDidUpdate(prevProps) {
    const { section, order, icon, blockNames, selected = '' } = this.props;

    if (section !== prevProps.section) {
      const prevSection = prevProps.section;
      const nextSection = section;
      // defer because in case this component get's renamed due to an unmount of
      // another component then we would register the new name here before the
      // unmount (including unregistering of that name) happens
      defer(() => {
        this.props.unregisterSection(prevSection);
        this.props.registerSection(nextSection, order, icon);
      });
    }

    if (blockNames.includes(selected) || selected === prevProps.section) {
      this.setState({ collapsed: false }); // eslint-disable-line
    }
  }

  public componentWillUnmount() {
    this.props.unregisterSection(this.props.section, this.props.order);
  }

  public toggle = () => {
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  private renderCollapsible() {
    return (
      <CollapsiblePageSection
        title={this.props.section}
        collapsed={this.state.collapsed}
        toggle={this.toggle}
        header={this.props.header}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </CollapsiblePageSection>
    );
  }

  public render() {
    const { children, collapsible, section } = this.props;
    return (
      <AnchoredBlock blockName={section}>
        {collapsible ? this.renderCollapsible() : children}
      </AnchoredBlock>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
