import { flow, get } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { ADMIN_REDUCER_KEY } from '../constants';

const selectModuleState = (state) =>
  get([MODULE_REDUCER_KEY, ADMIN_REDUCER_KEY], state) || state;

export const selectAdminCustomer = flow(selectModuleState, get(['customer']));
export const selectAdminSubscriptions = flow(
  selectModuleState,
  get(['subscriptions'])
);
export const selectAdminCustomerLoaded = flow(
  selectModuleState,
  get(['loaded'])
);
