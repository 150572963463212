import { useNavigate } from 'react-router-dom';

import EmptyState from '@alkem/react-ui-empty-state';

import Anchor from 'components/ui/basic/anchor';
import * as routes from 'routes';
import i18n from 'utils/i18n';

export default function CatalogEmptyProductTable() {
  const navigate = useNavigate();
  return (
    <EmptyState
      title={i18n.t('Your catalog is empty')}
      text={i18n.t('Once you have created products, you will find them here.')}
      action={{
        label: i18n.t('Create my first product'),
        onClick: () => navigate(routes.productCreate),
      }}
      tips={
        <div>
          <div>
            {i18n.t(
              'Already using an electronic catalog (Agena 3000, Equadis, @GP, Catalogic, etc) ?'
            )}
          </div>
          <Anchor className="btn" href={i18n.t('URL_DOCS_MANUFACTURER')}>
            {i18n.t('How to import my catalog')}
          </Anchor>
        </div>
      }
    />
  );
}
