import { hasInformationRequest } from 'core/api/organization-settings';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { UserImmutable } from 'types';
import { i18n } from 'utils/i18n';

export const requestedProductsFilter = {
  key: 'alkemics.isRequested',
  query: 'requested',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_requested_products.label',
    { defaultValue: 'Requested products' }
  ),
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    isRetailer(user) && hasInformationRequest(getOrganizationSettings(user)),
};

export const requestedProductsStatusFilter = {
  key: 'alkemics.information_request.requested_product_status',
  query: 'requested_product_status',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_requested_products_status.label',
    { defaultValue: 'Request status' }
  ),
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    isRetailer(user) && hasInformationRequest(getOrganizationSettings(user)),
};
