import { cloneDeep, curry, get, mapValues } from 'lodash';
import { createReducer } from 'redux-create-reducer';

import {
  EDIT_ORGANIZATION,
  ORGANIZATION_CGU_ACCEPTED,
  RECEIVE_ORGANIZATION,
  RECEIVE_ORGANIZATION_CGU,
  RECEIVE_ORGANIZATION_SETTING,
  RECEIVE_ORGANIZATION_SETTINGS,
  UPDATE_ORGANIZATION_SETTING,
} from 'constants/events/organization';
import { CGU_TYPE_ALKEMICS } from 'constants/permissions';
import { set } from 'utils/immutable';

const initialState = {
  source: {},
  edited: {},
  glns: [],
  cgus: {},
  isCguAccepted: false,
  acceptedCgus: {},
  isGdsnTermsAccepted: false,
};

export default createReducer(initialState, {
  [RECEIVE_ORGANIZATION]: (state, action) => ({
    ...state,
    source: action.organization,
    edited: cloneDeep(action.organization),
  }),

  [EDIT_ORGANIZATION]: (state, { data }) => ({
    ...state,
    edited: set(state.edited, data.field, data.value),
  }),

  [RECEIVE_ORGANIZATION_SETTING]: (state, action) => {
    const newState = { ...state };
    newState.edited.settings[action.key] = action.value;
    newState.source.settings[action.key] = action.value;
    return newState;
  },

  [UPDATE_ORGANIZATION_SETTING]: (state, { payload }) =>
    set(state, `edited.settings.${payload.key}`, payload.value),

  [RECEIVE_ORGANIZATION_SETTINGS]: (state, action) => {
    const newState = { ...state };
    newState.edited.settings = action.settings;
    newState.source.settings = action.settings;
    return newState;
  },

  [RECEIVE_ORGANIZATION_CGU]: (state, { cgus }) => {
    const acceptedCgus = mapValues(cgus, () => false);
    return { ...state, cgus, acceptedCgus };
  },

  [ORGANIZATION_CGU_ACCEPTED]: (state, { cguType }) => {
    const acceptedCgus = { ...state.acceptedCgus };
    acceptedCgus[cguType] = true;
    return { ...state, acceptedCgus };
  },
});

const REDUCER_KEY = 'organization';

export const getOrganizationId = (state) =>
  get(state, [REDUCER_KEY, 'edited', 'id']);

const selectCguByType = curry((type, state) =>
  get(state, [REDUCER_KEY, 'cgus', type])
);

export const selectAlkemicsCgu = selectCguByType(CGU_TYPE_ALKEMICS);

export const selectOrganizationGlns = (state) =>
  get(state, [REDUCER_KEY, 'source', 'ownsGLN']);
