import { createAction } from 'utils/redux';

import {
  ADD_EMAIL,
  CANCEL_SEND_ASSIGNATION,
  CLOSE_TARGET_REQUEST_MODAL,
  OPEN_TARGET_REQUEST_MODAL,
  REMOVE_EMAIL,
  SELECT_RECIPIENT,
  SEND_ASSIGNATION,
  START_SENDING_ASSIGNATION,
  STOP_SENDING_ASSIGNATION,
  TOGGLE_FORCE_ASSIGNATION,
  UNSELECT_RECIPIENT,
  UPDATE_MESSAGE,
} from './constants';

// ------------
// ACTION TYPES
// ------------

export const openTargetRequestModal = createAction(OPEN_TARGET_REQUEST_MODAL);
export const closeTargetRequestModal = createAction(CLOSE_TARGET_REQUEST_MODAL);

// Form

export const updateMessage = createAction(UPDATE_MESSAGE);

export const addEmail = createAction(ADD_EMAIL);
export const removeEmail = createAction(REMOVE_EMAIL);

export const selectRecipient = createAction(SELECT_RECIPIENT);
export const unselectRecipient = createAction(UNSELECT_RECIPIENT);

export const toggleForceAssignation = createAction(TOGGLE_FORCE_ASSIGNATION);

// Sagas

export const sendAssignation = createAction(SEND_ASSIGNATION);
export const cancelSendAssignation = createAction(CANCEL_SEND_ASSIGNATION);

export const startSendingAssignation = createAction(START_SENDING_ASSIGNATION);
export const stopSendingAssignation = createAction(STOP_SENDING_ASSIGNATION);
