import i18n from 'utils/i18n';

export const roomOrganizationType = 0;
export const roomProductversionType = 1;
export const roomRFPType = 3;

export const allAcked = {
  name: i18n.t('frontproductstream.constants.message_status.all_acked', {
    defaultValue: 'All acknowledged',
  }),
  id: 'ALL_ACKED',
  icon: 'mdi mdi-comment-check-outline',
};
export const iNeedToAck = {
  name: i18n.t(
    'frontproductstream.constants.message_status.need_my_acknowledge',
    {
      defaultValue: 'I need to acknowledge',
    }
  ),
  id: 'I_NEED_TO_ACK',
  icon: 'mdi mdi-comment-alert-outline',
};
export const waitingForOtherToAck = {
  name: i18n.t(
    'frontproductstream.constants.message_status.need_other_acknowledge',
    {
      defaultValue: 'Waiting for other to acknowledge',
    }
  ),
  id: 'WAITING_FOR_OTHER_TO_ACK',
  icon: 'mdi mdi-comment-processing-outline',
};

export const NoMessages = {
  name: i18n.t('frontproductstream.constants.message_status.no_messages', {
    defaultValue: 'Create a new conversation',
  }),
  id: 'NO_MESSAGES',
  icon: 'mdi mdi-comment-plus-outline',
};

export const MessageMap = {
  allAcked,
  iNeedToAck,
  waitingForOtherToAck,
};

export const MessageIdToMessageInfo = {
  [allAcked.id]: allAcked,
  [iNeedToAck.id]: iNeedToAck,
  [waitingForOtherToAck.id]: waitingForOtherToAck,
  [NoMessages.id]: NoMessages,
};
