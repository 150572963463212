import { isFunction, isUndefined } from 'lodash';
import { Action } from 'redux';

import { ActionBuilder, ActionWithPayload } from 'types/redux';
import { isDevelopment, isTest } from 'utils';

/**
 * An implementation of mergeProps that separates actions passed into
 * mapDispatchToProps into props.actions instead of merging them into component
 * props.
 */
export const separateActions = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actions: dispatchProps,
});

export function createWithPayload<P = any>(type: string, payload?: P) {
  if ((isDevelopment() || isTest()) && isUndefined(type)) {
    throw new Error(
      "Action with type 'undefined'. Have you misspelled a constant?"
    );
  }
  if (isUndefined(payload)) {
    return { type } as Action;
  }

  return { type, payload } as ActionWithPayload<P>;
}

export function createAction<P = any>(actionType: string): ActionBuilder<P> {
  return (payload?: P) => createWithPayload(actionType, payload);
}

export function createActionWithPayload<P>(actionType: string) {
  return (payload: P) =>
    createWithPayload(actionType, payload) as { type: string; payload: P };
}

export function createReducer<S>(
  initialState: S,
  reducers: {
    [key: string]: (s: S, a: any) => S;
  }
) {
  return function reducer(state = initialState, action: any): S {
    if (reducers[action.type]) {
      return reducers[action.type](state, action);
    }
    return state;
  };
}

type F<S> = (s: S) => S;

export function createFpReducer<S>(
  initialState: S,
  reducers: {
    [key: string]: (p: any, s: S) => F<S> | S;
  }
) {
  return function reducer(
    state = initialState,
    action: ActionWithPayload | { type: string; payload?: any }
  ) {
    const { type, payload, ...otherProps } = action;
    if (reducers[type]) {
      const result = reducers[type](
        isUndefined(payload) ? otherProps : payload,
        state
      );
      if (isFunction(result)) {
        return result(state);
      }
      return result;
    }
    return state;
  };
}

export const asyncConstants = (name) => ({
  ERROR: `${name}_ERROR`,
  REQUEST: `${name}_REQUEST`,
  SUCCESS: `${name}_SUCCESS`,
});
