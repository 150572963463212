import { Channel } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import { SharingUnitTariffsDetails } from 'routes';
import { push } from 'utils/history';
import { i18n } from 'utils/i18n';
import { logError } from 'utils/logging';
import { fill } from 'utils/routing';
import { takeEverySafe } from 'utils/saga';

import api from '../api';
import { DUPLICATE, DUPLICATE_FAILURE, DUPLICATE_SUCCESS } from '../constants';
import { selectId } from '../selectors';

function* duplicateSaga({
  duplicateSharingUnits,
  name,
}: {
  duplicateSharingUnits: boolean;
  name: string;
}) {
  const templateId = yield select(selectId);
  const { result, error } = yield call(
    api.duplicateTemplate,
    templateId,
    name,
    duplicateSharingUnits
  );

  if (!error) {
    yield put({ type: DUPLICATE_SUCCESS });
    yield call(push, fill(SharingUnitTariffsDetails, result.data.id));
  } else {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.duplicate_notification.error',
          {
            defaultValue:
              'An error occurred while duplicating the price template',
          }
        ),
        { context: 'modal', error }
      )
    );
    yield put({ type: DUPLICATE_FAILURE });
  }
}

export default function* saga() {
  try {
    yield takeEverySafe(
      DUPLICATE as unknown as Channel<{
        name: string;
        duplicateSharingUnits: boolean;
      }>,
      duplicateSaga
    );
  } catch (error) {
    logError(error);
  }
}
