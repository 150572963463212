import { List, Map, fromJS } from 'immutable';
import { identity } from 'lodash';
import memoizeOne from 'memoize-one';
import { createReducer } from 'redux-create-reducer';
import { createSelector } from 'reselect';

import { SHARING_UNIT } from 'constants/fields';
import {
  getOrganizationFields,
  getOrganizationWorksOnTargetMarket,
} from 'core/api/organization';
import { selectTargetMarketId } from 'reducers/productVersion';
import { RecipientsImmutable } from 'types';
import { get } from 'utils/immutable';

import { FETCH_RECIPIENTS_LIST, RECEIVE_RECIPIENTS_LIST } from './constants';

const initialState = Map({
  list: List(),
  isLoading: false,
});

export default createReducer(initialState, {
  [FETCH_RECIPIENTS_LIST]: (state) => state.set('isLoading', true),
  [RECEIVE_RECIPIENTS_LIST]: (state, data) =>
    state.set('list', fromJS(data.recipientsList)).set('isLoading', false),
});

const selectState = (state) => state.recipients;

export const getAll = createSelector(
  selectState,
  (state) => state.get('list') as RecipientsImmutable
);

export const selectRecipients = getAll;

export const selectRecipientsInAGroup = createSelector(getAll, (recipients) =>
  recipients.filter((recipient) => recipient?.get('group'))
);

export const getRecipientsMap = createSelector(getAll, (recipients) =>
  recipients.reduce(
    (recipientById, recipient) =>
      (recipient &&
        recipientById &&
        recipientById.set(recipient?.get('id'), recipient)) ||
      Map(),
    Map()
  )
);

export const hasNoRecipients = createSelector(
  getAll,
  (recipients) => recipients.size === 0
);

// filter and keep organizations that have a listing setting enabled (a.k.a B2B organizations)
export const getB2BRecipients = createSelector(
  getAll,
  (allRecipients) =>
    allRecipients.filter((recipient) =>
      Boolean(recipient?.get('listing'))
    ) as RecipientsImmutable
);

export const recipientByIdHasSetting = (recipientId, setting) =>
  createSelector(getRecipientsMap, (recipients) =>
    get(recipients, [recipientId, 'settings', setting])
  );

// add the flag to the authorized_settings list in auth.v2.organization.recipients.list_post
export const selectRecipientByIdHasSetting = createSelector(
  [getRecipientsMap],
  (recipients) =>
    memoizeOne(
      (recipientId, setting) =>
        get(recipients, [recipientId, 'settings', setting]) || false
    )
);

// filter and keep organizations that have a listing setting enabled (a.k.a B2B organizations)
// retrieve only the organizations ids
export const getB2BRecipientsIds = createSelector(
  getB2BRecipients,
  (b2bRecipients) =>
    b2bRecipients.map((b2bRecipient) => b2bRecipient?.get('id'))
);

// filter and keep organizations that do not have a listing setting enabled (a.k.a B2B organizations)
export const getNonB2BRecipients = createSelector(getAll, (allRecipients) =>
  allRecipients.filterNot((recipient) => Boolean(recipient?.get('listing')))
);

// retrieve all recipients ids
export const getRecipientsIds = createSelector(getAll, (recipients) =>
  recipients.map((recipient) => recipient?.get('id'))
);

export const selectSupportedLocalsByRecipientsId = createSelector(
  getAll,
  (recipients) =>
    recipients
      .groupBy((recipient) => recipient?.get('id'))
      .map((recipient) => recipient?.first().get('supportsLocales'))
);

export const selectSupportedLocalesForRecipientsIds = createSelector(
  selectSupportedLocalsByRecipientsId,
  (localesByRecipientId) =>
    (recipientIds: number[] | List<number> = []) =>
      List<number>(recipientIds)
        .map((recipientId) => localesByRecipientId.get(recipientId))
        .filter(identity)
        .flatten(1)
        .toSet()
);

export const filterRecipientsforTargetMarket = (recipients, targetMarketId) =>
  recipients.filter(getOrganizationWorksOnTargetMarket(targetMarketId));

export const selectB2BRecipientsForCurrentTargetMarket = createSelector(
  getB2BRecipients,
  selectTargetMarketId,
  filterRecipientsforTargetMarket
);

export const selectNonB2BRecipientsForCurrentTargetMarket = createSelector(
  getNonB2BRecipients,
  selectTargetMarketId,
  filterRecipientsforTargetMarket
);

export const selectFormattedRecipients = createSelector(
  selectRecipientsInAGroup,
  (recipients) =>
    recipients.map((recipient) =>
      fromJS({
        id: get(recipient, 'id'),
        label: get(recipient, 'nameLegal'),
      })
    )
);

export const selectIsLoading = createSelector(selectState, (state) =>
  state.get('isLoading')
);

export const selectSharingUnitFieldsByRecipient = createSelector(
  getAll,
  (recipients) =>
    recipients.reduce(
      (recipientById, r) =>
        r && recipientById
          ? recipientById.set(
              r.get('id'),
              getOrganizationFields(r).get(SHARING_UNIT)
            )
          : Map(),
      Map()
    )
);
