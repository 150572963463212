import { List, Map } from 'immutable';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Checkbox, Radio } from '@alkem/react-ui-inputs';

import MaturityAutocomplete from 'components/ui/autocomplete/maturity-rulesets';
import CollapsibleFilter from 'core/modules/list/components/filter/collapsible';
import { maturityFilter } from 'core/modules/list/constants/filters/maturity';
import { selectMaturityRulesets } from 'modules/catalog/product/selectors';
import { dedupe } from 'utils';
import i18n from 'utils/i18n';

import './index.scss';

const {
  FILTER_ALL,
  FILTER_ERROR,
  FILTER_NOT_REQUESTED,
  FILTER_REQUESTED,
  FILTER_SUCCESS,
} = maturityFilter;

const mapState = createStructuredSelector({
  maturityRulesets: selectMaturityRulesets,
});

class MaturityFilterComponent extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    filterConfig: ImmutablePropTypes.map.isRequired,
    maturityRulesets: ImmutablePropTypes.list.isRequired,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    filterQueryValues: PropTypes.array,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    filterConfig: Map(),
    maturityRulesets: List(),
  };

  requestedOptions = [
    {
      label: i18n.t('frontproductstream.core.list_filter_maturity.all_option', {
        defaultValue: 'All',
      }),
      value: FILTER_ALL,
    },
    {
      label: i18n.t(
        'frontproductstream.core.list_filter_maturity.requested_option',
        { defaultValue: 'Requested' }
      ),
      value: FILTER_REQUESTED,
    },
    {
      label: i18n.t(
        'frontproductstream.core.list_filter_maturity.not_requested_option',
        { defaultValue: 'Not requested' }
      ),
      value: FILTER_NOT_REQUESTED,
    },
  ];

  filterKey = maturityFilter.key;
  filterLabel = i18n.t('frontproductstream.core.list_filter_maturity.label', {
    defaultValue: 'Business maturity',
  });

  getValue = memoize((selectedFilterMap) => {
    const maturityId = Number(selectedFilterMap.keySeq().first());
    let maturityMap;
    if (Number.isInteger(maturityId) && this.props.maturityRulesets) {
      maturityMap = this.props.maturityRulesets.find(
        (m) => m.get('id') === maturityId
      );
    }
    if (maturityMap) {
      const maturity = this.formatMaturity(maturityMap);
      return {
        value: [maturity],
        maturity,
        filters: selectedFilterMap.first().first(),
      };
    }
    return { value: [], filters: [FILTER_ALL] };
  });

  formatMaturities = memoize((maturityRulesets) => {
    if (!maturityRulesets) {
      return [];
    }
    return maturityRulesets.map(this.formatMaturity).toJS();
  });

  componentDidMount() {
    this.initFiltersFromQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props.maturityRulesets.size && !prevProps.maturityRulesets.size) {
      this.initFiltersFromQuery();
    }
  }

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.filterKey, collapsed);
  };

  onChange = ({ maturity, filters }, fromQuery = false) => {
    this.props.onChange(
      {
        add: true,
        singleValue: true,
        data: {
          [maturity.value]: filters ||
            this.getValue(this.props.selectedFilterMap).filters || [FILTER_ALL],
        },
        key: this.filterKey,
        label: `${this.filterLabel}: ${maturity.label}`,
        value: maturity.value,
        valuePath: 'data',
      },
      fromQuery
    );
  };

  onSelectMaturity = (maturity) => {
    this.onChange({ maturity });
  };

  onUnselectMaturity = (maturity) => {
    this.props.onChange({
      add: false,
      key: this.filterKey,
      value: maturity.value,
    });
  };

  onChangeRequestedOption = (event) => {
    const { value } = event.target;
    const { maturity, filters } = this.getValue(this.props.selectedFilterMap);
    this.onChange({
      maturity,
      filters: [
        ...filters.filter(
          (filter) =>
            ![FILTER_ALL, FILTER_REQUESTED, FILTER_NOT_REQUESTED].includes(
              filter
            )
        ),
        value,
      ],
    });
  };

  onChangeSatisfyingOption(event, filter) {
    const { checked } = event.target;
    const { maturity, filters } = this.getValue(this.props.selectedFilterMap);
    this.onChange({
      maturity,
      filters: checked
        ? dedupe([...filters, filter])
        : filters.filter((f) => f !== filter),
    });
  }

  onChangeSatisfying = (event) => {
    this.onChangeSatisfyingOption(event, FILTER_SUCCESS);
  };

  onChangeNotSatisfying = (event) => {
    this.onChangeSatisfyingOption(event, FILTER_ERROR);
  };

  initFiltersFromQuery() {
    const { filterQueryValues, maturityRulesets, selectedFilterMap } =
      this.props;
    const currentValue = this.getValue(selectedFilterMap);
    if (
      !currentValue.value.length &&
      filterQueryValues &&
      filterQueryValues[0] &&
      maturityRulesets.size
    ) {
      const [maturityKey, filters] =
        Object.entries(filterQueryValues[0])[0] || [];
      const maturityId = Number(maturityKey);
      if (maturityId) {
        const result = maturityRulesets.find(
          (maturity) => maturity.get('id') === maturityId
        );
        if (result) {
          this.onChange(
            {
              maturity: this.formatMaturity(result),
              filters,
            },
            true
          );
        }
      }
    }
  }

  formatMaturity(maturityMap) {
    return {
      label: maturityMap.get('label'),
      value: maturityMap.get('id'),
      key: maturityMap.get('id'),
    };
  }

  formatAggregation(label, type, bypass) {
    const count = this.props.aggregations.getIn([type, 'doc_count']);
    if (!bypass && count >= 0) {
      return `${label} (${count})`;
    }
    return label;
  }

  render() {
    const { selectedFilterMap, filterConfig, maturityRulesets } = this.props;
    const { value, filters } = this.getValue(selectedFilterMap);
    const disabled = !(value && value[0]);
    return (
      <CollapsibleFilter
        id="maturity-filter"
        collapsed={filterConfig.get('collapsed')}
        label={this.filterLabel}
        onCollapse={this.onCollapse}
      >
        <MaturityAutocomplete
          id="maturity-filter-autocomplete"
          maturities={this.formatMaturities(maturityRulesets)}
          value={value}
          placeholder={i18n.t(
            'frontproductstream.core.list_filter_maturity.autocomplete_placeholder',
            { defaultValue: 'Select a maturity rule' }
          )}
          onSelect={this.onSelectMaturity}
          onUnselect={this.onUnselectMaturity}
        />
        <div className="MaturityFilter__requested">
          <Radio
            id="maturity-filter-requested"
            name="maturity-filter-requested"
            value={filters.find((filter) =>
              [FILTER_ALL, FILTER_REQUESTED, FILTER_NOT_REQUESTED].includes(
                filter
              )
            )}
            options={this.requestedOptions}
            vertical
            disabled={disabled}
            onChange={this.onChangeRequestedOption}
          />
        </div>
        <div className="MaturityFilter__satisfying">
          <Checkbox
            id="maturity-filter-satisfying"
            label={this.formatAggregation(
              i18n.t(
                'frontproductstream.core.list_filter_maturity.satisfying_checkbox',
                { defaultValue: 'Satisfying' }
              ),
              'success',
              disabled
            )}
            checked={filters.some((filter) => filter === FILTER_SUCCESS)}
            disabled={disabled}
            onChange={this.onChangeSatisfying}
          />
          <Checkbox
            id="maturity-filter-not-satisfying"
            label={this.formatAggregation(
              i18n.t(
                'frontproductstream.core.list_filter_maturity.not_satisfying_checkbox',
                { defaultValue: 'Not satisfying' }
              ),
              'error',
              disabled
            )}
            checked={filters.some((filter) => filter === FILTER_ERROR)}
            disabled={disabled}
            onChange={this.onChangeNotSatisfying}
          />
        </div>
      </CollapsibleFilter>
    );
  }
}

export const MaturityFilter = connect(mapState)(MaturityFilterComponent);
