// React
import { FC, useMemo } from 'react';

import Dropdown from 'components/ui/dropdown';
// Outer modules
import MultiLevelDropdown from 'components/ui/mulit-level-dropdown';
import { Level } from 'modules/price-waterfalls/types';
import i18n from 'utils/i18n';
import { update } from 'utils/immutable';

// Inner module
import {
  defaultAllowanceItem,
  defaultAllowanceLevel,
} from '../../../constants';

import './add.scss';

interface AddLineProps {
  model: string;
  levels: Level[];
  onUpdate?: (model: string, value: any) => void;
  hasEarlyPaymentDiscount?: boolean;
  onAddEarlyPaymentDiscountSelected?: () => void;
  addEarlyPaymentDiscountDisabled?: boolean;
  numBaseLevels: number;
  numBrackets: number;
  disabled?: boolean;
}

const AddLine: FC<AddLineProps> = (props) => {
  const {
    model,
    levels,
    onUpdate,
    numBaseLevels,
    numBrackets,
    hasEarlyPaymentDiscount,
    onAddEarlyPaymentDiscountSelected,
    addEarlyPaymentDiscountDisabled,
    disabled,
  } = props;

  const allowanceRankOptions = useMemo(
    () =>
      [...Array(numBaseLevels).keys()].map((index) => ({
        label: i18n.t('Rank {{rank}}', { rank: index + 1 }),
        onClick: () => {
          const newLevels = [...levels];
          // In that case we need to add a new level in the existing levels.
          if (index + 1 === numBaseLevels) {
            newLevels.splice(numBaseLevels, 0, defaultAllowanceLevel());
          }
          onUpdate?.(
            model,
            update(newLevels, `${index + 1}.items`, (items) => [
              ...items,
              defaultAllowanceItem(numBrackets),
            ])
          );
        },
      })),
    [levels, model, numBaseLevels, numBrackets, onUpdate]
  );

  return (
    <>
      <div className="AddAllowanceLevel">
        <div className="AddAllowanceLevel__row">
          {hasEarlyPaymentDiscount ? (
            <MultiLevelDropdown
              disabled={disabled}
              items={[
                {
                  label: i18n.t('Add a line'),
                  children: [
                    {
                      label: i18n.t('Add a discount or charge'),
                      children: allowanceRankOptions,
                    },
                    hasEarlyPaymentDiscount
                      ? {
                          label: i18n.t('Add an early payment discount'),
                          disabled: addEarlyPaymentDiscountDisabled,
                          onClick: onAddEarlyPaymentDiscountSelected,
                        }
                      : null,
                  ],
                },
              ]}
            />
          ) : (
            <>
              <i className="mdi mdi-plus-circle" />
              <span className="AddAllowanceLevel__row__label">
                {i18n.t('Add an allowance or charge')}
              </span>
              <Dropdown
                label={i18n.t('What is the rank?')}
                options={allowanceRankOptions}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default AddLine;
