import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import Image from 'components/ui/image';
import Modal from 'components/ui/modal';
import mediaApi from 'resources/mediaApi';
import i18n from 'utils/i18n';
import { formatMedia } from 'utils/links';

import AddOrganizationPicture from './add';
import './media-organization.scss';

export class MediaOrganizationCard extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  };

  state = {
    deleteModalState: false,
    addPictureModalState: false,
    loadingDelete: false,
    displayDelete: false,
    invalidatingCache: false,
  };

  labels = {
    profile: i18n.t(
      'frontproductstream.media_organization.card.profile_title',
      { defaultValue: 'Logo' }
    ),
    cover: i18n.t('frontproductstream.media_organization.card.cover_title', {
      defaultValue: 'Cover',
    }),
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  imageStatusCallback = (success) => {
    this.setState({ displayDelete: success });
  };

  openAddPictureModal = () => {
    this.setState({ addPictureModalState: true });
  };

  hideAddPictureModal = () => {
    this.setState({ addPictureModalState: false });
    this.invalidateMediaCache();
  };

  hideDeleteConfirmationModal = () => {
    this.setState({ deleteModalState: false, loadingDelete: false });
    this.invalidateMediaCache();
  };

  openDeleteConfirmationModal = () => {
    this.setState({ deleteModalState: true });
  };

  deletePicture = () => {
    const { organizationId, type } = this.props;
    this.setState({ loadingDelete: true });
    return mediaApi
      .OrganizationPictureDelete(organizationId, type)
      .then(this.hideDeleteConfirmationModal)
      .catch(this.hideDeleteConfirmationModal);
  };

  formatMediaUrl(organizationId, type) {
    return formatMedia(`/organization/picture/${organizationId}/${type}.png`);
  }

  invalidateMediaCache() {
    const { organizationId, type } = this.props;
    const mediaUrl = this.formatMediaUrl(organizationId, type);
    const invalidatingDone = () => {
      if (this._isMounted) {
        this.setState({ invalidatingCache: false });
      }
    };
    this.setState({ invalidatingCache: true });
    return fetch(mediaUrl, {
      method: 'GET',
      mode: 'no-cors',
      cache: 'reload',
    })
      .then(invalidatingDone)
      .catch(invalidatingDone);
  }

  render() {
    const { organizationId, type } = this.props;
    const { invalidatingCache } = this.state;
    return (
      <div className="MediaOrganization__card">
        <h3>{this.labels[type]}</h3>
        <div className="MediaOrganization__image">
          {!invalidatingCache && (
            <Image
              src={this.formatMediaUrl(organizationId, type)}
              statusHandler={this.imageStatusCallback}
            />
          )}
        </div>
        <div className="MediaOrganization__actions">
          {this.state.displayDelete && (
            <Button
              secondary
              content={i18n.t(
                'frontproductstream.media_organization.card_delete_button.label',
                { defaultValue: 'Delete' }
              )}
              onClick={this.openDeleteConfirmationModal}
            />
          )}
          <Button
            primary
            content={i18n.t(
              'frontproductstream.media_organization.card_upload_button.label',
              { defaultValue: 'Upload' }
            )}
            onClick={this.openAddPictureModal}
          />
        </div>
        {this.state.deleteModalState && (
          <Modal
            modalStyle="dynamic"
            title={i18n.t(
              'frontproductstream.media_organization.delete_modal.title',
              { defaultValue: 'Delete picture' }
            )}
            isProcessing={this.state.loadingDelete}
            onConfirm={this.deletePicture}
            onClose={this.hideDeleteConfirmationModal}
          >
            {i18n.t(
              'frontproductstream.media_organization.delete_modal.content',
              { defaultValue: 'Are you sure you want to delete this picture ?' }
            )}
          </Modal>
        )}
        {this.state.addPictureModalState && (
          <AddOrganizationPicture
            onClose={this.hideAddPictureModal}
            organizationId={organizationId}
            type={type}
          />
        )}
      </div>
    );
  }
}
export default MediaOrganizationCard;
