import classnames from 'classnames';
import { Map } from 'immutable';
import { forwardRef, memo } from 'react';

import Ellitips from '@alkem/react-ui-ellitips';
import { LazyTooltip } from '@alkem/react-ui-tooltip';
import { Referential } from '@alkem/sdk-dashboard';

import { get, getFp } from 'utils/immutable';

import './cell.scss';

export type ReferentialMap = Map<
  keyof Referential | 'firstLeftSticky',
  Referential[keyof Referential]
>;

export type Sorting = {
  id?: string;
  asc?: boolean;
};

type Props = {
  referential: Referential | ReferentialMap;
  sorting?: Sorting;
  withMultiLines?: boolean;
  onSort?: (referential: Referential | ReferentialMap, order: boolean) => void;
  innerRef?: any;
  isSticky?: boolean;
  useEllitips?: boolean;
  zeroIndex?: boolean;
  tooltipContent?: Node | string;
  ellitipsLines?: number;
  getReferentialSort?: (referential: any) => boolean;
  getReferentialWidth?: (referential: any) => number | null;
  getReferentialLabel?: (referential: any) => string;
  getReferentialHeader?: (referential: any) => boolean;
  getSortingAsc?: (sorting?: Sorting) => boolean | undefined;
};

const ListTableHeaderCell = memo((props: Props) => {
  const {
    withMultiLines = false,
    onSort,
    innerRef,
    isSticky,
    zeroIndex,
    tooltipContent,
    useEllitips,
    ellitipsLines = 2,
    getReferentialSort = getFp('data.sort'),
    getReferentialWidth = getFp('data.width'),
    getReferentialLabel = getFp('label'),
    getReferentialHeader = getFp('data.width'),
    getSortingAsc = getFp('asc'),
  } = props;
  const selected = Boolean(props.sorting);
  const sorting = selected ? getSortingAsc(props.sorting) : null;
  const { referential }: { referential: Referential | ReferentialMap } = props;
  const key = `${get(referential, 'key') || get(referential, 'id')}`;
  const label = getReferentialLabel(referential);
  const sort = getReferentialSort(referential);
  const width = getReferentialWidth(referential);
  const withHeader = getReferentialHeader(referential) !== false;

  const style: any = {};
  if (width) {
    style.width = width;
    style.minWidth = width;
  }

  const isSortable = () => Boolean(onSort && sort);

  const _onSort = () => {
    if (!isSortable()) {
      return;
    }
    onSort && onSort(referential, selected && !sorting);
  };

  return (
    <th
      data-testid="list-table-cell"
      ref={innerRef}
      className={classnames(
        'ListTableHeaderCell',
        withMultiLines && 'ListTableHeaderCell--multilines',
        get(referential, 'firstLeftSticky') && 'ListTableHeaderCell--sticky',
        isSticky && 'ListTableHeaderCell--firstRowSticky',
        isSticky && zeroIndex && 'ListTablHeaderCell--firstColumnSticky'
      )}
      style={style}
      data-selected={selected}
    >
      {withHeader && (
        <span
          data-testid="cell-with-header"
          className="ListTableHeaderCell__content"
        >
          <span
            className="ListTableHeaderCell__label"
            data-selected={selected}
            onClick={_onSort}
          >
            {tooltipContent ? (
              <LazyTooltip
                id={key}
                tooltipLabel={tooltipContent}
                place="bottom"
              >
                {label}
              </LazyTooltip>
            ) : useEllitips ? (
              <Ellitips
                id={key}
                label={label as string}
                place="top"
                lines={ellitipsLines}
              />
            ) : (
              label
            )}
          </span>
          {isSortable() && (
            <span
              data-testid="cell-sortable"
              className="ListTableHeaderCell__sortable"
              onClick={_onSort}
            >
              <i className="ListTableHeaderCell__sortIcons">
                <i
                  className="mdi mdi-chevron-up"
                  data-selected={sorting === true}
                />
                <i
                  className="mdi mdi-chevron-down"
                  data-selected={sorting === false}
                />
              </i>
            </span>
          )}
        </span>
      )}
    </th>
  );
});

export default forwardRef((props: Props, ref) => (
  <ListTableHeaderCell {...props} innerRef={ref} />
));
