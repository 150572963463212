import { hasIn, set, update } from 'lodash/fp';

import {
  ADD_PENDING_FIELD_COMMENT,
  NORMALIZED_COMMENT_CHANGES_REQUESTED,
  REMOVE_PENDING_FIELD_COMMENT,
} from 'constants/events/suggestions';
import { createFpReducer } from 'utils/redux';

import {
  NORMALIZED_COMMENTS_OPEN_MODAL,
  NORMALIZED_COMMENTS_REQUEST_CHANGES,
  NORMALIZED_COMMENTS_REQUEST_CHANGES_DONE,
  NORMALIZED_COMMENTS_STORE_DEPRECATEDS,
} from './constants';

export interface NormalizedCommentShort {
  id: string;
  fieldName: string;
}
export interface NormalizedCommentsState {
  pendingMessages: { [key: string]: any[] };
  isSending: boolean;
  isMessageModalOpen: boolean;
  deprecatedNormalizedComments: NormalizedCommentShort[];
}

export const initialState: NormalizedCommentsState = {
  // keys are PV ids, values are suggestions stacked in a pending message
  pendingMessages: {},
  isSending: false,
  isMessageModalOpen: false,
  deprecatedNormalizedComments: [],
};

export default createFpReducer<NormalizedCommentsState>(initialState, {
  [ADD_PENDING_FIELD_COMMENT]: ({ suggestion, productVersionId }, state) => {
    if (!hasIn(['pendingMessages', productVersionId], state)) {
      return set(['pendingMessages', productVersionId], [suggestion]);
    }
    return update(['pendingMessages', productVersionId], (suggestions) => [
      ...suggestions,
      suggestion,
    ]);
  },
  [REMOVE_PENDING_FIELD_COMMENT]: ({ label, productVersionId }, state) => {
    if (!hasIn(['pendingMessages', productVersionId], state)) {
      return state;
    }
    return update(['pendingMessages', productVersionId], (suggestions) =>
      suggestions.filter(
        (suggestion) => suggestion.label !== label || hasIn('value', suggestion)
      )
    );
  },
  [NORMALIZED_COMMENT_CHANGES_REQUESTED]: ({ productVersionId }, state) => {
    // Once the changes were requested, flag them so that the retailer does not
    // resend the same requests until he leaves the page
    if (!hasIn(['pendingMessages', productVersionId], state)) {
      return state;
    }
    return update(['pendingMessages', productVersionId], (suggestions) =>
      suggestions.map((suggestion) => {
        suggestion.sent = true;
        return suggestion;
      })
    );
  },
  [NORMALIZED_COMMENTS_OPEN_MODAL]: (isOpen) =>
    set('isMessageModalOpen', isOpen),
  [NORMALIZED_COMMENTS_REQUEST_CHANGES]: () => set('isSending', true),
  [NORMALIZED_COMMENTS_REQUEST_CHANGES_DONE]: () => set('isSending', false),
  [NORMALIZED_COMMENTS_STORE_DEPRECATEDS]: (normalizedComments) =>
    set('deprecatedNormalizedComments', normalizedComments),
});
