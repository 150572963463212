import { createAction } from 'utils/redux';

import {
  CANCEL_STATUS_POLLING,
  CREATE_SHARING_UNIT_REQUEST,
  DELETE_SHARING_UNIT_REQUEST,
  DISPLAY_WARNING_ON_SAVE,
  FETCH_DISPLAY_GROUPS_REQUEST,
  LOAD_SHARING_UNITS_EXPORT_FILE,
  RECEIVE_SHARING_UNITS_EXPORT_FILE,
  REQUEST_STATUS_POLLING,
  UPDATE_SHARING_UNIT_FIELD,
  UPDATE_SHARING_UNIT_STATUS,
} from './actionTypes';

// client only

export const updateSharingUnitField = createAction(UPDATE_SHARING_UNIT_FIELD);

export const displayWarningOnSave = createAction(DISPLAY_WARNING_ON_SAVE);

export const updateSharingUnitStatus = createAction(UPDATE_SHARING_UNIT_STATUS);

// server interaction

export const deleteSharingUnit = createAction(DELETE_SHARING_UNIT_REQUEST);

export const createSharingUnit = createAction(CREATE_SHARING_UNIT_REQUEST);

export const requestSharingUnitStatusPolling = (
  sharingUnitId,
  previousStatus,
  attempt = 1
) => ({
  type: REQUEST_STATUS_POLLING,
  sharingUnitId,
  previousStatus,
  attempt,
});

export const cancelSharingUnitStatusPolling = createAction(
  CANCEL_STATUS_POLLING
);

export const fetchDisplayGroups = createAction(FETCH_DISPLAY_GROUPS_REQUEST);

export const loadLastExportFile = createAction(LOAD_SHARING_UNITS_EXPORT_FILE);

export const receiveLastExportFile = createAction(
  RECEIVE_SHARING_UNITS_EXPORT_FILE
);
