import { Channel } from 'redux-saga';
import { call, delay, put, select } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError } from 'actions/notification';
import sharingUnitApi from 'modules/sharing-units/api';
import ServiceProductApi from 'resources/serviceProductApi';
import i18n from 'utils/i18n';
import { set } from 'utils/immutable';
import { logError } from 'utils/logging';
import qs from 'utils/query';
import { takeLatestSafe, withCancel } from 'utils/saga';

import {
  errorSearchingProduct,
  receiveProducts,
  updateFilterInError,
} from '../actions';
import {
  ADD_PRODUCT_SUCCESS,
  CANCEL_FETCH_PRODUCTS,
  CANCEL_PRODUCTS_POLLING,
  DELETE_SHARING_UNIT,
  FETCH_PRODUCTS,
  FETCH_TEMPLATE_SUCCESS,
  POLL_PRODUCTS,
  PRODUCTS_POLLING_FINISHED,
  RELOAD_SHARING_UNIT,
  SAVE_SUCCESS,
  TEMPLATE_LIST_PRODUCT_FILTER_TO_BIG,
  UPDATE_CURRENT_PRODUCTS_PAGE,
  UPDATE_FILTER_IN_ERROR,
  UPDATE_SEARCH,
} from '../constants';
import {
  selectId,
  selectIsTarget,
  selectProductsFilterInError,
  selectProductsPagination,
  selectProductsSearch,
} from '../selectors';

interface Response {
  data?: {
    data: any[];
    totalResults?: number;
    totalInError?: number;
  };
  error?: any;
}

async function fetchProducts(
  templateId: number,
  limit: number,
  offset: number,
  q: string,
  filterInError: boolean
): Promise<Response> {
  try {
    const { data } = await ServiceProductApi.get(
      `/product/v2/sharing-unit-templates/${templateId}/products`,
      qs.stringify({ limit, offset, q, in_error: filterInError }, true)
    );
    return { data };
  } catch (error) {
    return { error };
  }
}

async function listHierarchies(productKeyIds: number[]): Promise<any> {
  try {
    const { data } = await ServiceProductApi.post(
      `/product/v3/hierarchies/list`,
      {
        product_key_ids: productKeyIds,
      }
    );
    return { data };
  } catch (error) {
    return { error };
  }
}

function* fetchProductsSagaFromTemplateId({ id }: { id: number }) {
  yield put({ type: FETCH_PRODUCTS });
  const search = yield select(selectProductsSearch);
  const filterInError = yield select(selectProductsFilterInError);
  const { limit, offset } = yield select(selectProductsPagination);
  const isTarget = yield select(selectIsTarget);

  try {
    yield put(startLoading());
    const { data, error }: Response = yield call(
      fetchProducts,
      id,
      limit,
      offset,
      search,
      filterInError
    );
    if (error) {
      if (error.data?.error === TEMPLATE_LIST_PRODUCT_FILTER_TO_BIG) {
        yield put(errorSearchingProduct());
      } else {
        throw error;
      }
    }

    if (data) {
      const { data: products, totalResults, totalInError } = data;

      const productKeyIds = products.map((p) =>
        isTarget ? p.target_product_key.id : p.source_product_key.id
      );
      let hierarchies;
      let hierarchyError;
      if (productKeyIds.length > 0) {
        ({ data: hierarchies, error: hierarchyError } = yield call(
          listHierarchies,
          productKeyIds
        ));
        if (hierarchyError) {
          yield put(
            notificationError(
              i18n.t(
                'frontproductstream.sharingunit_tariff.retrieve_products_hierarchies_notification.error',
                {
                  defaultValue:
                    'An error occured while retrieving the logistical hierarchies of the products',
                }
              )
            )
          );
        }
        if (hierarchies && hierarchies.data) {
          hierarchies = hierarchies.data.reduce(
            (acc, h) =>
              set(
                acc,
                h.product_key_id,
                h.data.map((d) => d.data)
              ),
            {}
          );
        }
      }

      yield put(
        receiveProducts({
          products,
          totalResults,
          totalInError,
          hierarchies,
        })
      );
      if (filterInError && totalInError === 0) {
        yield put(updateFilterInError(false));
      }
    } else {
      yield put(receiveProducts({}));
    }
  } catch (error) {
    logError(error);
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.retrieve_products_notification.error',
          { defaultValue: 'An error occured while retrieving the products' }
        )
      )
    );
    yield put(receiveProducts({}));
  } finally {
    yield put(stopLoading());
  }
}

function* fetchProductsSaga() {
  const templateId = yield select(selectId);
  yield* fetchProductsSagaFromTemplateId({ id: templateId });
}

function* pollProducts() {
  for (const msDelay of [0, 5000, 10000]) {
    // FIXME never worked because delay was not an saga effect, but a call() fx
    yield delay(msDelay);
    yield* fetchProductsSaga();
  }
  yield put({ type: PRODUCTS_POLLING_FINISHED });
}

function* deleteSharingUnitSaga({ payload: id }) {
  yield put(startLoading());
  const { error } = yield call(sharingUnitApi.deleteSharingUnit, { id });
  if (error) {
    logError(error);
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.delete_products_notification.error',
          { defaultValue: 'An error occured while removing the product' }
        )
      )
    );
  } else {
    yield* fetchProductsSaga();
  }
  yield put(stopLoading());
}

export default function* productsSaga() {
  try {
    yield takeLatestSafe(
      [
        FETCH_TEMPLATE_SUCCESS,
        UPDATE_CURRENT_PRODUCTS_PAGE,
        UPDATE_SEARCH,
        UPDATE_FILTER_IN_ERROR,
      ] as unknown as Channel<{
        id: number;
      }>,
      withCancel(fetchProductsSaga, CANCEL_FETCH_PRODUCTS)
    );
    yield takeLatestSafe(
      [
        SAVE_SUCCESS,
        ADD_PRODUCT_SUCCESS,
        RELOAD_SHARING_UNIT,
      ] as unknown as Channel<{
        id: number;
      }>,
      function* poll() {
        yield put({ type: POLL_PRODUCTS });
      }
    );
    yield takeLatestSafe(
      POLL_PRODUCTS,
      withCancel(pollProducts, CANCEL_PRODUCTS_POLLING)
    );
    yield takeLatestSafe(
      DELETE_SHARING_UNIT as unknown as Channel<{ payload: any }>,
      deleteSharingUnitSaga
    );
  } catch (error) {
    logError(error);
  }
}
