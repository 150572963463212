import { List, Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  ADD_RECIPIENT,
  CANCEL_POLL_IMPORT_REPORT,
  CLOSE_MODAL,
  OPEN_MODAL,
  RECEIVE_IMPORT_REPORT,
  RECEIVE_RECIPIENTS_REQUIRED_FIELDS,
  REMOVE_RECIPIENT,
} from '../actions/types';

export const initialState = Map({
  importReport: { productImports: [], entities: [], steps: [] },
  importStatus: 'PENDING',
  isOpen: false,

  recipientsRequiredFields: Map(),
  selectedRecipients: List(),
});

export default createReducer(initialState, {
  [RECEIVE_IMPORT_REPORT]: (state, action) =>
    state
      .set('importReport', action.payload.report)
      .set('importStatus', action.payload.status),
  [CANCEL_POLL_IMPORT_REPORT]: (state) =>
    initialState.set('isOpen', state.get('isOpen')),
  [OPEN_MODAL]: (state) => state.set('isOpen', true),
  [CLOSE_MODAL]: (state) => state.set('isOpen', false),

  [RECEIVE_RECIPIENTS_REQUIRED_FIELDS]: (state, { recipientsRequiredFields }) =>
    state.update('recipientsRequiredFields', (rrf) =>
      rrf.merge(fromJS(recipientsRequiredFields))
    ),
  [ADD_RECIPIENT]: (state, { recipientId }) =>
    state.update('selectedRecipients', (selectedRecipients) =>
      selectedRecipients.push(recipientId)
    ),
  [REMOVE_RECIPIENT]: (state, { recipientId }) =>
    state.update('selectedRecipients', (selectedRecipients) =>
      selectedRecipients.filter((rId) => rId !== recipientId)
    ),
});
