import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { TARGET_REQUEST_MODAL_STORE_KEY } from './constants';

// ---------
// SELECTORS
// ---------

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][TARGET_REQUEST_MODAL_STORE_KEY];
}

export function selectModalIsOpen(state) {
  return selectState(state).get('modalIsOpen');
}

export function selectTitle(state) {
  return selectState(state).get('title');
}

export function selectChaseType(state) {
  return selectState(state).get('chaseType');
}

export function selectProducts(state) {
  return selectState(state).get('products');
}

export function selectSendingAssignation(state) {
  return selectState(state).get('sendingAssignation');
}

export function selectForm(state) {
  return selectState(state).get('form');
}

export function selectMessage(state) {
  return selectState(state).getIn(['form', 'message']);
}

export function selectEmails(state) {
  return selectState(state).getIn(['form', 'emails']);
}

export function selectStateRecipientId(state) {
  return selectState(state).getIn(['form', 'recipientId']);
}

export function selectToggleForceAssignation(state) {
  return selectState(state).getIn(['form', 'forceAssignation']);
}
