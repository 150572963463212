import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { PureComponent } from 'react';
import { list, map } from 'react-immutable-proptypes';

import ListTableRow from 'core/modules/list/components/table/row';
import { withNavigate } from 'hocs';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';

import ReportingTableCell from '../cell';

import './index.scss';

class ReportingTableRow extends PureComponent {
  static propTypes = {
    isSticky: bool,
    reportId: string.isRequired,
    report: map.isRequired,
    referentials: list.isRequired,
    selected: bool,
    isAggregation: bool,
    onSelect: func.isRequired,
    onClickCell: func.isRequired,
    isRetailer: bool.isRequired,
    navigate: func.isRequired,
  };

  static defaultProps = {
    selected: false,
    isAggregation: false,
    onSelect() {},
  };

  onSelect = (selected) => {
    const { onSelect, reportId } = this.props;
    onSelect(reportId, selected);
  };

  onClickCell = (data) => {
    const { onClickCell, report } = this.props;
    onClickCell({ ...data, report });
  };

  redirectToCompanyProfile = (manufacturerUUId) => () => {
    this.props.navigate(fill(routes.companyProfile, manufacturerUUId));
  };

  render() {
    const {
      reportId,
      report,
      referentials,
      selected,
      isAggregation,
      isRetailer,
      isSticky,
    } = this.props;
    return (
      <ListTableRow
        id={`reporting-table-row-${reportId}`}
        className={classNames(
          'ReportingTableRow',
          isAggregation && 'ReportingTableRow--aggregation'
        )}
        selected={selected}
        withBackground
      >
        {referentials.map((referential, index) => {
          if (isAggregation && index === 0) {
            return (
              <ReportingTableCell
                key="reporting-table-cell-aggregation-label"
                report={report.set(
                  referential.get('key'),
                  i18n.t(
                    'frontproductstream.reporting_page_row.total_results_heading.text',
                    { defaultValue: 'TOTAL - ASSORTMENT' }
                  )
                )}
                referential={referential}
                onClickCell={this.onClickCell}
                isSticky={isSticky}
                isAggregation
                isFirst
              />
            );
          } else if (isRetailer && index === 0) {
            // on retailer reportings, manufacturer name cell redirects to company profile
            const manufacturerUUId = report.get('uuid');
            return (
              <ReportingTableCell
                key={`reporting-table-cell-${reportId}-${referential.get(
                  'key'
                )}`}
                report={report}
                referential={referential}
                onClickCell={this.redirectToCompanyProfile(manufacturerUUId)}
                isAggregation={isAggregation}
                isClickable={!!manufacturerUUId}
                isSticky={isSticky}
              />
            );
          } else {
            return (
              <ReportingTableCell
                key={`reporting-table-cell-${reportId}-${referential.get(
                  'key'
                )}`}
                report={report}
                referential={referential}
                onClickCell={this.onClickCell}
                isAggregation={isAggregation}
                isSticky={isSticky}
              />
            );
          }
        })}
        <td />
      </ListTableRow>
    );
  }
}

export default withNavigate(ReportingTableRow);
