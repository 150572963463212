import { sessionStorage } from 'utils/storage';

export const setNextLocation = (nextLocation: string) => {
  sessionStorage.setItem('nextLocation', nextLocation);
};

export const getNextLocation = () => {
  return sessionStorage.getItem('nextLocation');
};

export const resetNextLocation = () => {
  sessionStorage.removeItem('nextLocation');
};
