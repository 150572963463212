import classNames from 'classnames';
import { connect } from 'react-redux';

import { Textarea } from '@alkem/react-ui-inputs';

import Field from 'components/ui/form/field';
import RichContent from 'components/ui/input/rich-content';

const mapStateToProps = () => ({});

export class FormTextarea extends Field {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue(data) {
    if (data.currentTarget) {
      return data.currentTarget.value;
    }
    return data;
  }

  render() {
    const { value, field } = this.props;
    if (!field) {
      return null;
    }

    const placeholder = field.placeholder || field.label;
    const id = this.getId();

    let content;
    if (field.options && field.options.richContent) {
      content = (
        <RichContent
          id={id}
          value={value}
          lightToolbars
          onChange={this.handleChange}
          disabled={this.isReadOnly()}
        />
      );
    } else {
      content = (
        <Textarea
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={this.handleChange}
          disabled={this.isReadOnly()}
          maxLength={20000}
        />
      );
    }

    const renderedLabel = this.renderLabel();
    const classes = {
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };

    return (
      <div
        className={classNames(
          this.getClasses({ 'InputField FormTextarea row': true })
        )}
      >
        {this.renderLabel('col-xs-4')}
        <div className={classNames(classes)}>
          {content}
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormTextarea);
