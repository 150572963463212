import classNames from 'classnames';

import './style.scss';

interface Props {
  iconName?: string;
}

export function Icon(props: Props) {
  if (!props.iconName) {
    return null;
  }
  const className = classNames(
    'AnchorNavItem__icon',
    'mdi',
    `mdi-${props.iconName}`
  );
  return <i className={className} />;
}
