import { Map } from 'immutable';
import { once } from 'lodash';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import i18n from 'utils/i18n';
import { size } from 'utils/immutable';

import AdvancedFilter from '../../advanced/filter';
import CollapsibleFilter from '../../collapsible';
import TabbedFilters from '../../tabbed';

import './index.scss';

export class HaveDoesntHaveFilter extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    filterConfig: ImmutablePropTypes.map.isRequired,
    filterQueryValues: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onSort: PropTypes.func,
    filterKey: PropTypes.string.isRequired,
    filterLabel: PropTypes.string.isRequired,
    filterSearchPlaceHolder: PropTypes.string.isRequired,
    filterNameKey: PropTypes.string.isRequired,
    filterNameKeyFallback: PropTypes.string,
    haveKey: PropTypes.string,
    doesntHaveKey: PropTypes.string,
    isExactFilter: PropTypes.bool,
    missingFilter: PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    filterConfig: Map(),
    haveKey: i18n.t(
      'frontproductstream.core.list_filter_have_something.have_label',
      { defaultValue: 'Have' }
    ),
    doesntHaveKey: i18n.t(
      'frontproductstream.core.list_filter_have_something.does_not_have_label',
      { defaultValue: "Doesn't have" }
    ),
    isExactFilter: false,
    missingFilter: {
      key: null,
      label: null,
    },
  };

  selectOptions = memoize((aggregations) => aggregations.valueSeq().toList());

  selectSelection = memoize((selectedFilterMap) =>
    selectedFilterMap.filter((value) => !value.get('not'))
  );

  selectSelectionNot = memoize((selectedFilterMap) =>
    selectedFilterMap.filter((value) => !!value.get('not'))
  );

  updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: this.props.filterQueryValues,
      filterList: this.selectOptions(this.props.aggregations),
      filterKey: this.props.filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter, { not }) =>
        `${not ? this.filterLabelNot : this.filterLabel}: ${filter.get(
          this.props.filterNameKey
        )}`,
      selectFilterData: (filter, { not }) => ({ not }),
    }).then((filtersFromQuery) => {
      this.props.onChange(filtersFromQuery, true);
    });
  });

  filterLabel = i18n.t(
    'frontproductstream.core.list_filter_have_something.have_label',
    { defaultValue: 'Have' }
  );

  filterLabelNot = i18n.t(
    'frontproductstream.core.list_filter_have_something.does_not_have_label',
    { defaultValue: "Doesn't have" }
  );

  constructor(props) {
    super(props);
    this.onChangeNot = this.onChange.bind(this, true);
    this.onChange = this.onChange.bind(this, false);
  }

  componentDidMount() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  componentDidUpdate() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  onChange(not, filter) {
    let filtersToUpdate = [];
    if (filter.add && this.props.selectedFilterMap.has(filter.value)) {
      filtersToUpdate = [
        {
          ...filter,
          add: false,
        },
      ];
    }
    this.props.onChange(
      filtersToUpdate.concat({
        ...filter,
        data: { not },
        not,
      })
    );
  }

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.props.filterKey, collapsed);
  };

  shouldUpdateSelectionFromQuery = () => {
    const { aggregations, selectedFilterMap } = this.props;
    return !aggregations.isEmpty() && selectedFilterMap.isEmpty();
  };

  render() {
    const { haveKey, doesntHaveKey } = this.props;

    const haveSelection = this.selectSelection(this.props.selectedFilterMap);
    const haveNotSelection = this.selectSelectionNot(
      this.props.selectedFilterMap
    );
    const haveTabTitle =
      haveKey + (size(haveSelection) ? ` (${size(haveSelection)})` : '');

    const doesntHaveTabTitle =
      doesntHaveKey +
      (size(haveNotSelection) ? ` (${size(haveNotSelection)})` : '');

    const getSelectLabel = (filter) => {
      const id = filter.get(this.props.filterNameKey);

      if (id === this.props.missingFilter.key) {
        return this.props.missingFilter.label;
      }

      return id || filter.get(this.props.filterNameKeyFallback);
    };

    return (
      <CollapsibleFilter
        id={`list-filter-${this.props.filterKey}`}
        label={this.props.filterLabel}
        collapsed={this.props.filterConfig.get('collapsed')}
        onCollapse={this.onCollapse}
      >
        <TabbedFilters>
          <AdvancedFilter
            tabTitle={haveTabTitle}
            filterKey={this.props.filterKey}
            filterLabel={this.filterLabel}
            filters={this.selectOptions(this.props.aggregations)}
            selectedFilterMap={haveSelection}
            aggregations={this.props.aggregations}
            searchQuery={this.props.filterConfig.get('query')}
            searchPlaceholder={this.props.filterSearchPlaceHolder}
            page={this.props.filterConfig.get('page')}
            onChange={this.onChange}
            onFilter={this.props.onFilter}
            onChangePage={this.props.onChangePage}
            onSort={this.props.onSort}
            selectors={{
              selectId: (filter) => filter.get('id'),
              selectLabel: getSelectLabel,
            }}
            withPagination
            isExactFilter={this.props.isExactFilter}
            hasMissingFilter
            missingFilterKey={this.props.missingFilter.key}
          />
          <AdvancedFilter
            tabTitle={doesntHaveTabTitle}
            filterKey={this.props.filterKey}
            filterLabel={this.filterLabelNot}
            selectors={{
              selectId: (filter) => filter.get('id'),
              selectLabel: getSelectLabel,
            }}
            filters={this.selectOptions(this.props.aggregations)}
            selectedFilterMap={haveNotSelection}
            searchQuery={this.props.filterConfig.get('query')}
            searchPlaceholder={this.props.filterSearchPlaceHolder}
            page={this.props.filterConfig.get('page')}
            onChange={this.onChangeNot}
            onFilter={this.props.onFilter}
            onChangePage={this.props.onChangePage}
            withPagination
            isExactFilter={this.props.isExactFilter}
            missingFilterKey={this.props.missingFilter.key}
          />
        </TabbedFilters>
      </CollapsibleFilter>
    );
  }
}
