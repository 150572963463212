import i18n from 'utils/i18n';

export const READY_FOR_CHECK = {
  name: i18n.t('frontproductstream.constants.physical_check_status.ready', {
    defaultValue: 'Ready for check',
  }),
  id: 1,
};
export const RECEIVED = {
  name: i18n.t('frontproductstream.constants.physical_check_status.received', {
    defaultValue: 'Received',
  }),
  id: 2,
};
export const PASS = {
  name: i18n.t('frontproductstream.constants.physical_check_status.pass', {
    defaultValue: 'Pass',
  }),
  id: 3,
};
export const FAIL = {
  name: i18n.t('frontproductstream.constants.physical_check_status.fail', {
    defaultValue: 'Fail',
  }),
  id: 4,
};
export const APPROVED = {
  name: i18n.t('frontproductstream.constants.physical_check_status.approved', {
    defaultValue: 'Approved',
  }),
  id: 5,
};
export const DISPUTE_1 = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute1_check',
    {
      defaultValue: 'Dispute 1 - Physical checker to check',
    }
  ),
  id: 6,
};
export const DISPUTE_1_TO_VALIDATE = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute1_supplier_check',
    {
      defaultValue: 'Dispute 1 - Supplier to check',
    }
  ),
  id: 7,
};
export const DISPUTE_1_APPROVED = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute1_approved',
    {
      defaultValue: 'Dispute 1 - Changes approved',
    }
  ),
  id: 8,
};
export const DISPUTE_2 = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute2_check',
    {
      defaultValue: 'Dispute 2 - Physical checker to check',
    }
  ),
  id: 9,
};
export const DISPUTE_2_TO_VALIDATE = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute2_supplier_check',
    {
      defaultValue: 'Dispute 2 - Supplier to check',
    }
  ),
  id: 10,
};
export const DISPUTE_2_APPROVED = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute2_approved',
    {
      defaultValue: 'Dispute 2 - Changes approved',
    }
  ),
  id: 11,
};
export const DISPUTE_3 = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute3_gs1_check',
    {
      defaultValue: 'Dispute 3 - GS1 to check',
    }
  ),
  id: 12,
};

export const DISPUTE_3_APPROVED = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute3_supplier_wrong',
    {
      defaultValue: 'Dispute 3 - Supplier is wrong',
    }
  ),
  id: 13,
};

export const DISPUTE_3_TO_VALIDATE = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute3_supplier_check',
    {
      defaultValue: 'Dispute 3 - Supplier to check',
    }
  ),
  id: 14,
};

export const DISPUTE_1_PASS = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute1_pass',
    {
      defaultValue: 'Dispute 1 - Pass',
    }
  ),
  id: 15,
};

export const DISPUTE_2_PASS = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute2_pass',
    {
      defaultValue: 'Dispute 2 - Pass',
    }
  ),
  id: 16,
};

export const DISPUTE_3_PASS = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.dispute3_pass',
    {
      defaultValue: 'Dispute 3 - Pass',
    }
  ),
  id: 17,
};

export const CHECK_NOT_REQUIRED = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_status.check_not_required',
    {
      defaultValue: 'Check not Required',
    }
  ),
  id: 18,
};

export const PhysicalCheckerStatusMap = {
  READY_FOR_CHECK,
  RECEIVED,
  PASS,
  DISPUTE_1_PASS,
  DISPUTE_2_PASS,
  DISPUTE_3_PASS,
  FAIL,
  APPROVED,
  DISPUTE_1,
  DISPUTE_1_TO_VALIDATE,
  DISPUTE_1_APPROVED,
  DISPUTE_2,
  DISPUTE_2_TO_VALIDATE,
  DISPUTE_2_APPROVED,
  DISPUTE_3,
  DISPUTE_3_APPROVED,
  DISPUTE_3_TO_VALIDATE,
  CHECK_NOT_REQUIRED,
};

export const getFromStatusId = (statusId) =>
  Object.values(PhysicalCheckerStatusMap).find(
    (status) => status.id === statusId
  );
