import { useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from 'components/ui/modal';
import { useDispatch } from 'hooks/useDispatch';
import { CHAT_PERMISSION, PRODUCT_PERMISSION } from 'modules/permissions/const';
import { usePermission } from 'modules/permissions/utils';
import { selectProductKeyId } from 'reducers/productVersion';
import { selectIsRetailer } from 'reducers/user/selectors';
import i18n from 'utils/i18n';

import { refuse, resetRefuseModal } from './actions';
import './refuse-modal.scss';
import {
  selectRefuseModalDescription,
  selectRefuseModalOpenedStatus,
  selectRefuseModalTitle,
} from './selectors';

const RetailerRefuseModal = () => {
  const description: any =
    useSelector(selectRefuseModalDescription) || i18n.t('Reason for refusal');
  const isRetailer = useSelector(selectIsRetailer);
  const opened = useSelector(selectRefuseModalOpenedStatus) || false;
  const title: string =
    useSelector(selectRefuseModalTitle) || i18n.t('Update refusal');
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const productKeyId = useSelector(selectProductKeyId);
  const { hasPermission } = usePermission({
    module: PRODUCT_PERMISSION,
    entityId: productKeyId,
  });

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onRefuse = () => {
    dispatch(refuse(message.trim()));
  };

  const onCancel = () => {
    dispatch(resetRefuseModal());
  };

  if (!isRetailer || !opened) {
    return null;
  }

  return (
    <Modal
      modalStyle="dynamic"
      title={title}
      confirmButtonText={i18n.t('Refuse') as string}
      onConfirm={onRefuse}
      onClose={onCancel}
      className="RetailerRefuseModal"
    >
      {hasPermission({
        entityId: productKeyId,
        permissions: CHAT_PERMISSION,
      }) && (
        <div className="RetailerRefuseModal__body">
          <div className="RetailerRefuseModal__label">{description}</div>
          <div className="RetailerRefuseModal__textarea">
            <textarea
              id="refuse-modal-textarea"
              data-testid="refuse-modal-textarea"
              value={message}
              onChange={onMessageChange}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RetailerRefuseModal;
