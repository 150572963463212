import { Component, ComponentType, ErrorInfo, ReactNode } from 'react';

import { logError } from 'utils/logging';

interface State {
  error: Error | null;
}

interface Props {
  children: (error: State['error']) => ReactNode;
}

class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(error: any) {
    return { error };
  }

  state = { error: null };

  componentDidCatch(error: Error, info: ErrorInfo) {
    logError(error, info.componentStack);
  }

  render() {
    return this.props.children(this.state.error);
  }
}

export default (
    WrappedComponent: ComponentType<any>,
    ErrorComponent: ComponentType<any>,
    { errorPropName = 'error' }: { errorPropName?: string } = {}
  ) =>
  (props: any) => {
    return (
      <ErrorBoundary>
        {(error) =>
          error ? (
            <ErrorComponent {...{ [errorPropName]: error }} {...props} />
          ) : (
            <WrappedComponent {...props} />
          )
        }
      </ErrorBoundary>
    );
  };
