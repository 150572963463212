import { debounce, isFunction, once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CustomAutocomplete from 'components/ui/autocomplete/custom';
import Anchor from 'components/ui/basic/anchor';
import InputWithLabel from 'components/ui/input/input-with-label';
import {
  getBrandId,
  getBrandName,
  getDisplayName,
} from 'core/api/productversion';
import suggestionApi from 'resources/suggestionApi';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import SuggestionsAutocomplete from '../plugins/suggestions';

import './kind.scss';

const options = { selectOnlyLeaves: true };

export default class KindAutocomplete extends PureComponent {
  // there are two possible modes:
  // either `suggestedKinds` is given (from validation)
  // or fetch realtime suggestions based on `suggestionData`

  static propTypes = {
    inputId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object,
    isReadOnly: PropTypes.bool,
    params: PropTypes.object,
    suggestionData: PropTypes.object,
    suggestedKinds: PropTypes.array,
  };

  static defaultProps = {
    isReadOnly: false,
  };

  _kindSuggestionPromise = null;

  trackSuggestionDisplay = once(() => {
    track({
      category: 'product',
      action: 'product_suggestion_realtime_kind_displayed',
    });
  });

  constructor(props) {
    super(props);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);
    this.onSuggestionClick = this.onSuggestionClick.bind(this);
    this.state = {
      suggestedKinds: null,
      suggestionAccepted: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.suggestedKinds) {
      return {
        suggestedKinds: props.suggestedKinds,
        suggestionAccepted: state.suggestionAccepted,
      };
    }
    return state;
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.suggestedKinds) {
      this.getSuggestedKinds();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.suggestionData !== this.props.suggestionData) {
      this.getSuggestedKinds();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSuggestionClick(value, rank) {
    const { suggestionData, suggestedKinds, onChange } = this.props;
    track({
      category: 'product',
      action: 'product_suggestion_realtime_kind_clicked',
      from_validation: !(
        typeof suggestedKinds === 'undefined' || suggestedKinds === null
      ),
      suggestion_data: suggestionData
        ? {
            lang: suggestionData.lang,
            product_name: getDisplayName(
              suggestionData.data,
              suggestionData.lang
            ),
            brand_id: getBrandId(suggestionData.data),
            brand_name: getBrandName(suggestionData.data),
          }
        : null,
      value: value.value,
      rank,
    });
    onChange(value);
    this.setState({ suggestionAccepted: true });
  }

  getSuggestedKinds = debounce(() => {
    const { suggestionData } = this.props;

    if (!suggestionData) {
      return;
    }

    if (
      !!this._kindSuggestionPromise &&
      isFunction(this._kindSuggestionPromise.cancel)
    ) {
      this._kindSuggestionPromise.cancel();
    }
    this._kindSuggestionPromise = suggestionApi.getRealTimeFieldSuggestion(
      'kind',
      suggestionData
    );
    this._kindSuggestionPromise.then(
      (response) => {
        if (!this._isMounted) {
          return;
        }
        this.setState({ suggestedKinds: response.data.data });
        this._kindSuggestionPromise = null;
      },
      () => {
        // Somehow there was a fail, don't display anything.
        if (!this._isMounted) {
          return;
        }
        this.setState({ suggestedKinds: null });
        this._kindSuggestionPromise = null;
      }
    );
  }, 500);

  shouldRenderSuggestions() {
    const { isReadOnly } = this.props;
    const { suggestedKinds, suggestionAccepted } = this.state;

    return !(
      isReadOnly ||
      suggestionAccepted ||
      !suggestedKinds ||
      (suggestedKinds && suggestedKinds.length === 0)
    );
  }

  renderExamples() {
    const { isReadOnly, value } = this.props;

    if (isReadOnly || value) {
      return null;
    }

    return (
      <div className="KindAutocomplete__examplesLink row">
        <span className="offset-xs-4 col-xs-8">
          {i18n.t(
            'frontproductstream.kind_autocomplete.help_to_classify.label',
            { defaultValue: 'Not sure how to classify your product?' }
          )}{' '}
          <Anchor data-elevio-article="33">
            {i18n.t(
              'frontproductstream.kind_autocomplete.help_to_classify.link',
              { defaultValue: 'View our examples' }
            )}
          </Anchor>
        </span>
      </div>
    );
  }

  render() {
    const { inputId, value, onChange, isReadOnly, params } = this.props;
    const { suggestedKinds } = this.state;

    const localParams = { ...params, as_tree: true, only_leaves: true };
    if (this.shouldRenderSuggestions()) {
      this.trackSuggestionDisplay();
    }
    return (
      <div className="KindAutocomplete">
        <InputWithLabel
          childId={inputId}
          label={i18n.t('frontproductstream.kind_autocomplete.input.label', {
            defaultValue: "Alkemics' classification",
          })}
          help={i18n.t('frontproductstream.kind_autocomplete.input.help', {
            defaultValue: "Place in Alkemics' classification tree",
          })}
        >
          <CustomAutocomplete
            id={inputId}
            className="InputField__input"
            value={value ? [value] : []}
            onSelect={onChange}
            onUnselect={onChange}
            path="/core/v3/referentials/kinds"
            placeholder={i18n.t(
              'frontproductstream.kind_autocomplete.input.placeholder',
              { defaultValue: "Alkemics' classification" }
            )}
            delegateSelection
            sort="alphabetical"
            params={localParams}
            searchOnClick
            disabled={isReadOnly}
            options={options}
            scrollIntoView
          />
        </InputWithLabel>
        {this.shouldRenderSuggestions() && (
          <SuggestionsAutocomplete
            suggestedEntities={suggestedKinds}
            onSuggestionClick={this.onSuggestionClick}
            toolTipMessage={i18n.t(
              'frontproductstream.kind_autocomplete.help.tooltip',
              {
                defaultValue:
                  'Click on a suggestion to classify your product in the selected suggested category',
              }
            )}
          />
        )}
        {this.renderExamples()}
      </div>
    );
  }
}
