import { flow, get } from 'lodash/fp';

export const selectValidationData = (state) => state.validation;

export const selectValidationResultsByEntity = flow(
  selectValidationData,
  get('ruleResultsByEntity')
);

export const selectValidationResults = flow(
  selectValidationData,
  get('ruleResults')
);

export const selectValidationResultsForViewAsSettings = flow(
  selectValidationData,
  get('ruleResultsForViewAsSettings')
);

export const selectValidationResultsForActiveShares = flow(
  selectValidationData,
  get('ruleResultsActiveSharesRecipients')
);

export const selectStaticWarning = flow(
  selectValidationData,
  get('staticWarning')
);

export const selectAssetHasErrorByAssetId = flow(
  selectValidationData,
  get('assetHasErrorByAssetId')
);

export const selectAssetRuleErrorCount = flow(
  selectValidationData,
  get('assetHasErrorByRuleId'),
  (errorMap: Record<string, boolean>) =>
    errorMap &&
    Object.values(errorMap).reduce(
      (acc: number, val: boolean) => acc + (val ? 1 : 0),
      0
    )
);
