import Immutable from 'immutable';
import { once } from 'lodash/fp';
import { memo, useCallback, useEffect, useMemo } from 'react';

import {
  SHARING_STATUS_BLOCKED,
  SHARING_STATUS_DRAFT,
  SHARING_STATUS_EXPECTED,
  SHARING_STATUS_RECIPIENT_ADDED,
  SHARING_STATUS_SHARED,
  SHARING_STATUS_UNSYNCHRONIZED,
} from 'constants/sharing-status';
import { sharingStatusFilter } from 'core/modules/list/constants/filters';
import {
  FilterOnChange,
  FilterOnCollapse,
  FilterOptions,
  ListAggregations,
} from 'types';
import { i18n } from 'utils/i18n';

import { SelectedFilterMap } from '../generic/types';
import { updateFilterFromQuery } from '../generic/utils';
import ListSimpleFilter from '../simple';

interface Props {
  aggregations: ListAggregations;
  collapsed: boolean;
  filterQueryValues: string[];
  hasRecipient?: boolean;
  onChange: FilterOnChange;
  onCollapse: FilterOnCollapse;
  selectedFilterMap?: SelectedFilterMap;
}

export const SharingStatusFilter = memo(
  ({
    aggregations,
    collapsed,
    filterQueryValues,
    hasRecipient,
    onChange,
    onCollapse,
    selectedFilterMap,
  }: Props) => {
    const { key, filterLabel } = sharingStatusFilter;

    const filterList: FilterOptions = useMemo(
      () =>
        Immutable.fromJS([
          SHARING_STATUS_DRAFT,
          SHARING_STATUS_EXPECTED,
          SHARING_STATUS_RECIPIENT_ADDED,
          SHARING_STATUS_BLOCKED,
          SHARING_STATUS_SHARED,
          SHARING_STATUS_UNSYNCHRONIZED,
        ]),
      []
    );

    const onSelectSatus = useCallback<FilterOnChange>(
      (...args) => {
        onChange(...args);
      },
      [onChange]
    );

    const onLocalCollapse = useCallback(
      (isCollapsed) => {
        onCollapse(key, isCollapsed);
      },
      [onCollapse, key]
    );

    const onUpdateFilterFromQuery = useMemo(
      () => once(updateFilterFromQuery),
      []
    );

    const selectedFilters = selectedFilterMap?.size ?? 0;
    const queryFilters = filterQueryValues?.length ?? 0;

    useEffect(
      () => {
        if (hasRecipient && !selectedFilters && queryFilters) {
          onUpdateFilterFromQuery({
            filterList,
            filterQueryValues,
            onChange,
            key,
            filterLabel,
          });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [hasRecipient, selectedFilters, queryFilters]
    );

    return (
      <ListSimpleFilter
        id="list-filter-sharing-status"
        filterList={filterList}
        filterKey={key}
        filterLabel={filterLabel}
        selectedFilterMap={selectedFilterMap}
        aggregations={hasRecipient ? aggregations : undefined}
        collapsed={collapsed}
        onChange={onSelectSatus}
        onCollapse={onLocalCollapse}
        disabled={!hasRecipient}
        header={
          hasRecipient
            ? ''
            : i18n.t(
                'frontproductstream.core.list_filter_sharing_status.empty_header',
                { defaultValue: 'Select one recipient to use this filter' }
              )
        }
      />
    );
  }
);
