import { get } from 'lodash/fp';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Referential } from '@alkem/sdk-dashboard';

import { notificationError, notificationSuccess } from 'actions/notification';
import { SelectCountries } from 'components/ui/autocomplete/countries/select-countries';
import InputWithLabel from 'components/ui/input/input-with-label';
import InputText from 'components/ui/input/text';
import Modal from 'components/ui/modal';
import { selectOrganizationTargetMarketsJS } from 'reducers/user/selectors';
import legalIdentifierApi from 'resources/legalIdentifierApi';
import { organizationAdmin } from 'routes';
import { TargetMarket } from 'types/targetMarkets';
import i18n from 'utils/i18n';

import AddSingleLegalIdentifier from './add-legal-identifier-single';

type CountryReferential = Referential<{}>;

type ConnectedProps = {
  targetMarkets: TargetMarket[];
};

interface LegalIdentifierType {
  id: string;
  value: string;
  label: string;
  country?: string;
}

interface Actions {
  notificationError: typeof notificationError;
  notificationSuccess: typeof notificationSuccess;
}

interface OwnProps {
  onClose?: () => Promise<void> | void;
  title: string;
  buttonText: string;
  multiple?: boolean;
  forceOpen?: boolean;
  legend?: string[];
  showLink?: boolean;
}

type Props = ConnectedProps & OwnProps & Actions;

const mapStateToProps = (state) => ({
  targetMarkets: selectOrganizationTargetMarketsJS(state),
});

interface State {
  values: { [key: string]: string };
  isOpen: boolean;
  isProcessing: boolean;
  legalIdentifierTypes: LegalIdentifierType[];
  selectedCountry: CountryReferential | null;
}

const actionCreators: Actions = {
  notificationError,
  notificationSuccess,
};

class AddLegalIdentifierModal extends PureComponent<Props, State> {
  isMounted: boolean = false;

  public state: State = {
    values: {},
    isOpen: false,
    isProcessing: false,
    legalIdentifierTypes: [],
    selectedCountry: null,
  };

  public componentDidMount() {
    this.init();
    this.isMounted = true;
  }

  public componentWillUnmount() {
    this.isMounted = false;
  }

  private setCountry = (country) => {
    this.setState({ selectedCountry: country });
  };

  private getCountry = () => {
    const { selectedCountry } = this.state;
    return selectedCountry;
  };
  private getValue = (key) => {
    const { values } = this.state;
    return values[key];
  };

  private setValue = (type: string, value: string) => {
    this.setState((prevState) => {
      const values = this.props.multiple ? { ...prevState.values } : {};
      values[type] = value;
      return { values };
    });
  };

  private updateValue = (key) => (event) => {
    this.setValue(key, event.target.value);
  };

  private updateLegalIdentifiers = (country) => {
    let legalIdentifierTypes;
    const vat = {
      id: 'VAT',
      value: 'VAT',
      label: i18n.t('frontproductstream.add_legal_identifier.type_vat.text', {
        defaultValue: 'VAT',
      }),
      country: country?.code,
    };
    if (country?.code === 'FR') {
      legalIdentifierTypes = [
        vat,
        {
          id: 'SIREN',
          value: 'SIREN',
          label: 'SIREN',
          country: country.code,
        },
      ];
    } else {
      legalIdentifierTypes = [vat];
    }
    this.setState({ legalIdentifierTypes });
    this.setCountry(country);
  };

  private init = () => {
    const { targetMarkets } = this.props;
    const hasFrTartgetMarket = targetMarkets.some(
      (tm) => tm.country_code === 'FR'
    );
    let legalIdentifierTypes: LegalIdentifierType[] = [
      {
        id: 'VAT',
        value: 'VAT',
        label: i18n.t('frontproductstream.add_legal_identifier.type_vat.text', {
          defaultValue: 'VAT',
        }),
        country: '',
      },
    ];

    if (!this.props.multiple || hasFrTartgetMarket) {
      legalIdentifierTypes = [
        ...legalIdentifierTypes,
        {
          id: 'SIREN',
          value: 'SIREN',
          label: 'SIREN',
          country: '',
        },
      ];
    }
    legalIdentifierTypes.forEach((legalIdentifierType) => {
      this.setValue(legalIdentifierType.id, '');
    });
    this.setState({ legalIdentifierTypes });
  };

  private confirmAddedLegalIdentifiers = async () => {
    const { legalIdentifierTypes } = this.state;
    this.setState({ isProcessing: true });

    const legalIdentifiers = legalIdentifierTypes
      .filter((legalIdentifierType) => this.getValue(legalIdentifierType.id))
      .map((legalIdentifierType) => legalIdentifierType.id)
      .map((_type) => {
        return {
          type: _type,
          value: this.getValue(_type),
          country: this.getCountry()?.code,
        };
      });
    try {
      const result = await legalIdentifierApi.LegalIdentifierCreateMultiple(
        legalIdentifiers
      );
      if (this.isMounted) {
        this.setState({ isProcessing: false, isOpen: false });
        this.props.onClose?.();
      }
      this.props.notificationSuccess(
        <div className="OrganizationLegalIdentifier__notification">
          <div>
            {i18n.t(
              'frontproductstream.add_legal_identifier.notification.text',
              {
                defaultValue:
                  'Legal identifiers has been added to your organization',
              }
            )}
          </div>
          <ul>
            {result.data.data.map((e) => (
              <li key={e.value}>
                {e.type} {e.value}
              </li>
            ))}
          </ul>
        </div>
      );
    } catch (error: any) {
      if (this.isMounted) {
        this.setState({ isProcessing: false });
      }
      if (error.data?.data?.errors) {
        this.props.notificationError(
          <div className="OrganizationLegalIdentifier__notification">
            <ul>
              {error.data.data.errors.map((e) => (
                <li key={e.message}>{e.message}</li>
              ))}
            </ul>
          </div>,
          { context: 'modal' }
        );
      } else {
        this.props.notificationError(error.data.message, {
          context: 'modal',
        });
      }
    }
  };

  private openModal = () => {
    this.init();
    this.setState({ isOpen: true });
  };

  private closeModal = () => {
    this.setState({ isOpen: false });
    this.props.onClose?.();
  };

  private isNotValid(type, value) {
    return !type || !value;
  }

  public render() {
    const { isOpen, isProcessing, legalIdentifierTypes } = this.state;
    const isNotValid = legalIdentifierTypes.every((type) =>
      this.isNotValid(type.id, this.getValue(type.id))
    );
    return (
      <>
        {!this.props.forceOpen && (
          <div
            className="OrganizationLegalIdentifierList__addLegalIdentifier"
            onClick={this.openModal}
          >
            {i18n.t('frontproductstream.add_legal_identifier.add.button', {
              defaultValue: 'Add Legal Identifier',
            })}
          </div>
        )}
        {(this.props.forceOpen || isOpen) && (
          <Modal
            title={this.props.title}
            confirmButtonText={this.props.buttonText}
            confirmDisabled={isNotValid}
            isProcessing={isProcessing}
            onConfirm={this.confirmAddedLegalIdentifiers}
            onClose={this.closeModal}
          >
            {this.props.legend &&
              this.props.legend.map((text: string) => <p key={text}>{text}</p>)}
            {this.props.multiple && (
              <InputWithLabel
                inputId="vat-country"
                label={i18n.t(
                  'frontproductstream.add_legal_identifier.country_placeholder.text',
                  {
                    defaultValue: 'Country',
                  }
                )}
                data-testid="vat-country"
              >
                <SelectCountries
                  value={this.getCountry() || null}
                  onChange={this.updateLegalIdentifiers}
                  defaultCode={get(
                    [0, 'country_code'],
                    this.props.targetMarkets
                  )}
                />
              </InputWithLabel>
            )}
            {this.props.multiple &&
              legalIdentifierTypes.map((key) => (
                <InputText
                  id={`add-legalidentifier-value-${key.id}`}
                  key={`add-legalidentifier-value-${key.id}`}
                  data-testid={`add-legalidentifier-value-${key.id}`}
                  label={i18n.t(key.label)}
                  onChange={this.updateValue(key.id)}
                  value={this.getValue(key.id)}
                />
              ))}

            {this.props.showLink && (
              <p>
                <br />
                <Link to={organizationAdmin} onClick={this.closeModal}>
                  <i>
                    {i18n.t(
                      'frontproductstream.add_legal_identifier.modal_extra_information.link',
                      {
                        defaultValue:
                          'You can also fill several numbers, from several countries, directly on the administration page.',
                      }
                    )}
                  </i>
                </Link>
              </p>
            )}
            {!this.props.multiple && (
              <AddSingleLegalIdentifier
                legalIdentifierTypes={legalIdentifierTypes}
                updateValue={this.updateValue}
                getValue={this.getValue}
                setCountry={this.setCountry}
                getCountry={this.getCountry}
              />
            )}
          </Modal>
        )}
      </>
    );
  }
}

export const AddLegalIdentifier = connect<ConnectedProps, Actions, OwnProps>(
  mapStateToProps,
  actionCreators
)(AddLegalIdentifierModal);
