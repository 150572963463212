import classNames from 'classnames';
import Immutable from 'immutable';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ClickOutside } from '@alkem/react-ui-click-outside';

import { SOURCE_PRODUCTDNA } from 'constants/organization-source';
import { CGU_TYPE_ALKEMICS } from 'constants/permissions';
import { FRANCE } from 'constants/targetMarkets';
import {
  getOrganizationSource,
  getOrganizationTargetMarkets,
  isManufacturer,
} from 'core/api/user';
import { selectUser } from 'reducers/user/selectors';
import * as routes from 'routes';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { getAppHostname } from 'utils/location';

import './help-center.scss';

const HelpCenterDropdown = () => {
  const [academyOpen, setAcademyOpen] = useState(false);
  const [formationOpen, setFormationOpen] = useState(false);
  const [informationOpen, setInformationOpen] = useState(false);
  const cguLink = useSelector((state: any) =>
    get(state, ['organization', 'cgus', CGU_TYPE_ALKEMICS, 'link'])
  );
  const academyToggle = () => {
    setAcademyOpen((isOpen) => !isOpen);
  };

  const formationToggle = () => {
    setFormationOpen((isOpen) => !isOpen);
  };

  const informationToggle = () => {
    setInformationOpen((isOpen) => !isOpen);
  };

  const hostname: string = getAppHostname();
  const baseurl: string = `https://academy.${hostname}`;

  return (
    <div className="dropdown-menu dropdown-menu-right" role="menu">
      <div onMouseEnter={academyToggle} onMouseLeave={academyToggle}>
        <a
          className="dropdown-item"
          href={`${baseurl}/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
        >
          <i className="mdi mdi-school" />{' '}
          {i18n.t('frontproductstream.navbar_help.academy.link', {
            defaultValue: 'Alkemics Academy',
          })}
        </a>
        {academyOpen && (
          <div className="dropdown-children">
            <div onMouseEnter={formationToggle} onMouseLeave={formationToggle}>
              <a
                className="dropdown-item"
                href={`${baseurl}/toutes-nos-formations/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
              >
                <i className="mdi mdi-laptop" />{' '}
                {i18n.t('frontproductstream.navbar_help.training.link', {
                  defaultValue: 'Formation',
                })}
              </a>
              {formationOpen && (
                <div className="dropdown-children">
                  <a
                    className="dropdown-item"
                    href={`${baseurl}/nos-certifications/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
                  >
                    <i className="mdi mdi-certificate" />
                    {i18n.t(
                      'frontproductstream.navbar_help.certifications.link',
                      { defaultValue: 'Certifications' }
                    )}
                  </a>
                  <a
                    className="dropdown-item"
                    href={`${baseurl}/nos-tutoriels/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
                  >
                    <i className="mdi mdi-library-video" />
                    {i18n.t('frontproductstream.navbar_help.tutorials.link', {
                      defaultValue: 'Tutorials',
                    })}
                  </a>
                  <a
                    className="dropdown-item"
                    href={`${baseurl}/nos-guides-utilisateurs/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
                  >
                    <i className="mdi mdi-book-open-variant" />
                    {i18n.t('frontproductstream.navbar_help.user_guides.link', {
                      defaultValue: 'Users guides',
                    })}
                  </a>
                  <a
                    className="dropdown-item"
                    href={`${baseurl}/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
                  >
                    <i className="mdi mdi-play-circle" />{' '}
                    {i18n.t('frontproductstream.navbar_help.videos.link', {
                      defaultValue: 'Videos',
                    })}
                  </a>
                  <a
                    className="dropdown-item"
                    href={`${baseurl}/nos-formations/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
                  >
                    <i className="mdi mdi-chair-school" />
                    {i18n.t(
                      'frontproductstream.navbar_help.online_training.link',
                      { defaultValue: 'Online training' }
                    )}
                  </a>
                </div>
              )}
            </div>
            <div
              onMouseEnter={informationToggle}
              onMouseLeave={informationToggle}
            >
              <a
                className="dropdown-item"
                href={`${baseurl}/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
              >
                <i className="mdi mdi-bullhorn" />{' '}
                {i18n.t('frontproductstream.navbar_help.information.link', {
                  defaultValue: 'Information',
                })}
              </a>
              {informationOpen && (
                <div className="dropdown-children">
                  <a
                    className="dropdown-item"
                    href={`${baseurl}/nouvelles-fonctionnalites/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
                  >
                    <i className="mdi mdi-new-box" />
                    {i18n.t(
                      'frontproductstream.navbar_help.new_features.link',
                      { defaultValue: 'New Features' }
                    )}
                  </a>
                  <a
                    className="dropdown-item"
                    href={`${baseurl}/le-centre-daide/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
                  >
                    <i className="mdi mdi-help-circle" />
                    {i18n.t('frontproductstream.navbar_help.help_center.link', {
                      defaultValue: 'Help Center',
                    })}
                  </a>
                  <a
                    className="dropdown-item"
                    href={`${baseurl}/le-blog/?utm_campaign=Alkemics%20Academy&utm_source=platform`}
                  >
                    <i className="mdi mdi-newspaper" />{' '}
                    {i18n.t('frontproductstream.navbar_help.news.link', {
                      defaultValue: 'News',
                    })}
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Link className="dropdown-item" to={routes.userSupport}>
        <i className="mdi mdi-face-agent" />{' '}
        {i18n.t('frontproductstream.navbar_help.support_tickets.link', {
          defaultValue: 'Support Tickets',
        })}
      </Link>
      <a className="dropdown-item" href={`https://docs.${hostname}/docs`}>
        <i className="mdi mdi-book-open-page-variant" />
        {i18n.t('frontproductstream.navbar_help.api_documentation.link', {
          defaultValue: 'API Documentation',
        })}
      </a>
      <a className="dropdown-item" href={`https://status.${hostname}`}>
        <i className="mdi mdi-heart" />{' '}
        {i18n.t('frontproductstream.navbar_help.platform_status.link', {
          defaultValue: 'Platform Status',
        })}
      </a>
      <a className="dropdown-item" href={cguLink}>
        <i className="mdi mdi-file-pdf" />{' '}
        {i18n.t('frontproductstream.navbar_help.terms_conditions.link', {
          defaultValue: 'TOS',
        })}
      </a>
    </div>
  );
};

export const HelpCenter = () => {
  const user: UserImmutable = useSelector(selectUser);
  const [helpCenterOpen, setHelpCenterOpen] = useState(false);
  const mainRef = useRef(null);

  const isProductDNA = getOrganizationSource(user) === SOURCE_PRODUCTDNA;
  const targetMarkets: Immutable.List<Immutable.Map<string, any>> =
    getOrganizationTargetMarkets(user) || Immutable.List();
  const isFrenchTM = targetMarkets.some((tm) => get(tm, ['id']) === FRANCE);

  if (isManufacturer(user) && !isProductDNA && isFrenchTM) {
    return (
      <li
        ref={mainRef}
        id="academy-access"
        data-testid="helpCenter"
        className={classNames('dropdown Navbar__dropdown HelpCenter', {
          open,
        })}
      >
        <button
          className="dropdown-toggle HelpCenter__button"
          data-testid="HelpCenterButton"
          onClick={() => setHelpCenterOpen((isOpen) => !isOpen)}
        >
          <i className="mdi mdi-help-circle HelpCenter__helpIcon " />
          <i className="mdi mdi-chevron-down" />
        </button>
        {helpCenterOpen && (
          <ClickOutside
            ref={mainRef}
            onClickOutside={() => setHelpCenterOpen(false)}
          >
            <HelpCenterDropdown />
          </ClickOutside>
        )}
      </li>
    );
  }
  return (
    <li className="nav-item HelpCenter" data-testid="helpCenter">
      <button
        className="HelpCenter__button"
        data-testid="HelpCenterButton"
        data-elevio-article="index"
      >
        <i className="mdi mdi-help-circle HelpCenter__helpIcon " />
      </button>
    </li>
  );
};

export default HelpCenter;
