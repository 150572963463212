import authApi from 'resources/authApi';

import { FETCH_RECIPIENTS_LIST, RECEIVE_RECIPIENTS_LIST } from './constants';

export const getRecipients = (() => {
  let promise;
  return (
      filters = {},
      sort = {
        by: 'nameLegal',
        order: 'asc',
      },
      withs = {
        fieldsUsed: true,
        withTargetMarkets: true,
        worksWithOrganization: true,
        currencies: true,
        locales: true,
        group: true,
        groupRelation: true,
      }
    ) =>
    (dispatch) => {
      if (promise) {
        return promise;
      }

      dispatch({ type: FETCH_RECIPIENTS_LIST });

      const _filters = { ...filters, worksWithOrganization: false };
      promise = authApi.RecipientsList({ filters: _filters, sort, withs }).then(
        ({ data }) => {
          dispatch({ type: RECEIVE_RECIPIENTS_LIST, recipientsList: data });
          promise = null;
        },
        () => {
          promise = null;
        }
      );
      return promise;
    };
})();
