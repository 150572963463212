import classNames from 'classnames';

import Tooltip from '@alkem/react-ui-tooltip';

import IconWithTextButton from 'components/ui/icon-text-button';
import { TypePackagingLabels } from 'constants/typePackaging';
import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  HETEROGENEOUS_UNIT,
  LOGISTICAL_UNIT,
  UnitType,
} from 'constants/unitTypes';
import {
  RELEASE_CASE_AS_CONSUMER_UNIT,
  RELEASE_NEW_PRODUCT_TYPE_ICONS,
} from 'modules/feature-flag/constants';
import LogisticalHierarchyIcon from 'modules/logistical-hierarchies/components/icon';
import i18n from 'utils/i18n';
import { capitalize } from 'utils/string';

import {
  CanChangeProductTypeData,
  Features,
  OnTypePackagingChange,
  RenderRfpSuLinkTooltip,
} from './FormTypePackaging.types';
import { unitOptionsMap } from './UnitTypes';

const optionPrefix = i18n.t(
  'frontproductstream.packaging_type.option_prefix.text',
  { defaultValue: 'Option only applicable to:' }
);
const typeConsumerUnit = i18n.t(
  'frontproductstream.packaging_type.consumer_unit.label',
  { defaultValue: 'Consumer unit' }
);
const typeLogisticalUnit = i18n.t(
  'frontproductstream.packaging_type.logistical_unit.label',
  { defaultValue: 'Logistical unit' }
);
const typeDisplayUnit = i18n.t(
  'frontproductstream.packaging_type.display_unit.label',
  { defaultValue: 'Display unit' }
);
const typeHeterogeneousUnit = i18n.t(
  'frontproductstream.packaging_type.heterogeneous_unit.label',
  { defaultValue: 'Heterogeneous unit' }
);

const allTypePackagings = [
  {
    id: TypePackagingLabels.EACH.id,
    label: TypePackagingLabels.EACH.label,
    validFor: [CONSUMER_UNIT.id],
    help: (
      <>
        <span>{optionPrefix}</span>
        <ul className="FormTypePackaging__Help">
          <li>{typeConsumerUnit}</li>
        </ul>
      </>
    ),
  },
  {
    id: TypePackagingLabels.PACK.id,
    label: TypePackagingLabels.PACK.label,
    validFor: [CONSUMER_UNIT.id, LOGISTICAL_UNIT.id],
    // This help will never get displayed, but for the sake of completeness
    // here it is.
    help: (
      <>
        <span>{optionPrefix}</span>
        <ul className="FormTypePackaging__Help">
          <li>{typeConsumerUnit}</li>
          <li>{typeLogisticalUnit}</li>
        </ul>
      </>
    ),
  },
  {
    id: TypePackagingLabels.CASE.id,
    label: TypePackagingLabels.CASE.label,
    validFor: [DISPLAY_UNIT.id, LOGISTICAL_UNIT.id, HETEROGENEOUS_UNIT.id],
    enabledPredicate: (
      unitType: UnitType['id'],
      features?: { [k: string]: boolean }
    ) =>
      unitType === CONSUMER_UNIT.id &&
      features?.[RELEASE_CASE_AS_CONSUMER_UNIT],
    help: (
      <>
        <span>{optionPrefix}</span>
        <ul className="FormTypePackaging__Help">
          <li>{typeDisplayUnit}</li>
          <li>{typeConsumerUnit}</li>
          <li>{typeLogisticalUnit}</li>
          <li>{typeHeterogeneousUnit}</li>
        </ul>
      </>
    ),
  },
  {
    id: TypePackagingLabels.PALLET.id,
    label: TypePackagingLabels.PALLET.label,
    validFor: [DISPLAY_UNIT.id, LOGISTICAL_UNIT.id],
    help: (
      <>
        <span>{optionPrefix}</span>
        <ul className="FormTypePackaging__Help">
          <li>{typeDisplayUnit}</li>
          <li>{typeLogisticalUnit}</li>
        </ul>
      </>
    ),
  },
];

type TypePackagingsProps = {
  isReadOnly: boolean;
  canChangeProductTypeData: CanChangeProductTypeData;
  features?: Features;
  valueId: number;
  selectedUnitTypeId: number;
  onTypePackagingChange: OnTypePackagingChange;
  renderRfpSuLinkTooltip: RenderRfpSuLinkTooltip;
};

export const TypePackagings = ({
  isReadOnly,
  canChangeProductTypeData,
  valueId,
  features,
  selectedUnitTypeId,
  onTypePackagingChange,
  renderRfpSuLinkTooltip,
}: TypePackagingsProps) => {
  return (
    <div className="FormTypePackaging__TypePackagings alk-flex alk-flex-center">
      {allTypePackagings
        .filter((packaging) => {
          const disabled =
            !packaging.validFor.includes(selectedUnitTypeId) &&
            !packaging.enabledPredicate?.(selectedUnitTypeId, features);
          return !features?.[RELEASE_NEW_PRODUCT_TYPE_ICONS] || !disabled;
        })
        .filter((packaging) => {
          if (isReadOnly && features?.[RELEASE_NEW_PRODUCT_TYPE_ICONS]) {
            return packaging.id === valueId;
          }
          return true;
        })
        .map((tp) => {
          const disabled =
            !tp.validFor.includes(selectedUnitTypeId) &&
            !tp.enabledPredicate?.(selectedUnitTypeId, features);
          const isCurrentValue = valueId === tp.id;
          const id = `type-packaging-choice-${tp.id}`;

          return (
            <div key={tp.id}>
              {!features?.[RELEASE_NEW_PRODUCT_TYPE_ICONS] ? (
                <div
                  id={id}
                  className={classNames(
                    'FormTypePackaging__Choice',
                    'alk-flex alk-flex-center alk-flex-column',
                    {
                      'FormTypePackaging__Choice--disabled':
                        isReadOnly ||
                        !canChangeProductTypeData.hasNoRfpSuLinks ||
                        disabled,
                      'FormTypePackaging__Choice--selected': isCurrentValue,
                    }
                  )}
                  onClick={onTypePackagingChange(
                    tp.id,
                    isReadOnly ||
                      !canChangeProductTypeData.hasNoRfpSuLinks ||
                      disabled
                  )}
                  data-tip
                  data-for={id}
                >
                  {/* No grey icon for the current value */}
                  <LogisticalHierarchyIcon
                    packagingTypeId={tp.id}
                    disabled={
                      (!canChangeProductTypeData.hasNoRfpSuLinks &&
                        !isCurrentValue) ||
                      disabled
                    }
                  />
                  <div>{tp.label}</div>
                </div>
              ) : (
                <IconWithTextButton
                  title={capitalize(tp.label)}
                  variant="secondary"
                  customIcon={
                    <LogisticalHierarchyIcon packagingTypeId={tp.id} />
                  }
                  isRowDisplay={isReadOnly}
                  onClick={onTypePackagingChange(
                    tp.id,
                    isReadOnly ||
                      !canChangeProductTypeData.hasNoRfpSuLinks ||
                      disabled
                  )}
                  active={isCurrentValue}
                  disabled={
                    isReadOnly ||
                    !canChangeProductTypeData.hasNoRfpSuLinks ||
                    disabled
                  }
                  data-tip
                  data-for={id}
                />
              )}

              {renderRfpSuLinkTooltip(
                canChangeProductTypeData,
                id,
                isCurrentValue
              )}

              {canChangeProductTypeData.hasNoRfpSuLinks && disabled && (
                <Tooltip id={`type-packaging-choice-${tp.id}`} place="bottom">
                  <span>{optionPrefix}</span>
                  <ul className="FormTypePackaging__Help">
                    {tp.validFor.map((tpId) => (
                      <li key={tpId}>{unitOptionsMap[tpId].label}</li>
                    ))}
                  </ul>
                </Tooltip>
              )}
            </div>
          );
        })}
    </div>
  );
};
