import classNames from 'classnames';
import { Component, Dispatch } from 'react';

import { formFieldUpdated, updateEntity } from 'actions/entity';
import Kushiel from 'components/ui/form/plugins/flagger';
import Raguel from 'components/ui/form/plugins/validator';
import InputLabel from 'components/ui/input/label';
import { ENTITY_TYPE_SHARINGUNIT } from 'constants/entities';
import PhysicalCheck from 'modules/contribution/components/icons';
import {
  DataOpsEditPlugin,
  DataOpsExceptions,
  DataOpsInfoPlugin,
  DataOpsNewPatch,
  DataOpsPatch,
  patchDataOpsField,
} from 'modules/data-ops';
import {
  FEATURE_DATA_OPS,
  FEATURE_PRO_RETAILER_FIELD_FLAG,
  RELEASE_LOGISTICAL_HIERARCHIES_ADVANCED_PRIVATE_FIELDS,
} from 'modules/feature-flag/constants';
import { hasFieldPermission } from 'modules/permissions';
import { ProductReviewFieldInfo } from 'modules/product-review';
import { get, size } from 'utils/immutable';

import './field.scss';
import { getId } from './utils/clean';
import { getDefaultValue } from './utils/seed';

export function renderHelpMessage(field) {
  let helpMessage: string | null = null;

  if (field.help) {
    helpMessage = field.help;
  }
  if (field.tips) {
    const tips = `<i class="mdi mdi-lightbulb"></i> ${field.tips}`;
    helpMessage = helpMessage ? `${helpMessage}<br/>${tips}` : tips;
  }

  return helpMessage;
}

export function isReadOnly(field) {
  return get(field, ['options', 'readOnly'], false);
}

interface FieldProps {
  dispatch: Dispatch<any>;
  entity?: { isDirty?: boolean; source: any };
  entityId: number;
  entityKind: string;
  assetId?: number;
  field: {
    placeholder?: string;
    label: string;
    options?: Record<string, string>;
    model: string;
    level?: number;
    resetOnChange?: { targets: string[]; conditionValues: string[] };
    isPrivate?: boolean;
    id: number;
    rank?: number;
    inputKind: {
      kind: string;
      referential?: string;
      url?: string;
      defaultValue?: any;
      values?: any;
      vertical?: boolean;
    };
    kind: string;
    children?: FieldProps['field'][];
    validators?: { kind: string; arg: number }[];
  };
  hasProductUpdatePermission?: boolean;
  isDirty?: boolean;
  value?: any;
  validate?: boolean;
  externalPlugins?: [Function] | null;
  rejections?: number[];
  extraParams: {
    onChange?(
      model: string,
      newValue: any,
      entityId: number | string,
      entityKind: string,
      isDirty?: boolean,
      ignoreField?: boolean,
      assetId?: number,
      isPrivate?: boolean
    ): void;
    noDispatch?: boolean;
    entityIndex?: number;
    patch?: { data: (DataOpsPatch | DataOpsNewPatch)[] };
    isPatchable?: boolean;
    isPatched?: boolean;
    disableDataOps?: boolean;
    isManufacturer?: boolean;
    isRetailer?: boolean;
    hasProductPatchPermission: boolean;
    flags?: Record<string, string>;
    recipientId?: number;
    isDisabled?: boolean;
    entityPermissions?: { [key: string]: string[] };
    hasNormalizedCommentPermission?: boolean;
  };
}

interface FieldState {
  isDirty?: boolean;
  isFlagged: boolean;
  raguelStatus?: {
    error?: boolean;
    hideField?: boolean;
    pendingSuggestions?: boolean;
    notRequested?: boolean;
  };
}

abstract class Field<CustomProps = {}, CustomState = {}> extends Component<
  FieldProps & CustomProps,
  FieldState & CustomState
> {
  multiLevel?: boolean;
  state: FieldState & CustomState;
  static defaultProps: Partial<FieldProps> = {
    isDirty: false,
    validate: true,
    hasProductUpdatePermission: false,
    externalPlugins: null,
  };

  constructor(props, additionalState: CustomState) {
    super(props);
    this.state = {
      isDirty: props.isDirty,
      isFlagged: false, // Retailer flags field to put a comment
      // Raguel judgement impacts field display.
      raguelStatus: {
        error: false,
        hideField: false,
      },
      ...additionalState,
    };
    this.getValue = this.getValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderPlugins = this.renderPlugins.bind(this);
    this.onRaguelJudgment = this.onRaguelJudgment.bind(this);
  }

  componentDidMount() {
    this.ensureDataIsPresent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      // Update if state is different
      nextState !== this.state ||
      // Update if value or field changed
      nextProps.field !== this.props.field ||
      nextProps.value !== this.props.value ||
      nextProps.rejections !== this.props.rejections ||
      !!(nextProps.value && size(nextProps.value) !== size(this.props.value)) ||
      this.getPatch(nextProps) !== this.getPatch() ||
      nextProps.extraParams?.onChange !== this.props.extraParams?.onChange ||
      nextProps.extraParams?.entityPermissions !==
        this.props.extraParams?.entityPermissions
    );
  }

  onRaguelJudgment(options) {
    const raguelStatus = { ...this.state.raguelStatus };
    let changed = false;
    Object.entries(options).forEach(([k, v]) => {
      if (v !== raguelStatus[k]) {
        changed = true;
        raguelStatus[k] = v;
      }
    });
    if (changed) {
      this.setState((state) => ({ ...state, raguelStatus }));
    }
  }

  getValue(event) {
    return event.currentTarget.value;
  }

  getOptionValue(option) {
    const { field } = this.props;
    if (field?.options) {
      return field.options[option];
    }
    return undefined;
  }

  getId() {
    const { field, entityKind, entityId } = this.props;
    return getId(field.model, entityKind, entityId);
  }

  getClasses(initialClasses = {}) {
    const { extraParams = {} as FieldProps['extraParams'] } = this.props;
    const { patch, isPatchable } = extraParams;
    const { isFlagged, raguelStatus = {} } = this.state;
    const { error, pendingSuggestions, hideField, notRequested } = raguelStatus;
    const isFlaggable = this.isFlaggable();
    return {
      ...initialClasses,
      FormField: true,
      'FormField--flaggable': isFlaggable,
      'FormField--flagged': isFlagged,
      'FormField--raguelWarning': pendingSuggestions,
      'FormField--raguelError': error,
      'FormField--raguelError--multiLevel': this.multiLevel,
      'FormField--hideField': hideField,
      'FormField--notRequested': notRequested,
      'FormField--editable': isPatchable,
      'FormField--patched':
        patch?.data !== undefined || this.props.rejections?.length,
    };
  }

  flagField = () => {
    if (!this.isFlaggable()) {
      return false;
    }
    this.setState({ isFlagged: true } as FieldState & CustomState);
    return true;
  };

  unflagField = () => {
    if (!this.isFlaggable()) {
      return false;
    }
    this.setState({ isFlagged: false } as FieldState & CustomState);
    return true;
  };

  isFlaggable() {
    return get(this.props.field, ['options', 'flaggable'], false);
  }

  ensureDataIsPresent() {
    const { field, value } = this.props;
    // We need to have a model to be able to match a validation rule with this component.
    const { defaultValue, changed } = getDefaultValue(
      field,
      value,
      null,
      this.multiLevel
    );
    if (changed) {
      this.dispatchChange(defaultValue, false); // Not dirty.
    }
    return changed;
  }

  dispatchChange(newValue, isDirty = true, ignoreField = true) {
    this.dispatchChangeWithModel(
      this.props.field.model,
      newValue,
      isDirty,
      ignoreField
    );
  }

  dispatchPatchDataOpsField(
    params: [
      model: string,
      newValue: any,
      entityId: number | string,
      entityKind: string,
      isDirty?: boolean,
      ignoreField?: boolean,
      assetId?: number
    ]
  ) {
    const { dispatch, field, extraParams } = this.props;
    const { isPatched, isPatchable, isRetailer } = extraParams ?? {};

    if (isRetailer && isPatched) {
      if (isPatchable === true) {
        // patch data for data-ops feature
        dispatch(patchDataOpsField([field.label, ...params]));
      }
      return true;
    }
    return false;
  }

  dispatchChangeWithModel(model, newValue, isDirty = true, ignoreField = true) {
    const { dispatch, entityId, entityKind, field, extraParams, assetId } =
      this.props;
    const { noDispatch, onChange } = extraParams ?? {};
    const currentModel = model || field.model;
    const params: [
      model: string,
      newValue: any,
      entityId: number | string,
      entityKind: string,
      isDirty?: boolean,
      ignoreField?: boolean,
      assetId?: number
    ] = [
      currentModel,
      newValue,
      entityId,
      entityKind,
      isDirty,
      ignoreField,
      assetId,
    ];

    if (this.dispatchPatchDataOpsField(params)) {
      return;
    }

    if (!noDispatch) {
      dispatch(updateEntity(...params));
      dispatch(formFieldUpdated(currentModel));
    }

    if (onChange) {
      onChange(...params, field.isPrivate);
    }
  }

  resetConditionalFields(newValue) {
    const {
      field,
      dispatch,
      extraParams = {} as FieldProps['extraParams'],
    } = this.props;
    const { noDispatch, onChange } = extraParams;
    if (
      field?.resetOnChange?.targets &&
      field?.resetOnChange?.conditionValues?.indexOf?.(newValue) !== -1
    ) {
      field.resetOnChange.targets.forEach((model) => {
        if (!noDispatch) {
          dispatch(
            updateEntity(
              model,
              undefined,
              this.props.entityId,
              this.props.entityKind
            )
          );
        }
        if (onChange) {
          onChange(
            model,
            undefined,
            this.props.entityId,
            this.props.entityKind
          );
        }
      });
    }
  }

  shouldDisplayItem(itemProp) {
    // itemProp can be anything supposed to be in field.options.display.
    // Display defaults to true if unspecified.
    const { field } = this.props;
    return get(field, `options.display.${itemProp}`) !== false;
  }

  handleChange(event, isDirty = true, ignoreField = true) {
    const newValue = this.getValue(event);

    if (!this.state.isDirty) {
      this.setState({ isDirty: true } as FieldState & CustomState);
    }

    this.dispatchChange(newValue, isDirty, ignoreField);

    // Reset other fields whose display is conditioned on this field's value.
    this.resetConditionalFields(newValue);
  }

  isVisible() {
    const { field } = this.props;
    return get(field, ['options', 'visible'], true);
  }

  isReadOnly(props?) {
    const { field, extraParams } = props || this.props;
    const { isPatchable, isDisabled, entityPermissions } = extraParams || {};

    if (!hasFieldPermission(field, entityPermissions)) {
      return true;
    }

    return isDisabled === true || typeof isPatchable === 'boolean'
      ? !isPatchable
      : isReadOnly(field);
  }

  getPatch(props?) {
    return (props || this.props).extraParams?.patch;
  }

  renderLabel(colClass?, alignLeft = false) {
    const { field } = this.props;
    if (!this.shouldDisplayItem('label')) {
      return null;
    }
    return (
      <div className={colClass}>
        <InputLabel
          id={field.model}
          htmlFor={this.getId()}
          label={field.label}
          help={this.renderHelpMessage()}
          alignLeft={alignLeft}
          mandatory={get(field, ['options', 'mandatory'], false)}
          multiLevel={this.multiLevel}
        />
      </div>
    );
  }

  renderHelpMessage() {
    const { field } = this.props;
    return renderHelpMessage(field);
  }

  renderPlugins({
    offset = false,
    validation = true,
    withKushiel = true,
    withDataOps = true,
    withRaguel = true,
    raguelOpt,
  }: {
    offset?: boolean;
    validation?: boolean;
    withKushiel?: boolean;
    withDataOps?: boolean;
    withRaguel?: boolean;
    raguelOpt?: { model: string };
  } = {}) {
    const {
      field,
      value,
      entityId,
      entityKind,
      hasProductUpdatePermission,
      validate,
      externalPlugins,
      extraParams = {} as FieldProps['extraParams'],
      rejections,
    } = this.props;
    const {
      entityIndex,
      flags = {},
      patch,
      hasProductPatchPermission = false,
      isPatchable,
      disableDataOps,
      isRetailer,
      isManufacturer,
      recipientId,
      hasNormalizedCommentPermission,
    } = extraParams || {};

    const { isFlagged } = this.state;
    const isFlaggable = this.isFlaggable();
    const isReadOnlyLocal = this.isReadOnly();
    const hasRaguel = withRaguel && validate && validation;
    const hasKushiel =
      flags[FEATURE_PRO_RETAILER_FIELD_FLAG] &&
      withKushiel &&
      hasNormalizedCommentPermission &&
      isFlaggable;
    let hasDataOps =
      !disableDataOps &&
      withDataOps &&
      field.level === 1 &&
      ((isRetailer && flags[FEATURE_DATA_OPS]) ||
        (isManufacturer && !!patch?.data));
    const hasAdvancedPrivateFieldEdit =
      !!flags[RELEASE_LOGISTICAL_HIERARCHIES_ADVANCED_PRIVATE_FIELDS] &&
      field.isPrivate;

    if (DataOpsExceptions.includes(field.model)) {
      hasDataOps = false;
    }
    const pluginsCount = [isRetailer && hasDataOps, hasKushiel].reduce(
      (acc, isEnabled) => (isEnabled ? acc + 1 : acc),
      0
    );

    const displayDataOpsEditPlugin =
      isRetailer &&
      hasDataOps &&
      entityKind !== ENTITY_TYPE_SHARINGUNIT &&
      !hasAdvancedPrivateFieldEdit &&
      hasProductPatchPermission &&
      isReadOnlyLocal;

    return (
      <div
        className={classNames(
          'FormField__plugins',
          pluginsCount > 0 && `FormField__plugins--${pluginsCount}`,
          offset && 'offset-xs-4'
        )}
        data-kind={get(field, ['kind'])}
        data-label={get(field, ['label'])}
        data-model={get(field, ['model'])}
        data-flaggable={isFlaggable}
        data-level={field.level}
        data-read-only={isReadOnlyLocal}
        data-kushiel={hasKushiel}
        data-dataops={hasDataOps}
        data-entity-id={entityId}
        data-entity-kind={entityKind}
        data-testid="form-field-plugins"
      >
        {(hasDataOps || hasKushiel) && (
          <div className="alk-flex alk-flex-center FormField__actions">
            {displayDataOpsEditPlugin && (
              <DataOpsEditPlugin
                field={field}
                entityId={entityId}
                entityKind={entityKind}
                isEditable={!!isPatchable}
              />
            )}
            {hasKushiel && (
              <Kushiel
                label={field.label}
                productVersionId={entityId}
                isFlagged={isFlagged}
                punish={this.flagField}
                forgive={this.unflagField}
                model={field.model}
                value={value}
              />
            )}
          </div>
        )}
        {hasRaguel && (
          <Raguel
            entityId={entityId}
            entityKind={entityKind}
            label={field.label}
            model={raguelOpt?.model || field.model}
            value={value}
            onJudgmentDay={this.onRaguelJudgment}
            readOnly={isReadOnlyLocal}
            displayActions={hasProductUpdatePermission}
            entityIndex={entityIndex}
            recipientId={recipientId}
          />
        )}
        <PhysicalCheck field={field} />
        {hasDataOps && patch?.data && (
          <DataOpsInfoPlugin
            patches={patch.data}
            canPatch={hasProductPatchPermission}
            label={field.label || ''}
          />
        )}
        {Boolean(rejections) && (
          <ProductReviewFieldInfo rejections={rejections} />
        )}
        {externalPlugins && externalPlugins.map((plugin) => plugin({ field }))}
      </div>
    );
  }

  render(): JSX.Element | null {
    return null;
  }
}

export default Field;
