import { Map, fromJS } from 'immutable';
import { inRange } from 'lodash';
import moment from 'moment';
import { bool } from 'prop-types';
import { Component } from 'react';
import { map } from 'react-immutable-proptypes';

import i18n from 'utils/i18n';

import './index.scss';

export default class AverageTimeCell extends Component {
  static className = 'AverageTimeCell';

  static propTypes = {
    data: map,
    referential: map.isRequired,
    isAggregation: bool,
  };

  static defaultProps = {
    data: Map({ avg: 0, doc_count: 0 }),
  };

  defaultConfig = fromJS({
    unit: 'day',
    quality: {
      good: [0, 7],
      medium: [7, 30],
      low: [30],
    },
  });

  getTargetTimeUnit() {
    const { referential } = this.props;
    return (
      referential.getIn(['cell', 'unit']) || this.defaultConfig.get('unit')
    );
  }

  getRangeByLevel(level) {
    const { referential } = this.props;
    const config = referential.get('cell') || this.defaultConfig;
    const path = ['quality', level];
    let range = config.getIn(path) || this.defaultConfig.getIn(path);
    if (range.size === 1) {
      range = range.push(Infinity);
    }
    return range;
  }

  getQuality(value) {
    const good = this.getRangeByLevel('good');
    if (inRange(value, good.get(0), good.get(1) + 1)) {
      return 'good';
    }
    const medium = this.getRangeByLevel('medium');
    if (inRange(value, medium.get(0), medium.get(1) + 1)) {
      return 'medium';
    }
    const low = this.getRangeByLevel('low');
    if (inRange(value, low.get(0), low.get(1) + 1)) {
      return 'low';
    }
    return 'none';
  }

  computeData(data) {
    const seconds = data.get('avg');
    let unit = this.getTargetTimeUnit();
    let avgTime;
    let greaterThanOrEqualToOne;
    let lessThanOne;
    let time;
    let quality = 'none';

    if (typeof seconds !== 'number' || seconds < 0) {
      return { time: '-', quality };
    }

    const duration = moment.duration(seconds, 'seconds');

    switch (unit) {
      case 'day':
        avgTime = Math.round(duration.asDays());
        greaterThanOrEqualToOne = i18n.t(
          'frontproductstream.reporting_page_result_cell.average_days_greater_than_or_equal_to_one.text',
          { defaultValue: '{{count}} day', count: avgTime }
        );
        lessThanOne = i18n.t(
          'frontproductstream.reporting_page_result_cell.average_days_less_than_one.text',
          { defaultValue: '< 1 day' }
        );
        break;
      case 'hour':
        avgTime = Math.round(duration.asHours());
        greaterThanOrEqualToOne = i18n.t(
          'frontproductstream.reporting_page_result_cell.average_hours_greater_than_or_equal_to_one.text',
          { defaultValue: '{{count}} hour', count: avgTime }
        );
        lessThanOne = i18n.t(
          'frontproductstream.reporting_page_result_cell.average_hours_less_than_one.text',
          { defaultValue: '< 1 hour' }
        );
        break;
      case 'minute':
        avgTime = Math.round(duration.asMinutes());
        greaterThanOrEqualToOne = i18n.t(
          'frontproductstream.reporting_page_result_cell.average_minutes_greater_than_or_equal_to_one.text',
          { defaultValue: '{{count}} minute', count: avgTime }
        );
        lessThanOne = i18n.t(
          'frontproductstream.reporting_page_result_cell.average_minutes_less_than_one.text',
          { defaultValue: '< 1 minute' }
        );
        break;
      default:
        unit = 'second';
        avgTime = Math.round(duration.asSeconds());
        greaterThanOrEqualToOne = i18n.t(
          'frontproductstream.reporting_page_result_cell.average_seconds_greater_than_or_equal_to_one.text',
          { defaultValue: '{{count}} second', count: avgTime }
        );
        lessThanOne = i18n.t(
          'frontproductstream.reporting_page_result_cell.average_seconds_less_than_one.text',
          { defaultValue: '< 1 second' }
        );
        break;
    }

    quality = this.getQuality(avgTime);

    if (avgTime < 1) {
      time = lessThanOne;
    } else {
      time = greaterThanOrEqualToOne;
    }

    return { time, quality };
  }

  render() {
    const { data, isAggregation } = this.props;
    if (!data || data.isEmpty()) {
      return null;
    }
    const { time, quality } = this.computeData(data);
    return (
      <div className={`AverageTimeCell AverageTimeCell--${quality}`}>
        {isAggregation ? (
          <div className="AverageTimeCell__count">{time}</div>
        ) : (
          time
        )}
      </div>
    );
  }
}
