import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { moduleName } from './constants';
import reducer from './reducer';
import mainSaga from './sagas/main';

registerModuleReducer(moduleName, reducer);
registerSaga(mainSaga);

export const AppStore = asyncComponent(async () =>
  registerModule(await import(/* webpackChunkName: "appstore" */ './async'))
);
