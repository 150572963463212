import { List, Map, OrderedMap, Set, fromJS, is } from 'immutable';
import moment from 'moment';
import { createReducer } from 'redux-create-reducer';

import { UPDATE_ENTITY } from 'constants/events/entity';
import { archived } from 'constants/sharedStatus';
import { getAsReadOnly } from 'utils/displayGroup';

import {
  CREATE_SHARING_UNIT_SUCCESS,
  DELETE_SHARING_UNIT_SUCCESS,
  DISPLAY_WARNING_ON_SAVE,
  FETCH_DISPLAY_GROUPS_SUCCESS,
  FETCH_SHARING_UNITS_SUCCESS,
  RECEIVE_SHARING_UNITS_EXPORT_FILE,
  SET_HIERARCHY_GRID_ERROR,
  UPDATE_SHARING_UNIT_FIELD,
  UPDATE_SHARING_UNIT_STATUS,
} from '../actionTypes';
import {
  createOrderedMapById,
  getNextClientId,
  withLinearHierarchies,
} from '../utils/core';

export const convertPath = (path) => path.slice(1).join('.');

export const updateIgnoredFields = (isDirty, path) => (ignoredFields) =>
  isDirty ? ignoredFields.delete(path) : ignoredFields.add(path);

function getDisplayGroups(payload) {
  const { displayGroups } = payload;
  if (payload.readOnly) {
    return displayGroups.map((dg) => getAsReadOnly(dg));
  } else {
    return displayGroups;
  }
}

export const createPriceWaterfallLabels = (pricewaterfallLabels) =>
  (pricewaterfallLabels || []).reduce(
    (acc, label) =>
      acc.set(
        label.sharingUnit_id,
        (acc.get(label.sharingUnit_id) || List()).push({
          ...label,
          present: true,
        })
      ),
    Map()
  );

export const initialState = fromJS({
  sharingUnitsById: OrderedMap(),
  displayGroupsByTargetOrganization: Map(),
  tariffDisplayGroupsByTargetOrganization: Map(),
  hasEdited: Set(),
  flattenHierarchies: true,
  /* Ignored fields for sharing unit fields, see reducers/productVersion for
   * explanation.
   */
  ignoredFields: Set(),
  hierarchyGridErrors: Map(),
  lastExportFileByGTIN: Map(),
  pricewaterfallLabels: Map(),
  hasPriceWaterfallLabelEdited: false,
  displayWarningOnSave: false,
});
const targetOrgIdComparator = (su1, su2) =>
  su1.targetOrganization.id - su2.targetOrganization.id;

const updateSharingUnit = (state, { sharingUnit }, clientId) =>
  state.setIn(
    ['sharingUnitsById', sharingUnit.id],
    fromJS(
      state.get('flattenHierarchies')
        ? withLinearHierarchies(sharingUnit)
        : sharingUnit
    )
      .update((su) =>
        su.set('data', su.getIn(['lastRequest', 'data']) || su.get('data'))
      )
      .set('clientId', clientId)
  );

export default createReducer(initialState, {
  [FETCH_SHARING_UNITS_SUCCESS]: (state, { payload, requestPayload }) => {
    const targetSharingStatus = payload.targetSharingStatuses;
    const archivedTargetOrganizationId = targetSharingStatus
      .filter((share) => share.status.id === archived.id)
      .map((share) => share.targetOrganization.id);

    // Keep only sharing unit that are synchronized
    const sharingUnits = payload.sharingUnits
      .filter(
        (su) =>
          archivedTargetOrganizationId.indexOf(su.targetOrganization.id) === -1
      )
      .sort(targetOrgIdComparator);

    const mapById =
      requestPayload && requestPayload.flattenHierarchies
        ? createOrderedMapById(
            sharingUnits.map((v) => withLinearHierarchies(v))
          )
        : createOrderedMapById(sharingUnits);

    return state
      .set('sharingUnitsById', mapById)
      .set(
        'flattenHierarchies',
        requestPayload && requestPayload.flattenHierarchies
      )
      .set('hasEdited', Set())
      .set('displayWarningOnSave', false)
      .set(
        'pricewaterfallLabels',
        createPriceWaterfallLabels(payload.pricewaterfallLabels)
      )
      .set('hasPriceWaterfallLabelEdited', false);
  },
  [RECEIVE_SHARING_UNITS_EXPORT_FILE]: (state, { payload }) =>
    state.withMutations((newState) => {
      payload.data.forEach((ef) => {
        ef.gtins.forEach((gtin) => {
          const efc = newState.getIn([
            'lastExportFileByGTIN',
            gtin,
            ef.recipient_organization_id,
          ]);
          if (
            efc === undefined ||
            moment(ef.createdAt).isAfter(efc.get('createdAt'))
          ) {
            newState.setIn(
              ['lastExportFileByGTIN', gtin, ef.recipient_organization_id],
              fromJS(ef)
            );
          }
        });
      });
    }),

  [CREATE_SHARING_UNIT_SUCCESS]: (state, { payload }) =>
    updateSharingUnit(state, payload, getNextClientId(state)),

  [DELETE_SHARING_UNIT_SUCCESS]: (state, { payload }) =>
    state.deleteIn(['sharingUnitsById', payload.sharingUnitId]),

  [UPDATE_SHARING_UNIT_FIELD]: (state, { payload }) =>
    state
      .setIn(
        ['sharingUnitsById', payload.sharingUnitId, 'data', payload.field],
        payload.value
      )
      .update('hasEdited', (hasEdited) => hasEdited.add(payload.sharingUnitId)),

  [UPDATE_SHARING_UNIT_STATUS]: (state, { payload }) =>
    state.updateIn(['sharingUnitsById', payload.sharingUnit.get('id')], (su) =>
      (su || Map()).set('status', payload.sharingUnit.get('status'))
    ),

  [UPDATE_ENTITY]: (state, action) => {
    if (action.entityKind === 'SharingUnit') {
      const [key, ...keyPath] = action.key.split('.');
      const suPath = ['sharingUnitsById', action.entityId];
      const basePath = [...suPath, 'data', key];
      const fullPath = [...basePath, ...keyPath];
      const currentValue = state.getIn(fullPath);
      const newValue = fromJS(action.value);
      if (!is(currentValue, newValue)) {
        return (
          state
            // Just make sure the id is here, for validation and stuff
            .setIn([...suPath, 'id'], action.entityId)
            .setIn(fullPath, newValue)
            .update('hasEdited', (hasEdited) =>
              action.isDirty ? hasEdited.add(action.entityId) : hasEdited
            )
            .update(
              'ignoredFields',
              updateIgnoredFields(action.isDirty, convertPath(basePath))
            )
        );
      }
    } else if (action.entityKind === 'PriceWaterfallLabel') {
      const labels =
        state.getIn(['pricewaterfallLabels', action.entityId]) || List();

      const index = labels.findIndex(
        (v) => v.product_id === action.value.product_id
      );
      if (index < 0) {
        return state
          .setIn(
            ['pricewaterfallLabels', action.entityId],
            labels.push({
              ...action.value,
            })
          )
          .set('hasPriceWaterfallLabelEdited', true);
      }

      return state
        .setIn(['pricewaterfallLabels', action.entityId, index], action.value)
        .set('hasPriceWaterfallLabelEdited', true);
    }

    return state;
  },
  [DISPLAY_WARNING_ON_SAVE]: (state) => state.set('displayWarningOnSave', true),

  [FETCH_DISPLAY_GROUPS_SUCCESS]: (state, { payload }) =>
    state.setIn(
      [
        payload.isTariff
          ? 'tariffDisplayGroupsByTargetOrganization'
          : 'displayGroupsByTargetOrganization',
        payload.targetOrganizationId,
      ],
      getDisplayGroups(payload)
    ),

  [SET_HIERARCHY_GRID_ERROR]: (state, { payload }) =>
    payload.errors.length > 0
      ? state.setIn(
          ['hierarchyGridErrors', payload.sharingUnitId],
          fromJS(payload.errors)
        )
      : state.deleteIn(['hierarchyGridErrors', payload.sharingUnitId]),
});
