import { createSelector } from 'reselect';

const selectModuleState = (state) => state.module.refuseModal;

export const selectRefusedEntityId = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('entityId')
);

export const selectRefusedEntityType = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('entityType')
);

export const selectRefuseModalOpenedStatus = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('opened')
);

export const selectRefuseModalTitle = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('title')
);

export const selectRefuseModalDescription = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('description')
);
