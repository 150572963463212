import { Moment } from 'moment';

import moment from 'utils/moment';

export const TIME_FORMAT_SECOND = 'ss';
export const TIME_FORMAT_SECOND_NO_DAY = 'ss no day';
export const TIME_FORMAT_MINUTE = 'mm';
export const TIME_FORMAT_MINUTE_LOCALIZED = 'mm locale';
export const TIME_FORMAT_DAY = 'DD';
export const TIME_FORMAT_ISO_8601 = 'iso';

const formatTemplates = {
  [TIME_FORMAT_SECOND]: 'YYYY-MM-DD HH:mm:ss',
  [TIME_FORMAT_SECOND_NO_DAY]: 'HH:mm:ss',
  [TIME_FORMAT_MINUTE]: 'YYYY-MM-DD HH:mm',
  [TIME_FORMAT_MINUTE_LOCALIZED]: 'L LT',
  [TIME_FORMAT_DAY]: 'YYYY-MM-DD',
  [TIME_FORMAT_ISO_8601]: undefined,
};

export const isTimestamp = (value) => /^-?\d+$/.test(String(value));

export const parseDateFromValue = (value, output) => {
  if (!value || value === undefined) {
    return null;
  }
  let date;
  if (output === 'timestamp' && isTimestamp(value)) {
    date = moment.unix(value).utc();
  } else if (output === 'iso_date') {
    date = moment.utc(value).local();
  } else {
    date = moment.utc(value);
  }
  return moment([date.year(), date.month(), date.date()]);
};

export const formatTime = (
  m: Moment | string,
  formatId: keyof typeof formatTemplates = TIME_FORMAT_MINUTE
) => moment(m).format(formatTemplates[formatId]);

// to be used in presence of a non-standard datetime string pretending
// to express an instant in UTC timezone
export const formatTimeImplicitUTC = (
  time: string,
  formatID: keyof typeof formatTemplates = TIME_FORMAT_MINUTE
) => formatTime(moment.utc(time).local(), formatID);
