import classNames from 'classnames';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomAutocomplete from 'components/ui/autocomplete/custom';
import Field from 'components/ui/form/field';
import { selectTargetMarketId } from 'reducers/productVersion';
import { selectDefaultTargetMarket } from 'reducers/user/selectors';
import { get, toJsIfImmutable } from 'utils/immutable';

import './autocomplete.scss';

const mapStateToProps = (state) => ({
  targetMarketId:
    selectTargetMarketId(state) || get(selectDefaultTargetMarket(state), 'id'),
});

export class FormAutocomplete extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    path: PropTypes.string.isRequired,
    // Parameter `params` to pass extra agrument when calling getList through CustomAutocomplete
    params: PropTypes.object,
    autoSelect: PropTypes.bool,
    targetMarketId: PropTypes.number.isRequired,
  });

  static defaultProps = Object.assign({}, Field.defaultProps, {
    params: {},
    autoSelect: true,
  });

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // We override this method because we are checking the id within the value.
    return (
      get(nextProps, 'value.id') !== get(this.props, 'value.id') ||
      super.shouldComponentUpdate(nextProps, nextState)
    );
  }

  getValue = (data) => {
    if (isNumber(data)) {
      return null;
    }
    return data.value || null;
  };

  render() {
    const { value, field, params, autoSelect, path, targetMarketId } =
      this.props;

    if (!this.isVisible()) {
      return null;
    }
    if (field.declinableBy && field.declinableBy.kind === 'languages') {
      return null;
    }

    // TODO: Handle immutable objects in autocomplete
    let localValue = toJsIfImmutable(value);
    localValue = localValue || [];
    if (localValue && !Array.isArray(localValue)) {
      localValue = [localValue];
    }

    const renderedLabel = this.renderLabel('col-xs-4');
    return (
      <div
        className={classNames(
          this.getClasses({ FormAutocomplete: true, row: true })
        )}
      >
        {renderedLabel}
        <div
          className={classNames(
            'InputField__input',
            'FormAutocomplete__input',
            {
              'col-xs-8': !!renderedLabel,
              'col-xs-12': !renderedLabel,
            }
          )}
        >
          <CustomAutocomplete
            id={this.getId()}
            value={localValue}
            onSelect={this.handleChange}
            onUnselect={this.handleChange}
            options={field.options}
            path={path}
            placeholder={field.placeholder || field.label}
            disabled={this.isReadOnly()}
            excludeList={field.excludeList}
            params={{
              ...params,
              target_market_id: targetMarketId,
            }}
            searchOnClick
            disableOnClickOutside
            delegateSelection
            autoSelect={autoSelect}
          />
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormAutocomplete);
