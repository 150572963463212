import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CustomAutocomplete from 'components/ui/autocomplete/custom';

export default class HierarchySegmentAutocomplete extends PureComponent {
  static propTypes = {
    inputId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object,
    isReadOnly: PropTypes.bool,
    params: PropTypes.object,
    placeholder: PropTypes.string,
    extraOptions: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    isReadOnly: false,
    value: null,
    params: {},
  };

  constructor(props) {
    super(props);

    this.formatTree = this.formatTree.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    if (!event || !event.path) {
      return;
    }
    const newEvent = event;
    newEvent.value.path = event.path;
    this.props.onChange(newEvent);
  }

  formatTree(tree) {
    return this.formatTreeRecursively(tree, []);
  }

  formatTreeRecursively(tree, path) {
    const formattedTree = {
      label: tree.name,
      value: tree,
      key: tree.id,
      path,
    };
    if (tree.children) {
      const currentPath = path.concat({
        key: tree.id,
        name: tree.name,
      });
      formattedTree.children = tree.children.map((child) =>
        this.formatTreeRecursively(child, currentPath)
      );
    } else {
      formattedTree.children = [];
    }
    return formattedTree;
  }

  formatValue(value) {
    if (!value) {
      return [];
    }

    let path = '';
    value.path.forEach((level) => {
      path += `${level.name} > `;
    });
    return [
      {
        id: value.id,
        label: value.name,
        path: `${path}${value.name}`,
      },
    ];
  }

  render() {
    const {
      inputId,
      value,
      isReadOnly,
      params,
      placeholder,
      className,
      extraOptions,
    } = this.props;
    const formattedValue = this.formatValue(value);
    return (
      <CustomAutocomplete
        id={inputId}
        className={classNames('InputField__input', className)}
        path="/core/v3/productsegments/hierarchy"
        params={{ as_tree: true, ...params }}
        onSelect={this.onChange}
        onUnselect={this.onChange}
        searchOnClick
        searchParam="filter_name_like"
        sort="alphabetical"
        disabled={isReadOnly}
        keyForSelected="path"
        value={formattedValue}
        limit={null}
        dataFormatter={this.formatTree}
        placeholder={placeholder}
        options={extraOptions}
      />
    );
  }
}
