import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { isManufacturer } from 'core/api/user';
import ListFilters from 'core/modules/list/components/filters';
import { FILTERS_PANEL } from 'core/modules/list/constants';
import { selectUser } from 'reducers/user/selectors';
import * as routes from 'routes';
import { flattenMap } from 'utils';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { hash } from 'utils/routing';

import { collapseFilters, trackAction } from '../../actions';
import {
  selectAreFiltersCollapsed,
  selectSelectedFilterList,
} from '../../selectors';

import { CatalogExpandedFilters } from './expanded';

const mapStateToProps = (state) => ({
  areFiltersCollapsed: selectAreFiltersCollapsed(state),
  withCreateProduct: isManufacturer(selectUser(state)),
  selectedFilters: selectSelectedFilterList(state).size,
});

const mapDispatchToProps = {
  collapseFilters,
  trackAction,
};

export class CatalogFilters extends PureComponent {
  static propTypes = {
    areFiltersCollapsed: PropTypes.bool,
    withCreateProduct: PropTypes.bool,
    actions: PropTypes.shape({
      collapseFilters: PropTypes.func.isRequired,
      trackAction: PropTypes.func.isRequired,
    }).isRequired,
    filtersQueryMap: PropTypes.object,
    selectedFilters: PropTypes.number,
    context: PropTypes.shape({
      menu: PropTypes.bool,
      createProduct: PropTypes.bool,
    }).isRequired,
  };

  static defaultProps = {
    areFiltersCollapsed: false,
    context: { menu: false },
  };

  filtersAction = {
    link: hash(routes.productCreate),
    label: i18n.t('Create a product'),
  };

  onCollapse = () => {
    const { areFiltersCollapsed, actions } = this.props;
    const collapsed = !areFiltersCollapsed;
    actions.collapseFilters({
      key: FILTERS_PANEL,
      collapsed,
    });
    actions.trackAction({
      action: 'filters_displayed',
      label: collapsed ? 'collapsed' : 'expanded',
    });
  };

  render() {
    const {
      areFiltersCollapsed,
      filtersQueryMap,
      selectedFilters,
      withCreateProduct,
      context,
    } = this.props;
    return (
      <ListFilters
        collapsed={areFiltersCollapsed}
        selectedFilters={
          selectedFilters ||
          flattenMap(filtersQueryMap).length ||
          Object.keys(filtersQueryMap).length
        }
        onCollapse={this.onCollapse}
        action={
          withCreateProduct && !context.menu && context.createProduct
            ? this.filtersAction
            : undefined
        }
      >
        <CatalogExpandedFilters
          filtersQueryMap={filtersQueryMap}
          context={context}
        />
      </ListFilters>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(CatalogFilters);
