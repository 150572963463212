import { moduleConst } from './shared';

// config
export const ROOMS_PER_PAGE = 9;
export const MESSAGES_PER_PAGE = 20;

// actions
export const FETCH_DATA = moduleConst('FETCH_DATA');
export const FETCH_DATA_ERROR = moduleConst('FETCH_DATA_ERROR');
export const ERASE_DATA = moduleConst('ERASE_DATA');
export const ON_SELECT_ROOM = moduleConst('ON_SELECT_ROOM');
export const SELECT_RECIPIENT = moduleConst('SELECT_RECIPIENT');
export const FILTER_RECIPIENTS = moduleConst('FILTER_RECIPIENTS');
export const SEND_MESSAGE = moduleConst('SEND_MESSAGE');
export const CHANGE_ROOM_PAGE = moduleConst('CHANGE_ROOM_PAGE');
export const LOAD_MORE_MESSAGES = moduleConst('LOAD_MORE_MESSAGES');

// events
export const SET_INITIAL_DATA = moduleConst('SET_INITIAL_DATA');
export const SET_MESSAGES_FOR_ROOM = moduleConst('SET_MESSAGES_FOR_ROOM');
export const SELECT_ROOM = moduleConst('SELECT_ROOM');
export const ADD_MESSAGE_TO_ROOM = moduleConst('ADD_MESSAGE_TO_ROOM');
export const ADD_MESSAGES_TO_ROOM = moduleConst('ADD_MESSAGES_TO_ROOM');
export const ADD_LOCAL_ROOM = moduleConst('ADD_LOCAL_ROOM');
export const CONVERT_TO_REMOTE_ROOM = moduleConst('CONVERT_TO_REMOTE_ROOM');
export const NOTIFY_COUNT_UNACKED = moduleConst('NOTIFY_COUNT_UNACKED');
export const ACK_ROOM = moduleConst('ACK_ROOM');
export const UPDATE_RECIPIENTS = moduleConst('UPDATE_RECIPIENTS');
export const UPDATE_ROOM = moduleConst('UDPATE_ROOM');
export const FETCH_ROOMS = moduleConst('FETCH_ROOMS');
export const ADD_ROOMS = moduleConst('ADD_ROOMS');
export const GO_TO_FIRST_PAGE = moduleConst('GO_TO_FIRST_PAGE');
export const SET_AS_FIRST_ROOM = moduleConst('SET_AS_FIRST_ROOM');
export const ADD_REMOTE_ROOM = moduleConst('ADD_REMOTE_ROOM');
export const NO_MORE_MESSAGES = moduleConst('NO_MORE_MESSAGES');

// attachments
export const ATTACHMENT_REMINDER = '1';

// client-server
export const FILTER_RECIPIENTS_REQUEST_START = moduleConst(
  'FILTER_RECIPIENTS_REQUEST_START'
);
export const FILTER_RECIPIENTS_REQUEST_END = moduleConst(
  'FILTER_RECIPIENTS_REQUEST_END'
);
