import { get } from 'utils/immutable';

import { allRequestAnswers } from './constants';
import { AssignationEvent } from './types';

export const getAnswerData = (data: AssignationEvent): [string, string] => {
  const statusReasons = get(data, 'status_reasons');
  let answerMessage = get(data, 'answer_message');
  let answerReason = get(data, 'status_reason');
  if (statusReasons !== undefined) {
    answerReason = get(statusReasons, 'reason');
    const answer = allRequestAnswers[answerReason];
    answerMessage = answer.getMessage(statusReasons);
  }
  return [answerMessage, answerReason];
};
