import { List, Map, fromJS } from 'immutable';
import { mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { ASSET_SCOPE, ASSET_STATUS, ASSET_TYPE } from 'constants/media';
import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { selectHasSpecificMediaFeature } from 'modules/feature-flag/selectors';
import {
  selectSharingUnits,
  selectSharingUnitsByRetailer,
  selectSharingUnitsByRetailerId,
} from 'modules/sharing-units/selectors';
import {
  selectPrimaryRecipients,
  selectSecondaryRecipients,
} from 'modules/view-as/selectors';
import { selectAssets, selectSpecificAssets } from 'reducers/media';
import {
  selectCanUpdateProduct,
  selectCanUpdateSharingUnit,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { byPath } from 'utils/sort';

import { MEDIA_KEY, REDUCER_KEY } from './constants';

export const selectModuleState = (state) =>
  state[MODULE_REDUCER_KEY][REDUCER_KEY];

export const selectHasSpecificFieldEdited = createSelector(
  selectModuleState,
  (state) => !!state && state.get('isDirty')
);

// -----------------------------------------------------------------------------
// selectors for specific assets

const add = (a, b) => a + b;

export const selectHasMainAssets = createSelector(
  selectAssets,
  (assets) =>
    [
      ASSET_TYPE.DOCUMENT,
      ASSET_TYPE.ENRICHED_CONTENT,
      ASSET_TYPE.PICTURE,
      ASSET_TYPE.VIDEO,
    ]
      .map((assetType) => get(assets, [assetType, 'length'], 0))
      .reduce(add, 0) > 0
);

const mapStatus = (assets) =>
  (assets || []).reduce(
    (acc, item) =>
      acc.set(
        item.id,
        Map().set('isActive', item.status === ASSET_STATUS.ACTIVE)
      ),
    Map()
  );

export const selectMasterAssetStatus = createSelector(
  selectAssets,
  (mainAssets) =>
    mapValues(mainAssets, (assets) =>
      assets.reduce(
        (acc, item) =>
          acc.set(
            item.id,
            Map().set('isActive', item.scope === ASSET_SCOPE.PUBLIC)
          ),
        Map()
      )
    )
);

export const selectAssetStatusByRecipient = createSelector(
  selectSpecificAssets,
  (specificAssets) =>
    specificAssets.reduce(
      (acc, item) =>
        acc.set(
          item.targetOrganization.id,
          fromJS(mapValues(item.data, mapStatus))
        ),
      Map()
    )
);

export const selectHasSpecificAssetsByRecipient = createSelector(
  selectAssetStatusByRecipient,
  (assetStatusByRecipient) => assetStatusByRecipient.map(() => true)
);

export const selectActivateAssetInProgress = createSelector(
  selectModuleState,
  (state) => state.get('toggleAssetStatusInProgress')
);

// -----------------------------------------------------------------------------
// Preparatory selectors: those ones only make sense to be used in the more
// complete ones below.
//
// For the exported selectors see the next section.
const selectOverridableFieldsByRecipient = createSelector(
  selectModuleState,
  selectHasMainAssets,
  selectHasSpecificMediaFeature,
  selectHasSpecificAssetsByRecipient,
  (
    state,
    hasMainAssets,
    hasSpecificMediaFeature,
    hasSpecificAssetsByRecipient
  ) =>
    state
      .get('overridableFieldsByRecipient', Map())
      .map((recipient) =>
        hasMainAssets &&
        hasSpecificMediaFeature &&
        !hasSpecificAssetsByRecipient.get(recipient.get('id'))
          ? recipient.update('fields', (fields) =>
              fields.push(Map({ model: MEDIA_KEY, label: i18n.t('Media') }))
            )
          : recipient
      )
      .map((recipient) => recipient.get('fields'), Map())
);
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Very simple selectors.
export const selectAllRecipientOverrides = createSelector(
  selectModuleState,
  (state) => (state ? state.get('dataOverrideByRecipient') : Map())
);

const selectAddedRecipients = createSelector(selectModuleState, (state) =>
  state.get('addedRecipients')
);

const selectRecipientIdUpdated = createSelector(selectModuleState, (state) =>
  state.get('recipientIdUpdated')
);
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Simple per recipient selectors.
export const selectSpecificDataByRecipient = createSelector(
  selectAllRecipientOverrides,
  (state) => state.map((recipient) => recipient.get('data', Map()))
);

export const selectAllRecipientSpecificData = createSelector(
  selectAllRecipientOverrides,
  (state) =>
    state.reduce((acc, value, recipientId) => {
      if (get(value, 'data')) {
        return acc.push(
          fromJS({
            targetOrganization: { id: recipientId },
            data: get(value, 'data'),
          })
        );
      }
      return acc;
    }, List())
);

export const selectSpecificFieldsByRecipient = createSelector(
  selectModuleState,
  (state) => state.get('fieldsByRecipient', Map())
);

export const selectSharingUnitsByRecipient = createSelector(
  selectSharingUnits,
  selectSharingUnitsByRetailerId,
  (sharingUnits, sharingUnitsByRetailerId) =>
    sharingUnitsByRetailerId.map((sharingUnitIds) =>
      sharingUnitIds.map((sharingUnitId) => sharingUnits.get(sharingUnitId))
    )
);

export const selectHasSharingUnitsByRecipient = createSelector(
  selectSharingUnitsByRetailer,
  (sharingUnitsByRetailer) =>
    sharingUnitsByRetailer.reduce(
      (acc, pair) =>
        acc.set(
          pair.getIn(['retailer', 'id']),
          pair.get('sharingUnits').size > 0
        ),
      Map()
    )
);

export const selectAllRecipients = createSelector(
  selectPrimaryRecipients,
  selectSecondaryRecipients,
  (primaryRecipients, secondaryRecipients) =>
    primaryRecipients.concat(secondaryRecipients).sort(byPath('id'))
);
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Per recipient selectors with a bit more work inside.
export const selectOverriddenFieldsByRecipient = createSelector(
  selectOverridableFieldsByRecipient,
  selectSpecificDataByRecipient,
  (overridableFieldsByRecipient, specificDataByRecipient) =>
    overridableFieldsByRecipient.map((fields, recipientId) => {
      if (!fields) {
        return null;
      }
      const data = specificDataByRecipient.get(recipientId, Map());
      if (!data) {
        return null;
      } // you never know
      const overriddenFieldNames = data.keySeq();
      return fields
        .filter((f) => overriddenFieldNames.includes(f.get('model')))
        .map((f) =>
          f.set('rank', overriddenFieldNames.indexOf(f.get('model')))
        );
    })
);

export const selectOverridableFieldsAvailableByRecipient = createSelector(
  selectOverridableFieldsByRecipient,
  selectSpecificDataByRecipient,
  (overridableFieldsByRecipient, specificDataByRecipient) =>
    overridableFieldsByRecipient.map((fields, recipientId) => {
      if (!fields) {
        return null;
      }
      const data = specificDataByRecipient.get(recipientId, Map());
      if (!data) {
        return null;
      } // you never know
      const overriddenFieldNames = data.keySeq();
      return fields
        .filter((f) => !overriddenFieldNames.includes(f.get('model')))
        .map((f) => ({
          id: f.get('model'),
          label: f.get('label'),
          value: f.get('model'),
        }))
        .toArray();
    })
);

export const selectCanHaveSpecificDataByRecipient = createSelector(
  selectOverriddenFieldsByRecipient,
  selectOverridableFieldsAvailableByRecipient,
  selectSpecificFieldsByRecipient,
  selectHasSpecificAssetsByRecipient,
  (
    overriddenFieldsByRecipient,
    overridableFieldsByRecipient,
    specificFieldsByRecipient,
    hasSpecificAssetsByRecipient
  ) =>
    overriddenFieldsByRecipient.map((overriddenFields, recipientId) => {
      const overridableFields = overridableFieldsByRecipient.get(recipientId);
      const specificFields = specificFieldsByRecipient.get(recipientId);
      const hasSpecificAssets = hasSpecificAssetsByRecipient.get(recipientId);
      return Boolean(
        (overridableFields && overridableFields.length > 0) ||
          (overriddenFields && overriddenFields.size > 0) ||
          (specificFields && specificFields.size > 0) ||
          hasSpecificAssets
      );
    })
);

export const selectCanHaveSpecificFieldsByRecipient = createSelector(
  selectSpecificFieldsByRecipient,
  (specificFieldsByRecipient) =>
    specificFieldsByRecipient.map(
      (specificFields) => !!specificFields && specificFields.size > 0
    )
);

export const selectHasSpecificAttributesByRecipient = createSelector(
  selectCanHaveSpecificDataByRecipient,
  selectSpecificDataByRecipient,
  selectAddedRecipients,
  (canHaveSpecificDataByRecipient, specificDataByRecipient, addedRecipients) =>
    canHaveSpecificDataByRecipient.map((canHaveSpecificData, recipientId) => {
      const data = specificDataByRecipient.get(recipientId, Map());

      return Boolean(
        canHaveSpecificData &&
          (addedRecipients.includes(recipientId) || (data && data.size > 0))
      );
    })
);

// Select recipients that the user is allowed to add, based on permissions.
// Exported for testing purposes only, used below 2 selectors.
export const filterAllowedRecipients = (
  recipients,
  canHaveSpecificDataByRecipient,
  hasSpecificAttributesByRecipient,
  hasSharingUnitsByRecipient,
  canUpdateProduct,
  canUpdateSharingUnit
) => {
  if (!canUpdateProduct && !canUpdateSharingUnit) {
    return List();
  }
  return recipients.filter(
    (recipient) =>
      !hasSpecificAttributesByRecipient.get(recipient.get('id')) &&
      !hasSharingUnitsByRecipient.get(recipient.get('id')) &&
      // Can add recipient for specific attributes.
      ((canUpdateProduct &&
        canHaveSpecificDataByRecipient.get(recipient.get('id'))) ||
        // Can add recipient for listing.
        (canUpdateSharingUnit && recipient.get('listing')))
  );
};

export const selectAllowedPrimaryRecipients = createSelector(
  selectPrimaryRecipients,
  selectCanHaveSpecificDataByRecipient,
  selectHasSpecificAttributesByRecipient,
  selectHasSharingUnitsByRecipient,
  selectCanUpdateProduct,
  selectCanUpdateSharingUnit,
  // Selector (see above):
  filterAllowedRecipients
);

export const selectAllowedSecondaryRecipients = createSelector(
  selectSecondaryRecipients,
  selectCanHaveSpecificDataByRecipient,
  selectHasSpecificAttributesByRecipient,
  selectHasSharingUnitsByRecipient,
  selectCanUpdateProduct,
  selectCanUpdateSharingUnit,
  // Selector (see above):
  filterAllowedRecipients
);

export const selectProductVersionSpecificDataToSave = createSelector(
  selectAllRecipientOverrides,
  selectRecipientIdUpdated,
  (specificFields, recipientIdUpdated) => {
    return specificFields
      .filter((_, targetOrganizationId) =>
        recipientIdUpdated.includes(targetOrganizationId)
      )
      .map((data, targetOrganizationId) => ({
        targetOrganization: { id: targetOrganizationId },
        data: data.get('data'),
      }))
      .toArray();
  }
);
