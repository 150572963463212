import { List, Map, OrderedMap, Record, Set } from 'immutable';
import { add, compose } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import {
  ADD_LOCAL_ROOM,
  ADD_MESSAGES_TO_ROOM,
  ADD_MESSAGE_TO_ROOM,
  ADD_REMOTE_ROOM,
  ADD_ROOMS,
  CHANGE_ROOM_PAGE,
  CONVERT_TO_REMOTE_ROOM,
  ERASE_DATA,
  FETCH_ROOMS,
  GO_TO_FIRST_PAGE,
  NO_MORE_MESSAGES,
  SELECT_ROOM,
  SET_AS_FIRST_ROOM,
  SET_INITIAL_DATA,
  SET_MESSAGES_FOR_ROOM,
  UPDATE_ROOM,
} from '../constants';

// ---------------------------------------------------------

const increment = (n) => n + 1;

const reduceToOrderedMap = (list) =>
  list.reduce((r, v) => r.set(v.get('id'), v), OrderedMap());

const getAlreadyAdded = (addedRooms, remoteRooms) =>
  Set.fromKeys(remoteRooms).intersect(Set.fromKeys(addedRooms));

// ---------------------------------------------------------

const setCurrentRoomPage = (page) => (state) =>
  state.set('currentRoomPage', page);

// ---------------------------------------------------------

const setTotalRemoteRooms = (totalRoomCount) => (state) =>
  state.set('totalRemoteRooms', totalRoomCount);

const increaseTotalRemoteRooms = () => (state) =>
  state.update('totalRemoteRooms', add(1));

// ---------------------------------------------------------

const addPendingRequest = () => (state) =>
  state.update('pendingRequests', (n) => n + 1);

const removePendingRequest = () => (state) =>
  state.update('pendingRequests', (n) => n - 1);

// ---------------------------------------------------------

const setRemoteRooms = (rooms) => (state) => {
  if (!rooms || rooms.size === 0) {
    return state;
  }
  const remoteRooms = reduceToOrderedMap(rooms);
  return state.set('remoteRooms', remoteRooms);
};

const addRemoteRooms = (rooms) => (state) => {
  const addedRooms = reduceToOrderedMap(rooms);
  const remoteRooms = state.get('remoteRooms');
  const alreadyAdded = getAlreadyAdded(addedRooms, remoteRooms);
  return state
    .mergeIn(['remoteRooms'], addedRooms)
    .update('fetchedAhead', (fetchedAhead) => fetchedAhead - alreadyAdded.size);
};

const addRemoteRoom = (room) => (state) =>
  state
    .update('remoteRooms', (remoteRooms) =>
      OrderedMap([[room.get('id'), room]]).concat(remoteRooms)
    )
    .update('fetchedAhead', increment);

const setAsFirstRoom = (roomId) => (state) =>
  state.update('remoteRooms', (remoteRooms) => {
    const room = state.getIn(['remoteRooms', roomId]);
    return OrderedMap([[roomId, room]]).concat(remoteRooms.remove(roomId));
  });

const updateRoom = (roomId, ackedAt, ackedBy, lastMessage) => (state) => {
  let newState = state;

  if (ackedAt && ackedBy) {
    newState = newState.setIn(
      ['remoteRooms', roomId, 'participant', 'acked_at'],
      ackedAt
    );
    newState = newState.setIn(
      ['remoteRooms', roomId, 'participant', 'acked_name'],
      ackedBy
    );
  }
  if (lastMessage) {
    newState = newState.setIn(
      ['remoteRooms', roomId, 'last_message'],
      lastMessage
    );
  }
  return newState;
};

// ---------------------------------------------------------

const addLocalRoom = (recipient) => (state) =>
  state.setIn(
    ['localRooms', recipient.get('id')],
    Map({
      id: recipient.get('id'),
      label: recipient.get('name'),
      organization_uuid: recipient.get('uuid'),
      local: true,
    })
  );

const deleteLocalRoom = (roomId) => (state) =>
  state.deleteIn(['localRooms', roomId]);

// ---------------------------------------------------------

const selectRoomId = (roomId) => (state) =>
  state.set('selectedRoomId', roomId || 0);

// ---------------------------------------------------------

const setLocalRoomIfOnlyOneRecipient = (rooms, recipients) => (state) => {
  if (rooms.size === 0 && recipients.size === 1) {
    return compose(
      selectRoomId(recipients.first().get('id')),
      addLocalRoom(recipients.first())
    )(state);
  } else {
    return state;
  }
};

// ---------------------------------------------------------

const setMessagesForRoom = (roomId, messages) => (state) => {
  if (messages && messages.size > 0) {
    return state.setIn(['messagesByRoomId', roomId], messages);
  } else {
    return state;
  }
};

const addMessageToRoom = (roomId, message) => (state) =>
  state.updateIn(['messagesByRoomId', roomId], (messages) =>
    (messages || List()).push(message)
  );

const addMessagesToRoom = (roomId, newMessages) => (state) =>
  state.updateIn(['messagesByRoomId', roomId], (messages) =>
    newMessages.concat(messages)
  );

// ---------------------------------------------------------

const setHasNoMoreMessages = (value) => (state) =>
  state.set('noMoreMessages', value);

// ---------------------------------------------------------

export const initialState = Record({
  currentRoomPage: 1,
  totalRemoteRooms: 0,
  pendingRequests: 0,
  remoteRooms: OrderedMap(),
  fetchedAhead: 0,
  localRooms: OrderedMap(),
  selectedRoomId: 0,
  messagesByRoomId: Map(),
  noMoreMessages: false,
})();

// ---------------------------------------------------------

const reducer = createReducer(initialState, {
  [SET_INITIAL_DATA]: (state, { payload }) =>
    compose(
      setLocalRoomIfOnlyOneRecipient(payload.rooms, payload.recipients),
      setRemoteRooms(payload.rooms),
      setTotalRemoteRooms(payload.totalRoomCount),
      selectRoomId(payload.selectedRoomId),
      setMessagesForRoom(payload.selectedRoomId, payload.messages)
    )(state),

  [ERASE_DATA]: () => initialState,

  [ADD_LOCAL_ROOM]: (state, { payload }) =>
    addLocalRoom(payload.recipient)(state),

  [CONVERT_TO_REMOTE_ROOM]: (state, { payload }) =>
    compose(
      deleteLocalRoom(payload.organizationId),
      addRemoteRoom(payload.room),
      increaseTotalRemoteRooms(),
      selectRoomId(payload.room.get('id'))
    )(state),

  [SELECT_ROOM]: (state, { payload }) =>
    compose(setHasNoMoreMessages(false), selectRoomId(payload.roomId))(state),

  [SET_MESSAGES_FOR_ROOM]: (state, { payload }) =>
    setMessagesForRoom(payload.roomId, payload.messages)(state),

  [ADD_MESSAGE_TO_ROOM]: (state, { payload }) =>
    addMessageToRoom(payload.roomId, payload.message)(state),

  [ADD_MESSAGES_TO_ROOM]: (state, { payload }) =>
    addMessagesToRoom(payload.roomId, payload.messages)(state),

  [UPDATE_ROOM]: (state, { payload }) =>
    updateRoom(
      payload.roomId,
      payload.ackedAt,
      payload.ackedBy,
      payload.lastMessage
    )(state),

  [CHANGE_ROOM_PAGE]: (state, { payload }) =>
    setCurrentRoomPage(payload.page)(state),

  [FETCH_ROOMS]: addPendingRequest(),

  [ADD_ROOMS]: (state, { payload }) =>
    compose(addRemoteRooms(payload.rooms), removePendingRequest())(state),

  [GO_TO_FIRST_PAGE]: setCurrentRoomPage(1),

  [SET_AS_FIRST_ROOM]: (state, { payload }) =>
    setAsFirstRoom(payload.roomId)(state),

  [ADD_REMOTE_ROOM]: (state, { payload }) => addRemoteRoom(payload.room)(state),

  [NO_MORE_MESSAGES]: setHasNoMoreMessages(true),
});

// ---------------------------------------------------------

// just for unit tests
export {
  setRemoteRooms,
  setMessagesForRoom,
  addLocalRoom,
  addMessageToRoom,
  setLocalRoomIfOnlyOneRecipient,
  addRemoteRoom,
};

// ---------------------------------------------------------

export default reducer;
