import classNames from 'classnames';
import memoize from 'memoize-one';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';

import {
  setAnchorNavigationSection,
  updateScrollPosition,
} from 'actions/navigation';
import { useQueryParams } from 'utils/routing';

import { AnchorNav } from './anchor-nav';
import './anchor-sticky-nav.scss';
import { NavItem } from './types';

const onNavItemClick = (onItemClick, itemId, dispatch) => {
  if (onItemClick) {
    onItemClick(itemId);
  }
  dispatch(setAnchorNavigationSection(itemId));
};

const getItems = memoize((anchors, items) => {
  return items
    .map((item) => {
      const matchingAnchor = anchors.find((anchor) =>
        item.id ? anchor.id === item.id : anchor.name === item.name
      );
      return {
        ...item,
        active: matchingAnchor ? matchingAnchor.active : false,
      };
    })
    .filter((item) => item);
});

const getHasLevel2 = (anchors, items) => {
  return getItems(anchors, items).some((item) => item.level === 2);
};

interface Props {
  title?: string | null;
  items?: NavItem[];
  stickyOffset?: number;
  stickyClass?: string;
  onItemClick?: () => void;
  children?: any;
}

const AnchorStickyNav = ({
  title = '',
  items = [],
  stickyOffset = 0,
  stickyClass = 'AnchorStickyNav__stickyElement--sticky',
  onItemClick,
  children,
}: Props) => {
  const dispatch = useDispatch();
  const anchors = useSelector((state: any) => state.navigation.anchors);
  const anchorNext = useSelector((state: any) => state.navigation.next);
  const anchorPrev = useSelector((state: any) => state.navigation.prev);
  const { section } = useQueryParams<{ section?: string }>();

  useEffect(() => {
    if (section) {
      onNavItemClick(onItemClick, section, dispatch);
    }
  }, [dispatch, onItemClick, section]);

  useEffect(() => {
    const onScrollListener = () => {
      const top = document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.body.scrollHeight || document.documentElement.scrollHeight;

      if (top < anchorPrev || top >= anchorNext) {
        dispatch(updateScrollPosition(anchors, top, height));
      }
    };

    window.addEventListener('scroll', onScrollListener);
    return () => window.removeEventListener('scroll', onScrollListener);
  }, [anchors, anchorNext, anchorPrev, dispatch]);

  return (
    <div className="AnchorStickyNav col-md-3">
      {title ? <h3>{title}</h3> : null}
      <StickyContainer className="AnchorStickyNav__stickyContainer">
        <Sticky
          topOffset={stickyOffset}
          className="AnchorStickyNav__stickyElement"
        >
          {({ isSticky, style }) => (
            <div
              className={classNames('AnchorStickyNav__stickyElement', {
                [stickyClass]: isSticky,
              })}
              style={style}
            >
              {children}
              <AnchorNav
                hasLevel2={getHasLevel2(anchors, items)}
                items={getItems(anchors, items)}
                onNavItemClick={(itemId) =>
                  onNavItemClick(onItemClick, itemId, dispatch)
                }
              />
            </div>
          )}
        </Sticky>
      </StickyContainer>
    </div>
  );
};

export default AnchorStickyNav;
