import Immutable from 'immutable';
import { memo, useCallback } from 'react';

import { reviewActionStatusFilter } from 'core/modules/list/constants/filters';
import { ACTION_STATUSES } from 'modules/product-review/constants';
import { ActionToReviewStatus } from 'modules/product-review/types';
import {
  FilterOnChange,
  FilterOnCollapse,
  FilterOptions,
  UserImmutable,
} from 'types';

import { SelectedFilterMap } from '../generic/types';
import { useQueryFilter } from '../generic/utils';
import ListSimpleFilter from '../simple';

export const filterList: FilterOptions = Immutable.fromJS(
  [
    ACTION_STATUSES[ActionToReviewStatus.TO_REVIEW],
    ACTION_STATUSES[ActionToReviewStatus.REVIEWING],
    ACTION_STATUSES[ActionToReviewStatus.CHANGE_REQUESTED],
    ACTION_STATUSES[ActionToReviewStatus.UPDATED],
  ].map((status) => ({
    ...status,
    iconClassName: `ProductReviewStatus--${status.id} mdi ${status.icon}`,
  }))
);

const { key, filterLabel } = reviewActionStatusFilter;

type Props = {
  collapsed: boolean;
  filterQueryValues: string[];
  onChange: FilterOnChange;
  onCollapse: FilterOnCollapse;
  selectedFilterMap?: SelectedFilterMap;
  user: UserImmutable;
};

export const ReviewActionStatusFilter = memo(
  ({
    collapsed,
    filterQueryValues,
    onChange,
    onCollapse,
    selectedFilterMap,
  }: Props) => {
    const onSelectStatus = useCallback<FilterOnChange>(
      (...args) => {
        onChange(...args);
      },
      [onChange]
    );

    const onLocalCollapse = useCallback(
      (isCollapsed) => {
        onCollapse(key, isCollapsed);
      },
      [onCollapse]
    );

    useQueryFilter({
      options: filterList,
      filter: { key, filterLabel },
      filterQueryValues,
      selectedFilterMap,
      onChange,
    });

    return (
      <ListSimpleFilter
        id="list-filter-review-action-status"
        filterList={filterList}
        filterKey={key}
        filterLabel={filterLabel}
        selectedFilterMap={selectedFilterMap}
        onChange={onSelectStatus}
        collapsed={collapsed}
        onCollapse={onLocalCollapse}
      />
    );
  }
);
