import { isRetailer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_CATALOG_FILTER_MULTI_SOURCE } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import { i18n } from 'utils/i18n';

export const duplicatedProductsFilter = {
  key: 'hasDuplicated',
  query: 'duplicated',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_duplicated_products.label',
    { defaultValue: 'Duplicated products' }
  ),
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    isRetailer(user) && hasFeature(user, FEATURE_CATALOG_FILTER_MULTI_SOURCE),
  hasDuplicatedKey: 'hasDuplicated',
};
