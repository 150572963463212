import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import { Ribbon } from '@alkem/react-ui-ribbon';
import { Tooltip } from '@alkem/react-ui-tooltip';
import type { Referential } from '@alkem/sdk-dashboard';

import { notificationError, notificationSuccess } from 'actions/notification';
import { Anchor } from 'components/ui/basic/anchor';
import { Modal } from 'components/ui/modal';
import { backgroundCheckContentTypes } from 'constants/assets';
import {
  ANGLE_HORIZONTAL,
  ANGLE_VERTICAL,
  FILE_TYPE,
  PRODUCT_FACE,
} from 'constants/productPicture';
import { loadReferentials } from 'modules/assets/actions';
import { selectReferentials } from 'modules/assets/selectors';
import {
  selectHasJsonStoreInputFeature,
  selectHasReleaseProductpageFlywheelMedia,
  selectHasSpecificMediaFeature,
  selectHasSpecificPackshotFeature,
} from 'modules/feature-flag/selectors';
import mediaApi from 'resources/mediaApi';
import { Exportable } from 'types/media';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import moment from 'utils/moment';

import { FieldAction, PictureAssetV2 } from '../AssetV2/types';
import { PictureModal } from '../actions/picture/picture-modal';

import './picture.scss';

export interface PictureProps {
  asset: PictureAssetV2;
  productKeyId: string | number;
  updateEntities: (type: string, id?: number) => void;
  updateSpecificEntities?: (
    assetId: number,
    recipientId: number,
    data: any
  ) => void;
  readOnly?: boolean;
  action?: FieldAction; // action from DG for update modal.
  settingNewPackshot: (packshot: { newPackshotId: number | null }) => void;
  recipientId?: number;
  newPackshotId: number | null;
  deleting: boolean;
  onDelete: (arg: boolean) => void;
  selected?: boolean;
  onSelect: (assetId: number, checked: boolean) => void;
  specificPicture?: boolean;
  index: number;
}

export const PictureAsset = memo((props: PictureProps) => {
  const dispatch = useDispatch();
  const {
    picturetypeofcontents = [],
  }: { picturetypeofcontents: Referential[] } = useSelector(selectReferentials);
  const hasSpecificMediaFeature = useSelector(selectHasSpecificMediaFeature);
  const hasSpecificPackshotFeature = useSelector(
    selectHasSpecificPackshotFeature
  );
  const hasJsonStoreInputFeature = useSelector(selectHasJsonStoreInputFeature);

  const hasReleaseProductpageFlywheelMedia = useSelector(
    selectHasReleaseProductpageFlywheelMedia
  );

  const {
    action,
    selected = false,
    readOnly = false,
    specificPicture = false,
    asset,
    onSelect,
    recipientId,
    updateSpecificEntities,
    updateEntities,
    onDelete,
    productKeyId,
    index,
    newPackshotId,
    deleting,
    settingNewPackshot,
  } = props;
  const { isPackshot } = asset;

  const [deleteModalState, setDeleteModalState] = useState(false);
  const [confirmPackshot, setConfirmPackshot] = useState(false);
  const [confirmSpecificPackshot, setConfirmSpecificPackshot] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [newSpecificPackshotId, setNewSpecificPackshotId] = useState<
    number | null
  >(null);

  const startDateValue = hasJsonStoreInputFeature
    ? asset.pictureEffectiveStartDate
    : asset.fileEffectiveStartDateTime;
  const endDateValue = hasJsonStoreInputFeature
    ? asset.pictureEffectiveEndDate
    : asset.fileEffectiveEndDateTime;
  const productFaceValue = hasJsonStoreInputFeature
    ? get(asset, ['productFaceCode', 'id'], null)
    : asset.productFace;
  const verticalAngleValue = hasJsonStoreInputFeature
    ? get(asset, ['pictureAngleVerticalCode', 'id'], null)
    : asset.angleVertical;
  const horizontalAngleValue = hasJsonStoreInputFeature
    ? get(asset, ['pictureAngleHorizontalCode', 'id'], null)
    : asset.angleHorizontal;
  const fileTypeValue = hasJsonStoreInputFeature
    ? get(asset, ['fileTypeCode', 'id'], null)
    : asset.fileType;
  const contentTypeValue = hasJsonStoreInputFeature
    ? get(asset, ['fileContentTypeCode', 'id'], null)
    : asset.contentType;

  const isArchived =
    (startDateValue && Date.parse(startDateValue) > Date.now()) ||
    (endDateValue && Date.parse(endDateValue) < Date.now());

  const contentTypeLabel = get(
    picturetypeofcontents.find((e) => e.id === contentTypeValue),
    'label'
  );

  useEffect(() => {
    if (picturetypeofcontents.length) {
      return;
    }
    dispatch(loadReferentials());
  }, [dispatch, picturetypeofcontents]);

  const _onSelect = (checked) => {
    onSelect(asset.id, checked);
  };

  const onConfirmSetAsPackshot = async () => {
    setConfirmPackshot(false);
    settingNewPackshot({ newPackshotId: asset.id });
    try {
      await mediaApi.ProductPictureUpdate({
        id: asset.id,
        productVersion_id: asset.productVersion_id,
        product_id: asset.product_id,
        isPackshot: true,
        scope: 'public',
      });
      settingNewPackshot({ newPackshotId: null });
      updateEntities('ProductPicture', asset.id);
      dispatch(
        notificationSuccess(
          i18n.t(
            'frontproducstream.asset_list.picture.update_packshot_notification.success',
            { defaultValue: 'Image has been set as packshot' }
          )
        )
      );
    } catch {
      settingNewPackshot({ newPackshotId: null });
      dispatch(
        notificationError(
          i18n.t(
            'frontproducstream.asset_list.picture.update_packshot_notification.error',
            { defaultValue: 'Image could not be set as packshot' }
          )
        )
      );
    }
  };

  const setAsPackshot = () => {
    if (hasSpecificMediaFeature && asset.scope !== 'public') {
      setConfirmPackshot(true);
    } else {
      onConfirmSetAsPackshot();
    }
  };

  const setAsSpecificPackshot = async () => {
    setConfirmSpecificPackshot(true);
  };

  const onConfirmSetAsSpecificPackshot = async () => {
    setConfirmSpecificPackshot(false);
    setNewSpecificPackshotId(asset.id);

    if (!recipientId || !updateSpecificEntities) {
      dispatch(
        notificationError(
          i18n.t(
            'frontproducstream.asset_list.picture.update_specific_packshot_notification.error',
            { defaultValue: 'Image could not be set set as specific packshot' }
          )
        )
      );

      return;
    }

    try {
      const newSpecificPackshot = await mediaApi.UpsertSpecificPictureData(
        asset.id,
        recipientId,
        {
          status: 1,
          isPackshot: true,
        }
      );
      setNewSpecificPackshotId(null);
      // Update the state of the application (media.assets.specificData)
      updateSpecificEntities(
        asset.id,
        recipientId,
        newSpecificPackshot.data.data
      );
      dispatch(
        notificationSuccess(
          i18n.t(
            'frontproducstream.asset_list.picture.update_specific_packshot_notification.success',
            { defaultValue: 'Image has been set as specific packshot' }
          )
        )
      );
    } catch {
      dispatch(
        notificationError(
          i18n.t(
            'frontproducstream.asset_list.picture.update_specific_packshot_notification.error',
            { defaultValue: 'Image could not be set set as specific packshot' }
          )
        )
      );
    }
  };

  const onCancelSetAsSpecificPackshot = () => {
    setConfirmSpecificPackshot(false);
  };

  const onCancelSetAsPackshot = () => {
    setConfirmPackshot(false);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    updateEntities('ProductPicture');
    hideModal();
  };

  const openModal = () => {
    setOpen(true);
  };

  const hideDeleteConfirmationModal = () => {
    setDeleteModalState(false);
  };

  const openDeleteConfirmationModal = () => {
    setDeleteModalState(true);
  };

  const deleteEntity = () => {
    hideDeleteConfirmationModal();
    if (asset.isPackshot) {
      return dispatch(
        notificationError(
          i18n.t(
            'frontproducstream.asset_list.picture.delete_notification.packshot_error',
            {
              defaultValue:
                'Choose an other packshot before deleting the current one.',
            }
          )
        )
      );
    }
    onDelete(true);
    return mediaApi.EntityPictureDelete(asset.id).then(
      () => {
        updateEntities(asset._type);
        onDelete(false);
        return dispatch(
          notificationSuccess(
            i18n.t(
              'frontproducstream.asset_list.picture.delete_notification.success',
              { defaultValue: 'Image deleted' }
            )
          )
        );
      },
      (error) => {
        onDelete(false);
        if (error.status === 403) {
          return dispatch(
            notificationError(
              i18n.t(
                'frontproducstream.asset_list.picture.delete_notification.permission_error',
                {
                  defaultValue:
                    "You don't have the permission to delete this image",
                }
              )
            )
          );
        }
        return dispatch(
          notificationError(
            i18n.t('frontproducstream.asset_list.delete_notification.error', {
              defaultValue: 'An error occured:',
            }) + error.message
          )
        );
      }
    );
  };

  const renderEditModal = (title) => {
    return (
      <PictureModal
        title={title}
        fieldActionItems={action?.items}
        count={-1}
        media={asset}
        productKeyId={productKeyId}
        readOnly={readOnly}
        onClose={hideModal}
        onSuccess={onSuccess}
        index={index}
      />
    );
  };

  const renderArchivedTag = () => {
    const message =
      `${i18n.t(
        'frontproducstream.asset_list.picture.archived.valid_from_tooltip',
        { defaultValue: 'Valid from' }
      )} ${moment(startDateValue).format('LL')}${'<br/>'}` +
      `${i18n.t(
        'frontproducstream.asset_list.picture.archived.expires_on_tooltip',
        { defaultValue: 'Expires on' }
      )} ${moment(endDateValue).format('LL')}`;
    return (
      isArchived && (
        <div
          className="Asset__archivedTag"
          data-tip={message}
          data-for={`picture-${asset.id}-tooltip`}
        >
          <span>
            {i18n.t('frontproducstream.asset_list.picture.archived.label', {
              defaultValue: 'Archived',
            })}
          </span>
          <Tooltip id={`picture-${asset.id}-tooltip`} />
        </div>
      )
    );
  };

  const renderEffectiveDates = (
    fileEffectiveStartDateTime,
    fileEffectiveEndDateTime
  ) => {
    let text: string | null = null;
    if (fileEffectiveStartDateTime) {
      text = i18n.t(
        'frontproducstream.asset_list.picture.effective_date.from_text',
        {
          defaultValue: 'From {{fileEffectiveStartDateTime}}',
          fileEffectiveStartDateTime: moment(fileEffectiveStartDateTime).format(
            'LL'
          ),
        }
      );
      if (fileEffectiveEndDateTime) {
        text = i18n.t(
          'frontproducstream.asset_list.picture.effective_date.from_to_text',
          {
            defaultValue:
              'From {{fileEffectiveStartDateTime}} to {{fileEffectiveEndDateTime}}',
            fileEffectiveStartDateTime: moment(
              fileEffectiveStartDateTime
            ).format('LL'),
            fileEffectiveEndDateTime: moment(fileEffectiveEndDateTime).format(
              'LL'
            ),
          }
        );
      }
    }

    return (
      text && (
        <p className="Asset__tight Asset__tight--effectiveDates"> {text} </p>
      )
    );
  };

  const renderFileSize = (fileSize) => {
    const fileSizeKB = fileSize / 1000;
    if (fileSizeKB > 0) {
      return (
        <p className="Asset__tight--fileSize">
          {i18n.t('frontproducstream.asset_list.picture.file_size.text', {
            defaultValue: '{{fileSize}} kB',
            fileSize: Math.round(fileSizeKB),
          })}
        </p>
      );
    }
    return null;
  };

  const renderContentType = () => {
    return (
      <p className="Asset__tight Asset__tight--contentType">
        {contentTypeLabel}
      </p>
    );
  };

  const renderFileType = (fileType) => {
    return (
      <p className="Asset__tight Asset__tight--fileType">
        {get(
          FILE_TYPE.find((e) => e.id === fileType),
          'label'
        )}
      </p>
    );
  };

  const renderDefinition = (height, width) => {
    if (height && width) {
      return height >= 1500 || width >= 1500 ? (
        <p className="Asset__tight Asset__tight--definition">
          {width}x{height}
        </p>
      ) : (
        <div
          className="Asset__tight--Error"
          data-testid="PictureDefinitionWarning"
        >
          <i className="mdi mdi-alert" />
          <span>
            {width}x{height}
          </span>
        </div>
      );
    }
    return null;
  };

  const renderTags = (tags) => {
    if (!Array.isArray(tags)) {
      return null;
    }
    return <p className="Asset__tags">{tags.join(', ')}</p>;
  };

  const renderAngles = (productFace, angleVertical, angleHorizontal) => {
    const angles = [
      get(
        PRODUCT_FACE.find((e) => e.id === productFace),
        'label'
      ),
      get(
        ANGLE_VERTICAL.find((e) => e.id === angleVertical),
        'label'
      ),
      get(
        ANGLE_HORIZONTAL.find((e) => e.id === angleHorizontal),
        'label'
      ),
    ]
      .filter((x) => !!x)
      .join(', ');
    return <p className="Asset__tight"> {angles} </p>;
  };

  const renderTransparency = () => {
    if (backgroundCheckContentTypes.includes(contentTypeValue)) {
      return asset.backgroundScore && asset.backgroundScore >= 75 ? (
        <span className="Asset__tight--transparency">
          {i18n.t(
            'frontproductstream.asset_list.picture.transparency_ok.text',
            { defaultValue: 'Transparent or white background' }
          )}
        </span>
      ) : (
        <div
          className="Asset__tight--Error"
          data-testid="PictureTransparencyWarning"
        >
          <i className="mdi mdi-alert" />
          <span>
            {i18n.t(
              'frontproductstream.asset_list.picture.transparency_ko.text',
              { defaultValue: 'Background neither transparent nor white' }
            )}
          </span>
        </div>
      );
    }
    return null;
  };

  const renderButtons = () => {
    if (specificPicture) {
      return (
        <div>
          {hasSpecificPackshotFeature && !isArchived && (
            <div
              id={`setpackshot-picture-${asset.id}`}
              className="set-as-packshot-button"
            >
              <Button
                primary
                content={
                  isPackshot
                    ? i18n.t(
                        'frontproductstream.asset_list.picture.specific_main_picture_button.label',
                        { defaultValue: 'Defined as specific main image' }
                      )
                    : i18n.t(
                        'frontproductstream.asset_list.picture.not_specific_main_picture_button.label',
                        { defaultValue: 'Set as specific main image' }
                      )
                }
                onClick={setAsSpecificPackshot}
                disabled={isPackshot || !!newPackshotId}
                displaySpinner={
                  Boolean(newSpecificPackshotId) &&
                  newSpecificPackshotId === asset.id
                }
                className={classNames({ 'Asset__btn--inactive': isPackshot })}
              />
            </div>
          )}
        </div>
      );
    }

    if (readOnly) {
      return (
        <div
          className="Asset__button--rightMargin"
          id={`view-picture-data-${asset.id}`}
        >
          <Button
            primary
            content={i18n.t('frontproductstream.asset_list.view_button.label', {
              defaultValue: 'View',
            })}
            onClick={openModal}
            className="Button--editPicture"
          />
          {isOpen &&
            renderEditModal(
              i18n.t('frontproductstream.asset_list.picture_modal.view_title', {
                defaultValue: 'View picture',
              })
            )}
        </div>
      );
    }

    return (
      <div>
        {!isArchived && (
          <div
            id={`setpackshot-picture-${asset.id}`}
            className="set-as-packshot-button"
          >
            <Button
              primary
              content={
                isPackshot
                  ? i18n.t(
                      'frontproductstream.asset_list.picture.main_picture_button.label',
                      { defaultValue: 'Defined as main image' }
                    )
                  : i18n.t(
                      'frontproductstream.asset_list.picture.not_main_picture_button.label',
                      { defaultValue: 'Set as main image' }
                    )
              }
              onClick={setAsPackshot}
              disabled={isPackshot || !!newPackshotId || deleting}
              displaySpinner={!!newPackshotId && newPackshotId === asset.id}
              className={classNames({ 'Asset__btn--inactive': isPackshot })}
            />
          </div>
        )}
        <div className="Asset__buttons">
          <div
            className="Asset__button--rightMargin"
            id={`edit-picture-${asset.id}`}
          >
            <Button
              secondary
              content={i18n.t(
                'frontproductstream.asset_list.edit_button.label',
                { defaultValue: 'Edit' }
              )}
              onClick={openModal}
              disabled={!!newPackshotId || deleting}
              className="Button--editPicture"
            />
            {isOpen &&
              renderEditModal(
                i18n.t(
                  'frontproductstream.asset_list.picture_modal.edit_title',
                  { defaultValue: 'Edit picture' }
                )
              )}
          </div>
          <div id={`delete-picture-${asset.id}`}>
            <div
              className="Asset__button--rightMargin"
              data-tip
              data-for={`delete-packshot-${asset.id}`}
            >
              <Button
                secondary
                content={i18n.t(
                  'frontproductstream.asset_list.delete_button.label',
                  { defaultValue: 'Delete' }
                )}
                onClick={openDeleteConfirmationModal}
                disabled={!!newPackshotId || deleting || asset.isPackshot}
                displaySpinner={deleting}
                className="Button--deletePicture"
              />
              {asset.isPackshot && (
                <Tooltip place="bottom" id={`delete-packshot-${asset.id}`}>
                  {i18n.t(
                    'frontproductstream.asset_list.picture.delete_button.packshot_tooltip',
                    { defaultValue: 'Packshot cannot be deleted.' }
                  )}
                </Tooltip>
              )}
            </div>
            {deleteModalState && (
              <Modal
                modalStyle="dynamic"
                title={i18n.t(
                  'frontproductstream.asset_list.delete_picture_modal.title',
                  { defaultValue: 'Delete picture' }
                )}
                onConfirm={deleteEntity}
                onClose={hideDeleteConfirmationModal}
              >
                {i18n.t(
                  'frontproductstream.asset_list.delete_picture_modal.content',
                  {
                    defaultValue:
                      'Are you sure you want to delete this picture ?',
                  }
                )}
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  };

  let assetToDisplay: Exportable | undefined;
  if (Array.isArray(asset.exportables)) {
    assetToDisplay = asset.exportables.find((e) => e.width === 256);
  }
  if (!assetToDisplay) {
    assetToDisplay = asset;
  }

  const packshot = isPackshot ? (
    <Ribbon
      value={i18n.t('frontproductstream.asset_list.picture.packshot.ribbon', {
        defaultValue: 'packshot',
      })}
    />
  ) : null;

  return (
    <div
      className={classNames({
        Asset__packshot: packshot,
        Asset: hasReleaseProductpageFlywheelMedia,
      })}
      data-asset-crc32={asset.crc32}
    >
      {!readOnly && (
        <Checkbox
          id={`checkbox-picture-${asset.id}`}
          label=""
          checked={selected}
          onChange={_onSelect}
          disabled={!!newPackshotId}
        />
      )}
      <div>
        <Anchor
          href={asset.uniformResourceIdentifier}
          className={classNames({
            Asset__image: !hasReleaseProductpageFlywheelMedia,
            'Asset__image-rfp': hasReleaseProductpageFlywheelMedia,
          })}
        >
          <img
            className={classNames({ 'Asset__image--archived': isArchived })}
            src={assetToDisplay?.uniformResourceIdentifier}
            alt=""
          />
        </Anchor>
        {!hasReleaseProductpageFlywheelMedia && packshot}
      </div>
      {hasReleaseProductpageFlywheelMedia ? (
        <>
          <div className="Asset__main-info">
            {renderArchivedTag()}
            {renderContentType()}
            {renderEffectiveDates(startDateValue, endDateValue)}
            {renderAngles(
              productFaceValue,
              verticalAngleValue,
              horizontalAngleValue
            )}
            {renderFileType(fileTypeValue)}
            {renderTags(asset.tags)}
          </div>
          <div className="Asset__secondary-info">
            <p className="Asset__tight Asset__dateAdd">
              {i18n.t('frontproductstream.asset_list.creation_date.text', {
                defaultValue: 'Added on',
              })}{' '}
              {moment(asset.createdAt).format('LL')}
            </p>
            {renderDefinition(asset.height, asset.width)}
            {asset.backgroundScore !== null && renderTransparency()}
            {renderFileSize(asset.fileSize)}
          </div>
          {renderButtons()}
        </>
      ) : (
        <div className="Asset__imageBody">
          {renderArchivedTag()}
          {renderContentType()}
          {renderEffectiveDates(startDateValue, endDateValue)}
          {renderAngles(
            productFaceValue,
            verticalAngleValue,
            horizontalAngleValue
          )}
          {renderFileType(fileTypeValue)}
          {renderTags(asset.tags)}
          <br />
          <p className="Asset__tight Asset__dateAdd">
            {i18n.t('frontproductstream.asset_list.creation_date.text', {
              defaultValue: 'Added on',
            })}{' '}
            {moment(asset.createdAt).format('LL')}
          </p>
          {renderDefinition(asset.height, asset.width)}
          {asset.backgroundScore !== null && renderTransparency()}
          {renderFileSize(asset.fileSize)}
          {renderButtons()}
        </div>
      )}
      {confirmPackshot && (
        <Modal
          modalStyle="dynamic"
          title={i18n.t(
            'frontproductstream.asset_list.update_packshot_picture_modal.title',
            { defaultValue: 'Confirm' }
          )}
          confirmButtonText={i18n.t(
            'frontproductstream.asset_list.update_packshot_picture_modal.button',
            { defaultValue: 'Set as packshot' }
          )}
          onConfirm={onConfirmSetAsPackshot}
          onClose={onCancelSetAsPackshot}
        >
          {i18n.t(
            'frontproductstream.asset_list.update_packshot_picture_modal.content',
            { defaultValue: 'Image will be shared with all recipients' }
          )}
        </Modal>
      )}
      {confirmSpecificPackshot && (
        <Modal
          modalStyle="dynamic"
          title={i18n.t(
            'frontproductstream.asset_list.update_specific_packshot_picture_modal.title',
            { defaultValue: 'Confirm' }
          )}
          confirmButtonText={i18n.t(
            'frontproductstream.asset_list.update_specific_packshot_picture_modal.button',
            { defaultValue: 'Set as packshot' }
          )}
          onConfirm={onConfirmSetAsSpecificPackshot}
          onClose={onCancelSetAsSpecificPackshot}
        >
          {i18n.t(
            'frontproductstream.asset_list.update_specific_packshot_picture_modal.content',
            { defaultValue: 'Image will be shared with the specific recipient' }
          )}
        </Modal>
      )}
    </div>
  );
});

export default PictureAsset;
