import { serviceProductApi } from 'resources';

import { ProductVersion, retailerIDsForUnshare } from '../types';

const unshare =
  (pv: ProductVersion, recipientId: number | null) => async () => {
    const pkid = pv.getIn(['product_key', 'id']);
    if (!pkid) {
      throw new Error('Missing product key in product version');
    }

    let targetOrganizationIds;
    if (recipientId === null) {
      // not specified : unshare with all
      targetOrganizationIds = retailerIDsForUnshare(pv);
      if (!targetOrganizationIds.length) {
        throw new Error(
          'No retailer to unshare with was found in product version'
        );
      }
    } else {
      targetOrganizationIds = [recipientId];
    }

    let result;
    try {
      result = await serviceProductApi.unshareVersion(
        [pkid],
        targetOrganizationIds
      );
    } catch (e: any) {
      // give the funnel a propre Error for reporting failure reason
      throw Error(e.data.message);
    }
    return result;
  };

export default unshare;
