import { useSelector } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';

import FeatureManufacturerRecipeModal from 'modules/feature-flag/components/manufacturer-recipe/modal';
import { selectHasRecipeFeature } from 'modules/feature-flag/selectors';
import { sanitize } from 'utils/DOMPurify';
import i18n from 'utils/i18n';

import RecipeModal from '../../actions/recipe/recipe-modal';
import { RecipeAssetV2 } from '../types';

type RecipeModalSwitchProps = {
  onClose: () => void;
  asset: RecipeAssetV2;
  isReadOnly: boolean;
};

export const RecipeModalSwitch = ({
  onClose,
  isReadOnly,
  asset,
}: RecipeModalSwitchProps) => {
  const hasRecipeFeature = useSelector(selectHasRecipeFeature);

  if (isReadOnly) {
    return (
      <Modal
        title={asset.title}
        modalStyle="fullHeight"
        onClose={onClose}
        hideFooter
      >
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(asset.content),
          }}
        />
      </Modal>
    );
  }

  if (!hasRecipeFeature) {
    return <FeatureManufacturerRecipeModal onClose={onClose} />;
  }

  return (
    <RecipeModal
      title={i18n.t(
        'frontproductstream.asset_list.enriched_content_modal.title',
        { defaultValue: 'Edit enriched content' }
      )}
      media={asset}
      productKeyId={asset.product_key_id}
      onSuccess={onClose}
      onClose={onClose}
    />
  );
};
