import Immutable from 'immutable';
import moment from 'moment';

import { Button } from '@alkem/react-ui-button';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import { MessageIdToMessageInfo } from 'constants/message';
import {
  TargetProductStatusNotAssigned,
  TargetProductStatusPendingAssignation,
} from 'constants/targetProductStatus';
import {
  getProductProductKeyId,
  getProductVersionTargetProductStatus,
} from 'core/api/productversion';
import * as routes from 'routes';
import { get } from 'utils/immutable';
import { withQuery } from 'utils/query';
import { fill, hash } from 'utils/routing';

import './index.scss';

interface Props {
  data: Immutable.Map<string, any>;
  productVersionId: number;
  productVersion: Immutable.Map<string, any>;
  isRetailer: boolean;
}

function MessageColumn({
  data,
  productVersionId,
  productVersion,
  isRetailer,
}: Props) {
  const statusList = get(data, ['status']);
  if (!statusList) {
    return null;
  }

  const withLink = ![
    TargetProductStatusNotAssigned.id,
    TargetProductStatusPendingAssignation.id,
  ].includes(getProductVersionTargetProductStatus(productVersion));
  const lastMessageDate = get(data, ['last_message_date']);
  const lastMessageFormat =
    lastMessageDate != null ? `${moment(lastMessageDate).format('L')}` : '';

  const statuses = statusList.map((status: string) => {
    const key = `${productVersionId}_${status}`;
    const MessageStatus = MessageIdToMessageInfo[status];
    return (
      <LazyTooltip
        key={key}
        id={key}
        className={`CatalogMessageColumn CatalogMessageColumn--${MessageStatus.id}`}
        tooltipLabel={MessageStatus.name}
      >
        <i
          className={`mdi ${MessageStatus.icon}`}
          data-testid="message-status-icon"
        />
      </LazyTooltip>
    );
  });

  return (
    <div className="CatalogMessageCell">
      {withLink ? (
        <Button
          href={hash(
            withQuery(
              fill(
                isRetailer ? routes.productDashboard : routes.productChat,
                getProductProductKeyId(productVersion)
              )
            )
          )}
          testid="link-to-product-dashboard"
        >
          {statuses}
        </Button>
      ) : (
        statuses
      )}
      <span className="CatalogMessageColumnDate">{lastMessageFormat}</span>
    </div>
  );
}

MessageColumn.center = true;

export default MessageColumn;
