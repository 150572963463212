import { Map as ImmutableMap } from 'immutable';
import { ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import Dropdown from 'components/ui/dropdown';
import {
  getProductProductKeyId,
  getSourceProductKeyId,
} from 'core/api/productversion';
import { isRetailer, isSuperAdmin, isSuperAdminLoggedAs } from 'core/api/user';
import {
  downloadComplianceReport,
  openExportModal,
} from 'modules/catalog/product/actions';
import { selectExportInProgress } from 'modules/catalog/product/selectors';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_COMPLIANCE_REPORT_EXPORT } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';

export interface Props {
  user: UserImmutable;
  selectedMap: ImmutableMap<number, boolean>;
  productMap: ImmutableMap<number, unknown>;
  nbFilteredProducts: number; // number of product results displayed after filtering in the catalog
}

interface DropdownOptionSpec {
  key: string;
  label: ReactNode;
  onClick: () => void;
  available: boolean;
}

export const CatalogExportButton = (props: Props) => {
  const dispatch = useDispatch();
  const inProgress: boolean = useSelector(selectExportInProgress);

  const { user, nbFilteredProducts, selectedMap, productMap } = props;

  const [dropdownLabel, selectedPKids]: [string, number[]] = useMemo(() => {
    // if some products are selected we operate on the selection
    // if none are selected we'll operate on all products

    const pkIdGetter = isRetailer(user)
      ? getSourceProductKeyId
      : getProductProductKeyId;

    const pkids = selectedMap
      .filter((v) => !!v)
      .keySeq()
      .toJS()
      .map((pvId: number) => pkIdGetter(productMap.get(pvId)));

    return [
      i18n.t('Export {{count}} sheet(s)', {
        count: pkids.length || nbFilteredProducts,
      }),
      pkids,
    ];
  }, [user, productMap, selectedMap, nbFilteredProducts]);

  if (nbFilteredProducts < 1) return null; // ideally the parent should not render this component in that case

  const userIsuperAdmin = isSuperAdmin(user) || isSuperAdminLoggedAs(user);
  const userHasComplianceReportFeature = hasFeature(
    user,
    FEATURE_COMPLIANCE_REPORT_EXPORT
  );

  let compilanceReportActionLabel: ReactNode = i18n.t('Compliance report');

  if (userIsuperAdmin && !userHasComplianceReportFeature) {
    // use can do this action only because he's superadmin
    compilanceReportActionLabel = (
      <span className="availableByBeingSuperAdmin">
        {compilanceReportActionLabel} (alk-admin)
      </span>
    );
  }

  const specs: DropdownOptionSpec[] = [
    {
      key: 'products',
      label: i18n.t('Export products'),
      onClick: () => dispatch(openExportModal()),
      available: true,
    },
    {
      key: 'compliance report',
      label: compilanceReportActionLabel,
      onClick: () =>
        dispatch(downloadComplianceReport({ productKeyIds: selectedPKids })),
      available: userIsuperAdmin || userHasComplianceReportFeature,
    },
  ].filter(({ available = true }) => available);

  switch (specs.length) {
    case 0:
      return null;
    case 1: {
      // dropdown would introduce useless click level
      const [{ onClick }] = specs;
      return (
        <Button
          secondary
          className="CatalogExportButton"
          onClick={onClick}
          content={dropdownLabel}
          disabled={inProgress}
          displaySpinner={inProgress}
        />
      );
    }
    default:
  }

  const options = specs.map(({ key, label, onClick }) => ({
    key,
    label: (
      <div key={key} className="ActionOption" onClick={onClick}>
        {label}
      </div>
    ),
  }));

  return (
    <Dropdown
      label={dropdownLabel}
      options={options}
      disabled={inProgress}
      closeDropdownOnClickElement
      rightDropdown
      withoutWrappers
    />
  );
};
