import { flow } from 'lodash/fp';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hasListing } from 'core/api/organization-settings';
import RecipientSpecificInformation from 'modules/recipient-specific-block';
import { RetailerRecipientValidation } from 'modules/recipient-specific-block/components/validation';
import {
  selectCanReadSharingUnit,
  selectCanValidateSharingUnitUpdates,
} from 'reducers/productVersion';
import {
  selectIsRetailer,
  selectOrganizationSettings,
  selectUser,
} from 'reducers/user/selectors';

import { hasFeatureProductReview } from '../../../product-review/utils';
import { acceptListingInit } from '../../modules/product-share-diff/actions';
import { selectApplyRequestsInProgress } from '../../modules/product-share-diff/selectors';
import {
  SharingUnitsAgreed,
  SharingUnitsInit,
  SharingUnitsRefused,
} from '../../modules/retailer-sharing-units';

const selectHasListing = flow(selectOrganizationSettings, hasListing);

function SharingUnits() {
  const dispatch = useDispatch();
  const isRetailer = useSelector(selectIsRetailer);
  const applyRequestsInProgress = useSelector(selectApplyRequestsInProgress);
  const canReadSharingUnit = useSelector(selectCanReadSharingUnit);
  const canValidateSharingUnitUpdates = useSelector(
    selectCanValidateSharingUnitUpdates
  );
  const hasListingSetting = useSelector(selectHasListing);
  const user = useSelector(selectUser);
  const hasFeatureReviewEngine = hasFeatureProductReview(user);

  const onAcceptListingInit = useCallback(
    (...args) => {
      dispatch(acceptListingInit(...args));
    },
    [dispatch]
  );

  if (isRetailer) {
    if (hasListingSetting === false || canReadSharingUnit === false) {
      return <RetailerRecipientValidation />;
    }
    return (
      <>
        <RetailerRecipientValidation />
        <SharingUnitsInit
          acceptListingInit={onAcceptListingInit}
          applyRequestsInProgress={applyRequestsInProgress}
          isReadOnly={!canValidateSharingUnitUpdates}
          order={2000}
          hasFeatureReviewEngine={hasFeatureReviewEngine}
        />
        <SharingUnitsAgreed order={3000} />
        <SharingUnitsRefused order={4000} />
      </>
    );
  } else {
    return <RecipientSpecificInformation />;
  }
}

export default SharingUnits;
