import memoize from 'memoize-one';

import Autocomplete from 'components/ui/autocomplete/index';
import authApi from 'resources/authApi';

export const searchRecipients = async (
  input: string,
  filters: object = {
    limit: 20,
  }
) => {
  const { data } = await authApi.RecipientsList({
    filters: {
      nameLegal: input,
      ...filters,
    },
    sort: {
      by: 'nameLegal',
      order: 'asc',
    },
  });
  return data;
};

export class RecipientLegacyAutocomplete extends Autocomplete {
  getLabel(organization) {
    return organization.nameLegal;
  }

  fetchRecipients = memoize(searchRecipients);

  getList(input) {
    return this.fetchRecipients(input, {
      worksWithOrganization: false,
      limit: 20,
    }).then((data) => {
      this.updateList(
        data.map((organization) => ({
          label: this.getLabel(organization),
          id: organization.id,
        }))
      );
    });
  }
}

export default RecipientLegacyAutocomplete;
