import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { SwitchButton } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';

import './index.scss';

export default class PublicationSummaryNetworkVisibility extends PureComponent {
  static propTypes = {
    isExclusive: PropTypes.bool.isRequired,
    saveVisibility: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isExclusive: null,
  };

  state = {
    exclusivityChanging: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isExclusive !== this.props.isExclusive) {
      this.setState({ exclusivityChanging: false }); // eslint-disable-line
    }
  }

  switchExclusivity = (isSelected) => {
    this.setState({ exclusivityChanging: true });
    this.props.saveVisibility(isSelected, false);
  };

  render() {
    const { isExclusive } = this.props;
    return (
      <div className="GroupPublicationSummary GroupPublicationSummary--visibility">
        <div className="GroupPublicationSummaryHeader">
          <span className="GroupPublicationSummaryHeader__GroupName">
            {i18n.t(
              'frontproductstream.publication_summary.product_visibility.title',
              { defaultValue: 'Product visibility' }
            )}
          </span>
          <div
            id="publication-summary-make-exclusive"
            className="PublicationSummaryNetworkVisibility__action alk-flex alk-flex-center"
            data-is-exclusive={isExclusive}
          >
            <span>
              {i18n.t(
                'frontproductstream.publication_summary.switch_product_visibility.text',
                { defaultValue: 'Make the product exclusive' }
              )}
            </span>
            <SwitchButton
              onChange={this.switchExclusivity}
              checked={isExclusive}
              disabled={this.state.exclusivityChanging}
            />
          </div>
        </div>
        <div className="GroupPublicationSummaryMembers">
          <div className="GroupPublicationSummaryMember GroupPublicationSummaryMember--noColor">
            <span className="PublicationSummaryNetworkVisibility__description">
              {i18n.t(
                'frontproductstream.publication_summary.product_visibility.text',
                {
                  defaultValue:
                    'Per default, your basic product information can be made available to all retailers that ask for it. If your product is exclusive to some retailers, then opt-out by activating this switch.',
                }
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
