import { PureComponent } from 'react';

import { LegalIdentifier } from '@alkem/sdk-dashboard/src/types/legalidentifier';

import { Body, Cell, Header, Row, Table } from 'components/ui/table';
import LegalIdentifierApi from 'resources/legalIdentifierApi';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { logError } from 'utils/logging';

import { AddLegalIdentifier } from './add-legal-identifier';
import DeleteLegalIdentifier from './delete-legal-identifier';
import './legal-identifier-list.scss';

const messages = {
  help: i18n.t('frontproductstream.organization_legal_identifiers.list.help', {
    defaultValue: 'Add here the legal identifiers for the company',
  }),
};

interface State {
  legalIdentifiers: LegalIdentifier[];
}

export class OrganizationLegalIdentifiersList extends PureComponent<{}, State> {
  private initialLegalIdentifiers: LegalIdentifier[] = [];

  public state = {
    legalIdentifiers: this.initialLegalIdentifiers,
  };

  private isCancelled = false;

  public componentDidMount() {
    this.fetchLegalIdentifiers();
  }

  public componentWillUnmount() {
    this.isCancelled = true;
  }

  private fetchLegalIdentifiers = async () => {
    try {
      const response = await LegalIdentifierApi.LegalIdentifierList();
      if (!this.isCancelled && get(response, 'data.data')) {
        this.setState({ legalIdentifiers: response.data.data });
      }
    } catch (error) {
      logError(error);
    }
  };

  public render() {
    const { legalIdentifiers } = this.state;
    return (
      <div className="OrganizationLegalIdentifierList">
        <h2 className="OrganizationLegalIdentifierList__title">
          {i18n.t('Legal Identifiers')}
        </h2>
        <div className="card card-block ">
          <p className="OrganizationLegalIdentifierList__Detail">
            {messages.help}
          </p>
          <Table>
            <Header>
              <th>
                {i18n.t(
                  'frontproductstream.organization_legal_identifiers.list_header_type.text',
                  {
                    defaultValue: 'Type',
                  }
                )}
              </th>
              <th>
                {i18n.t(
                  'frontproductstream.organization_legal_identifiers.list_header_number.text',
                  {
                    defaultValue: 'Number',
                  }
                )}
              </th>
              <th>
                {i18n.t(
                  'frontproductstream.organization_legal_identifiers.list_header_actions.text',
                  {
                    defaultValue: 'Actions',
                  }
                )}
              </th>
            </Header>
            <Body>
              {legalIdentifiers.map((legalIdentifier) => (
                <Row key={legalIdentifier.id}>
                  <Cell>{legalIdentifier.type}</Cell>
                  <Cell>{legalIdentifier.value}</Cell>
                  <Cell>
                    <DeleteLegalIdentifier
                      id={legalIdentifier.id}
                      label={`${legalIdentifier.type} ${legalIdentifier.value}`}
                      onDelete={this.fetchLegalIdentifiers}
                    />
                  </Cell>
                </Row>
              ))}
            </Body>
          </Table>
          <AddLegalIdentifier
            onClose={this.fetchLegalIdentifiers}
            title={i18n.t(
              'frontproductstream.organization_legal_identifiers.add_title.text',
              {
                defaultValue: 'Add a Legal Identifier',
              }
            )}
            buttonText={i18n.t(
              'frontproductstream.organization_legal_identifiers.add_button.text',
              {
                defaultValue: 'Add',
              }
            )}
          />
        </div>
      </div>
    );
  }
}
