import { createSelector } from 'reselect';

import { sharingUnitsSelector } from '.';

export const getDisplayGroups = createSelector(sharingUnitsSelector, (state) =>
  state.get('displayGroupsByTargetOrganization')
);

export const getTariffDisplayGroups = createSelector(
  sharingUnitsSelector,
  (state) => state.get('tariffDisplayGroupsByTargetOrganization')
);
