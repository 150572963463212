import { List, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { RECEIVE_PROFILE } from 'constants/events/user';
import { getOrganizationFields } from 'core/api/organization';
import { isRetailer, isThirdParty } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_VIEWAS_SELECTOR } from 'modules/feature-flag/constants';
import { compareNames } from 'utils';
import { get } from 'utils/immutable';

import {
  VIEW_AS_ADD_RECIPIENT,
  VIEW_AS_FILTER_RULESETS_FIELDS,
  VIEW_AS_INIT,
  VIEW_AS_LOADING,
  VIEW_AS_RECEIVE_ALWAYS_DISPLAYED_FIELDS,
  VIEW_AS_RECEIVE_RULESETS,
  VIEW_AS_REMOVE_RECIPIENT,
  VIEW_AS_SELECT_RULESET,
  VIEW_AS_SET_PRESELECTED,
  VIEW_AS_UNSELECT_RULESET,
} from './events';

const getInitialState = (
  preselectedRecipients,
  preselectedRuleSetIds,
  ruleSets
) =>
  fromJS({
    preselectedRecipients,
    preselectedRuleSetIds,
    recipients: List(),
    loading: false,
    ruleSets: ruleSets || [],
    filterRuleSetsFields: false,
    alwaysVisibleFields: List(),
  });

/* **********************
  Helpers.
  Those functions are exported solely for testing purposes.
 ********************** */

export const computePreselection = (
  recipients,
  preselectedRecipients,
  preselectedRuleSetIds,
  ruleSets,
  user
) => {
  let newState = getInitialState(
    preselectedRecipients,
    preselectedRuleSetIds,
    ruleSets
  );
  // Retailer cases.
  if (isRetailer(user) || isThirdParty(user)) {
    return newState;
  }

  const _formatRecipient = (recipient) =>
    fromJS({
      id: get(recipient, 'id'),
      label: get(recipient, 'nameLegal'),
      fields: getOrganizationFields(recipient),
      usesFields: get(recipient, 'usesFields'),
      worksOnTargetMarkets: get(recipient, 'worksOnTargetMarkets'),
      listing: recipient.get('listing'),
      'listing.hierarchy': recipient.get('listing.hierarchy'),
      settings: recipient.get('settings'),
      checked:
        !!preselectedRecipients &&
        preselectedRecipients.includes(get(recipient, 'id')),
      export_synchronization_status: recipient.get(
        'export_synchronization_status'
      ),
      group: recipient.get('group'),
    });

  newState = newState.set(
    'recipients',
    recipients.map(_formatRecipient).sort(compareNames)
  );
  return newState;
};

/* **********************
  Reducer.
  Those functions are exported solely for testing purposes.
 ********************** */

export const setPreselected = (state, { recipientsIds, ruleSetIds }) =>
  state
    .set('preselectedRecipients', fromJS(recipientsIds))
    .set('preselectedRuleSetIds', fromJS(ruleSetIds));

export const init = (
  state,
  { recipients, preselectedRecipients, preselectedRuleSetIds, user }
) => {
  const ruleSets = state.get('ruleSets');
  const filterRuleSetsFields = state.get('filterRuleSetsFields');

  // Restore the filterRuleSetsFields
  return computePreselection(
    recipients,
    preselectedRecipients,
    preselectedRuleSetIds,
    ruleSets,
    user
  ).set('filterRuleSetsFields', filterRuleSetsFields);
};

const toggleRecipient =
  (checked) =>
  (state, { recipient }) => {
    const allIndex = state
      .get('recipients')
      .findIndex((r) => r.get('id') === recipient.get('id'));
    if (allIndex === -1) {
      return state;
    }

    const newState = state.setIn(['recipients', allIndex, 'checked'], checked);
    return newState;
  };

export const addRecipient = toggleRecipient(true);
export const removeRecipient = toggleRecipient(false);

const isAlreadySelected = (ruleSets, ruleSetId) => {
  const index = ruleSets.findIndex((r) => r.get('id') === ruleSetId);
  if (index === -1) {
    return false;
  }

  return ruleSets.getIn([index, 'checked']);
};

export const receiveRuleSets = (state, { ruleSets }) => {
  // Better safe than sorry.
  if (!ruleSets) {
    return state;
  }

  const newState = state.set(
    'ruleSets',
    fromJS(ruleSets).map((r) =>
      r.set(
        'checked',
        isAlreadySelected(state.get('ruleSets'), r.get('id')) ||
          (state.get('preselectedRuleSetIds') || []).includes(r.get('id'))
      )
    )
  );

  return newState;
};

const updateRuleSet =
  (checked) =>
  (state, { ruleSet }) => {
    const index = state
      .get('ruleSets')
      .findIndex((r) => r.get('id') === ruleSet.get('id'));
    if (index === -1) {
      return state;
    }

    const newState = state.setIn(['ruleSets', index, 'checked'], checked);
    return newState;
  };
export const addRuleSet = updateRuleSet(true);
export const removeRuleSet = updateRuleSet(false);

export const setFilterRuleSetsFields = (state, { filter }) => {
  const newState = state.set('filterRuleSetsFields', filter);
  return newState;
};

export default createReducer(getInitialState(), {
  [VIEW_AS_SET_PRESELECTED]: setPreselected,
  [VIEW_AS_INIT]: init,
  [VIEW_AS_LOADING]: (state, { loading }) => state.set('loading', loading),
  [VIEW_AS_ADD_RECIPIENT]: addRecipient,
  [VIEW_AS_REMOVE_RECIPIENT]: removeRecipient,
  // Rule sets
  [VIEW_AS_RECEIVE_RULESETS]: receiveRuleSets,
  [VIEW_AS_SELECT_RULESET]: addRuleSet,
  [VIEW_AS_UNSELECT_RULESET]: removeRuleSet,
  [VIEW_AS_FILTER_RULESETS_FIELDS]: setFilterRuleSetsFields,
  [RECEIVE_PROFILE]: (state, { user }) => {
    const filter =
      !isRetailer(user) && hasFeature(user, FEATURE_VIEWAS_SELECTOR);
    return setFilterRuleSetsFields(state, { filter, user });
  },
  [VIEW_AS_RECEIVE_ALWAYS_DISPLAYED_FIELDS]: (state, { fields }) =>
    state.set('alwaysVisibleFields', fields),
});
