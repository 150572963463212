import { FORM_FIELD_UPDATED, UPDATE_ENTITY } from 'constants/events/entity';
import { createActionWithPayload } from 'utils/redux';

export const updateEntity = (
  key: string,
  value: any,
  entityId: number | string,
  entityKind: string,
  isDirty = true,
  ignoreField = true,
  assetId?: number
) => ({
  type: UPDATE_ENTITY,
  key,
  value,
  entityId,
  entityKind,
  isDirty,
  ignoreField,
  assetId,
});

export const formFieldUpdated =
  createActionWithPayload<string>(FORM_FIELD_UPDATED);
