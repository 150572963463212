import { Map } from 'immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { unshareProduct, unshareProductDone } from '../../actions';
import {
  selectIsUnsharingRecipient,
  selectRecipientToUnshare,
} from '../../selectors';

import './modal.scss';

interface ConnectedProps {
  recipient: Map<any, any>;
  isUnsharing: boolean;
}

interface Actions {
  unshareProduct: typeof unshareProduct;
  unshareProductDone: typeof unshareProductDone;
}

interface Props extends ConnectedProps {
  actions: Actions;
}

const mapStateToProps = createStructuredSelector<
  ConnectedProps,
  ConnectedProps
>({
  recipient: selectRecipientToUnshare,
  isUnsharing: selectIsUnsharingRecipient,
});

const mapDispatchToProps: Actions = {
  unshareProduct,
  unshareProductDone,
};

function PublicationSummaryUnshareModal({
  recipient,
  actions,
  isUnsharing,
}: Props) {
  return (
    <Modal
      modalStyle="dynamic"
      className="PublicationSummaryUnshareModal"
      title={i18n.t(
        'frontproductstream.publication_summary.unshare_modal.title',
        { defaultValue: 'Unshare product' }
      )}
      confirmButtonText={i18n.t(
        'frontproductstream.publication_summary.confirm_unshare_modal.button',
        {
          defaultValue: 'Unshare from {{recipientName}}',
          recipientName: recipient.get('nameLegal'),
        }
      )}
      onConfirm={actions.unshareProduct}
      onClose={actions.unshareProductDone}
      isProcessing={isUnsharing}
      danger
    >
      <p>
        {i18n.t('frontproductstream.publication_summary.unshare_modal.text', {
          defaultValue:
            'This action is only available to Alkemics support and should be done only with the approval of both the manufacturer and the retailer (ISO27001)',
        })}
      </p>
    </Modal>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(PublicationSummaryUnshareModal);
