import classNames from 'classnames';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AddButton } from '@alkem/react-ui-button';

import { selectTargetMarketId } from 'reducers/productVersion';
import i18n from 'utils/i18n';

import AddLabel from '../addlabel';
import ConceptLabel from '../concept-label';
import { Label } from '../types';

import './list.scss';

const mapStateToProps = (state) => ({
  targetMarketId: selectTargetMarketId(state),
});

interface LabelListProps {
  id: string;
  labels: Label[];
  kindId?: number;
  targetMarketId: number;
  searchPlaceholder?: string;
  disabled?: boolean;
  small?: boolean;
  onChange(labels: Label[]): void;
}

export class LabelList extends PureComponent<LabelListProps> {
  static defaultProps = {
    id: 'LabelList',
    disabled: false,
    small: false,
    searchPlaceholder: i18n.t(
      'frontproductstream.labels.list.search.placeholder',
      { defaultValue: 'Search labels' }
    ),
  };

  state = {
    adding: false,
  };

  onDelete = (item: Label) => {
    const { labels, onChange } = this.props;
    if (labels.length === 0) {
      return;
    }
    const newLabels = labels.filter((label) => label !== item);
    onChange(newLabels);
  };

  openModal = () => {
    this.setState({ adding: true });
  };

  closeModal = () => {
    this.setState({ adding: false });
  };

  render() {
    const {
      id,
      labels,
      kindId,
      targetMarketId,
      searchPlaceholder,
      disabled,
      small,
      onChange,
    } = this.props;
    const itemClasses = {
      LabelList__item: true,
      'LabelList__item--small': small,
    };
    const displayedLabelList = labels.map(
      (label) =>
        label.isConceptualizedBy?.id && (
          <div
            className={classNames(itemClasses)}
            key={label.isConceptualizedBy.id}
          >
            <div className="LabelList__item__content">
              <ConceptLabel
                label={label}
                onClickDelete={this.onDelete}
                disabled={disabled}
              />
            </div>
          </div>
        )
    );

    if (!kindId && !disabled) {
      displayedLabelList.push(
        <div className="LabelList__kindWarning" key="kind-warning">
          {i18n.t('frontproductstream.labels.list.select_category.text', {
            defaultValue: 'You need to select a category before adding labels',
          })}
        </div>
      );
    } else if (!disabled) {
      displayedLabelList.push(
        <div
          className={classNames({ ...itemClasses, LabelList__button: true })}
          key="add-label"
        >
          <AddButton
            label={i18n.t('frontproductstream.labels.list.add_button.label', {
              defaultValue: 'Add other labels',
            })}
            onClick={this.openModal}
          />
        </div>
      );
    }

    return (
      <div className="LabelList" id={id}>
        {displayedLabelList}
        {this.state.adding && (
          <AddLabel
            productKindId={kindId}
            targetMarketId={targetMarketId}
            close={this.closeModal}
            addLabels={onChange}
            selectedLabels={labels}
            searchPlaceholder={searchPlaceholder}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(LabelList);
