function onSelectRoom(selectedRoomId, rooms) {
  if (rooms.size > 0 && rooms.has(selectedRoomId)) {
    return rooms.setIn([selectedRoomId, 'selected'], true);
  }
  return rooms;
}

function getRoomLabel(selectedRoom) {
  if (selectedRoom) {
    return (
      // for remote rooms
      selectedRoom.getIn(['other_participant', 'name']) ||
      // for local rooms
      selectedRoom.get('label')
    );
  } else {
    // should not happen
    return '';
  }
}

const isLocalRoom = (room) => room.get('local');

/**
 * Calculate offset and limit for pre-loading rooms for the next page so we
 * always have current page and next page loaded.
 */
function prefetchRooms(
  roomsPerPage,
  currentPage,
  localRooms,
  remoteRoomsFetched,
  totalRemoteRooms,
  pendingRequests = 0,
  fetchedAhead = 0
) {
  // total rooms needed to fill all pages until next one
  const filledNextPage = (currentPage + 1 - pendingRequests) * roomsPerPage;

  // need from server, until next page minus local ones, not more than total
  const need = Math.min(filledNextPage - localRooms, totalRemoteRooms);

  // all rooms already fetched + pages in progress
  const have = remoteRoomsFetched - fetchedAhead;

  // calculate how many rooms need to be loaded,
  // if we have more rooms than needed, set to zero
  const roomsToLoad = Math.max(0, need - have);

  if (roomsToLoad === 0) {
    return { roomsToLoad };
  } else if (pendingRequests > 0) {
    // if we have pending requests, recalculate for next page
    // (only return a value for pendingRequests zero)
    return prefetchRooms(
      roomsPerPage,
      currentPage,
      localRooms,
      remoteRoomsFetched + roomsToLoad,
      totalRemoteRooms,
      pendingRequests - 1,
      fetchedAhead
    );
  } else {
    const offset = have;
    return { roomsToLoad, offset };
  }
}

export { onSelectRoom, getRoomLabel, isLocalRoom, prefetchRooms };
