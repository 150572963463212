import classNames from 'classnames';
import { Map } from 'immutable';
import { flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import { withUserType } from 'hocs';
import { AnchoredSection } from 'modules/anchored';
import { selectHasSuggestionBlockFeature } from 'modules/feature-flag/selectors';
import {
  selectCanUpdateProduct,
  selectProductVersionId,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { isImmutable, toJsIfImmutable } from 'utils/immutable';
import { track } from 'utils/tracking';

import {
  acceptAllSuggestions,
  acceptSuggestion,
  dismissAllSuggestions,
  dismissSuggestion,
} from './actions';
import './product-suggestions.scss';
import {
  selectAcceptingAll,
  selectDismissingAll,
  selectFieldsBeingUpdated,
  selectSuggestions,
  selectSuggestionsBeingAccepted,
  selectSuggestionsBeingDismissed,
} from './selectors';

const mapStateToProps = createStructuredSelector({
  canUpdateProduct: selectCanUpdateProduct,
  hasSuggestionBlock: selectHasSuggestionBlockFeature,
  diffs: selectSuggestions,
  fieldsBeingUpdated: selectFieldsBeingUpdated,
  suggestionsBeingAccepted: selectSuggestionsBeingAccepted,
  suggestionsBeingDismissed: selectSuggestionsBeingDismissed,
  acceptingAll: selectAcceptingAll,
  dismissingAll: selectDismissingAll,
  entityId: selectProductVersionId,
});

const mapDispatchToProps = {
  accept: acceptSuggestion,
  dismiss: dismissSuggestion,
  acceptAll: acceptAllSuggestions,
  dismissAll: dismissAllSuggestions,
};

const empty = (
  <span className="ProductSuggestion__DiffEmpty">{i18n.t('(empty)')}</span>
);

const enhance = flow(
  connect(mapStateToProps, mapDispatchToProps),
  withUserType
);

export class ProductSuggestions extends PureComponent {
  static propTypes = {
    order: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,

    canUpdateProduct: PropTypes.bool.isRequired,
    hasSuggestionBlock: PropTypes.bool.isRequired,
    isManufacturer: PropTypes.bool.isRequired,

    diffs: ImmutablePropTypes.list.isRequired,

    fieldsBeingUpdated: ImmutablePropTypes.set.isRequired,
    suggestionsBeingAccepted: ImmutablePropTypes.set.isRequired,
    suggestionsBeingDismissed: ImmutablePropTypes.set.isRequired,

    acceptingAll: PropTypes.bool.isRequired,
    dismissingAll: PropTypes.bool.isRequired,

    accept: PropTypes.func.isRequired,
    dismiss: PropTypes.func.isRequired,
    acceptAll: PropTypes.func.isRequired,
    dismissAll: PropTypes.func.isRequired,
  };

  onAcceptSuggestion = (ruleResult) => {
    this.props.accept(ruleResult);
    this.trackEvent('accept', ruleResult);
  };

  onDismissSuggestion = (ruleResult) => {
    this.props.dismiss(ruleResult);
    this.trackEvent('dismiss', ruleResult);
  };

  onAcceptAllSuggestions = () => {
    this.props.acceptAll();
    this.trackEvent('accept_all');
  };

  onDismissAllSuggestions = () => {
    this.props.dismissAll();
    this.trackEvent('dismiss_all');
  };

  trackEvent(event, ruleResult = Map()) {
    const { entityId } = this.props;
    const rule = Map(ruleResult);
    const suggestedValue = rule.getIn(['data', 'suggestedValue']);
    let value = '';
    if (['string', 'number', 'boolean'].includes(typeof suggestedValue)) {
      value = suggestedValue;
    } else if (isImmutable(suggestedValue)) {
      value = suggestedValue.get('label') || suggestedValue.get('data') || '';
    }
    track({
      category: 'product',
      action: `product_suggestion_block_${event}`,
      label: `product_version#${entityId}`,
      fieldmetadata: rule.get('fieldmetadataId'),
      value: toJsIfImmutable(value),
    });
  }

  renderActions(diff) {
    const {
      fieldsBeingUpdated,
      suggestionsBeingAccepted,
      suggestionsBeingDismissed,
      acceptingAll,
      dismissingAll,
    } = this.props;

    if (acceptingAll || dismissingAll) {
      return (
        <div className="ProductSuggestions__busy">
          {i18n.t('Updating...')}
          <Spinner small />
        </div>
      );
    }

    if (suggestionsBeingAccepted.includes(diff.id)) {
      return (
        <div className="ProductSuggestions__busy">
          {i18n.t('Accepting...')}
          <Spinner small />
        </div>
      );
    }

    if (suggestionsBeingDismissed.includes(diff.id)) {
      return (
        <div className="ProductSuggestions__busy">
          {i18n.t('Dismissing...')}
          <Spinner small />
        </div>
      );
    }

    if (fieldsBeingUpdated.includes(diff.model)) {
      return (
        <div className="ProductSuggestions__busy">
          {i18n.t('Updating field...')}
          <Spinner small />
        </div>
      );
    }

    return (
      <>
        <Button
          link
          content={i18n.t('Ignore')}
          className="ProductSuggestions__dismiss"
          onClick={() => this.onDismissSuggestion(diff.rule)}
        />
        <Button
          secondary
          content={i18n.t('Accept')}
          className="ProductSuggestions__accept"
          onClick={() => this.onAcceptSuggestion(diff.rule)}
        />
      </>
    );
  }

  render() {
    const {
      order,
      canUpdateProduct,
      hasSuggestionBlock,
      isManufacturer,
      diffs,
      acceptingAll,
      dismissingAll,
    } = this.props;

    if (
      !canUpdateProduct ||
      !hasSuggestionBlock ||
      !isManufacturer ||
      !diffs ||
      diffs.size === 0
    ) {
      return null;
    }

    return (
      <AnchoredSection
        collapsible
        section={i18n.t('Suggestions')}
        id="suggestionstovalidate"
        order={order}
      >
        <div className="ProductSuggestion__Diff">
          <div className="ProductSuggestion__DiffHeader row">
            <div className="col-xs-3">{i18n.t('Field name')}</div>
            <div className="col-xs-3">{i18n.t('Current value')}</div>
            <div className="col-xs-3">{i18n.t('Suggestions')}</div>
          </div>
          <div>
            {diffs
              .map((diff) => (
                <div
                  key={diff.field.label}
                  className="ProductSuggestion__DiffLine row"
                >
                  <div className="col-xs-3">{diff.field.label}</div>
                  <div className="col-xs-3">
                    {diff.current.length ? (
                      <ul className="ProductSuggestion__CurrentList">
                        {diff.current.map((value) => (
                          <li
                            key={value}
                            className={classNames(
                              'ProductSuggestion__CurrentItem',
                              diff.current.length === 1 &&
                                'ProductSuggestion__CurrentItem--single'
                            )}
                          >
                            {value}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      empty
                    )}
                  </div>
                  <div className="col-xs-6">
                    {diff.suggestions.map((suggestion, index) => (
                      <div
                        key={suggestion.id}
                        className={classNames(
                          'ProductSuggestion__SuggestionItem',
                          index === diff.suggestions.size - 1 &&
                            'ProductSuggestion__SuggestionItem--last'
                        )}
                      >
                        <div className="col-xs-6 ProductSuggestion__suggestionMessage">
                          {suggestion.message}
                        </div>
                        <div className="col-xs-6 ProductSuggestion__DiffActions">
                          {this.renderActions(suggestion)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
              .toArray()}
          </div>
          <div className="ProductSuggestions__buttons">
            <Button
              link
              className="ProductSuggestions__dismissAll"
              content={i18n.t('Ignore all suggestions')}
              onClick={this.onDismissAllSuggestions}
              disabled={acceptingAll || dismissingAll}
            />
            <Button
              primary
              className="ProductSuggestions__acceptAll"
              content={i18n.t('Accept all suggestions')}
              onClick={this.onAcceptAllSuggestions}
              disabled={acceptingAll || dismissingAll}
              displaySpinner={acceptingAll}
            />
          </div>
        </div>
      </AnchoredSection>
    );
  }
}

export default enhance(ProductSuggestions);
