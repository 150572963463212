import { useSelector } from 'react-redux';

import { LeavePagePrompt } from 'components/prompt/LeavePagePrompt';
import { selectHasLogisticalUnitEdited } from 'modules/logistical-hierarchies/selectors';
import { selectHasSpecificFieldEdited } from 'modules/recipient-specific-block/selectors';
import { selectHasEditedSharingUnit } from 'modules/sharing-units/selectors';
import { selectIsDirty } from 'reducers/productVersion';
import i18n from 'utils/i18n';

export const LeavingConfirmation = () => {
  const hasProductVersionEdited = Boolean(useSelector(selectIsDirty));
  const hasSharingUnitEdited: boolean = useSelector(selectHasEditedSharingUnit);
  const hasLogisticalUnitEdited: boolean = useSelector(
    selectHasLogisticalUnitEdited
  );
  const hasSpecificFieldEdited: boolean = useSelector(
    selectHasSpecificFieldEdited
  );

  return (
    <LeavePagePrompt
      when={
        hasProductVersionEdited ||
        hasSharingUnitEdited ||
        hasLogisticalUnitEdited ||
        hasSpecificFieldEdited
      }
    >
      <p>
        {i18n.t(
          'Some changes you made have not been saved yet. Are you sure you want to leave the page?'
        )}
      </p>
    </LeavePagePrompt>
  );
};

export default LeavingConfirmation;
