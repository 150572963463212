import { isFunction } from 'lodash/fp';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Authentication } from '@alkem/sdk-dashboard';

import { resetLoading } from 'actions/navigation';
import ComponentSequentialWorkflow from 'components/component-sequential-workflow';
import PageTop from 'layouts/PageTop';
import {
  UserProfileModal,
  isLegalIdentifiersModalVisible,
  isUserProfileModalVisible,
} from 'modules/admin/users';
import { selectIsAuthorized, tryAuthorization } from 'modules/auth/shared';
import { ConsentManagerChecker } from 'modules/consent-manager';
import IdlenessTracker from 'modules/idle/tracker';
import { AddLegalIdentifier } from 'modules/organization-legalidentifiers';
import { selectUser } from 'reducers/user/selectors';
import * as routes from 'routes';
import { DocumentationHandler } from 'utils/documentation';
import { shouldResetScroll } from 'utils/history';
import i18n from 'utils/i18n';
import { withQuery } from 'utils/query';

import { PageView } from './PageView';
import PublicRoutes from './Public';

export function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const locationRef = useRef(location);
  const user = useSelector(selectUser);
  const isAuthorized = useSelector(selectIsAuthorized);

  useEffect(() => {
    Authentication.addLogoutListener(() => {
      navigate(withQuery(routes.logout, { reason: 'expired_token' }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetLoading());
    if (
      !isAuthorized &&
      !PublicRoutes.some(
        (route) =>
          location.pathname !== routes.landing &&
          matchPath(route.path, location.pathname)
      )
    ) {
      dispatch(tryAuthorization(location));
    }
    if (isAuthorized && isFunction(global.Appcues?.page)) {
      global.Appcues.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (shouldResetScroll(locationRef.current, location)) {
      window.scrollTo(0, 0);
    }
    locationRef.current = location;
  }, [location]);

  return (
    <>
      {isAuthorized && <PageTop pathname={location.pathname} />}
      <ConsentManagerChecker bypass={!isAuthorized}>
        <Outlet />
        {isAuthorized && (
          <>
            <ComponentSequentialWorkflow>
              {isLegalIdentifiersModalVisible({ isAuthorized, user }) && (
                <AddLegalIdentifier
                  key="legal-identifiers"
                  multiple
                  forceOpen
                  title={i18n.t('Almost there!')}
                  buttonText={i18n.t('Update your profile')}
                  legend={[
                    i18n.t(
                      'Your distributors need additional information about your company.'
                    ),
                    i18n.t(
                      'Your distributors are now requesting your SIREN or VAT number information on the platform for a better follow-up of your account and an easier collaboration with your company.'
                    ),
                  ]}
                  showLink
                />
              )}
              {isUserProfileModalVisible({ isAuthorized, user }) && (
                <UserProfileModal key="user-profile" />
              )}
            </ComponentSequentialWorkflow>
            <DocumentationHandler />
          </>
        )}
        <PageView />
      </ConsentManagerChecker>
      {isAuthorized && <IdlenessTracker user={user} />}
    </>
  );
}
