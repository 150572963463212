import { List, Map } from 'immutable';
import moment from 'moment';
import { useMemo } from 'react';

import { ProductReviewStatus } from 'modules/product-review/components/product-review-status';

import CatalogDateColumn from '../date';

type Props = {
  data: List<Map<string, any>>;
};

export const CatalogReviewStatusCell = ({ data }: Props) => {
  const earliestStatusInWorkflow = useMemo(
    () =>
      data?.size
        ? data
            .map((action) => action?.get('status'))
            .min((status1, status2) => status1 - status2)
        : null,
    [data]
  );
  if (!earliestStatusInWorkflow) {
    return null;
  }
  return <ProductReviewStatus status={earliestStatusInWorkflow} />;
};

export const CatalogReviewLastUpdatedAtCell = ({ data }: Props) => {
  const lastUpdatedAt = useMemo(
    () =>
      data?.size
        ? data
            .map((action) => action?.get('last_updated_at'))
            .max((lastUpdatedAt1, lastUpdatedAt2) =>
              moment(lastUpdatedAt1).diff(moment(lastUpdatedAt2))
            )
        : null,
    [data]
  );
  if (!lastUpdatedAt) {
    return null;
  }
  return <CatalogDateColumn data={lastUpdatedAt} />;
};
