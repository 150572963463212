import { camelCase, mapKeys } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import { PRODUCTSEGMENT_TYPE_HIERARCHY_FREE } from 'constants/productSegment';
import i18n from 'utils/i18n';

import { RECEIVE_PRODUCT_HIERARCHY } from './actions';

const initialState = {
  filters: [],
  allowedIds: [],
  segmentMap: {},
  segmentFlatList: [],
};

export default createReducer(initialState, {
  [RECEIVE_PRODUCT_HIERARCHY]: (state, { allowedSegments, allSegments }) => {
    const allSegMap = allSegments
      .map(mapKeys(camelCase))
      .reduce((obj, { id, name, type, parentId }) => {
        let label = name;
        if (type === PRODUCTSEGMENT_TYPE_HIERARCHY_FREE.id) {
          label = i18n.t('Unclassified products');
        }
        return Object.assign(obj, {
          [id]: { key: id, label, parentId },
        });
      }, {});
    const addParents = (map, segId) => {
      const segment = allSegMap[segId];
      const { parentId } = segment;
      if (parentId) {
        const parent = allSegMap[parentId];
        if (!map[parentId]) {
          Object.assign(map, { [parentId]: parent });
        }
        addParents(map, parentId);
      }
      return map;
    };
    const segmentMap = {};
    const segmentFlatList = [];
    const tree = [];
    const segMap = allowedSegments.reduce((obj, segId) => {
      if (!allSegMap[segId]) {
        return obj;
      }
      segmentMap[segId] = { ...allSegMap[segId] };
      segmentFlatList.push(segmentMap[segId]);
      if (
        segmentMap[segId].parentId &&
        !segmentFlatList.find(({ key }) => key === segmentMap[segId].parentId)
      ) {
        segmentFlatList.push({ ...allSegMap[segmentMap[segId].parentId] });
      }
      return addParents(
        Object.assign(obj, { [segId]: allSegMap[segId] }),
        segId
      );
    }, {});
    Object.keys(segMap).forEach((segId) => {
      const seg = allSegMap[segId];
      const { parentId } = seg;
      if (parentId) {
        segMap[parentId].children = segMap[parentId].children || [];
        segMap[parentId].children.push(segMap[segId]);
      } else {
        tree.push(segMap[segId]);
      }
    });
    return {
      ...state,
      filters: tree,
      allowedIds: allowedSegments,
      segmentMap,
      segmentFlatList,
    };
  },
});
