import { createSelector } from 'reselect';

import { get } from 'utils/immutable';
import { MODULE_REDUCER_KEY } from 'utils/modules/reducer';

import { MODULE_NAME } from './constants';

const selectModuleState = (state) => state[MODULE_REDUCER_KEY][MODULE_NAME];

export const selectIsCreatingVariant = createSelector(
  selectModuleState,
  (state) => get(state, 'isCreatingVariant')
);

export const selectVariantFields = createSelector(selectModuleState, (state) =>
  get(state, 'variantFields')
);

export const selectToggledVariantKeyId = createSelector(
  selectModuleState,
  (state) => get(state, 'toggledVariantProductKeyId')
);
