import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { RecipientLegacyAutocomplete } from 'components/ui/autocomplete/recipient';
import i18n from 'utils/i18n';

import { selectRecipient, unselectRecipient } from '../../actions';
import { selectStateRecipientId } from '../../selectors';

export const mapStateToProps = (state) => ({
  recipientId: selectStateRecipientId(state),
});

export const mapDispatchToProps = {
  select: selectRecipient,
  unselect: unselectRecipient,
};

export class Manufacturer extends PureComponent {
  static propTypes = {
    recipientId: PropTypes.number,
    recipients: ImmutablePropTypes.list.isRequired,
    select: PropTypes.func.isRequired,
    unselect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  formatRecipientForAutocomplete(recipientId, recipients) {
    if (!recipientId) {
      return [];
    }
    const recipient = recipients
      .filter((organization) => organization.get('id') === recipientId)
      .first();

    if (!recipient) {
      return [];
    }

    return [
      {
        id: recipient.get('id'),
        label: recipient.get('nameLegal'),
      },
    ];
  }

  render() {
    const { recipientId, recipients, select, unselect, disabled } = this.props;
    if (!recipients.size) {
      return null;
    }

    const value = this.formatRecipientForAutocomplete(recipientId, recipients);

    return (
      <div className="InputField InputText row">
        <label
          htmlFor="AttributeOrganization"
          className="InputField__label col-xs-3"
        >
          <span className="InputText__label">{i18n.t('Belongs to')}</span>
        </label>
        <span className="InputField__input col-xs-9">
          <RecipientLegacyAutocomplete
            id="AttributeOrganization"
            className="AttributeOrganization RequestModal__emails"
            placeholder={i18n.t('Search for organization')}
            searchOnClick
            onSelect={select}
            onUnselect={unselect}
            value={value}
            disabled={disabled}
          />
        </span>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Manufacturer);
