import { isFunction, once } from 'lodash';
import PropTypes, { array, object } from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { updateCurrentLanguage } from 'actions/productversion';
import Modal from 'components/ui/modal';
import { fallbackLocale } from 'constants/localization';
import {
  selectLocales,
  selectLocalesByTargetMarket,
} from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { registerModuleReducer } from 'utils/modules/reducer';
import { separateActions } from 'utils/redux';
import { track } from 'utils/tracking';

import {
  cancelSave,
  closeProductVersionBulkEdit,
  displayGroupGet,
  init,
  resetProductVersionBulkEdit,
  save,
} from './actions';
import Edit from './components/edit';
import LocaleSelect from './components/locale-select';
import ProductsSummary from './components/products-summary';
import Save from './components/save';
import './index.scss';
import productVersionBulkEditReducers from './reducers';
import {
  PRODUCT_VERSION_BULK_EDIT_STORE_KEY,
  selectIsOpen,
  selectLanguages,
  selectProductVersions,
  selectSaving,
  selectSelectedDisplayGroups,
} from './selectors';

registerModuleReducer(
  PRODUCT_VERSION_BULK_EDIT_STORE_KEY,
  productVersionBulkEditReducers
);

const mapStateToProps = (state, ownProps) => ({
  selectedDisplayGroups: selectSelectedDisplayGroups(state),
  saving: selectSaving(state),
  languages: selectLanguages(state),
  supportedLocales: selectLocales(state),
  locales: selectLocalesByTargetMarket(state),
  currentLanguage: state.productVersion.currentLanguage,
  isOpen: ownProps.isOpen || selectIsOpen(state),
  productVersions: ownProps.productVersions || selectProductVersions(state),
});

const mapDispatchToProps = {
  init,
  displayGroupGet,
  save,
  cancelSave,
  updateCurrentLanguage,
  closeProductVersionBulkEdit,
  resetProductVersionBulkEdit,
};

export class BulkEditModal extends PureComponent {
  static STEP_EDIT = 0;

  static STEP_SUMMARY = 1;

  static STEP_SAVE = 2;

  static propTypes = {
    productVersions: ImmutablePropTypes.list.isRequired,
    selectedDisplayGroups: PropTypes.array.isRequired,
    languages: array.isRequired,
    locales: ImmutablePropTypes.map,
    currentLanguage: object,
    isOpen: PropTypes.bool,
    saving: PropTypes.bool,
    withAutoClose: PropTypes.bool,
    onClose: PropTypes.func,
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      displayGroupGet: PropTypes.func.isRequired,
      save: PropTypes.func.isRequired,
      cancelSave: PropTypes.func.isRequired,
      updateCurrentLanguage: PropTypes.func.isRequired,
      closeProductVersionBulkEdit: PropTypes.func.isRequired,
      resetProductVersionBulkEdit: PropTypes.func.isRequired,
    }),
  };

  state = {
    step: BulkEditModal.STEP_EDIT,
  };

  init = once(() => {
    const { languages, actions } = this.props;
    // Set the language in which the text fields will be set.
    if (languages.length) {
      actions.updateCurrentLanguage(languages[0]);
    } else {
      actions.updateCurrentLanguage(fallbackLocale);
    }
    actions.init();
    actions.displayGroupGet();
  });

  componentDidMount() {
    track({
      category: 'product',
      action: 'product_bulkedit_started',
    });
  }

  componentDidUpdate() {
    if (this.props.isOpen && this.props.productVersions.size) {
      this.init();
    }
  }

  componentWillUnmount() {
    this.props.actions.resetProductVersionBulkEdit();
  }

  onClose = () => {
    const { onClose, actions, withAutoClose } = this.props;
    actions.cancelSave();
    if (isFunction(onClose)) {
      onClose();
    }
    if (withAutoClose) {
      actions.closeProductVersionBulkEdit();
    }
  };

  onSave = () => {
    this.props.actions.save(this.props.productVersions);
    this.goToStepSave();
  };

  onCancelSave = () => {
    this.props.actions.cancelSave();
    this.goToStepSummary();
  };

  goToStep = (step) => {
    this.setState({ step });
  };

  goToStepEdit = () => {
    this.goToStep(BulkEditModal.STEP_EDIT);
  };

  goToStepSave = () => {
    this.goToStep(BulkEditModal.STEP_SAVE);
  };

  goToStepSummary = () => {
    this.goToStep(BulkEditModal.STEP_SUMMARY);
  };

  renderBody() {
    const { productVersions, locales } = this.props;
    const { step } = this.state;
    if (!productVersions || !productVersions.size) {
      return null;
    }
    if (
      step === BulkEditModal.STEP_EDIT ||
      step === BulkEditModal.STEP_SUMMARY
    ) {
      return (
        <>
          <ProductsSummary
            locales={locales}
            productVersions={productVersions}
          />
          <LocaleSelect
            languages={this.props.languages}
            currentLanguage={this.props.currentLanguage}
            setLanguage={this.props.actions.updateCurrentLanguage}
          />
          <Edit
            readOnly={step === BulkEditModal.STEP_SUMMARY}
            key={BulkEditModal.STEP_EDIT}
          />
        </>
      );
    } else if (step === BulkEditModal.STEP_SAVE) {
      return <Save />;
    }
    return null;
  }

  renderFooterStepAction() {
    const { step } = this.state;
    let button = null;
    switch (step) {
      case BulkEditModal.STEP_SUMMARY:
        button = (
          <Button
            content={`< ${i18n.t(
              'frontproductstream.product_bulk_edit.modal.back_button_step_summary_text',
              { defaultValue: 'Modify' }
            )}`}
            link
            onClick={this.goToStepEdit}
          />
        );
        break;
      case BulkEditModal.STEP_SAVE:
        button = (
          <Button
            content={`< ${i18n.t(
              'frontproductstream.product_bulk_edit.modal.back_button_step_save_text',
              { defaultValue: 'Review' }
            )}`}
            link
            onClick={this.onCancelSave}
          />
        );
        break;
      default:
        break;
    }
    if (!button) {
      return <div />;
    }
    return (
      <div className="BulkEditModal__footerButtons BulkEditModal__footerButtons--left">
        {button}
      </div>
    );
  }

  renderFooterStatus() {
    const { step } = this.state;
    let status = null;
    switch (step) {
      case BulkEditModal.STEP_EDIT:
        status = i18n.t(
          'frontproductstream.product_bulk_edit.modal.footer_step_1_text',
          { defaultValue: '1/3: List & Edit' }
        );
        break;
      case BulkEditModal.STEP_SUMMARY:
        status = i18n.t(
          'frontproductstream.product_bulk_edit.modal.footer_step_2_text',
          { defaultValue: '2/3: Summary' }
        );
        break;
      case BulkEditModal.STEP_SAVE:
        status = i18n.t(
          'frontproductstream.product_bulk_edit.modal.footer_step_3_text',
          { defaultValue: '3/3: Saving' }
        );
        break;
      default:
        break;
    }
    if (!status) {
      return null;
    }
    return <div className="BulkEditModal__footerStatus">{status}</div>;
  }

  render() {
    const { isOpen, saving, selectedDisplayGroups } = this.props;
    const { step } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        modalStyle="fullHeight"
        title={i18n.t('frontproductstream.product_bulk_edit.modal.title', {
          defaultValue: 'Bulk edit',
        })}
        className="BulkEditModal"
        confirmButtonText={
          (step === BulkEditModal.STEP_EDIT &&
            `${i18n.t(
              'frontproductstream.product_bulk_edit.modal.confirm_button_step_edit_text',
              { defaultValue: 'Validate' }
            )} >`) ||
          (step === BulkEditModal.STEP_SUMMARY &&
            `${i18n.t(
              'frontproductstream.product_bulk_edit.modal.confirm_button_step_summary_text',
              { defaultValue: 'Save' }
            )} >`) ||
          (step === BulkEditModal.STEP_SAVE &&
            i18n.t(
              'frontproductstream.product_bulk_edit.modal.confirm_button_step_save_text',
              { defaultValue: 'Close' }
            ))
        }
        confirmDisabled={!selectedDisplayGroups.length}
        isProcessing={saving}
        onConfirm={
          (step === BulkEditModal.STEP_EDIT && this.goToStepSummary) ||
          (step === BulkEditModal.STEP_SUMMARY && this.onSave) ||
          (step === BulkEditModal.STEP_SAVE && this.onClose)
        }
        hideCloseButton={step === BulkEditModal.STEP_SAVE}
        onClose={this.onClose}
        additionalFooterContent={this.renderFooterStepAction()}
        centerFooterContent={this.renderFooterStatus()}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(BulkEditModal);
