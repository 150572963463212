import { call, put, select } from 'redux-saga/effects';

import validationApi from 'resources/validationApi';
import { requestWithHeaders } from 'utils/api';
import { logError } from 'utils/logging';

import { RECEIVE_RECIPIENTS_REQUIRED_FIELDS } from '../actions/types';
import { selectRecipientsRequiredFields } from '../selectors';

export function* fetchRecipientsRequiredFields({ recipientId }) {
  const allRecipientsRequiredFields = yield select(
    selectRecipientsRequiredFields
  );
  if (allRecipientsRequiredFields.has(`${recipientId}`)) {
    return;
  }

  let recipientsRequiredFields = {};
  try {
    const { result } = yield call(
      requestWithHeaders,
      validationApi,
      'listRequiredFields',
      [recipientId],
      false // not only blocking fields
    );
    recipientsRequiredFields = result.data.data;
  } catch (e) {
    logError('An error occured while fetching the recipients fields', e);
  }

  yield put({
    type: RECEIVE_RECIPIENTS_REQUIRED_FIELDS,
    recipientsRequiredFields,
  });
}
