import { isObject } from 'lodash/fp';

import { showErrorPage, startLoading, stopLoading } from 'actions/navigation';
import { notificationError } from 'actions/notification';
import {
  ADD_PRODUCT_VERSION_TAG,
  BULK_SEED_DATA,
  DELETE_PRODUCT_VERSION,
  READY_FOR_VALIDATION,
  RECEIVE_PRODUCT_DATA,
  REMOVE_PRODUCT_VERSION_TAG,
  SET_CONTENT_OWNERS,
  SET_KIND_PARENTS,
  UPDATE_CURRENT_LANGUAGE,
} from 'constants/events/productversion';
import { TAG_EXPORTABLE } from 'constants/tags';
import { FEATURE_PERMISSIONS_V3_PRODUCT } from 'modules/feature-flag/constants';
import { reset as resetHierarchies } from 'modules/logistical-hierarchies/actions';
import { selectShouldShowLogisticalHierarchies } from 'modules/logistical-hierarchies/selectors';
import {
  PermissionData,
  hasPermissionV3,
  registerPermission,
} from 'modules/permissions';
import { PRODUCT_PERMISSION } from 'modules/permissions/const';
import {
  clearRFPs,
  receiveRFPs,
} from 'modules/product-page/modules/rfps/actions';
import { selectKindId } from 'reducers/productVersion';
import { selectUser } from 'reducers/user/selectors';
import authApi from 'resources/authApi';
import conceptApi from 'resources/conceptApi';
import serviceProductApi from 'resources/serviceProductApi';
import { cancelPromise } from 'utils';
import i18n from 'utils/i18n';
import { createAction } from 'utils/redux';

import {
  processAssets,
  processConsumerUnitDiffs,
  processContributions, // Hierarchy.
  processLogisticalHierarchyData,
  processLogisticalHierarchyDiffs, // Manufacturer specific.
  processManufacturerProductVersion,
  processManufacturerSharingUnits,
  processPermissions,
  processProductKey,
  processProductShares,
  processProductVersion, // Scopes
  processScopes,
  processSharingUnits,
  processSharingUnitsDiffs, // Specific
  processSpecificData,
  processTransactionIds,
} from './product-utils';

export const updateCurrentLanguage = (currentLanguage) => ({
  type: UPDATE_CURRENT_LANGUAGE,
  currentLanguage,
});

const addProductVersionTag = (tag) => ({
  type: ADD_PRODUCT_VERSION_TAG,
  payload: { tag },
});

const removeProductVersionTag = (tag) => ({
  type: REMOVE_PRODUCT_VERSION_TAG,
  payload: { tag },
});

export const setContentOwners = (productKeys) => ({
  type: SET_CONTENT_OWNERS,
  payload: { productKeys },
});

export const setKindParents = (kindParents) => ({
  type: SET_KIND_PARENTS,
  kindParents,
});

export const deleteProductVersion = () => ({
  type: DELETE_PRODUCT_VERSION,
});

export const setReadyForValidation = () => ({
  type: READY_FOR_VALIDATION,
});

const receiveProductData = createAction(RECEIVE_PRODUCT_DATA);

const registerPermissionFromProductResponse = (
  dispatch,
  permissions: PermissionData,
  keyId
) => {
  if (Array.isArray(permissions)) {
    dispatch(
      registerPermission({
        entity_type: PRODUCT_PERMISSION,
        id: keyId,
        permissions: permissions.reduce((acc, permission) => {
          acc[permission] = {};
          return acc;
        }, {}),
      })
    );
  } else if (isObject(permissions)) {
    dispatch(
      registerPermission({
        entity_type: PRODUCT_PERMISSION,
        id: keyId,
        permissions: permissions || {},
      })
    );
  }
};

export const fetchProductVersionByKeyId =
  (productKeyId, withDeleteOld = false): any =>
  async (dispatch, getState) => {
    dispatch(startLoading());
    try {
      const productResponse = await serviceProductApi.getProductByKeyId(
        productKeyId,
        { with_deleted_shares: true }
      );
      const { permissions, key, shares, rfps } = productResponse.data as any;
      const state = getState();
      const user = selectUser(state);
      if (withDeleteOld) {
        dispatch(deleteProductVersion());
        dispatch(resetHierarchies());
        dispatch(clearRFPs());
      }

      if (hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)) {
        registerPermissionFromProductResponse(dispatch, permissions, key.id);
      }
      dispatch(processPermissions(permissions));

      dispatch(processProductKey(key));
      dispatch(processProductShares(shares));
      dispatch(processSpecificData(productResponse.data));
      dispatch(processAssets(productResponse.data));
      dispatch(processManufacturerSharingUnits(productResponse.data));
      dispatch(processLogisticalHierarchyData(productResponse.data));
      dispatch(receiveRFPs(rfps));
      dispatch(processContributions(productResponse.data));
      dispatch(processManufacturerProductVersion(productResponse.data));
      dispatch(receiveProductData(productResponse.data));
      dispatch(processScopes(productResponse.data));
      dispatch(stopLoading());
    } catch (e: any) {
      dispatch(stopLoading());
      dispatch(showErrorPage());
      dispatch(
        notificationError(
          i18n.t('frontproductstream.product.fetch_error.notification', {
            defaultValue: 'An error occured while getting your product',
          }),
          {
            error: e,
          }
        )
      );
    }
  };

export const fetchRetailerProductVersionByKeyId = (() => {
  let promise;

  return (productKeyId, withDeleteOld = false): any =>
    (dispatch, getState) => {
      cancelPromise(promise, () => dispatch(stopLoading()));
      dispatch(startLoading());

      promise = serviceProductApi
        .getProductByKeyId(productKeyId)
        .then((response) => {
          dispatch(stopLoading());
          const state = getState();
          const user = selectUser(state);
          const productData = response.data as any;

          // Process data.
          const { permissions, key, shares, rfps } = productData;
          if (withDeleteOld) {
            dispatch(deleteProductVersion());
            dispatch(resetHierarchies());
            dispatch(clearRFPs());
          }

          if (hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)) {
            registerPermissionFromProductResponse(
              dispatch,
              permissions,
              key?.id
            );
          }
          dispatch(processPermissions(permissions));

          dispatch(processProductKey(key));
          dispatch(processProductShares(shares));
          if (rfps) {
            dispatch(receiveRFPs(rfps));
          }
          dispatch(processAssets(productData));
          dispatch(processSharingUnits(productData));
          dispatch(processContributions(productData));

          // Process diffs.
          dispatch(processConsumerUnitDiffs(productData));
          dispatch(processSharingUnitsDiffs(productData));

          // Logistical hierarchies.
          if (selectShouldShowLogisticalHierarchies(state)) {
            dispatch(processLogisticalHierarchyData(productData));
            dispatch(processLogisticalHierarchyDiffs(productData));
          }

          // Transaction IDs.
          dispatch(processTransactionIds(productData));

          dispatch(processProductVersion(productData));
          dispatch(receiveProductData(productData));

          promise = null;
        });
      promise.catch((error) => {
        dispatch(showErrorPage());
        dispatch(
          notificationError(
            i18n.t('frontproductstream.product.fetch_error.notification', {
              defaultValue: 'An error occured while getting your product',
            }),
            { error }
          )
        );
        promise = null;
        return error;
      });
      return promise;
    };
})();

export const addTagToProductVersion =
  (productKeyId, tag) => async (dispatch) => {
    dispatch(startLoading());
    try {
      await serviceProductApi.tagVersion(productKeyId, tag);
      dispatch(addProductVersionTag(tag));
      dispatch(stopLoading());
    } catch (e: any) {
      dispatch(stopLoading());
      // 400 is when user try to redo the same action
      if (e.data.status === 400) {
        return;
      }
      const errorMessage =
        e.data.status === 403
          ? i18n.t(
              'frontproductstream.product.validate_permission_error.notification',
              {
                defaultValue:
                  'You do not have the rights to validate the Product.',
              }
            )
          : i18n.t('frontproductstream.product.validate_error.notification', {
              defaultValue:
                'An error occured while validating the Product data.',
            });
      dispatch(notificationError(errorMessage));
    }
  };

export const removeTagFromProductVersion =
  (productKeyId, tag) => (dispatch) => {
    dispatch(startLoading());
    serviceProductApi.unTagVersion(productKeyId, tag).then(
      () => {
        dispatch(removeProductVersionTag(tag));
        dispatch(stopLoading());
      },
      (e) => {
        dispatch(stopLoading());
        // 400 is when user try to redo the same action
        if (e.data.status === 400) {
          return;
        }
        const errorMessage =
          e.data.status === 403
            ? i18n.t(
                'frontproductstream.product.unvalidate_permission_error.notification',
                {
                  defaultValue:
                    'You do not have the rights to unvalidate the Product.',
                }
              )
            : i18n.t(
                'frontproductstream.product.unvalidate_error.notification',
                {
                  defaultValue:
                    'An error occured while unvalidating the Product data.',
                }
              );
        dispatch(notificationError(errorMessage));
      }
    );
  };

/* This call is a POST, to avoid multiple call for the same PV
   we need to maintain a local cache.
*/
const productVersionPromises = {};
export const getProductVersionPermissions =
  (productVersionId) => (dispatch) => {
    // If promise already processing, return it
    const runningPromise = productVersionPromises[productVersionId];
    if (runningPromise) {
      return runningPromise;
    }

    const promise = authApi
      .UserManagesProductVersionShow(productVersionId)
      .then(
        (response) => {
          const { permissions } = response.data as any;
          dispatch(processPermissions(permissions));
          delete productVersionPromises[productVersionId];
          return permissions;
        },
        () => {
          delete productVersionPromises[productVersionId];
          return [];
        }
      );
    productVersionPromises[productVersionId] = promise;
    return promise;
  };

export const bulkSeedData = (updates) => ({
  type: BULK_SEED_DATA,
  updates,
});

export const fetchKindParents = () => async (dispatch, getState) => {
  try {
    const parentList = await conceptApi.ConceptListParents(
      selectKindId(getState())
    );
    dispatch(setKindParents(parentList.data.data));
  } catch (e: any) {
    dispatch(
      notificationError(
        i18n.t(
          'frontproductstream.product.fetch_kind_parents_error.notification',
          {
            defaultValue:
              'An error occured while getting your product informations',
          }
        ),
        {
          error: e,
        }
      )
    );
  }
};

export const setExportableTag =
  (productKeyId: number[]): any =>
  async (dispatch) => {
    dispatch(startLoading());
    try {
      await serviceProductApi.setExportableTag(productKeyId);
      dispatch(addProductVersionTag(TAG_EXPORTABLE));
    } finally {
      dispatch(stopLoading());
    }
  };
