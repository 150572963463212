import { fork } from 'redux-saga/effects';

import assetsSaga from 'modules/assets/sagas';
import bulkSetAsPublicSaga from 'modules/bulk-set-as-public/sagas';
import bulkUnshareSaga from 'modules/bulk-unshare/sagas';
import displayGroupsSaga from 'modules/display-groups/sagas';
import logisticalHierarchiesSaga from 'modules/logistical-hierarchies/sagas';
import priceWaterfallsSaga from 'modules/price-waterfalls/sagas';
import sharingUnitsSaga from 'modules/sharing-units/sagas';
import viewAsSaga from 'modules/view-as/sagas';

export default function* rootSaga() {
  yield fork(assetsSaga);
  yield fork(bulkSetAsPublicSaga);
  yield fork(displayGroupsSaga);
  yield fork(priceWaterfallsSaga);
  yield fork(logisticalHierarchiesSaga);
  yield fork(sharingUnitsSaga);
  yield fork(viewAsSaga);
  yield fork(bulkUnshareSaga);
}
