export const formatMailTo = (
  subject: string,
  email: string,
  data: [string, any][] = []
) => {
  const body = `${subject}

${'-'.repeat(10)}
${data.reduce(
  (acc, [label, value]) => `${acc}${label}: ${value}\n`,
  ''
)}${'-'.repeat(10)}
`;
  return `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
};
