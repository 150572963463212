import { flow } from 'lodash/fp';
import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { getOrganizationId } from 'core/api/user';
import { UserImmutable } from 'types/user';

import { KEY, ModuleState } from '../reducers';

export const selectModuleState = (state): ModuleState =>
  state[MODULE_REDUCER_KEY][KEY];

export const selectUser: (state: any) => UserImmutable = (state) => state.user;

export const selectOrganizationId = createSelector(
  selectUser,
  getOrganizationId
);

export const selectIsVisible = flow(selectModuleState, (state) => {
  return state.visible;
});
