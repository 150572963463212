import { SOURCE_PRODUCTDNA } from 'constants/organization-source';
import { getOrganizationLegalIdentifiers, isAdmin } from 'core/api/user';
import { hasLegalIdentifiersReminderModal } from 'modules/admin/organization';
import { HIDE_USER_PROFILE_MODAL, hasFeature } from 'modules/feature-flag';
import { getSource } from 'utils/branding';

export const isUserProfileModalVisible = ({ isAuthorized, user }) =>
  isAuthorized &&
  user &&
  !user.get('phonenumber') &&
  !hasFeature(user, HIDE_USER_PROFILE_MODAL);

export const isLegalIdentifiersModalVisible = ({ isAuthorized, user }) =>
  isAuthorized &&
  user &&
  isAdmin(user) &&
  hasLegalIdentifiersReminderModal(user) &&
  getSource(user) !== SOURCE_PRODUCTDNA &&
  (!getOrganizationLegalIdentifiers(user) ||
    getOrganizationLegalIdentifiers(user).size === 0);
