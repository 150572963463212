import { Map } from 'immutable';
import { get, once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { productHierarchyFilter } from 'core/modules/list/constants/filters/product-hierarchy';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import { getProductHierarchy } from 'modules/product-hierarchy/actions';
import {
  selectProductHierarchyFilters,
  selectProductHierarchyFlatList,
  selectProductHierarchyMap,
} from 'modules/product-hierarchy/selectors';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import AdvancedFilter from '../advanced';

const mapState = (state) => ({
  productHierarchyFilters: selectProductHierarchyFilters(state),
  productHierarchyFlatList: selectProductHierarchyFlatList(state),
  productHierarchyMap: selectProductHierarchyMap(state),
});

const mapDispatch = { getProductHierarchy };

class ProductHierarchyFilterComponent extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    filterConfig: ImmutablePropTypes.map,
    user: ImmutablePropTypes.map.isRequired,
    productHierarchyFilters: PropTypes.array,
    productHierarchyFlatList: PropTypes.array,
    productHierarchyMap: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onSort: PropTypes.func,
    actions: PropTypes.shape({
      getProductHierarchy: PropTypes.func.isRequired,
    }).isRequired,
    filterQueryValues: PropTypes.array,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    filterConfig: Map(),
    filterQueryValues: [],
  };

  filterKey = productHierarchyFilter.key;

  updateSelectionFromQuery = once(() => {
    const { filterQueryValues, productHierarchyFlatList, onChange } =
      this.props;
    buildFiltersFromQuery({
      filterQueryValues,
      filterList: productHierarchyFlatList,
      filterKey: this.filterKey,
      selectFilterValue: this.filterSelectors.selectId,
      selectFilterLabel: (filter) =>
        `${this.filterLabel}: ${this.filterSelectors.selectLabel(filter)}`,
      selectFilterData: (filter) => [this.filterSelectors.selectId(filter)],
    }).then((filtersFromQuery) => {
      filtersFromQuery.forEach((filter) => {
        this.fillHierarchyPath(filter);
      });
      onChange(filtersFromQuery, true);
    });
  });

  filterSelectors = {
    selectId: (filter) => filter.key,
    selectLabel: (filter) => filter.label,
    selectChildren: (filter) => filter.children,
  };

  filterLabel = i18n.t(
    'frontproductstream.core.list_filter_product_hierarchy.label',
    { defaultValue: 'Hierarchy' }
  );

  componentDidMount() {
    const { productHierarchyFilters } = this.props;
    if (!productHierarchyFilters || productHierarchyFilters.length === 0) {
      this.props.actions.getProductHierarchy({ includeUnclassified: true });
    }
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  componentDidUpdate() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.filterKey, collapsed);
  };

  onChange = (filter) => {
    if (this.props.filterConfig.get('query')) {
      this.fillHierarchyPath(filter);
    }
    this.props.onChange(filter);
  };

  getFilters() {
    if (this.props.filterConfig.get('query')) {
      return {
        filters: this.props.productHierarchyFlatList,
        withPagination: true,
        isHierarchyFlat: true,
      };
    }
    return {
      filters: this.props.productHierarchyFilters,
      withPagination: false,
      isHierarchyFlat: false,
    };
  }

  shouldUpdateSelectionFromQuery = () => {
    const {
      aggregations,
      filterQueryValues,
      productHierarchyFilters,
      productHierarchyFlatList,
      selectedFilterMap,
    } = this.props;
    return (
      productHierarchyFilters &&
      productHierarchyFilters.length &&
      productHierarchyFlatList &&
      productHierarchyFlatList.length &&
      aggregations.size &&
      filterQueryValues.length &&
      selectedFilterMap.isEmpty()
    );
  };

  fillHierarchyPath(filter) {
    const hierarchyMap = this.props.productHierarchyMap;
    let parentId = get(hierarchyMap, [filter.value, 'parentId']);
    while (parentId) {
      filter.data.push(parentId);
      parentId = get(hierarchyMap, [parentId, 'parentId']);
    }
    return filter;
  }

  render() {
    const { filters, withPagination, isHierarchyFlat } = this.getFilters();
    return (
      <AdvancedFilter
        id="list-filter-hierarchy"
        filterKey={this.filterKey}
        filterLabel={this.filterLabel}
        filters={filters}
        selectedFilterMap={this.props.selectedFilterMap}
        aggregations={this.props.aggregations}
        searchPlaceholder={i18n.t(
          'frontproductstream.core.list_filter_product_hierarchy.search_placeholder',
          { defaultValue: 'Search for hierarchy' }
        )}
        searchQuery={this.props.filterConfig.get('query')}
        collapsed={this.props.filterConfig.get('collapsed')}
        page={this.props.filterConfig.get('page')}
        onChange={this.onChange}
        onCollapse={this.props.onCollapse}
        onFilter={this.props.onFilter}
        onChangePage={this.props.onChangePage}
        onSort={isHierarchyFlat ? this.props.onSort : undefined}
        selectors={this.filterSelectors}
        withPagination={withPagination}
        withTree
      />
    );
  }
}

export const ProductHierarchyFilter = connect(
  mapState,
  mapDispatch,
  separateActions
)(ProductHierarchyFilterComponent);
