import { List, Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { RECEIVE_EXPORT_SYNCHRONIZATION_STATUS } from './constants';

const initialState = Map({
  exportSynchronizationStatuses: List(),
});

export default createReducer(initialState, {
  [RECEIVE_EXPORT_SYNCHRONIZATION_STATUS]: (state, action) => {
    const { statuses } = action;
    const formatedStatuses = statuses.reduce((_list, status) => {
      let list = _list;
      const uuidsToContextCode = status.getIn([
        'context',
        'uuids_to_context_code',
      ]);
      if (uuidsToContextCode && uuidsToContextCode.size > 0) {
        uuidsToContextCode.forEach((uuid) => {
          list = list.push(
            fromJS({
              status: status.get('status'),
              metadata: [
                {
                  entity_type: 'SharingUnit',
                  entity_id: uuid.get('sharingUnit_id'),
                },
              ],
              targetOrganization: status.get('targetOrganization'),
              date: status.getIn(['context', 'date']) || status.get('date'),
              format: status.get('format'),
              gtin: status.get('gtin'),
              context: {
                code: uuid.get('code'),
                date: status.getIn(['context', 'date']) || status.get('date'),
              },
            })
          );
        });
      } else {
        list = list.push(status);
      }
      return list;
    }, List());
    return state.set('exportSynchronizationStatuses', formatedStatuses);
  },
});
