import { Map, Set } from 'immutable';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import {
  FEATURE_MULTI_TARGETMARKET_CATALOG,
  hasFeature,
} from 'modules/feature-flag';
import { UserImmutable } from 'types';
import { get, size } from 'utils/immutable';

import {
  getBulkTooltipAllProductsFiltered,
  getBulkTooltipSameTM,
  getBulkTooltipSelectOneTM,
} from '../constants';

interface Props {
  selectedMap: Map<string, boolean>;
  productMap: Map<string | undefined, Map<string, any>>;
  tooltipMessage: string;
  name: string;
  checkMultiTm: boolean;
  checkDisabled: boolean;
  isDisabled: boolean;
  filtersQueryMap?: { [filterKey: string]: any };
  user: UserImmutable;
}

export default (WrappedComponent) => {
  function DisableWrapper(props: Props) {
    const hasMultiTMSelected = () => {
      const { selectedMap, productMap } = props;

      if (selectedMap.isEmpty()) {
        return false;
      }

      const selectedProductVersions = selectedMap
        .map((_, pvId) => productMap.get(pvId))
        .toList();
      const tms = selectedProductVersions.map((pv) =>
        get(pv, 'tags.targetMarket.id')
      );
      return Set(tms).size > 1;
    };

    const hasOnlyOneTMSelected = () => {
      const { selectedMap, filtersQueryMap, user } = props;
      const userHasMultiTm = hasFeature(
        user,
        FEATURE_MULTI_TARGETMARKET_CATALOG
      );
      if (selectedMap.isEmpty() && userHasMultiTm) {
        // Check that only one tm is selected for multi_tm client if no product selected
        const filteredTms = get(filtersQueryMap, 'tags.targetMarket.id');
        return filteredTms && size(filteredTms) === 1;
      }

      // If orga works only on one TM, all good
      return true;
    };

    if (props.checkDisabled && props.isDisabled) {
      return (
        <LazyTooltip
          id={'bulk-tooltip-' + props.name}
          tooltipLabel={props.tooltipMessage}
          place="bottom"
          block
        >
          <div className="ActionOption--link-disabled">
            <WrappedComponent {...props} disabled />
          </div>
        </LazyTooltip>
      );
    } else if (props.checkMultiTm && hasMultiTMSelected()) {
      return (
        <LazyTooltip
          id="bulk-multi-tm"
          tooltipLabel={getBulkTooltipSameTM()}
          place="bottom"
          block
        >
          <div className="ActionOption--link-disabled">
            <WrappedComponent {...props} disabled />
          </div>
        </LazyTooltip>
      );
    } else if (
      // Case where all products have been filtered and no selection
      props.selectedMap.isEmpty() &&
      props.productMap.size === 0
    ) {
      return (
        <LazyTooltip
          id="bulk-action-no-selection"
          tooltipLabel={getBulkTooltipAllProductsFiltered()}
          place="bottom"
          block
        >
          <div className="ActionOption--link-disabled">
            <WrappedComponent {...props} disabled />
          </div>
        </LazyTooltip>
      );
    } else if (
      props.selectedMap.isEmpty() &&
      (!hasOnlyOneTMSelected() || hasMultiTMSelected())
    ) {
      return (
        <LazyTooltip
          id="bulk-action-no-selection"
          tooltipLabel={getBulkTooltipSelectOneTM()}
          place="bottom"
          block
        >
          <div className="ActionOption--link-disabled">
            <WrappedComponent {...props} disabled />
          </div>
        </LazyTooltip>
      );
    } else {
      return <WrappedComponent {...props} />;
    }
  }
  DisableWrapper.shouldBeDisplayed = WrappedComponent.shouldBeDisplayed;
  return DisableWrapper;
};
