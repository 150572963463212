import { createAction } from 'utils/redux';

import {
  FETCH_RFP_MODULE_AVAILABLITY,
  RECEIVE_RFP_MODULE_AVAILABLITY,
} from './constants';

export const fetchHasRfpModule = createAction<{ organizationId: number }>(
  FETCH_RFP_MODULE_AVAILABLITY
);

export const receiveHasRfpModule = createAction<boolean>(
  RECEIVE_RFP_MODULE_AVAILABLITY
);
