import Immutable from 'immutable';
import { memo, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';

import Modal from 'components/ui/modal';
import { CATALOG, SOURCING } from 'constants/user-labels';
import { getOrganizationId, isAdmin } from 'core/api/user';
import {
  FEATURE_PERMISSIONS_V3_ORGANIZATION,
  FEATURE_PERMISSIONS_V3_PRODUCT,
} from 'modules/feature-flag';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  ORGANIZATION_PERMISSION,
  PRODUCT_PERMISSION,
  USERLABEL_MANAGE_PERMISSION,
} from 'modules/permissions/const';
import ProductsSummary from 'modules/product-version-bulk-edit/components/products-summary';
import { selectHasManageLabelPermission } from 'reducers/productVersion';
import {
  selectLocalesByTargetMarket,
  selectUser,
} from 'reducers/user/selectors';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { size } from 'utils/immutable';

import {
  cancelSaveUserLabels,
  fetchAllUserLabels,
  resetUserLabelModal,
  saveUserLabels,
  selectUserLabel,
  setUserLabelsContext,
} from '../../actions';
import {
  selectAllUserLabels,
  selectInProgress,
  selectIsVisible,
  selectSelectedUserLabels,
  selectSortedUserLabels,
} from '../../selectors/modal';
import { SelectedUserLabels, UserLabel, UserLabels } from '../../types';
import { hasUserLabelManagementFeature } from '../../utils';

import { AddUserLabelFormInput } from './form/add';
import { UserLabelFormItem } from './form/label';
import './index.scss';

interface Props {
  bulk?: boolean;
  context?: string;
  productVersions?: Immutable.List<any>;
}

const useUserLabelsPermissions = (
  user: UserImmutable,
  context: string,
  isBulk?: boolean
) => {
  const hasManageLabelPermission = useSelector(selectHasManageLabelPermission);
  const canCreateUserLabel = hasPermissionV3(
    user,
    FEATURE_PERMISSIONS_V3_ORGANIZATION
  )
    ? hasPermissionModule(
        user,
        ORGANIZATION_PERMISSION,
        USERLABEL_MANAGE_PERMISSION
      )
    : true;
  const canManageUserLabel = hasPermissionV3(
    user,
    FEATURE_PERMISSIONS_V3_PRODUCT
  )
    ? hasPermissionModule(user, PRODUCT_PERMISSION, USERLABEL_MANAGE_PERMISSION)
    : isAdmin(user);

  let canAttachOrDetachLabel: boolean = true;
  if (
    context === CATALOG &&
    hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)
  ) {
    canAttachOrDetachLabel = isBulk
      ? hasPermissionModule(
          user,
          PRODUCT_PERMISSION,
          USERLABEL_MANAGE_PERMISSION
        )
      : hasManageLabelPermission;
  }
  return { canAttachOrDetachLabel, canManageUserLabel, canCreateUserLabel };
};

export const UserLabelModal = memo(
  ({ bulk = false, context = CATALOG, productVersions }: Props) => {
    const dispatch = useDispatch();
    const scrollerRef = useRef<null | HTMLUListElement>(null);
    const inProgress: boolean = useSelector(selectInProgress);
    const isVisible: boolean = useSelector(selectIsVisible);
    const labels: UserLabels = useSelector(selectAllUserLabels);
    const locales: Immutable.List<any> = useSelector(
      selectLocalesByTargetMarket
    );
    const selected: SelectedUserLabels = useSelector(selectSelectedUserLabels);
    const sortedLabels: Immutable.List<number> = useSelector(
      selectSortedUserLabels
    );
    const user: UserImmutable = useSelector(selectUser);
    const { canAttachOrDetachLabel, canCreateUserLabel } =
      useUserLabelsPermissions(user, context, bulk);

    const canSeeUserLabels =
      (hasUserLabelManagementFeature(user) || context === SOURCING) &&
      isVisible;

    useEffect(() => {
      dispatch(setUserLabelsContext(context));
    }, [context, dispatch]);

    useEffect(() => {
      if (canSeeUserLabels) {
        dispatch(fetchAllUserLabels());
      }
    }, [dispatch, canSeeUserLabels]);

    const labelsCount = size(sortedLabels);
    useEffect(() => {
      if (labelsCount && scrollerRef.current) {
        scrollerRef.current.scrollTop = 0;
      }
    }, [labelsCount]);

    useEffect(
      () => () => {
        dispatch(resetUserLabelModal());
        dispatch(cancelSaveUserLabels());
      },
      [dispatch]
    );

    const hasUpdates = useMemo(
      () => selected.some((sel) => !!sel?.get('updated')),
      [selected]
    );

    if (!canSeeUserLabels) {
      return null;
    }

    const isReady = !!labels && !!sortedLabels;

    const onSave = () => {
      dispatch(saveUserLabels({ bulk }));
    };

    const onClose = () => {
      dispatch(resetUserLabelModal());
    };

    const onSelect = (userLabel: UserLabel, isSelected: boolean) => {
      dispatch(selectUserLabel({ userLabel, selected: isSelected }));
    };

    return (
      <Modal
        modalStyle="dynamic"
        className="UserLabelModal"
        title={i18n.t('Add a tag')}
        confirmButtonText={i18n.t('Save')}
        confirmDisabled={!isReady || !canAttachOrDetachLabel || !hasUpdates}
        isProcessing={inProgress}
        onConfirm={onSave}
        onClose={onClose}
      >
        <div className="UserLabelModal__body">
          {!productVersions || productVersions.isEmpty() ? null : (
            <ProductsSummary
              productVersions={productVersions}
              locales={locales}
            />
          )}
          <div className="UserLabelModal__formRow UserLabelModal__formRow--tags">
            <label htmlFor="user-label-modal-labels">
              {i18n.t('Current tags')}
            </label>
            {!isReady && (
              <div data-testid="spinner">
                <Spinner small />
              </div>
            )}
            {isReady && (
              <ul
                id="user-label-modal-labels"
                className="UserLabelModal__labels"
                ref={scrollerRef}
              >
                {sortedLabels.map((labelId) => (
                  <UserLabelFormItem
                    key={`user-label-form-item-${labelId}`}
                    label={labels.get(labelId as number)}
                    selected={selected.getIn([labelId, 'value'])}
                    partial={selected.getIn([labelId, 'partial'])}
                    onChange={onSelect}
                    disabled={inProgress}
                    canAttachOrDetachLabel={canAttachOrDetachLabel}
                    canCreateUserLabel={canCreateUserLabel}
                    context={context}
                  />
                ))}
              </ul>
            )}
          </div>
          {canCreateUserLabel && (
            <div className="UserLabelModal__formRow UserLabelModal__add">
              <span className="UserLabelModal__addLabel">
                <i className="BulkEditModalEdit__selectIcon mdi mdi-plus-circle" />
                {i18n.t('Add tag')}
              </span>
              <AddUserLabelFormInput
                disabled={!isReady || inProgress}
                organizationId={getOrganizationId(user)}
                canAttachOrDetachLabel={canAttachOrDetachLabel}
              />
            </div>
          )}
        </div>
      </Modal>
    );
  }
);
