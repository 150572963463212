import { List, Map, fromJS } from 'immutable';

import { resetPage, resetSelection } from 'core/modules/list/reducers/list';
import { calculateOffset } from 'utils';
import { toPercentage } from 'utils/number';
import { sortAsc, sortDesc } from 'utils/sort';

import { AUTH_DEMO } from '../../../constants';
import {
  AVERAGE_TIME_CELL,
  FRACTION_CELL,
  SIMPLE_QUALITY_CELL,
  TOTAL_PERCENTAGE_CELL,
} from '../../constants';
import { getIdFromReport, getReportData } from '../../utils';

import { buildCharts } from './charts';

export function extractReportList(state) {
  const page = state.getIn(['pagination', 'page']);
  const limit = state.getIn(['pagination', 'limit']);
  const reportPaginatedList = state.get('reportPaginatedList');
  if (reportPaginatedList) {
    return state.set('reportList', reportPaginatedList);
  } else {
    const offset = calculateOffset(page, limit);
    return state.set(
      'reportList',
      state.get('reportFullList').slice(offset, offset + limit)
    );
  }
}

export function updateTotalPages(state) {
  const total = state.getIn(['pagination', 'total']);
  const limit = state.getIn(['pagination', 'limit']);
  const pages = Math.ceil(total / limit);
  return state.setIn(['pagination', 'pages'], pages);
}

function getSorting(state) {
  const columnList = state.get('columnList');
  const sorting = state.get('sorting');
  return sorting || columnList.get(0).set('asc', true);
}

export const resetPagination = (state) => extractReportList(resetPage(state));

export const receiveFullReport = (reportState) => {
  return reportState
    .update('pagination', (p) =>
      p.withMutations((pagination) => {
        const limit = reportState.getIn(['pagination', 'limit']);
        const total = reportState.get('reportFullList').size;
        return pagination
          .set('total', total)
          .set('pages', Math.ceil(total / limit));
      })
    )
    .withMutations((newState) => {
      const sorting = getSorting(newState);
      const payload = { referential: sorting, asc: sorting.get('asc') };
      let sortedState = sortReport(reportState, { payload });
      sortedState = resetPagination(sortedState);
      return sortedState;
    });
};

export const receivePaginatedReport = (reportState, pagination_key) => {
  return reportState
    .set('reportPaginatedList', reportState.get('reportFullList'))
    .update('pagination', (p) =>
      p.withMutations((pagination) => {
        return pagination.set(
          'keys',
          pagination
            .getIn(['keys'], Map())
            .set(pagination.get('page') + 1, pagination_key)
        );
      })
    );
};

export function receiveReport(state, { payload = {} }: any) {
  const {
    isBackendPagination,
    aggregations,
    auth_type: authorization,
    charts,
  } = payload || {};
  const { detail, all, pagination_key } = aggregations;

  // report total aggregation
  let aggregation;
  let aggregationGroupByMeta;
  if (all) {
    const { row_values: aggregationRows = [], meta: aggregationMeta = [] } =
      all;
    [aggregation] = aggregationRows;
    aggregationGroupByMeta = aggregationMeta.filter(
      (m) => m.type === 'group_by'
    );
  }

  // report details
  const { meta = [], row_values: rows = [] } =
    !detail && !all ? aggregations : detail;
  const groupByMeta = meta.filter((m) => m.type === 'group_by');
  const idKeys = groupByMeta.map((m) => m.key);
  const columns = meta
    .filter((col) => !col.disable)
    .reduce((list, col) => {
      const {
        columns: columnKeys,
        columns_label: labels,
        filter,
        ...metaCol
      } = col;
      let newList = list;
      if (Array.isArray(columnKeys)) {
        columnKeys.forEach((subColKey) => {
          const key = `${col.key}.${subColKey}`;
          newList = newList.push(
            fromJS({
              ...metaCol,
              key,
              keyOrigin: subColKey,
              label: labels[key] || `${col.key}: ${subColKey}`,
              filter: filter[subColKey] || filter['%(value)s'],
            })
          );
        });
      } else {
        newList = newList.push(
          fromJS({
            ...metaCol,
            label: labels[col.key] || col.key,
            filter,
          })
        );
      }
      return newList;
    }, List());
  const columnMap = columns.reduce(
    (dict, col) => dict.set(col.get('key'), col),
    Map()
  );
  let reportFullList;
  const reportMap = Map().withMutations((reports) => {
    reportFullList = List(
      rows.map((row) => {
        const id = getIdFromReport(row, idKeys);
        reports.set(id, fromJS(row));
        return id;
      })
    );
  });
  return state.withMutations((newState) => {
    newState
      .set('columnList', columns)
      .set('columnMap', columnMap)
      .set('reportFullList', reportFullList)
      .set('reportMap', reportMap)
      .set('idKeys', idKeys)
      .set('reportAggregation', fromJS(aggregation))
      .set('aggregationGroupByMeta', fromJS(aggregationGroupByMeta))
      .set('groupByMeta', fromJS(groupByMeta))
      .set('isDemo', authorization === AUTH_DEMO)
      .set('charts', fromJS(buildCharts(charts, aggregations)));
    let paginatedState = isBackendPagination
      ? receivePaginatedReport(newState, pagination_key)
      : receiveFullReport(newState);
    paginatedState = extractReportList(paginatedState);
    paginatedState = resetSelection(paginatedState);
    return paginatedState;
  });
}

export const previousPage = (state) =>
  state.withMutations((newState) => {
    newState.updateIn(['pagination', 'page'], (page) => page - 1);
    extractReportList(newState);
    resetSelection(newState);
  });

export const nextPage = (state) =>
  state.withMutations((newState) => {
    newState.updateIn(['pagination', 'page'], (page) => page + 1);
    extractReportList(newState);
    resetSelection(newState);
  });

function getPercentage(report = Map(), asc) {
  const { isInteger } = Number;
  const ok = report.get('ok');
  const total = report.get('doc_count') as number;
  const isValid = isInteger(ok) && isInteger(total) && total > 0;
  if (isValid) {
    return toPercentage(ok, total);
  }
  return asc ? 101 : -1;
}

export function sortReport(state, { payload }) {
  const { referential, asc } = payload;
  const reportMap = state.get('reportMap');
  const reportFullList = state.get('reportFullList');
  const refKey = referential.get('key');
  const sortFunc = asc ? sortAsc : sortDesc;
  const cellType = referential.getIn(['cell', 'type']);
  let sortedReports;
  switch (cellType) {
    case SIMPLE_QUALITY_CELL:
    case TOTAL_PERCENTAGE_CELL:
      sortedReports = reportFullList.sort((keyA, keyB) =>
        sortFunc(
          getPercentage(
            getReportData(reportMap.get(keyA), refKey, cellType),
            asc
          ),
          getPercentage(
            getReportData(reportMap.get(keyB), refKey, cellType),
            asc
          )
        )
      );
      break;
    case AVERAGE_TIME_CELL:
      sortedReports = reportFullList.sort((keyA, keyB) =>
        sortFunc(
          reportMap.getIn([keyA, refKey, 'avg']),
          reportMap.getIn([keyB, refKey, 'avg'])
        )
      );
      break;
    case FRACTION_CELL:
      sortedReports = reportFullList.sort((keyA, keyB) =>
        sortFunc(
          reportMap.getIn([keyA, refKey, 'num']),
          reportMap.getIn([keyB, refKey, 'num'])
        )
      );
      break;
    default:
      sortedReports = reportFullList.sort((keyA, keyB) =>
        sortFunc(
          getReportData(reportMap.get(keyA), refKey),
          getReportData(reportMap.get(keyB), refKey)
        )
      );
  }
  return state
    .set('reportFullList', sortedReports)
    .set('sorting', referential.set('asc', asc));
}
