import {
  GET_NOTIFICATION_COUNTER,
  RECEIVE_NOTIFICATION_COUNTER,
} from '../constants/events';
import notificationApi from '../resources/notificationApi';

export const getNotificationUnreadCounter =
  (useCache = true) =>
  (dispatch) => {
    dispatch({ type: GET_NOTIFICATION_COUNTER });
    return notificationApi
      .unreadCount({ onlyRolledOut: false }, useCache)
      .then((results) => {
        const { total } = results.data;
        dispatch({ type: RECEIVE_NOTIFICATION_COUNTER, total });
      });
  };
