import classnames from 'classnames';
import moment from 'moment';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import {
  RELEASE_DATA_MATURITY_DEADLINE_V2,
  hasFeature,
} from 'modules/feature-flag';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import { getUpcomingDeadline } from '../utils';

export const MaturityRuleSetIcon = ({ ruleSet, user, selectedRecipients }) => {
  const isExecuted = ruleSet.get('allExecuted') > 0;
  const hasFailed = isExecuted && ruleSet.get('failed') > 0;
  const hasSucceeded = isExecuted && !hasFailed;
  if (!isExecuted) {
    return <StandardRuleSetIcon ruleSet={ruleSet} />;
  }
  const upcomingDeadline = getUpcomingDeadline(
    user,
    ruleSet,
    selectedRecipients
  );
  if (!upcomingDeadline) {
    return <StandardRuleSetIcon ruleSet={ruleSet} />;
  }
  const upcomingDeadlineMoment = moment.parseZone(upcomingDeadline);
  const dateInPast = upcomingDeadlineMoment.isBefore(moment());
  if (dateInPast && hasSucceeded) {
    return <StandardRuleSetIcon ruleSet={ruleSet} />;
  }
  return (
    <LazyTooltip
      id={`ruleset-${ruleSet.get('id')}`}
      delayShow={0}
      tooltipLabel={i18n.t(
        'frontproductstream.view_as.maturity_tooltip.label',
        {
          defaultValue: 'deadline: {{date}}',
          date: upcomingDeadlineMoment.format('L'),
        }
      )}
    >
      <i
        data-testid="MaturityRuleSetIcon"
        className={classnames(
          'mdi',
          upcomingDeadlineMoment.isBefore(moment())
            ? 'mdi-calendar-alert'
            : hasSucceeded
            ? 'mdi-calendar-check'
            : 'mdi-calendar-clock'
        )}
      />
    </LazyTooltip>
  );
};

export function StandardRuleSetIcon({ ruleSet }) {
  const isExecuted = ruleSet.get('allExecuted') > 0;
  const hasFailed = isExecuted && ruleSet.get('failed') > 0;
  const hasSucceeded = isExecuted && !hasFailed;
  const isRequested = get(ruleSet, 'requested', true);
  return (
    <i
      data-testid="StandardRuleSetIcon"
      className={classnames('mdi', {
        ProductValidationStatusOk: hasSucceeded,
        ProductValidationStatusKo: hasFailed,
        ProductValidationStatusUnknown: !isExecuted,
        'ViewAs__ruleSetIcon--notRequested': !isRequested,
      })}
    />
  );
}

export const RuleSetIcon = ({ ruleSet, user, selectedRecipients }) => {
  const isMaturityRuleSet = ruleSet.get('type') === 'MATURITY';
  const hasMaturityDeadlineFlag = hasFeature(
    user,
    RELEASE_DATA_MATURITY_DEADLINE_V2
  );
  const icon =
    isMaturityRuleSet && hasMaturityDeadlineFlag ? (
      <MaturityRuleSetIcon
        ruleSet={ruleSet}
        user={user}
        selectedRecipients={selectedRecipients}
      />
    ) : (
      <StandardRuleSetIcon ruleSet={ruleSet} />
    );
  return <span className="ViewAs__ruleSetIcon">{icon}</span>;
};
