import i18n from 'utils/i18n';

export const ASSET_TYPE = {
  PICTURE: 'pictures',
  VIDEO: 'videos',
  DOCUMENT: 'documents',
  ENRICHED_CONTENT: 'enriched_contents',
};

export const ASSET_TYPES = Object.values(ASSET_TYPE);

export const FILE_TYPE_MAP = {
  'application/zip': i18n.t('frontproductstream.constants.file_type.document', {
    defaultValue: 'Document',
  }),
  'application/pdf': i18n.t(
    'frontproductstream.constants.file_type.pdf_document',
    { defaultValue: 'PDF Document' }
  ),
  'application/vnd.ms-excel': i18n.t(
    'frontproductstream.constants.file_type.excel_document',
    { defaultValue: 'Excel Document' }
  ),
  'application/vnd.ms-office': i18n.t(
    'frontproductstream.constants.file_type.office_document',
    { defaultValue: 'Office Document' }
  ),
  'application/vnd.ms-word': i18n.t(
    'frontproductstream.constants.file_type.word_document',
    { defaultValue: 'Word Document' }
  ),
  'application/vnd.ms-powerpoint': i18n.t(
    'frontproductstream.constants.file_type.powerpoint_document',
    { defaultValue: 'PowerPoint Document' }
  ),
  'application/msword': i18n.t(
    'frontproductstream.constants.file_type.word_document',
    { defaultValue: 'Word Document' }
  ),
  'application/msexcel': i18n.t(
    'frontproductstream.constants.file_type.excel_document',
    { defaultValue: 'Excel Document' }
  ),
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': i18n.t(
    'frontproductstream.constants.file_type.excel_document',
    { defaultValue: 'Excel Document' }
  ),
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    i18n.t('frontproductstream.constants.file_type.word_document', {
      defaultValue: 'Word Document',
    }),
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    i18n.t('frontproductstream.constants.file_type.powerpoint_document', {
      defaultValue: 'PowerPoint Document',
    }),
};

// ProductVideo
export const PRODUCT_VIDEO_TYPE_BRAINSONIC = { id: 0, name: 'TYPE_BRAINSONIC' };

// ProductEnrichedContent
export const PRODUCT_ENRICHED_CONTENT_TYPE_RECIPE = { id: 1, name: 'Recipe' };

// ProductPicture
export const PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_STANDARD = {
  id: 0,
  name: 'A',
};
export const PRODUCT_PICTURE_FILETYPE_PICTURE_DEFINITION_HIGH = {
  id: 1,
  name: 'D',
};
export const PRODUCT_PICTURE_FILETYPE_PICTURE_OTHER = { id: 2, name: 'Z' };

export const PRODUCT_PICTURE_TYPE_OF_CONTENT_UNPACKAGED = {
  id: 0,
  name: '0',
  help: 'Out of packaging',
  type: 'type_of_content',
};

export const PRODUCT_PICTURE_TYPE_OF_CONTENT_PACKAGED = {
  id: 1,
  name: '1',
  help: 'In packaging',
  type: 'type_of_content',
};

// Origin Media
export const ASSET_ORIGIN_STREAM_USER = { id: 1, name: 'ORIGIN_STREAM_USER' };

export const ASSET_SCOPE = {
  PUBLIC: 'public',
  SPECIFIC: 'specific',
};

export const ASSET_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};
