import { call, put, putResolve, takeLatest } from 'redux-saga/effects';

import { Authentication, Cookie } from '@alkem/sdk-dashboard';

import {
  fetchProductSegmentPermissions,
  getOrganizationPermissions,
  getProfile,
} from 'actions/user';
import { config } from 'constants/context';
import { loadPrefs } from 'constants/prefs';
import { STATUS_ACTIVE } from 'constants/user';
import { getOrganizationId, isManufacturer } from 'core/api/user';
import { getRecipients } from 'modules/recipients/actions';
import { fetchHasRfpModule } from 'modules/rfp';
import authApi from 'resources/authApi';
import * as routes from 'routes';
import { asAction } from 'utils/actions';
import { push } from 'utils/history';
import { logError } from 'utils/logging';
import { withQuery } from 'utils/query';
import { withCatch } from 'utils/saga';

import { authorized, notAuthorized } from '../actions';
import { LOGOUT, TRY_AUTHORIZATION } from '../actions/types';
import { setNextLocation } from '../utils';

export default function* sharedAuthSaga() {
  yield takeLatest(TRY_AUTHORIZATION, withCatch(tryAuthorizationSaga));
  yield takeLatest(LOGOUT, withCatch(logoutSaga));
}

export function* loadProfileSaga({ payload: useCache = true } = {}) {
  const { user, error } = yield putResolve(asAction(getProfile(useCache)));

  if (error) {
    yield put(notAuthorized());
    throw error;
  }

  if (user.get('status') !== STATUS_ACTIVE.id) {
    yield call(push, withQuery(routes.logout, { reason: 'deactivated_user' }));
    return;
  }

  if (!user.get('belongsTo').size) {
    yield call(push, withQuery(routes.logout, { reason: 'no_organization' }));
    return;
  }

  yield call(Authentication.initTokenStorageOnLoad);
  yield call(loadPrefs, user, config);
  yield put(authorized());
  yield put(asAction(fetchProductSegmentPermissions(user.get('id'))));
  yield put(asAction(getOrganizationPermissions()));

  // TODO: stop loading recipients when retailers.
  // currently required by notifications and target request modal.
  yield put(asAction(getRecipients()));
  if (isManufacturer(user)) {
    yield put(fetchHasRfpModule({ organizationId: getOrganizationId(user) }));
  }
}

export function* tryAuthorizationSaga({ payload: location }) {
  try {
    yield call(loadProfileSaga);
  } catch (error) {
    const { pathname, search } = location;
    yield call(setNextLocation, `${pathname}${search}`);
    const { status } = /** @type {{ status: number }} */ (error);
    if (status >= 400 && status < 500) {
      push(routes.login);
    }
  }
}

/**
 * @param {object} params
 * @param {{ nextlocation?: string }=} params.payload
 */
export function* logoutSaga({ payload = {} } = {}) {
  const { nextlocation } = payload;
  if (nextlocation) {
    yield call(setNextLocation, nextlocation);
  }

  try {
    yield call([authApi, authApi.UserLogout]);
  } catch (error) {
    logError(error);
  }
  Cookie.deleteCookie('admin_email');
  yield call(Authentication.clearTokensAfterLogout);
}
