import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { PRODUCT_UPDATE } from 'constants/permissions';
import { hasAllowPush } from 'core/api/organization-settings';
import {
  getOrganizationSettings,
  hasProductPermission,
  isRetailer,
} from 'core/api/user';
import { FEATURE_PERMISSIONS_V3_PRODUCT } from 'modules/feature-flag/constants';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  DISABLE_PERMISSION,
  PRODUCT_PERMISSION,
} from 'modules/permissions/const';
import RemoveFromActiveRangeModal from 'modules/remove-from-active-range';
import { showRemoveFromActiveRange } from 'modules/remove-from-active-range/actions';
import i18n from 'utils/i18n';

import '../index.scss';

const mapDispatchToProps = {
  showRemoveFromActiveRange,
};

export class RemoveFromActiveRangeAction extends PureComponent {
  static propTypes = {
    showRemoveFromActiveRange: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    isRetailer(user) &&
    !hasAllowPush(getOrganizationSettings(user)) &&
    (hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)
      ? hasPermissionModule(user, PRODUCT_PERMISSION, DISABLE_PERMISSION)
      : hasProductPermission(PRODUCT_UPDATE)(user));

  getLabel(nb) {
    return i18n.t('Remove from catalog {{count}} sheet(s)', { count: nb });
  }

  removeFromActiveRange = () => {
    const { selectedMap, productMap, disabled } = this.props;
    if (disabled) {
      return;
    }
    const checkedProducts = productMap.filter(
      (pv, i) => selectedMap.get(i) === true
    );

    this.props.showRemoveFromActiveRange(checkedProducts);
  };

  render() {
    const label = this.getLabel(this.props.selectedMap.size);

    return (
      <div>
        <>
          <div className="ActionOption" onClick={this.removeFromActiveRange}>
            {label}
          </div>
          <RemoveFromActiveRangeModal />
        </>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(RemoveFromActiveRangeAction);
