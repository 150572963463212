import { flow } from 'lodash/fp';

import { selectMainState } from '.';

export const selectModalState = (state) => selectMainState(state).modal;

export const selectIsVisible = flow(selectModalState, (modalState) =>
  modalState.get('visible')
);

export const selectInProgress = flow(selectModalState, (modalState) =>
  modalState.get('inProgress')
);

export const selectSelectedUserLabels = flow(selectModalState, (modalState) =>
  modalState.get('selected')
);

export const selectProductVersionIds = flow(selectModalState, (modalState) =>
  modalState.get('productVersionIds')
);

export const selectProductKeyIds = flow(selectModalState, (modalState) =>
  modalState.get('productKeyIds')
);

export const selectAllUserLabels = flow(selectModalState, (modalState) =>
  modalState.get('labels')
);

export const selectSortedUserLabels = flow(selectModalState, (modalState) =>
  modalState.get('sortedLabels')
);

export const selectUserLabelsContext = flow(selectModalState, (modalState) =>
  modalState.get('userLabelsContext')
);
