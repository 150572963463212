import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { CHASE } from 'constants/assignation';
import { TargetProductStatusWaitingToBeShared } from 'constants/targetProductStatus';
import { hasAssignation } from 'core/api/organization-settings';
import {
  getSharedOrganizationId,
  getTargetProductStatus,
} from 'core/api/retailerproductversion';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { openTargetRequestModal } from 'modules/target-request-modal/actions';
import i18n from 'utils/i18n';

import '../index.scss';

const mapDispatchToProps = {
  openTargetRequestModal,
};

class ChaseAction extends PureComponent {
  static propTypes = {
    openTargetRequestModal: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    isRetailer(user) && hasAssignation(getOrganizationSettings(user));

  static isEnabled = ({ productMap, selectedMap }) => {
    const pvs = productMap.filter((pv, i) => selectedMap?.get(i) === true);
    if (!pvs.size) {
      return false;
    }
    if (
      pvs.every(
        (pv) =>
          TargetProductStatusWaitingToBeShared.id === getTargetProductStatus(pv)
      ) === false
    ) {
      // all selection must be in this status
      return false;
    }
    const waitingToBeSharedProductVersions = pvs;
    // all waitingToBeShared products of selection must share same source org
    const manufacturerId = getSharedOrganizationId(
      waitingToBeSharedProductVersions.first()
    );
    return waitingToBeSharedProductVersions.every(
      (pv) => getSharedOrganizationId(pv) === manufacturerId
    );
  };

  constructor(props) {
    super(props);
    this.chase = this.chase.bind(this);
  }

  getChasableProducts() {
    // retrieve list of product with state in [accepted] within selection
    const { selectedMap, productMap } = this.props;
    return productMap.filter(
      (pv, i) =>
        selectedMap.get(i) === true &&
        TargetProductStatusWaitingToBeShared.id === getTargetProductStatus(pv)
    );
  }

  getLabel() {
    return i18n.t('Remind');
  }

  chase() {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    const products = this.getChasableProducts();
    this.props.openTargetRequestModal({
      products,
      title: i18n.t('Reminding manufacturer'),
      chaseType: CHASE,
    });
  }

  render() {
    const label = this.getLabel();
    return (
      <div className="ActionOption" onClick={this.chase}>
        {label}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(ChaseAction);
