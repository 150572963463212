import classNames from 'classnames';
import { flatten, get } from 'lodash/fp';
import { useState } from 'react';

import CustomAutocomplete from 'components/ui/autocomplete/custom';
import InputWithLabel from 'components/ui/input/input-with-label';
import { DataOpsEditPlugin } from 'modules/data-ops';
import { hasPermissionsByEntity } from 'modules/permissions';
import {
  PATCH_PERMISSION,
  PRODUCT_PERMISSION,
} from 'modules/permissions/const';
import i18n from 'utils/i18n';

import { ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT } from '../../constants';
import { isConsumerOrDisplayUnit } from '../../helpers';

import { DeepUnit, ObjectPath } from './utils';

interface Props {
  flattenedUnits: DeepUnit[];
  hasDataOps: boolean;
  isPatchedHierarchy?: boolean;
  onChange: (event: { value: any }) => void;
  targetMarketId: number;
  unitLabel: string;
  unitReferentialUri: string;
  valuePaths: ObjectPath[];
  model: string;
  isDataOpsPatcher?: boolean;
  isDataOpsReceiver?: boolean;
}

export function LogisticalHierarchyUnitField({
  flattenedUnits,
  hasDataOps,
  isPatchedHierarchy,
  onChange,
  targetMarketId,
  unitLabel,
  unitReferentialUri,
  valuePaths,
  model,
  isDataOpsPatcher,
}: Props) {
  const [isEditable, setEditable] = useState(false);
  const isPatchable = isDataOpsPatcher && hasDataOps && !isPatchedHierarchy;
  let canPatch = false;

  if (isPatchable) {
    const someUnit = flattenedUnits.find(
      (unit) => !isConsumerOrDisplayUnit(unit.versionData)
    );
    canPatch =
      !!someUnit &&
      hasPermissionsByEntity({
        entity: someUnit.versionData,
        entityType: PRODUCT_PERMISSION,
        permissions: [PATCH_PERMISSION],
      });
    if (!canPatch) {
      return null;
    }
  } else if (flattenedUnits.every((unit) => unit.isReadOnly)) {
    return null;
  }

  // If all have the same unit, display it.
  const units = flatten(
    flattenedUnits
      .filter((unit) =>
        isPatchable
          ? !isConsumerOrDisplayUnit(unit.versionData)
          : !unit.isReadOnly
      )
      .map((unit) =>
        valuePaths.map((path) =>
          get([...path, 0, 'expressedIn'], unit.versionData)
        )
      )
  );
  let displayedUnit: any;
  if (
    units.length > 0 &&
    units.every((unit) => get(['id'], unit) === get(['id'], units[0]))
  ) {
    [displayedUnit] = units;
  }
  // Display a warning/error if no single unit found.
  let error: any;
  if (units.some((u) => !u)) {
    // Missing units.
    error = (
      <span className="alk-txt-danger">
        <i className="mdi mdi-alert" />
        {i18n.t(
          'frontproductstream.logistical_hierarchies_unit_field.missing_unit.error',
          { defaultValue: 'Please select a unit of measure' }
        )}
      </span>
    );
  } else if (!displayedUnit) {
    // Multiple units.
    error = (
      <span className="alk-txt-highlight">
        <i className="mdi mdi-alert" />
        {i18n.t(
          'frontproductstream.logistical_hierarchies_unit_field.multiple_unit.error',
          { defaultValue: 'Multiple units of measure selected' }
        )}
      </span>
    );
  }

  return (
    <div
      className={classNames('FormField', {
        'FormField--editable': isPatchable && isEditable,
      })}
    >
      <InputWithLabel label={unitLabel}>
        <CustomAutocomplete
          id={`unit-field-${model}`}
          className="InputField__input"
          value={displayedUnit ? [displayedUnit] : []}
          onSelect={onChange}
          onUnselect={isPatchable ? null : onChange}
          path={unitReferentialUri}
          params={{ target_market_id: targetMarketId }}
          sort="alphabetical"
          searchOnClick
          disabled={isPatchable ? !isEditable : false}
        />
        {error}
      </InputWithLabel>
      {canPatch && (
        <div className="alk-flex alk-flex-center FormField__actions">
          <DataOpsEditPlugin
            field={{ model: 'displayedUnit' }}
            entityId={0}
            entityKind={ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT}
            isEditable={isEditable}
            onEdit={setEditable}
          />
        </div>
      )}
    </div>
  );
}
