import classNames from 'classnames';

import Collapsible, { Props as CollapsibleProps } from 'components/collapsible';

import './index.scss';

interface AdditionalProps {
  header?: {
    icon: string;
    className?: string;
    text: string;
  };
}

type Props = CollapsibleProps & AdditionalProps;

const CollapsiblePageSection = ({
  children,
  className,
  title,
  header,
  ...props
}: Props) => (
  <Collapsible
    {...props}
    className={classNames('CollapsiblePageSection', className)}
    title={
      <div className="alk-flex alk-flex-center alk-flex-space-between">
        <h1>{title}</h1>
        <span className="alk-flex alk-flex-center">
          {header && (
            <>
              <i
                className={classNames(
                  'Collapsible__header__icon mdi',
                  `mdi-${header.icon}`,
                  header.className
                )}
              />
              <span>{header.text}</span>
            </>
          )}
        </span>
      </div>
    }
  >
    {children}
  </Collapsible>
);

export default CollapsiblePageSection;
