import classNames from 'classnames';
import { noop } from 'lodash';
import { ReactNode } from 'react';

import './styles.scss';

interface CollapseButtonProps {
  className?: string;
  big?: boolean;
  collapsed: boolean;
}

const CollapseButton = ({ collapsed, className, big }: CollapseButtonProps) => (
  <span
    className={classNames(
      'Collapsible__collapse-button',
      className,
      big && 'Collapsible__collapse-button_big'
    )}
  >
    <i
      className={classNames('mdi', {
        'mdi-chevron-down': collapsed,
        'mdi-chevron-up': !collapsed,
      })}
    />
  </span>
);

interface CollapsibleContentProps {
  children: ReactNode;
  collapsed: boolean;
}

const CollapsibleContent = ({
  collapsed,
  children,
}: CollapsibleContentProps) => (
  <div
    className={classNames('Collapsible__content', {
      'Collapsible__content-collapsed': collapsed,
    })}
  >
    {children}
  </div>
);

export interface Props {
  children: ReactNode;
  className?: string;
  title: ReactNode;
  collapsed: boolean;
  toggle(): void;
  disabled?: boolean;
  big?: boolean;
  noMargins?: boolean;
  noRenderIfCollapsed?: boolean;
  heading?: boolean;
}

export const Collapsible = ({
  children,
  className,
  title,
  collapsed,
  toggle,
  disabled,
  big,
  noRenderIfCollapsed,
  noMargins,
  heading = big,
}: Props) => {
  const isCollapsed = disabled || collapsed;
  return (
    <div
      className={classNames('Collapsible', className, {
        'Collapsible--noMargins': noMargins,
      })}
    >
      <div
        className={classNames(
          'Collapsible__header',
          'alk-flex alk-flex-center alk-flex-space-between',
          {
            'Collapsible__header--disabled': disabled,
            'Collapsible__header--collapsed': isCollapsed,
          }
        )}
        onClick={disabled ? noop : toggle}
      >
        {big && heading ? (
          <h3>{title}</h3>
        ) : (
          <div className="alk-flex-1">{title}</div>
        )}
        <CollapseButton collapsed={isCollapsed} big={big} />
      </div>
      <CollapsibleContent collapsed={isCollapsed}>
        {(!isCollapsed || !noRenderIfCollapsed) && children}
      </CollapsibleContent>
    </div>
  );
};

export default Collapsible;
