import { isNotThirdParty } from 'core/api/user';
import { selectHasCatalogFilterQualityFeature } from 'modules/feature-flag/selectors';

import { isAvailableForProductStatus } from '../../utils/filters/product-status';

import { postShareProductStatus } from './share-status';

export const dataQualityFilter = {
  key: 'validation.status',
  query: 'validation',
  isAvailable: ({ user, selectedFilterTargetProductStatus } = {}) =>
    selectHasCatalogFilterQualityFeature(user) &&
    isNotThirdParty(user) &&
    isAvailableForProductStatus(
      selectedFilterTargetProductStatus,
      postShareProductStatus
    ),
  customField: 'recipients_validation_status',
};
