import { put, select } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import { FEATURE_PERMISSIONS_V3_PRODUCT } from 'modules/feature-flag/constants';
import { selectEditedDataMap } from 'modules/logistical-hierarchies/selectors';
import {
  hasInstancePermissions,
  hasPermissionV3,
  selectPermissions,
} from 'modules/permissions';
import {
  PATCH_PERMISSION,
  PRODUCT_PERMISSION,
} from 'modules/permissions/const';
import {
  selectEditedProductVersion,
  selectProductKeyId,
} from 'reducers/productVersion';
import { selectUser } from 'reducers/user/selectors';
import { ProductLogisticalHierarchyData, ProductVersionData } from 'types';
import { i18n } from 'utils/i18n';

import { dataOpsLuEntityId } from '../selectors';

type PvData = ProductVersionData['data'];
type LuData = ProductLogisticalHierarchyData['data'];
type LuVersion = ProductLogisticalHierarchyData['data']['version'];

export const hierarchyEntityId = (hierarchy: LuData) =>
  dataOpsLuEntityId(hierarchy.internalRootId, hierarchy.gtin);

export function* getEntityById(entityId: number | string) {
  const pv: PvData = yield select(selectEditedProductVersion);
  const hierarchies: {
    [key: string]: LuData;
  } = yield select(selectEditedDataMap);
  const entityIdToEntity: {
    [key: number]: PvData | LuVersion;
  } = { [pv.id]: pv };
  for (const hierarchy of Object.values(hierarchies)) {
    entityIdToEntity[hierarchyEntityId(hierarchy)] = hierarchy.version;
  }
  const entity: PvData | LuVersion = entityIdToEntity[entityId];
  if (!entity) {
    throw new Error(
      i18n.t(
        'frontproductstream.product_collaborative_edit.product_entity_not_found.notification',
        {
          defaultValue: 'Entity {{entityId}} not found',
          entityId,
        }
      )
    );
  }
  return entity;
}

export function* hasPatchPermission({
  withNotif,
}: { withNotif?: boolean } = {}) {
  try {
    const user = yield select(selectUser);
    if (hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)) {
      const permissions = yield select(selectPermissions);
      const productKeyId = yield select(selectProductKeyId);
      const canPatch = hasInstancePermissions(
        permissions,
        PRODUCT_PERMISSION,
        productKeyId,
        PATCH_PERMISSION
      );
      if (withNotif && !canPatch) {
        yield put(
          notificationError(
            i18n.t(
              'frontproductstream.product_collaborative_edit.insufficient_permission_on_product.notification',
              {
                defaultValue:
                  'You do not have the permission to patch this product',
              }
            )
          )
        );
      }
      return canPatch;
    }
  } catch {
    // noop
  }
  return true;
}
