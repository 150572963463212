import { AssetTypeV2, Field } from './types';

const getIsArchived = (asset: AssetTypeV2) => {
  if (asset._type !== 'ProductPicture') {
    return false;
  }

  const { pictureEffectiveStartDate, pictureEffectiveEndDate } = asset;

  return (
    (pictureEffectiveStartDate &&
      Date.parse(pictureEffectiveStartDate) > Date.now()) ||
    (pictureEffectiveEndDate &&
      Date.parse(pictureEffectiveEndDate) < Date.now())
  );
};

const getPreviewUri = (asset: AssetTypeV2) => {
  if (asset._type !== 'ProductPicture') {
    return '';
  }

  const wantedExportable =
    Array.isArray(asset.exportables) &&
    asset.exportables.find((e) => e.width === 256);

  const assetToDisplay = wantedExportable || asset;

  return assetToDisplay?.uniformResourceIdentifier;
};

const getLabel = (asset: AssetTypeV2) => {
  switch (asset._type) {
    case 'ProductPicture':
      return asset.fileContentTypeCode?.label || asset.fileTypeCode?.label;
    case 'ProductDocument':
      return asset.fileName;
    case 'ProductEnrichedContent':
      return asset.title;
    case 'ProductVideo':
      return asset.fileNameText;
  }
};

const getFileType = (asset: AssetTypeV2) => {
  switch (asset._type) {
    case 'ProductPicture':
      return {
        iconName: 'image',
        fileTypelabel: 'Image',
      };
    case 'ProductDocument':
      return {
        iconName: 'file-document',
        fileTypelabel: 'Document',
      };
    case 'ProductVideo':
      return {
        iconName: 'video',
        fileTypelabel: 'Video',
      };
    case 'ProductEnrichedContent':
      return {
        iconName: 'food',
        fileTypelabel: 'Recipe',
      };
  }
};

export const getAssetDerivedData = (asset: AssetTypeV2) => {
  return {
    isArchived: getIsArchived(asset),
    previewUri: getPreviewUri(asset),
    label: getLabel(asset),
    fileType: getFileType(asset),
  };
};

export const areAssetReadOnly = ({
  field,
  assetType,
}: {
  field: Field;
  assetType: 'document' | 'picture' | 'recipe' | 'video';
}) => field.options[assetType].readOnly;
