import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import TargetRequestModal from './TargetRequestModal';
import { TARGET_REQUEST_MODAL_STORE_KEY } from './constants';
import reducers from './reducers';
import mainSaga from './sagas';

registerModuleReducer(TARGET_REQUEST_MODAL_STORE_KEY, reducers);
registerSaga(mainSaga);

export default TargetRequestModal;
