import { fromJS } from 'immutable';
import { call, delay, put, select } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import { RECEIVE_RULE_RESULTS } from 'modules/validation';
import { selectOrganizationId as selectUserOrganizationId } from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { takeLatestSafe } from 'utils/saga';

import api from '../api';
import {
  FETCH_PRODUCTS_SUCCESS,
  VALIDATE,
  VALIDATE_FAILURE,
  VALIDATE_SUCCESS,
} from '../constants';
import { CLOSE_EDIT_MODAL } from '../edit-modal/constants';
import {
  selectHierarchies,
  selectId,
  selectIsPublicationModalOpen,
  selectIsTarget,
  selectName,
  selectProducts,
  selectSelectedRecipient,
  selectSharingUnitTariffData,
  selectType,
} from '../selectors';

export function* watchValidate() {
  yield takeLatestSafe(
    [VALIDATE, FETCH_PRODUCTS_SUCCESS, CLOSE_EDIT_MODAL],
    validate
  );
}

/**
 * Function use to clean hierarchies and remove the 'version' node
 * To avoid having too big payload sent to validation.
 * Be careful when adding validation rule on =_full_data_.logisticalHierarchies for tariff
 * because there will be no version in the JSON
 */
function _cleanHierarchies(hierarchies) {
  for (let hierarchy of hierarchies) {
    delete hierarchy.version;
    if (hierarchy.children) {
      hierarchy['children'] = _cleanHierarchies(hierarchy.children);
    }
  }
  return hierarchies;
}

export function* validate() {
  yield delay(500);
  const id = (yield select(selectId)) || 0;
  const name = yield select(selectName);
  const type = yield select(selectType);
  const data = yield select(selectSharingUnitTariffData);
  const recipient = yield select(selectSelectedRecipient);
  const sourceOrganizationId = yield select(selectUserOrganizationId);
  const sharingUnits = yield select(selectProducts);
  const hierarchies = yield select(selectHierarchies);
  const checkImports = yield select(selectIsPublicationModalOpen);
  const isTarget = yield select(selectIsTarget);

  if (!recipient) {
    return;
  }

  const enrichedSharingUnits = sharingUnits.map((su) => {
    const enriched = {
      ...su,
      logisticalHierarchies: _cleanHierarchies(
        hierarchies[
          isTarget ? su.target_product_key.id : su.source_product_key.id
        ]
      ),
      specializes: { id: su.product.id },
    };

    return enriched;
  });

  const { result, error } = yield call(api.validate, id, {
    data: {
      _type: 'SharingUnitTemplate',
      id,
      name,
      type,
      targetOrganization: { id: recipient.get('id') },
      sourceOrganization: { id: sourceOrganizationId },
      data,
      sharingUnits: enrichedSharingUnits,
    },
    languages: recipient.get('supportsLocales'),
    filter_source_include: ['gtin'],
    check_imports: checkImports,
  });

  if (!error) {
    const results = fromJS(result.result).setIn(['entity', 'id'], id);
    yield put({
      type: RECEIVE_RULE_RESULTS,
      data: results,
      // TODO CCS-tariff: handle this more gracefully.
      versionId: id,
      sharingUnits,
      logisticalUnits: [],
    });
    yield put({
      type: VALIDATE_SUCCESS,
      result,
    });
  } else {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.validate_notification.error',
          { defaultValue: 'An error occured validating the price template' }
        ),
        { context: 'modal' }
      )
    );
    yield put({ type: VALIDATE_FAILURE, error });
    yield call(logError, error);
  }
}
