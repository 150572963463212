import {
  call,
  fork,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { stopLoading } from 'actions/navigation';
import { notificationError } from 'actions/notification';
import { RETRY_OR_CONTACT_SUPPORT } from 'constants/errors';

import { logError } from './logging';

export function withCatch(
  saga,
  {
    withNotification = false,
    errorMessage,
    stopLoadingOnCatch = false,
    stopLoadingEvent = null,
  }: {
    withNotification?: boolean;
    errorMessage?: string | ((error: any) => string);
    stopLoadingOnCatch?: boolean;
    stopLoadingEvent?: (() => any) | null;
  } = {}
) {
  return function* withCatchSaga(...args) {
    let requestDone = false;
    let hasError = false;
    try {
      yield call(saga, ...args);
      requestDone = true; // Request aborted by redux-saga
    } catch (error: any) {
      hasError = true;
      logError(error);
      if (withNotification) {
        yield put(
          notificationError(
            (typeof errorMessage === 'function'
              ? errorMessage(error)
              : errorMessage) ||
              error.notificationMessage ||
              RETRY_OR_CONTACT_SUPPORT,
            { error }
          )
        );
      }
    } finally {
      if (!requestDone || hasError) {
        if (stopLoadingOnCatch) {
          yield put(stopLoading());
        }
        if (stopLoadingEvent) {
          yield put(stopLoadingEvent());
        }
      }
    }
  };
}

export function withNotificationError(errorMessage) {
  return { withNotification: true, errorMessage };
}

export const takeFirst = (pattern, saga, ...args) =>
  fork(function* () {
    while (true) {
      const action = yield take(pattern);
      yield call(saga, ...args.concat(action));
    }
  });

export function takeFirstSafe(action, saga, catchParams) {
  return takeFirst(action, withCatch(saga, catchParams));
}

export function withCancel(saga, action) {
  return function* _cancel(...args) {
    yield put({ type: action });
    yield race({
      task: call(saga, ...args),
      cancel: take(action),
    });
  };
}

export function takeEverySafe(action, saga, catchParams?) {
  return takeEvery(action, withCatch(saga, catchParams));
}

export function takeLatestSafe(action, saga, catchParams?) {
  return takeLatest(action, withCatch(saga, catchParams));
}
