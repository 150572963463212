import classNames from 'classnames';
import { List, Map } from 'immutable';
import { ReactNode, RefCallback, memo } from 'react';

import { ListTableHeader, ListTableHeaderProps } from './header';
import './index.scss';

type Props = {
  children: ReactNode;
  className?: string;
  referentials?: List<any> | any[];
  columnMap?: Map<any, any> | object;
  forwardedRef?: RefCallback<any>;
  stickyHeader?: boolean;
  withHeaderMultiLines?: boolean;
  useEllitipsHeader?: boolean;
  ellitipsHeaderLines?: any;
} & Omit<ListTableHeaderProps, 'referentials' | 'columnMap'>;

const ListTable = memo(
  ({
    forwardedRef,
    sorting = Map(),
    className,
    children,
    stickyHeader,
    withHeaderMultiLines,
    useEllitipsHeader,
    ellitipsHeaderLines,
    columnMap,
    referentials,
    ...props
  }: Props) => (
    <table className={classNames('ListTable', className)} ref={forwardedRef}>
      {columnMap && referentials && (
        <thead>
          <ListTableHeader
            sticky={stickyHeader}
            sorting={sorting}
            withMultiLines={withHeaderMultiLines}
            useEllitips={useEllitipsHeader}
            ellitipsLines={ellitipsHeaderLines}
            columnMap={columnMap}
            referentials={referentials}
            {...props}
          />
        </thead>
      )}
      <tbody className="ListTable__tbody">{children}</tbody>
    </table>
  )
);

export * from './header';
export * from './cell';
export * from './row';
export { ListTable };
export default ListTable;
