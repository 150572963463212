import { List, Map } from 'immutable';
import { negate as not } from 'lodash/fp';
import { createSelector } from 'reselect';

import { selectFilteredDisplayGroups } from 'modules/view-as/selectors/fields';

import { isEmptyListToListContainingEmptyList } from '../utils';

import {
  getContribution,
  getPhysicalCheckFailedFields,
  getPhysicalCheckFields,
  getPhysicalCheckPassedFields,
} from '.';

export const selectThirdPartyPhysicalCheckFields = createSelector(
  selectFilteredDisplayGroups,
  (specificFields) => {
    const queue = [...specificFields];
    const result = {};
    while (queue.length > 0) {
      const node = queue.pop();
      // we do not support contribution for synoyms yet, we
      // should remove this synonyms check when we do.
      if (node.level === 1 && node.model !== 'synonyms') {
        result[node.model] = node;
      } else if (node.items && node.items.length > 0) {
        node.items.forEach((item) => queue.push(item));
      }
    }
    return result;
  }
);

export const remainingPhysicalCheckFieldsNumber = createSelector(
  selectThirdPartyPhysicalCheckFields,
  getPhysicalCheckFields,
  (fields, physicalcheckFields) =>
    Object.keys(fields).length - physicalcheckFields.count()
);

export const hasPhysicalCheckCanFail = createSelector(
  getPhysicalCheckFailedFields,
  remainingPhysicalCheckFieldsNumber,
  (failedFields, remainingFields) =>
    remainingFields === 0 && failedFields.count() > 0
);

export const hasPhysicalCheckCanPass = createSelector(
  selectThirdPartyPhysicalCheckFields,
  getPhysicalCheckPassedFields,
  (fields, passedFields) => passedFields.count() === Object.keys(fields).length
);

export const getCleanedContributionDiff = createSelector(
  getContribution,
  (contribution) =>
    contribution
      .get('contributionDiff', List())
      .filter(not(isEmptyListToListContainingEmptyList))
);

export const getFailedFieldFiltered = createSelector(
  getCleanedContributionDiff,
  getPhysicalCheckFailedFields,
  selectThirdPartyPhysicalCheckFields,
  (contributionDiff, failedFields, fields) => {
    const diffByModel = contributionDiff.reduce(
      (acc, diffLine) =>
        acc.update(diffLine.getIn(['name']), List(), (list) =>
          list.push(diffLine)
        ),
      Map()
    );
    return failedFields.reduce((acc, value, field) => {
      // check if the field is in diff, diff only return label we have to check on it
      if (!fields[field]) {
        return acc;
      }
      if (diffByModel.get(field)) {
        return acc.setIn(['failed', field], diffByModel.get(field));
      } else {
        return acc.setIn(['tolerance', field], fields[field].label);
      }
    }, Map({ failed: Map(), tolerance: Map() }));
  }
);
