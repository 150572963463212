import { Application, ResponseWithData } from '@alkem/sdk-dashboard';

import { notificationError } from 'actions/notification';
import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import {
  RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS,
  RECEIVE_PROFILE,
  RECEIVE_USER_ORGANIZATION_PERMISSIONS,
  UPDATE_USERSETTING,
  UPDATE_USER_FIELD,
} from 'constants/events/user';
import { getOrganizationId } from 'core/api/user';
import { selectUserSetting } from 'reducers/user/selectors';
import applicationApi from 'resources/applicationApi';
import authApi, {
  authApiImmutable,
  upsertUserSetting,
} from 'resources/authApi';
import { UserImmutable } from 'types';
import i18n, { setProfileLanguage } from 'utils/i18n';
import { fromJsIfNotImmutable } from 'utils/immutable';
import { logError } from 'utils/logging';
import { createAction } from 'utils/redux';
import { updateTracking } from 'utils/tracking';
import { identifyDataDogSession } from 'utils/tracking/datadog';

export const receiveProductSegmentsPermissions = (productSegments) => ({
  type: RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS,
  payload: { productSegments },
});

export const fetchProductSegmentPermissions = (userId) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await authApiImmutable.ListProductSegmentsPermissions(userId);
    dispatch(receiveProductSegmentsPermissions(data.get('productsegments')));
  } catch (error) {
    logError(error);
    dispatch(
      notificationError(
        i18n.t(
          'frontproductstream.user.fetch_product_segment_permissions_error.notification',
          {
            defaultValues:
              'An error occured while fetching product segments permissions',
          }
        )
      )
    );
  }
};

export const getOrganizationPermissions = () => (dispatch, getState) => {
  const { user } = getState();
  const organizationId = getOrganizationId(user);
  if (!organizationId) {
    return Promise.reject();
  }
  dispatch({ type: START_LOADING });

  return authApiImmutable
    .userManagesOrganizationShow(organizationId)
    .then((response) => {
      dispatch({ type: STOP_LOADING });
      dispatch({
        type: RECEIVE_USER_ORGANIZATION_PERMISSIONS,
        payload: {
          permissions: response.data.data.get('permissions') || [],
        },
      });
    })
    .catch(() => {
      dispatch({ type: STOP_LOADING });
      dispatch(
        notificationError(
          i18n.t(
            'frontproductstream.user.fetch_organization_permissions_error.notification',
            {
              defaultValue:
                "An error occured while retrieving your organization's permissions",
            }
          )
        )
      );
    });
};

export const getProfile =
  (useCache = true) =>
  async (dispatch) => {
    dispatch({ type: START_LOADING });
    try {
      const [userRes, appsRes]: [
        ResponseWithData<UserImmutable>,
        ResponseWithData<Application[]>
      ] = await Promise.all([
        authApiImmutable.UserMe(
          {
            withOrganizationSettings: true,
            withUserSettings: true,
            withNetwork: true,
            withSsoInfo: true,
            withOrganizationLegalIdentifiers: true,
            withModules: true,
            withOrganizationList: true,
          },
          useCache
        ) as any,
        applicationApi
          .ApplicationList({
            visibilities: false,
            isEnabled: true,
            isActive: true,
          })
          .catch((err) => {
            logError(err);
            return { data: { data: [] as Application[] } };
          }) as any,
      ]);
      const user: UserImmutable = userRes.data.data.set(
        'applications',
        fromJsIfNotImmutable(appsRes.data.data || [])
      );
      const reloadIfLangChanged = setProfileLanguage(user.get('language'));

      updateTracking(user);
      identifyDataDogSession(user);
      dispatch({ type: RECEIVE_PROFILE, user });
      dispatch({ type: STOP_LOADING });
      reloadIfLangChanged();

      return { user };
    } catch (error) {
      dispatch({ type: STOP_LOADING });
      return { error };
    }
  };

export const updateUserSetting = (key, value) => async (dispatch, getState) => {
  value = fromJsIfNotImmutable(value);
  const oldValue = selectUserSetting(key, getState());
  try {
    dispatch({ type: UPDATE_USERSETTING, key, value });
    await upsertUserSetting({ key, value });
  } catch (error: any) {
    dispatch({ type: UPDATE_USERSETTING, key, value: oldValue });
    dispatch(
      notificationError(
        i18n.t('frontproductstream.user.update_setting_error.notification', {
          defaultValue: 'An error occured while updating your setting',
        }),
        { error }
      )
    );
  }
};

export const updateUserField = createAction(UPDATE_USER_FIELD);

export const cancelClaimGLN = (claimId: number) => () =>
  authApi.UserClaimGlnDelete(claimId);
