import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import UnreadCount from 'components/unread-count';
import { NOTIFICATION_UPDATE_INTERVAL } from 'constants/config/default';
import * as routes from 'routes';

import { getNotificationUnreadCounter } from './actions';
import './notification-unread.scss';

const mapStateToProps = (state) => ({
  total: state.userNotification.unread.get('total'),
  user: state.user,
});

let stopAnimateTimerId;
let refreshTimerId;

export class NotificationUnread extends Component {
  static ANIMATION_TIME = 3000;

  static propTypes = {
    total: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { animate: true };
    this.refresh = this.refresh.bind(this);
    this.stopAnimate = this.stopAnimate.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    const { total } = this.props;
    if (prevProps.total < total) {
      this.setState({ animate: true }); // eslint-disable-line
      global.clearTimeout(stopAnimateTimerId);
      stopAnimateTimerId = global.setTimeout(
        this.stopAnimate,
        NotificationUnread.ANIMATION_TIME
      );
    }
  }

  componentWillUnmount() {
    global.clearTimeout(refreshTimerId);
    global.clearTimeout(stopAnimateTimerId);
  }

  stopAnimate() {
    this.setState({ animate: false });
  }

  refresh() {
    const { dispatch } = this.props;
    dispatch(getNotificationUnreadCounter()).then(() => {
      global.clearTimeout(refreshTimerId);
      refreshTimerId = global.setTimeout(
        this.refresh,
        NOTIFICATION_UPDATE_INTERVAL
      );
    });
  }

  render() {
    const { total } = this.props;
    const { animate } = this.state;
    const bellClasses = {
      mdi: true,
      'mdi-bell': true,
      NotificationUnread__bell: true,
      'NotificationUnread__bell--animate': total && animate,
    };

    return (
      <div className="NotificationUnread">
        <Link
          to={{ pathname: routes.notifications, search: 'read=false' }}
          className="NotificationUnread__indicator"
        >
          <i className={classNames(bellClasses)} />
          <UnreadCount absolute count={total} />
        </Link>
      </div>
    );
  }
}

export default connect(mapStateToProps)(NotificationUnread);
