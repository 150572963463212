import { bool, func, shape, string } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { closeModal, sendPromote } from '../../actions';
import { selectIsLoading, selectIsOpen } from '../../selectors';
import { getModalName, isFormValid } from '../../utils';

import PromoteProductsModalBody from './body';

const mapStateToProps = (state) => ({
  isOpen: selectIsOpen(state),
  isLoading: selectIsLoading(state),
  formValid: isFormValid(state),
  modalName: getModalName(state),
});

const mapDispatchToProps = {
  closeModal,
  sendPromote,
};

export class PromoteProductsModal extends PureComponent {
  static propTypes = {
    isOpen: bool,
    isLoading: bool,
    formValid: bool,
    modalName: string.isRequired,
    actions: shape({
      closeModal: func.isRequired,
      sendPromote: func.isRequired,
    }).isRequired,
  };

  closeModal = () => {
    const { actions, isLoading } = this.props;
    if (!isLoading) {
      actions.closeModal();
    }
  };

  render() {
    const { actions, formValid, isLoading, isOpen, modalName } = this.props;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        modalStyle="dynamic"
        title={modalName}
        confirmButtonText={i18n.t('Send')}
        confirmDisabled={!formValid}
        isProcessing={isLoading}
        onConfirm={actions.sendPromote}
        onClose={this.closeModal}
      >
        <PromoteProductsModalBody />
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(PromoteProductsModal);
