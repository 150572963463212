import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError } from 'actions/notification';
import {
  isManufacturer as isUserManufacturer,
  isRetailer as isUserRetailer,
} from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_PERMISSIONS_V3_SHARINGUNITTARIFF } from 'modules/feature-flag/constants';
import { selectHasTariffFilterSearch } from 'modules/feature-flag/selectors';
import { registerPermission } from 'modules/permissions';
import { selectUser } from 'reducers/user/selectors';
import * as routes from 'routes';
import { push } from 'utils/history';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { logError } from 'utils/logging';

import { selectCurrentRecipient } from '../actions';
import api from '../api';
import {
  FETCH_PUBLICATION_RESULTS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_SUCCESS,
  SEARCH_LIST,
  UPDATE_SELECTED_FILTERS,
} from '../constants';
import {
  sharingUnitTariffAvailabilityDatesFilter,
  sharingUnitTariffRecipientFilter,
  sharingUnitTariffStatusFilter,
  sharingUnitTariffSupplierCodesFilter,
  sharingUnitTariffTypesFilter,
  sharingUnitTariffUpdatedFilter,
} from '../constants/filters';
import {
  selectIsTarget,
  selectPagination,
  selectSearchQuery,
  selectSelectedFilterMap,
} from '../selectors';

export function* watchFetch() {
  yield takeEvery(FETCH_TEMPLATE, fetchTemplate);
  yield takeLatest(
    [FETCH_TEMPLATES, UPDATE_SELECTED_FILTERS, SEARCH_LIST],
    fetchTemplates
  );
}

export function* fetchTemplate({ id }) {
  const loggedUser = yield select(selectUser);
  const hasPermissionV3 = hasFeature(
    loggedUser,
    FEATURE_PERMISSIONS_V3_SHARINGUNITTARIFF
  );
  const { result, error } = yield call(
    hasPermissionV3 ? api.getV3_5 : api.get,
    id
  );

  if (result) {
    yield put(
      registerPermission({
        entity_type: 'sharingunittemplate',
        id: result.data.id,
        permissions: result.permissions,
      })
    );
    const template = result.data;
    yield put({
      type: FETCH_TEMPLATE_SUCCESS,
      template,
    });
    yield put(selectCurrentRecipient(get(template, 'targetOrganization.id')));

    const isTarget = yield select(selectIsTarget);
    if (template.has_failed_publication && !isTarget) {
      yield put({ type: FETCH_PUBLICATION_RESULTS, offset: 0 });
    }
  } else {
    yield put({ type: FETCH_TEMPLATE_FAILURE, error });
    yield call(logError, error);

    let message = i18n.t(
      'frontproductstream.sharingunit_tariff.retrieve_notification.error',
      { defaultValue: 'An error occured while fetching the template' }
    );
    if (error && error.status === 403) {
      message = i18n.t(
        'frontproductstream.sharingunit_tariff.retrieve_permission_notification.error',
        { defaultValue: "You don't have access to this template" }
      );
    }
    yield put(notificationError(message));
    yield call(push, routes.SharingUnitTariffsList);
  }
}

export function* getListParams() {
  const selectedFilterMap = yield select(selectSelectedFilterMap);
  const user = yield select(selectUser);
  const isManufacturer = isUserManufacturer(user);
  const isRetailer = isUserRetailer(user);
  const hasTariffFilterSearch = yield select(selectHasTariffFilterSearch);

  const toArray = (selectedFilter) =>
    selectedFilter ? selectedFilter.keySeq().toArray() : [];

  const intervalToISODate = (from, to, withTime) =>
    [from, to].map((dateValue, index) => {
      if (dateValue) {
        const date = dateValue.clone();
        if (withTime) {
          date[index === 0 ? 'startOf' : 'endOf']('day').utc();
        }
        return date.format('YYYY-MM-DDTHH:mm:ss');
      }
    });

  const isAvailableData = { user, isManufacturer, isRetailer };
  const availableFilters = [
    [
      sharingUnitTariffStatusFilter,
      (filter, selected) => ({ statuses: toArray(selected.get(filter.key)) }),
    ],
    [
      sharingUnitTariffSupplierCodesFilter,
      (filter, selected) => ({
        supplierCodes: toArray(selected.get(filter.key)),
      }),
    ],
    [
      sharingUnitTariffRecipientFilter,
      (filter, selected) => {
        const ids = toArray(selected.get(filter.key));
        return isManufacturer
          ? { targetOrganizationIds: ids }
          : { sourceOrganizationIds: ids };
      },
    ],
    [
      sharingUnitTariffUpdatedFilter,
      (filter, selected) => {
        const [updatedFrom, updatedTo] = intervalToISODate(
          selected.getIn([filter.key, 'from']),
          selected.getIn([filter.key, 'to']),
          true
        );
        return { updatedFrom, updatedTo };
      },
    ],
    [
      sharingUnitTariffTypesFilter,
      (filter, selected) => ({ types: toArray(selected.get(filter.key)) }),
    ],
    [
      sharingUnitTariffAvailabilityDatesFilter,
      (filter, selected) => {
        const [validFrom, validTo] = intervalToISODate(
          selected.getIn([filter.key, 'from']),
          selected.getIn([filter.key, 'to'])
        );
        return { validFrom, validTo };
      },
    ],
  ].filter(([filter]) =>
    /** @type {{isAvailable: (args:any)=> boolean}} */ (filter).isAvailable(
      isAvailableData
    )
  );

  let searchQuery;
  let aggregations;
  if (hasTariffFilterSearch) {
    searchQuery = yield select(selectSearchQuery);
    aggregations = availableFilters.reduce(
      /** @param {string[]} acc */
      (acc, [filter]) =>
        'aggregationKey' in filter && filter.aggregationKey
          ? acc.concat([filter.aggregationKey])
          : acc,
      []
    );
  }

  const filterValues = availableFilters.reduce(
    (acc, [filter, toValue]) =>
      Object.assign(
        acc,
        /** @type {Function} */ (toValue)(filter, selectedFilterMap)
      ),
    {}
  );
  return { aggregations, searchQuery, filterValues };
}

export function* fetchTemplates() {
  yield put(startLoading());
  const pagination = yield select(selectPagination);
  const { aggregations, searchQuery, filterValues } = yield call(getListParams);
  // Update this when url query params will be supported
  const needFullAggregations = false;

  const [{ result, error }, fullAggregResponse] = yield call(() => {
    const filters = {
      ...filterValues,
      limit: pagination.get('limit'),
      offset: pagination.get('offset'),
      aggregations,
      searchQuery,
    };
    const promises = [api.list(filters)];
    if (needFullAggregations && aggregations) {
      const fullFilters = { limit: 0, offset: 0, aggregations };
      promises.push(api.list(fullFilters));
    }
    return Promise.all(promises);
  });

  if (result) {
    const {
      data: templates,
      totalResults,
      sourceOrganizations,
      aggregations: _aggregations,
    } = result;
    yield put({
      type: FETCH_TEMPLATES_SUCCESS,
      templates,
      totalResults,
      sourceOrganizations,
      aggregations: _aggregations,
      fullAggregations: fullAggregResponse?.result?.aggregations,
    });
  } else {
    yield put({ type: FETCH_TEMPLATES_FAILURE, error });
    const message = i18n.t(
      'frontproductstream.sharingunit_tariff.retrieve_permission_notification.error',
      { defaultValue: 'An error occured while fetching your data' }
    );
    yield put(notificationError(message));
    yield call(logError, error);
  }
  yield put(stopLoading());
}
