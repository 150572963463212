import processString from 'react-process-string';

import i18n from 'utils/i18n';

export const renderSupplierInfo = (refusedRecipient) => {
  return processString([
    {
      regex: /__OrganizationName__/,
      fn: () => (
        <span
          key={`name-${refusedRecipient.organizationName}`}
          className="InformationRequests__supplierManuf"
        >
          {`${refusedRecipient.organizationName}`}
        </span>
      ),
    },
    {
      regex: /__CreationDate__/,
      fn: () => (
        <span
          key={`date-${refusedRecipient.organizationName}`}
          className="InformationRequests__supplierDate"
        >
          {refusedRecipient.creationDate}
        </span>
      ),
    },
  ])(
    i18n.t('Request refused by the Alkemics organization {{orga}} - {{date}}', {
      orga: '__OrganizationName__',
      date: '__CreationDate__',
    })
  );
};
