import { List, Map, fromJS } from 'immutable';
import { get } from 'lodash';
import { createReducer } from 'redux-create-reducer';

import { sortAsc } from 'utils/sort';

import { AUTH_ADMIN, AUTH_ALLOWED, AUTH_DEMO } from '../../constants';
import { RECEIVE_REPORS } from '../actions/types';

const initialState = Map({
  reportList: List(),
  isReportListLoaded: false,
});

const getRank = (report) => report.rank || 1000;

export default createReducer(initialState, {
  [RECEIVE_REPORS]: (state, { payload = {} } = {}) => {
    const { reports, hasAdminReports } = payload;
    const routes = {};
    return state.withMutations((newState) =>
      newState
        .set(
          'reportList',
          fromJS(
            reports
              .sort((r1, r2) => sortAsc(getRank(r1), getRank(r2)))
              .map((ref) => {
                const reporting = get(ref, 'data.reporting');
                let route = get(reporting, 'route');
                if (route in routes) {
                  routes[route] += 1;
                  route = `${route}${routes[route]}`;
                } else {
                  routes[route] = 1;
                }
                return {
                  label: get(ref, 'label'),
                  rank: get(ref, 'rank'),
                  type: get(reporting, 'type'),
                  route,
                  authorization: get(reporting, 'auth_type'),
                  config: {
                    id: get(ref, 'id'),
                    name: get(reporting, 'aggregations.detail'),
                    aggregation: get(reporting, 'aggregations.all'),
                  },
                };
              })
              .filter((ref) => {
                const authList = [AUTH_ALLOWED, AUTH_DEMO];
                if (hasAdminReports) {
                  authList.push(AUTH_ADMIN);
                }
                return authList.includes(ref.authorization);
              })
          )
        )
        .set('isReportListLoaded', true)
    );
  },
});
