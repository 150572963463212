import { useCallback, useMemo } from 'react';

import i18n from 'utils/i18n';

import { ENTITY_TYPE_LOGISTICAL_HIERARCHY_DIMENSIONS } from '../../constants';
import { BulkUpdate } from '../../structures';

import {
  LogisticalHierarchyGroupedFields,
  LogisticalHierarchyGroupedFieldsProps,
} from './grouped-fields';
import { DeepUnit, getConvertedValue, multiply } from './utils';

export function LogisticalHierarchyDimensions(
  props: LogisticalHierarchyGroupedFieldsProps
) {
  const valuePaths = useMemo(
    () => [
      ['isSizedBy', 0, 'height'],
      ['isSizedBy', 0, 'width'],
      ['isSizedBy', 0, 'depth'],
    ],
    []
  );

  const columnNames = useMemo(
    () => [
      i18n.t(
        'frontproductstream.logistical_hierarchies_dimensions_table.height_column.text',
        { defaultValue: 'Height' }
      ),
      i18n.t(
        'frontproductstream.logistical_hierarchies_dimensions_table.width_column.text',
        { defaultValue: 'Width' }
      ),
      i18n.t(
        'frontproductstream.logistical_hierarchies_dimensions_table.depth_column.text',
        { defaultValue: 'Depth' }
      ),
      i18n.t(
        'frontproductstream.logistical_hierarchies_dimensions_table.unit_volume_column.text',
        { defaultValue: 'Unit volume' }
      ),
      i18n.t(
        'frontproductstream.logistical_hierarchies_dimensions_table.summed_volume_column.text',
        { defaultValue: 'Summed volume of contained children' }
      ),
    ],
    []
  );

  const getSeedDataUpdate = useCallback((unit: DeepUnit): BulkUpdate | null => {
    const { id, versionData } = unit;
    if (versionData?.isSizedBy?.length > 0) {
      return null;
    }
    return {
      internalId: id,
      path: ['isSizedBy'],
      value: [
        {
          height: [{ data: null, expressedIn: null }],
          width: [{ data: null, expressedIn: null }],
          depth: [{ data: null, expressedIn: null }],
        },
      ],
    };
  }, []);

  const getBaseComputedValue = useCallback(
    (versionData: object, paths: (string | number)[][]): number =>
      multiply(...paths.map((p) => getConvertedValue(versionData, p))),
    []
  );

  const getBestValue = useCallback(
    (
      baseValue: number
    ): {
      value: number;
      unitCode: any;
    } => {
      let value = baseValue;
      let unitCode = 'm';
      // Step down on the unit scale while the value is too small to be elegantly displayed.
      const unitMap = {
        m: 'dm',
        dm: 'cm',
        cm: 'mm',
      };
      while (value <= 0.1 && unitMap[unitCode]) {
        value *= 1000;
        unitCode = unitMap[unitCode];
      }
      return {
        value,
        unitCode: (
          <>
            {unitCode}
            <sup>3</sup>
          </>
        ),
      };
    },
    []
  );

  return (
    <LogisticalHierarchyGroupedFields
      {...props}
      label={i18n.t(
        'frontproductstream.logistical_hierarchies_dimensions_table.dimensions.title',
        { defaultValue: 'Dimensions' }
      )}
      valuePaths={valuePaths}
      columnNames={columnNames}
      unitLabel={i18n.t(
        'frontproductstream.logistical_hierarchies_dimensions_table.length_unit.text',
        { defaultValue: 'Length unit of logistical units' }
      )}
      unitReferentialUri="/core/v3/referentials/lengths"
      getSeedDataUpdate={getSeedDataUpdate}
      getBaseComputedValue={getBaseComputedValue}
      errorEntityKind={ENTITY_TYPE_LOGISTICAL_HIERARCHY_DIMENSIONS}
      errorModel="isSizedBy"
      getBestValue={getBestValue}
      warningMessage={i18n.t(
        'frontproductstream.logistical_hierarchies_dimensions_table.volume_warning.text',
        {
          defaultValue:
            'The volume of this unit is inferior to the sum of the volumes of its children.',
        }
      )}
      model="isSizedBy"
    />
  );
}
