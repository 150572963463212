import { List, fromJS } from 'immutable';
import { useMemo } from 'react';

import { AnchoredSection } from 'modules/anchored';
import { mergeDiffs } from 'modules/data-ops/utils';
import { RELEASE_DATA_OPS_MESSAGES } from 'modules/feature-flag/constants';
import { DiffImmutable } from 'types/diff';
import { i18n } from 'utils/i18n';
import { get } from 'utils/immutable';

import {
  DataOpsAmendingStatus,
  DataOpsPatch,
  DataOpsPatchAmendingStatus,
  DataOpsPatchOrganization,
} from '../../types';

import './blocks.scss';
import { DataOpsDiffBulkActions } from './bulk-actions';
import { DataOpsDiffBlockLines } from './diff-lines';

interface Props {
  order?: number;
  organization: DataOpsPatchOrganization;
  patches: DataOpsPatch[];
  amendingStatus: DataOpsAmendingStatus;
  onAcceptPatch: (patches: DataOpsPatch[], all?: boolean) => void;
  onRefusePatch: (patches: DataOpsPatch[], all?: boolean) => void;
  isDataOpsPatcher?: boolean;
  isDataOpsReceiver?: boolean;
  canPatch: boolean;
}

interface DiffBlock {
  patch: DataOpsPatch;
  diffList: List<DiffImmutable>;
}

export const DataOpsDiffBlock = function DataOpsDiffBlock({
  order,
  organization,
  patches,
  amendingStatus,
  onAcceptPatch,
  onRefusePatch,
  isDataOpsPatcher,
  isDataOpsReceiver,
  canPatch,
}: Props) {
  const diffBlocks = useMemo<DiffBlock[]>(
    () =>
      patches.map((patch) => ({
        patch,
        diffList: fromJS(
          isDataOpsPatcher
            ? mergeDiffs(patch.diffOld, patch.diffNew)
            : patch.diffNew
        ) as List<DiffImmutable>,
      })),
    [patches, isDataOpsPatcher]
  );

  if (
    isDataOpsReceiver &&
    !get(organization, ['settings', RELEASE_DATA_OPS_MESSAGES], false)
  ) {
    return null;
  }

  const isAcceptingOrRefusing = (
    patchAmendingStatus?: DataOpsPatchAmendingStatus
  ) => patchAmendingStatus?.isAccepting || patchAmendingStatus?.isRefusing;

  const filterPatches = (
    localPatches: DataOpsPatch[],
    localAmendingStatus: DataOpsAmendingStatus
  ) =>
    localPatches.filter(
      (patch) => !isAcceptingOrRefusing(localAmendingStatus[patch.id])
    );

  const onAcceptAll = () => {
    onAcceptPatch(filterPatches(patches, amendingStatus), true);
  };

  const onRefuseAll = () => {
    onRefusePatch(filterPatches(patches, amendingStatus), true);
  };

  const isAmendingAll = isAcceptingOrRefusing(amendingStatus[0]);

  const diffBlockFieldNameColumnLabel = i18n.t(
    'frontproductstream.product_collaborative_edit.diff_block_table_column_field_name.label',
    {
      defaultValue: 'Field name',
    }
  );
  const diffBlockCurrentValueColumnLabel = i18n.t(
    'frontproductstream.product_collaborative_edit.diff_block_table_column_current_value.label',
    {
      defaultValue: 'Current value',
    }
  );
  const diffBlockOldValueColumnLabel = i18n.t(
    'frontproductstream.product_collaborative_edit.diff_block_table_column_old_value.label',
    {
      defaultValue: 'Old value',
    }
  );
  const diffBlockPatchedValueColumnLabel = i18n.t(
    'frontproductstream.product_collaborative_edit.diff_block_table_column_patched_value.label',
    {
      defaultValue: 'Patched value',
    }
  );
  const diffBlockNewValueColumnLabel = i18n.t(
    'frontproductstream.product_collaborative_edit.diff_block_table_column_new_value.label',
    {
      defaultValue: 'New value',
    }
  );
  const diffBlockRetailerValueColumnLabel = i18n.t(
    'frontproductstream.product_collaborative_edit.diff_block_table_column_retailer_value.label',
    {
      defaultValue: 'Retailer value',
    }
  );

  return (
    <AnchoredSection
      collapsible
      section={
        isDataOpsPatcher
          ? i18n.t(
              'frontproductstream.product_collaborative_edit.diff_block_retailer_header.text',
              { defaultValue: 'Patches updates' }
            )
          : i18n.t(
              'frontproductstream.product_collaborative_edit.diff_block_manufacturer_header.text',
              {
                defaultValue: 'Fields patched by {{organization}}',
                organization: organization.nameLegal,
              }
            )
      }
      id="data-ops-diff-block"
      order={order}
    >
      <div
        className="DataOpsDiffBlock alk-padding-large"
        data-testid="data-ops-diff-block"
        data-organization={organization.nameLegal}
      >
        <table className="DataOpsDiffBlock__table">
          <tbody>
            <tr>
              <td>
                {isDataOpsPatcher ? (
                  <div className="ProductHistoryDiffCard__header row">
                    <div className="col-xs-3">
                      {diffBlockFieldNameColumnLabel}
                    </div>
                    <div className="col-xs-3">
                      {diffBlockOldValueColumnLabel}
                    </div>
                    <div className="col-xs-3">
                      {diffBlockPatchedValueColumnLabel}
                    </div>
                    <div className="col-xs-3">
                      {diffBlockNewValueColumnLabel}
                    </div>
                  </div>
                ) : (
                  <div className="ProductHistoryDiffCard__header row">
                    <div className="col-xs-4">
                      {diffBlockFieldNameColumnLabel}
                    </div>
                    <div className="col-xs-4">
                      {diffBlockCurrentValueColumnLabel}
                    </div>
                    <div className="col-xs-4">
                      {diffBlockRetailerValueColumnLabel}
                    </div>
                  </div>
                )}
              </td>
              <td />
            </tr>
            {diffBlocks.map(({ patch, diffList }) => (
              <DataOpsDiffBlockLines
                key={`${patch.productKeyId}-${patch.fieldName}`}
                patch={patch}
                diffList={diffList}
                amendingStatus={amendingStatus[patch.id]}
                disabled={isAmendingAll || !canPatch}
                onAcceptPatch={onAcceptPatch}
                onRefusePatch={onRefusePatch}
                isDataOpsPatcher={isDataOpsPatcher}
                isDataOpsReceiver={isDataOpsReceiver}
              />
            ))}
          </tbody>
        </table>
        <DataOpsDiffBulkActions
          isAmendingAll={isAmendingAll}
          onAcceptAll={onAcceptAll}
          onRefuseAll={onRefuseAll}
          isDataOpsPatcher={isDataOpsPatcher}
          isDataOpsReceiver={isDataOpsReceiver}
          canPatch={canPatch}
        />
      </div>
    </AnchoredSection>
  );
};
