import { Map } from 'immutable';
import { curry } from 'lodash/fp';

import {
  get,
  isImmutable,
  keys,
  remove,
  set,
  sortBy,
  update,
} from 'utils/immutable';

export const resetPage = (state) => {
  let newState = state;
  if (get(newState, 'pagination.page') !== 1) {
    newState = update(newState, 'pagination', (pagination) =>
      set(pagination, 'page', 1)
    );
  }
  // reset backend pagination indexes
  const paginationKeys = get(newState, 'pagination.keys');
  if (paginationKeys) {
    newState = update(newState, 'pagination', (pagination) =>
      set(pagination, 'keys', isImmutable(paginationKeys) ? Map() : {})
    );
  }
  return newState;
};

export const resetSelection = (state) => {
  let newState = state;
  const selectedMap = get(newState, 'selectedMap');
  if (Map.isMap(selectedMap) && !selectedMap.isEmpty()) {
    newState = set(newState, 'selectedMap', Map());
  } else if (
    !Map.isMap(selectedMap) &&
    typeof selectedMap === 'object' &&
    Object.keys(selectedMap).length
  ) {
    newState = set(newState, 'selectedMap', {});
  }
  return newState;
};

const selectEntitiesReducer = curry((listPath, state, { payload }) =>
  update(state, 'selectedMap', (selectedMap) => {
    if (payload.all) {
      let _selectedMap = selectedMap;
      if (payload.selected) {
        for (const id of get(state, listPath)) {
          _selectedMap = set(_selectedMap, id, true);
        }
        return _selectedMap;
      }
      for (const id of keys(selectedMap)) {
        _selectedMap = remove(_selectedMap, id);
      }
      return _selectedMap;
    }
    if (payload.selected) {
      return set(selectedMap, payload.id, true);
    }
    return remove(selectedMap, payload.id);
  })
);
export const createSelectEntitiesReducer = selectEntitiesReducer;
export const createSelectEntitiesReducerFp = (listPath) => (payload, state) =>
  selectEntitiesReducer(listPath, state, { payload });

export const sortFilterByDocCountReducer = (state, { payload: filterKey }) =>
  update(state, ['aggregations'], (aggregations) =>
    update(aggregations, [filterKey], (aggreg) =>
      sortBy(aggreg, (i) => -get(i, ['doc_count'], 0))
    )
  );
