import { createAction } from 'utils/redux';

import { UserInvitationFormErrors } from '../types/invitation';

import {
  CANCEL_INVITATION,
  CLOSE_INVITATION_MODAL,
  INVITATION_FORM_ERRORS,
  INVITE_USER,
  OPEN_INVITATION_MODAL,
} from './types';

export const openInvitationModal = createAction(OPEN_INVITATION_MODAL);
export const closeInvitationModal = createAction(CLOSE_INVITATION_MODAL);
export const inviteUser = createAction(INVITE_USER);
export const invitationFormErrors = createAction<UserInvitationFormErrors>(
  INVITATION_FORM_ERRORS
);
export const cancelInvitation = createAction(CANCEL_INVITATION);
