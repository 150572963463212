import Immutable from 'immutable';
import { createSelector } from 'reselect';

const selectAnchoredState = (state) => state.anchored;

const selectSectionsByName = createSelector(selectAnchoredState, (state) =>
  state.get('sectionsByName')
);

export const selectSectionsInOrder = createSelector(
  selectSectionsByName,
  (sections) => sections.sortBy((section) => section.get('order')).valueSeq()
);

export const selectBlocks = createSelector(selectSectionsByName, (sections) =>
  sections.keySeq().reduce(
    (acc, section) =>
      acc.set(
        section,
        sections
          .getIn([section, 'blocks'])
          .sort((a, b) => a.get('position') - b.get('position'))
          .map((block) => block.get('name'))
      ),
    Immutable.Map()
  )
);

export const selectValidationResultsHidden = createSelector(
  selectAnchoredState,
  (state) => state.get('validationResultsHidden')
);

const getTopSection = (section) => ({
  id: section.get('id'),
  name: section.get('name'),
  level: 1,
  iconName: section.get('icon'),
});

const getSubSections = (section) =>
  section
    .get('blocks')
    .sort((a, b) => a.get('position') - b.get('position'))
    .map((block) => ({
      id: block.get('id'),
      name: block.get('name'),
      level: 2,
    }));

export const selectNavigationItems = createSelector(
  selectSectionsInOrder,
  (sections) =>
    sections
      .flatMap((section) => [
        getTopSection(section),
        ...getSubSections(section),
      ])
      .toArray()
);
