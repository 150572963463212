import { curry } from 'lodash';

import { apiConfig } from '@alkem/sdk-dashboard';

import { getProtocol } from 'utils/history';

import { getAppHostname } from './location';

const formatLink = curry(
  /**
   * @param {string} httpHost
   * @param {string} httpsHost
   * @param {string} path
   * @returns
   */
  (httpHost, httpsHost, path) => {
    const protocol = getProtocol();
    const host = protocol === 'http:' ? httpHost : httpsHost;
    return `${protocol}//${host}/${path.replace(/^\//, '')}`;
  }
);

const hostname = getAppHostname();

export const formatDownload = formatLink(
  `download.${hostname}`,
  `sdownload.${hostname}`
);

export const formatMedia = (path) => {
  const protocol = getProtocol();
  const { URL_CDN_MEDIA } = apiConfig();
  const baseUrl =
    protocol === 'http:'
      ? URL_CDN_MEDIA.replace(/https:\/\/s?/, 'http://')
      : URL_CDN_MEDIA;
  return `${baseUrl}${path.startsWith('/') ? '' : '/'}${path}`;
};
