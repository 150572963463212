import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { MODULE_NAME } from './constants';
import reducer from './reducer';
import mainSaga from './sagas';

registerModuleReducer(MODULE_NAME, reducer);
registerSaga(mainSaga);

export { hasSUTPermissions } from './utils';
export { validate as validateSUTariff } from './actions';
export { validate as validateSUForTemplate } from './edit-modal/actions';
export { selectSelectedRecipientId } from './selectors';
export {
  Types as TemplateTypes,
  Statuses as TemplateStatuses,
} from './constants';
export { default as templatesApi } from './api';
export { default as PriceTemplateStatusBadge } from './status-badge';

const asyncModule = async () =>
  registerModule(
    await import(/* webpackChunkName: "sharing-unit-tarrifs" */ './async')
  );
export const SharingUnitTariffsList = asyncComponent(asyncModule, {
  componentPath: 'SharingUnitTariffsList',
});
export const SharingUnitTariffsDetails = asyncComponent(asyncModule, {
  componentPath: 'SharingUnitTariffsDetails',
});
