import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Outer modules
import { updateEntity } from 'actions/entity';
import Field from 'components/ui/form/field';
import Raguel from 'components/ui/form/plugins/validator';
import { selectHasEditableFirstBracketTariff } from 'modules/feature-flag/selectors';
import { getRecipientsMap } from 'modules/recipients/reducers';
import { selectSelectedRecipientId } from 'modules/sharing-unit-tariffs';
import { selectIsLoggedAs } from 'reducers/user/selectors';
import { isProduction } from 'utils';
import { get } from 'utils/immutable';

// Inner module
import { ConnectedPriceWaterfall } from '../../components/price-waterfall';
import { defaultPriceWaterfall, minimalPriceWaterfall } from '../../constants';
import '../../price-waterfalls.scss';

const mapStateToProps = (state) => {
  const targetOrganizationId = selectSelectedRecipientId(state);

  return {
    targetOrganizationId,
    retailersById: getRecipientsMap(state),
    forcePriceDisabled: selectIsLoggedAs(state) && isProduction(),
    editableFirstBracket: selectHasEditableFirstBracketTariff(state),
  };
};

export class PriceWaterfalls extends Field {
  static propTypes = {
    ...Field.propTypes,
    targetOrganizationId: PropTypes.number,
    forcePriceDisabled: PropTypes.bool.isRequired,
    editableFirstBracket: PropTypes.bool,
    extraParams: PropTypes.shape({
      onChange: PropTypes.func,
    }),
  };

  componentDidMount() {
    const { value } = this.props;

    if (!value || !value[0]) {
      const priceWaterfall = this.isTariff()
        ? minimalPriceWaterfall()
        : defaultPriceWaterfall();
      this.dispatchChange([priceWaterfall], false); // Not dirty.
    }
  }

  componentDidUpdate() {
    // Do nothing
  }

  onUpdate = (model, value, isDirty = true) => {
    const params = [
      model,
      value,
      this.props.entityId,
      this.props.entityKind,
      isDirty,
      true, // ignoreField
    ];

    this.props.dispatch(updateEntity(...params));
    if (this.props.extraParams?.onChange) {
      this.props.extraParams.onChange(...params);
    }
  };

  isTariff() {
    const { field } = this.props;
    return get(field, 'options.isTariff', false);
  }

  renderPriceWaterFall() {
    const {
      entityId,
      entityKind,
      targetOrganizationId,
      field,
      value,
      forcePriceDisabled,
      editableFirstBracket,
    } = this.props;

    const displayType = this.isTariff() ? 'tariff' : 'template';
    return (
      <ConnectedPriceWaterfall
        model={`${field.model}.0`}
        value={
          value && value[0]
            ? value[0]
            : { levels: [], brackets: [], bracketUnit: {} }
        }
        entityId={entityId}
        entityType={entityKind}
        onUpdate={this.onUpdate}
        disabled={this.isReadOnly()}
        targetOrganizationId={targetOrganizationId}
        canDelete={false}
        label={null}
        hasEstimationFeature={false}
        displayType={displayType}
        forcePriceDisabled={forcePriceDisabled}
        editableFirstBracket={editableFirstBracket}
      />
    );
  }

  render() {
    const { entityId, entityKind, field, value, targetOrganizationId } =
      this.props;

    return (
      <div className="PriceWaterfalls">
        <div className="Raguel__block FormField--raguelError">
          <Raguel
            entityId={entityId}
            entityKind={entityKind}
            label={field.label}
            model={`${field.model}.0`}
            value={value}
            recipientId={targetOrganizationId}
            readOnly={this.isReadOnly()}
          />
        </div>
        {this.isVisible() && this.renderPriceWaterFall()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(PriceWaterfalls);
