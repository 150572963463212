import { flow } from 'lodash';
import { filter, flatten, map, reduce, values } from 'lodash/fp';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePermission } from 'modules/permissions';
import {
  PATCH_PERMISSION,
  PRODUCT_PERMISSION,
} from 'modules/permissions/const';
import { selectProductKeyId } from 'reducers/productVersion';

import { acceptDataOpsPatches, refuseDataOpsPatches } from '../../actions';
import {
  selectDataOpsAmendingStatus,
  selectDataOpsPatches,
  selectHasDataOps,
  selectIsActivelyShared,
  selectIsDataOpsPatcher,
  selectIsDataOpsReceiver,
} from '../../selectors';
import {
  DataOpsDiffStatus,
  DataOpsPatch,
  DataOpsPatchOrNewPatch,
  DataOpsPatchOrganization,
  DataOpsPatchState,
  DataOpsPatchStatus,
  DataOpsPatchesState,
} from '../../types';

import { DataOpsDiffBlock } from './block';

type OrgAndPatchTuple = [DataOpsPatchOrganization, DataOpsPatch[]];

interface PatchesByOrgId {
  [orgId: number]: OrgAndPatchTuple;
}

export const DataOpsDiffBlocks = memo(function DataOpsDiffBlocks({
  order,
}: {
  order: number;
}) {
  const dispatch = useDispatch();
  const hasDataOps = useSelector(selectHasDataOps);
  const isDataOpsPatcher = useSelector(selectIsDataOpsPatcher);
  const isDataOpsReceiver = useSelector(selectIsDataOpsReceiver);
  const patchMap = useSelector(selectDataOpsPatches);
  const amendingStatus = useSelector(selectDataOpsAmendingStatus);
  const isActivelyShared = useSelector(selectIsActivelyShared);
  const productKeyId = useSelector(selectProductKeyId);
  const shouldRenderBlock = hasDataOps && isActivelyShared;

  const { hasPermission } = usePermission({
    module: PRODUCT_PERMISSION,
    entityId: productKeyId,
  });
  const canPatch: boolean = hasPermission({ permissions: [PATCH_PERMISSION] });

  const patchesByOrg = useMemo(() => {
    if (shouldRenderBlock) {
      return flow<
        [DataOpsPatchesState],
        DataOpsPatchState[],
        DataOpsPatchOrNewPatch[][],
        DataOpsPatchOrNewPatch[],
        DataOpsPatch[],
        PatchesByOrgId,
        OrgAndPatchTuple[]
      >(
        values,
        map((patchState) => patchState.data),
        flatten,
        filter<DataOpsPatch>((patch) => {
          if (!patch?.id || patch.status !== DataOpsPatchStatus.ENABLED) {
            return false;
          }
          if (isDataOpsPatcher) {
            return [DataOpsDiffStatus.PATCH_DISPUTE_STATUS].includes(
              patch.diffStatus
            );
          }
          return true;
        }),
        reduce<DataOpsPatch, PatchesByOrgId>((patchesByOrgId, patch) => {
          const organization = patch.user.belongsTo[0];
          if (!patchesByOrgId[organization.id]) {
            patchesByOrgId[organization.id] = [organization, [patch]];
          } else {
            patchesByOrgId[organization.id][1].push(patch);
          }
          return patchesByOrgId;
        }, {}),
        values
      )(patchMap);
    }
    return [];
  }, [shouldRenderBlock, isDataOpsPatcher, patchMap]);

  const onAcceptPatch = useCallback(
    (patches: DataOpsPatch[], all?: boolean) => {
      dispatch(acceptDataOpsPatches({ patches, all }));
    },
    [dispatch]
  );

  const onRefusePatch = useCallback(
    (patches: DataOpsPatch[], all?: boolean) => {
      dispatch(refuseDataOpsPatches({ patches, all }));
    },
    [dispatch]
  );

  return (
    <>
      {patchesByOrg.map(([organization, patches]) => (
        <DataOpsDiffBlock
          key={organization.id}
          organization={organization}
          patches={patches}
          amendingStatus={amendingStatus}
          order={order}
          onAcceptPatch={onAcceptPatch}
          onRefusePatch={onRefusePatch}
          isDataOpsPatcher={isDataOpsPatcher}
          isDataOpsReceiver={isDataOpsReceiver}
          canPatch={canPatch}
        />
      ))}
    </>
  );
});
