import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import { logout } from 'modules/auth/shared/actions';
import { selectIsAuthorized } from 'modules/auth/shared/selectors';
import * as routes from 'routes';
import { withQuery } from 'utils/query';

export function Logout() {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(selectIsAuthorized);
  const [searchParams] = useSearchParams();
  const nextlocation = searchParams.get('nextlocation') || '';
  const error = searchParams.get('reason');

  useEffect(() => {
    dispatch(logout({ nextlocation }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthorized ? null : (
    <Navigate to={withQuery(routes.login, error ? { error } : {})} />
  );
}

export default Logout;
