import { Map } from 'immutable';
import { get, negate, set } from 'lodash/fp';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { SwitchButton } from '@alkem/react-ui-button';

import { updateEntity } from 'actions/entity';
import { isReadOnly } from 'components/ui/form/field';
import {
  RuleApplicationStatus,
  ruleEntities,
  selectValidationResultsByEntity,
} from 'modules/validation';
import {
  selectCurrentLanguage,
  selectTextileVariantList,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { getUnitsIndexes } from 'utils/validation-helpers';

import { toggleVariant } from '../../actions';
import { Statuses } from '../../constants';
import {
  selectToggledVariantKeyId,
  selectVariantFields,
} from '../../selectors';
import AddVariant from '../add-variant';
import CollapsibleVariant from '../collapsible-variant';

import './variant-list.scss';

function getFailingVariantIndexesCount(
  validationResults,
  entityId,
  entityType,
  variantFields
) {
  const validationResult =
    validationResults.find(
      (e) =>
        e.getIn(['entity', 'id']) === entityId &&
        e.getIn(['entity', '_type']) === entityType
    ) || Map();
  const pattern = /^textileVariantList\.(\d+)\..*$/;
  const variantFieldNames = Object.values(variantFields).map(get('model'));
  return (
    (validationResult.get('rules') || Map())
      .filter(
        (r) =>
          r.get('entityType') === ruleEntities.CONSUMER_UNIT ||
          r.get('entityType') === ruleEntities.TEXTILE_VARIANT
      )
      .filter((r) => r.get('status') === RuleApplicationStatus.KO)
      // check if it's for a variant field (looking at paths)
      .filter(
        (r) =>
          r
            .get('paths')
            .filter(
              (ps) =>
                ps.filter(
                  (p) =>
                    p.includes('textileVariantList') &&
                    (variantFieldNames.some((fieldName) =>
                      p.includes(fieldName)
                    ) ||
                      p.includes('namePublicLong'))
                ).size > 0
            ).size > 0
      )
      .toList()
      // returns failing variant indexes: ["2", "3"]
      .map((r) => getUnitsIndexes(r, pattern).toList())
      // get a list of all the failing indexes, as many times as they have rules failing
      .flatten()
      // get the count of failures per index
      .countBy((v) => parseInt(v, 10))
  );
}

interface State {
  shouldDisplayInactive: boolean;
}

interface Props {
  value: any;
  field: any;
  entity: any;
  entityId: number;
  entityKind: string;

  variantFields: any;
  textileVariantList: any;
  currentLanguage: any;
  toggledVariantProductKeyId: number | null;
  validationResults: any;
  actions: {
    updateEntity: typeof updateEntity;
    toggleVariant(variantProductKeyId: number);
  };
}

const mapStateToProps = createStructuredSelector({
  variantFields: selectVariantFields,
  textileVariantList: selectTextileVariantList,
  currentLanguage: selectCurrentLanguage,
  toggledVariantProductKeyId: selectToggledVariantKeyId,
  validationResults: selectValidationResultsByEntity,
});

const mapDispatchToProps = {
  updateEntity,
  toggleVariant,
};

export class VariantList extends PureComponent<Props, State> {
  public state: State = {
    shouldDisplayInactive: false,
  };

  private updateDisplayActiveVariants = (shouldDisplayInactive: boolean) => {
    this.setState({ shouldDisplayInactive });
  };

  private onToggleVariant = (variantProductKeyId: number) => () => {
    this.props.actions.toggleVariant(variantProductKeyId);
  };

  private onDelete = (index) => () => {
    const { value, field, entityId, entityKind } = this.props;
    const newValue = value.filter((_v, i) => i !== index);
    this.props.actions.updateEntity(
      field.model,
      newValue,
      entityId,
      entityKind
    );
  };

  private onSetInactive = (index) => () => {
    const { value, field, entityId, entityKind } = this.props;
    const newValue = set(`${index}.status`, Statuses.INACTIVE.id, value);
    this.props.actions.updateEntity(
      field.model,
      newValue,
      entityId,
      entityKind
    );
  };

  private onSetActive = (index) => () => {
    const { value, field, entityId, entityKind } = this.props;
    const newValue = set(`${index}.status`, Statuses.ACTIVE.id, value);
    this.props.actions.updateEntity(
      field.model,
      newValue,
      entityId,
      entityKind
    );
  };

  private renderVariants = (variant, errorCounts) => {
    const {
      field,
      variantFields,
      entity,
      entityId,
      entityKind,
      currentLanguage,
      toggledVariantProductKeyId,
    } = this.props;
    const readOnly = isReadOnly(field);
    const productKeyId = get(['textileVariant', 'product_key_id'], variant);
    const index = get(['index'], variant);
    return (
      <CollapsibleVariant
        key={`CollapsibleVariant-${get('textileVariant.id', variant)}`}
        textileVariant={get('textileVariant', variant)}
        inactive={get('status', variant) === Statuses.INACTIVE.id}
        fields={Object.values(variantFields)}
        entity={entity}
        entityKind={entityKind}
        entityId={entityId}
        index={index}
        collapsed={productKeyId !== toggledVariantProductKeyId}
        toggle={this.onToggleVariant(productKeyId)}
        onDelete={this.onDelete(index)}
        onSetInactive={this.onSetInactive(index)}
        onSetActive={this.onSetActive(index)}
        currentLanguage={currentLanguage}
        errorCount={errorCounts.get(index) || 0}
        disabled={readOnly}
      />
    );
  };

  public render = () => {
    const { shouldDisplayInactive } = this.state;
    const {
      field,
      variantFields,
      entityId,
      entityKind,
      textileVariantList,
      validationResults,
    } = this.props;
    const readOnly = isReadOnly(field);
    const hasInactiveVariant =
      textileVariantList.filter(
        (v) => get('status', v) === Statuses.INACTIVE.id
      ).length > 0;

    const errorCounts = getFailingVariantIndexesCount(
      validationResults,
      entityId,
      entityKind,
      variantFields
    );
    const variantFieldNames = Object.values(variantFields).map(get('model'));
    // Set the index in the variants to reorder them as needed when displaying.
    const variants = textileVariantList.map((v, i) => set(['index'], i, v));
    const isActive = (v) => get(['status'], v) === Statuses.ACTIVE.id;
    const activeVariants = variants.filter(isActive);
    const inactiveVariants = variants.filter(negate(isActive));
    return (
      <div className="Textile_variants">
        <h3>{i18n.t('Variants')}</h3>
        {errorCounts.size > 0 && (
          <div className="Raguel__block FormField--raguelError">
            <div className="Raguel">
              <div className="Raguel__message">
                <span>
                  {i18n.t('Some validation errors were found on variants')}
                </span>
              </div>
            </div>
          </div>
        )}
        {hasInactiveVariant && (
          <SwitchButton
            content={i18n.t('Show inactive variants')}
            onChange={this.updateDisplayActiveVariants}
            checked={shouldDisplayInactive}
          />
        )}
        <div className="Textile_variants_list">
          {activeVariants.map((v) => this.renderVariants(v, errorCounts))}
          {shouldDisplayInactive &&
            inactiveVariants.map((v) => this.renderVariants(v, errorCounts))}
        </div>
        {!readOnly && <AddVariant variantFieldNames={variantFieldNames} />}
      </div>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(VariantList);
