import { List } from 'immutable';
import { call, delay, select } from 'redux-saga/effects';

import { get } from 'utils/immutable';
import { logError } from 'utils/logging';
import { track } from 'utils/tracking';

import { trackAction as buildAction } from '../actions';
import {
  ARCHIVED_PRODUCTS,
  ASSIGNATION,
  PRODUCTS,
  PRODUCTS_TO_REVIEW,
  PRODUCTS_WITH_MENU,
  SOURCING,
} from '../constants/context';
import { selectCatalogContext, selectSearch } from '../selectors';

function* getCategoryByContext() {
  const context = yield select(selectCatalogContext);
  switch (context) {
    case ASSIGNATION:
      return 'assignation';
    case SOURCING:
      return 'sourcing';
    case PRODUCTS:
    case PRODUCTS_WITH_MENU:
    case PRODUCTS_TO_REVIEW:
    case ARCHIVED_PRODUCTS:
    default:
      return 'catalog';
  }
}

function* enrichCatalogAction({ action, ...payload } = {}) {
  const category = yield call(getCategoryByContext);
  return {
    ...payload,
    category,
    action: `${category}_${action}`,
  };
}

export function* trackAction({ payload } = {}) {
  let action = payload;
  if (action && !action.category) {
    action = yield call(enrichCatalogAction, action);
  }
  yield call(track, action);
}

function* trackSearchQuery(query) {
  if (query) {
    yield call(
      trackAction,
      buildAction({
        action: 'searched',
        label: query,
      })
    );
  }
}

export function* trackSearch() {
  try {
    const query = yield select(selectSearch);
    yield delay(1000);
    yield call(trackSearchQuery, query);
  } catch (e) {
    logError(e);
  }
}

export function* trackSelectedFilters({ payload } = {}) {
  try {
    const filterList = List.isList(payload) ? payload : List([payload]);
    for (const filter of filterList) {
      if (get(filter, 'add')) {
        yield call(
          trackAction,
          buildAction({
            action: 'filtered',
            label: `${filter.get('key')}=${filter.get('value')}`,
          })
        );
      }
    }
  } catch (e) {
    logError(e);
  }
}

export function* trackSortList({ payload } = {}) {
  try {
    const code = payload.referential.get('code');
    const order = payload.asc ? 'asc' : 'desc';
    yield call(
      trackAction,
      buildAction({
        action: 'orderedby',
        label: `${code}=${order}`,
      })
    );
  } catch (e) {
    logError(e);
  }
}
