import classNames from 'classnames';
import { Map } from 'immutable';
import moment from 'moment';

import {
  RELEASE_DATA_MATURITY_DEADLINE,
  hasFeature,
} from 'modules/feature-flag';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';

import { MaturitiesSummary, MaturitySummary } from '../maturity';

import './index.scss';

interface Props {
  data: Map<string, any>;
  user: UserImmutable;
}

function computeCalendarIconClass(
  isDeadlineBehind: boolean,
  isIncompleteScenario: boolean
): string {
  if (isDeadlineBehind) {
    if (isIncompleteScenario) {
      return 'mdi-calendar-alert';
    } else {
      return 'mdi-calendar-blank';
    }
  } else {
    if (isIncompleteScenario) {
      return 'mdi-calendar-clock';
    } else {
      return 'mdi-calendar-check';
    }
  }
}

function MaturitiesDeadLinesNothing() {
  return (
    <span
      className="MaturitiesDeadlinesCell__information"
      data-testid="maturity-cell-information"
    >
      <i data-testid="calendar-icon" className="mdi mdi-calendar-blank" />{' '}
      {i18n.t('frontproductstream.maturity_deadline_cell.noupcoming.text', {
        defaultValue: 'nothing coming',
      })}
    </span>
  );
}

function FeaturedUpcomingScenario({
  featured_upcoming_scenario,
}: {
  featured_upcoming_scenario: MaturitySummary;
}) {
  const upcomingDeadline = moment
    .utc(featured_upcoming_scenario.deadline)
    .local();
  const incompleteScenario =
    featured_upcoming_scenario.successful !== featured_upcoming_scenario.total;

  const endOfToday = moment().endOf('day');
  const isDeadlineBehind = upcomingDeadline.isSameOrBefore(endOfToday);

  if (!incompleteScenario && isDeadlineBehind) {
    return <MaturitiesDeadLinesNothing />;
  }
  const iconClass = computeCalendarIconClass(
    isDeadlineBehind,
    incompleteScenario
  );

  return (
    <span>
      <i data-testid="calendar-icon" className={`mdi ${iconClass}`} />{' '}
      {upcomingDeadline.format('L')}
    </span>
  );
}

export const MaturitiesDeadlinesCell = ({ data, user }: Props) => {
  let body;

  if (!hasFeature(user, RELEASE_DATA_MATURITY_DEADLINE)) {
    return null;
  }

  const maturitiesSummary: MaturitiesSummary = data.toJS();
  if (!maturitiesSummary.featured_upcoming_scenario) {
    body = <MaturitiesDeadLinesNothing />;
  } else {
    const othersCount = maturitiesSummary.upcoming_scenarios.length - 1;
    body = (
      <div>
        <FeaturedUpcomingScenario
          featured_upcoming_scenario={
            maturitiesSummary.featured_upcoming_scenario
          }
        />
        {!!othersCount && (
          <div className="MaturitiesDeadlinesCell__others">
            {i18n.t('frontproductstream.maturity_deadline_cell.others.text', {
              count: othersCount,
              defaultValue: '+{{count}} other upcomings',
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      data-testid="upcoming-date"
      className={classNames('MaturitiesDeadlinesCell')}
    >
      {body}
    </div>
  );
};
