export const TYPE_ASSIGNATION = 0;
export const TYPE_CHASE = 1;

export const STATUS_PENDING = 0;
export const STATUS_ACCEPTED = 1;
export const STATUS_REFUSED = 2;
export const STATUS_BOUNCED = 6;

export const ASSIGN = 'assign';
export const REASSIGN = 'reassign';
export const CHASE = 'chase';

export const LAST_REFUSED_EVENT_REFUSED = 2;
export const LAST_REFUSED_EVENT_BOUNCED = 6;
export const NOT_ASSIGNED = 0;
