import i18n from 'utils/i18n';

export const NOT_CHECKED = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_retailer_status.not_checked',
    { defaultValue: 'Not checked' }
  ),
  id: 1,
};
export const CHECK_NOT_REQUIRED = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_retailer_status.not_required',
    { defaultValue: 'Not required' }
  ),
  id: 2,
};
export const IN_PROGRESS = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_retailer_status.in_progress',
    { defaultValue: 'In progress' }
  ),
  id: 3,
};
export const FINISHED = {
  name: i18n.t(
    'frontproductstream.constants.physical_check_retailer_status.finished',
    { defaultValue: 'Finished' }
  ),
  id: 4,
};

export const PhysicalCheckerRetailerStatusMap = {
  NOT_CHECKED,
  CHECK_NOT_REQUIRED,
  IN_PROGRESS,
  FINISHED,
};

export const getFromStatusId = (statusId) =>
  Object.values(PhysicalCheckerRetailerStatusMap).find(
    (status) => status.id === statusId
  );
