import { getId, getProductProductKeyId } from 'core/api/productversion';
import serviceProductApi from 'resources/serviceProductApi';
import { cancelPromise } from 'utils';
import { createAction } from 'utils/redux';
import { track } from 'utils/tracking';

import {
  BULK_VALIDATE_DONE,
  BULK_VALIDATE_IN_PROGRESS,
  BULK_VALIDATE_RESET,
  BULK_VALIDATE_SHOW,
} from './types';

let validatedProductsPromise;

export const showBulkValidate = createAction(BULK_VALIDATE_SHOW);
export const bulkValidateInProgress = createAction(BULK_VALIDATE_IN_PROGRESS);
export const bulkValidateDone = createAction(BULK_VALIDATE_DONE);

export const resetBulkValidate = () => {
  cancelPromise(validatedProductsPromise);
  return { type: BULK_VALIDATE_RESET };
};

const requestBulkValidate = async ({ productVersions, dispatch }) => {
  try {
    const pkids = productVersions.valueSeq().map(getProductProductKeyId).toJS();

    validatedProductsPromise = serviceProductApi.setExportableTag(pkids);
    await validatedProductsPromise;

    const pvids = productVersions.valueSeq().map(getId).toJS();
    let progressCount = 1;
    await Promise.all(
      pvids.map((id) => {
        return track({
          category: 'product',
          action: 'product_validated',
          label: id,
        }).then(() => {
          dispatch(
            bulkValidateInProgress({
              count: progressCount++,
              productVersionId: id,
              status: 'success',
            })
          );
        });
      })
    );
    return 'success';
  } catch (e) {
    return 'danger';
  } finally {
    dispatch(bulkValidateDone());
  }
};

export const validateProducts = (productVersions) => (dispatch) => {
  cancelPromise(validatedProductsPromise);
  dispatch(bulkValidateInProgress());
  return requestBulkValidate({
    productVersions,
    dispatch,
  });
};
