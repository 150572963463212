import classnames from 'classnames';
import { connect } from 'react-redux';

import Field from 'components/ui/form/field';
import { renderField } from 'components/ui/form/field/utils/render';
import { get } from 'utils/immutable';

export class FormDict extends Field {
  renderChildren() {
    const {
      field,
      entity,
      entityKind,
      entityId,
      validate,
      hasProductUpdatePermission,
      extraParams,
    } = this.props;

    return field.children
      .filter((localField) => {
        if (
          !Array.isArray(localField.applicableConditionValues) ||
          !field.filterApplicableCondition ||
          !field.filterApplicableCondition.conditionSource
        ) {
          return true;
        }
        const currentCondition = get(
          entity,
          `edited.${field.filterApplicableCondition.conditionSource}`
        );
        if (
          localField.applicableConditionValues.indexOf(currentCondition) !== -1
        ) {
          return true;
        }
        return false;
      })
      .map((localField_, i) => {
        const localKey = `${field.model}-${i}`;
        const localField = { ...localField_ };
        localField.model = `${field.model}.${localField.model}`;

        return renderField(
          entity,
          entityKind,
          entityId,
          localKey,
          localField,
          validate,
          hasProductUpdatePermission,
          null,
          extraParams
        );
      });
  }

  render() {
    if (!this.props.field.children) {
      return null;
    }
    return (
      <div className={classnames('FormDict', this.getClasses())}>
        {!this.props.isFlat && (
          <div className="row">{this.renderLabel('col-xs-4')}</div>
        )}
        <div className="row">
          <div
            className={
              this.props.isFlat ? 'col-xs-12' : 'offset-xs-1 col-xs-11'
            }
          >
            {this.renderChildren()}
            {this.renderPlugins({ offset: true })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(FormDict);
