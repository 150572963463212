import { flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { TargetProductStatusPostAssignationMap } from 'constants/targetProductStatus';
import { withUserType } from 'hocs';
import {
  selectCurrentLanguage,
  selectTargetProductStatus,
} from 'reducers/productVersion';
import { selectIsLoggedAs } from 'reducers/user/selectors';

import { BasicInformation } from '../basic-information';

import { AdminRuleCheck } from './admin-rule-check';
import ProductFooterActions from './product-footer-actions';
import './product-footer.scss';
import { RetailerFooterActions } from './retailer-footer-actions';
import SynchronizationError from './synchronization-error';
import ThirdPartyFooterActions from './third-party-footer-actions';

const mapStateToProps = createStructuredSelector({
  language: selectCurrentLanguage,
  productStatus: selectTargetProductStatus,
});

const enhance = flow(connect(mapStateToProps), withUserType);

export const ProductFooterWrapper = ({ children }: { children: any }) => (
  <div className="ProductFooter">{children}</div>
);

export const CustomProductFooterActions = ({ children }: { children: any }) => (
  <div className="container-fluid">
    <div className="ProductFooter__content row">
      <div className="ProductFooter__basic col-xs-2">
        <BasicInformation noPublishStatus />
      </div>
      <div className="ProductFooter__actions col-xs-10">{children}</div>
    </div>
  </div>
);

export function ProductFooterView({
  isManufacturer,
  isPrivateLabel,
  isRetailer,
  isThirdParty,
  language,
  productStatus,
}) {
  const isLoggedAs = useSelector(selectIsLoggedAs);
  if (
    isRetailer &&
    (!language || !(productStatus in TargetProductStatusPostAssignationMap))
  ) {
    return null;
  }

  return (
    <ProductFooterWrapper>
      {(isManufacturer || isPrivateLabel) && <SynchronizationError />}
      <CustomProductFooterActions>
        {isLoggedAs && <AdminRuleCheck />}
        {(isManufacturer || isPrivateLabel) && <ProductFooterActions />}
        {isRetailer && <RetailerFooterActions />}
        {isThirdParty && <ThirdPartyFooterActions />}
      </CustomProductFooterActions>
    </ProductFooterWrapper>
  );
}

ProductFooterView.propTypes = {
  // store
  language: PropTypes.object,
  productStatus: PropTypes.string,

  // withUserType
  isManufacturer: PropTypes.bool.isRequired,
  isPrivateLabel: PropTypes.bool.isRequired,
  isRetailer: PropTypes.bool.isRequired,
  isThirdParty: PropTypes.bool.isRequired,
};

export const ProductFooter = enhance(ProductFooterView);
