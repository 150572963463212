import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { withQuery } from 'utils/query';
import { fill } from 'utils/routing';

import './header-nav-link.scss';
import { trackNavigation } from './utils';

interface Props {
  children?: any;
  exact?: boolean;
  logasVisibility?: boolean;
  productKeyId: string;
  title: string;
  to: string;
  when: boolean;
}

export const HeaderNavLink = ({
  children,
  exact,
  logasVisibility,
  productKeyId,
  title,
  to,
  when,
}: Props) => {
  const location = useLocation();
  if (!when) {
    return null;
  }
  const route = withQuery(fill(to, productKeyId));
  const classes = {
    ProductHeaderNavigation__link: true,
    'ProductHeaderNavigation__link--logas': logasVisibility,
  };
  return (
    <NavLink
      to={route}
      className={({ isActive }) =>
        classNames(
          classes,
          (exact ? isActive && location.pathname === route : isActive) &&
            'ProductHeaderNavigation__link--active'
        )
      }
      onClick={trackNavigation(to)}
    >
      {title}
      {children}
    </NavLink>
  );
};

export default HeaderNavLink;
