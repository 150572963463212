import { isManufacturer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { RELEASE_NEW_MATURITY_COMPONENT } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';

export enum FilterStatuses {
  error = 'error',
  success = 'success',
  all = 'all',
  none = 'none',
}

export const recipientsScenariosFilter = {
  key: 'recipients_scenarios',
  query: 'scenarios',
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    isManufacturer(user) && hasFeature(user, RELEASE_NEW_MATURITY_COMPONENT),
  customField: 'recipients_scenarios',
  valuePath: 'data',
  withCustomField: ({ user }) =>
    isManufacturer(user) && hasFeature(user, RELEASE_NEW_MATURITY_COMPONENT),

  parseQueryValues: (queryStr = '') => {
    const [scenarioIdQuery, statusQuery = FilterStatuses.none] =
      queryStr.split(':');
    const scenarioId = Number(scenarioIdQuery);
    if (scenarioId > 0) {
      const status = FilterStatuses[statusQuery] || FilterStatuses.none;
      return { scenario_id: scenarioId, status };
    }
    // need an empty table to ignore the filter if not well formatted
    return [];
  },

  formatQueryValues: (value) => {
    if (value && !value.isEmpty()) {
      const scenarioId = value.get('scenario_id');
      const filter = value.get('status');
      return `${scenarioId}:${filter}`;
    }
    return '';
  },
};
