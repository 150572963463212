export const FETCH_LIST = 'catalog/fetch-list';
export const CANCEL_FETCH_LIST = 'catalog/cancel-fetch-list';
export const RECEIVE_LIST = 'catalog/receive-list';
export const RESET_CATALOG = 'catalog/reset';
export const FETCH_COLUMN_REFERENTIALS = 'catalog/fetch-column-referentials';
export const CANCEL_FETCH_COLUMN_REFERENTIALS =
  'catalog/cancel-fetch-column-referentials';
export const RECEIVE_COLUMN_REFERENTIALS =
  'catalog/receive-column-referentials';
export const PRODUCT_SELECTION = 'catalog/product-selection';
export const SELECT_COLUMN = 'catalog/select-column';
export const UPDATE_COLUMN_SELECTION = 'catalog/update-column-selection';
export const SORT_FILTER_BY_DOC_COUNT = 'catalog/sort-filter-by-doc-count';
export const SORT_LIST = 'catalog/sort-list';
export const SEARCH = 'catalog/search';
export const TRACK_CATALOG_ACTION = 'catalog/track-action';
export const TRACK_SEARCH = 'catalog/track-search';
export const COLLAPSE_FILTERS = 'catalog/collapse-filters';
export const UPDATE_SELECTED_FILTERS = 'catalog/update-selected-filters';
export const UPDATE_SELECTED_FILTERS_FROM_QUERY =
  'catalog/update-selected-filters-from-query';
export const UPDATE_FILTERED_FILTERS = 'catalog/update-filtered-filters';
export const UPDATE_FILTERS_PAGINATION = 'catalog/update-filters-pagination';
export const UPDATE_ITEMS_PER_PAGE = 'catalog/update-items-per-page';
export const EXPORT_PRODUCTS = 'catalog/export-products';
export const EXPORT_PRODUCTS_DONE = 'catalog/export-products-done';
export const CANCEL_EXPORT_PRODUCTS = 'catalog/cancel-export-products';
export const DOWNLOAD_COMPLIANCE_REPORT = 'catalog/download-compliance-report';
export const UPDATE_SEARCH_FROM_QUERY = 'catalog/update-search-from-query';
export const UPSERT_LIST_COLUMNS = 'catalog/upsert-list-columns';
export const CANCEL_UPSERT_LIST_COLUMNS = 'catalog/cancel-upsert-list-columns';
export const OPEN_EXPORT_MODAL = 'catalog/open-export-modal';
export const CLOSE_EXPORT_MODAL = 'catalog/close-export-modal';
export const UPDATE_EXPORT_FORMAT = 'catalog/select-export-format';
export const UPDATE_WITH_ARCHIVED_PRODUCTS =
  'catalog/update-with-archived-products';
export const UPDATE_ONLY_DUPLICATED_PRODUCTS =
  'catalog/update-only-duplicated-products';
export const UPDATE_WITH_ALL_TYPES = 'catalog/update-with-all-types';
export const REPLACE_FILTERS = 'catalog/replace-filters';
export const SET_CATALOG_CONTEXT = 'catalog/set-context';
export const UPDATE_WITH_REFUSED_ASSIGNATIONS =
  'catalog/update-with-refused-assignations';
export const UPDATE_ASSIGNATION_STATUS = 'catalog/update-assignation-status';
export const RECEIVE_PUBLIC_PRODUCT = 'catalog/receive-public-product';
export const FETCH_PUBLIC_PRODUCT = 'catalog/fetch-public-product';
export const INIT_CATALOG = 'catalog/init';
export const SET_FILTERS_FROM_REPORTING = 'catalog/set-filters-from-reporting';
export const SET_CATALOG_UUID = 'catalog/set-uuid';
export const FETCH_EXPORT_FILE_FORMATS = 'catalog/fetch-export-file-formats';
export const RECEIVE_EXPORT_FILE_FORMATS =
  'catalog/receive-export-file-formats';
export const UPDATE_PRODUCTS_TO_EXPORT = 'catalog/update-products-to-export';
export const DISPATCH_PRODUCTS = 'catalog/dispatch-products';
export const FETCH_MATURITY_RULESETS = 'catalog/fetch-maturity-rulesets';
export const RECEIVE_MATURITY_RULESETS = 'catalog/receive-maturity-rulesets';
export const BULK_ACTIVATE_RECIPIENTS = 'catalog/bulk-activate-recipients';
export const SET_HIGHLIGHTED_ROW_IDENTIFIER =
  'catalog/set-highlighed-row-identifier';
