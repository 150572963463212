import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import {
  createSelectOnlyDuplicatedProducts,
  createSelectWithArchivedProducts,
  createSelectorAreFiltersCollapsed,
  createSelectorFiltersConfig,
  createSelectorSelectedFilterList,
  createSelectorSelectedFilterMap,
} from 'core/modules/list/selectors';
import { FEATURE_REPORTING_PAGINATION } from 'modules/feature-flag/constants';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import { selectIsRetailer } from 'reducers/user/selectors';

import { REDUCER_KEY as REPORTING_REDUCER_KEY } from '../../constants';
import { REDUCER_KEY } from '../constants';

export const selectModuleState = (state) =>
  state[MODULE_REDUCER_KEY][REPORTING_REDUCER_KEY][REDUCER_KEY];

export const selectFiltersConfig =
  createSelectorFiltersConfig(selectModuleState);

export const selectAreFiltersCollapsed =
  createSelectorAreFiltersCollapsed(selectModuleState);

export const selectColumnList = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('columnList')
);

export const selectColumnMap = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('columnMap')
);

export const selectReportList = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('reportList')
);

export const selectReportMap = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('reportMap')
);

export const selectPagination = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('pagination')
);

export const selectSelectedFilterList =
  createSelectorSelectedFilterList(selectModuleState);

export const selectSelectedFilterMap =
  createSelectorSelectedFilterMap(selectModuleState);

export const selectAggregations = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('aggregations')
);

export const selectSorting = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('sorting')
);

export const selectSelectedMap = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('selectedMap')
);

export const selectExportInProgress = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('exportInProgress')
);

export const selectReportAggregation = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('reportAggregation')
);

export const selectWithArchivedProducts =
  createSelectWithArchivedProducts(selectModuleState);

export const selectGroupByMeta = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('groupByMeta')
);

export const selectOnlyDuplicatedProducts =
  createSelectOnlyDuplicatedProducts(selectModuleState);

export const selectAggregationGroupByMeta = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('aggregationGroupByMeta')
);

export const selectReportConfig = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('reportConfig')
);

export const selectIsDemo = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('isDemo')
);

export const selectCharts = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('charts')
);

export const selectIsReportLoading = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('loading')
);

export const selectIsBackendPagination = (state) => {
  const isRetailer = selectIsRetailer(state);
  const hasPagination = selectHasFeature(FEATURE_REPORTING_PAGINATION)(state);
  return isRetailer && hasPagination;
};

export const selectBackendPaginationKeys = createSelector(
  selectModuleState,
  (moduleState) => moduleState.getIn(['pagination', 'keys'], Map())
);
