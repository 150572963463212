import PropTypes from 'prop-types';

import { ENTITY_TYPE_SHARINGUNIT_TARIFF } from 'constants/entities';

import SharingUnitPriceWaterfalls from './containers/sharing-unit';
import SharingUnitTariffPriceWaterfalls from './containers/sharing-unit-tariff';

const PriceWaterfalls = (props) =>
  props.entityKind === ENTITY_TYPE_SHARINGUNIT_TARIFF ? (
    <SharingUnitTariffPriceWaterfalls {...props} />
  ) : (
    <SharingUnitPriceWaterfalls {...props} />
  );

PriceWaterfalls.propTypes = {
  entityKind: PropTypes.string,
  entity: PropTypes.any,
};

export default PriceWaterfalls;
