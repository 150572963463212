import i18n from 'utils/i18n';

export const STATUS_INACTIVE = {
  id: 0,
  code: 'inactive',
  label: i18n.t('frontproductstream.constants.user_status.disabled', {
    defaultValue: 'Disabled',
  }),
};
export const STATUS_ACTIVE = {
  id: 1,
  code: 'active',
  label: i18n.t('frontproductstream.constants.user_status.active', {
    defaultValue: 'Active',
  }),
};
export const STATUS_PENDING = {
  id: 2,
  code: 'invited',
  label: i18n.t('frontproductstream.constants.user_status.invited', {
    defaultValue: 'Email pending validation',
  }),
};
export const USER_STATUSES = [STATUS_INACTIVE, STATUS_ACTIVE, STATUS_PENDING];

export const SERVICE_PRODUCT = { id: 4, name: 'service-product' };
export const SERVICE_CORE = { id: 53, name: 'service-core' };
export const SERVICE_MEDIA = { id: 56, name: 'service-media' };
export const SERVICE_ETL = { id: 62, name: 'service-etl' };
export const SERVICE_TRANSACTION = { id: 66, name: 'service-transaction' };

export const SERVICE_SALMON = { id: 2784, name: 'Team Alkemics' };

export const TYPE_ALK_SERVICE = 1;
export const TYPE_ALK_SUPER_ADMIN = 2;
export const TYPE_SUPPORT = 3;
export const TYPE_PLATFORM_ADMIN = 4;
export const TYPE_USER = 5;

export const USER_PENDING_INVITATION = -1;
export const USER_REJECTED_STATUS = 0;
export const USER_ACCEPTED_STATUS = 1;
export const USER_PENDING_STATUS = 2;

export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_LOCAL_ADMIN = 'local_admin';
export const USER_ROLE_USER = 'user';

export const userRoles = [
  {
    value: USER_ROLE_ADMIN,
    label: i18n.t('frontproductstream.constants.user_role.admin', {
      defaultValue: 'Administrator',
    }),
  },
  {
    value: USER_ROLE_LOCAL_ADMIN,
    label: i18n.t('frontproductstream.constants.user_role.local_admin', {
      defaultValue: 'Local Administrator',
    }),
  },
  {
    value: USER_ROLE_USER,
    label: i18n.t('frontproductstream.constants.user_role.user', {
      defaultValue: 'User',
    }),
  },
];
