import { archived as lifeCycleArchived } from 'constants/filters/lifeCycle';
import { notificationApi } from 'modules/notification-list';
import assignationApi from 'resources/assignationApi';
import serviceProductApi from 'resources/serviceProductApi';

export const forwardAssignation = async (emails, reference) => {
  await assignationApi.forward({
    emails,
    gtin: reference,
  });
};

export const declineProduct = async (
  reference,
  targetMarketId,
  reason,
  message
) => {
  await serviceProductApi.declineProduct(
    reference,
    targetMarketId,
    reason,
    message
  );
};

export const createProductAsArchived = async (
  reference,
  targetMarketId,
  reason,
  message
) => {
  await serviceProductApi.createProduct(
    reference,
    targetMarketId,
    reason,
    {
      productVersion: {
        data: {
          lifeCycle: lifeCycleArchived.id,
          isConsumerUnit: true,
        },
      },
    },
    message,
    true
  );
};

export const ignoreNotification = async (reference) => {
  await notificationApi.ignore(reference);
};
