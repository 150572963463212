import { isManufacturer, isRetailer } from 'core/api/user';
import { getBU } from 'modules/rfp/modules/answers/api';
import { selectUser } from 'reducers/user/selectors';
import * as routes from 'routes';
import { push } from 'utils/history';
import { fill } from 'utils/routing';

export const redirectToRFPPages = async (query, _, getState) => {
  const user = selectUser(getState());
  const gtin = query.get('gtin');
  const buId = query.get('buId');
  if (isManufacturer(user)) {
    /** @type {{ data: any }} */
    const { data } = await getBU(buId);
    push(fill(routes.rfpEventBUDetails, data.event.id, buId), {
      search: gtin,
    });
  } else if (isRetailer(user)) {
    push(fill(routes.rfpAnswers), {
      businessUnitIds: buId,
      search: gtin,
    });
  } else {
    push(routes.rfp);
  }
};

export const RFPPage = (query) => (dispatch, getState) => {
  redirectToRFPPages(query, dispatch, getState);
};
