import { Spinner } from '@alkem/react-ui-spinner';

const withSpinner =
  (CustomSpinner: any) =>
  (WrappedComponent: any) =>
  ({ loading, ...props }: { loading: boolean; [key: string]: any }) => {
    if (loading) {
      if (CustomSpinner) {
        return <CustomSpinner />;
      } else {
        return <Spinner medium />;
      }
    } else {
      return <WrappedComponent {...props} />;
    }
  };

export default withSpinner;
