import classNames from 'classnames';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';
import Tooltip from '@alkem/react-ui-tooltip';

import RaguelStatic from 'components/ui/form/plugins/validator/static';
import LinkButton from 'core/components/button/link';
import {
  PriceTemplateStatusBadge,
  TemplateStatuses,
} from 'modules/sharing-unit-tariffs';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { fill } from 'utils/routing';

import './styles.scss';

/**
 * @param {object} props
 * @param {any} props.tariff
 * @param {boolean} props.canUpdateTariff
 * @param {boolean=} props.isLogisticalUnit
 * @returns {JSX.Element}
 */
function SharingUnitTariff(props) {
  return (
    <div
      className={classNames(
        props.tariff.get('errorNumber') > 0 && 'FormField--raguelError'
      )}
    >
      <div className="Tariff">
        <div className="Tariff__linked-to">
          {i18n.t(
            'frontproductstream.product_page.sharing_unit_tariff_linked_to.text',
            { defaultValue: 'Linked to' }
          )}
        </div>
        <div className="Tariff__info">
          <span>{props.tariff.get('name')}</span>
          <PriceTemplateStatusBadge status={props.tariff.get('status')} />
        </div>

        {props.canUpdateTariff && (
          <div className="Tariff__action">
            {props.isLogisticalUnit ? (
              <div>
                <Tooltip
                  id={`type-packaging-choice-${props.tariff.get('id')}`}
                  place="bottom"
                >
                  {i18n.t(
                    'frontproductstream.product_page.sharing_unit_tariff_product_now_logistical_unit.tooltip',
                    { defaultValue: 'This product is now a logistical unit' }
                  )}
                </Tooltip>
                <span
                  data-tip
                  data-for={`type-packaging-choice-${props.tariff.get('id')}`}
                  className="btn btn-secondary disabled"
                >
                  {i18n.t(
                    'frontproductstream.product_page.sharing_unit_tariff_see.button',
                    { defaultValue: 'View' }
                  )}
                </span>
              </div>
            ) : (
              <Link
                to={fill(
                  routes.SharingUnitTariffsDetails,
                  props.tariff.get('id')
                )}
                className="btn btn-secondary"
              >
                {i18n.t(
                  'frontproductstream.product_page.sharing_unit_tariff_see.button',
                  { defaultValue: 'View' }
                )}
              </Link>
            )}
          </div>
        )}
      </div>

      {props.tariff.get('errorNumber') > 0 && (
        <div className="Tariff-error">
          <RaguelStatic
            blocking={props.tariff.get('hasBlockingErrors')}
            message={i18n.t(
              'frontproductstream.product_page.sharing_unit_tariff_errors_on_tariff_data.error',
              {
                defaultValue: "You have {{count}} error on the tariff's data",
                count: props.tariff.get('errorNumber'),
              }
            )}
          />
        </div>
      )}
    </div>
  );
}

SharingUnitTariff.propTypes = {
  isLogisticalUnit: PropTypes.bool.isRequired,
  tariff: ImmutablePropTypes.map.isRequired,
  canUpdateTariff: PropTypes.bool.isRequired,
};

function SharingUnitTariffs(props) {
  const {
    tariffs,
    tariffRules,
    isLogisticalUnit,
    canUpdateTariff,
    canSaveProduct,
    productRequiresSaving,
    actions,
    recipient,
  } = props;
  const navigate = useNavigate();

  function renderButtons() {
    if (tariffs.size > 0) {
      return null;
    }
    if (canSaveProduct && productRequiresSaving) {
      return (
        <Button
          secondary
          content={i18n.t(
            'frontproductstream.product_page.sharing_unit_tariff_save_and_go_to_tariff_conditions.button',
            { defaultValue: 'Save and go to tariff and conditions' }
          )}
          className="Button_Tariffs"
          /* eslint-disable-next-line react/jsx-no-bind */
          onClick={() =>
            actions.saveProduct(false, routes.SharingUnitTariffsList)
          }
        />
      );
    } else {
      return (
        <Button
          secondary
          content={i18n.t(
            'frontproductstream.product_page.sharing_unit_tariff_go_to_tariff_conditions.button',
            { defaultValue: 'Go to tariff and conditions' }
          )}
          className="Button_Tariffs"
          onClick={() => navigate(routes.SharingUnitTariffsList)}
        />
      );
    }
  }

  const tariffInError = tariffs.filter((t) => t.get('errorNumber') > 0);
  const tariffWithoutError = tariffs.filter((t) => t.get('errorNumber') === 0);

  const shouldDisplayWarning =
    (!tariffRules || tariffRules.size === 0) &&
    tariffWithoutError.size > 0 &&
    tariffWithoutError.filter((tariff) =>
      [
        TemplateStatuses.PUBLISHED.id,
        TemplateStatuses.REPUBLISHED.id,
        TemplateStatuses.ACCEPTED.id,
      ].includes(tariff.get('status'))
    ).size === 0;

  return (
    <div className="Tariffs">
      <h3>
        {i18n.t('frontproductstream.product_page.sharing_unit_tariff.title', {
          defaultValue: 'Tariff',
        })}
      </h3>
      <div
        className={classNames(
          'Tariffs__list',
          shouldDisplayWarning && 'FormField--raguelWarning', // No worries: the conditions are mutually exclusive
          tariffRules &&
            tariffRules.size > 0 &&
            tariffRules.some(
              (r) => get(r, 'status') === 1 && !get(r, 'bypassed')
            ) &&
            'FormField--raguelError'
        )}
      >
        {shouldDisplayWarning && (
          <div className="Tariffs__list__error">
            <RaguelStatic
              message={i18n.t(
                'frontproductstream.product_page.sharing_unit_tariff_product_not_linked_to_published_template.error',
                {
                  defaultValue:
                    'Your product should be linked to a published template',
                }
              )}
              buttons={renderButtons()}
              warning
            />
          </div>
        )}
        {tariffWithoutError.map((tariff) => (
          <SharingUnitTariff
            tariff={tariff}
            isLogisticalUnit={isLogisticalUnit}
            key={tariff.get('id')}
            canUpdateTariff={canUpdateTariff}
          />
        ))}
        {tariffRules && tariffRules.size > 0 && (
          <div className="Tariffs__list__error">
            {tariffRules
              .map((rule) => (
                <RaguelStatic
                  key={get(rule, ['id'])}
                  rule={rule}
                  recipientId={recipient.get('id')}
                />
              ))
              .toArray()}
            {renderButtons()}
          </div>
        )}
      </div>
      <div className="Tariffs__in-error">
        {tariffInError.map((tariff) => (
          <SharingUnitTariff
            key={tariff.get('id')}
            tariff={tariff}
            canUpdateTariff={canUpdateTariff}
          />
        ))}
      </div>
      <div className="Tariffs__footer">
        {i18n.t(
          'frontproductstream.product_page.sharing_unit_tariff_help_add_tariff_section.text',
          {
            defaultValue:
              "To add a tariff section, go to a draft tariff and add this product to the linked product list. Don't forget to publish the tariff once completed.",
          }
        )}
        &nbsp;
        {canUpdateTariff ? (
          <LinkButton
            to={routes.SharingUnitTariffsList}
            className="CatalogMenu__createProduct"
            secondary
          >
            {i18n.t(
              'frontproductstream.product_page.sharing_unit_tariff_go_to_tariff_conditions.link',
              { defaultValue: 'Go to tariff and conditions' }
            )}
          </LinkButton>
        ) : (
          <span>
            {i18n.t(
              'frontproductstream.product_page.sharing_unit_tariff_contact_admin.text',
              { defaultValue: 'Contact an Admin' }
            )}
          </span>
        )}
      </div>
    </div>
  );
}

SharingUnitTariffs.propTypes = {
  tariffs: ImmutablePropTypes.list.isRequired,
  tariffRules: ImmutablePropTypes.list,
  isLogisticalUnit: PropTypes.bool.isRequired,
  canUpdateTariff: PropTypes.bool.isRequired,
  canSaveProduct: PropTypes.bool.isRequired,
  productRequiresSaving: PropTypes.bool.isRequired,
  recipient: ImmutablePropTypes.map.isRequired,
  actions: PropTypes.shape({
    saveProduct: PropTypes.func.isRequired,
  }).isRequired,
};

export default SharingUnitTariffs;
