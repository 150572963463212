import classNames from 'classnames';
import { isEqual } from 'lodash';
import { sortBy } from 'lodash/fp';
import { connect } from 'react-redux';

import { AddButton } from '@alkem/react-ui-button';
import { InputDisabled } from '@alkem/react-ui-inputs';

import { cleanFieldLabel } from 'components/ui/form/field/utils/clean';
import {
  enrichChildFields,
  filterChildFields,
} from 'components/ui/form/field/utils/filter';
import { getDefaultValueWithChildren } from 'components/ui/form/field/utils/seed';
import FormGroup from 'components/ui/form/form-group';
import Raguel from 'components/ui/form/plugins/validator';
import i18n from 'utils/i18n';
import { isEmpty } from 'utils/isEmpty';

import { FormList } from '../list';

import './multi-level-field.scss';

class MultiLevelField extends FormList {
  multiLevel = true;
  multipleChildrenContainerClass =
    'col-xs-12 MultiLevelField__list--multipleChildren';
  canBeEmpty: boolean;

  constructor(props) {
    super(props);

    this.canBeEmpty = isEmpty(props.value);
  }

  renderItems(hasSingleChild, seededItemIndex) {
    const {
      entity,
      value,
      field,
      entityKind,
      entityId,
      validate,
      extraParams,
    } = this.props;

    if (!value || !value.length) {
      return null;
    }

    // `value` holds the list of already selected items
    const noRepetition = field.options && field.options.noRepetition;
    let excludeList = noRepetition ? [...value] : [];
    if (['isComplementaryWith', 'isSubstitutableWith'].includes(field.model)) {
      excludeList = excludeList
        .map((item) => item.targetProduct)
        .filter((item) => item)
        .map((item) => ({ ...item, key: item.id }));
    }
    return value.map((_item, i) => {
      const localKey = `MultiLevelField__${field.model}-${i}`;
      const enrichedFields = enrichChildFields(field, i, excludeList);
      const filteredFields = sortBy(
        'rank',
        filterChildFields(entity, field, enrichedFields)
      );
      const cleanedFields = hasSingleChild
        ? filteredFields.map((childField) => cleanFieldLabel(childField))
        : filteredFields;

      const dg = {
        kind: 'DisplayGroup',
        items: cleanedFields,
      };

      const hideDeleteButton =
        i === seededItemIndex &&
        i === 0 &&
        !this.state.deletionMade &&
        !this.canBeEmpty;

      // Special case for declinable sub field.
      const singleChild = hasSingleChild
        ? this.getSingleChild(cleanedFields)
        : null;

      const declinableSingleChild =
        singleChild && 'declinableBy' in singleChild;

      // If multiple elements, only display once.
      const shouldInstantiateRaguel =
        hasSingleChild &&
        validate &&
        i === value.length - 1 &&
        !declinableSingleChild;

      const raguelModel =
        (singleChild &&
          singleChild.declinableBy &&
          `${singleChild.model}.0.data`) ||
        (singleChild && singleChild.model);

      const {
        onChange,
        noDispatch,
        entityIndex,
        flags,
        patch,
        disableDataOps,
        recipientId,
      } = extraParams || {};
      const renderedItem = [
        <div className="row MultiLevelField__item" key={localKey}>
          <div className="col-xs-12">
            <div className="MultiLevelField__itemContent">
              <FormGroup
                entity={entity}
                entityKind={entityKind}
                entityId={entityId}
                excludeList={excludeList}
                formGroup={dg}
                borders={!hasSingleChild}
                condensed
                validate={
                  validate && (!hasSingleChild || declinableSingleChild)
                }
                onChangeField={onChange}
                noDispatch={noDispatch}
                entityIndex={entityIndex}
                flags={flags}
                patch={patch}
                disableDataOps={disableDataOps}
              />
              {this.renderDeleteButton(i, hideDeleteButton)}
            </div>
          </div>
        </div>,
      ];
      if (shouldInstantiateRaguel) {
        renderedItem.push(
          <Raguel
            entityId={entityId}
            entityKind={entityKind}
            label={field.label}
            model={raguelModel}
            value={value}
            onJudgmentDay={this.onRaguelJudgment}
            readOnly={this.isReadOnly()}
            key="raguel"
            recipientId={recipientId}
          />
        );
      }
      return renderedItem;
    });
  }

  renderAddButton(_hasSingleChild, seededItemIndex) {
    const { field, value } = this.props;
    if (this.isReadOnly() || (this.hasSingleItem() && value?.length > 0)) {
      return null;
    }
    if (field.validators && value) {
      const validator = field.validators.find(
        (e) => e.kind === 'maxLengthList'
      );
      if (validator && value.length >= validator.arg) {
        return null;
      }
    }
    const disabled = seededItemIndex !== -1;
    const label = disabled
      ? i18n.t(
          'frontproductstream.multi_level_field.add_button.disabled_label',
          { defaultValue: 'Please modify the last item before adding another' }
        )
      : `${i18n.t('frontproductstream.multi_level_field.add_button.label', {
          defaultValue: 'Add',
        })} ${field.label.toLowerCase()}`;
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="MultiLevelField__addButton">
            <AddButton
              label={label}
              onClick={this.onAddItemClick}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  }

  renderDeleteButton(index, isHidden) {
    if (this.isReadOnly() || this.hasSingleItem() || isHidden) {
      return null;
    }
    const removeButtonClassNames = {
      MultiLevelField__deleteButton: true,
    };
    return (
      <div
        className={classNames(removeButtonClassNames)}
        onClick={this.onDeleteItem(index)}
      >
        <i className="mdi mdi-delete" />
      </div>
    );
  }

  renderSingleChild(seededItemIndex) {
    return (
      <div
        id={this.getId()}
        className="col-xs-12 MultiLevelField__list--singleChild"
      >
        {this.renderItems(true, seededItemIndex)}
        {this.renderAddButton(true, seededItemIndex)}
        {this.renderPlugins()}
      </div>
    );
  }

  renderEmptyReadOnly() {
    return (
      <div className="col-xs-12 InputField__input">
        <InputDisabled
          id={this.getId()}
          emptyValueString={i18n.t(
            'frontproductstream.multi_level_field.empty_state.text',
            { defaultValue: 'This field has not been filled' }
          )}
        />
        {this.renderPlugins({ offset: true })}
      </div>
    );
  }

  render() {
    const { field, value } = this.props;
    if (!field) {
      return null;
    }

    // Check if the seeded data has been modified.
    const defaultValue = getDefaultValueWithChildren(field, null, true);
    const seededItemIndex = value
      ? value.findIndex((e) => isEqual(e, defaultValue))
      : -1;

    let content;
    if (this.isReadOnly() && (!value || !value.length)) {
      content = this.renderEmptyReadOnly();
    } else if (this.hasSingleChild()) {
      content = this.renderSingleChild(seededItemIndex);
    } else {
      content = this.renderMultipleChildren(seededItemIndex);
    }

    return (
      <div
        className={classNames(
          this.getClasses({ MultiLevelField: true, row: true })
        )}
      >
        {this.renderLabel('col-xs-12')}
        {content}
      </div>
    );
  }
}

export default connect()(MultiLevelField);
