import { filter, flatten } from 'lodash/fp';

export const findFields = ({ type, path }, fieldName) => {
  if (type === 'consumer unit') {
    const formGroup = document.querySelector(
      `[id="form-group-${path.split('.')[0]}`
    );
    if (formGroup) {
      return formGroup.querySelectorAll(
        `[id*='FormField-'][id*='-${fieldName}']`
      );
    }
  }
  if (type === 'media') {
    return document.querySelectorAll(`[id="form-group-${path.split('.')[0]}`);
  }
  return document.querySelectorAll(`[id*='FormField-'][id*='-${fieldName}']`);
};

export const findMessage = (currentError?: {
  fieldName: string;
  message: string;
  listPosition?: number;
  ruleId: number;
  type: any;
  path: any;
}) => {
  if (currentError) {
    const { fieldName, message, listPosition, ruleId } = currentError;
    const [errorField] = findFields(currentError, fieldName);

    let validationErrors: any[] = [];

    // TODO does this code not actually belong into findFields? oO
    const formList =
      errorField && errorField.querySelector('.FormList__itemContent');
    if (formList && typeof listPosition !== 'undefined') {
      const formGroup = formList.querySelector('.form-group');
      if (formGroup) {
        validationErrors = [
          formGroup.querySelectorAll('.FormField--raguelError')[listPosition],
        ].filter((x) => x);
      }
    }

    if (!validationErrors.length) {
      validationErrors = (
        errorField && errorField.closest('.FormField--raguelError')
          ? [errorField && errorField.closest('.FormField--raguelError')]
          : (errorField &&
              Array.from(
                errorField.querySelectorAll('.FormField--raguelError')
              )) ||
            []
      ).filter((x) => x);
    }

    let errorMessages: any[] = [];
    if (validationErrors.length) {
      errorMessages = flatten(
        Array.from(validationErrors).map(
          (validationError: any) =>
            (validationError &&
              Array.from(
                validationError.querySelectorAll(
                  '.Raguel__message:not(.Raguel__message--disabled)'
                )
              )) ||
            []
        )
      );
    } else if (errorField) {
      errorMessages = Array.from(
        errorField.querySelectorAll(
          '.Raguel__message:not(.Raguel__message--disabled)'
        )
      );
      if (errorMessages.length === 0) {
        errorMessages = Array.from(
          (errorField as any)
            .closest('[id^=form-group]')
            .querySelectorAll(
              '.Raguel__message:not(.Raguel__message--disabled)'
            )
        );
      }
    }

    return errorMessages.find((el: any) => {
      if (el) {
        if (ruleId) {
          return ruleId.toString() === el.getAttribute('data-rule-id');
        }
        return el.textContent && el.textContent.includes(message);
      }
      return false;
    });
  }
};

// ----------------------------------------------------
// Tools to print certain values:
// (they will make your life much easier!)

export const printDisplayGroups = (displayGroups) =>
  displayGroups.map(printDisplayGroup).filter((x) => x);

function printDisplayGroup(node) {
  if (node.options && node.options.visible === false) {
    return null;
  }

  const result = {};

  [
    //
    'id',
    'model',
    'rank',
  ].forEach((prop) => {
    if (node[prop]) {
      result[prop] = node[prop];
    }
  });

  [
    //
    'children',
    'items',
  ].forEach((prop) => {
    if (node[prop] && node[prop].length) {
      result[prop] = printDisplayGroups(node[prop]);
    }
  });

  return result;
}

// to be mapped over result of selectFailedRulesForStepper
// Ex: console.log('failedRules', failedRules.toJS().map(printFailedRule));
export const printFailedRule = (node: any) => {
  const result: any = {};

  if (node.root_fields && node.root_fields.length === 1) {
    [result.root_field] = node.root_fields;
  }

  if (node.root_field) {
    result.root_field = node.root_field;
  }

  const paths = filter((p) => p.length, node.paths);
  if (paths.length > 1) {
    result.paths = flatten(paths);
  } else {
    [result.path] = flatten(paths);
  }

  return result;
};

interface Error {
  fieldName: string;
  rank: number;
  order: number;
  path: string;
  listPosition: number;
  message: string;
}
type Column = [string, number, (error: Error) => string];
/**
 * Print field errors to console (for debug)
 *
 * @param debug set to true if you want to enable the logging
 * @param errors errors (by display group)
 * @param nextIndex next (actually current) error index
 */
/* eslint-disable no-console */
export function printErrors(label, errors, currentIndex) {
  console.log(label);
  const columns: Column[] = [
    ['Rank', 19, (error) => String(error.rank || '')],
    ['Path', 11, (error) => error.path],
    ['Order', 1, (error) => String(error.order || '')],
    ['List Position', 1, (error) => String(error.listPosition || '')],
    ['Field', 25, (error) => error.fieldName],
    ['Message', 23, (error) => error.message],
  ];

  const indexPads = `${errors.length + 1}`.length - 1;

  const header = [`${' '.repeat(indexPads)}# `]
    .concat(
      columns.map(
        (column) => ` ${column[0].slice(0, column[1]).padEnd(column[1])} `
      )
    )
    .join('|');

  const separation = [`--${'-'.repeat(indexPads)}`]
    .concat(columns.map((column) => `-${'-'.repeat(column[1])}-`))
    .join('+')
    .slice(0, -1);

  const lines = errors.map((error, index) =>
    [
      index === currentIndex ? '%c' : '',
      [`${index + 1} `.padStart(indexPads + 2)]
        .concat(
          columns.map(
            (column) =>
              ` ${(column[2](error) || '')
                .slice(0, column[1])
                .padEnd(column[1])} `
          )
        )
        .join('|'),
      index === currentIndex ? '%c' : '',
    ].join('')
  );

  console.log(
    ['', header, separation, lines.join('\n')].join('\n'),
    'font-weight: bold',
    'font-weight: normal'
  );
}
/* eslint-enable no-console */
