import { List } from 'immutable';
import { flow } from 'lodash/fp';
import { createSelector } from 'reselect';

const selectModuleState = (state) => state.productPage;

export const selectIsLoading = createSelector(selectModuleState, (state) =>
  state.get('isLoading')
);

export const selectIsSavingInProgress = createSelector(
  selectModuleState,
  (state) => state.get('savingInProgress')
);

export const selectWasSavedSuccessful = createSelector(
  selectModuleState,
  (state) => state.get('savingSuccessful')
);

export const selectIsSettingVisibility = createSelector(
  selectModuleState,
  (state) => state.get('settingVisibility')
);

export const selectIsHeterogeneousUnitSelected = createSelector(
  selectModuleState,
  (state) => state.get('isHeterogeneousUnitSelected')
);

export const selectRejectedFields = flow(
  selectModuleState,
  (state) => state.get('rejectedFields') ?? {}
);

export const selectRuleSetDeadlines = createSelector(
  selectModuleState,
  (state) => state.get('maturityRuleSetDeadlines')
);

export const selectMaturityRuleSetsWithDeadlinesToBeDefined = createSelector(
  selectModuleState,
  (state) => state.get('promptedMaturityRuleSetsWithDeadlines') ?? List()
);
