import { isManufacturer } from 'core/api/user';
import { Filter } from 'core/modules/list/constants/filters/types';
import {
  RELEASE_NEW_MATURITY_COMPONENT,
  hasFeature,
} from 'modules/feature-flag';
import { UserImmutable } from 'types';

// only used for aggregation
export const manufacturerScenariosAgg: Filter = {
  key: 'manufacturer_scenarios',
  customField: 'manufacturer_scenarios',
  query: 'never used',
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    isManufacturer(user) && hasFeature(user, RELEASE_NEW_MATURITY_COMPONENT),
};
