import { List, Map } from 'immutable';
import { flow, isEqual } from 'lodash/fp';

import { PRODUCTS_NOT_DUPLICATED } from 'constants/duplicated-products';
import { archived } from 'constants/filters/lifeCycle';
import { duplicatedProductsFilter } from 'core/modules/list/constants/filters/duplicatedProducts';
import { lifecycleFilter } from 'core/modules/list/constants/filters/lifecycle';
import {
  updateDuplicatedProducts,
  updateWithArchivedProducts,
} from 'core/modules/list/reducers/filters-config';
import {
  get,
  isImmutable,
  remove,
  set,
  setFp,
  updateFp,
} from 'utils/immutable';

import { resetPage, resetSelection } from '../list';

export const initSelectedFilterState = (
  state,
  { withImmutable = true } = {}
) => {
  if (withImmutable) {
    const newState = set(state, 'selectedFilterList', List());
    return set(newState, 'selectedFilterMap', Map());
  }
  return { ...state, selectedFilterList: [], selectedFilterMap: {} };
};

export const updateOneSelectedFilter = (state, { payload }) => {
  let newState = state;

  const key = get(payload, 'key');
  const add = get(payload, 'add');
  const value = get(payload, 'value');
  const data = get(payload, 'data') || true;
  const singleValue = get(payload, 'singleValue') || false;
  const useAndOperator = get(payload, 'useAndOperator') || false;

  if (useAndOperator) {
    newState = set(
      newState,
      ['filtersConfig', key, 'useAndOperator'],
      useAndOperator
    );
  }

  if (singleValue) {
    newState = flow(
      updateFp('selectedFilterMap', (m) => remove(m, key)),
      updateFp('selectedFilterList', (list) =>
        list.filter((filter) => get(filter, 'key') !== key)
      )
    )(newState);
  }

  if (add) {
    const existingElement = get(newState, 'selectedFilterList').find(
      (filter) =>
        get(filter, 'key') === key && isEqual(get(filter, 'value'), value)
    );

    if (!existingElement) {
      newState = flow(
        updateFp('selectedFilterMap', (m) => set(m, [key, value], data)),
        updateFp('selectedFilterList', (list) =>
          isImmutable(list) ? list.push(payload) : [...list, payload]
        )
      )(newState);
    } else if (get(existingElement, 'data') !== get(payload, 'data')) {
      newState = flow(
        updateFp('selectedFilterMap', (m) => set(m, [key, value], data)),
        updateFp('selectedFilterList', (list) => {
          const filteredList = list.filter(
            (filter) =>
              get(filter, 'key') !== key ||
              !isEqual(get(filter, 'value'), value)
          );

          return isImmutable(filteredList)
            ? filteredList.push(payload)
            : [...filteredList, payload];
        })
      )(newState);
    }
  } else {
    newState = flow(
      updateFp('selectedFilterMap', (m) => remove(m, [key, value])),
      updateFp('selectedFilterList', (list) =>
        list.filter((filter) => {
          return (
            get(filter, 'key') !== key || !isEqual(get(filter, 'value'), value)
          );
        })
      )
    )(newState);
  }

  return set(newState, 'lastFilterUpdated', key);
};

const updateMultipleSelectedFilters = (state, { payload }) => {
  let newState = state;
  payload.forEach((filterOpt) => {
    newState = updateOneSelectedFilter(newState, {
      payload: filterOpt,
    });
  });
  return newState;
};

export const resetSelectedFilters = (state) =>
  flow(
    setFp(
      'selectedFilterMap',
      isImmutable(get(state, 'selectedFilterMap')) ? Map() : {}
    ),
    setFp(
      'selectedFilterList',
      isImmutable(get(state, 'selectedFilterList')) ? List() : []
    )
  )(state);

export const updateSelectedFilters = (state, { payload }) => {
  let newState = state;
  newState = resetPage(newState);
  newState = resetSelection(newState);
  if (get(payload, 'reset')) {
    return resetSelectedFilters(newState);
  } else if (List.isList(payload) || Array.isArray(payload)) {
    return updateMultipleSelectedFilters(newState, { payload });
  }
  return updateOneSelectedFilter(newState, { payload });
};

export const updateSelectedFiltersFromQuery = (state, action) => {
  const { payload } = action;
  const filters = List.isList(payload) ? payload : List([payload]);
  let newState = state;
  if (
    filters.some(
      (filter) =>
        get(filter, 'key') === lifecycleFilter.key &&
        get(filter, 'value') === archived.id &&
        get(filter, 'add')
    )
  ) {
    newState = updateWithArchivedProducts(newState, { payload: true });
  }
  if (
    filters.some(
      (filter) =>
        get(filter, 'key') === duplicatedProductsFilter.key &&
        get(filter, 'value') === PRODUCTS_NOT_DUPLICATED.id &&
        get(filter, 'add')
    )
  ) {
    newState = updateDuplicatedProducts(newState, { payload: true });
  }
  return updateSelectedFilters(newState, action);
};
