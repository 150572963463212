import { get } from 'lodash';

import { BillingAddress } from '@alkem/sdk-dashboard';

import billingApi from 'resources/billing';

export const subscribeToPlan = ({
  planId,
  couponId,
}: {
  planId: string;
  couponId?: string;
}) => billingApi.subscribeToPlan({ planId, couponId });

export const cancelSubscription = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => billingApi.cancelSubscription({ subscriptionId });

export const fetchCustomer = async ({
  withSubscriptions,
}: { withSubscriptions?: boolean } = {}) => {
  const { response, error } = await billingApi.fetchCustomer({
    withSubscriptions,
  });
  if (error) {
    return { error };
  }
  const data = get(response, ['data', 'data']) || {
    infos: undefined,
    subscriptions: undefined,
  };
  return {
    customer: data.infos || {},
    subscriptions: data.subscriptions || [],
  };
};

export const fetchCustomerSource = async () => {
  const { response, error } = await billingApi.fetchCustomerSource();
  if (error) {
    return { error };
  }
  return { source: get(response, ['data', 'data']) };
};

export const upsertCustomer = async ({
  sourceId,
  sourceType,
  billingAddress,
  taxId,
}: {
  sourceId: string;
  sourceType: string;
  billingAddress: BillingAddress;
  taxId?: string;
}) =>
  billingApi.upsertCustomer({
    sourceId,
    sourceType,
    billingAddress,
    taxId: taxId || '',
  });

export const fetchComputedBill = async ({
  planId,
  couponId,
}: {
  planId: string;
  couponId?: string;
}) => {
  const { response, error } = await billingApi.fetchComputedBill({
    planId,
    couponId,
  });
  if (error) {
    return { error };
  }
  return { bill: get(response, ['data', 'data']) };
};

export const fetchNextBill = async ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => {
  const { response, error } = await billingApi.fetchNextBill({
    subscriptionId,
  });
  if (error) {
    return { error };
  }
  return { bill: get(response, ['data', 'data']) };
};

export const fetchPlans = async () => {
  const { response, error } = await billingApi.fetchPlans();
  if (error) {
    return { error };
  }
  return { plans: get(response, ['data', 'data']) };
};

export const fetchPlan = async (stripePlanId: string) => {
  const { response, error } = await billingApi.fetchPlan({ stripePlanId });
  if (error) {
    throw error;
  }
  return get(response, ['data', 'data']);
};

export const confirmSubscription = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => billingApi.confirmSubscription({ subscriptionId });
