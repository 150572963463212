import { createSelector } from 'reselect';

import { get } from 'utils/immutable';

function selectState(state) {
  return state.referentials;
}

export const selectReferential = createSelector(
  selectState,
  (referentials) => (slug) => get(referentials, [slug])
);
