import { Set, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  BULK_PREPARE_ASSIGN,
  BULK_PREPARE_ASSIGN_FAILURE,
  BULK_PREPARE_ASSIGN_SUCCESS,
  BULK_PREPARE_RESET,
  BULK_PREPARE_SELECT_GROUP,
  BULK_PREPARE_SHOW,
  BULK_PREPARE_UNSELECT_GROUP,
} from './constants';

export const KEY = 'bulkPrepareForChannels';

export const initialState = fromJS({
  isOpen: false,
  productVersions: {},
  isAssigning: false,
  done: false,

  selectedGroupIds: Set(),
});

export default createReducer(initialState, {
  [BULK_PREPARE_SHOW]: (state, { productVersions }) =>
    state.withMutations((newState) =>
      newState.set('isOpen', true).set('productVersions', productVersions)
    ),
  [BULK_PREPARE_RESET]: () => initialState,

  [BULK_PREPARE_SELECT_GROUP]: (state, { groupId }) =>
    state.update('selectedGroupIds', (selectedGroupIds) =>
      selectedGroupIds.add(groupId)
    ),
  [BULK_PREPARE_UNSELECT_GROUP]: (state, { groupId }) =>
    state.update('selectedGroupIds', (selectedGroupIds) =>
      selectedGroupIds.delete(groupId)
    ),

  [BULK_PREPARE_ASSIGN]: (state) =>
    state.set('isAssigning', true).set('done', false),
  [BULK_PREPARE_ASSIGN_SUCCESS]: (state) =>
    state.set('isAssigning', false).set('done', true),
  [BULK_PREPARE_ASSIGN_FAILURE]: (state) =>
    state.set('isAssigning', false).set('done', false),
});
