import { isObject } from 'lodash';

export function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.reduce((acc, v) => acc && isEmpty(v), true);
  }

  if (isObject(value)) {
    return Object.keys(value).reduce(
      (acc, k) => acc && isEmpty(value[k]),
      true
    );
  }

  return value == null;
}
