import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';

import {
  DISPUTE_1,
  DISPUTE_2,
  DISPUTE_3,
  RECEIVED,
} from 'constants/physicalCheckerStatus';
import {
  fetchContributionDiff,
  openCannotCheckModal,
  submitPhysicalCheck,
} from 'modules/contribution/actions';
import CannotCheckModal from 'modules/contribution/components/modals/cannot-check-modal';
import ContributionDiffModal from 'modules/contribution/components/modals/contribution-diff-modal';
import {
  getPhysicalCheckContribution,
  hasPhysicalCheckCanCannotCheck,
} from 'modules/contribution/selectors';
import {
  hasPhysicalCheckCanFail,
  hasPhysicalCheckCanPass,
  remainingPhysicalCheckFieldsNumber,
} from 'modules/contribution/selectors/check-fields';
import { selectHasThirdPartyPhysicalCheckStatus } from 'modules/feature-flag/selectors';
import i18n from 'utils/i18n';

import './third-party-footer-actions.scss';

const mapDispatchToProps = {
  fetchContributionDiff,
  openCannotCheckModal,
  submitPhysicalCheck,
};

const mapStateToProps = createStructuredSelector({
  hasPhysicalCheck: selectHasThirdPartyPhysicalCheckStatus,
  physicalCheckCanCannotCheck: hasPhysicalCheckCanCannotCheck,
  physicalCheckCanFail: hasPhysicalCheckCanFail,
  physicalCheckCanPass: hasPhysicalCheckCanPass,
  physicalCheckContribution: getPhysicalCheckContribution,
  remainingPhysicalCheckFieldsCount: remainingPhysicalCheckFieldsNumber,
});

export class ThirdPartyFooterActions extends Component {
  static propTypes = {
    // from store
    hasPhysicalCheck: PropTypes.bool,
    physicalCheckCanCannotCheck: PropTypes.bool.isRequired,
    physicalCheckCanFail: PropTypes.bool.isRequired,
    physicalCheckCanPass: PropTypes.bool.isRequired,
    physicalCheckContribution: PropTypes.object.isRequired,
    remainingPhysicalCheckFieldsCount: PropTypes.number.isRequired,

    // handlers
    fetchContributionDiff: PropTypes.func.isRequired,
    openCannotCheckModal: PropTypes.func.isRequired,
    submitPhysicalCheck: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hasPhysicalCheck: false,
    physicalCheckCanCannotCheck: false,
    physicalCheckCanFail: false,
    physicalCheckCanPass: false,
    physicalCheckContribution: Map(),
  };

  passPhysicalCheck = () => {
    this.props.submitPhysicalCheck();
  };

  failPhysicalCheck = () => {
    this.props.fetchContributionDiff();
  };

  showCannotCheckModal = () => {
    this.props.openCannotCheckModal();
  };

  hasPhysicalCheck = () =>
    this.props.hasPhysicalCheck &&
    [RECEIVED.id, DISPUTE_1.id, DISPUTE_2.id, DISPUTE_3.id].includes(
      this.props.physicalCheckContribution.get('status')
    );

  render() {
    const {
      physicalCheckCanFail,
      physicalCheckCanPass,
      physicalCheckCanCannotCheck,
      remainingPhysicalCheckFieldsCount,
      physicalCheckContribution,
    } = this.props;

    return (
      <div className="FooterActions ThirdPartyFooterActions__buttons">
        {this.hasPhysicalCheck && (
          <>
            {physicalCheckContribution.get('status') === RECEIVED.id &&
              physicalCheckCanCannotCheck && (
                <Button
                  primary
                  className="ThirdPartyFooterActions__cannotCheckButton"
                  content={i18n.t('Cannot Be Checked')}
                  onClick={this.showCannotCheckModal}
                />
              )}
            {physicalCheckCanFail && (
              <Button
                primary
                className="ThirdPartyFooterActions__failButton"
                content={i18n.t('Fail')}
                onClick={this.failPhysicalCheck}
              />
            )}
            {physicalCheckCanPass && (
              <Button
                primary
                className="ThirdPartyFooterActions__passButton"
                content={i18n.t('Pass')}
                onClick={this.passPhysicalCheck}
              />
            )}
            {!physicalCheckCanCannotCheck &&
              !physicalCheckCanFail &&
              !physicalCheckCanPass && (
                <span className="ThirdPartyFooterActions__remainingFields">
                  {`${remainingPhysicalCheckFieldsCount} ${i18n.t(
                    'fields need to be checked'
                  )}`}
                </span>
              )}
            <CannotCheckModal />
            <ContributionDiffModal />
          </>
        )}
      </div>
    );
  }
}

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(ThirdPartyFooterActions);
