import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { notificationError, notificationSuccess } from 'actions/notification';
import Modal from 'components/ui/modal';
import mailDomainApi from 'resources/legalIdentifierApi';
import { Notification } from 'types/notification';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

interface Props {
  id: number;
  label: string;
  onDelete: () => Promise<void>;
  notificationError: Notification;
  notificationSuccess: Notification;
}

interface State {
  isOpen: boolean;
  isProcessing: boolean;
}

const mapDispatchToProps = {
  notificationError,
  notificationSuccess,
};

export class DeleteLegalIdentifier extends PureComponent<Props, State> {
  public state = {
    isOpen: false,
    isProcessing: false,
  };

  private isCancelled = false;

  public componentWillUnmount() {
    this.isCancelled = true;
  }

  private showSuccessMessage = () => {
    const { label } = this.props;
    this.props.notificationSuccess(
      i18n.t('frontproductstream.delete_legal_identifier.notification.text', {
        defaultValue:
          'Legal identifier {{label}} has been deleted from your organization',
        label,
      })
    );
  };

  private showErrorMessage = () => {
    this.props.notificationError(
      i18n.t('frontproductstream.delete_legal_identifier.notification.error', {
        defaultValue: 'Could not delete this legal identifier',
      }),
      { context: 'modal' }
    );
  };

  private deleteLegalIdentifier = async () => {
    const { id } = this.props;
    this.setState({ isProcessing: true });
    try {
      await mailDomainApi.LegalIdentifierDelete(id);
      if (this.isCancelled) {
        return;
      }
      this.showSuccessMessage();
      this.setState({ isOpen: false });
      this.props.onDelete();
    } catch (error) {
      logError(error);
      this.showErrorMessage();
    } finally {
      this.setState({ isProcessing: false });
    }
  };

  private openModal = () => {
    this.setState({ isOpen: true });
  };

  private closeModal = () => {
    this.setState({ isOpen: false });
  };

  public render() {
    const { label } = this.props;
    const { isOpen, isProcessing } = this.state;
    const modalTitle = i18n.t(
      'frontproductstream.delete_legal_identifier.modal_title.text',
      {
        defaultValue: 'Delete legal identifier {{label}}',
        label,
      }
    );
    return (
      <div>
        <div
          className="mdi mdi-delete OrganizationLabelIdentifierList__delete"
          onClick={this.openModal}
        />
        {isOpen && (
          <Modal
            title={modalTitle}
            modalStyle="dynamic"
            confirmButtonText={i18n.t('Delete')}
            isProcessing={isProcessing}
            onConfirm={this.deleteLegalIdentifier}
            onClose={this.closeModal}
          >
            <p>
              {i18n.t(
                'frontproductstream.delete_legal_identifier.modal_body.text',
                {
                  defaultValue:
                    'Do you want to delete the label identifier {{label}}',
                  label,
                }
              )}
            </p>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(DeleteLegalIdentifier);
