import { set, update } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import {
  PHYSICAL_CHECK_FIELD,
  SELECT_FIELD,
} from 'modules/contribution/constants';
import { traverseOn } from 'utils/fp';

import {
  CLOSE_ALL_FORM_GROUPS,
  CLOSE_FORM_GROUP,
  FORCE_EDITABLE_DISPLAY_GROUPS,
  OPEN_FORM_GROUP,
  RECEIVE_DISPLAY_GROUPS,
  RESET_DISPLAY_GROUPS,
  RESET_DISPLAY_GROUPS_READONLY,
  RESET_EDITABLE_DISPLAY_GROUPS,
  RESET_SELECTED_HETEROGENEOUS_UNIT,
  SELECTED_HETEROGENEOUS_UNIT,
} from './constants';
import { buildItemPath, makeFieldEditable } from './utils';

const initialState = {
  default: [],
  specificFields: [],
  nonSpecificFields: [],
  pathsOfWriteableFields: [],
  collapsedFormGroups: {},
  editable: false,
  shouldFetchHeterogeneousFields: false,
};

// Referential 259: Specific Fields for Organization
export const SPECIFIC_FIELDS_ID = 259;

export const findItemWhere = (check, items) =>
  items.reduce((acc, item) => {
    if (check(item)) {
      acc.push(item);
    }
    if (item.items) {
      const result = findItemWhere(check, item.items);
      if (result.length) {
        acc.push(...result);
      }
    }
    return acc;
  }, []);

export default createReducer(initialState, {
  [RECEIVE_DISPLAY_GROUPS]: (state, action) => {
    const allDisplayGroups = action.list;

    const specificFieldsDisplayGroups = [];
    const nonSpecificFields = allDisplayGroups.filter((dp) => {
      if (dp.id === SPECIFIC_FIELDS_ID) {
        specificFieldsDisplayGroups.push(dp);
      }
      return dp.id !== SPECIFIC_FIELDS_ID;
    });
    return {
      ...state,
      specificFields: specificFieldsDisplayGroups,
      nonSpecificFields,
      default: allDisplayGroups,
    };
  },

  [SELECT_FIELD]: (state, { payload: { model, value } }) => {
    const isReadOnly = value !== PHYSICAL_CHECK_FIELD.FAIL;
    return makeFieldEditable(state, ['default'], model, isReadOnly);
  },

  [RESET_DISPLAY_GROUPS]: () => initialState,

  [RESET_DISPLAY_GROUPS_READONLY]: (state, { readOnly }) => ({
    ...state.pathsOfWriteableFields.reduce(
      (acc, path) =>
        update(
          buildItemPath(path),
          traverseOn('children', set('options.readOnly', readOnly)),
          acc
        ),
      state
    ),
    pathsOfWriteableFields: [],
  }),

  [OPEN_FORM_GROUP]: (state, { payload }) => {
    return set(['collapsedFormGroups', payload.formGroupId], false, state);
  },

  [CLOSE_FORM_GROUP]: (state, { payload }) =>
    set(['collapsedFormGroups', payload.formGroupId], true, state),

  [CLOSE_ALL_FORM_GROUPS]: (state) =>
    set(['collapsedFormGroups'], initialState.collapsedFormGroups, state),

  [FORCE_EDITABLE_DISPLAY_GROUPS]: set(['editable'], true),
  [RESET_EDITABLE_DISPLAY_GROUPS]: set(['editable'], false),

  [SELECTED_HETEROGENEOUS_UNIT]: (state, payload) =>
    set(['shouldFetchHeterogeneousFields'], payload.value, state),
  [RESET_SELECTED_HETEROGENEOUS_UNIT]: (state) =>
    set(
      ['shouldFetchHeterogeneousFields'],
      initialState.shouldFetchHeterogeneousFields,
      state
    ),
});
