import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
  ArchivedProductCatalog,
  AssignationCatalog,
  ProductCatalogForMenu,
  ProductToReviewCatalog,
} from 'modules/catalog/product';
import { InformationRequestList } from 'modules/information-requests';
import * as routes from 'routes';
import { fill, relativeLaxPath, relativePath } from 'utils/routing';

type Props = {
  defaultRoute?: string;
};

const CatalogBody = ({ defaultRoute }: Props) => {
  const location = useLocation();
  const parentRoute = routes.catalog;
  const [reference, view] = relativePath(location.pathname, parentRoute).split(
    '/'
  );

  return (
    <Routes>
      <Route
        path={relativeLaxPath(routes.catalogAssignation, parentRoute)}
        element={<AssignationCatalog />}
      />
      <Route
        path={relativeLaxPath(routes.catalogAll, parentRoute)}
        element={<ProductCatalogForMenu />}
      />
      <Route
        path={relativeLaxPath(routes.catalogArchived, parentRoute)}
        element={<ArchivedProductCatalog />}
      />
      <Route
        path={relativeLaxPath(routes.catalogToReview, parentRoute)}
        element={<ProductToReviewCatalog />}
      />
      <Route
        path={relativeLaxPath(routes.informationRequestList, parentRoute)}
        element={<InformationRequestList />}
      />
      <Route
        path={relativePath(`${routes.catalog}/:reference/:view`, parentRoute)}
        element={
          <Navigate
            to={{
              pathname: fill(
                `${routes.productPageLegacy}/:view`,
                reference,
                view
              ),
              search: location.search,
            }}
          />
        }
      />
      <Route
        path={relativePath(`${routes.catalog}/:reference`, parentRoute)}
        element={
          <Navigate
            to={{
              pathname: fill(routes.productPageLegacy, reference),
              search: location.search,
            }}
          />
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={{
              pathname:
                defaultRoute &&
                [
                  routes.catalogAssignation,
                  routes.catalogAll,
                  routes.catalogArchived,
                  routes.catalogToReview,
                  routes.informationRequestList,
                ].includes(defaultRoute)
                  ? defaultRoute
                  : routes.catalogAll,
              search: location.search,
            }}
          />
        }
      />
    </Routes>
  );
};

export { CatalogBody };
