import { Map, fromJS, is } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { UPDATE_ENTITY } from 'constants/events/entity';
import {
  SHARING_UNIT_STATUS_AGREED,
  SHARING_UNIT_STATUS_DELETED,
  SHARING_UNIT_STATUS_DRAFT,
  SHARING_UNIT_STATUS_INIT,
  SHARING_UNIT_STATUS_REFUSED,
} from 'modules/sharing-units/constants';
import { toLinearHierarchy } from 'modules/sharing-units/utils/hierarchy';
import i18n from 'utils/i18n';

import {
  RETAILER_RECEIVE_SHARINGUNITS,
  RETAILER_RESET_SHARINGUNITS,
  RETAILER_UPDATE_SINGLE_SHARINGUNIT,
} from './constants';

const initialState = fromJS({
  sharingunitsMap: {},
  sharingunitsByStatus: {
    [SHARING_UNIT_STATUS_INIT]: [],
    [SHARING_UNIT_STATUS_AGREED]: [],
    [SHARING_UNIT_STATUS_DELETED]: [],
    [SHARING_UNIT_STATUS_DRAFT]: [],
    [SHARING_UNIT_STATUS_REFUSED]: [],
  },
  loading: false,
});

export default createReducer(initialState, {
  [RETAILER_RECEIVE_SHARINGUNITS]: (
    state,
    { sharingunits, flattenHierarchies }
  ) => {
    const allStatuses = [
      SHARING_UNIT_STATUS_INIT,
      SHARING_UNIT_STATUS_AGREED,
      SHARING_UNIT_STATUS_DELETED,
      SHARING_UNIT_STATUS_DRAFT,
      SHARING_UNIT_STATUS_REFUSED,
    ];
    const statuses = {};
    allStatuses.forEach((status) => {
      statuses[status] = [];
    });
    const sharingunitsMap = Map().withMutations((map) => {
      sharingunits.forEach((sharingunit) => {
        let updatedSharingUnit = sharingunit;
        if (sharingunit.get('status') === SHARING_UNIT_STATUS_INIT) {
          updatedSharingUnit = sharingunit.set(
            'data',
            sharingunit.getIn(['lastRequest', 'data'], Map())
          );
        }
        if (
          sharingunit.get('status') === SHARING_UNIT_STATUS_AGREED &&
          !sharingunit.getIn(['data', 'name'])
        ) {
          updatedSharingUnit = sharingunit.setIn(
            ['data', 'name'],
            `${i18n.t('Listing')} - ${
              statuses[SHARING_UNIT_STATUS_AGREED].length + 1
            }`
          );
        }
        // Update data
        if (
          flattenHierarchies &&
          updatedSharingUnit.getIn(['data', 'hierarchy'])
        ) {
          updatedSharingUnit = updatedSharingUnit.setIn(
            ['data', 'hierarchy'],
            toLinearHierarchy(updatedSharingUnit.getIn(['data', 'hierarchy']))
          );
        }
        map.set(sharingunit.get('id').toString(), updatedSharingUnit);
        statuses[sharingunit.get('status')].push(sharingunit.get('id'));
      });
    });
    return state.withMutations((newState) =>
      newState
        .set('sharingunitsMap', sharingunitsMap)
        .set('sharingunitsByStatus', fromJS(statuses))
        .set('loading', false)
    );
  },
  [RETAILER_UPDATE_SINGLE_SHARINGUNIT]: (
    state,
    { sharingunit, flattenHierarchies }
  ) => {
    const sharingUnitId = sharingunit.get('id');
    const oldStatus = state.getIn([
      'sharingunitsMap',
      sharingUnitId.toString(),
      'status',
    ]);
    const newStatus = sharingunit.get('status');
    let updatedSharingUnit = sharingunit;
    // Update data
    if (flattenHierarchies && updatedSharingUnit.getIn(['data', 'hierarchy'])) {
      updatedSharingUnit = updatedSharingUnit.setIn(
        ['data', 'hierarchy'],
        toLinearHierarchy(updatedSharingUnit.getIn(['data', 'hierarchy']))
      );
    }
    return state.withMutations((newState) => {
      newState.setIn(
        ['sharingunitsMap', sharingUnitId.toString()],
        updatedSharingUnit
      );
      if (oldStatus !== newStatus) {
        newState
          .setIn(
            ['sharingunitsByStatus', oldStatus],
            newState
              .getIn(['sharingunitsByStatus', oldStatus])
              .filter((id) => id !== sharingUnitId)
          )
          .setIn(
            ['sharingunitsByStatus', newStatus],
            newState
              .getIn(['sharingunitsByStatus', newStatus])
              .push(sharingUnitId)
          );
      }
      return newState;
    });
  },
  [UPDATE_ENTITY]: (state, action) => {
    if (action.entityKind === 'SharingUnit') {
      const keyPath = action.key.split('.');
      const path = [
        'sharingunitsMap',
        String(action.entityId),
        'data',
        ...keyPath,
      ];
      const currentValue = state.getIn(path);
      const newValue = fromJS(action.value);
      if (!is(currentValue, newValue)) {
        return state.setIn(path, newValue);
      }
    }
    return state;
  },
  [RETAILER_RESET_SHARINGUNITS]: () => initialState,
});
