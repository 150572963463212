import { reduce, set, unset, update } from 'lodash/fp';

import { ActionPayload } from 'types';

import { removeDataOpsPatches } from '../actions';
import { DataOpsPatch, DataOpsPatchesState } from '../types';

export const dataOpsRemovePatchesReducer = (
  patchList: ActionPayload<typeof removeDataOpsPatches>
) =>
  update(['patches'], (patches: DataOpsPatchesState) =>
    reduce(
      (patchesState, patch) => {
        if (patch.key && patchesState[patch.key]) {
          const newData = patchesState[patch.key].data.filter(
            (p) => (p as DataOpsPatch).id !== patch.id
          );
          if (newData.length) {
            return set([patch.key, 'data'], newData, patchesState);
          }
          return unset([patch.key], patchesState);
        }
        return patchesState;
      },
      patches,
      patchList
    )
  );
