import * as referentialApi from 'resources/referentialApi';
import { logError } from 'utils/logging';

import * as constants from './constants';

export const fetchReferential = (() => {
  const isFetching = {};
  return (slug) => async (dispatch) => {
    if (isFetching[slug]) {
      return;
    }
    isFetching[slug] = true;
    try {
      const response = await referentialApi.fetchReferentials(slug);
      dispatch({
        type: constants.RECEIVE_REFERENTIAL,
        payload: {
          entities: response.referentials,
          slug,
        },
      });
    } catch (e) {
      logError(`Failed to fetch referential ${slug}`);
      logError(e);
    }
    isFetching[slug] = false;
  };
})();
