import { List } from 'immutable';
import { flow, over, spread } from 'lodash/fp';

import { hasRfp } from 'core/api/organization-settings';
import {
  getOrganizationSettings,
  isManufacturer,
  isRetailer,
  managesOrganization,
  userHasRfp,
} from 'core/api/user';
import {
  FEATURE_PERMISSIONS_V3_RFP_BUSINESSUNIT,
  FEATURE_PERMISSIONS_V3_RFP_EVENT,
} from 'modules/feature-flag/constants';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  RFP_BUSINESSUNIT_PERMISSION,
  RFP_EVENT_PERMISSION,
  SHOW_PERMISSION,
} from 'modules/permissions/const';
import { getAll as selectRecipients } from 'modules/recipients/reducers';
import { selectUser } from 'reducers/user/selectors';
import { RecipientsImmutable, UserImmutable } from 'types';
import { GlobalState } from 'types/redux';

import { selectHasRfpModule } from './modules/userInfo/selectors';

const hasRecipientsWithRfp = (recipients: RecipientsImmutable) =>
  recipients.some((r) => hasRfp(r?.get('settings')));

const hasPermissionRFPEventV3 = (user: UserImmutable) =>
  hasPermissionV3(user, FEATURE_PERMISSIONS_V3_RFP_EVENT) &&
  hasPermissionModule(user, RFP_EVENT_PERMISSION, SHOW_PERMISSION);

const hasPermissionRFPBusinessUnitV3 = (user: UserImmutable) =>
  hasPermissionV3(user, FEATURE_PERMISSIONS_V3_RFP_BUSINESSUNIT) &&
  hasPermissionModule(user, RFP_BUSINESSUNIT_PERMISSION, SHOW_PERMISSION);

export const hasRfpForRetailer = (user: UserImmutable) =>
  isRetailer(user) &&
  hasRfp(getOrganizationSettings(user)) &&
  (hasPermissionRFPEventV3(user) ||
    hasPermissionRFPBusinessUnitV3(user) ||
    (!hasPermissionRFPEventV3(user) &&
      !hasPermissionRFPBusinessUnitV3(user) &&
      userHasRfp(user))); // @Clarky seal of approval

export const selectHasRfpForRetailer = flow<[GlobalState], any, boolean>(
  selectUser,
  hasRfpForRetailer
);

export const hasRfpForManufacturer = (
  user: UserImmutable,
  recipients: RecipientsImmutable = List(),
  userHasRfpModule: boolean
): boolean => {
  return (
    isManufacturer(user) &&
    managesOrganization(user) &&
    ((hasRfp(getOrganizationSettings(user)) &&
      hasRecipientsWithRfp(recipients)) ||
      userHasRfpModule)
  );
};

export const selectHasRfpForManufacturer = flow<[GlobalState], any, boolean>(
  over<any>([selectUser, selectRecipients, selectHasRfpModule]),
  spread(hasRfpForManufacturer)
);
