import { LogAs, Login, LoginSSO } from 'modules/auth/login';
import Logout from 'modules/auth/logout';
import { Activate, CreatePassword, ForgotPassword } from 'modules/auth/others';
import { Signup } from 'modules/auth/signup';
import { Redirect } from 'modules/redirect';
import { Unsubscribe } from 'modules/unsubscribe';
import * as routes from 'routes';

import Landing from './Landing';

export default [
  { path: routes.landing, component: Landing, exact: true },
  { path: routes.login, component: Login, exact: true },
  { path: routes.loginSSO, component: LoginSSO },
  { path: routes.logout, component: Logout },
  { path: routes.logAs, component: LogAs },
  { path: routes.signupComplete, component: Signup, exact: true },
  { path: routes.signup, component: Signup },
  { path: routes.forgotPassword, component: ForgotPassword },
  { path: routes.createPassword, component: CreatePassword },
  { path: routes.validateAccount, component: Activate },

  { path: routes.redirect, component: Redirect },
  { path: routes.r, component: Redirect },
  { path: routes.unsubscribe, component: Unsubscribe },
];
