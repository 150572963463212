import { List } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

// Outer modules
import { updateEntity } from 'actions/entity';
import Field from 'components/ui/form/field';
import Raguel from 'components/ui/form/plugins/validator';
import {
  hasAllPriceDisplayUnitSetting,
  hasAllPriceHluSetting,
  hasCuPriceDisplayUnitSetting,
  hasCuPriceHluSetting,
  hasDisplayPriceDisplayUnitSetting,
  hasHeterogeneousPriceHluSetting,
  hasNoPriceDisplayUnitSetting,
  hasNoPriceHluSetting,
} from 'core/api/organization-settings';
import { isLoggedAs } from 'core/api/user';
// Inner module
import { ErrorPanel } from 'core/components/error/error-pannel';
import { selectHasEditableFirstBracketListing } from 'modules/feature-flag/selectors';
import { getRecipientsMap } from 'modules/recipients/reducers';
import {
  getPricewaterfallLabels,
  getSharingUnitTargetOrganizationId,
} from 'modules/sharing-units/selectors';
import {
  selectCurrentProductVersionGTIN,
  selectDisplayName,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectIsHeterogeneousLogisticalUnit,
  selectProductId,
  selectProductVersionIsMadeOf,
} from 'reducers/productVersion';
import {
  selectIsRetailer,
  selectUser,
  selectUserOrganizationSettings,
} from 'reducers/user/selectors';
import i18n from 'utils/i18n';
import { get, size } from 'utils/immutable';
import { track } from 'utils/tracking';

import { createPriceWaterfall } from '../../actions';
import PriceWaterfallsCurrency from '../../components/currency';
import ConnectedPriceWaterfall from '../../components/price-waterfall';
import AddPriceWaterfall from '../../components/price-waterfall/add';
import '../../price-waterfalls.scss';
import {
  selectCurrencyForSharingUnit,
  selectSharingUnitIsDraft,
} from '../../selectors';

const mapStateToProps = (state, { entityId }) => ({
  productId: selectProductId(state),
  targetOrganizationId: getSharingUnitTargetOrganizationId(state)(entityId),
  retailersById: getRecipientsMap(state),
  currency: selectCurrencyForSharingUnit(state)(entityId),
  isMadeOf: selectProductVersionIsMadeOf(state),
  isHeterogeneousLogisticalUnit: selectIsHeterogeneousLogisticalUnit(state),
  isDisplayUnit: selectIsDisplayUnit(state),
  isConsumerUnit: selectIsConsumerUnit(state),
  currentProductDisplayName: selectDisplayName(state),
  currentProductGtin: selectCurrentProductVersionGTIN(state),
  canDelete: selectSharingUnitIsDraft(state)(entityId),
  priceWaterfallLabels: getPricewaterfallLabels(state).get(entityId) || List(),
  isRetailer: selectIsRetailer(state),
  user: selectUser(state),
  retailerSetting: selectUserOrganizationSettings(state),
  editableFirstBracket: selectHasEditableFirstBracketListing(state),
});

export class PriceWaterfalls extends Field {
  static propTypes = {
    ...Field.propTypes,
    productId: PropTypes.number.isRequired,
    targetOrganizationId: PropTypes.number,
    currency: PropTypes.object,
    isMadeOf: PropTypes.array.isRequired,
    isDisplayUnit: PropTypes.bool.isRequired,
    isConsumerUnit: PropTypes.bool.isRequired,
    currentProductDisplayName: PropTypes.string.isRequired,
    currentProductGtin: PropTypes.string.isRequired,
    canDelete: PropTypes.bool.isRequired,
    priceWaterfallLabels: ImmutablePropTypes.list.isRequired,
    isRetailer: PropTypes.bool.isRequired,
    retailerSetting: PropTypes.object,
    editableFirstBracket: PropTypes.bool,
    user: PropTypes.object,
  };

  componentDidMount() {
    // Do nothing
  }

  componentDidUpdate() {
    // Do nothing
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.priceWaterfallLabels !== this.props.priceWaterfallLabels) {
      return true;
    }

    return super.shouldComponentUpdate(nextProps);
  }

  getRetailerSettings(retailer) {
    if (size(retailer, ['settings'])) {
      return get(retailer, ['settings']);
    }
    return retailer;
  }

  getDisplayType() {
    const { field } = this.props;
    if (get(field, 'options.isTariff', false)) {
      return 'fromTariff';
    }
    if (get(field, 'options.isTemplate', false)) {
      return 'fromTemplate';
    }
    return 'default';
  }

  onDelete = (productId) => () => {
    const { field, value } = this.props;
    if (value && value.length) {
      const newValue = value.filter(
        (pw) => get(pw, ['product', 'id']) !== productId
      );
      this.onUpdate(field.model, newValue);
      track({
        category: 'pricewaterfall',
        action: 'pricewaterfall_removed',
        product_id: productId,
      });
    }
  };

  onUpdate = (model, value, isDirty = true) => {
    this.props.dispatch(
      updateEntity(
        model,
        value,
        this.props.entityId,
        this.props.entityKind,
        isDirty,
        true // ignoreField
      )
    );
  };

  onUpdateLabel = (value) => {
    this.props.dispatch(
      updateEntity(
        'label',
        value,
        this.props.entityId,
        'PriceWaterfallLabel',
        true, // is dirty
        true // ignoreField
      )
    );
  };

  onCreate = (productId, duplicateFrom) => {
    const { dispatch, targetOrganizationId } = this.props;
    dispatch(
      createPriceWaterfall({
        productId,
        entityId: this.props.entityId,
        duplicateFrom,
        targetOrganizationId,
      })
    );
  };

  renderPriceWaterFall = (model, value, productId, productLabel) => {
    const {
      entityId,
      entityKind,
      targetOrganizationId,
      canDelete,
      priceWaterfallLabels,
      editableFirstBracket,
    } = this.props;
    const priceWaterfallLabel = priceWaterfallLabels.find(
      (label) => label.product_id === get(value, ['product', 'id'])
    ) || {
      sharingUnit_id: entityId,
      product_id: productId,
      label: '',
      present: false,
    };

    return (
      <>
        {(value || !this.isReadOnly()) && <h4>{productLabel}</h4>}
        {value && (
          <ConnectedPriceWaterfall
            key={productId}
            model={model}
            value={value}
            entityId={entityId}
            entityType={entityKind}
            onUpdate={this.onUpdate}
            disabled={this.isReadOnly()}
            targetOrganizationId={targetOrganizationId}
            canDelete={canDelete}
            onDelete={this.onDelete(productId)}
            label={priceWaterfallLabel}
            onUpdateLabel={this.onUpdateLabel}
            displayType={this.getDisplayType()}
            editableFirstBracket={editableFirstBracket}
          />
        )}
        {!value && !this.isReadOnly() && (
          <AddPriceWaterfall
            onCreate={this.onCreate}
            productId={productId}
            recipientId={targetOrganizationId}
          />
        )}
      </>
    );
  };

  getProductValueAndKey = (productId) => {
    const value = this.props.value || [];
    return value.reduce((acc, v, k) => {
      if (v.product && v.product.id === productId) {
        acc.key = k;
        acc.value = v;
      }
      return acc;
    }, {});
  };

  getPriceWaterfallProducts = () => {
    const { isDisplayUnit, isHeterogeneousLogisticalUnit } = this.props;
    if (isDisplayUnit) {
      return this.getPriceWaterfallProductsForDisplayUnit();
    } else if (isHeterogeneousLogisticalUnit) {
      return this.getPriceWaterfallProductsForHLU();
    }
  };

  /**
   * Based on the retailer setting the products for a display unit may be
   * the display unit itself
   * the children (is made of)
   * all of that
   */
  getPriceWaterfallProductsForDisplayUnit = () => {
    const {
      targetOrganizationId,
      retailersById,
      user,
      retailerSetting,
      isRetailer,
      currentProductGtin,
      productId,
      currentProductDisplayName,
      isMadeOf,
    } = this.props;

    let retailer;
    if (isRetailer) {
      retailer = retailerSetting;
    } else {
      retailer = this.getRetailerSettings(
        retailersById.get(targetOrganizationId)
      );
    }
    let products = [];
    if (
      hasAllPriceDisplayUnitSetting(retailer) ||
      hasDisplayPriceDisplayUnitSetting(retailer) ||
      hasNoPriceDisplayUnitSetting(retailer)
    ) {
      products.push({
        id: productId,
        label: `${i18n.t(
          'Display Unit'
        )} - ${currentProductGtin} ${currentProductDisplayName}`,
      });
    }

    if (hasCuPriceDisplayUnitSetting(retailer) && isLoggedAs(user)) {
      // in the case where a price was created on a consumerUnit, then converted
      // in a displayUnit / hlu, the price shouldn't exist on display unit / hlu, we display it
      // for admin logged as so they can delete it
      products.push({
        id: productId,
        label: `${i18n.t(
          'Display Unit - (admin only - should not be present based on retailer configuration)'
        )} - ${currentProductGtin} ${currentProductDisplayName}`,
      });
    }
    if (
      hasAllPriceDisplayUnitSetting(retailer) ||
      hasCuPriceDisplayUnitSetting(retailer)
    ) {
      products = products.concat(
        isMadeOf
          .filter((product) => !!product.targetProduct)
          .map((product) => ({
            id: product.targetProduct.id,
            label: `${i18n.t('Component')} - ${product.targetProduct.label}`,
          }))
      );
    }
    return products;
  };

  getPriceWaterfallProductsForHLU = () => {
    const {
      targetOrganizationId,
      retailersById,
      user,
      retailerSetting,
      isRetailer,
      currentProductGtin,
      productId,
      currentProductDisplayName,
      isMadeOf,
    } = this.props;

    let retailer;
    if (isRetailer) {
      retailer = retailerSetting;
    } else {
      retailer = this.getRetailerSettings(
        retailersById.get(targetOrganizationId)
      );
    }
    let products = [];
    if (
      hasAllPriceHluSetting(retailer) ||
      hasHeterogeneousPriceHluSetting(retailer) ||
      hasNoPriceHluSetting(retailer)
    ) {
      products.push({
        id: productId,
        label: `${i18n.t(
          'Heterogeneous Unit'
        )} - ${currentProductGtin} ${currentProductDisplayName}`,
      });
    }

    if (hasCuPriceHluSetting(retailer) && isLoggedAs(user)) {
      // in the case where a price was created on a consumerUnit, then converted
      // in a displayUnit, the price shouldn't exist on display unit, we display it
      // for admin logged as so they can delete it
      products.push({
        id: productId,
        label: `${i18n.t(
          'Display Unit - (admin only - should not be present based on retailer configuration)'
        )} - ${currentProductGtin} ${currentProductDisplayName}`,
      });
    }
    if (hasAllPriceHluSetting(retailer) || hasCuPriceHluSetting(retailer)) {
      products = products.concat(
        isMadeOf
          .filter((product) => !!product.targetProduct)
          .map((product) => ({
            id: product.targetProduct.id,
            label: `${i18n.t('Component')} - ${product.targetProduct.label}`,
          }))
      );
    }
    return products;
  };

  renderPriceWaterFalls = () => {
    const { field } = this.props;
    // if product is not display Unit only 1 priceWaterfall to render
    if (
      !this.props.isDisplayUnit &&
      !this.props.isHeterogeneousLogisticalUnit
    ) {
      const valueAndKey = this.getProductValueAndKey(this.props.productId);
      return this.renderPriceWaterFall(
        `${field.model}.${valueAndKey.key}`,
        valueAndKey.value,
        this.props.productId
      );
    }

    const products = this.getPriceWaterfallProducts();
    return (
      <div className="PriceWaterfalls--blocs">
        {products.map((product) => {
          const valueAndKey = this.getProductValueAndKey(product.id);
          return (
            <div
              className="PriceWaterfalls--bloc"
              id={`PriceWaterfalls--bloc__${product.id}`}
              key={`PriceWaterfalls--bloc.${product.id}`}
            >
              {this.renderPriceWaterFall(
                `${field.model}.${valueAndKey.key}`,
                valueAndKey.value,
                product.id,
                product.label
              )}
            </div>
          );
        })}
      </div>
    );
  };

  computeStalePriceWaterFallErrorMessage = (priceWaterfall) => {
    const {
      productId,
      retailersById,
      targetOrganizationId,
      isConsumerUnit,
      isDisplayUnit,
      isHeterogeneousLogisticalUnit,
    } = this.props;
    const retailer = retailersById.get(targetOrganizationId);

    let messageTypechanged = priceWaterfall.label
      ? i18n.t(
          "The product's unit type has changed, please delete the associated price waterfall ({{not_translated}})",
          { not_translated: priceWaterfall.label }
        )
      : i18n.t(
          "The product's unit type has changed, please delete the associated price waterfall"
        );

    let messageChildDeleted = priceWaterfall.label
      ? i18n.t(
          'The child product ({{not_translated}}) has been deleted from this unit, please delete the associated price waterfall.',
          { not_translated: priceWaterfall.label }
        )
      : i18n.t(
          'A child product has been deleted from this unit, please delete the associated price waterfall.'
        );

    if (hasCuPriceDisplayUnitSetting(retailer)) {
      if (
        isConsumerUnit ||
        (isDisplayUnit && priceWaterfall.id === productId) ||
        (isHeterogeneousLogisticalUnit && priceWaterfall.id === productId)
      ) {
        return messageTypechanged;
      } else if (priceWaterfall.id !== productId) {
        return messageChildDeleted;
      }
    } else if (hasAllPriceDisplayUnitSetting(retailer)) {
      if (isConsumerUnit) {
        return messageTypechanged;
      } else if (priceWaterfall.id !== productId) {
        return messageChildDeleted;
      }
    }
    return null;
  };

  renderStalePriceWaterfallErrors = (value) => {
    const { isConsumerUnit, isDisplayUnit } = this.props;

    const productsWithPriceWaterfall = value.map((v) => ({
      id: v.product.id,
      label: v.product.label,
    }));
    const products = this.getPriceWaterfallProducts();
    let productIds = new Set();

    // whatever goes in the productIds set will not generate a price waterfall error
    if (isConsumerUnit) {
      productIds = productIds.add(this.props.productId);
    } else if (isDisplayUnit) {
      products.forEach((p) => {
        if (p.id !== this.props.productId) productIds.add(p.id);
      });
    }

    let productsWithoutPriceWaterfall = [];
    productsWithPriceWaterfall.forEach((pwpw) => {
      if (!productIds.has(pwpw.id)) {
        productsWithoutPriceWaterfall.push(pwpw);
      }
    });

    const buttonText = i18n.t('Delete price waterfall');
    return productsWithoutPriceWaterfall.map((pwpw) => {
      let errorMessage;
      errorMessage = this.computeStalePriceWaterFallErrorMessage(pwpw);
      return (
        errorMessage && (
          <ErrorPanel key={pwpw.id}>
            <div className="ErrorPanel__text">{errorMessage}</div>
            <Button secondary onClick={this.onDelete(pwpw.id)}>
              {buttonText}
            </Button>
          </ErrorPanel>
        )
      );
    });
  };

  render() {
    const {
      entityId,
      entityKind,
      field,
      currency,
      value,
      targetOrganizationId,
      isConsumerUnit,
      isDisplayUnit,
      isRetailer,
    } = this.props;
    return (
      <div className="PriceWaterfalls">
        {this.getDisplayType() !== 'fromTariff' && (
          <PriceWaterfallsCurrency
            entityId={entityId}
            entityType={entityKind}
            model={`${field.model}.currency.code`}
            value={currency ? currency.get('code') : null}
          />
        )}
        <div className="Raguel__block FormField--raguelError">
          <Raguel
            entityId={entityId}
            entityKind={entityKind}
            label={field.label}
            model={field.model}
            value={value}
            recipientId={targetOrganizationId}
            readOnly={this.isReadOnly()}
          />
        </div>
        {value &&
          !isRetailer &&
          (isConsumerUnit || isDisplayUnit) &&
          this.renderStalePriceWaterfallErrors(value)}
        {this.renderPriceWaterFalls()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(PriceWaterfalls);
